import { t } from "i18next";
import { formatLocaleDate, getLocaleByUserOrganization, getSelectedDataById } from "../../utils/utils";

import { useSelector } from "react-redux";
import DocumentDto from "../../model/dto/DocumentDto";
import OrganizationUserDto from "../../model/dto/OrganizationUserDto";
import TeamsDto from "../../model/dto/TeamDto";
import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import LmAvatar from "../avatar/LmAvatar";
import { AuthState } from "../../model/states/AuthState";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { useMemo } from "react";

interface Props {
  document: DocumentDto;
  users: OrganizationUserDto[];
  teams: TeamsDto[];
}

const DocumentMetadata = ({ document, users, teams }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const team = teams?.find((team: TeamsDto) => team.id === document?.teamId);
  const user = users?.find((user: OrganizationUserDto) => user.userId === document?.createdBy);
  const dateLocale = useMemo(()=>getLocaleByUserOrganization(stateAuth.userInfo.data.user.id, selectedOrganizationData?.users) === 'en' ? 'en-US' : 'es-ES', [stateAuth.userInfo.selectedOrganization])

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-3">
        <LmAvatar src={user?.avatarImage} radius="100%" />
        <div>
          <div className="flex items-center flex-wrap gap-2">
            <p className="text-subtle text-sm">{t("content.uploadedBy")}</p>
            <div className="text-sm font-semibold">
              <span>{document?.created?.name} </span>
              <span>{document?.created?.lastName}</span>
            </div>
          </div>
          <div className="text-xs text-subtle mt-1">{formatLocaleDate(document?.createdAt, dateLocale)}</div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <LmAvatar src={team?.image} size={40} radius="0.5rem" />
        {stateMain.sizeScreen !== SizeScreen.MOBILE && <p className="text-sm text-subtle font-semibold">{team?.name}</p>}
      </div>
    </div>
  );
};

export default DocumentMetadata;
