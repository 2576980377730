import { Dialog } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import { LmAddToPlaylistForm } from "../form-add-to-playlist/LmAddToPlaylistForm";
import { Timeline } from "../../model/dto/ProjectDto";

interface Props {
  timelines: Timeline[];
  open: boolean;
  handleClose(): void;
}
const LmModalAddToPlaylist = ({ timelines, open, handleClose }: Props) => {
  const { t } = useTranslation();
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className={`p-5 md:p-8 theme-${stateMain.colorAplication}`}>
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative">{t("content.addToPlaylist")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-new-team" />
        </div>
        <p className="mb-3">{t("content.addToPlaylistDescription")}</p>
        <LmAddToPlaylistForm timelines={timelines} onClose={onClose} />
      </div>
    </Dialog>
  );
};

export default LmModalAddToPlaylist;
