import React from "react";
import LmIcon from "../icon/LmIcon";
import "./LmButton.scss";

interface Button {
  testid?: string;
  title?: string;
  type?: string;
  styleButton?: string;
  size?: string;
  fullWidth?: boolean;
  isRadius?: boolean;
  isDisabled?: boolean;
  icon?: any;
  iconSize?: string;
  iconPosition?: string;
  text?: string | null;
  buttonType?: "submit" | "reset" | "button";
  clickAction?(event?: React.MouseEvent<HTMLButtonElement>): void;
}

const LmButton: React.FC<Button> = ({
  testid = "",
  title = "",
  type = "primary",
  styleButton = "solid",
  size = "small",
  fullWidth = true,
  isRadius = true,
  isDisabled = false,
  icon = null,
  iconSize = "original",
  iconPosition = "left",
  text = "",
  buttonType = "button",
  clickAction = () => {},
}) => {
  return (
    <button
      data-testid={testid}
      title={title}
      className={`lm-button flex justify-center items-center ${
        fullWidth && "w-full sm:w-auto"
      } min-w-16 lm-button--${type} lm-button--${styleButton} lm-button--${size} ${isRadius ? "lm-button--radius" : ""} ${
        isDisabled ? "lm-button--disabled" : ""
      } ${icon ? "lm-button--icon-" + iconPosition : ""}`}
      disabled={isDisabled}
      onClick={!isDisabled ? clickAction : () => {}}
      type={buttonType}>
      {icon && <LmIcon className="lm-button__icon" size={iconSize} icon={icon} />}
      {text && <span>{text}</span>}
    </button>
  );
};

export default LmButton;
