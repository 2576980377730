import React from "react";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";

interface Data {
  name: string;
  value: number;
}

interface IStorageBar {
  measure?: string | null;
  maxCapacity: number;
  flex?: boolean;
  align?: string;
  data: Data[];
}

const StorageBarMultiple: React.FC<IStorageBar> = ({ measure = "GB", maxCapacity = 16, data, flex = false, align = "left" }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={`storage ${flex && "flex flex-wrap md:flex-nowrap items-center gap-4"}`}>
      <div
        className="relative w-full h-4 rounded-3xl overflow-hidden bg-gray-300/30 flex gap-px"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        {data
          .filter((item) => item.value > 0)
          .map((item) => (
            <div key={item.name} className="h-4 bg-orgColor" style={{ width: `${(item.value * 100) / maxCapacity}%` }}></div>
          ))}
      </div>
      <p className={`text-${align} w-full ${!flex && "mt-4"}`}>
        {`${data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0).toFixed(2)} ${measure} ${t(
          "storage.of"
        )} ${maxCapacity} ${measure} ${t("storage.consumed")}`}
      </p>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <div className="rounded-s shadow-shadow py-2 px-4 space-y-2">
          {data.map((item) => (
            <div key={"tooltip-" + item.name} className="flex gap-1">
              <span>{item.name}:</span>
              <span>
                {item.value.toFixed(2)} {measure}
              </span>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default StorageBarMultiple;
