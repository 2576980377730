import { useEffect, useState } from "react";
import NotificationsListItem from "../notifications-list-item/NotificationsListItem";
import Pager from "../pager/Pager";
import { useDispatch, useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import NotificationDto from "../../model/dto/NotificationDto";
import { NotificationsSorterOptions } from "../../model/NotificationsSorterOptions";

import { NotificationsState } from "../../model/states/NotificationsState";
import useNotifications from "../../hooks/useNotifications";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import { useTranslation } from "react-i18next";
import { AuthState } from "../../model/states/AuthState";
import { ReadType } from "../../model/ReadType";
import { ActionNotificationsType } from "../../model/actions/typeNotifications";
import { TypeActions } from "../../model/actions/typeActions";
import Empty from "../empty/empty";

const ITEMS_PER_PAGE = 10;
interface Props {
  sorterOption: NotificationsSorterOptions;
}
const LmNotificationsList = ({ sorterOption }: Props) => {
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const originalList = stateNotifications.notificationsList;
  const [listToShow, setListToShow] = useState<NotificationDto[]>([]);
  const [activePage, setActivePage] = useState(0);
  const [filteredList, setFilteredList] = useState<NotificationDto[]>();
  const [selectedNotification, setSelectedNotification] = useState<NotificationDto>();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const { markAsRead, markAsUnread, deleteNotification } = useNotifications();

  useEffect(() => {
    checkFilters();
  }, [sorterOption, originalList]);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_CHANGE_FILTERED_NOTIFICATIONS_LIST, value: filteredList ?? [] });
  }, [filteredList]);

  useEffect(() => {
    checkFilters(true);
  }, [stateAuth.userInfo.selectedOrganization]);

  useEffect(() => {
    if (stateNotifications.filter) {
      checkFilters(true);
    } else {
      const sorterList = sortList(sorterOption, originalList);
      setFilteredList(sorterList);
      setListToShow(sorterList?.slice(activePage * ITEMS_PER_PAGE, (activePage + 1) * ITEMS_PER_PAGE));
    }
  }, [stateNotifications.filter]);

  const filterListByNotificationsType = (list?: NotificationDto[]): NotificationDto[] => {
    if (list) {
      return list.filter((item) => {
        if (stateNotifications?.filter?.notificationType?.includes(item.type.tag)) {
          return item;
        }
      });
    }

    return [];
  };

  const filterListByReadType = (list?: NotificationDto[]): NotificationDto[] => {
    if (list) {
      return list.filter((item) => {
        const isRead = item?.readAt !== null;
        if (
          stateNotifications?.filter?.read?.length === 2 ||
          (stateNotifications?.filter?.read?.includes(ReadType.Read) && isRead) ||
          (stateNotifications?.filter?.read?.includes(ReadType.Unread) && !isRead)
        ) {
          return item;
        }
      });
    }

    return [];
  };

  const filterByDateRange = (list: NotificationDto[] = []): NotificationDto[] => {
    const { start, end } = stateNotifications.filter?.dateRange ?? {};
    if (!start && end) {
      const endDate = new Date(end);
      endDate.setHours(0, 0, 0, 0);
      return list.filter((item) => {
        const createdAt = new Date(item.createdAt);
        createdAt.setHours(0, 0, 0, 0);
        return createdAt <= endDate;
      });
    }

    if (start && end) {
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(end);
      endDate.setHours(0, 0, 0, 0);
      return list.filter((item) => {
        const createdAt = new Date(item.createdAt);
        createdAt.setHours(0, 0, 0, 0);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    return [];
  };

  const checkFilters = (resetPagination?: boolean) => {
    let resultList: NotificationDto[] = [...(originalList ?? [])];
    if (stateNotifications.filter?.notificationType) {
      resultList = filterListByNotificationsType(originalList);
    }

    if (stateNotifications.filter?.read) {
      const aux = [...resultList];
      resultList = filterListByReadType(aux);
    }

    if (stateNotifications.filter?.dateRange && stateNotifications.filter?.dateRange.end !== "") {
      const aux = [...resultList];
      resultList = filterByDateRange(aux);
    }

    const sorterList = sortList(sorterOption, resultList);
    setFilteredList(resultList);
    if (resetPagination) {
      setActivePage(0);
      setListToShow(sorterList.slice(0 * ITEMS_PER_PAGE, (0 + 1) * ITEMS_PER_PAGE));
    } else {
      setListToShow(sorterList.slice(activePage * ITEMS_PER_PAGE, (activePage + 1) * ITEMS_PER_PAGE));
    }
  };

  const sortList = (sorter: NotificationsSorterOptions, list: NotificationDto[]): NotificationDto[] => {
    switch (sorter) {
      case NotificationsSorterOptions.lastRecent:
        return list?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

      case NotificationsSorterOptions.mostRecent:
        return list?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      default: {
        return list;
      }
    }
  };

  const onClickMark = (notification: NotificationDto) => {
    if (notification?.readAt !== null) {
      markAsUnread([notification.id]);
    } else {
      markAsRead([notification.id]);
    }
  };

  const onClickDelete = (notification: NotificationDto) => {
    setSelectedNotification(notification);
    setShowModalConfirm(true);
  };
  const onDeleteNotification = () => {
    deleteNotification([selectedNotification?.id ?? ""]);
    setShowModalConfirm(false);
  };

  const onClickPrevPage = (items: { start: number; end: number }) => {
    setActivePage((prevValue) => prevValue - 1);
    const itemsByPage = filteredList?.slice(items.start - 1, items.end);
    if (itemsByPage) setListToShow(itemsByPage);
  };

  const onClickNextPage = (items: { start: number; end: number }) => {
    setActivePage((prevValue) => prevValue + 1);
    const itemsByPage = filteredList?.slice(items.start - 1, items.end);
    if (itemsByPage) setListToShow(itemsByPage);
  };

  return (
    <div>
      <div>
        {listToShow.length ? (
          listToShow.map((notification: NotificationDto) => (
            <NotificationsListItem
              key={notification.id}
              id={notification.id || ""}
              title={notification.type?.title || ""}
              description={notification.details ?? ""}
              tag={notification.type?.tag}
              date={notification.createdAt}
              isChecked={stateNotifications.selectedNotifications.includes(notification.id)}
              read={notification?.readAt !== null}
              externalLink={notification?.externalLink}
              avatarImage={notification.avatarImage}
              actionMark={() => onClickMark(notification)}
              actionDelete={() => onClickDelete(notification)}
            />
          ))
        ) : (
          <div className="col-span-full row-span-full">
            <Empty text={t("notifications.emptyNotifications")} />
          </div>
        )}
      </div>
      <div className="flex justify-end items-center mt-4">
        <Pager
          total={filteredList?.length ?? 0}
          itemsForPage={ITEMS_PER_PAGE}
          activePage={activePage}
          actionPrev={(items: { start: number; end: number }) => onClickPrevPage(items)}
          actionNext={(items: { start: number; end: number }) => onClickNextPage(items)}
        />
      </div>
      <LmModalConfirm
        id="test-modal-confirm-remove-notification"
        title={t("notifications.deleteNotificationTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={showModalConfirm}
        handleClose={() => setShowModalConfirm(false)}
        confirm={onDeleteNotification}
      />
    </div>
  );
};

export default LmNotificationsList;
