import { TypeActions } from "../../model/actions/typeActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { InitialState } from "../../model/states/InitialState";
import { initialState } from "../states/mainState";

export const mainReducer = (state: InitialState = initialState, action: TypeActions): InitialState => {
  switch (action.type) {
    case ActionMainType.ALERT_OPEN: {
      return { ...state, alertNotification: { ...action.value, open: true } };
    }

    case ActionMainType.ALERT_CLOSE: {
      return { ...state, alertNotification: { ...state.alertNotification, open: false, message: "" } };
    }

    case ActionMainType.ACTION_VIEW_MENU: {
      return { ...state, viewMenu: { ...action.value } };
    }

    case ActionMainType.ACTION_CHANGE_COLOR_APLICATION: {
      return { ...state, colorAplication: action.value };
    }

    case ActionMainType.ACTION_SIZE_SCREEN: {
      return { ...state, sizeScreen: action.value };
    }

    default: {
      return { ...state };
    }
  }
};
