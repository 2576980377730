import { useFormik } from "formik";
import LmButton from "../button/LmButton";
import LmIcon from "../icon/LmIcon";
import { restorePasswordInitialValues, restorePasswordValidationSchema } from "./LmLoginForms.data";
import { ReactComponent as InputError } from "../../assets/icons/input-error.svg";
import { useTranslation } from "react-i18next";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { NavLink } from "react-router-dom";
import { ROUTE_LOGIN } from "../../routes/routes";

export function LmRestorePasswordForm() {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: restorePasswordInitialValues(),
    validationSchema: restorePasswordValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = async (formValue: any) => {
    dispatch<TypeActions>({
      type: ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_LOADING,
      value: { email: formValue.email, message: t("restorePassword.subtitle2") },
    });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full max-w-sm" data-testid="restore-password-form">
        <div>
          <input
            id="email"
            data-testid="email"
            name="email"
            placeholder={`${t("formLabel.email")}`}
            className={`w-full text-lg leading-7 p-4 rounded bg-white/80 ${formik.errors.email ? "border border-solid border-error-500" : ""}`}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && (
            <div className="flex items-center gap-2 my-3">
              <LmIcon icon={<InputError />} size="xs" />
              <span className="text-error-500">{formik.errors.email}</span>
            </div>
          )}
        </div>
        <LmButton
          data-testid="restore-button"
          styleButton="primary"
          type="solid"
          buttonType="submit"
          size="medium"
          text={`${t("restorePassword.sendButton")}`}
          iconPosition="right"
          isDisabled={!formik.isValid || stateAuth.resetRequestPassword.loading || formik.values.email === ""}></LmButton>

        <NavLink className="text-white hover:opacity-75 text-sm cursor-pointer mt-6 w-fit mx-auto" data-testid="restore-button-back" to={ROUTE_LOGIN}>
          {t("restorePassword.backButton")}
        </NavLink>
      </form>
    </>
  );
}
