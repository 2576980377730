import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AlertNotification from "./components/alert-notification/AlertNotification";
import Layout from "./components/layout/Layout";
import { SIZE_DESKTOP, SIZE_MOBILE } from "./constants/globalConstanst";
import { RolesType } from "./model/RolesType";
import { SizeScreen } from "./model/SizeSreen";
import { ActionAuthType } from "./model/actions/typeAuthActions";
import { ActionMainType, TypeMainActions } from "./model/actions/typeMainActions";
import { AuthState } from "./model/states/AuthState";
import { InitialState } from "./model/states/InitialState";
import { MainState } from "./model/states/MainState";
import ContentManagement from "./pages/content-management/ContentManagement";
import ContentManagementDetail from "./pages/content-management/ContentManagementDetail";
import DashBoard from "./pages/dashboard/Dashboard";
import LicenseSelector from "./pages/license-selector/LicenseSelector";
import Login from "./pages/login/Login";
import Register from "./pages/login/Register";
import RestorePassword from "./pages/login/RestorePassword";
import MatchStatisticDetail from "./pages/match-statistic/MatchStatisticDetail";
import NewOrganization from "./pages/new-organization/NewOrganization";
import Notifications from "./pages/notifications/Notifications";
import PageError from "./pages/page-error/PageError";
import Teams from "./pages/teams/Teams";
import Users from "./pages/users/Users";
import PermissionsRoute from "./routes/PermissionsRoute";
import PrivateRoute from "./routes/PrivateRoute";
import {
  ROUTE_ERROR,
  ROUTE_LICENSE_SELECTOR,
  ROUTE_LOGIN,
  ROUTE_PASSWORD_RESET,
  ROUTE_PRIVATE_CONTENT_MANAGEMENT,
  ROUTE_PRIVATE_DASHBOARD,
  ROUTE_PRIVATE_MATCH_STATISTICS,
  ROUTE_PRIVATE_NEW_ORGANIZATION,
  ROUTE_PRIVATE_NOTIFICATIONS,
  ROUTE_PRIVATE_TEAMS,
  ROUTE_PRIVATE_USERS,
  ROUTE_REGISTER,
  ROUTE_RESTORE_PASSWORD,
} from "./routes/routes";
import { changeLocale, getAllLocales } from "./utils/utils";

function App() {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const [firstLoading, setFirstLoading] = useState(false);
  const dispatch = useDispatch();
  const userLang = navigator.language;
  const defaultLocale = userLang.substring(0, 2);

  const removeLocalStorage = () => {
    if (!stateAuth.isStayLogged) {
      localStorage.removeItem("persist:root");
    }
  };

  const sizeScreen = () => {
    if (window.innerWidth > SIZE_DESKTOP) {
      dispatch<TypeMainActions>({ type: ActionMainType.ACTION_SIZE_SCREEN, value: SizeScreen.DESKTOP });
    } else if (window.innerWidth > SIZE_MOBILE) {
      dispatch<TypeMainActions>({ type: ActionMainType.ACTION_SIZE_SCREEN, value: SizeScreen.TABLET });
    } else {
      dispatch<TypeMainActions>({ type: ActionMainType.ACTION_SIZE_SCREEN, value: SizeScreen.MOBILE });
    }
  };

  useEffect(() => {
    if (stateAuth.appLanguage) {
      dispatch({
        type: ActionAuthType.ACTION_STATE_CHANGE_LOCALE,
        value: stateAuth.appLanguage,
      });
      changeLocale(stateAuth.appLanguage);
    } else {

      const allLocales = getAllLocales().map((locale) => locale.id);
      const lang = allLocales.includes(defaultLocale) ? defaultLocale : "en";

      dispatch({
        type: ActionAuthType.ACTION_STATE_CHANGE_LOCALE,
        value: lang,
      });
      changeLocale(lang);
    }
  }, [stateAuth.auth]);

  useEffect(() => {
    sizeScreen();

    window.addEventListener("resize", sizeScreen);
    return () => window.removeEventListener("resize", sizeScreen);

  }, []);

  useEffect(() => {
    if (stateAuth.userInfo.selectedOrganization) {
        dispatch({
            type: ActionAuthType.ACTION_USER_INFO_LOADING,
        })
    }
  }, [stateAuth.userInfo.selectedOrganization]);


  useEffect(() => {
    if (!firstLoading) {
      window.addEventListener("beforeunload", removeLocalStorage);
      setFirstLoading(true);
    }
    return () => window.removeEventListener("beforeunload", removeLocalStorage);
  }, [stateAuth.isStayLogged]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path={":id/:contentId/:commentId"} element={<Login />} />
          <Route path={":id/:contentId"} element={<Login />} />
          <Route path={ROUTE_LOGIN} element={<Login />} />
          <Route path={ROUTE_RESTORE_PASSWORD} element={<RestorePassword />} />
          <Route path={ROUTE_PASSWORD_RESET} element={<Register />} />
          <Route path={ROUTE_REGISTER} element={<Register />} />
          <Route path={ROUTE_ERROR} element={<PageError />} />
          <Route
            path="/"
            element={
              <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                <Layout />
              </PrivateRoute>
            }>
            <Route
              path={ROUTE_LICENSE_SELECTOR}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <LicenseSelector />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTE_PRIVATE_DASHBOARD}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <DashBoard />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTE_PRIVATE_NEW_ORGANIZATION}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <NewOrganization />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTE_PRIVATE_TEAMS}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <Teams />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTE_PRIVATE_USERS}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <PermissionsRoute access={["ownerClub"]}>
                    <Users />
                  </PermissionsRoute>
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTE_PRIVATE_CONTENT_MANAGEMENT}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <ContentManagement />
                </PrivateRoute>
              }>
              <Route
                path={":id"}
                element={
                  <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                    <ContentManagementDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path={":id/:commentId"}
                element={
                  <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                    <ContentManagementDetail />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route
              path={ROUTE_PRIVATE_NOTIFICATIONS}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <Notifications />
                </PrivateRoute>
              }
            />

            <Route
              path={`${ROUTE_PRIVATE_MATCH_STATISTICS}/:documentId`}
              element={
                <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                  <MatchStatisticDetail />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <PrivateRoute roleToAccess={[RolesType.CloudUser, RolesType.Owner, RolesType.External]}>
                <DashBoard />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>

      <AlertNotification
        open={stateMain.alertNotification.open}
        handleClose={() => {
          dispatch<TypeMainActions>({ type: ActionMainType.ALERT_CLOSE });
        }}
        severity={stateMain.alertNotification.severity}
        message={stateMain.alertNotification.message}></AlertNotification>
    </>
  );
}

export default App;
