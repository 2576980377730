import React from "react";
import "./LmDropDown.scss";
import { ClickAwayListener } from "@mui/material";

interface IDropDown {
  id?: string;
  open: boolean;
  close(): any;
  children: any;
}
// hay que añadir variable para desplegar a izquierda o derecha - TODO
const LmDropDown: React.FC<IDropDown> = ({ open, close, children, id = "" }) => {
  return (
    <>
      {open && (
        <ClickAwayListener touchEvent="onTouchStart" onClickAway={close}>
          <ul data-testid={id} className="lm-dropdown">
            {children}
          </ul>
        </ClickAwayListener>
      )}
    </>
  );
};

export default LmDropDown;
