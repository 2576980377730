import { Popover } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { IFilterApplied, Ref, Timeline } from "../../model/dto/ProjectDto";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import LmButton from "../button/LmButton";
import LmCheckBox from "../check-box/LmCheckBox";
import MatchFieldViewer from "../match-statistic/MatchFieldViewer";
import ProjectAnalysisFilter from "./ProjectAnalysisFilter";
import { InitialState } from "../../model/states/InitialState";
import { SizeScreen } from "../../model/SizeSreen";
import Loading from "../../components/loading/Loading";
interface IProjectAnalysis {
  viewTimeLine(timeline: Timeline): void;
}

const ProjectZonalTags: React.FC<IProjectAnalysis> = ({ viewTimeLine }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const project = useMemo(() => stateContent.project.data, [stateContent.project.data]);
  const [field, setField] = useState(0);
  const [timelinesShowed, setTimelinesShowed] = useState(project?.Timeline);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterApplied, setFilterApplied] = useState<IFilterApplied>({ event: [], tags: [], teams: [], player: [], period: [] });

  useEffect(() => {
    filter({ ...filterApplied });
  }, [field]);

  useEffect(() => {
    if(project) {
      setTimelinesShowed(project.Timeline);
    }
  }, [project]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-filter-pop-over" : undefined;

  const filterTimelines = (timelines: Timeline[], filterApplied: IFilterApplied): Timeline[] => {
    const periodsFiltered = stateContent.project.data?.Periods.filter((pe) => filterApplied.period.indexOf(pe.$id) !== -1);
    let result =
      filterApplied.event.length > 0 ? timelines.filter((timeline) => filterApplied.event.indexOf((timeline.EventType as Ref).$ref) !== -1) : timelines;
    result =
      filterApplied.tags.length > 0
        ? result.filter((timeline) => timeline.Tags.filter((tag) => filterApplied.tags.indexOf(tag.Value) !== -1).length > 0)
        : result;
    result =
      filterApplied.teams.length > 0
        ? result.filter((timeline) => timeline.Teams.filter((team) => filterApplied.teams.indexOf(team.$ref) !== -1).length > 0)
        : result;
    result =
      filterApplied.player.length > 0
        ? result.filter((timeline) => timeline.Players.filter((pl) => filterApplied.player.indexOf(pl.$ref) !== -1).length > 0)
        : result;
    result =
      filterApplied.period.length > 0
        ? result.filter(
            (timeline) =>
              periodsFiltered && periodsFiltered?.filter((pe) => timeline.Start >= pe.Nodes[0].Start && timeline.Stop <= pe.Nodes[0].Stop).length > 0
          )
        : result;
    return result;
  };

  const filter = (filterApplied: IFilterApplied) => {
    setTimelinesShowed(filterTimelines(project?.Timeline as Timeline[], filterApplied));
    setFilterApplied({ ...filterApplied });
  };

  const changeView = (view: number) => {
    setField(view);
  };

  const eventTimelineClick = (eventTimelineId: string) => {
    const timeline = project?.Timeline.find(timeline => timeline.$id === eventTimelineId);
    if (timeline) {
      viewTimeLine(timeline)
    }
    
  }

  return (
    <>
       {!project ? (
            <Loading />
        ) : (
      <div className="">
        <div className="flex flex-wrap items-center gap-2 py-4">
          <div className="text-sm font-semibold">
            <LmCheckBox text={t("content.wholeField")} id="project-category" value={field === 0} changeValue={() => changeView(0)}></LmCheckBox>
          </div>
          <div>
            <LmCheckBox text={t("content.midfield")} id="project-team1" value={field === 1} changeValue={() => changeView(1)}></LmCheckBox>
          </div>
          <div>
            <LmCheckBox text={t("content.soccerGoal")} id="project-team2" value={field === 2} changeValue={() => changeView(2)}></LmCheckBox>
          </div>

          <div className="ml-auto">
            <LmButton
              styleButton="secondary"
              buttonType="button"
              type="solid"
              size={stateMain.sizeScreen !== SizeScreen.MOBILE ? 'small' : "extra-small"}
              text={stateMain.sizeScreen !== SizeScreen.MOBILE ? t("notifications.filters") : ''}
              icon={<Filter />}
              iconPosition="center"
              clickAction={handleClick}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
            <ProjectAnalysisFilter filter={filter} listView={0} handleClose={handleClose} filterAplied={filterApplied} />
          </Popover>
        </div>
        {field === 0 && timelinesShowed?.length && (
          <div className="w-full">
            <MatchFieldViewer
              timelines={timelinesShowed as Timeline[]}
              background={stateContent.project.data?.Dashboard.FieldBackground ?? ""}
              imageType="FieldPosition"
              statistic={project}
              eventTimelineClick={eventTimelineClick}   
            />
          </div>
        )}
        {field === 1 && timelinesShowed?.length && (
          <div className="flex gap-8">
            <MatchFieldViewer
              timelines={timelinesShowed as Timeline[]}
              background={stateContent.project.data?.Dashboard.HalfFieldBackground ?? ""}
              imageType="HalfFieldPosition"
              statistic={project}
              eventTimelineClick={eventTimelineClick}   
            />
          </div>
        )}
        {field === 2 && (
          <div>
            <MatchFieldViewer
              timelines={timelinesShowed as Timeline[]}
              background={stateContent.project.data?.Dashboard.GoalBackground ?? ""}
              imageType="GoalPosition"
              statistic={project}
              eventTimelineClick={eventTimelineClick}   
            />
          </div>
        )}
      </div>
      )}
    </>
  );
};

export default ProjectZonalTags;
