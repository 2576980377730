import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-narrow-down.svg";
import { ReactComponent as Dots } from "../../assets/icons/dots-horizontal.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { ReactComponent as Tag } from "../../assets/icons/tag.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as ViewStats } from "../../assets/icons/menu-teams.svg";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { ContentManagementState, ContentManagementType } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { ROUTE_PRIVATE_MATCH_STATISTICS } from "../../routes/routes";
import { getSelectedDataById, isStaff, isStaffByOrganization, typesContentStateFilesSelected } from "../../utils/utils";
import LmButton from "../button/LmButton";
import LmIcon from "../icon/LmIcon";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import LmModalAddTag from "../modal-content-add-tag/LmModalAddTag";
import LmModalChangeFileCategory from "../modal-content-change-category/LmModalChangeFileCategory";
import ModalError from "../modal-error/ModalError";
import LmModalInfo from "../modal-info/LmModalInfo";
import LmModalShareFile from "../modal-share-file/LmModalShareFile";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
interface IMasiveOptions {
  isDetail: boolean;
}

const MasiveOptions: React.FC<IMasiveOptions> = ({ isDetail = false }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState<boolean[]>([false, false, false, false, false]);
  const [openModalWarning, setOpenModalWarning] = useState({ open: false, message: "" });
  const [openModalInfoDelete, setOpenModalInfoDelete] = useState(false);
  const [openModalFilesAllowed, setOpenModalfilesAllowed] = useState(false);
  const [openModalDeleteProject, setOpenModalDeleteProject] = useState(false);
  const [optionSelected, setOptionSelected] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStaffByOrg = useMemo(() => isStaffByOrganization(selectedOrganizationData, stateAuth), [stateAuth.userInfo.selectedOrganization]);

  const isStaffByDocument = (idDocument: string) => {
    const document = stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)].data.find((doc) => doc.id === idDocument);
    const team = selectedOrganizationData?.teams.find((team) => team.id === document?.teamId);
    if (team) {
      return isStaff(team, stateAuth);
    }
    return false;
  };

  const isDetailAndStaff = (isDetail && stateContent.filesSelected.length === 1 && isStaffByDocument(stateContent.filesSelected[0])) ||
                            (isDetail && stateContent.document.data && isStaffByDocument(stateContent.document.data.id));
  /* const isDetalAndFileOwner = () => {
    if (stateContent.filesSelected.length === 1 && isDetail) {
      const document = stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)].data.find(
        (doc) => doc.id === stateContent.filesSelected[0]
      );
      return document?.createdBy === stateAuth.userInfo.data.user.id || isOwner(stateAuth);
    }
    return false;
  }; */

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const callBackDownLoadFiles = () => {
    setOpenModal([true, false, false, false, false]);
  };

  const downloadFiles = () => {
    dispatch({
      type: ActionContentManagementType.ACTION_DOWNLOAD_FILES_LOADING,
      value: { data: stateContent.filesSelected.join("&id="), callBack: callBackDownLoadFiles },
    });
  };

  const callBackDeleteFiles = (filesAllowed: string[]) => {
    setOpenModalInfoDelete(true);
    dispatch({ type: ActionContentManagementType.ACTION_DELETE_FILES_SUCCESS, value: filesAllowed });
  };

  const getFilesAllowed = (option: number) => {
    const allFiles = stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)].data;
    // let files;
    /* if (option === 4) {
      files = stateContent.filesSelected.filter((file) => {
        const dataFile = allFiles.find((allFile) => allFile.id === file);
        if (dataFile) {
          return dataFile.createdBy === stateAuth.userInfo.data.user.id || isOwner(stateAuth);
        }
        return false;
      });
    } else { */
    const files = stateContent.filesSelected.filter((file) => {
        const dataFile = allFiles.find((allFile) => allFile.id === file);
        const team = selectedOrganizationData?.teams.find((t) => t.id === dataFile?.teamId);
        if (dataFile && team) {
          return isStaff(team, stateAuth);
        }
        return false;
      });
   /*  } */
    return files;
  };

  const deleteFiles = () => {
    closeModal(4);
    if (stateContent.contentSelected === ContentManagementType.Project) {
      setOpenModalDeleteProject(true);
    } else {
      const params = `?organizationId=${stateAuth.userInfo.selectedOrganization}&id=${stateContent.filesSelected.join("&id=")}`;
      dispatch({
        type: ActionContentManagementType.ACTION_DELETE_FILES_LOADING,
        value: { data: params, callBack: () => callBackDeleteFiles(stateContent.filesSelected) },
      });
    }
  };

  const deleteProject = (deleteVideo: boolean) => {
    setOpenModalDeleteProject(false);
    let params = `?organizationId=${stateAuth.userInfo.selectedOrganization}&id=${stateContent.filesSelected.join("&id=")}`;
    if (deleteVideo) {
      const allFiles = stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)].data;
      const dataFile = stateContent.filesSelected.map(file => allFiles.find(allFile => allFile.id === file)?.relatedVideoId ?? '');
      params += "&id=" + dataFile.join("&id=")
    }

    dispatch({
      type: ActionContentManagementType.ACTION_DELETE_FILES_LOADING,
      value: { data: params, callBack: () => callBackDeleteFiles(stateContent.filesSelected) },
    });
  }

  const handleClose = (option: number) => {
    setOptionSelected(option);
    setAnchorEl(null);
    const numFilesSelected = stateContent.filesSelected.length;
    if (numFilesSelected === 0) {
      setOpenModalWarning({ open: true, message: t("errors.errorMasiveOperationsMessage") });
    } else {
      if (option === 0) {
        downloadFiles();
      } else {
        const filesAllowed = getFilesAllowed(option);
        dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: filesAllowed });
        if (filesAllowed.length === 0) {
          setOpenModalWarning({ open: true, message: t("errors.errorMasiveOperationNoFilesAllowed") });
        } else if (filesAllowed.length < stateContent.filesSelected.length) {
          setOpenModalfilesAllowed(true);
        } else {
          const options = [...openModal];
          options[option] = true;
          setOpenModal(options);
        }
      }
    }
  };

  const operationFilesAllowed = () => {
    setOpenModalfilesAllowed(false);
    const options = [...openModal];
    options[optionSelected] = true;
    setOpenModal(options);
  };

  const closeModal = (option: number) => {
    const options = [...openModal];
    options[option] = false;
    setOpenModal(options);
  };

  const messageDeleteFiles = useMemo(()=>{
    let message = '';
    if (stateContent.contentSelected === ContentManagementType.Video) {
      message = t("content.messageDeleteVideo")
    } else if (stateContent.contentSelected === ContentManagementType.Project) {
      message = t("content.messageDeleteProject")
    } else {
      message = t("modalConfirm.descriptionLogout")
    }
    return message;
  }, [stateContent.contentSelected])

  const hideMasiveOptions = () => {
    return (stateContent.contentSelected === ContentManagementType.Dashboard ||
        stateContent.contentSelected === ContentManagementType.Template) &&
        (isDetail && stateContent.document.data && !isStaffByDocument(stateContent.document.data.id));
  }

  return (
    <>
    {!hideMasiveOptions() &&
        <div className="ml-auto">
            <LmButton
            styleButton="secondary"
            fullWidth={false}
            buttonType="submit"
            type="solid"
            size="small"
            icon={<Dots />}
            iconPosition="center"
            clickAction={handleClick}
            />
        </div>
      }

      <Menu
        id="basic-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "0.5rem" }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {" "}
        {(stateContent.contentSelected === ContentManagementType.Document || stateContent.contentSelected === ContentManagementType.Video) && (
          <MenuItem onClick={() => handleClose(0)} className="flex gap-2">
            <LmIcon size="sm" icon={<ArrowDown />} />
            <span>{t("content.download")}</span>
          </MenuItem>
        )}
        {isDetail && stateContent.contentSelected === ContentManagementType.Project && (
          <MenuItem
            onClick={() => {
              dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_MATCH_STATISTICS });
              navigate(`/${ROUTE_PRIVATE_MATCH_STATISTICS}/${stateContent.document.data?.id}`);
            }}
            className="flex gap-2">
            <LmIcon size="sm" icon={<ViewStats />} />
            <span>{t("content.viewStats")}</span>
          </MenuItem>
        )}
        {((!isDetail && isStaffByOrg) || isDetailAndStaff) && (
          <MenuItem onClick={() => handleClose(1)} className="flex gap-2">
            <LmIcon size="sm" icon={<Star />} />
            <span>{t("content.changeCategory")}</span>
          </MenuItem>
        )}
        {!isDetail && isStaffByOrg && (
          <MenuItem onClick={() => handleClose(2)} className="flex gap-2">
            <LmIcon size="sm" icon={<Share />} />
            <span>{t("content.share")}</span>
          </MenuItem>
        )}
        {!isDetail && isStaffByOrg && (
          <MenuItem onClick={() => handleClose(3)} className="flex gap-2">
            <LmIcon size="sm" icon={<Tag />} />
            <span>{t("content.addTag")}</span>
          </MenuItem>
        )}
        {((!isDetail && isStaffByOrg) || isDetailAndStaff)  && (
            <MenuItem onClick={() => handleClose(4)} className="flex gap-2">
              <LmIcon size="sm" icon={<Trash />} />
              <span>{t("content.delete")}</span>
            </MenuItem>
          )}
      </Menu>
      <LmModalInfo
        id="test-modal-info-download-files"
        title={t("content.titleDownloadFilesOk")}
        description={t("content.messageDownloadFilesOk")}
        textConfirm={t("modalConfirm.textConfirm")}
        open={openModal[0]}
        handleClose={() => closeModal(0)}></LmModalInfo>
      <LmModalChangeFileCategory open={openModal[1]} handleClose={() => closeModal(1)} />
      <LmModalShareFile open={openModal[2]} handleClose={() => closeModal(2)} />
      <LmModalAddTag open={openModal[3]} handleClose={() => closeModal(3)} />

      <LmModalInfo
        id="test-modal-info-delete-files"
        title={t("content.titleDeleteFilesOk")}
        description={t("content.messageDeleteFilesOk")}
        textConfirm={t("modalConfirm.textConfirm")}
        open={openModalInfoDelete}
        handleClose={() => setOpenModalInfoDelete(false)}></LmModalInfo>

      <LmModalConfirm
        id="test-modal-confirm-delete-files"
        title={t("content.titleDeleteFilesOk")}
        description={messageDeleteFiles}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModal[4]}
        handleClose={() => closeModal(4)}
        confirm={deleteFiles}></LmModalConfirm>

      <LmModalConfirm
        id="test-modal-confirm-delete-project-files"
        title={t("content.titleDeleteFilesOk")}
        description={t('content.messageDeleteProjectVideo')}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalDeleteProject}
        handleClose={()=>deleteProject(false)}
        confirm={()=>deleteProject(true)}></LmModalConfirm>

      <ModalError
        title={t("errors.errorMasiveOperationsTitle")}
        message={openModalWarning.message}
        open={openModalWarning.open}
        handleClose={() => setOpenModalWarning({ open: false, message: "" })}
      />

      <LmModalConfirm
        id="test-modal-confirm-files-allowed"
        title={t("modalConfirm.titleFilesAllowed")}
        description={t("modalConfirm.descriptionFilesAllowed")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalFilesAllowed}
        handleClose={() => setOpenModalfilesAllowed(false)}
        confirm={operationFilesAllowed}></LmModalConfirm>
    </>
  );
};
export default MasiveOptions;
