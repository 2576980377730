import { useSelector } from "react-redux";
import { Period, ProjectCategoryDto, ProjectDto, Ref, TeamType, Timeline } from "../../model/dto/ProjectDto";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { getProjectDataTeamPlayer } from "../../utils/utils";
import StatsComparisonItem from "../stats-comparison-item/StatsComparisonItem";
import { MainState } from "../../model/states/MainState";

interface Props {
  selectedPeriod: string;
  eventsCategory: ProjectCategoryDto[];
  periods: Period[];
  localId: string;
  visitorId: string;
}

const MatchStatisticViewer = ({ selectedPeriod, eventsCategory, periods, localId, visitorId }: Props) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);

  const getTimelinesLength = (timelines: Timeline[], type: TeamType): number => {
    const filteredTimelines = getFilteredTimelines(timelines); 
        
    return filteredTimelines.filter((timeline: Timeline) => {     
      let teams = timeline.Players.map((player: Ref) => getProjectDataTeamPlayer(player?.$ref, stateContent.project.data as ProjectDto).$id) 
      if (teams.length === 0 && timeline.Teams) {
        teams = timeline.Teams.map((team) => team.$ref)
      }  
      return teams.includes(type === TeamType.LOCAL ? localId : visitorId)
    }).length
    
  }

  const getPercent = (timelines: Timeline[], type: TeamType) => {
    const total = getFilteredTimelines(timelines);
    if(!total) {
      return 0;
    }

    const teamTimelines = getTimelinesLength(timelines, type);
    const percentage = Math.floor((100 * teamTimelines) / total.length).toFixed(1);
    if(!percentage || isNaN(+percentage)) {
      return 0;
    }
    return +percentage;
  }

  const getFilteredTimelines = (timelines: Timeline[]) => {   
    const filteredTimelines = timelines
    
    if(selectedPeriod === "All") {
      return filteredTimelines;
    } 

    const period = periods.find(period => period.Name === selectedPeriod);
    const start = period?.Nodes[0].Start ?? 0;
    const end = period?.Nodes[0].Stop ?? -1;
    return filteredTimelines.filter(timeline => timeline.EventTime >= start && timeline.EventTime < end);
  }
  
  return (
    <>
      { eventsCategory.filter(event => event.name).map(event => (
        <div key={event.event} className="mb-4">
          <StatsComparisonItem             
            statName={event.name} 
            numberA={getTimelinesLength(event.timelines, TeamType.LOCAL)} 
            percentA={getPercent(event.timelines, TeamType.LOCAL)} 
            numberB={getTimelinesLength(event.timelines, TeamType.VISITOR)} 
            percentB={getPercent(event.timelines, TeamType.VISITOR)} />
        </div>
      ))}
    </>
  )
}

export default MatchStatisticViewer;