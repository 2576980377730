import * as Yup from "yup";
import { t } from "i18next";
import { regexEmailFormat, regexNumber } from "../../constants/globalConstanst";
import PlayerResultDto from "../../model/dto/PlayerResultDto";
import { dateFormat } from "../../utils/utils";

export function playerInitialValues(player: PlayerResultDto | null) {
  return {
    name: player?.name || "",
    lastName: player?.lastName || "",
    nickName: player?.nickName || "",
    countryId: player?.countryId || undefined,
    position: player?.position || undefined,
    number: player?.number || "",
    height: player?.height || "",
    weight: player?.weight || "",
    birthDate: player?.birthDate ? dateFormat(player.birthDate) : "",
    email: player?.email || "",
  };
}

export function playerValidationSchema() {
  const heightError = t("errors.form.heightError");
  const weightError = t("errors.form.weightError");
  return Yup.object({
    name: Yup.string().required(t("errors.form.required") as string),
    lastName: Yup.string().required(t("errors.form.required") as string),
    nickName: Yup.string().required(t("errors.form.required") as string),
    position: Yup.string().required(t("errors.form.required") as string),
    countryId: Yup.string().required(t("errors.form.required") as string),
    height: Yup.string().matches(regexNumber, t("errors.form.passwordFormat") as string).test("greater than 100", heightError, value => parseInt(value as string) <= 100 || !value),
    weight: Yup.string().matches(regexNumber, t("errors.form.passwordFormat") as string).test("greater than 500", weightError, value => parseInt(value as string) <= 500 || !value),
    email: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexEmailFormat, t("errors.form.passwordFormat") as string),
    number: Yup.string()
      .required(t("errors.form.required") as string)
      .test("test-number", t("errors.form.zero") as string, function (value) {
        const numberValue = parseInt(value);
        return numberValue >= 0 && numberValue <= 100;
      }),
    birthDate: Yup.string().test("test-birthDate", t("errors.form.birthDate") as string, function (value) {
      let valid = false;
      if (!value || (value && new Date(value as string) < new Date())) {
        valid = true;
      }
      return valid;
    }),
  });
}
