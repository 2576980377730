export interface Params {
  method: string;
  body?: string | FormData;
  headers: {
    "Content-Type"?: string;
    Authorization?: string;
  };
}

export const getFetchParams = (method: string, body: any, token: string | null | undefined): Params => {
  const params: Params = {
    method: method,
    headers: {
      "Content-Type": "Application/json",
    },
  };
  if (body !== null) {
    params.body = JSON.stringify(body);
  }
  if (token) {
    params.headers.Authorization = "Bearer " + token;
  }

  return params;
};
