import { t } from "i18next";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  ActionContentManagementType,
  ActionTagsLoading,
  ActionTagsPrivateLoading,
  TypeDocumentManagementActions,
} from "../../model/actions/typeContentManagementActions";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import { TagsDto } from "../../model/dto/DocumentDto";
import ResponseApiError from "../../model/dto/ResponseApiError";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getFetchParams } from "./utilsSaga";

const baseUrlApiTags = process.env.REACT_APP_API_URL + "Tags";

export function* tags(action: ActionTagsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);

    const response: Response = yield call(
      fetch,
      baseUrlApiTags + "/" + action.value.organizationId + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TagsDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_TAGS_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_TAGS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* tagsPrivate(action: ActionTagsPrivateLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = baseUrlApiTags + "/" + action.value.organizationId + "?locale=" + state.appLanguage + "&teamId=" + action.value.teamId;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, state.login.loginResponse?.token));
    if (response.ok) {
      const data: TagsDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_TAGS_PRIVATE_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_TAGS_PRIVATE_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* tagsWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_TAGS_LOADING, tags);
}

export function* tagsPrivateWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_TAGS_PRIVATE_LOADING, tagsPrivate);
}

export default function* rootTagsSaga() {
  yield all([tagsWhatcher(), tagsPrivateWhatcher()]);
}
