import React from "react";
import Dialog from "@mui/material/Dialog";
import LmButton from "../button/LmButton";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";

interface IModalInfo {
  id?: string;
  title: string;
  description?: string | null;
  textConfirm: string;
  open: boolean;
  handleClose(): void;
}
const LmModalInfo: React.FC<IModalInfo> = ({ id = "", title = "", description = "", textConfirm = "", open, handleClose }) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-6" data-testid={id}>
        <p className="font-decorative text-decorative text-lg mb-2">{title}</p>
        {description && <p className="text-sm mb-6">{description}</p>}
        <div className="flex justify-end gap-2">
          <LmButton text={textConfirm} type="organization" buttonType="button" clickAction={handleClose}></LmButton>
        </div>
      </div>
    </Dialog>
  );
};
export default LmModalInfo;
