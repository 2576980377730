import LmIcon from "../icon/LmIcon";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import LmAvatar from "../avatar/LmAvatar";
import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";

interface Props {
  id: string;
  avatarImage?: string;
  name: string;
  email: string;
  roles: string;
  teams: string;
  actionEdit?(): void;
  actionDelete?(): void;
}

const UsersListItem = ({ id, avatarImage, name, email, roles, teams, actionEdit, actionDelete }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  return (
    <div data-testid={`user-list-item-${id}`} className="flex items-center gap-4 py-2 md:p-4 md:border-b border-neutral-200">
      {stateMain.sizeScreen !== SizeScreen.DESKTOP && <LmAvatar src={avatarImage} radius="1rem" />}
      <div className="flex flex-wrap lg:grid lg:grid-cols-4 items-center gap-x-4 lg:w-full text-sm text-subtle">
        <div className="w-full lg:col-span-1 flex gap-4 items-center">
          {stateMain.sizeScreen == SizeScreen.DESKTOP && <LmAvatar src={avatarImage} radius="1rem" />}
          <p className="font-semibold text-decorative">{name}</p>
        </div>
        <p className="break-all">{email}</p>
        {stateMain.sizeScreen !== SizeScreen.MOBILE && <p>{roles}</p>}
        {stateMain.sizeScreen !== SizeScreen.MOBILE && <p>{teams}</p>}
      </div>
      <div className="flex justify-end items-center w-14 gap-2 md:gap-4 ml-auto">
        {actionEdit && (
          <LmIcon className="cursor-pointer hover:opacity-80 transition order-1 md:order-none" icon={<Edit />} size="sm" clickAction={actionEdit} />
        )}
        {actionDelete && <LmIcon className="cursor-pointer hover:opacity-80 transition " icon={<Trash />} size="sm" clickAction={actionDelete} />}
      </div>
    </div>
  );
};

export default UsersListItem;
