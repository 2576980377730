import { Buffer } from "buffer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { ReactComponent as LoginLogo } from "../../assets/img/logo-longomatch-white.svg";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import "../login/Login.scss";

function PageError() {
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const erroMessage = Buffer.from(query.get("error") as string, "base64").toString();

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: false, showLateralMenu: false } });
  }, []);

  return (
    <div data-testid="login-page" className="login full-height min-h-screen flex flex-col justify-center items-center p-6">
      <LoginLogo className="md:w-72 w-48 md:mb-24 mb-16" />
      <p className="max-w-md text-error-500 border-2 border-error-500 rounded bg-white/80 p-4">{erroMessage}</p>
    </div>
  );
}

export default PageError;
