import { Divider } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InputError } from "../../assets/icons/input-error.svg";
import EmptyTeams from "../../assets/img/empty-folder.png";
import LmButtonViewAll from "../../components/button-view-all/LmButtonViewAll";
import EmptyDashboard from "../../components/empty/emptyDashboard";
import LmIcon from "../../components/icon/LmIcon";
import LmLastComments from "../../components/last-comments/LmLastComments";
import LmLastDocuments from "../../components/last-documents/LmLastDocuments";
import LmLastNotifications from "../../components/last-notifications/LmLastNotifications";
import LmLastProjects from "../../components/last-projects/LmLastProjects";
import LmLastUsers from "../../components/last-users/LmLastUsers";
import StorageBarMultiple from "../../components/storage-bar/StorageBarMultiple";
import TeamInfoItem from "../../components/team-info-item/TeamInfoItem";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { ContentManagementType } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { TeamState } from "../../model/states/TeamState";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT, ROUTE_PRIVATE_NOTIFICATIONS, ROUTE_PRIVATE_TEAMS, ROUTE_PRIVATE_USERS } from "../../routes/routes";
import { authState } from "../../store/states/authState";
import { getSelectedDataById, isCoachOrHeadCoachByOrganization, isOwner, isStaff } from "../../utils/utils";

const StaffDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateTeam: TeamState = useSelector((state: MainState) => state.team);
  const selectedOrganizationData: OrganizationDto = useMemo(
    () => getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations),
    [stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations]
  );
  const NUMBER_OF_TEAMS = 4;

  useEffect(() => {
    if (!isOwner(authState)) {
      dispatch<TypeActions>({ type: ActionTeamType.ACTION_RESET_DASHBOARD_MAX_STORAGE });
      selectedOrganizationData?.teams
        .filter((team) => isStaff(team, stateAuth))
        .forEach((team) => {
          if (team.storage) {
            dispatch<TypeActions>({ type: ActionTeamType.ACTION_UPDATE_DASHBOARD_MAX_STORAGE, value: team.storage });
          } else {
            dispatch<TypeActions>({ type: ActionTeamType.ACTION_TEAM_SUMARY_LOADING, value: team.id as string });
          }
        });
    }
  }, []);

  const onNavigate = (route: string) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: route });
    navigate(`/${route}`);
  };

  const goToDocuments = (tabOption: number) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT });
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_CONTENT_MANAGEMENT_TAB_SELECTED, value: tabOption });
    navigate(`/${ROUTE_PRIVATE_CONTENT_MANAGEMENT}`);
  };

  const storageComsumed = useMemo(
    () => selectedOrganizationData?.teams.reduce((accumulator, currentValue) => accumulator + (currentValue?.consumedStorage || 0), 0),
    [selectedOrganizationData]
  );
  const comsumedPercent = useMemo(() => (storageComsumed * 100) / (selectedOrganizationData?.license?.storage || 1), [selectedOrganizationData]);

  const maxCapacity = useMemo(
    () =>
      stateTeam.dashboardTotalStorage < (selectedOrganizationData?.license?.storage as number)
        ? stateTeam.dashboardTotalStorage
        : (selectedOrganizationData?.license?.storage as number),
    [stateTeam.dashboardTotalStorage]
  );

  return (
    <div className="flex flex-col xl:flex-row xl:gap-12 mb-5">
      <div className="flex-1">
        <div className="flex justify-between mb-5">
          <p className="font-extrabold ">{t("dashboard.teamSection")}</p>
          <LmButtonViewAll onClick={() => onNavigate(ROUTE_PRIVATE_TEAMS)} />
        </div>
        <div className="grid grid-cols-2 gap-5">
          {selectedOrganizationData?.teams.length ? (
            selectedOrganizationData?.teams
              .filter((team) => isStaff(team, stateAuth))
              .slice(0, NUMBER_OF_TEAMS)
              .map((team) => (
                <TeamInfoItem
                  key={team.id}
                  avatarImage={team.image}
                  titleText={<div>{team.name}</div>}
                  subtitleText={`${team.staff?.length ? team.staff?.length + " " + t("dashboard.members") : t("dashboard.noMembers")} · ${
                    team.players?.length ? team.players?.length + " " + t("dashboard.players") : t("dashboard.noPlayers")
                  }`}
                />
              ))
          ) : (
            <div className="col-span-2">
              <EmptyDashboard text={t("content.emptyCategory")} img={EmptyTeams} />
            </div>
          )}
        </div>
        <Divider sx={{ margin: "1.5rem 0" }} />
        <p className="font-extrabold mb-5 flex gap-1 items-center">
          {t("dashboard.storageSection")}
          {comsumedPercent >= 95 && <LmIcon icon={<InputError />} tooltip={t("summary.maxStorage") as string} size="xs" />}
        </p>
        <StorageBarMultiple
          maxCapacity={isOwner(stateAuth) ? (selectedOrganizationData?.license?.storage as number) : maxCapacity}
          data={selectedOrganizationData?.teams
            .filter((team) => isStaff(team, stateAuth))
            .map((team) => {
              return { name: team.name, value: team?.consumedStorage as number };
            })}
        />
        <Divider sx={{ margin: "1.5rem 0" }} />
        <div className="flex justify-between mb-5">
          <p className="font-extrabold ">{t("dashboard.lasContentSection")}</p>
          <LmButtonViewAll onClick={() => goToDocuments(ContentManagementType.Document)} />
        </div>
        <div className="mb-5">
          <LmLastDocuments />
        </div>
        <div className="flex justify-between mb-5">
          <p className="font-extrabold ">{t("dashboard.lastProjectsSection")}</p>
          <LmButtonViewAll onClick={() => goToDocuments(ContentManagementType.Project)} />
        </div>
        <div className="mb-5">
          <LmLastProjects />
        </div>
      </div>
      <div className="flex-1">
        <p className="font-extrabold mb-5">{t("dashboard.lastPostsSection")}</p>
        <LmLastComments />
        <div className="flex justify-between mb-5">
          <p className="font-extrabold ">{t("dashboard.lastNotificationsSection")}</p>
          <LmButtonViewAll onClick={() => onNavigate(ROUTE_PRIVATE_NOTIFICATIONS)} />
        </div>
        <LmLastNotifications />
        {isCoachOrHeadCoachByOrganization(selectedOrganizationData, stateAuth) && (
          <>
            <div className="flex justify-between mb-5">
              <p className="font-extrabold ">{t("dashboard.lastMembersSection")}</p>
              {isOwner(stateAuth, selectedOrganizationData?.id) && <LmButtonViewAll onClick={() => onNavigate(ROUTE_PRIVATE_USERS)} />}
            </div>
            <LmLastUsers />
          </>
        )}
      </div>
    </div>
  );
};

export default StaffDashboard;
