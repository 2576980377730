import { Avatar, Badge } from "@mui/material";
import React from "react";
import LmIcon from "../icon/LmIcon";
import { ReactComponent as MiniBadge } from "../../assets/icons/mini-badge.svg";

interface IAvatar {
  testid?: string;
  src?: string | undefined;
  variant?: "circular" | "rounded" | "square";
  className?: string;
  badge?: "badge" | "miniBadge";
  alt?: string | null;
  size?: number;
  radius?: string;
  border?: boolean;
  children?: JSX.Element;
  onClick?(): void;
}

const LmAvatar: React.FC<IAvatar> = ({
  testid,
  src,
  variant,
  className,
  badge,
  alt = "",
  size = 40,
  radius = "4px",
  border = false,
  onClick = () => {},
  children,
}) => {
  return badge ? (
    <Badge
      sx={{
        ".MuiBadge-badge": {
          minWidth: "12px",
          height: "12px",
          top: "16%",
          right: "3%",
          padding: "0",
          backgroundColor: badge === "miniBadge" ? "" : "#e89633",
        },
      }}
      overlap="circular"
      badgeContent={badge === "miniBadge" ? <LmIcon size="original" testid={testid} icon={<MiniBadge />} /> : " "}>
      <Avatar
        className={`lm-avatar  ${className ? className : ""}`}
        src={src}
        variant={variant}
        alt={alt as string}
        sx={{ width: size, height: size, borderRadius: radius, display: children ? "block" : "flex", border: border ? "1px solid #e3e5ec" : "none" }}
        onClick={onClick}>
        {children}
      </Avatar>
    </Badge>
  ) : (
    <Avatar
      className={`lm-avatar ${className ? className : ""}`}
      src={src}
      variant={variant}
      alt={alt as string}
      sx={{ width: size, height: size, borderRadius: radius, display: children ? "block" : "flex", border: border ? "1px solid #e3e5ec" : "none" }}
      onClick={onClick}>
      {children}
    </Avatar>
  );
};

export default LmAvatar;
