import CheckInvitationDto from "../dto/CheckInvitationDto";
import Login from "../dto/LoginDto";
import LoginResponse from "../dto/LoginResponseDto";
import OrganizationDto from "../dto/OrganizationDto";
import OrganizationSumaryDto from "../dto/OrganizationSumaryDto";
import OrganizationUserDto from "../dto/OrganizationUserDto";
import { PendingInvitationsUserDto } from "../dto/PendingInvitationsUserDto";
import PlayerResultDto from "../dto/PlayerResultDto";
import StaffDto from "../dto/StaffDto";
import TeamsDto from "../dto/TeamDto";
import TeamGroupResultDto from "../dto/TeamGroupResultDto";
import TeamSumaryDto from "../dto/TeamSumaryDto";
import UserDto from "../dto/UserDto";
import UserInfoDto from "../dto/UserInfoDto";

export enum ActionAuthType {
  ACTION_LOGIN_LOADING = "ACTION_LOGIN_LOADING",
  ACTION_LOGIN_SUCCESS = "ACTION_LOGIN_SUCCESS",
  ACTION_LOGIN_ERROR = "ACTION_LOGIN_ERROR",

  ACTION_RESET_REQUEST_PASSWORD_LOADING = "ACTION_RESET_REQUEST_PASSWORD_LOADING",
  ACTION_RESET_REQUEST_PASSWORD_SUCCESS = "ACTION_RESET_REQUEST_PASSWORD_SUCCESS",
  ACTION_RESET_REQUEST_PASSWORD_ERROR = "ACTION_RESET_REQUEST_PASSWORD_ERROR",

  ACTION_PASSWORD_RESET_LOADING = "ACTION_PASSWORD_RESET_LOADING",
  ACTION_PASSWORD_RESET_SUCCESS = "ACTION_PASSWORD_RESET_SUCCESS",
  ACTION_PASSWORD_RESET_ERROR = "ACTION_PASSWORD_RESET_ERROR",

  ACTION_PASSWORD_CHANGE_LOADING = "ACTION_PASSWORD_CHANGE_LOADING",
  ACTION_PASSWORD_CHANGE_SUCCESS = "ACTION_PASSWORD_CHANGE_SUCCESS",
  ACTION_PASSWORD_CHANGE_ERROR = "ACTION_PASSWORD_CHANGE_ERROR",

  ACTION_INVITATIONS_LOADING = "ACTION_INVITATIONS_LOADING",
  ACTION_INVITATIONS_SUCCESS = "ACTION_INVITATIONS_SUCCESS",

  ACTION_CHECK_INVITATIONS_LOADING = "ACTION_CHECK_INVITATIONS_LOADING",
  ACTION_CHECK_INVITATIONS_SUCCESS = "ACTION_CHECK_INVITATIONS_SUCCESS",
  ACTION_CHECK_INVITATIONS_ERROR = "ACTION_CHECK_INVITATIONS_ERROR",

  ACTION_USER_INFO_LOADING = "ACTION_USER_INFO_LOADING",
  ACTION_USER_INFO_SUCCESS = "ACTION_USER_INFO_SUCCESS",
  ACTION_USER_INFO_ERROR = "ACTION_USER_INFO_ERROR",

  ACTION_GET_LAST_USERS_LOADING = "ACTION_GET_LAST_USERS_LOADING",
  ACTION_GET_LAST_USERS_SUCCESS = "ACTION_GET_LAST_USERS_SUCCESS",
  ACTION_GET_LAST_USERS_ERROR = "ACTION_GET_LAST_USERS_ERROR",

  ACTION_USER_PROFILE_LOADING = "ACTION_USER_PROFILE_LOADING",
  ACTION_USER_PROFILE_SUCCESS = "ACTION_USER_PROFILE_SUCCESS",
  ACTION_USER_PROFILE_ERROR = "ACTION_USER_PROFILE_ERROR",

  ACTION_USER_PROFILE_IMAGE_LOADING = "ACTION_USER_PROFILE_IMAGE_LOADING",
  ACTION_USER_PROFILE_IMAGE_SUCCESS = "ACTION_USER_PROFILE_IMAGE",
  ACTION_USER_PROFILE_IMAGE_ERROR = "ACTION_USER_PROFILE_IMAGE_ERROR",

  ACTION_USER_DESACTIVATE_LOADING = "ACTION_USER_DESACTIVATE_LOADING",
  ACTION_USER_DESACTIVATE_SUCCESS = "ACTION_USER_DESACTIVATE_SUCCESS",
  ACTION_USER_DESACTIVATE_ERROR = "ACTION_USER_DESACTIVATE_ERROR",

  ACTION_CHECK_STAY_LOGGED = "ACTION_CHECK_STAY_LOGGED",

  ACTION_CHANGE_USER_ORGANIZATION_ROLE = "ACTION_CHANGE_USER_ORGANIZATION_ROLE",

  ACTION_CHANGE_APP_USER_LANGUAGE = "ACTION_CHANGE_APP_USER_LANGUAGE",

  ACTION_LOGOUT = "ACTION_LOGOUT",

  ACTION_DELETE_STATE_ORGANIZATION = "ACTION_DELETE_STATE_ORGANIZATION",
  ACTION_UNSUBSCRIBE_STATE_ORGANIZATION = "ACTION_UNSUBSCRIBE_STATE_ORGANIZATION",

  ACTION_STATE_SELECT_ORGANIZATION = "ACTION_STATE_SELECT_ORGANIZATION",
  ACTION_STATE_SELECT_LICENSE = "ACTION_STATE_SELECT_LICENSE",
  ACTION_STATE_SELECT_TEAM = "ACTION_STATE_SELECT_TEAM",
  ACTION_STATE_UPDATE_ORGANIZATION = "ACTION_STATE_UPDATE_ORGANIZATION",
  ACTION_STATE_CREATE_ORGANIZATION = "ACTION_STATE_CREATE_ORGANIZATION",

  ACTION_STATE_GET_ORGANIZATION_USERS = "ACTION_STATE_GET_ORGANIZATION_USERS",
  ACTION_STATE_UPDATE_ORGANIZATION_USER = "ACTION_STATE_UPDATE_ORGANIZATION_USER",
  ACTION_STATE_DELETE_ORGANIZATION_USER = "ACTION_STATE_DELETE_ORGANIZATION_USER",

  ACTION_STATE_UPDATE_TEAM = "ACTION_STATE_UPDATE_TEAM",
  ACTION_STATE_CREATE_TEAM = "ACTION_STATE_CREATE_TEAM",
  ACTION_STATE_DELETE_TEAM = "ACTION_STATE_DELETE_TEAM",

  ACTION_STATE_GET_PENDING_INVITATIONS = "ACTION_STATE_GET_PENDING_INVITATIONS",

  ACTION_STATE_UPDATE_TEAM_GROUP = "ACTION_STATE_UPDATE_TEAM_GROUP",
  ACTION_STATE_CREATE_TEAM_GROUP = "ACTION_STATE_CREATE_TEAM_GROUP",
  ACTION_STATE_DELETE_TEAM_GROUP = "ACTION_STATE_DELETE_TEAM_GROUP",

  ACTION_STATE_UPDATE_PLAYER = "ACTION_STATE_UPDATE_PLAYER",
  ACTION_STATE_CREATE_PLAYER = "ACTION_STATE_CREATE_PLAYER",
  ACTION_STATE_DELETE_PLAYER = "ACTION_STATE_DELETE_PLAYER",

  ACTION_STATE_UPDATE_STAFF = "ACTION_STATE_UPDATE_STAFF",
  ACTION_STATE_CREATE_STAFF = "ACTION_STATE_CREATE_STAFF",
  ACTION_STATE_DELETE_STAFF = "ACTION_STATE_DELETE_STAFF",

  ACTION_STATE_GET_TEAM_GROUPS = "ACTION_STATE_GET_TEAM_GROUPS",

  ACTION_STATE_PLAYER_BY_ORGANIZATION = "ACTION_STATE_PLAYER_BY_ORGANIZATION",
  ACTION_STATE_PLAYER_BY_ORGANIZATION_UNREGISTERED = "ACTION_STATE_PLAYER_BY_ORGANIZATION_UNREGISTERED",

  ACTION_OPTION_MENU_SELECTED = "ACTION_OPTION_MENU_SELECTED",

  ACTION_STATE_CHANGE_LOCALE = "ACTION_STATE_CHANGE_LOCALE",

  ACTION_STATE_GROUPS_BY_ORGANIZATION = "ACTION_STATE_GROUPS_BY_ORGANIZATION",
  ACTION_STATE_UPDATE_PROFILE_IMAGE = "ACTION_STATE_UPDATE_PROFILE_IMAGE",
  ACTION_STATE_UPDATE_PROFILE_DATA = "ACTION_STATE_UPDATE_PROFILE_DATA",

  RESEND_PENDING_INVITATION_LOADING = "RESEND_PENDING_INVITATION_LOADING",
  RESEND_PENDING_INVITATION_SUCCESS = "RESEND_PENDING_INVITATION_SUCCESS",
  RESEND_PENDING_INVITATION_ERROR = "RESEND_PENDING_INVITATION_ERROR",
}

export interface ActionLoginLoading {
  type: ActionAuthType.ACTION_LOGIN_LOADING;
  value: Login;
}

export interface ActionLoginSuccess {
  type: ActionAuthType.ACTION_LOGIN_SUCCESS;
  value: LoginResponse;
}

export interface ActionLoginError {
  type: ActionAuthType.ACTION_LOGIN_ERROR;
  value: string;
}

export interface ActionResetRequestPasswordLoading {
  type: ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_LOADING;
  value: {
    email: string;
    message: string;
  };
}

export interface ActionResetRequestPasswordSuccess {
  type: ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_SUCCESS;
  value: boolean;
}

export interface ActionResetRequestPasswordError {
  type: ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_ERROR;
}

export interface ActionPasswordResetLoading {
  type: ActionAuthType.ACTION_PASSWORD_RESET_LOADING;
  value: {
    data: {
      code: string;
      newPassword: string;
    };
    message: string;
  };
}

export interface ActionPasswordResetSuccess {
  type: ActionAuthType.ACTION_PASSWORD_RESET_SUCCESS;
  value: boolean;
}

export interface ActionPasswordResetError {
  type: ActionAuthType.ACTION_PASSWORD_RESET_ERROR;
}

export interface ActionInvitationsLoading {
  type: ActionAuthType.ACTION_INVITATIONS_LOADING;
  value: {
    data: {
      invitationId: string;
      password: string;
    };
    message: string;
  };
}

export interface ActionCheckInvitationsLoading {
  type: ActionAuthType.ACTION_CHECK_INVITATIONS_LOADING;
  value: string;
}

export interface ActionCheckInvitationsSuccess {
  type: ActionAuthType.ACTION_CHECK_INVITATIONS_SUCCESS;
  value: CheckInvitationDto;
}

export interface ActionCheckInvitationsError {
  type: ActionAuthType.ACTION_CHECK_INVITATIONS_ERROR;
}

export interface ActionChangePasswordLoading {
  type: ActionAuthType.ACTION_PASSWORD_CHANGE_LOADING;
  value: {
    data: {
      oldPassword: string;
      newPassword: string;
    };
    message: string;
  };
}

export interface ActionChangePasswordSuccess {
  type: ActionAuthType.ACTION_PASSWORD_CHANGE_SUCCESS;
  value: boolean;
}

export interface ActionChangePasswordError {
  type: ActionAuthType.ACTION_PASSWORD_CHANGE_ERROR;
}

export interface ActionUserInfoLoading {
  type: ActionAuthType.ACTION_USER_INFO_LOADING;
}

export interface ActionUserInfoSuccess {
  type: ActionAuthType.ACTION_USER_INFO_SUCCESS;
  value: UserInfoDto;
}

export interface ActionUserInfoError {
  type: ActionAuthType.ACTION_USER_INFO_ERROR;
  value: string;
}

export interface ActionGetLastUsersLoading {
  type: ActionAuthType.ACTION_GET_LAST_USERS_LOADING;
  value: {
    organizationId: string;
    top: number;
  };
}

export interface ActionGetLastUsersSuccess {
  type: ActionAuthType.ACTION_GET_LAST_USERS_SUCCESS;
  value: OrganizationUserDto[];
}

export interface ActionGetLastUsersError {
  type: ActionAuthType.ACTION_GET_LAST_USERS_ERROR;
}

export interface ActionUserProfileLoading {
  type: ActionAuthType.ACTION_USER_PROFILE_LOADING;
  value: UserDto;
}

export interface ActionUserProfileSuccess {
  type: ActionAuthType.ACTION_USER_PROFILE_SUCCESS;
  value: { data: UserDto; ok: true };
}

export interface ActionUserProfileError {
  type: ActionAuthType.ACTION_USER_PROFILE_ERROR;
  value: string;
}

export interface ActionUserProfileImageLoading {
  type: ActionAuthType.ACTION_USER_PROFILE_IMAGE_LOADING;
  value: { id: string; image: string; defaultLanguage: string };
}

export interface ActionUserProfileImageSuccess {
  type: ActionAuthType.ACTION_USER_PROFILE_IMAGE_SUCCESS;
  value: { ok: boolean; image: string };
}
export interface ActionUserProfileImageError {
  type: ActionAuthType.ACTION_USER_PROFILE_IMAGE_ERROR;
}
export interface ActionCheckStayLogged {
  type: ActionAuthType.ACTION_CHECK_STAY_LOGGED;
  value: boolean;
}

export interface ActionChangeUserOrganizationRole {
  type: ActionAuthType.ACTION_CHANGE_USER_ORGANIZATION_ROLE;
  value: OrganizationDto;
}

export interface ActionLogout {
  type: ActionAuthType.ACTION_LOGOUT;
}

export interface ActionUserDesactivateLoading {
  type: ActionAuthType.ACTION_USER_DESACTIVATE_LOADING;
}

export interface ActionUserDesactivateSuccess {
  type: ActionAuthType.ACTION_USER_DESACTIVATE_SUCCESS;
}

export interface ActionUserDesactivateError {
  type: ActionAuthType.ACTION_USER_DESACTIVATE_ERROR;
}
export interface ActionDeleteStateOrganization {
  type: ActionAuthType.ACTION_DELETE_STATE_ORGANIZATION;
  value: string;
}
export interface ActionUnsubscribeStateOrganization {
  type: ActionAuthType.ACTION_UNSUBSCRIBE_STATE_ORGANIZATION;
  value: string;
}
export interface ActionStateSelectOrganization {
  type: ActionAuthType.ACTION_STATE_SELECT_ORGANIZATION;
  value: OrganizationDto;
}

export interface ActionStateSelectLicense {
  type: ActionAuthType.ACTION_STATE_SELECT_LICENSE;
  value: string;
}

export interface ActionStateSelectTeam {
  type: ActionAuthType.ACTION_STATE_SELECT_TEAM;
  value: string;
}

export interface ActionOptionMenuSelected {
  type: ActionAuthType.ACTION_OPTION_MENU_SELECTED;
  value: string;
}

export interface ActionStateUpdateOrganization {
  type: ActionAuthType.ACTION_STATE_UPDATE_ORGANIZATION;
  value: OrganizationSumaryDto | OrganizationDto;
}

export interface ActionStateUpdateOrganizationUser {
  type: ActionAuthType.ACTION_STATE_UPDATE_ORGANIZATION_USER;
  value: OrganizationUserDto;
}

export interface ActionStateGetOrganizationUsers {
  type: ActionAuthType.ACTION_STATE_GET_ORGANIZATION_USERS;
  value: OrganizationUserDto[];
}

export interface ActionStateDeleteOrganizationUser {
  type: ActionAuthType.ACTION_STATE_DELETE_ORGANIZATION_USER;
  value: string;
}

export interface ActionStateCreateOrganization {
  type: ActionAuthType.ACTION_STATE_CREATE_ORGANIZATION;
  value: OrganizationSumaryDto | OrganizationDto;
}

export interface ActionStateUpdateTeam {
  type: ActionAuthType.ACTION_STATE_UPDATE_TEAM;
  value: TeamsDto | TeamSumaryDto;
}

export interface ActionStateCreateTeam {
  type: ActionAuthType.ACTION_STATE_CREATE_TEAM;
  value: TeamsDto | TeamSumaryDto;
}

export interface ActionStateDeleteTeam {
  type: ActionAuthType.ACTION_STATE_DELETE_TEAM;
  value: string;
}

export interface ActionStateUpdateTeamGroup {
  type: ActionAuthType.ACTION_STATE_UPDATE_TEAM_GROUP;
  value: TeamGroupResultDto;
}

export interface ActionStateCreateTeamGroup {
  type: ActionAuthType.ACTION_STATE_CREATE_TEAM_GROUP;
  value: TeamGroupResultDto;
}

export interface ActionStateGetPendingInvitations {
  type: ActionAuthType.ACTION_STATE_GET_PENDING_INVITATIONS;
  value: PendingInvitationsUserDto[];
}

export interface ActionStateDeleteTeamGroup {
  type: ActionAuthType.ACTION_STATE_DELETE_TEAM_GROUP;
  value: string;
}

export interface ActionStateUpdatePlayer {
  type: ActionAuthType.ACTION_STATE_UPDATE_PLAYER;
  value: { data: PlayerResultDto; group: string[] };
}

export interface ActionStateCreatePlayer {
  type: ActionAuthType.ACTION_STATE_CREATE_PLAYER;
  value: { data: PlayerResultDto; group: string[] };
}

export interface ActionStateDeletePlayer {
  type: ActionAuthType.ACTION_STATE_DELETE_PLAYER;
  value: { playerId: string; teamId: string };
}

export interface ActionStateUpdateStaff {
  type: ActionAuthType.ACTION_STATE_UPDATE_STAFF;
  value: StaffDto;
}

export interface ActionStateCreateStaff {
  type: ActionAuthType.ACTION_STATE_CREATE_STAFF;
  value: StaffDto[];
}

export interface ActionStateDeleteStaff {
  type: ActionAuthType.ACTION_STATE_DELETE_STAFF;
  value: { userId: string; teamId: string };
}

export interface ActionStatePlayerByOrganization {
  type: ActionAuthType.ACTION_STATE_PLAYER_BY_ORGANIZATION;
  value: PlayerResultDto[];
}

export interface ActionStatePlayerByOrganizationUnregistered {
  type: ActionAuthType.ACTION_STATE_PLAYER_BY_ORGANIZATION_UNREGISTERED;
  value: PlayerResultDto[];
}

export interface ActionStateGetTeamGroups {
  type: ActionAuthType.ACTION_STATE_GET_TEAM_GROUPS;
  value: { data: TeamGroupResultDto[]; teamId: string };
}

export interface ActionStateChangeLocale {
  type: ActionAuthType.ACTION_STATE_CHANGE_LOCALE;
  value: string;
}

export interface ActionStateGroupsByOrganization {
  type: ActionAuthType.ACTION_STATE_GROUPS_BY_ORGANIZATION;
  value: TeamGroupResultDto[];
}
export interface ActionStateUpdateProfileImage {
  type: ActionAuthType.ACTION_STATE_UPDATE_PROFILE_IMAGE;
  value: string;
}

export interface ActionStateUpdateProfileData {
  type: ActionAuthType.ACTION_STATE_UPDATE_PROFILE_DATA;
  value: UserDto;
}

export interface ActionResendPendingInvitationLoading {
  type: ActionAuthType.RESEND_PENDING_INVITATION_LOADING;
  value: string;
}

export interface ActionResendPendingInvitationSuccess {
  type: ActionAuthType.RESEND_PENDING_INVITATION_SUCCESS;
}

export interface ActionResendPendingInvitationError {
  type: ActionAuthType.RESEND_PENDING_INVITATION_ERROR;
}

export interface ActionChangeAppUserLanguage {
  type: ActionAuthType.ACTION_CHANGE_APP_USER_LANGUAGE;
  value: OrganizationDto;
}

export type TypeAuthActions =
  | ActionLoginLoading
  | ActionLoginSuccess
  | ActionLoginError
  | ActionChangePasswordLoading
  | ActionChangePasswordSuccess
  | ActionChangePasswordError
  | ActionLogout
  | ActionCheckStayLogged
  | ActionResetRequestPasswordLoading
  | ActionResetRequestPasswordSuccess
  | ActionResetRequestPasswordError
  | ActionPasswordResetLoading
  | ActionPasswordResetSuccess
  | ActionPasswordResetError
  | ActionInvitationsLoading
  | ActionUserInfoLoading
  | ActionUserInfoSuccess
  | ActionUserInfoError
  | ActionUserProfileLoading
  | ActionUserProfileSuccess
  | ActionUserProfileError
  | ActionChangeUserOrganizationRole
  | ActionUserProfileImageLoading
  | ActionUserProfileImageSuccess
  | ActionUserProfileImageError
  | ActionUserDesactivateLoading
  | ActionUserDesactivateSuccess
  | ActionUserDesactivateError
  | ActionDeleteStateOrganization
  | ActionStateSelectOrganization
  | ActionStateSelectLicense
  | ActionOptionMenuSelected
  | ActionStateUpdateOrganization
  | ActionStateCreateOrganization
  | ActionStateUpdateTeam
  | ActionStateCreateTeam
  | ActionStateDeleteTeam
  | ActionStateUpdatePlayer
  | ActionStateCreatePlayer
  | ActionStateDeletePlayer
  | ActionStateUpdateStaff
  | ActionStateCreateStaff
  | ActionStateDeleteStaff
  | ActionStateSelectTeam
  | ActionStateUpdateTeamGroup
  | ActionStateCreateTeamGroup
  | ActionStateDeleteTeamGroup
  | ActionStatePlayerByOrganization
  | ActionUnsubscribeStateOrganization
  | ActionCheckInvitationsLoading
  | ActionCheckInvitationsSuccess
  | ActionCheckInvitationsError
  | ActionStateUpdateOrganizationUser
  | ActionStateGetOrganizationUsers
  | ActionStateDeleteOrganizationUser
  | ActionStateGetPendingInvitations
  | ActionStateGetTeamGroups
  | ActionStateChangeLocale
  | ActionStateGroupsByOrganization
  | ActionStateUpdateProfileImage
  | ActionStateUpdateProfileData
  | ActionStatePlayerByOrganizationUnregistered
  | ActionGetLastUsersLoading
  | ActionGetLastUsersSuccess
  | ActionGetLastUsersError
  | ActionResendPendingInvitationLoading
  | ActionResendPendingInvitationSuccess
  | ActionResendPendingInvitationError
  | ActionChangeAppUserLanguage;
