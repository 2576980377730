import { changeLanguage, t } from "i18next";
import { ReactComponent as EnIcon } from "../assets/icons/languages/en.svg";
import { ReactComponent as EsIcon } from "../assets/icons/languages/es.svg";
import Analyst from "../assets/img/Analyst.svg";
import Coach from "../assets/img/Coach.svg";
import Consumer from "../assets/img/Consumer.svg";
import HeadCoach from "../assets/img/HeadCoach.svg";
import { DEFAULT_IMAGE } from "../constants/globalConstanst";
import { FormatDateString } from "../model/FormatDateString";
import { NotificationsType } from "../model/NotificationsType";
import { PositionsPlayer } from "../model/PositionsPlayer";
import { ReadType } from "../model/ReadType";
import { RolesUserOrganizationType } from "../model/RolesType";
import { UserRolesByTeam } from "../model/UserRolesByTeam";
import CountryDto from "../model/dto/CountryDto";
import CreateOrganizationDto from "../model/dto/CreateOrganizationDto";
import DocumentDto from "../model/dto/DocumentDto";
import LicenseDto from "../model/dto/LicensesDto";
import OrganizationDto from "../model/dto/OrganizationDto";
import OrganizationUserDto from "../model/dto/OrganizationUserDto";
import { ProjectDto, Ref, Timeline, EventType } from "../model/dto/ProjectDto";
import TeamDto, { UpdateTeam } from "../model/dto/TeamDto";
import { IdValue } from "../model/forms/IdValue";
import { OrganizationMainDataForm } from "../model/forms/OrganizationForm";
import { StaffForm } from "../model/forms/StaffForm";
import { TeamForm } from "../model/forms/TeamForm";
import { UserForm } from "../model/forms/UserForm";
import { AuthState } from "../model/states/AuthState";
import { TypeOfFile } from "../model/states/ContentManagementState";

export function getAllLocales(): IdValue[] {
  return [
    {
      id: "es",
      value: t("languages.es"),
      icon: <EsIcon />,
    },
    {
      id: "en",
      value: t("languages.en"),
      icon: (
        <div>
          <EnIcon />
        </div>
      ),
    },
  ];
}

export function changeLocale(locale: string) {
  changeLanguage(locale);
}

export function getLocaleByUserOrganization(userId: string, organizationUsers?: OrganizationUserDto[]): string {
  const userLocale = organizationUsers?.find((user) => user.userId === userId)?.language;

  return userLocale ?? "en";
}

export function formatDate(date: string): string {
  if (date) {
    const newDate = new Date(date);
    return newDate.toLocaleString("es-ES", { year: "numeric", month: "2-digit", day: "2-digit" });
  }
  return "";
}

export function formatLocaleDate(date: string, locale: string): string {
  if (date) {
    const newDate = new Date(date);
    return newDate.toLocaleString(locale, { year: "numeric", month: "2-digit", day: "2-digit" });
  }
  return "";
}

export function formatDateAgo(date: string, locale: string) {
  const auxDate = new Date(date);
  const now = new Date();

  if (auxDate.toDateString() !== now.toDateString()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return auxDate.toLocaleDateString(FormatDateString[locale]);
  }

  const diff = now.getTime() - auxDate.getTime();
  const diffMinutes = Math.round(diff / 60000);

  if (diffMinutes < 60) {
    return `${t("time.timeAgo")} ${diffMinutes} ${t("time.minutesAgo")}`;
  } else {
    const diffHours = Math.floor(diffMinutes / 60);

    return `${t("time.timeAgo")} ${diffHours} ${t("time.hoursAgo")}`;
  }
}

export function getReadType(): IdValue[] {
  return [
    { id: ReadType.Read, value: t("readType.read") },
    { id: ReadType.Unread, value: t("readType.unread") },
  ];
}

export function getNotificationsType(): IdValue[] {
  return [
    { id: NotificationsType.Comment, value: t("notificationsType.comment") },
    { id: NotificationsType.Tagged, value: t("notificationsType.tagged") },
    { id: NotificationsType.Shared, value: t("notificationsType.shared") },
    { id: NotificationsType.Storage, value: t("notificationsType.storage") },
    { id: NotificationsType.Users, value: t("notificationsType.users") },
    { id: NotificationsType.DeletedFiles, value: t("notificationsType.deletedFiles") },
    { id: NotificationsType.DeleteUsers, value: t("notificationsType.deleteUsers") },
  ];
}

export function getRoleOptions(): IdValue[] {
  return [
    { id: RolesUserOrganizationType.HeadCoach, value: t("roles.headCoach") },
    { id: RolesUserOrganizationType.Coach, value: t("roles.coach") },
    { id: RolesUserOrganizationType.Analyst, value: t("roles.analyst") },
    { id: `${RolesUserOrganizationType.Player}`, value: t("roles.player") },
  ];
}

export function getRoleOptionsForNewOrganization(): IdValue[] {
  return [
    { id: RolesUserOrganizationType.HeadCoach, value: t("roles.headCoach") },
    { id: RolesUserOrganizationType.Coach, value: t("roles.coach") },
    { id: RolesUserOrganizationType.Analyst, value: t("roles.analyst") },
  ];
}

export function getAllRoleOptions(): IdValue[] {
  return [
    { id: `${RolesUserOrganizationType.HeadCoach}`, value: t("roles.headCoach"), image: HeadCoach },
    { id: `${RolesUserOrganizationType.Coach}`, value: t("roles.coach"), image: Coach },
    { id: `${RolesUserOrganizationType.Analyst}`, value: t("roles.analyst"), image: Analyst },
    { id: `${RolesUserOrganizationType.Player}`, value: t("roles.player"), image: Consumer },
  ];
}

export function getRole(role: string[] | undefined): IdValue[] {
  const roles = getRoleOptions();
  const roleFound = roles.filter((rol) => role?.includes(rol.id));
  if (roleFound) {
    return roleFound;
  } else {
    return [];
  }
}

export function getRoleString(role: string) {
  let result = "";
  const allRoles = getAllRoleOptions();
  const findRole = allRoles.find((item) => item.id === role);
  if (findRole) result = findRole.value;
  return result;
}

export function organizationFormsToDto(organizationForm: OrganizationMainDataForm, licenseData: LicenseDto | null): CreateOrganizationDto {
  return {
    name: organizationForm.name,
    color: organizationForm.color,
    address: organizationForm.address,
    cifVat: organizationForm.cifVat,
    email: organizationForm.email,
    licenseId: licenseData?.id ?? "",
    image: organizationForm.image,
    phone: organizationForm.phone,
    teams: [],
  };
}

export const optionsPositionPlayer = (): IdValue[] => {
  const options: IdValue[] = [];
  for (const value in PositionsPlayer) {
    options.push({ id: value, value: t("player." + value) });
  }
  return options;
};

export const optionsCountries = (countries: CountryDto[]): IdValue[] => {
  return countries.map((country) => {
    return { id: country.id, value: country.name } as IdValue;
  });
};

export function teamFormsToDto(teamForm: TeamForm, image: string | undefined, organization: OrganizationDto, teamId?: string) {
  const team: UpdateTeam = {
    organizationId: organization.id ?? "",
    image: image ?? organization.image,
    name: teamForm.name,
    storage: teamForm.storage === "" || teamForm.storage === null ? null : Number(teamForm.storage),
    category: teamForm.category,
  };
  if (teamId) {
    team.id = teamId;
  }
  return team;
}

export function teamFormsWithStaffToDto(teamForm: TeamForm, staff: StaffForm[], organization: OrganizationDto): TeamDto {
  const staffDto = staff.map((member) => {
    return {
      userEmail: member.email,
      userName: member.name,
      userLastName: member.lastName,
      roles: member.roles,
      avatarImage: member.image || organization.image || DEFAULT_IMAGE,
    };
  });

  return {
    organizationId: organization.id ?? "",
    image: organization.image,
    name: teamForm.name,
    storage: Number(teamForm.storage),
    category: teamForm.category,
    staff: staffDto,
  };
}

export function formatOrganizationUserDtoToUsersListForm(organizationUser: OrganizationUserDto): UserForm {
  const { user, avatarImage, teamUsers, userId } = organizationUser;
  if (user?.name && user?.email) {
    const { name, email, lastName } = user;
    let teams = "";
    let roles = "";
    let teamsTotal: string[] = [];
    let rolesTotal: string[] = [];
    const userRolesByTeam: UserRolesByTeam[] = [];
    if (teamUsers?.length === 1) {
      teams = teamUsers[0].team?.name;
      if (teamUsers[0]?.roles && teamUsers[0]?.roles.length > 1) {
        roles = `${getRoleString(teamUsers[0]?.roles[0])} (+${teamUsers[0]?.roles.length - 1})`;
        rolesTotal = teamUsers[0]?.roles;
      } else {
        roles = getRoleString(teamUsers[0]?.roles[0]);
        rolesTotal = [teamUsers[0]?.roles[0]];
      }
      userRolesByTeam.push({ teamId: teamUsers[0].teamId, teamAvatar: teamUsers[0].avatarImage ?? "", teamName: teams, roles: rolesTotal });
    } else if (teamUsers?.length > 1) {
      teams = `${teamUsers[0].team?.name} (+${teamUsers.length - 1})`;
      const allRolesByTeam: string[] = teamUsers
        .map((user) => {
          userRolesByTeam.push({ teamId: user.teamId, teamAvatar: user.avatarImage ?? "", teamName: user.team.name, roles: user.roles });
          return user.roles;
        })
        .flat();
      let addedRoles = allRolesByTeam.filter((item, index) => allRolesByTeam.indexOf(item) === index);
      rolesTotal = [...addedRoles];
      addedRoles = addedRoles.map((item) => getRoleString(item));
      roles = addedRoles.length > 1 ? `${addedRoles[0]} (+${addedRoles.length - 1})` : addedRoles.join(", ");
    }

    if (teamUsers?.length) {
      const totalTeams = teamUsers.map((team) => team.team?.name);
      teamsTotal = totalTeams;
    }

    return { id: userId, avatarImage, name, lastName, email, roles, teams, rolesTotal, teamsTotal, userRolesByTeam };
  }
  return { id: userId, avatarImage, name: "", lastName: "", email: "", roles: "", teams: "", rolesTotal: [], teamsTotal: [], userRolesByTeam: [] };
}
export const isOwner = (stateAuth: AuthState, selectedOrganization?: string): boolean => {
  const selectedOganization = getSelectedDataById(selectedOrganization ?? stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  return stateAuth.userInfo.data.user.id === selectedOganization?.ownerId;
};

export const anyOwner = (stateAuth: AuthState): boolean => {
  const idUser = stateAuth.userInfo.data.user.id;
  const isOwner = stateAuth.userInfo.data.organizations.find((org) => org.ownerId === idUser);
  return isOwner ? true : false;
};

export const rolesUserTeam = (team: TeamDto, stateAuth: AuthState) => {
  const userId = stateAuth.userInfo.data.user.id;
  const staff = team?.staff?.find((staff) => staff.userId === userId);
  return staff?.roles ?? [];
};

export const isCoach = (team: TeamDto, stateAuth: AuthState) => {
  const rolesUser = rolesUserTeam(team, stateAuth);
  return rolesUser.includes(RolesUserOrganizationType.Coach) || isOwner(stateAuth);
};

export const isAnalyst = (team: TeamDto, stateAuth: AuthState) => {
  const rolesUser = rolesUserTeam(team, stateAuth);
  return rolesUser.includes(RolesUserOrganizationType.Analyst) || isOwner(stateAuth);
};

export const isHeadCoach = (team: TeamDto, stateAuth: AuthState) => {
  const rolesUser = rolesUserTeam(team, stateAuth);
  return rolesUser.includes(RolesUserOrganizationType.HeadCoach) || isOwner(stateAuth);
};

export const isCoachOrHeadCoach = (team: TeamDto, stateAuth: AuthState):boolean => {
  const rolesUser = rolesUserTeam(team, stateAuth);
  return rolesUser.includes(RolesUserOrganizationType.HeadCoach) || rolesUser.includes(RolesUserOrganizationType.Coach) || isOwner(stateAuth);
};

export const isStaff = (team: TeamDto, stateAuth: AuthState): boolean => {
  const rolesUser = rolesUserTeam(team, stateAuth);  
  return (
    rolesUser.includes(RolesUserOrganizationType.HeadCoach) ||
    rolesUser.includes(RolesUserOrganizationType.Coach) ||
    rolesUser.includes(RolesUserOrganizationType.Analyst) ||
    isOwner(stateAuth)
  );
};

export const isStaffRoleIncluded = (roles: string[]): boolean => {
  return (
    roles.includes(RolesUserOrganizationType.HeadCoach) ||
    roles.includes(RolesUserOrganizationType.Coach) ||
    roles.includes(RolesUserOrganizationType.Analyst)
    
  );
}

export const isStaffByOrganization = (organization: OrganizationDto, stateAuth: AuthState) => {
  let isStaffByOrg = false;

  organization?.teams?.forEach((team) => {
    isStaffByOrg = isStaffByOrg || isStaff(team, stateAuth);
  });
  return isStaffByOrg || isOwner(stateAuth);
};

export const isCoachOrHeadCoachByOrganization = (organization: OrganizationDto, stateAuth: AuthState) => {
  let isCoachOrHeadCoachOrg = false;

  organization?.teams?.forEach((team) => {
    isCoachOrHeadCoachOrg = isCoachOrHeadCoachOrg || isCoachOrHeadCoach(team, stateAuth);
  });
  return isCoachOrHeadCoachOrg || isOwner(stateAuth);
};

export const getSelectedDataById = (id: string, collectionList: any[]): any => {
  if (collectionList && collectionList.length > 0) {
    const selectedData = collectionList.find((item: any) => item.id === id);
    return selectedData;
  } else {
    return {};
  }
};

export const dateFormat = (date: string): string => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();

  return `${d.getFullYear()}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`;
};

export const normalizeString = (value: string) => {
  return value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

export const orderDocuments = (docs: DocumentDto[], by: "asc" | "desc", limit?: number): DocumentDto[] => {
  const documents = [...docs];
  if (by === "asc") {
    documents.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  } else {
    documents.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }
  if (limit) return documents.slice(0, limit);
  return documents;
};

export const getBase64Img = (src: string) => {
  return `data:image/png;base64, ${src}`;
};

export const parseColor = (color: string) => {
  return color ? color.substring(0, color.length - 2) : "";
};

export const typesContentStateFiles = () => ["documents", "videos", "playlists", "dashboards", "templates", "projects"];
export const typesContentStateFilesSelected = (option: number): TypeOfFile => typesContentStateFiles()[option] as TypeOfFile;

export const getProjectDataPlayer = (ref: string, project: ProjectDto) => {
  let player = project?.LocalTeamTemplate.List.find((player) => player.$id === ref);
  if (!player) {
    player = project?.VisitorTeamTemplate.List.find((player) => player.$id === ref);
  }
  return player;
};

export const getProjectDataTeamPlayer = (ref: string, project: ProjectDto) => {
  const player = project?.LocalTeamTemplate.List.find(player => player.$id === ref);
  let team = project?.LocalTeamTemplate;
  if (!player) {    
    team = project?.VisitorTeamTemplate
  }
  return team
}
export const isPlayerLocal = (ref: string, project: ProjectDto) => {
  const player = project?.LocalTeamTemplate.List.find(player => player.$id === ref);  
  if (player) {    
    return true;
  }
  return false
}

export const isTeamLocal = (ref: string, project: ProjectDto) => {    
  if (project?.LocalTeamTemplate.$id === ref) {    
    return true;
  }
  return false
}

export const getProjectDataTeam = (ref: string, project: ProjectDto) => {
  return project?.LocalTeamTemplate.$id === ref ? project?.LocalTeamTemplate : project?.VisitorTeamTemplate;
};

export const getProjectResultTag = (tags: any[]) => {
  let result = "";
  const tag = tags.find((tag) => tag.Group !== "");
  if (tag) {
    result = tag.Value;
  }
  return result;
};

export const getColorByEvent = (project: ProjectDto, timeline: Timeline) => {
  const eventType = project?.Dashboard.List.find((item) => (item.EventType as EventType)?.$id === (timeline.EventType as Ref).$ref);
  return (eventType?.EventType as EventType)?.Color
}

export const getTypeByEvent = (project: ProjectDto, timeline: Timeline) => {
  const eventType = project?.Dashboard.List.find((item) => (item.EventType as EventType)?.$id === (timeline.EventType as Ref).$ref);
  return (eventType?.EventType as EventType)?.$type
}

export const getEventProject = (project: ProjectDto, timeline: Timeline) => {
  const eventType = project?.Dashboard.List.find((item) => (item.EventType as EventType)?.$id === (timeline.EventType as Ref).$ref);
  return eventType
}

export const getEventScoreProject = (project: ProjectDto, timeline: Timeline) => {
  const eventType = project?.Dashboard.List.find((item) => (item.EventType as EventType)?.$id === (timeline.EventType as Ref).$ref);
  if ((eventType?.EventType as EventType)?.Score) {
    return (eventType?.EventType as EventType)?.Score
  } 
  return eventType?.Score
}

export const convertMilisecondsToHoursMinutesSeconds = (time: number) => {
  function addZ(n: number) {
    return (n < 10 ? "0" : "") + n;
  }

  let s = time;

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return addZ(hrs) + ":" + addZ(mins) + ":" + addZ(secs) + "." + addZ(ms);
};

export const convertMilisecondsToMinutesSeconds = (time: number) => {
  function addZ(n: number) {
    return (n < 10 ? "0" : "") + n;
  }

  let s = time;

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;

  return addZ(mins) + ":" + addZ(secs);
};

export const getTypeOfDocument = (type: string) => {
  const types = {Document: 0, Video: 1, Playlist: 2, Dashboard: 3, Template: 4, Project: 5};

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return types[type]
}