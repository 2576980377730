import React from "react";
import LmAvatar from "../avatar/LmAvatar";
import LmIcon from "../icon/LmIcon";

interface IAvatarLabelInfo {
  src?: any;
  avatarIcon?: JSX.Element;
  size?: number;
  radius?: string;
  alt?: string;
  label: string;
  info?: string;
  secondLine?: string;
  infoMargin?: string;
  divider?: string;
  icon?: any;
  id?: string;
  textAction?: string;
  onSelect?(id: string): void;
  onClickAction?(id: string): void;
}

const LmAvatarLabelInfoTwoLines: React.FC<IAvatarLabelInfo> = ({
  src,
  avatarIcon,
  size = 30,
  radius = "4px",
  label,
  info,
  secondLine,
  infoMargin = "",
  divider,
  alt = "",
  icon = null,
  id = "",
  textAction,
  onSelect = () => {},
  onClickAction = () => {},
}) => {
  return (
    <div data-testid={id} className="flex items-center gap-2" onClick={() => onSelect(id)}>
      {avatarIcon ? (
        <LmAvatar radius={radius} size={size} src={src} alt={alt}>
          {avatarIcon}
        </LmAvatar>
      ) : (
        <LmAvatar radius={radius} size={size} src={src} alt={alt} />
      )}

      <div className="flex flex-col truncate">
        <p className="text-sm md:flex md:items-center md:gap-1">
          <span className="font-semibold text-decorative truncate" title={label}>
            {label}
          </span>

          {info && (
            <>
              {divider && <span className="hidden md:inline text-subtle">{divider}</span>}
              <span className={`block md:inline-flex text-subtle ${infoMargin}`}>{info}</span>
            </>
          )}
        </p>
        <p className={`block md:inline-flex text-subtle text-xs ${infoMargin}`}>{secondLine}</p>
      </div>

      {icon && (
        <div className="flex items-center gap-1 ml-auto">
          <span className="text-sm cursor-pointer">{textAction}</span>
          <LmIcon className="cursor-pointer hover:opacity-60 transition" icon={icon} size="sm" clickAction={() => onClickAction(id)} />
        </div>
      )}
    </div>
  );
};

export default LmAvatarLabelInfoTwoLines;
