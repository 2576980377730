import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { ReactComponent as CheckCircleFilledIcon } from "../../assets/icons/check-filled.svg";
import { ReactComponent as ErrorIcon } from "../../assets/img/error-dialog.svg";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface IAlertNotification {
  open: boolean;
  severity: AlertColor;
  message: string;
  handleClose(): void;
}

const AlertNotification: React.FC<IAlertNotification> = ({ open, handleClose, severity, message }) => {
  return (
    <Stack spacing={2}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        data-testid="alert-notification">
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="outlined"
          iconMapping={{
            success: <CheckCircleFilledIcon className="w-6 h-6" />,
            error: <ErrorIcon className="w-6 h-6" />,
          }}
          sx={{ backgroundColor: "white" }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
export default AlertNotification;
