import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import LmButton from "../button/LmButton";
import { teamInitialValues, teamValidationSchema } from "./LmTeamForm.data";
import LmInput from "../input/LmInput";
import LmMessageError from "../message-error/LmMessageError";
import { Divider } from "@mui/material";
import LoadImage from "../load-image/LoadImage";
import { ChangeEvent, useState } from "react";
import { ViewMode } from "../../model/ViewMode";
import { TeamState } from "../../model/states/TeamState";
import { useDispatch, useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import { getSelectedDataById, teamFormsToDto } from "../../utils/utils";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import StorageBar from "../storage-bar/StorageBar";
import { regexInteger } from "../../constants/globalConstanst";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";

interface Props {
  onClose(): void;
  viewMode: ViewMode;
}

export function LmTeamForm({ onClose, viewMode }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateTeam: TeamState = useSelector((state: MainState) => state.team);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const formData = stateTeam.teamForm;
  const selectedOrganization: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  const freeStorage = Math.floor(selectedOrganization?.freeStorage || 0)
  const validateStorage =  viewMode === ViewMode.EDIT ? (stateTeam.selectedTeam?.storage || 0) + freeStorage : freeStorage;
  const maxCapacity = ((stateTeam.selectedTeam?.storage as number) > 0 && viewMode === ViewMode.EDIT) ? stateTeam.selectedTeam?.storage : freeStorage;
  const currentCapacity = viewMode === ViewMode.EDIT ? stateTeam.selectedTeam?.consumedStorage as number : 0;  

  const [image, setImage] = useState(viewMode === ViewMode.CREATE ? selectedOrganization.image : stateTeam.selectedTeam?.image);
  const formik = useFormik({
    initialValues: teamInitialValues(viewMode === ViewMode.CREATE ? formData : stateTeam.selectedTeam),
    validationSchema: teamValidationSchema(validateStorage as number),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = async (formValue: any) => {
    if (selectedOrganization) {
      if (viewMode === ViewMode.CREATE) {
        const newTeam = teamFormsToDto(formValue, image, selectedOrganization);
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_CREATE_TEAM_LOADING,
          value: { isOrganizationAssistant: false, dataCallback: { data: newTeam, callBack: onClose } },
        });
      } else {
        const newTeam = teamFormsToDto(formValue, image, selectedOrganization, stateTeam?.selectedTeam?.id);
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_EDIT_TEAM_LOADING,
          value: { data: newTeam, callBack: onClose },
        });
      }
    }
  };

  const changeImage = (image: string) => {
    setImage(image);
  };

  const onChangeStorage = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (regexInteger.test(value)) {      
      formik.setFieldValue("storage", parseInt(value));
    } else if (value === "") {
      formik.setFieldValue("storage", value);
    }     
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="w-full" data-testid="test-lmTeamForm">
        <div className="flex flex-wrap gap-4 justify-between items-center p-4 md:py-2 mb-7" style={{ backgroundColor: "rgba(241, 243, 246, 0.5)" }}>
          <div className="flex items-center">
            <LoadImage img={image} id="image" onChangeImage={changeImage} />
            <p className="text-sm font-semibold ml-4">{t("teams.teamForm.imageSelector")}</p>
          </div>
        </div>

        <div className="flex flex-wrap gap-6">
          <p className="font-decorative text-decorative w-full">{t("teams.teamForm.teamDataSection")}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
            <div>
              <LmInput
                required={true}
                id="name"
                label={t("formLabel.name")}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.name}></LmInput>
              {formik.touched.name && formik.errors.name && <LmMessageError id="name" message={formik.errors.name}></LmMessageError>}
            </div>
            <div>
              <LmInput
                required={true}
                id="category"
                label={t("formLabel.category")}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.category}></LmInput>
              {formik.touched.category && formik.errors.category && <LmMessageError id="category" message={formik.errors.category}></LmMessageError>}
            </div>
          </div>
        </div>

        <Divider sx={{ margin: "1.5rem 0" }} />

        <div className="flex flex-wrap gap-6">
          <p className="font-decorative text-decorative w-full">{t("teams.teamForm.storageSection") + ' (Max: ' + freeStorage + 'GB)'}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full items-center">
            <div>
              <LmInput
                id="storage"                
                blur={formik.handleBlur}
                changeValue={onChangeStorage}
                value={formik.values.storage}
                iconSufix={"GB"}></LmInput>
              {formik.touched.storage && formik.errors.storage && <LmMessageError id="storage" message={formik.errors.storage}></LmMessageError>}
            </div>
            <div className="md:col-span-2 xl:mt-9">
              <StorageBar flex maxCapacity={maxCapacity || 0} currentCapacity={currentCapacity} labelUsed={t("storage.consumed")}/>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-end gap-4 mt-8">
          <LmButton styleButton="secondary" type="solid" clickAction={onClose} size="small" text={`${t("buttonLabel.cancel")}`} iconPosition="right"></LmButton>
          <LmButton
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={viewMode === ViewMode.CREATE ? t("buttonLabel.create") : t("buttonLabel.save")}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
}
