export const esp = {
  title: "Longomatch - es",
  login: {
    title: "Bienvenido al software de análisis de video que ayuda a entrenadores, analistas y atletas",
    stayLogged: "Mantener sesión conectada",
    forgotPassword: "Recordar contraseña",
    button: "Iniciar sesión",
  },
  restorePassword: {
    title: "Restaurar contraseña",
    subtitle1: "Introduzca la dirección de correo electrónico que utilizó en el registro.",
    subtitle2: "Enviaremos un correo con tu usuario y un enlace para resetear la contraseña",
    sendButton: "Enviar",
    backButton: "Volver al login",
  },
  register: {
    passwordInfoTitle: "Características de las contraseñas seguras",
    passwordInfo1: "Al menos 8 caracteres",
    passwordInfo2: "Una mezcla de mayúsculas y minúsculas",
    passwordInfo3: "Una mezcla de letras y números ",
    passwordInfo4: "Contener al menos un carácter especial (# . , ? ! @ $ % ^ & * -)",
    button: "Inicio de sesión",
    resetPassword: "Confirmar contraseña",
  },
  licenseSelector: {
    title: "Bienvenido",
    subtitle: "Seleccione la licencia con la que desea acceder a la aplicación",
    teams: "Equipos",
    createOrganization1: "Cree su organización para su licencia",
    createOrganization2: "comprada el",
  },
  menu: {
    home: "Home",
    users: "Gestión de usuarios",
    teams: "Gestión de Equipos",
    content: "Gestión de contenido",
    notifications: "Notificaciones",
    logOut: "Cerrar sesión",
  },
  topMenu: {
    editProfile: "Editar perfil",
    editPassword: "Editar contraseña",
    altIconUser: "Avatar de usuario",
  },
  pager: {
    of: "de",
  },
  editUser: {
    buttonCancel: "Cancelar",
    buttonSave: "Guardar",
    unsubscribe: "Darse de baja de la organización",
    title: "Editar perfil",
    mentioned: "Cuando se me menciona",
    shared: "Cuando se comparten contenidos conmigo",
    added: "Cuando se añade un comentario a un contenido de mi equipo",
    titleNotifications: "Reciba notificaciones por correo electrónico cuando:",
    messagePasswordChanged: "Contraseña cambiada correctamente",
    messagePasswordCreate: "Contraseña establecida correctamente",
    editPasswordTitle: "Editar contraseña",
    unsubscribeTitle: "Darse de baja de la organización",
    unsubscribeSelect: "Seleccione el tipo de baja",
    unsubscribeOption1: "Darse de baja de esta organización",
    unsubscribeOption2: "No renovar la suscripción",
    unsubscribeOption3: "Darse de baja de la plataforma Longomatch",
    messageUnsubscribeSucess: "Subscripción cancelada con éxito",
    messageEditProfile: "Datos actualizados correctamente",
    messageEditImageProfile: "Imagen de perfil actualizada correctamente",
    messageDesactivateUser: "El usuario ha sido dado de baja en la organización correctamente",
  },
  storage: {
    of: "de",
    used: "utilizados",
    consumed: "consumidos",
  },
  errors: {
    form: {
      required: "Campo obligatorio",
      passwordFormat: "El formato no es correcto",
      passwordRepeat: "Las contraseñas no coinciden",
      storageLimit: "Almacenamiento insuficiente",
      repeatEmail: "Este Email ya está añadido",
      birthDate: "La fecha no puede ser mayor que la actual",
      zero: "El número debe estar entre 0 y 100",
      heightError: "La altura no puede ser mayor que 100",
      weightError: "El peso no puede ser mayor que 500",
    },
    server: "Error al realizar la operación, inténtelo de nuevo más tarde",
    errorImageTitle: "Error en la imagen seleccionada",
    errorImageSize: "El formato de imagen seleccionado no es válido, debe ser uno de los siguientes: .jpg, .jpeg, .png",
    errorImageFormat: "El tamaño de la imagen excede el permitido: 1MB",
    errorInvitation: "La invitación ya ha sido utilizada",
    errorMasiveOperationsTitle: "Operaciones masivas",
    errorMasiveOperationsMessage: "No tiene ningún artículo seleccionado",
    errorMasiveOperationNoFilesAllowed: "No puede realizar esta acción en estos archivos",
  },
  newOrganization: {
    title: "Nueva Organización",
    tab1: "Datos principales",
    tab2: "Equipos",
    continue: "Continuar",
    cancel: "Cancelar",
    create: "Crear",
    loadImageLabel: "Seleccione la imagen para la organización",
    dataSectionTitle: "Datos de la Organización",
    planSectionTitle: "Planes",
    planSectionSubtitle: "Ver características",
    teamSectionTitle: "Detalles del equipo",
    staffSectionTitle: "Cuerpo Técnico",
    addStaff: "Añadir",
    teamsTitle: "Una vez creado el primer equipo, dependiendo de la licencia, podrás crear más equipos posteriormente.",
    noStaffTitle: "Aún no hay un Cuerpo Técnico registrado",
    noStaffDescription: "Puede crear un Cuerpo Técnico aquí",
  },
  teams: {
    editOrganization: "Editar Organización",
    editOrganizationSuccess: "La Organización se ha Actualizado correctamente",
    deleteOrganization: "Eliminar Organización",
    deleteOrganizationSuccess: "La Organización ha sido eliminada",
    deleteOrganizationSubtitle: "¿Está seguro de que desea eliminar esta organización y volver al selector de licencias?",
    deleteTeamTitle: "¿Está seguro de que desea eliminar este Equipo?",
    createTeamGroupSuccess: "El grupo ha sido creado correctamente",
    deleteStaffTitle: "¿Está seguro de que desea eliminar este Usuario?",
    deleteGroupTitle: "¿Está seguro de que desea eliminar este grupo?",
    deleteTeamGroupSuccess: "El grupo ha sido eliminado correctamente",
    editTeamGroupSuccess: "El grupo ha sido actualizado correctamente",
    teamForm: {
      addTitle: "Nuevo Equipo",
      editTitle: "Editar Equipo",
      imageSelector: "Seleccione la imagen para el equipo",
      teamDataSection: "Datos del equipo",
      storageSection: "Almacenamiento",
      createSuccess: "El equipo se ha creado correctamente",
      editSuccess: "El equipo ha sido actualizado correctamente",
      deleteSuccess: "El equipo se ha eliminado correctamente",
    },
  },
  colorSelector: {
    red: "Rojo",
    orange: "Naranja",
    pink: "Rosa",
    purple: "Morado",
    darkBlue: "Azul oscuro",
    lightBlue: "Azul claro",
    darkGreen: "Verde oscuro",
    lightGreen: "Verde claro",
  },
  buttonLabel: {
    addNewTeam: "Añadir equipo",
    cancel: "Cancelar",
    continue: "Continuar",
    save: "Guardar",
    delete: "Eliminar",
    create: "Crear",
    goBack: "Volver atrás",
    filter: "Filtrar",
    pendingInvitation: "Invitaciones pendientes",
    invite: "Invitar",
    clear: "Limpiar",
    search: "Buscar",
    upload: "Subir",
    viewAll: "Ver todos",
    accept: "Aceptar",
  },
  formLabel: {
    name: "Nombre",
    lastName: "Apellidos",
    address: "Dirección",
    cifVat: "CIF/VAT",
    email: "Email",
    phone: "Teléfono",
    currentPassword: "Contraseña actual",
    password: "Contraseña",
    repeatPassword: "Repita contraseña",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirme contraseña",
    license: "Licencia",
    validUntil: "Fecha de expiración",
    storage: "Almacenamiento",
    category: "Categoría",
    role: "Rol",
    nickName: "Alias",
    nationality: "Nacionalidad",
    position: "Posición",
    number: "Número",
    height: "Altura",
    weight: "Peso",
    birthDate: "Fecha de nacimiento",
    group: "Grupo",
    selectEmpty: "nada seleccionado",
    players: "Jugadores",
    morePlayers: "Añadir más jugadores",
    team: "Equipo",
    selectTeam: "Seleccionar equipo",
    description: "Añadir descripción",
    shareWithGroups: "Compartir con",
    tags: "Etiquetas",
    comment: "Comentario",
    language: "Idioma",
    notificationType: "Tipo de notificación",
    read: "Leído",
    dateStart: "Fecha desde",
    dateEnd: "Fecha hasta",
    filterEvenType: "Tipo de evento",
    teamTagged: "Equipo etiquetado",
    playerTagged: "Jugador etiquetado",
    period: "Periodo",
    notes: "Notas",
    commonTags: "Etiquetas Comunes",
    wholeField: "Campo completo",
    midfield: "Medio campo",
    soccerGoal: "Zona de gol",
  },
  modalConfirm: {
    titleLogout: "Vas a abandonar la aplicación",
    descriptionLogout: "¿Estás seguro?",
    titleFilesAllowed: "Ficheros permitidos",
    descriptionFilesAllowed: "Hay ficheros para los que no tienes permiso para realizar la acción seleccionada. ¿Quieres realizar la acción al resto?",
    textCancel: "No",
    textConfirm: "Sí",
    textOk: "Ok",
  },
  summary: {
    summaryTab: "Resumen",
    expireDate: "Fecha de expiración: ",
    playersTab: "Jugadores",
    groupsTab: "Grupos",
    teams: "Equipos",
    plan: "Plan",
    license: "Licencia",
    storage: "Almacenamiento",
    users: "Usuarios",
    members: "Miembros",
    staff: "Cuerpo Técnico",
    invitations: "Invitaciones Pendientes",
    noTeams: "no hay equipos disponibles",
    maxStorage: "Estás a punto de alcanzar el almacenamiento máximo",
  },
  player: {
    emptySection: "No hay jugadores añadidos a este equipo",
    addPlayer: "Añadir jugador",
    newPlayer: "Nuevo jugador",
    editPlayer: "Editar jugador",
    goalkeeper: "Portero",
    defender: "Defensa",
    midfielder: "Centro",
    winger: "Extremo",
    middleForward: "Delantero medio",
    newPlayerForm: {
      createSuccess: "El jugador se ha creado correctamente",
      editSuccess: "Jugador actualizado correctamente",
      deleteSuccess: "Jugador eliminado correctamente",
    },
    deletePlayerTitle: "¿Está seguro de que desea eliminar este Jugador?",
    viewTitle: "{{name}} - Nº {{number}}",
  },
  staff: {
    emptySection: "No se ha añadido un Cuerpo Técnico a este equipo",
    addStaff: "Añadir",
    editStaff: "Editar Cuerpo Técnico",
    createSuccess: "Usuario creado correctamente",
    editSuccess: "Usuario actualizado correctamente",
    deleteSuccess: "Usuario eliminado correctamente",
  },
  group: {
    emptySection: "No hay grupos añadidos a este equipo",
    addGroup: "Nuevo Grupo",
    editGroup: "Editar Grupo",
    deleteGroup: "¿Está seguro de que desea eliminar este Grupo?",
    players: "Jugadores",
  },
  roles: {
    admin: "Admin",
    headCoach: "Entrenador Jefe",
    headcoach: "Entrenador Jefe",
    coach: "Entrenador",
    analyst: "Analista",
    consumer: "Jugador",
    player: "Jugador",
  },
  autocomplete: {
    notFound: "No hay resultados",
  },
  users: {
    name: "Nombre",
    email: "Email",
    role: "Rol",
    team: "Equipo",
    usersTitle: "Gestión de usuarios",
    searchUser: "Nombre de usuario...",
    pendingInvitationsTitle: "Invitaciones pendientes",
    emptyPendingInvitations: "No hay invitaciones pendientes",
    editMemberTitle: "Editar Usuario",
    inviteMemberTitle: "Invitar a un miembro",
    inviteMemberSubtitle: "Introduzca un correo electrónico y asigne al menos un rol por equipo",
    inviteMemberTeams: "Equipos",
    emptyUsers: "No se han encontrado usuarios",
    deleteUserTitle: "Borrar usuario",
    deleteUserSubtitle: "¿Está seguro de que desea eliminar este usuario?",
    deleteUserSuccess: "Usuario eliminado",
    editSuccess: "Usuario actualizado correctamente",
    resendPendingInvitationSuccess: "Invitación reenviada",
    resendPendingInvitation: "Resend",
  },
  content: {
    documentTitle: "Gestión de contenido",
    documentTitlePlayer: "Compartido contigo",
    selectTeam: "Seleccione un equipo",
    lastModification: "Última modificación",
    document: "Documento",
    documents: "Documentos",
    video: "Vídeo",
    videos: "Vídeo",
    dashboard: "Botonera",
    template: "Equipos",
    project: "Proyecto",
    playlist: "Lista de reproducción",
    allTeams: "Todos los equipos",
    documentsPlaceholder: "Buscar {{value}}, nombre de etiqueta...",
    uploadFile: "Cargar archivo",
    download: "Descargar",
    changeCategory: "Cambiar de categoría",
    emptyCategory: "No hay artículos en esta categoría",
    share: "Compartir",
    addTag: "Añadir etiqueta",
    delete: "Eliminar",
    selectAll: "Seleccionar todo",
    items: "Artículos",
    noCategory: "{{value}} sin categoría",
    uploadFileTitle: "Cargar archivo",
    uploadFileDescription: "Seleccione el documento que desea cargar",
    uploadVideoDescription: "Seleccione el video que desea cargar",
    uploadDocument: "Cargar fichero",
    fileUploaded: "Archivo cargado",
    groupsPlaceholder: "Grupo",
    usersPlaceholder: "Usuario",
    modalChangeCategoryTitle: "Cambiar categoría",
    modalChangeCategorySubTitle: "Seleccione una categoría existente o cree una nueva",
    modalChangeAddTagTitle: "Añadir etiqueta",
    modalChangeAddTagSubTitle: "Seleccione o cree una etiqueta",
    messageChangeFileCategoryOk: "Categoría cambiada con éxito",
    messageAddTagToFileOk: "Etiquetas añadidas correctamente",
    messageDownloadFilesOk: "Los archivos solicitados se comprimirán. Recibirá una notificación con un enlace de descarga cuando el proceso esté listo",
    titleDownloadFilesOk: "Descargar archivos",
    messageDeleteFilesOk: "Los archivos se eliminarán. Recibirás una notificación cuando el proceso esté listo",
    titleDeleteFilesOk: "Eliminar archivos",
    messageDeleteCategory: "Categoría eliminada correctamente",
    messageConfirmDeleteCategoryTitle: "Eliminar categoría",
    messageConfirmDeleteCategoryDescription: "La categoría será eliminada, los archivos asociados a esta categoría irán a ninguna categoría",
    messageConfirmDeleteCommentTitle: "Eliminar comentario",
    messageConfirmDeleteCommentDescription: "El comentario será eliminado, las respuestas a este comentario también serán eliminadas",
    messageShareFileOk: "Archivos compartidos correctamente",
    messageDownloadSingleFileInfo: "Descargando el fichero, puede tardar unos minutos dependiendo de su tamaño.",
    messageDownloadSingleFileOk: "Fichero descargado correctamente",
    shareWith: "Compartir con",
    sharedWithMembers: "Compartido con {{value}} miembros",
    sharedBy: "Compartido por",
    stopSharing: "Dejar de compartir",
    messageUnShareFileOk: "Se ha dejado de compartir el fichero",
    detailDocumentMessagesTab: "Mensajes ({{value}})",
    detailDocumentShareTab: "Compartir ({{value}})",
    detailDocumentAnalysis: "Analysis",
    detailDocumentZonalTags: "Zonal Tags",
    detailDocumentPlayList: "Listas de reproducción ({{value}})",
    detailDocumentClips: "Clips ({{value}})",
    publicTag: "Pública",
    privateTag: "Privada",
    uploadedBy: "Subido por",
    answers: "{{ value }} respuestas",
    answer: "1 respuesta",
    reply: "Contestar",
    comment: "Escribe aquí...",
    noComments: "No hay mensajes todavía",
    errorComments: "No se ha encontrado el comentario (Quizás se haya eliminado)",
    playerNumber: "Nº {{ value }}",
    players: "{{value}} jugadores",
    detailMatchStatisticTitle: "Estadísticas",
    detailMatchStatisticGame: {
      game: "Partido",
      all: "Todo",
    },
    detailMatchStatisticPlayer: "Jugador",
    noFilesToShow: "No hay ningún fichero de tipo {{value}}",
    fileNotFound: "El fichero no existe",
    mentionAccessLine1: "Los siguientes usuarios no tienen acceso: ",
    mentionAccessLine2: "¿Desea darles acceso a este contenido?",
    modalGrantAccessTitle: "¡Atención!",
    viewStats: "Ver estadísticas",
    noZonalTag: "No hay información de la tag zonal",
    results: "Results",
    total: "Total",
    staffPlaceholder: "Jugador/Técnico",
    addToPlaylist: "Añadir a una Lista de reproducción",
    addToPlaylistDescription: "Seleccionar una Lista de reproducción existente o crear una nueva",
    addToPlaylistDescription2: "O crea una nueva",
    playlistExistingPlaceholder: "Lista de reproducción existente",
    playlistNewPlaceholder: "Nueva Lista de reproducción",
    evenDetail: "Detalles del evento",
    playedTime: "Tiempo jugado: ",
    points: "Puntos: ",
    admonition: "Amonestaciones: ",
    team: "Equipo",
    playersSelector: "Jugadores",
    category: "Categoría",
    addPlaylistSuccess: "Lista de reproducción actualizada",
    addToPlayListModalInfoTitle: "Añadir a una Lista de reproducción",
    addToPlayListModalInfoDescription: "No hay ningún evento seleccionado",
    play: "Play",
    addPlaylist: "Añadir a la Lista de reproducción",
    viewDetail: "Detalle",
    sharedFileDescription: "Selecciona el usuario o grupo para compartir el proyecto. Si el usuario no existe, se le enviará una invitación a su email",
    uploading: "Subiendo:",
    messageDeleteVideo: "Al eliminar el video, se eliminarán también todos los proyectos asociados y listas de reproducción asociadas. ¿Estas de acuerdo?",
    messageDeleteProjectVideo: "¿Quieres eliminar también el video asociado al proyecto?",
    messageDeleteProject: "Al eliminar el proyecto, se eliminarán también las Listas de reproducción asociadas. ¿Estas de acuerdo?",
    commonTags: "Etiquetas Comunes",
    wholeField: "Campo completo",
    midfield: "Medio campo",
    soccerGoal: "Zona de gol",
  },
  project: {
    category: "Categoría",
  },
  notifications: {
    notificationsTitle: "Notificaciones",
    filters: "Filtros",
    mostRecent: "Más recientes",
    lessRecent: "Menos recientes",
    selected: "Notificaciones seleccionadas:",
    delete: "Eliminar",
    mark: "Marcar como leído",
    unmark: "Marcar como no leído",
    selectAll: "Seleccionar todo",
    unselectAll: "Deseleccionar todo",
    deleteNotificationTitle: "¿Está seguro de que desea eliminar esta notificación?",
    emptyNotifications: "No tiene notificaciones",
    seeAll: "Ver todas",
    unRead: "Últimas notificaciones sin leer",
    emptyNotificationsUnread: "No tiene notificaciones sin leer",
  },
  notificationsType: {
    unknown: "Otros",
    comment: "Comentario",
    tagged: "Etiquetado",
    shared: "Compartido",
    storage: "Almacenamiento",
    users: "Usuarios",
    deletedFiles: "Archivos borrados",
    downloadFiles: "Descarga de archivos",
    deleteUsers: "Usuarios eliminados",
  },
  time: {
    timeAgo: "Hace",
    minutesAgo: "minutos",
    hoursAgo: "horas",
  },
  readType: {
    read: "Leído",
    unread: "No leído",
  },
  dashboard: {
    teamSection: "Equipos",
    storageSection: "Almacenamiento",
    lastPostsSection: "Últimos Comentarios",
    lastNotificationsSection: "Últimas Notificaciones",
    lastDocumentsSection: "Documentos",
    lasContentSection: "Contenido",
    lastProjectsSection: "Proyectos",
    lastMembersSection: "Nuevos Miembros",
    sharedWithYouSection: "Compartido contigo",
    lastMentionsSection: "Últimas Menciones",
    goToComment: "Ir al comentario",
    members: "Miembros",
    noMembers: "Sin miembros",
    players: "Jugadores",
    noPlayers: "Sin jugadores",
  },
  playlist: {
    messageConfirmDeletePlaylistTitle: "Eliminar lista de reproducción",
    messageConfirmDeleteClipTitle: "Eliminar Videoclip",
    messageConfirmDeletePlaylistDescription: "¿Está seguro de que quiere borrar esta lista de reproducción?",
    messageConfirmDeleteClipDescription: "¿Está seguro de que quiere borrar este Videoclip?",
    messageConfirmDeleteClipDescription2:
      "¿Está seguro de que quiere borrar este Videoclip?. Este es el último Videoclip de la Lista de reproducción. Se eliminará también la Lista de reproducción",
    modalInfoPlaylistTitle: "Más información sobre la lista de reproducción",
    tabShareTitle: "Compartir",
    tabCommentsTitle: "Comentarios",
  },
  uploadImage: {
    title: "Cambiar imagen",
    subtitle: "Recomendaciones",
    recommendation1: "Se recomienda utilizar imágenes cuadradas",
    recommendation2: "El tamaño máximo del fichero es de 1 mb",
  },
  languages: {
    es: "Español",
    en: "Inglés",
  },
};
