import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoBlack } from "../../assets/icons/logo-black.svg";
import FreeLicenseCard from "../../components/free-license-card/FreeLicenseCard";
import OrganizationCard from "../../components/organization-card/OrganizationCard";
import { ViewMode } from "../../model/ViewMode";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import LicenseDto from "../../model/dto/LicensesDto";
import OrganizationDto from "../../model/dto/OrganizationDto";
import UserDto from "../../model/dto/UserDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { ROUTE_PRIVATE_DASHBOARD, ROUTE_PRIVATE_NEW_ORGANIZATION } from "../../routes/routes";

function LicenseSelector() {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const userInfoData: UserDto | undefined = stateAuth?.userInfo?.data?.user;
  const freeLicenses: LicenseDto[] = stateAuth?.userInfo?.data?.freeLicenses || [];
  const organizations: OrganizationDto[] = stateAuth?.userInfo?.data?.organizations || [];

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: false, showLateralMenu: false } });
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CLEAN_ORGANIZATION_FORM,
    });
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CHANGE_VIEW_MODE,
      value: ViewMode.CREATE,
    });
  }, []);

  useEffect(() => {
    if (!location.state?.stayOnPage) {
      if (stateAuth.userInfo.data.organizations.length === 0 && stateAuth.userInfo.data.freeLicenses.length === 0) {
        dispatch<TypeActions>({ type: ActionAuthType.ACTION_LOGOUT });
      } else if (stateAuth.userInfo.data.organizations.length === 0 && stateAuth.userInfo.data.freeLicenses.length === 1) {
        handleClickLicense(stateAuth.userInfo.data.freeLicenses[0]);
      } else if (stateAuth.userInfo.data.organizations.length === 1 && stateAuth.userInfo.data.freeLicenses.length === 0) {
        handleClickOrganization(stateAuth.userInfo.data.organizations[0]);
      }
    }
  }, [stateAuth.userInfo.data]);

  const handleClickOrganization = (organization: OrganizationDto) => {
    if (organization.id) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_ORGANIZATION, value: organization });
    }
    dispatch<TypeActions>({ type: ActionMainType.ACTION_CHANGE_COLOR_APLICATION, value: organization.color });
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHANGE_USER_ORGANIZATION_ROLE, value: organization });
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_DASHBOARD });
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHANGE_APP_USER_LANGUAGE, value: organization });
    navigate("/" + ROUTE_PRIVATE_DASHBOARD);
  };

  const handleClickLicense = (license: LicenseDto) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_LICENSE, value: license.id });
    navigate("/" + ROUTE_PRIVATE_NEW_ORGANIZATION);
  };

  return (
    <div className="full-height flex flex-col justify-center items-center p-6" data-testid="page-selector-licence">
      <LogoBlack className="w-48 md:w-72 mb-8 md:mb-16" />
      <p className="font-decorative text-decorative text-xl md:text-2xl text-center mb-4">{`${t("licenseSelector.title")} ${userInfoData?.name.trim()}!`}</p>
      <p className="text-lg md:text-xl font-semibold text-center mb-12">{t("licenseSelector.subtitle")}</p>
      <div className="flex flex-wrap justify-center items-stretch gap-4 max-w-2xl">
        {organizations.map((organization) => (
          <OrganizationCard key={organization.id} organization={organization} onClick={handleClickOrganization} />
        ))}
        {freeLicenses.map((license) => (
          <FreeLicenseCard key={license.id} license={license} onClick={handleClickLicense} />
        ))}
      </div>
    </div>
  );
}

export default LicenseSelector;
