import { useEffect, useMemo, useState } from "react";
import { Dialog, Tab, Tabs } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { useTranslation } from "react-i18next";
import { InitialState } from "../../model/states/InitialState";
import { useDispatch, useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import LmButton from "../button/LmButton";
import LmAvatarLabelInfoTwoLines from "../avatar-label-info/LmAvatarLabelInfoTwoLines";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import Loading from "../loading/Loading";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import LmCommentList from "../comment-list/LmCommentList";
import UserDto from "../../model/dto/UserDto";
import { CommentDto, UserAccessDto } from "../../model/dto/CommentDto";
import DocumentDto from "../../model/dto/DocumentDto";
import { getSelectedDataById } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { TypeActions } from "../../model/actions/typeActions";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import LmModalGrantAccess from "../modal-grant-access/LmModalGrantAccess";
import { SizeScreen } from "../../model/SizeSreen";

interface Props {
  playlist?: DocumentDto;
  open: boolean;
  handleClose(): void;
  handleOpenShareModal(): void;
  users: Partial<UserDto>[];
  loggedUser: UserDto;
}
const LmModalInfoPlaylist = ({ open, handleClose, handleOpenShareModal, playlist, users, loggedUser }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);

  const [selectedTabValue, setSelecteTabValue] = useState(0);
  const [commentsData, setCommentsData] = useState<CommentDto[]>([]);
  const [saveCommentData, setSaveCommentData] = useState<Partial<CommentDto> | null>(null);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [parentComment, setParentComment] = useState<CommentDto | null>(null);
  const [usersGrantAccess, setUsersGrantAccess] = useState<UserAccessDto[]>([]);
  const [modalGrantAccess, setModalGrantAccess] = useState(false);
  const [modalDeleteComent, setModalDeleteComent] = useState(false);
  const [commentToDeleteIsParent, setCommentToDeleteIsParent] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState("");

  useEffect(() => {
    if (playlist) {
      dispatch<TypeActions>({
        type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_LOADING,
        value: playlist?.id,
      });
    }
  }, [playlist]);

  useEffect(() => {
    setCommentsLoading(stateContent.playlistComments.loading);
    if (stateContent.playlistComments.ok) {
      setCommentsData(stateContent.playlistComments.data);
      setParentComment(stateContent.playlistComments.parentComment);
    }
  }, [stateContent.playlistComments]);

  useEffect(() => {
    if (stateContent.grantAccess.ok) {
      saveComment();
      setUsersGrantAccess([]);
      dispatch({ type: ActionContentManagementType.ACTION_GRANT_ACCESS_RESET });
    }
  }, [stateContent.grantAccess]);
  
  const shareUsers = useMemo(
    () =>
      playlist?.shareWithUsers.map((userId: string) => {
        const dataUsers = selectedOrganizationData?.users?.find((userOrganization) => userOrganization.userId === userId);
        let roles: string[] = [];
        dataUsers?.teamUsers
          .filter((team) => team.userId === userId)
          .forEach((team) => {
            roles = roles.concat(team.roles);
          });
        const setRoles = new Set<string>(roles);
        return {
          id: dataUsers?.userId,
          name: dataUsers?.user?.name,
          lastName: dataUsers?.user?.lastName,
          avatar: dataUsers?.avatarImage,
          roles: roles && roles.length ? [...setRoles].join(",") : "",
        };
      }),
    [playlist]
  );

  const onClose = () => {
    handleClose();
  };

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelecteTabValue(newValue);
  };

  const unShareFile = (user: any) => {
    dispatch({ type: ActionContentManagementType.ACTION_UNSHARE_FILE_LOADING, value: { files: [playlist?.id], users: [user?.id], groups: [], comment: "" } });
  };

  const saveComment = (data?: Partial<CommentDto>) => {
    if (!data && saveCommentData) {
      data = saveCommentData as Partial<CommentDto>;
    }
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_LOADING, value: data as Partial<CommentDto> });
    setSaveCommentData(null);
  };

  const checkSaveComment = (text: string, parentCommentId = "") => {
    const payload = {
      text: text,
      relatedFileId: playlist!.id,
      parentCommentId: parentCommentId,
    };
    setSaveCommentData(payload);

    const getMentions = (comment: string) => {
      const mentions = [];
      for (let i = 0; i < comment.length; i++) {
        if (comment.charAt(i) === "@" && comment.charAt(i + 1) === "<") {
          const stopIndex = comment.indexOf(">", i);
          if (stopIndex !== -1) {
            mentions.push(comment.substring(i + 2, stopIndex));
          }
        }
      }
      return mentions;
    };
    const mentions = getMentions(text);
    if (!mentions.length) {
      saveComment(payload);
      return;
    }

    const mentionUsers: UserAccessDto[] = [];
    users?.forEach((user) => {
      if (mentions.includes(`${user.name} ${user.lastName}`)) {
        mentionUsers.push({
          mention: `${user.name} ${user.lastName}`,
          id: user?.id as string,
          name: user?.name as string,
          lastName: user?.lastName as string,
        });
      }
    });

    const notFoundUsers: UserAccessDto[] = [];
    mentionUsers.forEach((user: any) => {
      if (!playlist?.shareWithUsers?.includes(user.id) && !playlist?.shareWithPlayers?.includes(user.id)) {
        notFoundUsers.push(user);
      }
    });

    if (notFoundUsers.length) {
      setUsersGrantAccess(notFoundUsers);
      setModalGrantAccess(true);
    } else {
      saveComment(payload);
    }
  };

  const likeComment = (commentId: string) => {
    const payload = { commentId: commentId, userId: loggedUser.id };
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_LOADING, value: payload });
  };

  const unlikeComment = (commentId: string) => {
    const payload = { commentId: commentId, userId: loggedUser.id };
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_LOADING, value: payload });
  };

  const askDeleteComment = (commentId: string, isParent: boolean) => {
    setCommentToDelete(commentId);
    setCommentToDeleteIsParent(isParent);
    setModalDeleteComent(true);
  };

  const deleteComments = () => {
    dispatch<TypeActions>({
      type: ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_LOADING,
      value: { commentId: commentToDelete, isParent: commentToDeleteIsParent },
    });

    if (commentToDeleteIsParent) {
      goToParentComment();
    }

    setModalDeleteComent(false);
    setCommentToDelete("");
    setCommentToDeleteIsParent(false);
  };

  const viewAnswers = (commentId: string) => {
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING, value: commentId });
  };

  const goToParentComment = () => {
    if (!parentComment?.parentCommentId) {
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_LOADING, value: playlist!.id });
    } else {
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING, value: parentComment.parentCommentId });
    }
  };

  const dispatchGrantAccess = () => {
    setModalGrantAccess(false);
    const payload = {
      users: usersGrantAccess.map((u) => u.id),
      files: [playlist!.id],
    };

    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GRANT_ACCESS_LOADING, value: payload });
  };

  const closeModalGrantAccess = () => {
    setModalGrantAccess(false);
    saveComment();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className={`p-5 md:p-8 theme-${stateMain.colorAplication}`}>
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative">{`${t("playlist.modalInfoPlaylistTitle")} ${playlist?.fileName ?? ""}`}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-new-team" />
        </div>
        <div className="flex">
          <div className="flex-1 mb-5">
            <Tabs className="shadow-border w-full" textColor="inherit" indicatorColor="primary" value={selectedTabValue} onChange={onChangeTab}>
              <Tab sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }} label={t("playlist.tabShareTitle")} />

              <Tab sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }} label={t("playlist.tabCommentsTitle")} />
            </Tabs>
          </div>
        </div>
        {selectedTabValue === 0 && (
          <div>
            {shareUsers?.length === 0 && <span className="font-semibold">{t("content.sharedWithMembers", {value: 0})}</span>}
            <div className="flex justify-end">
              <LmButton
                styleButton="organization"
                buttonType="button"
                type="solid"
                size="small"
                text={`${t("content.shareWith")}`}
                icon={<Share />}
                iconPosition="left"
                clickAction={handleOpenShareModal}
              />
            </div>
            <div className="space-y-3 mt-4">
              {!stateContent.unShareFile.loading &&
                shareUsers
                  ?.filter((user: any) => user.name)
                  .map((user: any) => (
                    <div key={user?.id}>
                      <LmAvatarLabelInfoTwoLines
                        src={user.avatar}
                        label={user?.name + " " + user?.lastName}
                        info={user.roles}
                        divider="·"
                        radius="100%"
                        secondLine={`${t("content.sharedBy")} ${playlist?.shared ? playlist?.shared?.name + " " + playlist?.shared?.lastName : ""}`}
                        icon={<Close />}
                        textAction={stateMain.sizeScreen !== SizeScreen.MOBILE ? t("content.stopSharing") as string : ''}
                        onClickAction={() => unShareFile(user)}></LmAvatarLabelInfoTwoLines>
                    </div>
                  ))}
              {stateContent.unShareFile.loading && <Loading />}
            </div>
          </div>
        )}
        {selectedTabValue === 1 && (
          <div>
            {!commentsData.length && !commentsLoading && <p className="mt-5">{t("content.noComments")}</p>}
            {!commentsLoading ? (
              <LmCommentList
                comments={commentsData}
                parentComment={parentComment}
                screenSize={stateMain.sizeScreen}
                users={users}
                loggedUser={loggedUser}
                saveComment={checkSaveComment}
                likeComment={likeComment}
                unlikeComment={unlikeComment}
                deleteComment={askDeleteComment}
                viewAnswers={viewAnswers}
                goToParentComment={goToParentComment}></LmCommentList>
            ) : (
              <Loading />
            )}
          </div>
        )}
      </div>
      <LmModalConfirm
        title={t("content.messageConfirmDeleteCommentTitle")}
        description={t("content.messageConfirmDeleteCommentDescription")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={modalDeleteComent}
        confirm={deleteComments}
        handleClose={() => setModalDeleteComent(false)}></LmModalConfirm>
      <LmModalGrantAccess open={modalGrantAccess} users={usersGrantAccess} handleClose={() => closeModalGrantAccess()} confirm={() => dispatchGrantAccess()} />
    </Dialog>
  );
};

export default LmModalInfoPlaylist;
