import { TypeActions } from "../../model/actions/typeActions";
import { ActionNotificationsType } from "../../model/actions/typeNotifications";
import { NotificationsState } from "../../model/states/NotificationsState";
import { notificationsState } from "../states/notificationsState";

export const notificationReducer = (state: NotificationsState = notificationsState, action: TypeActions): NotificationsState => {
  switch (action.type) {
    case ActionNotificationsType.ACTION_GET_NOTIFICATIONS_LOADING: {
      return { ...state, getNotifications: { loading: true, ok: false, error: false } };
    }

    case ActionNotificationsType.ACTION_GET_NOTIFICATIONS_SUCCESS: {
      const showUserNotification = action.value.filter((notification) => !notification.readAt).length > 0;

      return {
        ...state,
        notificationsList: action.value,
        showUserNotification: showUserNotification,
        getNotifications: { loading: false, ok: true, error: false },
      };
    }

    case ActionNotificationsType.ACTION_GET_NOTIFICATIONS_ERROR: {
      return { ...state, getNotifications: { loading: false, ok: false, error: true } };
    }

    case ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_SUCCESS:
    case ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_SUCCESS: {
      const auxNotificationsList = global.structuredClone(state.notificationsList);
      for (const notification of auxNotificationsList) {
        const notificationMarked = action.value.find((item) => item.id === notification.id);
        if (notificationMarked) {
          notification.readAt = notificationMarked.readAt;
        }
      }

      const showUserNotification = auxNotificationsList.filter((notification) => !notification.readAt).length > 0;

      return { ...state, showUserNotification: showUserNotification, notificationsList: auxNotificationsList };
    }

    case ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_SUCCESS: {
      const auxNotificationsList = global.structuredClone(state.notificationsList);
      const updateNotificationsList = auxNotificationsList.filter(
        (stateNotification) => !action.value.some((notification) => stateNotification.id === notification.id)
      );

      const showUserNotification = updateNotificationsList.filter((notification) => !notification.readAt).length > 0;

      return { ...state, showUserNotification: showUserNotification, notificationsList: updateNotificationsList };
    }

    case ActionNotificationsType.ACTION_CHECK_NOTIFICATION: {
      const selectedNotifications = global.structuredClone(state.selectedNotifications);
      selectedNotifications.push(action.value);
      return { ...state, selectedNotifications };
    }

    case ActionNotificationsType.ACTION_UNCHECK_NOTIFICATION: {
      const selectedNotifications = global.structuredClone(state.selectedNotifications);
      const updateList = selectedNotifications.filter((item) => item !== action.value);

      return { ...state, selectedNotifications: updateList };
    }

    case ActionNotificationsType.ACTION_CHECK_ALL_NOTIFICATION: {
      const auxNotificationsList = global.structuredClone(state.filteredNotificationsList);
      const allIds = auxNotificationsList.map((item) => item.id);
      return { ...state, selectedNotifications: allIds };
    }

    case ActionNotificationsType.ACTION_UNCHECK_ALL_NOTIFICATION: {
      return { ...state, selectedNotifications: [] };
    }

    case ActionNotificationsType.ACTION_CHANGE_NOTIFICATION_TYPE_FILTER: {
      return { ...state, filter: { ...state.filter, notificationType: action.value } };
    }

    case ActionNotificationsType.ACTION_CHANGE_READ_TYPE_FILTER: {
      return { ...state, filter: { ...state.filter, read: action.value } };
    }

    case ActionNotificationsType.ACTION_CLEAN_NOTIFICATIONS_FILTER: {
      return { ...state, filter: undefined };
    }

    case ActionNotificationsType.ACTION_CHANGE_FILTERED_NOTIFICATIONS_LIST: {
      return { ...state, filteredNotificationsList: action.value };
    }

    case ActionNotificationsType.ACTION_CHANGE_DATE_RANGE_FILTER: {
      return { ...state, filter: { ...state.filter, dateRange: { start: action.value.start, end: action.value.end } } };
    }

    default: {
      return { ...state };
    }
  }
};
