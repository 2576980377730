import { Dialog } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { ViewMode } from "../../model/ViewMode";
import { LmGroupForm } from "../form-group/LmGroupForm";
import TeamGroupResultDto from "../../model/dto/TeamGroupResultDto";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";

interface Props {
  id?: string;
  open: boolean;
  viewMode: ViewMode;
  handleClose(): void;
  group?: TeamGroupResultDto | undefined;
}
const LmModalGroup = ({ id, open, handleClose, viewMode, group }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const { t } = useTranslation();

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8" data-testid={id}>
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl">{viewMode === ViewMode.CREATE ? t("group.addGroup") : t("group.editGroup")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-new-group" />
        </div>
        <LmGroupForm onClose={onClose} viewMode={viewMode} group={group} />
      </div>
    </Dialog>
  );
};

export default LmModalGroup;
