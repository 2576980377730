import { NotificationsState } from "../../model/states/NotificationsState";

export const notificationsState: NotificationsState = {
  notificationsList: [],
  filteredNotificationsList: [],
  selectedNotifications: [],
  filter: undefined,
  getNotifications: {
    loading: false,
    error: false,
    ok: false,
  },
  showUserNotification: false,
};
