import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DocumentImage from "../../assets/img/file.svg";
import VideoImage from "../../assets/img/video-file.png";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { IdValue } from "../../model/forms/IdValue";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getSelectedDataById, isStaff, typesContentStateFilesSelected } from "../../utils/utils";
import LmButton from "../button/LmButton";
import LmMessageError from "../message-error/LmMessageError";
import LmSelect from "../select/LmSelect";
import LmTextArea from "../textarea/LmTextArea";
import { teamInitialValues, teamValidationSchema } from "./LmUploadFileform.data";

import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ACCEPT_FILES_CONTENT_DOCUMENTS } from "../../constants/globalConstanst";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import { ContentManagementState, ContentManagementType } from "../../model/states/ContentManagementState";
import { OrganizationState } from "../../model/states/OrganizationState";
import { TeamState } from "../../model/states/TeamState";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import LmTags from "../autocomplete/LmTags";
import { PlayerState } from "../../model/states/PlayerState";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
interface Props {
  onClose(): void;
}

export function LmUploadFileForm({ onClose }: Props) {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const statePlayer: PlayerState = useSelector((state: MainState) => state.player);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const stateTeam: TeamState = useSelector((state: MainState) => state.team);
  const selectedOrganization: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  const teamOptions = useMemo(() => {
    return selectedOrganization.teams
      .filter((team) => isStaff(team, stateAuth))
      .map((team) => {
        return {
          id: team.id as string,
          value: team.name,
          image: team.image,
        };
      });
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const [nameFile, setNameFile] = useState("");
  const [teamId, setTeamId] = useState<IdValue | undefined | null>(
    teamOptions.find((teamOption) => teamOption.id === stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)].teamSelected)
  );
  const [category, setCategory] = useState<IdValue[]>([]);
  const [categoryValue, setCategoryValue] = useState("");

  const [group, setGroup] = useState<IdValue[]>([]);
  const [groupValue, setGroupValue] = useState("");

  const [user, setUser] = useState<IdValue[]>([]);
  const [userValue, setUserValue] = useState("");
  const [tags, setTags] = useState<IdValue[]>([]);

  const formik = useFormik({
    initialValues: teamInitialValues(stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)].teamSelected),
    validationSchema: teamValidationSchema(stateContent.contentSelected),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const categoryOptions = useMemo(
    () =>
      stateContent.categories.data.map((category) => {
        return { id: category.id, value: category.name };
      }),
    [stateContent.categories.data]
  );
  const tagsOptions = useMemo(
    () =>
      stateContent.tags.data.filter(tag=>tag.type === 'Public').map((tag) => {
        return { id: tag.id, value: tag.name, type: tag.type };
      }),
    [stateContent.tags]
  );
  const groupsOptions = useMemo(() => {
    setGroup([]);
    setGroupValue("");
    const groups: IdValue[] = [];
    const teamSelected = formik.values.teamId;
    const team = selectedOrganization.teams.find((t) => t.id === teamSelected);
    if (team) {
      team.groups?.forEach((group) => groups.push({ id: group.id, value: group.name }));
    }
    return groups;
  }, [stateTeam.groups.ok, teamId]);

  const usersOptions = useMemo(() => {
    let users = selectedOrganization.users?.map((user) => {
      return { id: user.user?.id, value: user.user?.name + ", " + user.user?.lastName, type: 'user' };
    }).filter(item => item !== undefined);
    if (selectedOrganization?.playersUnregistered) {
      users = users?.concat(selectedOrganization?.playersUnregistered?.map(player => {
        return { id: player.id, value: player.name + ", " + player.lastName, type: 'player' };
      }))
    }
   
    return users
  }, [stateOrganization.organizationUsers.loading, stateAuth.userInfo.selectedOrganization, statePlayer.dataPlayerUnregistered.loading]);  


  useEffect(() => {
    if (selectedOrganization.users && selectedOrganization.users?.filter(user => !user.user).length > 0) {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }
    if (!selectedOrganization.playersUnregistered) {
      dispatch<TypeActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING, value: stateAuth.userInfo.selectedOrganization });      
    }
  }, [stateAuth.userInfo.selectedOrganization]);

  useEffect(() => {
    if (stateContent.uploadDocument.ok) {
      setTags([]);
      onClose();
      dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_RESET });
    }
  }, [stateContent.uploadDocument.ok]);

  useEffect(() => {
    if (teamId) {
      const team = selectedOrganization.teams.find((team) => team.id === formik.values.teamId);
      if (team && team.groups?.length === 0) {
        dispatch<TypeActions>({ type: ActionTeamType.ACTION_GET_TEAM_GROUPS_LOADING, value: teamId?.id });
      }
    }
  }, [formik.values.teamId]);
 
  const onSubmit = async (formValue: any) => {
    const file: File = formValue.file;
    const type: string = ContentManagementType[stateContent.contentSelected];
    const fileData = {
      ...formValue,
      type: type,
      fileName: file.name,
      mimeType: file.type,
      newCategoryName: formValue.categoryId === "" ? categoryValue : null,
      expectedSize: file.size    
    };   
    delete fileData.file;   
    dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_LOADING, value: { data: fileData, file: file } });
  };

  const onChangeTeam = (option: IdValue) => {
    formik.setFieldValue("teamId", option.id);
    setTeamId(option);
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files) {
      formik.setFieldValue("file", e.currentTarget?.files[0]);
      setNameFile(e.currentTarget?.files[0].name);
    }
  };

  const selectedCategory = (options: IdValue[]) => {
    setCategory(options as IdValue[]);
    if (options.length > 0) {
      setCategoryValue(options[0].value);
      formik.setFieldValue("categoryId", options[0].id);
    }
  };

  const selectedValueCategoryDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setCategoryValue(e.target.value);
  };

  const selectedGroup = (options: IdValue[]) => {
    setGroup(options as IdValue[]);
    setGroupValue("");
    formik.setFieldValue(
      "shareWithGroups",
      options.map((option) => option.id)
    );
  };

  const selectedValueGroupDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setGroupValue(e.target.value);
  };

  const selectedUser = (options: IdValue[]) => {
    setUser(options as IdValue[]);
    setUserValue("");
    formik.setFieldValue(
      "shareWithUsers",
      options.filter(option => option.type === 'user').map(option => option.id)
    );   
    formik.setFieldValue(
      "shareWithPlayers",
      options.filter(option => option.type === 'player').map(option => option.id)
    );
  };

  const selectedValueUserDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setUserValue(e.target.value);
  };

  const selectTag = (option: IdValue) => {
    const options: IdValue[] = [...tags, option];

    setTags(options);
    formik.setFieldValue("tags", options);
  };

  const deleteTag = (option: IdValue) => {
    let newOptions;
    if (option.id) {
      newOptions = tags.filter((tag: IdValue) => tag.id !== option.id);
    } else {
      newOptions = tags.filter((tag: IdValue) => tag.value !== option.value);
    }
    setTags(newOptions);
    formik.setFieldValue("tags", newOptions);
  };

  const onCloseForm = () => {
    setTags([]);
    onClose();
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-upload-file-form">
        <div className="grid grid-cols-1 gap-4 w-full mb-8">
          <label htmlFor="upload-file" className="text-center p-5 rounded bg-neutral-50 border border-neutral-200 cursor-pointer">
            <img className="mx-auto mb-5" src={stateContent.contentSelected === ContentManagementType.Document ? DocumentImage : VideoImage}></img>
            {nameFile ? <span>{nameFile}</span> : <span className="text-sm font-semibold">{t("content.uploadDocument")}</span>}
          </label>
          <input
            id="upload-file"
            type="file"
            className="hidden"
            accept={stateContent.contentSelected === ContentManagementType.Document ? ACCEPT_FILES_CONTENT_DOCUMENTS : "video/*"}
            onChange={onChangeFile}></input>
          {formik.touched.file && formik.errors.file && <LmMessageError id="file" message={formik.errors.file}></LmMessageError>}
          {stateContent.contentSelected === ContentManagementType.Video && (
            <div>
              <LmTextArea
                required={true}
                id="description"
                label={t("formLabel.description")}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.description}></LmTextArea>
              {formik.touched.description && formik.errors.description && (
                <LmMessageError id="description" message={formik.errors.description}></LmMessageError>
              )}
            </div>
          )}
          <div>
            <LmSelect
              id="teamId"
              label={t("formLabel.selectTeam")}
              required={true}
              multipleCheck={false}
              options={teamOptions}
              selectedValue={onChangeTeam}
              value={teamId}
            />
            {formik.touched.teamId && formik.errors.teamId && <LmMessageError id="teamId" message={formik.errors.teamId}></LmMessageError>}
          </div>
          <div>
            <LmAutocomplete
              label={t("formLabel.category")}
              id="categoryId"
              autocomplete="off"
              value={category}
              valueDisplay={categoryValue}
              multiple={false}
              options={categoryOptions as IdValue[]}
              setValueDisplay={selectedValueCategoryDisplay}
              selectedValue={(options) => selectedCategory(options as IdValue[])}
            />
            {formik.touched.categoryId && formik.errors.categoryId && <LmMessageError id="categoryId" message={formik.errors.categoryId}></LmMessageError>}
          </div>

          <div>
            <LmTags
              id="tags"
              label={t("formLabel.tags") as string}
              value={tags}
              options={tagsOptions as IdValue[]}
              selectedValue={(option: IdValue) => selectTag(option)}
              deleteValue={(option: IdValue) => deleteTag(option)}
            />
          </div>
          <div>
            <div>
              <LmAutocomplete
                label={t("formLabel.shareWithGroups")}
                id="shareWithGroups"
                placeholder={t("content.groupsPlaceholder") as string}
                value={group}
                valueDisplay={groupValue}
                setValueDisplay={selectedValueGroupDisplay}
                multiple={true}
                options={groupsOptions as IdValue[]}
                selectedValue={(options) => selectedGroup(options as IdValue[])}
              />
            </div>
            <div>
              <LmAutocomplete
                id="shareWithUsers"
                placeholder={t("content.usersPlaceholder") as string}
                value={user}
                valueDisplay={userValue}
                setValueDisplay={selectedValueUserDisplay}
                multiple={true}
                options={usersOptions as IdValue[]}
                selectedValue={(options) => selectedUser(options as IdValue[])}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-end gap-4 mt-8">
          <LmButton
            styleButton="secondary"
            type="solid"
            clickAction={onCloseForm}
            size="small"
            text={`${t("buttonLabel.cancel")}`}
            iconPosition="right"></LmButton>
          <LmButton
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={t("buttonLabel.upload")}
            isDisabled={stateContent.uploadDocument.loading}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
}