import { useDispatch, useSelector } from "react-redux";
import LmAvatar from "../../components/avatar/LmAvatar";
import LmButton from "../../components/button/LmButton";
import { MainState } from "../../model/states/MainState";
import { OrganizationState } from "../../model/states/OrganizationState";
import { ReactComponent as Dots } from "../../assets/icons/dots-horizontal.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import LmDropDown from "../../components/dropdown/LmDropDown";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ModalEditOrganization from "../modal-edit-organization/ModalEditOrganization";

import LmModalTeam from "../modal-team/LmModalTeam";
import { ViewMode } from "../../model/ViewMode";
import { InitialState } from "../../model/states/InitialState";
import { SizeScreen } from "../../model/SizeSreen";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { useNavigate } from "react-router-dom";
import { ROUTE_LICENSE_SELECTOR } from "../../routes/routes";
import { getSelectedDataById, isOwner } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";

const TeamsHeader = () => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openModalEditOrganization, setOpenModalEditOrganization] = useState(false);
  const [openModalNewTeam, setOpenModalNewTeam] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const organization = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  const openEditOrganization = () => {
    setOpenMenu(false);
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CHANGE_VIEW_MODE,
      value: ViewMode.EDIT,
    });
    setOpenModalEditOrganization(true);
  };

  const openDeleteOrganization = () => {
    setOpenMenu(false);
    setOpenModalConfirm(true);
  };

  const onDeleteOrganization = () => {
    if (organization) {
      setOpenModalConfirm(false);
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_LOADING, value: organization });
    }
  };

  useEffect(() => {
    if (stateOrganization.exit.ok) {
      navigate("/" + ROUTE_LICENSE_SELECTOR);
      dispatch({ type: ActionOrganizationType.ACTION_RESET_ORGANIZATION_EXIT });
    }
  }, [stateOrganization.exit.ok]);

  return (
    <>    
      <div className="flex flex-wrap gap-4 p-5 lg:p-8">      
        <div className="flex space-x-2">
          <LmAvatar src={organization?.image} variant="rounded" radius="4px" border={true} />
          <div className="flex items-center">
            <p className="font-decorative text-decorative text-sm mr-2">{organization?.name}</p>
            <div className="w-2.5	h-2.5 rounded-sm  bg-orgColor" />
          </div>
        </div>
        {isOwner(stateAuth) && (
          <div className="flex ml-auto space-x-2">
            <div className="relative">
              <LmButton
                styleButton="secondary"
                buttonType="submit"
                type="solid"
                size="small"
                icon={<Dots />}
                iconSize={stateMain.sizeScreen === SizeScreen.MOBILE ? "xs" : ""}
                iconPosition="center"
                clickAction={() => setOpenMenu(!openMenu)}
              />
              <LmDropDown open={openMenu} close={() => setOpenMenu(false)} id="organization-dropDrown">
                <li className="cursor-pointer flex items-center gap-2 h-11" data-testid="modal-edit-organization" onClick={openEditOrganization}>
                  <span>{t("teams.editOrganization")}</span>
                </li>
                <li className="cursor-pointer flex items-center gap-2 h-11" data-testid="modal-delete-organization" onClick={openDeleteOrganization}>
                  <span>{t("teams.deleteOrganization")}</span>
                </li>
              </LmDropDown>
            </div>
            <div className="add-button-container">
              <LmButton
                styleButton="organization"
                buttonType="submit"
                type="solid"
                size="small"
                isDisabled={organization.teams?.length >= organization.license.maxNumberOfTeams}
                text={stateMain.sizeScreen === SizeScreen.MOBILE ? "" : t("buttonLabel.addNewTeam")}
                iconPosition="left"
                clickAction={() => setOpenModalNewTeam(true)}
                icon={<Plus />}
              />
            </div>
          </div>
        )}
      </div>
      <ModalEditOrganization open={openModalEditOrganization} handleClose={() => setOpenModalEditOrganization(false)} />
      <LmModalConfirm
        id="test-modal-confirm-remove-team"
        title={t("teams.deleteOrganizationSubtitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalConfirm}
        handleClose={() => setOpenModalConfirm(false)}
        confirm={onDeleteOrganization}></LmModalConfirm>
      <LmModalTeam open={openModalNewTeam} handleClose={() => setOpenModalNewTeam(false)} viewMode={ViewMode.CREATE} />
    </>
  );
};

export default TeamsHeader;
