import React, { ChangeEvent, KeyboardEvent, MouseEvent } from "react";
import LmIcon from "../icon/LmIcon";
import LmLabel from "../label/LmLabel";
import "./LmInput.scss";

interface IInput {
  id?: string;
  min?: number | string;
  max?: number | string;
  maxlength?: number;
  readonly?: boolean;
  type?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string | null;
  tags?: boolean;
  required?: boolean;
  helpText?: string;
  iconSufix?: any;
  prefix?: any;
  iconSufixAction?(): void;
  changeValue?(value: ChangeEvent<HTMLInputElement>): void;
  blur?(value: ChangeEvent<HTMLInputElement>): void;
  focus?(value: ChangeEvent<HTMLInputElement>): void;
  keyDown?(value: KeyboardEvent<HTMLInputElement>): void;
  click?(value: MouseEvent<HTMLInputElement>): void; 
  autoComplete?: string;
}

const LmInput: React.FC<IInput> = ({
  prefix = null,
  id = "",
  min = 0,
  max = undefined,
  maxlength = 250,
  readonly = false,
  type = "text",
  value = undefined,
  placeholder = "",
  disabled = false,
  error = false,
  label = "",
  tags = false,
  required = false,
  iconSufix = null,
  iconSufixAction = () => {},
  helpText = "",
  changeValue = () => {},
  blur = () => {},
  focus = () => {},
  keyDown = () => {},
  click = () => {},
  autoComplete = "off",
}) => {
  return (
    <div className={`lm-input min-w-0 ${disabled ? "lm-input--disabled" : ""} ${error ? "lm-input--error" : ""} ${readonly ? "lm-input--readonly" : ""}`}>
      {label !== "" && <LmLabel value={label} id={id} required={required}></LmLabel>}
      <div className={`lm-input__wrapper ${tags && "lm-input__wrapper--tags"}`}>
        <div className="lm-input__inner">
          {prefix && <>{prefix}</>}
          <input
            className="lm-input__element"
            id={id}
            data-testid={id}
            min={min}
            max={max}
            maxLength={maxlength} 
            readOnly={readonly}
            type={type}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={changeValue}
            onBlur={blur}
            onFocus={focus}      
            onKeyDown={keyDown}   
            onClick={click}  
            autoComplete={autoComplete}
          />
        </div>
        {iconSufix && (
          <div className="lm-input__actions">
            <LmIcon icon={iconSufix} size="xs" testid="input-icon-sufix" clickAction={iconSufixAction} />
          </div>
        )}
      </div>
      {helpText && <div className="lm-input__help">{helpText}</div>}
    </div>
  );
};

export default LmInput;
