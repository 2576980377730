import LicenseDto from "../dto/LicensesDto";
import OrganizationDto from "../dto/OrganizationDto";
import { ViewMode } from "../ViewMode";
import OrganizationSumaryDto from "../dto/OrganizationSumaryDto";
import { DataCallback } from "../DataCallback";
import { EditOrganizationUserDto } from "../dto/EditOrganizationUserDto";

export enum ActionOrganizationType {
  ACTION_CREATE_ORGANIZATION_LOADING = "ACTION_CREATE_ORGANIZATION_LOADING",
  ACTION_CREATE_ORGANIZATION_SUCCESS = "ACTION_CREATE_ORGANIZATION_SUCCESS",
  ACTION_CREATE_ORGANIZATION_ERROR = "ACTION_CREATE_ORGANIZATION_ERROR",
  ACTION_EDIT_ORGANIZATION_LOADING = "ACTION_EDIT_ORGANIZATION_LOADING",
  ACTION_EDIT_ORGANIZATION_SUCCESS = "ACTION_EDIT_ORGANIZATION_SUCCESS",
  ACTION_EDIT_ORGANIZATION_ERROR = "ACTION_EDIT_ORGANIZATION_ERROR",
  ACTION_AUTORIZATION_UNSUBSCRIBE_LOADING = "ACTION_AUTORIZATION_UNSUBSCRIBE_LOADING",
  ACTION_AUTORIZATION_UNSUBSCRIBE_SUCCESS = "ACTION_AUTORIZATION_UNSUBSCRIBE_SUCCESS",
  ACTION_AUTORIZATION_UNSUBSCRIBE_ERROR = "ACTION_AUTORIZATION_UNSUBSCRIBE_ERROR",
  ACTION_AUTORIZATION_UNSUBSCRIBE_RESET = "ACTION_AUTORIZATION_UNSUBSCRIBE_RESET",
  ACTION_CHANGE_MAIN_DATA_FORM_VALUE = "ACTION_CHANGE_MAIN_DATA_FORM_VALUE",
  ACTION_SELECT_ORGANIZATION = "ACTION_SELECT_ORGANIZATION",
  ACTION_SELECT_LICENSE = "ACTION_SELECT_LICENSE",
  ACTION_CLEAN_ORGANIZATION_FORM = "ACTION_CLEAN_ORGANIZATION_FORM",
  ACTION_CHANGE_VIEW_MODE = "ACTION_CHANGE_VIEW_MODE",
  ACTION_CHANGE_ACTIVE_TAB = "ACTION_CHANGE_ACTIVE_TAB",
  ACTION_ORGANIZATION_SUMARY_LOADING = "ACTION_ORGANIZATION_SUMARY_LOADING",
  ACTION_ORGANIZATION_SUMARY_SUCCESS = "ACTION_ORGANIZATION_SUMARY_SUCCESS",
  ACTION_ORGANIZATION_SUMARY_ERROR = "ACTION_ORGANIZATION_SUMARY_ERROR",
  ACTION_CHANGE_TEAM_TAB_SELECTED = "ACTION_CHANGE_TEAM_TAB_SELECTED",
  ACTION_DELETE_ORGANIZATION_LOADING = "ACTION_DELETE_ORGANIZATION_LOADING",
  ACTION_DELETE_ORGANIZATION_SUCCESS = "ACTION_DELETE_ORGANIZATION_SUCCESS",
  ACTION_DELETE_ORGANIZATION_ERROR = "ACTION_DELETE_ORGANIZATION_ERROR",
  ACTION_RESET_ORGANIZATION_EXIT = "ACTION_RESET_ORGANIZATION_EXIT",

  ACTION_GET_ORGANIZATION_USERS_LOADING = "ACTION_GET_ORGANIZATION_USERS_LOADING",
  ACTION_GET_ORGANIZATION_USERS_SUCCESS = "ACTION_GET_ORGANIZATION_USERS_SUCCESS",
  ACTION_GET_ORGANIZATION_USERS_ERROR = "ACTION_GET_ORGANIZATION_USERS_ERROR",

  ACTION_DELETE_ORGANIZATION_USER_LOADING = "ACTION_DELETE_ORGANIZATION_USER_LOADING",
  ACTION_DELETE_ORGANIZATION_USER_SUCCESS = "ACTION_DELETE_ORGANIZATION_USER_SUCCESS",
  ACTION_DELETE_ORGANIZATION_USER_ERROR = "ACTION_DELETE_ORGANIZATION_USER_ERROR",

  ACTION_EDIT_ORGANIZATION_USER_LOADING = "ACTION_EDIT_ORGANIZATION_USER_LOADING",
  ACTION_EDIT_ORGANIZATION_USER_SUCCESS = "ACTION_EDIT_ORGANIZATION_USER_SUCCESS",
  ACTION_EDIT_ORGANIZATION_USER_ERROR = "ACTION_EDIT_ORGANIZATION_USER_ERROR",

  ACTION_CHANGE_SORT_ORGANIZATION_USER = "ACTION_CHANGE_SORT_ORGANIZATION_USER",

  ACTION_CHANGE_USER_NAME_FILTER = "ACTION_CHANGE_USER_NAME_FILTER",
  ACTION_CHANGE_USER_ROLES_FILTER = "ACTION_CHANGE_USER_ROLES_FILTER",
  ACTION_CHANGE_USER_TEAMS_FILTER = "ACTION_CHANGE_USER_TEAMS_FILTER",
  ACTION_CHANGE_USER_QUICK_FILTER = "ACTION_CHANGE_USER_QUICK_FILTER",
  ACTION_CLEAN_USER_FILTER = "ACTION_CLEAN_USER_FILTER",
  ACTION_CLEAN_USER_QUICK_FILTER = "ACTION_CLEAN_USER_QUICK_FILTER",

  ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_LOADING = "ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_LOADING",
  ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_SUCCESS = "ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_SUCCESS",
  ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_ERROR = "ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_ERROR",
}

export interface ActionCreateOrganizationLoading {
  type: ActionOrganizationType.ACTION_CREATE_ORGANIZATION_LOADING;
  value: OrganizationDto;
}

export interface ActionCreateOrganizationSuccess {
  type: ActionOrganizationType.ACTION_CREATE_ORGANIZATION_SUCCESS;
  value: OrganizationDto;
}

export interface ActionCreateOrganizationError {
  type: ActionOrganizationType.ACTION_CREATE_ORGANIZATION_ERROR;
  value: string;
}

export interface ActionEditOrganizationLoading {
  type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_LOADING;
  value: { organizationId: string; dataCallback: DataCallback<OrganizationDto> };
}

export interface ActionEditOrganizationSuccess {
  type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_SUCCESS;
  value: OrganizationDto;
}

export interface ActionEditOrganizationError {
  type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_ERROR;
  value: string;
}

export interface ActionUnsubscribeLoading {
  type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_LOADING;
  value: string;
}

export interface ActionUnsubscribeSuccess {
  type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_SUCCESS;
  value: boolean;
}

export interface ActionUnsubscribeError {
  type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_ERROR;
}

export interface ActionUnsubscribeReset {
  type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_RESET;
}

export interface ActionChangeMainDataFormValue {
  type: ActionOrganizationType.ACTION_CHANGE_MAIN_DATA_FORM_VALUE;
  value: { key: string; value: any };
}

export interface ActionSelectOrganization {
  type: ActionOrganizationType.ACTION_SELECT_ORGANIZATION;
  value: OrganizationDto;
}

export interface ActionSelectLicense {
  type: ActionOrganizationType.ACTION_SELECT_LICENSE;
  value: LicenseDto;
}

export interface ActionCleanOrganizationForm {
  type: ActionOrganizationType.ACTION_CLEAN_ORGANIZATION_FORM;
}

export interface ActionChangeActiveTab {
  type: ActionOrganizationType.ACTION_CHANGE_ACTIVE_TAB;
  value: number;
}

export interface ActionChangeViewMode {
  type: ActionOrganizationType.ACTION_CHANGE_VIEW_MODE;
  value: ViewMode;
}
export interface ActionOrganizationSumaryLoading {
  type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_LOADING;
  value: string;
}

export interface ActionOrganizationSumarySuccess {
  type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_SUCCESS;
  value: OrganizationSumaryDto;
}

export interface ActionOrganizationSumaryError {
  type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_ERROR;
}

export interface ActionChangeTeamTabSelected {
  type: ActionOrganizationType.ACTION_CHANGE_TEAM_TAB_SELECTED;
  value: number;
}

export interface ActionDeleteOrganizationLoading {
  type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_LOADING;
  value: OrganizationDto;
}

export interface ActionDeleteOrganizationSuccess {
  type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_SUCCESS;
}

export interface ActionDeleteOrganizationError {
  type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_ERROR;
  value: string;
}

export interface ActionResetOrganizationExit {
  type: ActionOrganizationType.ACTION_RESET_ORGANIZATION_EXIT;
}

export interface ActionGetOrganizationUsersLoading {
  type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING;
  value: string;
}

export interface ActionGetOrganizationUsersSuccess {
  type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_SUCCESS;
}

export interface ActionGetOrganizationUsersError {
  type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_ERROR;
}

export interface ActionChangeUserNameFilter {
  type: ActionOrganizationType.ACTION_CHANGE_USER_NAME_FILTER;
  value: string;
}

export interface ActionChangeUserRolesFilter {
  type: ActionOrganizationType.ACTION_CHANGE_USER_ROLES_FILTER;
  value: string[];
}

export interface ActionChangeUserTeamsFilter {
  type: ActionOrganizationType.ACTION_CHANGE_USER_TEAMS_FILTER;
  value: string[];
}

export interface ActionChangeUserQuickFilter {
  type: ActionOrganizationType.ACTION_CHANGE_USER_QUICK_FILTER;
  value: string[];
}

export interface ActionCleanUserFilter {
  type: ActionOrganizationType.ACTION_CLEAN_USER_FILTER;
}

export interface ActionCleanUserQuickFilter {
  type: ActionOrganizationType.ACTION_CLEAN_USER_QUICK_FILTER;
}

export interface ActionDeleteOrganizationUserLoading {
  type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_LOADING;
  value: {
    organizationId: string;
    userId: string;
  };
}

export interface ActionDeleteOrganizationUserSuccess {
  type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_SUCCESS;
}

export interface ActionDeleteOrganizationUserError {
  type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_ERROR;
  value: string;
}

export interface ActionEditOrganizationUserLoading {
  type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_LOADING;
  value: {
    organizationId: string;
    userId: string;
    dataCallback: DataCallback<EditOrganizationUserDto>;
  };
}

export interface ActionEditOrganizationUserSuccess {
  type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_SUCCESS;
}

export interface ActionEditOrganizationUserError {
  type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_ERROR;
}

export interface ActionGetOrganizationPendingInvitationsLoading {
  type: ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_LOADING;
  value: string;
}

export interface ActionGetOrganizationPendingInvitationsSuccess {
  type: ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_SUCCESS;
}

export interface ActionGetOrganizationPendingInvitationsError {
  type: ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_ERROR;
}

export interface ActionChangeSortOrganizationUser {
  type: ActionOrganizationType.ACTION_CHANGE_SORT_ORGANIZATION_USER;
  value: string;
}

export type TypeOrganizationActions =
  | ActionCreateOrganizationError
  | ActionCreateOrganizationSuccess
  | ActionCreateOrganizationLoading
  | ActionEditOrganizationError
  | ActionEditOrganizationSuccess
  | ActionEditOrganizationLoading
  | ActionUnsubscribeLoading
  | ActionChangeMainDataFormValue
  | ActionSelectOrganization
  | ActionSelectLicense
  | ActionCleanOrganizationForm
  | ActionChangeActiveTab
  | ActionChangeViewMode
  | ActionOrganizationSumaryLoading
  | ActionOrganizationSumarySuccess
  | ActionOrganizationSumaryError
  | ActionUnsubscribeSuccess
  | ActionUnsubscribeError
  | ActionChangeTeamTabSelected
  | ActionDeleteOrganizationLoading
  | ActionDeleteOrganizationSuccess
  | ActionDeleteOrganizationError
  | ActionResetOrganizationExit
  | ActionGetOrganizationUsersLoading
  | ActionGetOrganizationUsersSuccess
  | ActionGetOrganizationUsersError
  | ActionChangeUserNameFilter
  | ActionChangeUserRolesFilter
  | ActionChangeUserTeamsFilter
  | ActionChangeUserQuickFilter
  | ActionCleanUserFilter
  | ActionCleanUserQuickFilter
  | ActionDeleteOrganizationUserLoading
  | ActionDeleteOrganizationUserSuccess
  | ActionDeleteOrganizationUserError
  | ActionEditOrganizationUserLoading
  | ActionEditOrganizationUserSuccess
  | ActionEditOrganizationUserError
  | ActionGetOrganizationPendingInvitationsLoading
  | ActionGetOrganizationPendingInvitationsSuccess
  | ActionGetOrganizationPendingInvitationsError
  | ActionChangeSortOrganizationUser
  | ActionUnsubscribeReset;
