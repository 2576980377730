import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import LmHomeUsers from "../home-users/LmHomeUsers";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { getSelectedDataById } from "../../utils/utils";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import EmptyUsers from "../../assets/img/empty-members.png";
import EmptyDashboard from "../empty/emptyDashboard";

const LmLastUsers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = useMemo(
    () => getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations),
    [stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations]
  );
  useEffect(() => {
    dispatch<TypeActions>({
      type: ActionAuthType.ACTION_GET_LAST_USERS_LOADING,
      value: {
        organizationId: stateAuth.userInfo.selectedOrganization as string,

        top: 2,
      },
    });
  }, [stateAuth.userInfo.selectedOrganization]);

  return stateAuth.getLastUsers?.loading ? (
    <Loading />
  ) : (
    <div className="border border-neutral-200 rounded p-5 mb-5 space-y-4">
      {stateAuth?.getLastUsers?.data?.length ? (
        stateAuth.getLastUsers.data.map((user) => <LmHomeUsers key={user.userId} user={user} teamsInfo={selectedOrganizationData?.teams} />)
      ) : (
        <EmptyDashboard text={t("content.emptyCategory")} img={EmptyUsers} />
      )}
    </div>
  );
};

export default LmLastUsers;
