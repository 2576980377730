import * as Yup from "yup";
import { formatDate } from "../../utils/utils";
import { t } from "i18next";
import { regexEmailFormat, regexPhone } from "../../constants/globalConstanst";

export function mainDataInitialValues(dataStore: any, licenseData: any) {
  return {
    name: dataStore?.name || "",
    address: dataStore?.address || "",
    cifVat: dataStore?.cifVat || "",
    email: dataStore?.email || "",
    phone: dataStore?.phone || "",
    license: licenseData?.type?.code || "",
    validUntil: formatDate(licenseData?.validUntil),
    storage: licenseData?.storage || "",
  };
}

export function organizationTeamInitialValues(dataStore: any) {
  return {
    name: dataStore.teamForm.name,
    category: dataStore.teamForm.category,
    storage: dataStore.teamForm.storage,
  };
}

export function mainDataValidationSchema() {
  return Yup.object({
    name: Yup.string().required(t("errors.form.required") as string),
    address: Yup.string().required(t("errors.form.required") as string),
    cifVat: Yup.string().required(t("errors.form.required") as string),
    email: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexEmailFormat, t("errors.form.passwordFormat") as string),
    phone: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexPhone, t("errors.form.passwordFormat") as string),
  });
}

export function organizationTeamValidationSchema(maxStorage: number) {
  return Yup.object({
    name: Yup.string().required(t("errors.form.required") as string),
    category: Yup.string().required(t("errors.form.required") as string),
    storage: Yup.number()
      .typeError("Amount must be a number")
      .max(maxStorage, t("errors.form.storageLimit") as string),
  });
}
