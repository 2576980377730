import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import LmButton from "../button/LmButton";
import LmInput from "../input/LmInput";
import LmMessageError from "../message-error/LmMessageError";
import { staffInitialValues, staffValidationSchema } from "./LmStaffForm.data";
import LmSelect from "../select/LmSelect";
import { getRole, getRoleOptionsForNewOrganization, getSelectedDataById } from "../../utils/utils";
import LoadImage from "../load-image/LoadImage";
import { ChangeEvent, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import StaffDto from "../../model/dto/StaffDto";
import { IdValue } from "../../model/forms/IdValue";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import { AuthState } from "../../model/states/AuthState";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { MainState } from "../../model/states/MainState";
import TeamsDto from "../../model/dto/TeamDto";
import { OrganizationState } from "../../model/states/OrganizationState";

interface Props {
  team: TeamsDto;
  staff: StaffDto | null;
  onClose(): void;
}

export function LmStaffForm({ team, onClose, staff }: Props) {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roleOptions = getRoleOptionsForNewOrganization();
  const [image, setImage] = useState(staff ? (staff?.avatarImage as string) : selectedOrganizationData?.image);
  const formik = useFormik({
    initialValues: staffInitialValues(staff ? getRole(staff.roles) : [], staff),
    validationSchema: staffValidationSchema(),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => onSubmit(values),
  });
  const [name, setName] = useState<IdValue[]>([]);
  const [nameValue, setNameValue] = useState(staff ? staff.user?.name : "");
  const [roles, setRoles] = useState<any>(staff ? getRole(staff.roles) : []);

  const onSubmit = async (formValue: any) => {
    if (roles.length > 0) {
      if (!staff) {
        const data = { ...formValue, teams: [{ teamId: team.id, roles: formValue.roles }], avatarImage: image };
        delete data.roles;
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_CREATE_STAFF_LOADING,
          value: { data: data, callBack: onClose },
        });
      } else {
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_EDIT_STAFF_LOADING,
          value: {
            teamId: team.id as string,
            userId: staff.user?.id as string,
            dataCallback: {
              data: {
                avatarImage: image,
                roles: formValue.roles,
                defaultLanguage: stateAuth.appLanguage
              },
              callBack: onClose,
            },
          },
        });
      }
    }
  };

  const changeImage = (image: string) => {
    setImage(image);
  };

  const selectedStaff = (options: IdValue[]) => {
    setName(options as IdValue[]);
    setNameValue(options[0].value.split("-")[0]);

    const staff = selectedOrganizationData?.users?.find((st) => st.userId === options[0].id);
    if (staff) {
      formik.setValues({
        userId: staff.user?.id || staff.userId,
        userName: staff.user.name,
        userLastName: staff.user.lastName,
        userEmail: staff.user.email,
        roles: [],
      });
      setImage(staff.avatarImage);
    }
  };

  const selectedValueNameDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value.split("-")[0]);
    formik.handleChange(e);
    formik.handleBlur(e);
  };

  const usersAvailable = () =>
    selectedOrganizationData?.users?.filter((user) => {
      const userInTeam = team.staff?.find((staff) => staff.user?.id === user?.user?.id);
      if (userInTeam) {
        return false;
      }
      return true;
    });

  const optionsStaff = useMemo(() => {
    const users = usersAvailable();
    if (users) {
      return users.map((user) => {
        return { id: user?.userId, value: user?.user?.name + "-" + user?.user?.lastName };
      });
    }
    return [];
  }, [stateOrganization.organizationUsers.loading]);

  const onChangeRoles = (options: IdValue[]) => {
    setRoles(options);
    formik.setFieldValue(
      "roles",
      options.map((role) => role.id)
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-LmStaffForm">
        <div className="flex justify-center mb-8">
          <LoadImage size="lg" img={image} id="image" onChangeImage={changeImage} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full mb-8">
          {!staff ? (
            <div>
              <LmAutocomplete
                label={t("formLabel.name")}
                required={true}
                id="userName"
                value={name}
                valueDisplay={nameValue}
                multiple={false}
                options={optionsStaff as IdValue[]}
                setValueDisplay={selectedValueNameDisplay}
                selectedValue={(options) => selectedStaff(options as IdValue[])}
              />
              {formik.touched.userName && formik.errors.userName && <LmMessageError id="name" message={formik.errors.userName}></LmMessageError>}
            </div>
          ) : (
            <div>
              <LmInput
                required={true}
                id="userName"
                label={t("formLabel.name")}
                disabled={staff !== null}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.userName}></LmInput>
              {formik.touched.userName && formik.errors.userName && <LmMessageError id="userName" message={formik.errors.userName}></LmMessageError>}
            </div>
          )}

          <div>
            <LmInput
              required={true}
              id="userLastName"
              label={t("formLabel.lastName")}
              disabled={staff !== null}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.userLastName}></LmInput>
            {formik.touched.userLastName && formik.errors.userLastName && (
              <LmMessageError id="userLastName" message={formik.errors.userLastName}></LmMessageError>
            )}
          </div>
          <div>
            <LmInput
              required={true}
              id="userEmail"
              label={t("formLabel.email")}
              disabled={staff !== null}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.userEmail}></LmInput>
            {formik.touched.userEmail && formik.errors.userEmail && <LmMessageError id="userEmail" message={formik.errors.userEmail}></LmMessageError>}
          </div>
          <div>
            <LmSelect
              id="roles"
              required={true}
              label={t("formLabel.role")}
              multipleCheck={true}
              valueMultiple={roles}
              options={roleOptions}
              selectedValue={onChangeRoles}
            />
            {formik.touched.roles && roles?.length === 0 && <LmMessageError id="roles" message={t("errors.form.required")}></LmMessageError>}
          </div>
        </div>

        <div className="flex flex-wrap justify-end gap-4">
          <LmButton styleButton="secondary" type="solid" clickAction={onClose} size="small" text={`${t("buttonLabel.cancel")}`} iconPosition="right"></LmButton>
          <LmButton
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={!staff ? t("buttonLabel.create") : t("buttonLabel.save")}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
}
