import { Tab, Tabs } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import MatchStatisticGame from "../../components/match-statistic/MatchStatisticGame";
import MatchStatisticSummary from "../../components/match-statistic/MatchStatisticSummary";
import MatchStatisticTeam from "../../components/match-statistic/MatchStatisticTeam";
import MatchStatisticTeamMobile from "../../components/match-statistic/MatchStatisticTeamMobile";
import { SizeScreen } from "../../model/SizeSreen";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { ProjectCategoryDto, ProjectDto, Timeline } from "../../model/dto/ProjectDto";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT, ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL } from "../../routes/routes";

export default function MatchStatisticDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { sizeScreen }: InitialState = useSelector((state: MainState) => state.main);
  const { project }: ContentManagementState = useSelector((state: MainState) => state.content);

  const [selectedTab, setSelecteTab] = useState(0);
  const [statistic, setStatistic] = useState<ProjectDto | null>(null);
  const [eventsCategory, setEventsCategory] = useState<ProjectCategoryDto[]>([]);
  const [timelinesPeriod, setTimelinesPeriod] = useState<Timeline[]>([]);
  const [periodSelected, setPeriodSelected] = useState('All')

  useEffect(() => {
    setStatistic(project.data);
    setEventsCategory(project.eventsCategory);
  }, [project]);

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelecteTab(newValue);
  };

  const goBack = (categoryName?: string) => {
    const { documentId } = params;

    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL });    
    let url = `/${ROUTE_PRIVATE_CONTENT_MANAGEMENT}/${documentId}`;
    if(categoryName) {
      url += `?event=${categoryName}`;
    }
    navigate(url);
  };

  return (
    <>
      <div className="px-5 lg:px-12  mt-20 lg:mt-0" data-testid="page-content-management">
        {statistic ? (
          <div className="xl:flex-1 py-8">
            <MatchStatisticSummary statistic={statistic} sizeScreen={sizeScreen} goBackClick={goBack} timelines={timelinesPeriod} period={periodSelected}></MatchStatisticSummary>

            <div className="mt-4">
              <Tabs className="shadow-border" textColor="inherit" indicatorColor="primary" value={selectedTab} onChange={onChangeTab}>
                <Tab
                  sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize", fontWeight: selectedTab === 0 ? "semibold" : "normal" }}
                  label={t("content.detailMatchStatisticGame.game")}
                />
                <Tab
                  sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize", fontWeight: selectedTab === 1 ? "semibold" : "normal" }}
                  label={t("content.detailMatchStatisticPlayer")}
                />
              </Tabs>
            </div>
            {selectedTab === 0 && <MatchStatisticGame statistic={statistic} eventsCategory={eventsCategory} timelinesPeriod={(timelines)=>setTimelinesPeriod(timelines)} periodSelected={(period)=>setPeriodSelected(period)}></MatchStatisticGame>}
            {selectedTab === 1 &&  sizeScreen === SizeScreen.DESKTOP && <MatchStatisticTeam statistic={statistic} eventsCategory={eventsCategory} categoryNavigation={(categoryName) => goBack(categoryName)}></MatchStatisticTeam>}
            {selectedTab === 1 &&  sizeScreen !== SizeScreen.DESKTOP && <MatchStatisticTeamMobile statistic={statistic} eventsCategory={eventsCategory} categoryNavigation={(categoryName) => goBack(categoryName)}></MatchStatisticTeamMobile>}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
