import { Dialog } from "@mui/material";
import { t } from "i18next";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { TemplatePlayerDto } from "../../model/dto/TemplatePlayerDto";
import TemplatePlayerData from "./PlayerData";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";

interface Props {
  open: boolean;
  handleClose: () => void;
  player: TemplatePlayerDto;
}
const ModalTemplatePlayer = ({ open, player, handleClose }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      {player && <div className="p-5 md:p-8">
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl">{t("player.viewTitle", { name: `${player.Name} ${player.LastName}`, number: player.Number})}</p>
          <Close onClick={onClose} className="cursor-pointer" />
        </div>
        <TemplatePlayerData player={player}></TemplatePlayerData>
      </div>}
    </Dialog>
  );
};

export default ModalTemplatePlayer;
