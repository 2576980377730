import Dialog from "@mui/material/Dialog";
import { t } from "i18next";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ProjectDto, Timeline } from "../../model/dto/ProjectDto";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import LmInput from "../input/LmInput";
import MatchFieldViewer from "../match-statistic/MatchFieldViewer";
interface IProjectAnalysisDetail {
  statistic: ProjectDto;
  timeline: Timeline | null;
  open: boolean;
  handleClose(): void;
}
const ProjectAnalysisDetail: React.FC<IProjectAnalysisDetail> = ({ statistic, timeline, open, handleClose }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const tags = useMemo(() => {
    return timeline?.Tags;
  }, [timeline]);

  const commonTags = useMemo(
    () =>
      tags
        ?.filter((tag) => tag.Group === "")
        .map((tag) => tag.Value)
        .join(","),
    [timeline]
  );
  const otherTags = useMemo(() => tags?.filter((tag) => tag.Group !== ""), [timeline]);

  
  const fieldPosition = timeline?.FieldPosition !== null;
  const halfFieldPosition = timeline?.HalfFieldPosition !== null;
  const goalPositionPosition = timeline?.GoalPosition !== null;
  
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={(fieldPosition || halfFieldPosition ||  goalPositionPosition) ? 'xl' : 'md'} fullWidth>
      <div className="p-6">
        <div className="flex justify-between items-center mb-8">
          <p className="text-lg lg:text-2xl font-decorative text-decorative">{t("content.evenDetail")}</p>
          <Close className="cursor-pointer" onClick={handleClose} />
        </div>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1 space-y-4">
            <div>
              <LmInput readonly={true} label={t("formLabel.name")} disabled={true} value={timeline?.Name}></LmInput>
            </div>
            <div>
              <LmInput readonly={true} label={t("formLabel.commonTags")} disabled={true} value={commonTags}></LmInput>
            </div>
            <div>
              <LmInput readonly={true} label={t("formLabel.notes")} disabled={true} value={timeline?.Notes ?? ""}></LmInput>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {otherTags?.map((tag) => (
                <div key={tag.$id}>
                  <LmInput readonly={true} label={tag.Group} disabled={true} value={tag.Value}></LmInput>
                </div>
              ))}
            </div>
          </div>
          {(fieldPosition || halfFieldPosition ||  goalPositionPosition) && <div className="flex-1">
            {fieldPosition &&  <div className="mb-4">
              <MatchFieldViewer
                  timelines={[timeline as Timeline]}
                  background={stateContent.project.data?.Dashboard.FieldBackground ?? ""}
                  imageType="FieldPosition"
                  statistic={statistic}                           
                />
              </div>}
              {(halfFieldPosition || goalPositionPosition) && <div className="grid sm:grid-cols-2 gap-4">
                {halfFieldPosition &&  <MatchFieldViewer
                    timelines={[timeline as Timeline]}
                    background={stateContent.project.data?.Dashboard.HalfFieldBackground ?? ""}
                    imageType="HalfFieldPosition"
                    statistic={statistic}                
                  />}
                  {goalPositionPosition &&  <MatchFieldViewer
                    timelines={[timeline as Timeline]}
                    background={stateContent.project.data?.Dashboard.GoalBackground ?? ""}
                    imageType="GoalPosition"
                    statistic={statistic}                
                  />}
              </div>}
          </div>}
        </div>
      </div>
    </Dialog>
  );
};
export default ProjectAnalysisDetail;
