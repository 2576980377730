import { t } from "i18next";
import { convertMilisecondsToHoursMinutesSeconds } from "../../utils/utils";

interface Props {
  elapsedTime: number;
  points: number;
  admonition: number;
}

const MatchStatisticTeamSummary = ({ elapsedTime, points, admonition }: Props) => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-4 md:gap-8 bg-green-50 text-sm font-semibold text-subtle leading-8 py-2 px-4">
      <p>
        {t("content.playedTime")}
        <span className="text-decorative">{convertMilisecondsToHoursMinutesSeconds(elapsedTime)}</span>
      </p>
      <p>
        {t("content.points")}
        <span className="text-decorative">{points}</span>
      </p>
      <p>
        {t("content.admonition")}
        <span className="text-decorative">{admonition}</span>
      </p>
    </div>
  );
};

export default MatchStatisticTeamSummary;
