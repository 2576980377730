export enum TeamType {
  LOCAL = 1,
  VISITOR = 2,
}

export interface ClipToReactPlayer {
  id: string;
  start: number;
  stop: number;
  drawings: Drawing[];
}
export interface IFilterApplied {
  event: string[];
  tags: string[];
  teams: string[];
  player: string[];
  period: string[];
}

export interface ProjectCategoryDto {
  event: string;
  name: string;
  color: string;
  timelines: Timeline[];
  tags?: Tag[];
}

export interface ProjectPlayeryDto {
  id: string;
  player: TeamPlayer | undefined;
  team: TeamTemplate;
  timelines: Timeline[];
}

export interface Lapse {
  from: number;
  to: number;
  eventName: string;
  color: string;
}
export interface AddVideoClip {
  relatedFileId: string;
  lapses: Lapse[];
}

export interface ProjectDto {
  $id: string;
  $type: string;
  ID: string;
  Dashboard: Dashboard;
  LocalTeamTemplate: TeamTemplate;
  VisitorTeamTemplate: TeamTemplate;
  EventTypes: (EventType | Ref)[];
  Description: Description;
  Timeline: Timeline[];
  Periods: Period[];
  Timers: any[];
  Playlists: Playlist[];
  FieldId: number;
  Version: number;
  CreationDate: string;
}

export interface Dashboard {
  $id: string;
  $type: string;
  ID: string;
  Version: number;
  List: EventList[];
  Name: string;
  GamePeriods: string[];
  Image: any;
  FieldBackground: string;
  HalfFieldBackground: string;
  GoalBackground: string;
  DisablePopupWindow: boolean;
  Preview: string;
  InitialTemplate: boolean;
  CreationDate: string;
}

export interface EventList {
  $id: string;
  $type: string;
  ShowSubcategories?: boolean;
  TagsPerRow?: number;
  EventType?: EventType | Ref;
  TagMode?: number;
  Start?: number;
  Stop?: number;
  Position: string;
  Width: number;
  Height: number;
  TextColor: string;
  HotKey: string;
  BackgroundImage: any;
  ShowIcon: boolean;
  ShowHotkey: boolean;
  ShowSettingIcon: boolean;
  ActionLinks: any[];
  Score?: any;
  PenaltyCard?: any;
  Tag?: Tag;
  Name?: string;
  BackgroundColor?: string;
  FontSize?: number;
}

export interface EventType {
  $id: string;
  $type: string;
  ID: string;
  Tags?: Tag[];
  Name: string;
  Color: string;
  FontSize: number;
  TagGoalPosition: boolean;
  TagFieldPosition: boolean;
  TagHalfFieldPosition: boolean;
  FieldPositionIsDistance: boolean;
  HalfFieldPositionIsDistance: boolean;
  SortMethod: number;
  AllowLocation: boolean;
  Version: number;
  CreationDate: string;
  Score?: Score;
  PenaltyCard?: PenaltyCard;
}

export interface Ref {
  $ref: string;
}

export interface Tag {
  $id: string;
  $type: string;
  Group: string;
  Value: string;
  HotKey: string;
}

export interface Score {
  $id: string;
  $type: string;
  Color: string;
  Name: string;
  Points: number;
}

export interface PenaltyCard {
  $id: string;
  $type: string;
  Name: string;
  Color: string;
  Shape: number;
}

export interface TeamTemplate {
  $id: string;
  $type: string;
  ID: string;
  TeamName: string;
  ActiveColor: number;
  Colors: string[];
  Formation: number[];
  Name: string;
  Shield: string;
  Preview: string;
  List: TeamPlayer[];
  InitialTemplate: boolean;
  Version: number;
  CreationDate: string;
}

export interface TeamPlayer {
  $id: string;
  $type: string;
  ID: string;
  Position: string;
  Number: number;
  Birthday: string;
  Height: number;
  Weight: number;
  Playing: boolean;
  Name: string;
  LastName: string;
  NickName: string;
  Photo: string;
  Nationality: string;
  Mail: any;
  Version: number;
  CreationDate: string;
}

export interface Description {
  $id: string;
  $type: string;
  TitleAutoGenerated: boolean;
  Title: string;
  FileSet: FileSet;
  DashboardName: string;
  Season: string;
  Competition: string;
  Category: string;
  Description: string;
  Group: string;
  Phase: string;
  LocalName: string;
  VisitorName: string;
  LocalGoals: number;
  VisitorGoals: number;
  LocalShield: string;
  VisitorShield: string;
  MatchDate: string;
  LastModified: string;
}

export interface FileSet {
  $id: string;
  $type: string;
  ID: string;
  VisibleRegion: VisibleRegion;
  IsStretched: boolean;
  MediaFiles: MediaFile[];
  Version: number;
  CreationDate: string;
}

export interface VisibleRegion {
  $id: string;
  $type: string;
  Name: any;
  Start: number;
  Stop: number;
  EventTime: number;
  Rate: number;
}

export interface MediaFile {
  $id: string;
  $type: string;
  FilePath: string;
  Duration: number;
  HasVideo: boolean;
  HasAudio: boolean;
  Container: string;
  VideoCodec: string;
  AudioCodec: any;
  VideoWidth: number;
  VideoHeight: number;
  DisplayVideoWidth: number;
  DisplayVideoHeight: number;
  Fps: number;
  Par: number;
  Preview: string;
  Offset: number;
  Name: string;
}

export interface Timeline {
  $id: string;
  $type: string;
  HomeStartingPlayers?: HomeStartingPlayer[];
  HomeBenchPlayers?: HomeBenchPlayer[];
  AwayStartingPlayers?: AwayStartingPlayer[];
  AwayBenchPlayers?: AwayBenchPlayer[];
  Start: number;
  Stop: number;
  ID: string;
  EventTime: number;
  Miniature?: string | null;
  Name: string;
  EventType: EventType | Ref;
  Notes?: string | null;
  Players: Player[];
  Teams: Team[];
  Drawings: Drawing[];
  FieldPosition?: FieldPosition | null;
  HalfFieldPosition?: HalfFieldPosition | null;
  GoalPosition?: GoalPosition | null;
  CamerasLayout: any;
  CamerasConfig: CamerasConfig[];
  FileSet: FileSet | Ref;
  Tags: Tag[];
  Version: number;
  CreationDate: string;
  Rate: number;
  In?: In;
  Out?: Out;
  Reason?: number;
  Color?: string;
}

export interface Clip {
  id: string;
  organizationId: string;
  relatedFileId: string;
  eventName: string;
  color: string;
  from: number;
  to: number;
}

export interface HomeStartingPlayer {
  $ref: string;
}

export interface HomeBenchPlayer {
  $ref: string;
}

export interface AwayStartingPlayer {
  $ref: string;
}

export interface AwayBenchPlayer {
  $ref: string;
}

export interface Player {
  $ref: string;
}

export interface Team {
  $ref: string;
}

export interface Drawing {
  $id: string;
  $type: string;
  CameraConfig: CameraConfig;
  RegionOfInterest: RegionOfInterest;
  Miniature: string;
  Freehand: any;
  Drawables: any[];
  Shapes: Shapes;
  Render: number;
  Pause: number;
  MeasureReference: MeasureReference;
  DrawingWithoutBackground: string;
}

export interface CameraConfig {
  $id: string;
  $type: string;
  Index: number;
  RegionOfInterest: RegionOfInterest;
}

export interface RegionOfInterest {
  $id: string;
  $type: string;
  Start: string;
  Width: number;
  Height: number;
}

export interface Shapes {
  $id: string;
  $type: string;
  Children: Children[];
  CTM: Ctm;
  X: number;
  Y: number;
  Width: number;
  Height: number;
  ClipRegion: string;
  FillColor: string;
  StrokeColor: string;
  Hatch: Hatch;
  LineWidth: number;
  LineDash: any;
  IsClickable: boolean;
  IsSelectable: boolean;
  IsDraggable: boolean;
  IsResizable: boolean;
  IsZIndexLocked: boolean;
  TranslationX: number;
  TranslationY: number;
  ScaleX: number;
  ScaleY: number;
  Rotation: number;
  StrokeShaders: any[];
  FillShaders: any[];
  State: number;
  Visible: boolean;
}

export interface Children {
  $id: string;
  $type: string;
  Areas?: Area[];
  CTM: Ctm;
  LinkLineWidth?: number;
  Base?: number;
  X: number;
  Y: number;
  Width: number;
  Height: number;
  ClipRegion: string;
  FillColor: string;
  StrokeColor: string;
  Hatch: Hatch;
  LineWidth: number;
  LineDash: any;
  IsClickable: boolean;
  IsSelectable: boolean;
  IsDraggable: boolean;
  IsResizable: boolean;
  IsZIndexLocked: boolean;
  TranslationX: number;
  TranslationY: number;
  ScaleX: number;
  ScaleY: number;
  Rotation: number;
  StrokeShaders: any[];
  FillShaders: any[];
  State: number;
  Visible: boolean;
  StartEnding?: number;
  StopEnding?: number;
  LineStyle?: number;
  Gradient?: any;
  SpotStyle?: number;
  BaseHeight?: number;
  Style?: number;
  Text?: string;
  Escape?: boolean;
  Ellipsize?: boolean;
  Font?: string;
  Points?: string[];
  Name?: string;
  Number?: number;
  Image?: Image;
  ShowImage?: boolean;
  AngleDeg?: number;
  StopX?: number;
  StopY?: number;
  AngleStyle?: number;
  ShowDegrees?: boolean;
  TopSide?: TopSide;
  BottomSide?: BottomSide;
  MidX?: number;
  MidY?: number;
  ControlX?: number;
  ControlY?: number;
  RadiusX?: number;
  RadiusY?: number;
}

export interface Area {
  $id: string;
  $type: string;
  CTM: any;
  LineStyle: number;
  Style: number;
  RadiusX: number;
  RadiusY: number;
  X: number;
  Y: number;
  Width: number;
  Height: number;
  ClipRegion: string;
  FillColor: string;
  StrokeColor: string;
  Hatch: Hatch;
  LineWidth: number;
  LineDash: any;
  IsClickable: boolean;
  IsSelectable: boolean;
  IsDraggable: boolean;
  IsResizable: boolean;
  IsZIndexLocked: boolean;
  TranslationX: number;
  TranslationY: number;
  ScaleX: number;
  ScaleY: number;
  Rotation: number;
  StrokeShaders: any[];
  FillShaders: any[];
  State: number;
  Visible: boolean;
}

export interface Hatch {
  $id: string;
  $type: string;
  Width: number;
  Spacing: number;
  Angle: number;
  IsEmpty: boolean;
}

export interface Ctm {
  $id: string;
  $type: string;
  TranslationX: number;
  TranslationY: number;
  ScaleX: number;
  ScaleY: number;
  SkewX: number;
  SkewY: number;
}

export interface Image {
  $id: string;
  $type: string;
  width: number;
  height: number;
  Raw: Raw;
  Format: number;
}

export interface Raw {
  $type: string;
  $value: string;
}

export interface TopSide {
  $id: string;
  $type: string;
  Item1: number;
  Item2: number;
}

export interface BottomSide {
  $id: string;
  $type: string;
  Item1: number;
  Item2: number;
}

export interface MeasureReference {
  $id: string;
  $type: string;
  Value: number;
  Distance: number;
  Unit: number;
}

export interface FieldPosition {
  $id: string;
  $type: string;
  Points: string[];
}

export interface HalfFieldPosition {
  $id: string;
  $type: string;
  Points: string[];
}

export interface GoalPosition {
  $id: string;
  $type: string;
  Points: string[];
}

export interface CamerasConfig {
  $id: string;
  $type: string;
  Index: number;
  RegionOfInterest: RegionOfInterest;
}

export interface In {
  $ref: string;
}

export interface Out {
  $ref: string;
}

export interface Period {
  $id: string;
  $type: string;
  ID: string;
  Name: string;
  Nodes: Node[];
  Version: number;
  CreationDate: string;
}

export interface Node {
  $id: string;
  $type: string;
  Name: string;
  Start: number;
  Stop: number;
  EventTime: number;
  Rate: number;
}

export interface Playlist {
  $id: string;
  $type: string;
  ID: string;
  Name: string;
  Elements: Element[];
  Duration: number;
  LastModified: string;
  Version: number;
  CreationDate: string;
}

export interface Element {
  $id: string;
  $type: string;
  Play: Play | { $ref: string };
  Title: string;
  Rate: number;
  RateString: string;
  CamerasLayout: any;
  CamerasConfig: CamerasConfig[];
}

export interface Play {
  $id?: string;
  $type?: string;
  ID?: string;
  Start?: number;
  Stop?: number;
  EventTime?: number;
  Miniature: any;
  Name?: string;
  EventType?: EventType | Ref;
  Notes?: string;
  Players?: any[];
  Teams?: Team[];
  Drawings?: any[];
  FieldPosition?: FieldPosition | null;
  HalfFieldPosition?: any | null;
  GoalPosition?: any | null;
  CamerasLayout: any;
  CamerasConfig?: CamerasConfig[];
  FileSet?: FileSet | Ref;
  Tags?: Tag[];
  Version?: number;
  CreationDate?: string;
  Rate?: number;
  $ref?: string;
}
