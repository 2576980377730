import { useDispatch } from "react-redux";
import { TypeActions } from "../model/actions/typeActions";
import { ActionNotificationsType } from "../model/actions/typeNotifications";

const useNotifications = () => {
  const dispatch = useDispatch();

  const checkNotification = (id: string) => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_CHECK_NOTIFICATION, value: id });
  };
  const uncheckNotification = (id: string) => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_UNCHECK_NOTIFICATION, value: id });
  };

  const markAsRead = (ids: string[], clearSelectedOptions?: boolean) => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_LOADING, value: ids });
    if (clearSelectedOptions) uncheckAllNotifications();
  };
  const markAsUnread = (ids: string[], clearSelectedOptions?: boolean) => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_LOADING, value: ids });
    if (clearSelectedOptions) uncheckAllNotifications();
  };

  const deleteNotification = (ids: string[], clearSelectedOptions?: boolean) => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_LOADING, value: ids });
    if (clearSelectedOptions) uncheckAllNotifications();
  };

  const checkAllNotifications = () => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_CHECK_ALL_NOTIFICATION });
  };
  const uncheckAllNotifications = () => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_UNCHECK_ALL_NOTIFICATION });
  };

  return { checkNotification, uncheckNotification, markAsRead, markAsUnread, deleteNotification, checkAllNotifications, uncheckAllNotifications };
};

export default useNotifications;
