import { TypeActions } from "../../model/actions/typeActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { OrganizationState } from "../../model/states/OrganizationState";
import { ViewMode } from "../../model/ViewMode";
import { organizationState } from "../states/organizationState";

export const organizationReducer = (state: OrganizationState = organizationState, action: TypeActions): OrganizationState => {
  switch (action.type) {
    case ActionOrganizationType.ACTION_CREATE_ORGANIZATION_LOADING: {
      return { ...state, organizationForm: { ...state.organizationForm, loading: true, ok: false } };
    }
    case ActionOrganizationType.ACTION_CREATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizationForm: { ...state.organizationForm, loading: false, ok: true, activeTab: 1, viewMode: ViewMode.EDIT },
      };
    }
    case ActionOrganizationType.ACTION_CREATE_ORGANIZATION_ERROR: {
      return { ...state, organizationForm: { ...state.organizationForm, loading: false, ok: false } };
    }
    case ActionOrganizationType.ACTION_EDIT_ORGANIZATION_LOADING: {
      return { ...state, organizationForm: { ...state.organizationForm, loading: true, ok: false } };
    }
    case ActionOrganizationType.ACTION_EDIT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizationForm: { ...state.organizationForm, loading: false, ok: true, activeTab: 1, viewMode: ViewMode.EDIT },
      };
    }
    case ActionOrganizationType.ACTION_EDIT_ORGANIZATION_ERROR: {
      return { ...state, organizationForm: { ...state.organizationForm, loading: false, ok: false } };
    }
    case ActionOrganizationType.ACTION_CHANGE_MAIN_DATA_FORM_VALUE: {
      return {
        ...state,
        organizationForm: {
          ...state.organizationForm,
          mainData: { ...state.organizationForm.mainData, [action.value.key]: action.value.value },
        },
      };
    }
    case ActionOrganizationType.ACTION_SELECT_ORGANIZATION: {
      return {
        ...state,
        organizationForm: { ...state.organizationForm, viewMode: ViewMode.EDIT },
      };
    }
    case ActionOrganizationType.ACTION_SELECT_LICENSE: {
      return {
        ...state,
        selectedLicense: action.value,
      };
    }
    case ActionOrganizationType.ACTION_CLEAN_ORGANIZATION_FORM: {
      return {
        ...state,
        organizationForm: {
          ...state.organizationForm,
          mainData: organizationState.organizationForm.mainData,
        },
      };
    }
    case ActionOrganizationType.ACTION_CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        organizationForm: {
          ...state.organizationForm,
          activeTab: action.value,
        },
      };
    }
    case ActionOrganizationType.ACTION_CHANGE_VIEW_MODE: {
      return {
        ...state,
        organizationForm: {
          ...state.organizationForm,
          viewMode: action.value,
        },
      };
    }

    case ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_LOADING: {
      return { ...state, exit: { ...state.exit, loading: true, ok: false, error: false } };
    }

    case ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_SUCCESS: {
      return { ...state, exit: { ...state.exit, loading: false, ok: true, error: false } };
    }

    case ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_ERROR: {
      return { ...state, exit: { ...state.exit, loading: false, ok: false, error: true } };
    }

    case ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_RESET: {
      return { ...state, exit: { ...state.exit, loading: false, ok: false, error: false } };
    }

    case ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_LOADING: {
      return { ...state, organizationSumary: { loading: true, ok: false, error: false, data: null } };
    }
    case ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_SUCCESS: {
      return { ...state, organizationSumary: { loading: false, ok: true, error: false, data: action.value } };
    }
    case ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_ERROR: {
      return { ...state, organizationSumary: { loading: false, ok: false, error: true, data: null } };
    }
    case ActionOrganizationType.ACTION_CHANGE_TEAM_TAB_SELECTED: {
      return {
        ...state,
        teamTabSelected: action.value,
      };
    }
    case ActionOrganizationType.ACTION_DELETE_ORGANIZATION_LOADING: {
      return { ...state, organizationForm: { ...state.organizationForm, loading: true, ok: false } };
    }
    case ActionOrganizationType.ACTION_DELETE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        exit: { ...state.exit, ok: true },
        organizationForm: { ...state.organizationForm, loading: false, ok: true, activeTab: 0, viewMode: ViewMode.CREATE },
      };
    }
    case ActionOrganizationType.ACTION_DELETE_ORGANIZATION_ERROR: {
      return { ...state, organizationForm: { ...state.organizationForm, loading: false, ok: false } };
    }
    case ActionOrganizationType.ACTION_RESET_ORGANIZATION_EXIT: {
      return { ...state, exit: { ...state.exit, loading: false, ok: false, error: false } };
    }

    case ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING: {
      return { ...state, organizationUsers: { ...state.organizationUsers, loading: true, ok: false, error: false } };
    }
    case ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_SUCCESS: {
      return { ...state, organizationUsers: { ...state.organizationUsers, loading: false, ok: true, error: false } };
    }
    case ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_ERROR: {
      return { ...state, organizationUsers: { ...state.organizationUsers, loading: false, ok: false, error: true } };
    }

    case ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_LOADING: {
      return { ...state, organizationUsers: { ...state.organizationUsers, editUser: { loading: true, ok: false, error: false } } };
    }
    case ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_SUCCESS: {
      return { ...state, organizationUsers: { ...state.organizationUsers, editUser: { loading: false, ok: true, error: false } } };
    }
    case ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_ERROR: {
      return { ...state, organizationUsers: { ...state.organizationUsers, editUser: { loading: false, ok: false, error: true } } };
    }

    case ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_LOADING: {
      return { ...state, organizationUsers: { ...state.organizationUsers, pendingInvitations: { loading: true, ok: false, error: false } } };
    }
    case ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_SUCCESS: {
      return { ...state, organizationUsers: { ...state.organizationUsers, pendingInvitations: { loading: false, ok: true, error: false } } };
    }
    case ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_ERROR: {
      return { ...state, organizationUsers: { ...state.organizationUsers, pendingInvitations: { loading: false, ok: false, error: true } } };
    }

    case ActionOrganizationType.ACTION_CHANGE_USER_NAME_FILTER: {
      return { ...state, organizationUsers: { ...state.organizationUsers, filter: { ...state.organizationUsers.filter, name: action.value } } };
    }
    case ActionOrganizationType.ACTION_CHANGE_USER_ROLES_FILTER: {
      return { ...state, organizationUsers: { ...state.organizationUsers, filter: { ...state.organizationUsers.filter, roles: action.value } } };
    }
    case ActionOrganizationType.ACTION_CHANGE_USER_TEAMS_FILTER: {
      return { ...state, organizationUsers: { ...state.organizationUsers, filter: { ...state.organizationUsers.filter, teams: action.value } } };
    }
    case ActionOrganizationType.ACTION_CHANGE_USER_QUICK_FILTER: {
      return {
        ...state,
        organizationUsers: { ...state.organizationUsers, filter: { name: state.organizationUsers.filter?.name || undefined }, quickRoles: action.value },
      };
    }
    case ActionOrganizationType.ACTION_CLEAN_USER_FILTER: {
      return { ...state, organizationUsers: { ...state.organizationUsers, filter: { name: state.organizationUsers.filter?.name || undefined } } };
    }
    case ActionOrganizationType.ACTION_CLEAN_USER_QUICK_FILTER: {
      return { ...state, organizationUsers: { ...state.organizationUsers, quickRoles: undefined } };
    }

    case ActionOrganizationType.ACTION_CHANGE_SORT_ORGANIZATION_USER: {
      return { ...state, organizationUsers: { ...state.organizationUsers, sort: action.value } };
    }

    default: {
      return { ...state };
    }
  }
};
