import { ProjectCategoryDto } from "../../model/dto/ProjectDto";
import { parseColor } from "../../utils/utils";

interface Props {
  category: ProjectCategoryDto;
  active: boolean;
  onCategoryClicked: (category: ProjectCategoryDto) => void;
}

const MatchStatisticCategory = ({ category, active, onCategoryClicked }: Props) => {
  return (
    <div
      className={`max-w-full overflow-hidden border-l-4 border-b border-b-neutral-200 py-2 px-4 cursor-pointer transition hover:bg-neutral-50 ${active ? 'bg-[#45e9ad1a]' : ''}`}
      style={{ borderLeftColor: parseColor(category.color) }}
      onClick={() => onCategoryClicked(category)}>
      <p className="text-sm font-semibold leading-8 truncate" title={category.name}>
        {category.name}
      </p>
    </div>
  );
};

export default MatchStatisticCategory;
