import LmButton from "../button/LmButton";
import LmSelect from "../select/LmSelect";
import { useTranslation } from "react-i18next";
import { ReactComponent as ClearIcon } from "../../assets/icons/x.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { getNotificationsType, getReadType } from "../../utils/utils";
import { IdValue } from "../../model/forms/IdValue";
import LmInput from "../input/LmInput";
import { DateRange } from "../../model/DateRange";
import { useSelector } from "react-redux";
import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";

interface Props {
  onSearch(readType: IdValue[], notificationsType: IdValue[], dateRange: DateRange): void;
  onClearFilter(): void;
  notificationsType: IdValue[];
  setNotificationsType(options: IdValue[]): void;
  readType: IdValue[];
  setReadType(options: IdValue[]): void;
  dateRange: DateRange;
  setDateRange(dateRange: DateRange): void;
}

const LmNotificationsFilter = ({ onSearch, onClearFilter, notificationsType, setNotificationsType, readType, setReadType, dateRange, setDateRange }: Props) => {
  const { t } = useTranslation();
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const notificationsTypeOptions = getNotificationsType();
  const readTypeOptions = getReadType();

  const handleClearFilter = () => {
    onClearFilter();
  };

  const handleSearch = () => {
    onSearch(readType, notificationsType, dateRange);
  };

  const onChangeNotificationsTypeFilter = (options: IdValue[]) => {
    setNotificationsType(options);
  };

  const onChangeReadTypeFilter = (options: IdValue[]) => {
    setReadType(options);
  };

  const onChangeStartDate = (startDate: string) => {
    if (dateRange.end) {
      const newRange = { start: startDate, end: dateRange.end };
      setDateRange(newRange);
    } else {
      setDateRange({ start: startDate, end: new Date().toISOString() });
    }
  };

  const onChangeEndDate = (endDate: string) => {
    const newRange = { start: dateRange.start, end: endDate };
    setDateRange(newRange);
  };

  return (
    <div className={`m-4 space-y-4 min-w-0 sm:min-w-[20rem] theme-${stateMain.colorAplication}`}>
      <LmSelect
        id="notification-type-select"
        label={t("formLabel.notificationType")}
        multipleCheck={true}
        valueMultiple={notificationsType}
        options={notificationsTypeOptions}
        selectedValue={onChangeNotificationsTypeFilter}
      />
      <LmSelect
        id="notification-read-select"
        label={t("formLabel.read")}
        multipleCheck={true}
        valueMultiple={readType}
        options={readTypeOptions}
        selectedValue={onChangeReadTypeFilter}
      />
      {stateMain.sizeScreen !== SizeScreen.MOBILE ? (
        <div className="flex space-x-2">
          <LmInput
            id="date-start"
            label={t("formLabel.dateStart")}
            changeValue={(e) => onChangeStartDate(e.target.value)}
            type="date"
            max={dateRange.end}
            value={dateRange.start}></LmInput>
          <LmInput
            id="date-end"
            label={t("formLabel.dateEnd")}
            changeValue={(e) => onChangeEndDate(e.target.value)}
            type="date"
            min={dateRange.start}
            value={dateRange.end}></LmInput>
        </div>
      ) : (
        <>
          <LmInput
            id="date-start"
            label={t("formLabel.dateStart")}
            changeValue={(e) => onChangeStartDate(e.target.value)}
            type="date"
            max={dateRange.end}
            value={dateRange.start}></LmInput>
          <LmInput
            id="date-end"
            label={t("formLabel.dateEnd")}
            changeValue={(e) => onChangeEndDate(e.target.value)}
            type="date"
            min={dateRange.start}
            value={dateRange.end}></LmInput>
        </>
      )}
      <div className="flex justify-end mt-8 space-x-2">
        <div className="add-button-container">
          <LmButton
            styleButton="secondary"
            buttonType="submit"
            type="solid"
            size="extra-small"
            text={t("buttonLabel.clear")}
            icon={<ClearIcon />}
            iconPosition="center"
            clickAction={handleClearFilter}
          />
        </div>
        <div className="add-button-container">
          <LmButton
            styleButton="organization"
            type="solid"
            size="extra-small"
            text={t("buttonLabel.search")}
            iconPosition="left"
            clickAction={handleSearch}
            icon={<SearchIcon />}></LmButton>
        </div>
      </div>
    </div>
  );
};

export default LmNotificationsFilter;
