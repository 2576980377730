import LmMainDataForm from "../../components/new-organization-forms/LmMainDataForm";

const MainData = () => {
  return (
    <div className="mt-7" data-testid="organization-main-data">
      <LmMainDataForm modal={false} />
    </div>
  );
};

export default MainData;
