import { TypeActions } from "../../model/actions/typeActions";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
import { PlayerState } from "../../model/states/PlayerState";
import { playerState } from "../states/playerState";

export const playerReducer = (state: PlayerState = playerState, action: TypeActions): PlayerState => {
  switch (action.type) {
    case ActionPlayerType.ACTION_CREATE_PLAYER_LOADING: {
      return {
        ...state,
        dataPlayer: { loading: true, error: false, ok: false },
      };
    }

    case ActionPlayerType.ACTION_CREATE_PLAYER_SUCCESS: {
      return {
        ...state,
        dataPlayer: { loading: false, error: false, ok: action.value },
      };
    }

    case ActionPlayerType.ACTION_CREATE_PLAYER_ERROR: {
      return {
        ...state,
        dataPlayer: { loading: false, error: true, ok: false },
      };
    }

    case ActionPlayerType.ACTION_EDIT_PLAYER_LOADING: {
      return {
        ...state,
        dataPlayer: { loading: true, error: false, ok: false },
      };
    }

    case ActionPlayerType.ACTION_EDIT_PLAYER_SUCCESS: {
      return {
        ...state,
        dataPlayer: { loading: false, error: false, ok: action.value },
      };
    }

    case ActionPlayerType.ACTION_EDIT_PLAYER_ERROR: {
      return {
        ...state,
        dataPlayer: { loading: false, error: true, ok: false },
      };
    }

    case ActionPlayerType.ACTION_COUNTRIES_LOADING: {
      return {
        ...state,
        countries: { loading: true, error: false, data: [] },
      };
    }

    case ActionPlayerType.ACTION_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: { loading: true, error: false, data: action.value },
      };
    }

    case ActionPlayerType.ACTION_COUNTRIES_ERROR: {
      return {
        ...state,
        countries: { loading: true, error: false, data: [] },
      };
    }

    case ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING: {
      return {
        ...state,
        dataPlayerUnregistered: { loading: true, error: false, ok: false },
      };
    }

    case ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_SUCCESS: {
      return {
        ...state,
        dataPlayerUnregistered: { loading: false, error: false, ok: true },
      };
    }

    case ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_ERROR: {
      return {
        ...state,
        dataPlayerUnregistered: { loading: false, error: true, ok: false },
      };
    }

    default: {
      return { ...state };
    }
  }
};
