import { all } from "redux-saga/effects";
import rootAuthSaga from "./sagaAuth";
import rootContentManagementSaga from "./sagaContentManagement";
import rootOrganizationSaga from "./sagaOrganization";
import rootPlayerSaga from "./sagaPlayer";
import rootTeamSaga from "./sagaTeam";
import rootCategoriesSaga from "./sagaCategories";
import rootTagsSaga from "./sagaTags";
import rootNotificationsSaga from "./sagaNotifications";

export default function* rootSaga() {
  yield all([
    rootAuthSaga(),
    rootOrganizationSaga(),
    rootTeamSaga(),
    rootPlayerSaga(),
    rootContentManagementSaga(),
    rootCategoriesSaga(),
    rootTagsSaga(),
    rootNotificationsSaga(),
  ]);
}
