import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as Folder } from "../../assets/icons/folder.svg";

import { IFilterApplied, ProjectCategoryDto, ProjectDto, ProjectPlayeryDto, Ref, TeamTemplate, Timeline } from "../../model/dto/ProjectDto";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import AnalysisAccordion from "../analysis-item/AnalysisAccordion";
import AnalysisItemByCategory from "../analysis-item/AnalysisItemByCategory";

import { Popover } from "@mui/material";
import AnalysisHeaderItemByTeam from "../analysis-item/AnalysisHeaderItemByTeam";
import AnalysisItemByTeam from "../analysis-item/AnalysisItemByTeam";
import LmButton from "../button/LmButton";
import LmCheckBox from "../check-box/LmCheckBox";
import LmModalAddToPlaylist from "../modal-add-to-playlist/LmModalAddToPlaylist";
import ProjectAnalysisDetail from "./ProjectAnalysisDetail";
import LmModalInfo from "../modal-info/LmModalInfo";
import ProjectAnalysisFilter from "./ProjectAnalysisFilter";
import { InitialState } from "../../model/states/InitialState";
import { SizeScreen } from "../../model/SizeSreen";
import Loading from "../../components/loading/Loading";
interface IProjectAnalysis {
  isStaffByTeam: boolean;
  viewTimeLine(timeline: Timeline): void;
}

const ProjectAnalysis: React.FC<IProjectAnalysis> = ({ isStaffByTeam, viewTimeLine }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const [listView, setListView] = useState(0);
  const [timelinesSelected, setTimelinesSelected] = useState<Timeline[]>([]);
  const [openModalAddToPlaylist, setOpenModalToPlaylist] = useState(false);

  const [listEventsCategoryShow, setListEventsCategoryShow] = useState<ProjectCategoryDto[]>(stateContent.project.eventsCategory);
  const [listEventsPlayersShow, setListEventsPlayersShow] = useState<ProjectPlayeryDto[]>(stateContent.project.eventsPlayer);
  const [timelineDetail, setTimelineDetail] = useState<Timeline | null>(null);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);

  const project = useMemo(() => stateContent.project.data, [stateContent.project.data]);

  const nameTeam1 = useMemo(() => project?.LocalTeamTemplate.Name, [stateContent.project.data]);
  const nameTeam2 = useMemo(() => project?.VisitorTeamTemplate.Name, [stateContent.project.data]);

  const idTeam1 = useMemo(() => project?.LocalTeamTemplate.$id, [stateContent.project.data]);
  const idTeam2 = useMemo(() => project?.VisitorTeamTemplate.$id, [stateContent.project.data]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterApplied, setFilterApplied] = useState<IFilterApplied>({ event: [], tags: [], teams: [], player: [], period: [] });
  const [timelineActive, setTimelineActive] = useState('');

  useEffect(() => {
    setListEventsCategoryShow(stateContent.project.eventsCategory)
  }, [stateContent.project.eventsCategory]);

  useEffect(() => {
    setListEventsPlayersShow(stateContent.project.eventsPlayer)
  }, [stateContent.project.eventsPlayer]);

  useEffect(() => {
    filter({ ...filterApplied });
  }, [listView]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-filter-pop-over" : undefined;

  const addPlayList = () => {
    if (timelinesSelected.length > 0) {
      setOpenModalToPlaylist(true);
    } else {
      setOpenModalInfo(true);
    }    
  };

  const filterTimelines = (timelines: Timeline[], filterApplied: IFilterApplied): Timeline[] => {
    const periodsFiltered = stateContent.project.data?.Periods.filter((pe) => filterApplied.period.indexOf(pe.$id) !== -1);
    let result =
      filterApplied.event.length > 0 ? timelines.filter((timeline) => filterApplied.event.indexOf((timeline.EventType as Ref).$ref) !== -1) : timelines;
    result =
      filterApplied.tags.length > 0
        ? result.filter((timeline) => timeline.Tags.filter((tag) => filterApplied.tags.indexOf(tag.Value) !== -1).length > 0)
        : result;
    result =
      filterApplied.teams.length > 0
        ? result.filter((timeline) => timeline.Teams.filter((team) => filterApplied.teams.indexOf(team.$ref) !== -1).length > 0)
        : result;
    result =
      filterApplied.player.length > 0
        ? result.filter((timeline) => timeline.Players.filter((pl) => filterApplied.player.indexOf(pl.$ref) !== -1).length > 0)
        : result;
    result =
      filterApplied.period.length > 0
        ? result.filter(
            (timeline) =>
              periodsFiltered && periodsFiltered?.filter((pe) => timeline.Start >= pe.Nodes[0].Start && timeline.Stop <= pe.Nodes[0].Stop).length > 0
          )
        : result;
    return result;
  };

  const filter = (filterApplied: IFilterApplied) => {
    if (listView === 0) {
      const listEventsCategoryShowCopy: ProjectCategoryDto[] = stateContent.project.eventsCategory.map((eventCategory) => {
        return {
          ...eventCategory,
          timelines: filterTimelines(eventCategory.timelines, filterApplied),
        };
      });
      setListEventsCategoryShow(listEventsCategoryShowCopy.filter((eventCategory) => eventCategory.timelines.length > 0));
    } else {
      const listEventsPlayersShowCopy: ProjectPlayeryDto[] = stateContent.project.eventsPlayer.map((eventPlayer) => {
        return {
          ...eventPlayer,
          timelines: filterTimelines(eventPlayer.timelines, filterApplied),
        };
      });
      setListEventsPlayersShow(listEventsPlayersShowCopy.filter((eventPlayer) => eventPlayer.timelines.length > 0));
    }
    setFilterApplied({ ...filterApplied });
  };

  const viewDetail = (timeline: Timeline) => {
    setTimelineDetail(timeline);
    setShowModalDetail(true);
  };

  const selectTimeline = (value: boolean, timeline: Timeline) => {
    let timelines = [];
    if (value) {
      timelines = [...timelinesSelected, timeline];
    } else {
      timelines = timelinesSelected.filter((tline) => tline.$id !== timeline.$id);
    }
    setTimelinesSelected(timelines);
  };

  const changeView = (view: number) => {
    setListView(view);
    setTimelinesSelected([]);
  };

  const viewTimeLineActive = (timeline: Timeline)=>  {
    setTimelineActive(timeline.$id);
    viewTimeLine(timeline);
  }
  return (
    <>    
        {!listEventsCategoryShow.length ? (
            <Loading />
        ) : (
        <>
            <div className="flex flex-wrap items-center gap-4 py-4">
            <div className="text-sm font-semibold">
                <LmCheckBox text={t("project.category")} id="project-category" value={listView === 0} changeValue={() => changeView(0)}></LmCheckBox>
            </div>
            <div>
                <LmCheckBox text={nameTeam1} id="project-team1" value={listView === 1} changeValue={() => changeView(1)}></LmCheckBox>
            </div>
            <div>
                <LmCheckBox text={nameTeam2} id="project-team2" value={listView === 2} changeValue={() => changeView(2)}></LmCheckBox>
            </div>
            <div className="flex items-center gap-4 ml-auto">
                {isStaffByTeam && <div>
                <LmButton
                    styleButton="secondary"
                    title={t('content.addPlaylist') as string}
                    buttonType="submit"
                    type="solid"
                    size="extra-small"
                    icon={<Folder />}
                    iconPosition="center"
                    clickAction={addPlayList}
                />
                </div>}
                <div>
                <LmButton
                    styleButton="secondary"
                    buttonType="button"
                    type="solid"
                    size="small"
                    text={stateMain.sizeScreen !== SizeScreen.MOBILE ? t("notifications.filters") : ''}
                    icon={<Filter />}
                    iconPosition="center"
                    clickAction={handleClick}
                />
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
                }}
                transformOrigin={{
                vertical: "top",
                horizontal: "center",
                }}>
                <ProjectAnalysisFilter filter={filter} listView={listView} handleClose={handleClose} filterAplied={filterApplied} />
            </Popover>
            </div>
            <div className="border border-neutral-200 rounded overflow-hidden">
            {listView === 0 &&
                listEventsCategoryShow.map((event) => (
                <AnalysisAccordion
                    key={event.event}
                    color={event.color}
                    event={event}
                    header={
                    <p className="text-xs font-bold p-4">
                        {event.name}({event.timelines.length})
                    </p>
                    }>
                    {event.timelines.map((timeline, index) => (
                    <AnalysisItemByCategory
                        key={timeline.$id + '-' + index}
                        timeline={timeline}
                        isStaffByTeam={isStaffByTeam}
                        active = {timeline.$id === timelineActive}
                        project={project as ProjectDto}
                        selected={timelinesSelected.find(tline => tline.$id === timeline.$id) ? true : false}
                        checkTimeline={selectTimeline}
                        selectTimeline={viewDetail}
                        viewTimeLine={viewTimeLineActive}                    
                    />
                    ))}
                </AnalysisAccordion>
                ))}
            {listView >= 1 &&
                listEventsPlayersShow
                .filter((event) => (listView === 1 ? event.team.$id === idTeam1 : event.team.$id === idTeam2))
                .map((player) => (
                    <AnalysisAccordion
                    key={player.player?.$id}
                    color={""}
                    header={
                        <AnalysisHeaderItemByTeam
                        imgPlayer={player.player?.Photo ?? ""}
                        imgTeam={player.team?.Shield}
                        name={player.player?.Name + " " + (player.player?.LastName !== null ? player.player?.LastName : '')}
                        num={player.timelines.length}
                        />
                    }>
                    {player.timelines.map((timeline) => (
                        <AnalysisItemByTeam
                        key={timeline.$id}
                        timeline={timeline}
                        isStaffByTeam={isStaffByTeam}
                        active = {timeline.$id === timelineActive}                     
                        selected={timelinesSelected.find(tline => tline.$id === timeline.$id) ? true : false}
                        team={project?.LocalTeamTemplate as TeamTemplate}
                        checkTimeline={selectTimeline}
                        selectTimeline={viewDetail}
                        viewTimeLine={viewTimeLineActive}
                        />
                    ))}
                    </AnalysisAccordion>
                ))}
            </div>
        </>)}
      <LmModalAddToPlaylist timelines={timelinesSelected} open={openModalAddToPlaylist} handleClose={() => setOpenModalToPlaylist(false)} />
      <ProjectAnalysisDetail statistic={project as ProjectDto} timeline={timelineDetail} open={showModalDetail} handleClose={() => setShowModalDetail(false)} />
      <LmModalInfo
        id="test-modal-info-add-playlist"
        title={t("content.addToPlayListModalInfoTitle")}
        description={t("content.addToPlayListModalInfoDescription")}
        textConfirm={t("modalConfirm.textOk")}
        open={openModalInfo}
        handleClose={() => setOpenModalInfo(false)}></LmModalInfo>
    </>
  );
};

export default ProjectAnalysis;
