import React, { useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down-black.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron-up.svg";
import LmIcon from "../icon/LmIcon";

interface Props {
  header: JSX.Element;
  actionsButtons?: JSX.Element;
  children: any;
  onOpen?(): void;
}

const LmAccordion: React.FC<Props> = ({ header, actionsButtons, children, onOpen }) => {
  const [open, setOpen] = useState(false);
  // test
  const handleOnOpen = () => {
    if (!open && onOpen) {
      onOpen();
    }
    setOpen(!open);
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex-1 overflow-hidden">{header}</div>
        <div className="flex items-center mr-5">
          {actionsButtons}
          <LmIcon className="cursor-pointer" icon={open ? <ChevronUp /> : <ChevronDown />} size="sm" clickAction={handleOnOpen} />
        </div>
      </div>
      {open && <div className="">{children}</div>}
    </div>
  );
};

export default LmAccordion;
