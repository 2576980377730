import { t } from "i18next";
import * as Yup from "yup";

export function teamInitialValues(dataStore: any) {
  return {
    name: dataStore.name,
    category: dataStore.category,
    storage: dataStore.storage,
  };
}

export function teamValidationSchema(maxStorage: number) {
  return Yup.object({
    name: Yup.string().required(t("errors.form.required") as string),
    category: Yup.string().required(t("errors.form.required") as string),
    storage: Yup.number().nullable()
      .max(maxStorage, t("errors.form.storageLimit") as string),
  });
}
