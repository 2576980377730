import { TypeActions } from "../../model/actions/typeActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import { TeamState } from "../../model/states/TeamState";
import { teamState } from "../states/teamState";

export const teamReducer = (state: TeamState = teamState, action: TypeActions): TeamState => {
  switch (action.type) {
    case ActionTeamType.ACTION_CHANGE_TEAM_FORM_VALUE: {
      return {
        ...state,
        teamForm: { ...state.teamForm, [action.value.key]: action.value.value },
      };
    }
    case ActionTeamType.ACTION_EDIT_TEAM_LOADING:
    case ActionTeamType.ACTION_CREATE_TEAM_LOADING: {
      return {
        ...state,
        loading: true,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_EDIT_TEAM_SUCCESS:
    case ActionTeamType.ACTION_CREATE_TEAM_SUCCESS: {
      return {
        ...state,
        teamForm: {
          image: "",
          name: "",
          category: "",
          storage: "",
          staff: [],
        },
        loading: false,
        ok: true,
      };
    }
    case ActionTeamType.ACTION_EDIT_TEAM_ERROR:
    case ActionTeamType.ACTION_CREATE_TEAM_ERROR: {
      return {
        ...state,
        loading: false,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_LOADING: {
      return {
        ...state,
        loading: true,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_SUCCESS: {
      return {
        ...state,
        loading: false,
        ok: true,
      };
    }
    case ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_ERROR: {
      return {
        ...state,
        loading: false,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_SELECT_TEAM: {
      return {
        ...state,
        selectedTeam: action.value,
      };
    }
    case ActionTeamType.ACTION_TEAM_SUMARY_LOADING: {
      return { ...state, teamSumary: { loading: true, ok: false, error: false, data: null } };
    }
    case ActionTeamType.ACTION_TEAM_SUMARY_SUCCESS: {
      const maxAssignableStorage = action.value?.maxAssignableStorage || 0;
      return {
        ...state,
        teamSumary: { loading: false, ok: true, error: false, data: action.value },
        dashboardTotalStorage: state.dashboardTotalStorage + maxAssignableStorage,
      };
    }
    case ActionTeamType.ACTION_TEAM_SUMARY_ERROR: {
      return { ...state, teamSumary: { loading: false, ok: false, error: true, data: null } };
    }
    case ActionTeamType.ACTION_DELETE_TEAM_LOADING: {
      return {
        ...state,
        loading: true,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_DELETE_TEAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        ok: true,
      };
    }
    case ActionTeamType.ACTION_DELETE_TEAM_ERROR: {
      return {
        ...state,
        loading: false,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_CREATE_TEAM_GROUP_LOADING: {
      return {
        ...state,
        loading: true,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_CREATE_TEAM_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        ok: true,
      };
    }
    case ActionTeamType.ACTION_CREATE_TEAM_GROUP_ERROR: {
      return {
        ...state,
        loading: false,
        ok: false,
      };
    }
    case ActionTeamType.ACTION_CREATE_STAFF_LOADING: {
      return {
        ...state,
        staff: {
          loading: true,
          ok: false,
          error: false,
        },
      };
    }
    case ActionTeamType.ACTION_CREATE_STAFF_SUCCESS: {
      return {
        ...state,
        staff: {
          loading: false,
          ok: true,
          error: false,
        },
      };
    }
    case ActionTeamType.ACTION_CREATE_STAFF_ERROR: {
      return {
        ...state,
        staff: {
          loading: false,
          ok: false,
          error: true,
        },
      };
    }
    case ActionTeamType.ACTION_GET_TEAM_GROUPS_LOADING: {
      return {
        ...state,
        groups: {
          loading: true,
          ok: false,
          error: false,
        },
      };
    }
    case ActionTeamType.ACTION_GET_TEAM_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: {
          loading: false,
          ok: true,
          error: false,
        },
      };
    }
    case ActionTeamType.ACTION_GET_TEAM_GROUPS_ERROR: {
      return {
        ...state,
        groups: {
          loading: false,
          ok: false,
          error: true,
        },
      };
    }

    case ActionTeamType.ACTION_UPDATE_DASHBOARD_MAX_STORAGE: {
      return {
        ...state,
        dashboardTotalStorage: state.dashboardTotalStorage + action.value,
      };
    }

    case ActionTeamType.ACTION_RESET_DASHBOARD_MAX_STORAGE: {
      return {
        ...state,
        dashboardTotalStorage: 0,
      };
    }

    default: {
      return { ...state };
    }
  }
};
