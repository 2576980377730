import * as Yup from "yup";
import { IdValue } from "../../model/forms/IdValue";
import { t } from "i18next";
import StaffDto from "../../model/dto/StaffDto";
import { regexEmailFormat } from "../../constants/globalConstanst";

export function staffInitialValues(role: IdValue[], staff: StaffDto | null) {
  return {
    userId: "",
    userName: staff ? staff.user?.name : "",
    userLastName: staff ? staff.user?.lastName : "",
    userEmail: staff ? staff.user?.email : "",
    roles: role.map((rol) => rol.id),
  };
}

export function staffValidationSchema() {
  return Yup.object({
    userName: Yup.string().required(t("errors.form.required") as string),
    userLastName: Yup.string().required(t("errors.form.required") as string),
    userEmail: Yup.string()
      .matches(regexEmailFormat, t("errors.form.passwordFormat") as string)
      .required(t("errors.form.required") as string),
  });
}
