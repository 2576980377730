import React from "react";
import { useTranslation } from "react-i18next";

interface IStorageBar {
  measure?: string | null;
  maxCapacity: number;
  currentCapacity: number;
  flex?: boolean;
  align?: string;
  labelUsed: string;
}

const StorageBar: React.FC<IStorageBar> = ({ measure = "GB", maxCapacity = 16, currentCapacity = 10, flex = false, align = "left", labelUsed = '' }) => {
  const { t } = useTranslation();
  const capacityPercent = (currentCapacity * 100) / maxCapacity;

  return (
    <div className={`storage ${flex && "flex flex-wrap md:flex-nowrap items-center gap-4"}`}>
      <div className="relative w-full h-4 rounded-3xl overflow-hidden bg-gray-300/30">
        <div className="h-4 absolute bg-orgColor" style={{ width: `${capacityPercent}%` }}></div>
      </div>
      <p className={`text-${align} w-full ${!flex && "mt-4"}`}>
        {`${currentCapacity % 1 === 0 ? currentCapacity : currentCapacity.toFixed(2)} ${measure} ${t("storage.of")} ${maxCapacity % 1 === 0 ? maxCapacity : maxCapacity.toFixed(2)} ${measure} ${labelUsed}`}
      </p>
    </div>
  );
};

export default StorageBar;
