import { PlayerState } from "../../model/states/PlayerState";

export const playerState: PlayerState = {
  dataPlayer: {
    loading: false,
    error: false,
    ok: false,
  },
  countries: {
    loading: false,
    error: false,
    data: [],
  },
  dataPlayerUnregistered: {
    loading: false,
    error: false,
    ok: false,
  },
};
