import React, { ChangeEvent } from "react";
import LmLabel from "../label/LmLabel";
import "./LmCheckBox.scss";

interface ICheckBox {
  id?: string;
  value?: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: string | null;
  text?: string | null;
  labelPosition?: "left" | "right";
  required?: boolean;
  helpText?: string;
  changeValue(value: ChangeEvent<HTMLInputElement>): void;
  blur?(value: ChangeEvent<HTMLInputElement>): void;
  focus?(value: ChangeEvent<HTMLInputElement>): void;
}

const LmCheckBox: React.FC<ICheckBox> = ({
  id = "",
  value = false,
  disabled = false,
  error = false,
  label = "",
  text = "",
  labelPosition = "right",
  required = false,
  helpText = "",
  changeValue = () => {},
  blur = () => {},
  focus = () => {},
}) => {
  return (
    <div className={`lm-checkbox ${disabled ? "lm-checkbox--disabled" : ""} ${error ? "lm-checkbox--error" : ""}`}>
      {label && <LmLabel value={label} id={id} required={required}></LmLabel>}
      <div className="flex items-start gap-2">
        <input
          className="lm-checkbox__element"
          id={id}
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={changeValue}
          onBlur={blur}
          onFocus={focus}
        />
        <div className="lm-checkbox__label flex">
          {/* <span id={id}>{text}</span> */}
          <LmLabel value={text} id={id} required={false}></LmLabel>
        </div>
      </div>
      {helpText && <div className="lm-checkbox__help">{helpText}</div>}
    </div>
  );
};

export default LmCheckBox;
