import { FILE_PENDING_DELETE } from "../../constants/globalConstanst";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { CommentDto } from "../../model/dto/CommentDto";
import DocumentDto, { CategoryDto } from "../../model/dto/DocumentDto";
import { UploadFile } from "../../model/dto/FileDto";
import { EventType, ProjectCategoryDto, ProjectDto, ProjectPlayeryDto, Ref, Timeline } from "../../model/dto/ProjectDto";
import { ContentManagementState, ContentManagementTabs, ContentManagementType, TypeOfFile } from "../../model/states/ContentManagementState";
import { getProjectDataPlayer, getProjectDataTeamPlayer, orderDocuments, typesContentStateFiles, typesContentStateFilesSelected } from "../../utils/utils";
import { contentManagementState } from "../states/contentManagementState";

export const contentManagementReducer = (state: ContentManagementState = contentManagementState, action: TypeActions): ContentManagementState => {
  switch (action.type) {
    case ActionContentManagementType.ACTION_DOCUMENTS_LOADING: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      stateCopy.typesOfFiles[typeFile] = { ...stateCopy.typesOfFiles[typeFile], loading: true, error: false, ok: false, data: [] };

      return {
        ...stateCopy,
      };
    }

    case ActionContentManagementType.ACTION_DOCUMENTS_SUCCESS: {
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      const docs = orderDocuments(action.value.data, state.typesOfFiles[typeFile].order);
      const stateCopy = { ...state };
      stateCopy.typesOfFiles[typeFile] = {
        ...stateCopy.typesOfFiles[typeFile],
        loading: false,
        error: false,
        ok: true,
        data: docs.map((document, index) => {
          return {
            ...document,
            index,
          };
        }),
      };

      return {
        ...stateCopy,
      };
    }

    case ActionContentManagementType.ACTION_DOCUMENTS_ERROR: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      stateCopy.typesOfFiles[typeFile] = { ...stateCopy.typesOfFiles[typeFile], loading: false, error: true, ok: false, data: [] };

      return {
        ...stateCopy,
      };
    }

    case ActionContentManagementType.ACTION_DOCUMENTS_ORDER_BY_DATE: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      const files = [...stateCopy.typesOfFiles[typeFile].data];
      const order = stateCopy.typesOfFiles[typeFile].order === "asc" ? "desc" : "asc";
      stateCopy.typesOfFiles[typeFile] = { ...stateCopy.typesOfFiles[typeFile], data: orderDocuments(files, order), order: order };

      return {
        ...stateCopy,
      };
    }

    case ActionContentManagementType.ACTION_DOCUMENTS_CHANGE_FILTER_TEAM: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);

      stateCopy.typesOfFiles[typeFile] = { ...stateCopy.typesOfFiles[typeFile], teamSelected: action.value };

      return {
        ...stateCopy,
      };
    }

    case ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_LOADING: {
      return {
        ...state,
        changeFileCategory: { loading: true, error: false, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_SUCCESS: {
      const stateCopy = global.structuredClone(state);
      const typeFile = typesContentStateFilesSelected(state.contentSelected);

      const files: DocumentDto[] = stateCopy.typesOfFiles[typeFile].data;
      let newCategory: CategoryDto | null;

      if (!action.value.categoryId && action.value.data.length > 0) {
        newCategory = action.value.data[0].category;
        if (newCategory) {
          stateCopy.categories.data.push(newCategory);

          typesContentStateFiles().forEach((type) => {
            stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.push({
              idCategory: newCategory?.id as string,
              name: newCategory?.name as string,
              state: true,
            });
          });
        }

        if (stateCopy.document.data) {
          stateCopy.document.data.category = newCategory;
        }
      }

      action.value.data.forEach((document) => {
        const docIndex = files.findIndex((doc) => doc.id === document.id);
        if (docIndex >= 0) {
          files[docIndex].category = document.category;
          files[docIndex].categoryId = document.categoryId;
        }
      });

      stateCopy.typesOfFiles[typeFile] = { ...stateCopy.typesOfFiles[typeFile], data: files };

      return {
        ...stateCopy,
        changeFileCategory: { loading: false, error: false, ok: true, data: action.value.data },
      };
    }

    case ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_ERROR: {
      return {
        ...state,
        changeFileCategory: { loading: false, error: true, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_EDIT_CATEGORY_LOADING: {
      return {
        ...state,
        editCategory: { loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_EDIT_CATEGORY_SUCCESS: {
      const stateCopy = global.structuredClone(state);
      const categories = { ...state.categories };
      const index = categories.data.findIndex((cat) => cat.id === action.value.id);
      if (index >= 0) {
        categories.data[index].name = action.value.name;

        typesContentStateFiles().forEach((type) => {
          const currentCategory = stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.findIndex((cat) => cat.idCategory === action.value.id);
          if (currentCategory !== -1) {
            stateCopy.typesOfFiles[type as TypeOfFile].stateCategory[currentCategory].name = action.value.name;
          }
        });
      }
      return {
        ...stateCopy,
        editCategory: { loading: false, error: false, ok: true },
        categories: { ...categories },
      };
    }

    case ActionContentManagementType.ACTION_EDIT_CATEGORY_ERROR: {
      return {
        ...state,
        editCategory: { loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_CATEGORY_LOADING: {
      return {
        ...state,
        deleteCategory: { loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_CATEGORY_SUCCESS: {
      const stateCopy = global.structuredClone(state);
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      stateCopy.typesOfFiles[typeFile].data
        .filter((doc) => doc.categoryId === action.value)
        .forEach((doc) => {
          doc.category = null;
          doc.categoryId = null;
        });

      typesContentStateFiles().forEach((type) => {
        stateCopy.typesOfFiles[type as TypeOfFile].stateCategory = [
          ...stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.filter((cat) => cat.idCategory !== action.value),
        ];
      });

      return {
        ...stateCopy,
        deleteCategory: { loading: false, error: false, ok: true },
        categories: { ...state.categories, data: state.categories.data.filter((cat) => cat.id !== action.value) },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_CATEGORY_ERROR: {
      return {
        ...state,
        deleteCategory: { loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_LOADING: {
      const data = action.value.data;
      return {
        ...state,
        uploadDocument: {
          loading: true,
          ok: false,
          error: false,
          data: { id: "", name: data.fileName, size: data.expectedSize ?? 0, file: action.value.file, progress: 0 },
        },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_SUCCESS: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      if (action.value.newCategoryName && action.value.data.category) {
        const newCategory: CategoryDto = { ...action.value.data.category };
        stateCopy.categories.data.push(newCategory);
        typesContentStateFiles().forEach((type) => {
          stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.push({ idCategory: newCategory.id, name: newCategory.name, state: true });
        });
      }
      stateCopy.typesOfFiles[typeFile].data.push({ ...action.value.data, index: stateCopy.typesOfFiles[typeFile].data.length });
      const dataFile: UploadFile = { ...(stateCopy.uploadDocument.data as UploadFile) };
      return {
        ...stateCopy,
        uploadDocument: {
          loading: true,
          ok: false,
          error: false,
          data: { ...dataFile, id: action.value.data.id },
        },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_ERROR: {
      return {
        ...state,
        uploadLink: { ok: false, loading: false, error: true, data: null },
        uploadDocument: {
          loading: false,
          ok: false,
          error: true,
          data: null,
        },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_PROGRESS: {
      const data = { ...(state.uploadDocument.data as UploadFile) };
      return {
        ...state,
        uploadDocument: {
          loading: true,
          ok: false,
          error: false,
          data: { ...data, progress: action.value },
        },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_RESET: {
      return {
        ...state,
        uploadLink: { ok: false, loading: false, error: false, data: null },
        uploadDocument: { ok: false, loading: false, error: false, data: null },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_LINK_LOADING: {
      return {
        ...state,
        uploadLink: { ok: false, loading: false, error: false, data: null },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_LINK_SUCCESS: {
      return {
        ...state,
        uploadLink: { ok: true, loading: false, error: false, data: action.value },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_LINK_ERROR: {
      return {
        ...state,
        uploadDocument: {
          loading: false,
          ok: false,
          error: true,
          data: null,
        },
        uploadLink: { ok: false, loading: false, error: true, data: null },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_TO_AZURE_LOADING: {
      return {
        ...state,
        uploadToAzure: true,
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_TO_AZURE_SUCCESS: {
      return {
        ...state,
        uploadToAzure: false,
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_SUCCESS: {
      return {
        ...state,
        uploadDocument: { ok: true, loading: false, error: false, data: null },
      };
    }

    case ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_ERROR: {
      return {
        ...state,
        uploadDocument: { ok: false, loading: false, error: true, data: null },
      };
    }

    case ActionContentManagementType.ACTION_CONTENT_MANAGEMENT_TAB_SELECTED: {
      return {
        ...state,
        contentSelected: action.value,
      };
    }

    case ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES: {
      return {
        ...state,
        filesSelected: action.value,
      };
    }

    case ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_LOADING:
    case ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_LOADING: {
      return {
        ...state,
        addTagToFile: { loading: true, error: false, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_SUCCESS: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);

      const files: DocumentDto[] = stateCopy.typesOfFiles[typeFile].data;
      files.forEach((document) => {
        const doc = action.value.find((doc) => doc.id === document.id);
        if (doc) {
          document = { ...document, tags: doc.tags };
        }
      });

      stateCopy.typesOfFiles[typeFile] = { ...stateCopy.typesOfFiles[typeFile], data: files };

      if (stateCopy.document.data) {
        const doc = action.value.find((doc) => doc.id === stateCopy.document.data!.id);
        stateCopy.document.data.tags = doc!.tags;
      }

      return {
        ...stateCopy,
        addTagToFile: { loading: false, error: false, ok: true, data: action.value },
      };
    }

    case ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_ERROR:
    case ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_ERROR: {
      return {
        ...state,
        addTagToFile: { loading: false, error: true, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_RESET: {
      return {
        ...state,
        addTagToFile: { loading: false, error: false, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_CATEGORIES_LOADING: {
      return {
        ...state,
        categories: { loading: true, error: false, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_CATEGORIES_SUCCESS: {
      const stateCopy = { ...state };
      typesContentStateFiles().forEach((type) => {
        action.value.forEach((cat) => {
          if (stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.findIndex((stateCat) => stateCat.idCategory === cat.id) === -1) {
            stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.push({ idCategory: cat.id, name: cat.name, state: true });
          }
        });
        if (stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.findIndex((stateCat) => stateCat.idCategory === "0") === -1) {
          stateCopy.typesOfFiles[type as TypeOfFile].stateCategory.push({ idCategory: "0", name: "", state: true });
        }
      });

      return {
        ...stateCopy,
        categories: { loading: false, error: false, ok: true, data: action.value },
      };
    }

    case ActionContentManagementType.ACTION_CATEGORIES_ERROR: {
      return {
        ...state,
        categories: { loading: false, error: true, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_OPEN_CLOSE_CATEGORY: {
      const stateCopy = global.structuredClone(state);
      const category = stateCopy.typesOfFiles[action.value.typeOfFile].stateCategory.find((cat) => cat.idCategory === action.value.idCategory);
      if (category) {
        category.state = action.value.status;
      }

      return {
        ...stateCopy,
      };
    }

    case ActionContentManagementType.ACTION_TAGS_LOADING: {
      return {
        ...state,
        tags: { loading: true, error: false, ok: false, data: [], private: [] },
      };
    }

    case ActionContentManagementType.ACTION_TAGS_SUCCESS: {
      return {
        ...state,
        tags: { loading: false, error: false, ok: true, data: action.value, private: [] },
      };
    }

    case ActionContentManagementType.ACTION_TAGS_ERROR: {
      return {
        ...state,
        tags: { loading: false, error: true, ok: false, data: [], private: [] },
      };
    }

    case ActionContentManagementType.ACTION_TAGS_PRIVATE_LOADING: {
      return {
        ...state,
        tags: { loading: true, error: false, ok: false, data: [...state.tags.data], private: [] },
      };
    }

    case ActionContentManagementType.ACTION_TAGS_PRIVATE_SUCCESS: {
      return {
        ...state,
        tags: {
          loading: false,
          error: false,
          ok: true,
          data: [...state.tags.data],
          private: action.value.filter((tag) => tag.type === "Staff"),
        },
      };
    }

    case ActionContentManagementType.ACTION_TAGS_PRIVATE_ERROR: {
      return {
        ...state,
        tags: { loading: false, error: true, ok: false, data: [...state.tags.data], private: [] },
      };
    }

    case ActionContentManagementType.ADD_TAGS_ORGANIZATION: {
      return {
        ...state,
        tags: {
          ...state.tags,
          data: [...state.tags.data, ...action.value.filter((tag) => tag.type === "Public")],
          private: [...state.tags.private, ...action.value.filter((tag) => tag.type === "Staff")],
        },
      };
    }

    case ActionContentManagementType.ACTION_SHARE_FILE_LOADING: {
      return {
        ...state,
        shareFile: { loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_SHARE_FILE_ERROR: {
      return {
        ...state,
        shareFile: { loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_LOADING: {
      return {
        ...state,
        documentUrl: { loading: true, error: false, ok: false, url: "" },
      };
    }

    case ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_SUCCESS: {
      return {
        ...state,
        documentUrl: { loading: false, error: false, ok: true, url: action.value },
      };
    }

    case ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_ERROR: {
      return {
        ...state,
        documentUrl: { loading: false, error: true, ok: false, url: "" },
      };
    }

    case ActionContentManagementType.ACTION_LOAD_DOCUMENT_LOADING: {
      return {
        ...state,
        project: {
          data: null,
          eventsCategory: [],
          eventsPlayer: [],
        },
        document: { loading: true, error: false, ok: false, data: null },
      };
    }

    case ActionContentManagementType.ACTION_LOAD_DOCUMENT_SUCCESS: {
      let content: ProjectDto | null = null;
      const eventCategory: ProjectCategoryDto[] = [];
      const eventPlayer: ProjectPlayeryDto[] = [];

      if (action.value.type === "Project") {
        content = JSON.parse(action.value.content);

        // substitution events are the only one not the ref in the event types
        const substitutionEventId = (content?.EventTypes.find((event: EventType | Ref) => "$id" in event) as EventType)?.$id;
        content?.Timeline.filter((timeline: Timeline) => (timeline.EventType as Ref).$ref !== substitutionEventId).forEach((timeline) => {
          const eventType = content?.Dashboard.List.find((item) => (item.EventType as EventType)?.$id === (timeline.EventType as Ref).$ref);
          const foundCategoryIndex = eventCategory.findIndex((cat) => cat.event === (timeline.EventType as Ref).$ref);

          if (foundCategoryIndex === -1) {
            eventCategory.push({
              event: (timeline.EventType as Ref).$ref as string,
              name: (eventType?.EventType as EventType)?.Name ?? "",
              color: (eventType?.EventType as EventType)?.Color ?? "",
              tags: (eventType?.EventType as EventType)?.Tags,
              timelines: [timeline],
            });
          } else {
            eventCategory[foundCategoryIndex].timelines = eventCategory[foundCategoryIndex].timelines.concat(timeline);
          }

          timeline.Players.forEach((player) => {
            const foundPlayerIndex = eventPlayer.findIndex((eventPlayer) => eventPlayer.id === player.$ref);
            if (foundPlayerIndex === -1) {
              eventPlayer.push({
                id: player.$ref,
                player: getProjectDataPlayer(player.$ref, content as ProjectDto),
                team: getProjectDataTeamPlayer(player.$ref, content as ProjectDto),
                timelines: [timeline],
              });
            } else {
              eventPlayer[foundPlayerIndex].timelines = eventPlayer[foundPlayerIndex].timelines.concat(timeline);
            }
          });
        });
      }

      return {
        ...state,
        document: { loading: false, error: false, ok: true, data: action.value },
        project: {
          data: content,
          eventsCategory: [...eventCategory],
          eventsPlayer: [...eventPlayer],
        },
      };
    }

    case ActionContentManagementType.ACTION_LOAD_DOCUMENT_ERROR: {
      return {
        ...state,
        project: {
          data: null,
          eventsCategory: [],
          eventsPlayer: [],
        },
        document: { loading: false, error: true, ok: false, data: null },
      };
    }

    case ActionContentManagementType.ACTION_DOCUMENT_RESET: {
      return {
        ...state,
        document: { loading: false, error: false, ok: false, data: null },
        comments: { lastComments: [], loading: false, error: false, ok: false, data: [], parentComment: null },
        documentUrl: { loading: false, error: false, ok: false, url: "" },
      };
    }

    case ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_SUCCESS: {
      const stateCopy = { ...state };
      const tags = (stateCopy.document.data as DocumentDto).tags.filter((tag) => tag.id !== action.value.tagId);

      const document: DocumentDto = {
        ...(stateCopy.document.data as DocumentDto),
        tags: tags,
      };

      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      const files: DocumentDto[] = stateCopy.typesOfFiles[typeFile].data;
      const fileIndexSelected = files.findIndex((file) => file.id === action.value.fileId);
      files[fileIndexSelected] = { ...document };

      return {
        ...stateCopy,
        document: { ...stateCopy.document, data: { ...document } },
        addTagToFile: { loading: false, error: false, ok: true, data: [document] },
      };
    }

    case ActionContentManagementType.ACTION_SHARE_FILE_RESET: {
      return {
        ...state,
        shareFile: { loading: false, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_UNSHARE_FILE_LOADING: {
      return {
        ...state,
        unShareFile: { loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_SHARE_FILE_SUCCESS:
    case ActionContentManagementType.ACTION_UNSHARE_FILE_SUCCESS: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      if (action.value[0].type !== ContentManagementTabs.Playlist) {
        let document: DocumentDto | null = null;
        if (stateCopy.document.data) {
          const doc = action.value.find((doc) => doc.id === (stateCopy.document.data as DocumentDto).id);
          document = {
            ...(stateCopy.document.data as DocumentDto),
            shareWithGroups: doc!.shareWithGroups,
            shareWithUsers: doc!.shareWithUsers,
            shareWithPlayers: doc!.shareWithPlayers,
            shared: doc!.shared,
          };
        }

        const files: DocumentDto[] = stateCopy.typesOfFiles[typeFile].data;

        action.value.forEach((document) => {
          const docIndex = files.findIndex((doc) => doc.id === document.id);
          if (docIndex >= 0) {
            files[docIndex] = {
              ...files[docIndex],
              shareWithGroups: document.shareWithGroups,
              shareWithUsers: document.shareWithUsers,
              shareWithPlayers: document.shareWithPlayers,
              shared: document.shared,
            };
          }
        });

        stateCopy.typesOfFiles[typeFile] = { ...stateCopy.typesOfFiles[typeFile], data: files };

        if (action.type === ActionContentManagementType.ACTION_SHARE_FILE_SUCCESS) {
          return {
            ...stateCopy,
            document: { ...stateCopy.document, data: document },
            shareFile: { loading: false, error: false, ok: true },
          };
        } else {
          return {
            ...stateCopy,
            document: { ...stateCopy.document, data: document },
            unShareFile: { loading: false, error: false, ok: true },
          };
        }
      } else {
        if (action.type === ActionContentManagementType.ACTION_SHARE_FILE_SUCCESS) {
          return {
            ...state,

            shareFile: { loading: false, error: false, ok: true },
          };
        } else {
          return {
            ...state,

            unShareFile: { loading: false, error: false, ok: true },
          };
        }
      }
    }

    case ActionContentManagementType.ACTION_UNSHARE_FILE_ERROR: {
      return {
        ...state,
        unShareFile: { loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_UNSHARE_FILE_RESET: {
      return {
        ...state,
        unShareFile: { loading: false, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_FILES_LOADING: {
      return {
        ...state,
        deleteFiles: { loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_FILES_SUCCESS: {
      const stateCopy = { ...state };
      const typeFile = typesContentStateFilesSelected(state.contentSelected);
      stateCopy.typesOfFiles[typeFile].data
        .filter((doc) => action.value.includes(doc.id))
        .forEach((doc) => {
          doc.status = FILE_PENDING_DELETE;
        });

      return {
        ...stateCopy,
        deleteFiles: { loading: false, error: false, ok: true },
        document: { ...stateCopy.document, data: null },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_FILES_ERROR: {
      return {
        ...state,
        deleteFiles: { loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_FILES_RESET: {
      return {
        ...state,
        deleteFiles: { loading: false, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_GET_FILE_COMMENTS_LOADING: {
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: { ...state.playlistComments, loading: true, error: false, ok: false, parentComment: null, data: [] },
        };
      }
      return {
        ...state,
        comments: { ...state.comments, loading: true, error: false, ok: false, parentComment: null, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_GET_FILE_COMMENTS_SUCCESS: {
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: {
            ...state.playlistComments,
            loading: false,
            error: false,
            ok: true,
            data: action.value,
            parentComment: null,
          },
        };
      }

      return {
        ...state,
        comments: {
          ...state.comments,
          loading: false,
          error: false,
          ok: true,
          data: action.value,
          parentComment: null,
        },
      };
    }

    case ActionContentManagementType.ACTION_GET_FILE_COMMENTS_ERROR: {
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: { ...state.playlistComments, loading: false, error: true, ok: false },
        };
      }
      return {
        ...state,
        comments: { ...state.comments, loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_SUCCESS: {
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: {
            ...state.playlistComments,
            loading: false,
            error: false,
            ok: true,
            parentComment: action.value.parentComment,
            data: action.value.replies,
          },
        };
      }
      return {
        ...state,
        comments: { ...state.comments, loading: false, error: false, ok: true, parentComment: action.value.parentComment, data: action.value.replies },
      };
    }

    case ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_SUCCESS: {
      let commentsCopy = { ...state.comments };
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        commentsCopy = { ...state.playlistComments };
      }

      if (commentsCopy.parentComment && action.value.savedComment.parentCommentId === commentsCopy.parentComment?.id) {
        const numberOfReplies = commentsCopy.parentComment.numberOfReplies + 1;
        commentsCopy.parentComment = {
          ...commentsCopy.parentComment,
          numberOfReplies: numberOfReplies,
        };
        commentsCopy.data = [...commentsCopy.data, action.value.savedComment];
      }

      if (!commentsCopy.parentComment && !action.value.savedComment.parentCommentId) {
        commentsCopy.data = [...commentsCopy.data, action.value.savedComment];
      }

      const repliedCommentIndex = commentsCopy.data.findIndex((comment) => comment.id === action.value.parentId);
      if (repliedCommentIndex !== -1) {
        const numberOfReplies = commentsCopy.data[repliedCommentIndex].numberOfReplies + 1;
        commentsCopy.data = [
          ...commentsCopy.data.slice(0, repliedCommentIndex),
          { ...commentsCopy.data[repliedCommentIndex], numberOfReplies: numberOfReplies },
          ...commentsCopy.data.slice(repliedCommentIndex + 1),
        ];
      }

      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: {
            ...state.playlistComments,
            loading: false,
            error: false,
            ok: true,
            parentComment: commentsCopy.parentComment,
            data: commentsCopy.data,
          },
        };
      }

      return {
        ...state,
        comments: { ...state.comments, loading: false, error: false, ok: true, parentComment: commentsCopy.parentComment, data: commentsCopy.data },
      };
    }

    case ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_ERROR:
    case ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_ERROR:
    case ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_ERROR:
    case ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_ERROR:
    case ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_ERROR: {
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: { ...state.playlistComments, loading: false, error: true, ok: false },
        };
      }
      return {
        ...state,
        comments: { ...state.comments, loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING:
    case ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_LOADING:
    case ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_LOADING:
    case ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_LOADING:
    case ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_LOADING: {
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: { ...state.playlistComments, loading: true, error: false, ok: false },
        };
      }
      return {
        ...state,
        comments: { ...state.comments, loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_SUCCESS: {
      let commentsCopy = { ...state.comments };
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        commentsCopy = { ...state.playlistComments };
      }
      if (!action.value.isParent) {
        commentsCopy.data = commentsCopy.data.filter((comment) => comment.id !== action.value.commentId);

        if (commentsCopy.parentComment) {
          const numberOfReplies = commentsCopy.parentComment.numberOfReplies - 1;
          commentsCopy.parentComment = {
            ...commentsCopy.parentComment,
            numberOfReplies: numberOfReplies,
          };
        }
      }

      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: {
            ...state.playlistComments,
            loading: false,
            error: false,
            ok: true,
            data: commentsCopy.data,
            parentComment: commentsCopy.parentComment,
          },
        };
      }

      return {
        ...state,
        comments: {
          ...state.comments,
          loading: false,
          error: false,
          ok: true,
          data: commentsCopy.data,
          parentComment: commentsCopy.parentComment,
        },
      };
    }

    case ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_SUCCESS: {
      let commentsCopy = { ...state.comments };
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        commentsCopy = { ...state.playlistComments };
      }
      const { commentId } = action.value;

      const commentIndex = commentsCopy.data.findIndex((comment: CommentDto) => comment.id === action.value.commentId);
      if (commentIndex >= 0) {
        commentsCopy.data = [
          ...commentsCopy.data.slice(0, commentIndex),
          { ...commentsCopy.data[commentIndex], likedBy: [...commentsCopy.data[commentIndex].likedBy, action.value.userId] },
          ...commentsCopy.data.slice(commentIndex + 1),
        ];
      }

      if (commentsCopy.parentComment && commentId === commentsCopy.parentComment?.id) {
        commentsCopy.parentComment = {
          ...commentsCopy.parentComment,
          likedBy: [...commentsCopy.parentComment.likedBy, action.value.userId],
        };
      }

      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: {
            ...state.playlistComments,
            loading: false,
            error: false,
            ok: true,
            parentComment: commentsCopy.parentComment,
            data: commentsCopy.data,
          },
        };
      }

      return {
        ...state,
        comments: { ...state.comments, loading: false, error: false, ok: true, parentComment: commentsCopy.parentComment, data: commentsCopy.data },
      };
    }

    case ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_SUCCESS: {
      let commentsCopy = { ...state.comments };
      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        commentsCopy = { ...state.playlistComments };
      }
      const { commentId } = action.value;

      const commentIndex = commentsCopy.data.findIndex((comment: CommentDto) => comment.id === action.value.commentId);
      if (commentIndex >= 0) {
        commentsCopy.data = [
          ...commentsCopy.data.slice(0, commentIndex),
          {
            ...commentsCopy.data[commentIndex],
            likedBy: [...commentsCopy.data[commentIndex].likedBy.filter((userId: string) => userId !== action.value.userId)],
          },
          ...commentsCopy.data.slice(commentIndex + 1),
        ];
      }

      if (commentsCopy.parentComment && commentId === commentsCopy.parentComment?.id) {
        commentsCopy.parentComment = {
          ...commentsCopy.parentComment,
          likedBy: [...commentsCopy.parentComment.likedBy.filter((userId: string) => userId !== action.value.userId)],
        };
      }

      if (state.contentSelected === ContentManagementType.Project && state.contentDetailTabSelected === ContentManagementTabs.Playlist) {
        return {
          ...state,
          playlistComments: {
            ...state.playlistComments,
            loading: false,
            error: false,
            ok: true,
            parentComment: commentsCopy.parentComment,
            data: commentsCopy.data,
          },
        };
      }

      return {
        ...state,
        comments: { ...state.comments, loading: false, error: false, ok: true, parentComment: commentsCopy.parentComment, data: commentsCopy.data },
      };
    }

    case ActionContentManagementType.ACTION_GRANT_ACCESS_LOADING: {
      return {
        ...state,
        grantAccess: { ok: false, loading: true, error: false },
      };
    }

    case ActionContentManagementType.ACTION_GRANT_ACCESS_SUCCESS: {
      const stateCopy = { ...state };
      stateCopy.document.data!.shareWithUsers = [...stateCopy.document.data!.shareWithUsers, ...action.value];
      return {
        ...stateCopy,
        grantAccess: { ok: true, loading: false, error: false },
      };
    }

    case ActionContentManagementType.ACTION_GRANT_ACCESS_ERROR: {
      return {
        ...state,

        grantAccess: { ok: false, loading: false, error: true },
      };
    }

    case ActionContentManagementType.ACTION_GRANT_ACCESS_RESET: {
      return {
        ...state,

        grantAccess: { ok: false, loading: false, error: false },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_MENTIONS_LOADING:
    case ActionContentManagementType.ACTION_GET_LAST_COMMENTS_LOADING: {
      return {
        ...state,
        comments: { ...state.comments, loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_MENTIONS_SUCCESS:
    case ActionContentManagementType.ACTION_GET_LAST_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: { ...state.comments, loading: false, error: false, ok: true, lastComments: action.value },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_MENTIONS_ERROR:
    case ActionContentManagementType.ACTION_GET_LAST_COMMENTS_ERROR: {
      return {
        ...state,
        comments: { ...state.comments, loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_LOADING: {
      return {
        ...state,
        lastDocuments: { ...state.lastDocuments, loading: true, error: false, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        lastDocuments: { ...state.lastDocuments, data: action.value, loading: false, error: false, ok: true },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_ERROR: {
      return {
        ...state,
        lastDocuments: { ...state.lastDocuments, loading: false, error: true, ok: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_PROJECTS_LOADING: {
      return {
        ...state,
        lastProjects: { ...state.lastProjects, loading: true, error: false, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_PROJECTS_SUCCESS: {
      return {
        ...state,
        lastProjects: { ...state.lastProjects, data: action.value, loading: false, error: false, ok: true },
      };
    }

    case ActionContentManagementType.ACTION_GET_LAST_PROJECTS_ERROR: {
      return {
        ...state,
        lastProjects: { ...state.lastProjects, loading: false, error: true, ok: false },
      };
    }

    case ActionContentManagementType.ACTION_CONTENT_DETAIL_TAB_SELECTED: {
      return {
        ...state,
        contentDetailTabSelected: action.value,
      };
    }

    case ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_LOADING: {
      return {
        ...state,
        playlistByProjectSelected: { loading: true, ok: false, error: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_SUCCESS: {
      const formatedData = action.value.map((document) => {
        return { ...document, clipsLoading: true, clipsOk: false, clipsError: false };
      });
      return {
        ...state,
        playlistByProjectSelected: { loading: false, ok: true, error: false, data: formatedData },
      };
    }

    case ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_ERROR: {
      return {
        ...state,
        playlistByProjectSelected: { loading: false, ok: false, error: true, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_CREATE_PLAYLIST_LOADING: {
      return {
        ...state,
        createPlaylist: { loading: true, ok: false, error: false, data: null },
      };
    }

    case ActionContentManagementType.ACTION_CREATE_PLAYLIST_SUCCESS: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);
      playlistData.push(action.value);
      return {
        ...state,
        createPlaylist: { loading: false, ok: true, error: false, data: action.value },
        playlistByProjectSelected: { loading: false, ok: false, error: true, data: playlistData },
      };
    }

    case ActionContentManagementType.ACTION_CREATE_PLAYLIST_ERROR: {
      return {
        ...state,
        createPlaylist: { loading: false, ok: false, error: true, data: null },
      };
    }

    case ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_LOADING: {
      return {
        ...state,
        addClipsToPlaylist: { loading: true, ok: false, error: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_SUCCESS: {
      return {
        ...state,
        addClipsToPlaylist: { loading: false, ok: true, error: false, data: action.value },
      };
    }

    case ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_ERROR: {
      return {
        ...state,
        addClipsToPlaylist: { loading: false, ok: false, error: true, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_RESET_CREATE_PLAYLIST: {
      return {
        ...state,
        createPlaylist: { loading: false, ok: false, error: false, data: null },
        addClipsToPlaylist: { loading: false, ok: false, error: false, data: [] },
      };
    }

    case ActionContentManagementType.ACTION_STATE_DELETE_PLAYLIST_BY_ID: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);
      const updatePlaylistData = playlistData.filter((playlist) => playlist.id !== action.value);

      return {
        ...state,
        playlistByProjectSelected: { ...state.playlistByProjectSelected, data: updatePlaylistData },
      };
    }

    case ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_LOADING: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);
      const selectedPlaylistIndex = playlistData.findIndex((playlist) => playlist.id === action.value);
      if (selectedPlaylistIndex !== -1) {
        playlistData[selectedPlaylistIndex] = { ...playlistData[selectedPlaylistIndex], clipsLoading: true, clipsOk: false, clipsError: false };
      }

      return {
        ...state,
        playlistByProjectSelected: { ...state.playlistByProjectSelected, data: playlistData },
      };
    }

    case ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_SUCCESS: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);
      const selectedPlaylistIndex = playlistData.findIndex((playlist) => playlist.id === action.value.playlistId);
      if (selectedPlaylistIndex !== -1) {
        playlistData[selectedPlaylistIndex] = {
          ...playlistData[selectedPlaylistIndex],
          clips: action.value.clips,
          clipsLoading: false,
          clipsOk: true,
          clipsError: false,
        };
      }

      return {
        ...state,
        playlistByProjectSelected: { ...state.playlistByProjectSelected, data: playlistData },
      };
    }

    case ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_ERROR: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);
      const selectedPlaylistIndex = playlistData.findIndex((playlist) => playlist.id === action.value);
      if (selectedPlaylistIndex !== -1) {
        playlistData[selectedPlaylistIndex] = { ...playlistData[selectedPlaylistIndex], clipsLoading: false, clipsOk: false, clipsError: true };
      }

      return {
        ...state,
        playlistByProjectSelected: { ...state.playlistByProjectSelected, data: playlistData },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_SUCCESS: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);
      const selectedPlaylistIndex = playlistData.findIndex((playlist) => playlist.id === action.value.playlistId);
      if (selectedPlaylistIndex !== -1) {
        playlistData[selectedPlaylistIndex] = {
          ...playlistData[selectedPlaylistIndex],
          clips: playlistData[selectedPlaylistIndex].clips.filter((clip) => clip.id !== action.value.clipId),
        };
      }
      return {
        ...state,
        playlistByProjectSelected: { ...state.playlistByProjectSelected, data: playlistData },
      };
    }

    case ActionContentManagementType.ACTION_DELETE_PLAY_LIST_SUCCESS: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);

      return {
        ...state,
        playlistByProjectSelected: { ...state.playlistByProjectSelected, data: playlistData.filter((playlist) => playlist.id !== action.value) },
      };
    }

    case ActionContentManagementType.ACTION_SELECTED_PLAYLIST: {
      return {
        ...state,
        selectedPlaylist: action.value,
      };
    }

    case ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_SUCCESS: {
      const playlistData = global.structuredClone(state.playlistByProjectSelected.data);
      const selectedPlaylistIndex = playlistData.findIndex((playlist) => playlist.id === action.value.id);
      if (selectedPlaylistIndex !== -1) {
        playlistData[selectedPlaylistIndex] = {
          ...playlistData[selectedPlaylistIndex],
          ...action.value,
          clips: playlistData[selectedPlaylistIndex].clips,
        };
      }
      return {
        ...state,
        playlistByProjectSelected: { ...state.playlistByProjectSelected, data: playlistData },
      };
    }

    default: {
      return { ...state };
    }
  }
};
