import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../components/empty/empty";
import LmModalConfirm from "../../components/modal-confirm/LmModalConfirm";
import ModalNewPlayer from "../../components/modal-new-player/ModalNewPlayer";
import TeamInfoItem from "../../components/team-info-item/TeamInfoItem";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
import PlayerResultDto from "../../model/dto/PlayerResultDto";
import TeamsDto from "../../model/dto/TeamDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { isCoachOrHeadCoach } from "../../utils/utils";
interface Props {
  players: PlayerResultDto[] | undefined;
  team: TeamsDto;
}
const PlayersTab = ({ players, team }: Props) => {
  const [openModalPlayer, setOpenModalPlayer] = useState(false);
  const [openModalDeletePlayer, setOpenModalDeletePlayer] = useState(false);
  const [playerSelected, setPlayerSelected] = useState<PlayerResultDto>();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);

  const dispatch = useDispatch();

  const getDate = (date: string) => (date ? new Date(date).toLocaleDateString() : "");

  const editPlayer = (player: PlayerResultDto) => {
    setPlayerSelected(player);
    setOpenModalPlayer(true);
  };

  const deletePlayer = (player: PlayerResultDto) => {
    setPlayerSelected(player);
    setOpenModalDeletePlayer(true);
  };

  const onDeletePlayer = () => {
    dispatch<TypeActions>({
      type: ActionPlayerType.ACTION_DELETE_PLAYER_LOADING,
      value: { playerId: playerSelected?.id as string, teamId: playerSelected?.teamId as string },
    });
    setOpenModalDeletePlayer(false);
  };

  return (
    <>
      {players && players.length > 0 ? (
        players.map((player: any) => (
          <div className="border border-neutral-200 rounded p-4" key={player.id}>
            <TeamInfoItem
              avatarImage={player.avatarImage}
              titleText={
                <div>
                  <span className="italic">{player.nickName}</span> · {t("player." + player.position)}
                </div>
              }
              subtitleText={`${getDate(player.birthDate)} ${player.birthDate ? "·" : ""} Nº ${player.number}`}
              actionEdit={isCoachOrHeadCoach(team, stateAuth) ? () => editPlayer(player) : undefined}
              actionDelete={isCoachOrHeadCoach(team, stateAuth) ? () => deletePlayer(player) : undefined}
            />
          </div>
        ))
      ) : (
        <div className="col-span-full row-span-full">
          <Empty text={t("player.emptySection")} />
        </div>
      )}
      <ModalNewPlayer open={openModalPlayer} handleClose={() => setOpenModalPlayer(false)} player={playerSelected as PlayerResultDto} team={team} />
      <LmModalConfirm
        id="test-modal-confirm-remove-player"
        title={t("player.deletePlayerTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalDeletePlayer}
        handleClose={() => setOpenModalDeletePlayer(false)}
        confirm={onDeletePlayer}></LmModalConfirm>
    </>
  );
};

export default PlayersTab;
