import { DateRange } from "../DateRange";
import NotificationDto from "../dto/NotificationDto";

export enum ActionNotificationsType {
  ACTION_GET_NOTIFICATIONS_LOADING = "ACTION_GET_NOTIFICATIONS_LOADING",
  ACTION_GET_NOTIFICATIONS_SUCCESS = "ACTION_GET_NOTIFICATIONS_SUCCESS",
  ACTION_GET_NOTIFICATIONS_ERROR = "ACTION_GET_NOTIFICATIONS_ERROR",

  ACTION_MARK_NOTIFICATIONS_UNREAD_LOADING = "ACTION_MARK_NOTIFICATIONS_UNREAD_LOADING",
  ACTION_MARK_NOTIFICATIONS_UNREAD_SUCCESS = "ACTION_MARK_NOTIFICATIONS_UNREAD_SUCCESS",
  ACTION_MARK_NOTIFICATIONS_UNREAD_ERROR = "ACTION_MARK_NOTIFICATIONS_UNREAD_ERROR",

  ACTION_MARK_NOTIFICATIONS_READ_LOADING = "ACTION_MARK_NOTIFICATIONS_READ_LOADING",
  ACTION_MARK_NOTIFICATIONS_READ_SUCCESS = "ACTION_MARK_NOTIFICATIONS_READ_SUCCESS",
  ACTION_MARK_NOTIFICATIONS_READ_ERROR = "ACTION_MARK_NOTIFICATIONS_READ_ERROR",

  ACTION_DELETE_NOTIFICATIONS_LOADING = "ACTION_DELETE_NOTIFICATIONS_LOADING",
  ACTION_DELETE_NOTIFICATIONS_SUCCESS = "ACTION_DELETE_NOTIFICATIONS_SUCCESS",
  ACTION_DELETE_NOTIFICATIONS_ERROR = "ACTION_DELETE_NOTIFICATIONS_ERROR",

  ACTION_CHECK_NOTIFICATION = "ACTION_CHECK_NOTIFICATION",
  ACTION_UNCHECK_NOTIFICATION = "ACTION_UNCHECK_NOTIFICATION",
  ACTION_CHECK_ALL_NOTIFICATION = "ACTION_CHECK_ALL_NOTIFICATION",
  ACTION_UNCHECK_ALL_NOTIFICATION = "ACTION_UNCHECK_ALL_NOTIFICATION",

  ACTION_CHANGE_NOTIFICATION_TYPE_FILTER = "ACTION_CHANGE_NOTIFICATION_TYPE_FILTER",
  ACTION_CHANGE_READ_TYPE_FILTER = "ACTION_CHANGE_READ_TYPE_FILTER",
  ACTION_CHANGE_DATE_RANGE_FILTER = "ACTION_CHANGE_DATE_RANGE_FILTER",
  ACTION_CLEAN_NOTIFICATIONS_FILTER = "ACTION_CLEAR_NOTIFICATIONS_FILTER",
  ACTION_CHANGE_FILTERED_NOTIFICATIONS_LIST = "ACTION_CHANGE_FILTERED_NOTIFICATIONS_LIST",
}

export interface ActionGetNotificationsLoading {
  type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_LOADING;
}
export interface ActionGetNotificationsSuccess {
  type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_SUCCESS;
  value: NotificationDto[];
}
export interface ActionGetNotificationsError {
  type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_ERROR;
}

export interface ActionMarkNotificationsUnreadLoading {
  type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_LOADING;
  value: string[];
}
export interface ActionMarkNotificationsUnreadSuccess {
  type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_SUCCESS;
  value: NotificationDto[];
}
export interface ActionMarkNotificationsUnreadError {
  type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_ERROR;
}

export interface ActionMarkNotificationsReadLoading {
  type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_LOADING;
  value: string[];
}
export interface ActionMarkNotificationsReadSuccess {
  type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_SUCCESS;
  value: NotificationDto[];
}
export interface ActionMarkNotificationsReadError {
  type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_ERROR;
}

export interface ActionDeleteNotificationsLoading {
  type: ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_LOADING;
  value: string[];
}
export interface ActionDeleteNotificationsSuccess {
  type: ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_SUCCESS;
  value: NotificationDto[];
}
export interface ActionDeleteNotificationsError {
  type: ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_ERROR;
}

export interface ActionCheckNotification {
  type: ActionNotificationsType.ACTION_CHECK_NOTIFICATION;
  value: string;
}

export interface ActionUncheckNotification {
  type: ActionNotificationsType.ACTION_UNCHECK_NOTIFICATION;
  value: string;
}

export interface ActionCheckAllNotification {
  type: ActionNotificationsType.ACTION_CHECK_ALL_NOTIFICATION;
}

export interface ActionUncheckAllNotification {
  type: ActionNotificationsType.ACTION_UNCHECK_ALL_NOTIFICATION;
}

export interface ActionChangeNotificationTypeFilter {
  type: ActionNotificationsType.ACTION_CHANGE_NOTIFICATION_TYPE_FILTER;
  value: string[];
}

export interface ActionChangeReadTypeFilter {
  type: ActionNotificationsType.ACTION_CHANGE_READ_TYPE_FILTER;
  value: string[];
}

export interface ActionChangeDateRangeFilter {
  type: ActionNotificationsType.ACTION_CHANGE_DATE_RANGE_FILTER;
  value: DateRange;
}

export interface ActionCleanNotificationsFilter {
  type: ActionNotificationsType.ACTION_CLEAN_NOTIFICATIONS_FILTER;
}

export interface ActionChangeFilteredNotificationsList {
  type: ActionNotificationsType.ACTION_CHANGE_FILTERED_NOTIFICATIONS_LIST;
  value: NotificationDto[];
}

export type TypeNotificationsActions =
  | ActionGetNotificationsLoading
  | ActionGetNotificationsSuccess
  | ActionGetNotificationsError
  | ActionMarkNotificationsUnreadLoading
  | ActionMarkNotificationsUnreadSuccess
  | ActionMarkNotificationsUnreadError
  | ActionMarkNotificationsReadLoading
  | ActionMarkNotificationsReadSuccess
  | ActionMarkNotificationsReadError
  | ActionDeleteNotificationsLoading
  | ActionDeleteNotificationsSuccess
  | ActionDeleteNotificationsError
  | ActionCheckNotification
  | ActionUncheckNotification
  | ActionCheckAllNotification
  | ActionUncheckAllNotification
  | ActionChangeNotificationTypeFilter
  | ActionChangeReadTypeFilter
  | ActionCleanNotificationsFilter
  | ActionChangeFilteredNotificationsList
  | ActionChangeDateRangeFilter;
