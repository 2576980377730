import { useTranslation } from "react-i18next";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Mark } from "../../assets/icons/check-circle.svg";
import { ReactComponent as Unmark } from "../../assets/icons/x.svg";
import LmIcon from "../icon/LmIcon";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import { NotificationsState } from "../../model/states/NotificationsState";
import useNotifications from "../../hooks/useNotifications";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import { useState } from "react";

interface Props {
  selectedNum: number;
}

const LmNotificationsEdition = ({ selectedNum }: Props) => {
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);
  const { deleteNotification, markAsRead, markAsUnread } = useNotifications();
  const { t } = useTranslation();
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const onDeleteSelection = () => {
    deleteNotification(stateNotifications.selectedNotifications, true);
    setShowModalConfirm(false);
  };

  const onMarkAsRead = () => {
    markAsRead(stateNotifications.selectedNotifications, true);
  };
  const onMarkAsUnread = () => {
    markAsUnread(stateNotifications.selectedNotifications, true);
  };

  return (
    <div className="flex flex-wrap md:flex-nowrap items-center justify-center md:justify-start gap-4 md:gap-6 fixed bottom-0 md:bottom-6 inset-x-0 mx-auto p-5 md:px-6 md:py-8 w-full md:w-fit text-sm font-semibold bg-gray-100 md:rounded-xl drop-shadow-[0_4px_4px_rgba(0,0,0,0.1)] z-10">
      <p className="w-full md:w-auto">
        {selectedNum} {t("notifications.selected")}
      </p>
      <div className="flex items-center gap-1 cursor-pointer hover:opacity-70 transition" onClick={() => setShowModalConfirm(true)}>
        <LmIcon icon={<Trash />} size="sm" />
        <p>{t("notifications.delete")}</p>
      </div>
      <div className="flex items-center gap-1 cursor-pointer hover:opacity-70 transition" onClick={onMarkAsRead}>
        <LmIcon icon={<Mark />} size="sm" />
        <p>{t("notifications.mark")}</p>
      </div>
      <div className="flex items-center gap-1 cursor-pointer hover:opacity-70 transition" onClick={onMarkAsUnread}>
        <LmIcon icon={<Unmark />} size="sm" />
        <p>{t("notifications.unmark")}</p>
      </div>
      <LmModalConfirm
        id="test-modal-confirm-remove-notification"
        title={t("notifications.deleteNotificationTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={showModalConfirm}
        handleClose={() => setShowModalConfirm(false)}
        confirm={onDeleteSelection}
      />
    </div>
  );
};

export default LmNotificationsEdition;
