import React, { useEffect, useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down-black.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron-up.svg";
import LmIcon from "../icon/LmIcon";
import { useSearchParams } from "react-router-dom";
import { ProjectCategoryDto } from "../../model/dto/ProjectDto";

interface IAnalysisAccordion {
  event?: ProjectCategoryDto;
  color: string;
  header: any;
  children: any;
}

const AnalysisAccordion: React.FC<IAnalysisAccordion> = ({ event, color, header, children }) => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(()=>{
    const eventParam = searchParams.get('event');
    if (eventParam && event && eventParam === event.name?.trim()) {
      setOpen(true);      
    }
  }, [])
  
  
  return (
    <div>
      <div
        className={`flex justify-between items-center border-b border-neutral-200 ${color ? "border-l-4" : ""} cursor-pointer`}
        style={{ borderLeftColor: `${color}` }}
        onClick={() => setOpen(!open)}>
        {header}
        <LmIcon className="p-4" icon={open ? <ChevronUp /> : <ChevronDown />} size="sm" />
      </div>
      {open && <div className="">{children}</div>}
    </div>
  );
};

export default AnalysisAccordion;
