import { t } from "i18next";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionAuthType, TypeAuthActions } from "../../model/actions/typeAuthActions";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import { ActionOrganizationType, TypeOrganizationActions } from "../../model/actions/typeOrganizationActions";
import {
  ActionCreateStaffLoading,
  ActionCreateTeamGroupLoading,
  ActionCreateTeamLoading,
  ActionCreateTeamWithStaffLoading,
  ActionDeleteStaffLoading,
  ActionDeleteTeamGroupLoading,
  ActionDeleteTeamLoading,
  ActionEditStaffLoading,
  ActionEditTeamGroupLoading,
  ActionEditTeamLoading,
  ActionGetGroupsByOrganizationLoading,
  ActionGetTeamGroupsLoading,
  ActionTeamSumaryLoading,
  ActionTeamType,
  TypeTeamActions,
} from "../../model/actions/typeTeamActions";
import ResponseApiError from "../../model/dto/ResponseApiError";
import StaffDto from "../../model/dto/StaffDto";
import TeamsDto from "../../model/dto/TeamDto";
import TeamGroupResultDto from "../../model/dto/TeamGroupResultDto";
import TeamSumaryDto from "../../model/dto/TeamSumaryDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getFetchParams } from "./utilsSaga";

const baseUrlTeamsApi = process.env.REACT_APP_API_URL + "Teams";
const baseUrlTeamGroupApi = process.env.REACT_APP_API_URL + "TeamPlayerGroups";

export function* createTeam(action: ActionCreateTeamLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "?locale=" + state.appLanguage,
      getFetchParams("POST", action.value.dataCallback.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamsDto = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_SUCCESS, value: data });
      /*  if (!action.value.isOrganizationAssistant) {        */
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_CREATE_TEAM, value: data });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.teamForm.createSuccess"), severity: "success" } });
      /*  } */
      yield call(action.value.dataCallback.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* createTeamWithStaff(action: ActionCreateTeamWithStaffLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "/withStaff?locale=" + state.appLanguage,
      getFetchParams("POST", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamsDto = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_SUCCESS, value: data });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_CREATE_TEAM, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editTeam(action: ActionEditTeamLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "/" + action.value.data.id + "?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamsDto = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_EDIT_TEAM_SUCCESS, value: data });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_TEAM, value: data });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.teamForm.editSuccess"), severity: "success" } });
      yield call(action.value.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_EDIT_TEAM_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteTeam(action: ActionDeleteTeamLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "/" + action.value.id + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_DELETE_TEAM_SUCCESS });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_DELETE_TEAM, value: action.value.id as string });

      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_CHANGE_TEAM_TAB_SELECTED, value: 0 });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.teamForm.deleteSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_DELETE_TEAM_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* sumaryTeam(action: ActionTeamSumaryLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamSumaryDto = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_TEAM_SUMARY_SUCCESS, value: data });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_TEAM, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_TEAM_SUMARY_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* createStaff(action: ActionCreateStaffLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "/invite?locale=" + state.appLanguage,
      getFetchParams("POST", action.value.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_STAFF_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("staff.createSuccess"), severity: "success" } });
      if (action.value.data.userId) {
        const data: StaffDto[] = yield call([response, "json"]);
        if (data.length > 0 && !data[0].avatarImage && action.value.data.avatarImage) {
          data[0].avatarImage = action.value.data.avatarImage;
        }

        yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_CREATE_STAFF, value: data });
      }
      yield call(action.value.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_STAFF_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editStaff(action: ActionEditStaffLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "/" + action.value.teamId + "/user/" + action.value.userId + "?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value.dataCallback.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: StaffDto = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_STAFF, value: data });
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_EDIT_STAFF_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("staff.editSuccess"), severity: "success" } });
      yield call(action.value.dataCallback.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_EDIT_STAFF_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* createTeamGroup(action: ActionCreateTeamGroupLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamGroupApi + "?locale=" + state.appLanguage,
      getFetchParams("POST", action.value.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamGroupResultDto = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_SUCCESS, value: data });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_CREATE_TEAM_GROUP, value: data });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.createTeamGroupSuccess"), severity: "success" } });
      yield call(action.value.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteStaff(action: ActionDeleteStaffLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamsApi + "/" + action.value.teamId + "/user/" + action.value.userId + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_DELETE_STAFF, value: action.value });
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_DELETE_STAFF_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("staff.deleteSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_DELETE_STAFF_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editTeamGroup(action: ActionEditTeamGroupLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamGroupApi + "/" + action.value.data.id + "?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamGroupResultDto = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_SUCCESS, value: data });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_TEAM_GROUP, value: data });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.editTeamGroupSuccess"), severity: "success" } });
      yield call(action.value.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteTeamGroup(action: ActionDeleteTeamGroupLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamGroupApi + "/" + action.value.id + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_DELETE_TEAM_GROUP_SUCCESS });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_DELETE_TEAM_GROUP, value: action.value.id });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.deleteTeamGroupSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_DELETE_TEAM_GROUP_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getGroups(action: ActionGetTeamGroupsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamGroupApi + "/byTeam/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamGroupResultDto[] = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_GET_TEAM_GROUPS_SUCCESS });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_GET_TEAM_GROUPS, value: { data: data, teamId: action.value } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_GET_TEAM_GROUPS_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getGroupsByOrganization(action: ActionGetGroupsByOrganizationLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlTeamGroupApi + "/byOrganizationId/" + action.value,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: TeamGroupResultDto[] = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_SUCCESS });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_GROUPS_BY_ORGANIZATION, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeTeamActions>({ type: ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* createTeamWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_CREATE_TEAM_LOADING, createTeam);
}

export function* createTeamWithStaffWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_LOADING, createTeamWithStaff);
}

export function* editTeamWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_EDIT_TEAM_LOADING, editTeam);
}

export function* sumaryTeamWhatcher() {
  yield takeEvery(ActionTeamType.ACTION_TEAM_SUMARY_LOADING, sumaryTeam);
}

export function* deleteTeamWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_DELETE_TEAM_LOADING, deleteTeam);
}

export function* createStaffWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_CREATE_STAFF_LOADING, createStaff);
}

export function* editStaffWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_EDIT_STAFF_LOADING, editStaff);
}

export function* deleteStaffWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_DELETE_STAFF_LOADING, deleteStaff);
}

export function* createTeamGroupWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_CREATE_TEAM_GROUP_LOADING, createTeamGroup);
}

export function* editTeamGroupWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_EDIT_TEAM_GROUP_LOADING, editTeamGroup);
}

export function* deleteTeamGroupWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_DELETE_TEAM_GROUP_LOADING, deleteTeamGroup);
}

export function* getGroupsWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_GET_TEAM_GROUPS_LOADING, getGroups);
}

export function* getGroupsByOrganizationWhatcher() {
  yield takeLatest(ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_LOADING, getGroupsByOrganization);
}

export default function* rootTeamSaga() {
  yield all([
    createTeamWithStaffWhatcher(),
    editTeamWhatcher(),
    sumaryTeamWhatcher(),
    deleteTeamWhatcher(),
    createStaffWhatcher(),
    editStaffWhatcher(),
    deleteStaffWhatcher(),
    createTeamWhatcher(),
    createTeamGroupWhatcher(),
    editTeamGroupWhatcher(),
    deleteTeamGroupWhatcher(),
    getGroupsWhatcher(),
    getGroupsByOrganizationWhatcher(),
  ]);
}
