import { Dialog } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { LmPlayerForm } from "../form-player/LmPlayerForm";
import PlayerResultDto from "../../model/dto/PlayerResultDto";
import TeamsDto from "../../model/dto/TeamDto";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import { useSelector } from "react-redux";

interface Props {
  open: boolean;
  player: PlayerResultDto | null;
  team: TeamsDto;
  handleClose(): void;
}
const ModalNewPlayer = ({ open, handleClose, player, team }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const { t } = useTranslation();

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8">
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl">{!player ? t("player.newPlayer") : t("player.editPlayer")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-new-player" />
        </div>
        <LmPlayerForm onClose={onClose} player={player} team={team} />
      </div>
    </Dialog>
  );
};

export default ModalNewPlayer;
