import { Dialog } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { LmStaffForm } from "../form-staff/LmStaffForm";
import StaffDto from "../../model/dto/StaffDto";
import TeamsDto from "../../model/dto/TeamDto";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
interface Props {
  id?: string;
  open: boolean;
  team: TeamsDto;
  staff: StaffDto | null;
  handleClose(): void;
}
const LmModalStaff = ({ id, open, handleClose, team, staff }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const { t } = useTranslation();

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8" data-testid={id}>
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl">{!staff ? t("staff.addStaff") : t("staff.editStaff")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-new-group" />
        </div>
        <LmStaffForm onClose={onClose} team={team} staff={staff} />
      </div>
    </Dialog>
  );
};

export default LmModalStaff;
