import { DataCallback } from "../DataCallback";
import { EditStaffDto } from "../dto/EditStaffDto";
import StaffCreateDto from "../dto/StaffCreateDto";
import TeamDto, { UpdateTeam } from "../dto/TeamDto";
import TeamGroupResultDto from "../dto/TeamGroupResultDto";
import TeamSumaryDto from "../dto/TeamSumaryDto";
import { TeamGroupForm } from "../forms/TeamGroupForm";

export enum ActionTeamType {
  ACTION_CREATE_TEAM_LOADING = "ACTION_CREATE_TEAM_LOADING",
  ACTION_CREATE_TEAM_SUCCESS = "ACTION_CREATE_TEAM_SUCCESS",
  ACTION_CREATE_TEAM_ERROR = "ACTION_CREATE_TEAM_ERROR",
  ACTION_CREATE_TEAM_WITH_STAFF_LOADING = "ACTION_CREATE_TEAM_WITH_STAFF_LOADING",
  ACTION_CREATE_TEAM_WITH_STAFF_SUCCESS = "ACTION_CREATE_TEAM_WITH_STAFF_SUCCESS",
  ACTION_CREATE_TEAM_WITH_STAFF_ERROR = "ACTION_CREATE_TEAM_WITH_STAFF_ERROR",
  ACTION_CHANGE_TEAM_FORM_VALUE = "ACTION_CHANGE_TEAM_FORM_VALUE",
  ACTION_SELECT_TEAM = "ACTION_SELECT_TEAM",
  ACTION_EDIT_TEAM_LOADING = "ACTION_EDIT_TEAM_LOADING",
  ACTION_EDIT_TEAM_SUCCESS = "ACTION_EDIT_TEAM_SUCCESS",
  ACTION_EDIT_TEAM_ERROR = "ACTION_EDIT_TEAM_ERROR",
  ACTION_TEAM_SUMARY_LOADING = "ACTION_TEAM_SUMARY_LOADING",
  ACTION_TEAM_SUMARY_SUCCESS = "ACTION_TEAM_SUMARY_SUCCESS",
  ACTION_TEAM_SUMARY_ERROR = "ACTION_TEAM_SUMARY_ERROR",
  ACTION_DELETE_TEAM_LOADING = "ACTION_DELETE_TEAM_LOADING",
  ACTION_DELETE_TEAM_SUCCESS = "ACTION_DELETE_TEAM_SUCCESS",
  ACTION_DELETE_TEAM_ERROR = "ACTION_DELETE_TEAM_ERROR",
  ACTION_CREATE_STAFF_LOADING = "ACTION_CREATE_STAFF_LOADING",
  ACTION_CREATE_STAFF_SUCCESS = "ACTION_CREATE_STAFF_SUCCESS",
  ACTION_CREATE_STAFF_ERROR = "ACTION_CREATE_STAFF_ERROR",

  ACTION_EDIT_STAFF_LOADING = "ACTION_EDIT_STAFF_LOADING",
  ACTION_EDIT_STAFF_SUCCESS = "ACTION_EDIT_STAFF_SUCCESS",
  ACTION_EDIT_STAFF_ERROR = "ACTION_EDIT_STAFF_ERROR",

  ACTION_DELETE_STAFF_LOADING = "ACTION_DELETE_STAFF_LOADING",
  ACTION_DELETE_STAFF_SUCCESS = "ACTION_DELETE_STAFF_SUCCESS",
  ACTION_DELETE_STAFF_ERROR = "ACTION_DELETE_STAFF_ERROR",
  ACTION_CREATE_TEAM_GROUP_LOADING = "ACTION_CREATE_TEAM_GROUP_LOADING",
  ACTION_CREATE_TEAM_GROUP_SUCCESS = "ACTION_CREATE_TEAM_GROUP_SUCCESS",
  ACTION_CREATE_TEAM_GROUP_ERROR = "ACTION_CREATE_TEAM_GROUP_ERROR",
  ACTION_EDIT_TEAM_GROUP_LOADING = "ACTION_EDIT_TEAM_GROUP_LOADING",
  ACTION_EDIT_TEAM_GROUP_SUCCESS = "ACTION_EDIT_TEAM_GROUP_SUCCESS",
  ACTION_EDIT_TEAM_GROUP_ERROR = "ACTION_EDIT_TEAM_GROUP_ERROR",
  ACTION_DELETE_TEAM_GROUP_LOADING = "ACTION_DELETE_TEAM_GROUP_LOADING",
  ACTION_DELETE_TEAM_GROUP_SUCCESS = "ACTION_DELETE_TEAM_GROUP_SUCCESS",
  ACTION_DELETE_TEAM_GROUP_ERROR = "ACTION_DELETE_TEAM_GROUP_ERROR",

  ACTION_GET_TEAM_GROUPS_LOADING = "ACTION_GET_TEAM_GROUPS_LOADING",
  ACTION_GET_TEAM_GROUPS_SUCCESS = "ACTION_GET_TEAM_GROUPS_SUCCESS",
  ACTION_GET_TEAM_GROUPS_ERROR = "ACTION_GET_TEAM_GROUPS_ERROR",

  ACTION_GET_GROUPS_BY_ORGANIZATION_LOADING = "ACTION_GET_GROUPS_BY_ORGANIZATION_LOADING",
  ACTION_GET_GROUPS_BY_ORGANIZATION_SUCCESS = "ACTION_GET_GROUPS_BY_ORGANIZATION_SUCCESS",
  ACTION_GET_GROUPS_BY_ORGANIZATION_ERROR = "ACTION_GET_GROUPS_BY_ORGANIZATION_ERROR",

  ACTION_UPDATE_DASHBOARD_MAX_STORAGE = "ACTION_UPDATE_DASHBOARD_MAX_STORAGE",
  ACTION_RESET_DASHBOARD_MAX_STORAGE = "ACTION_RESET_DASHBOARD_MAX_STORAGE",
}

export interface ActionUpdateDashboardMaxStorage {
  type: ActionTeamType.ACTION_UPDATE_DASHBOARD_MAX_STORAGE;
  value: number;
}

export interface ActionResetDashboardMaxStorage {
  type: ActionTeamType.ACTION_RESET_DASHBOARD_MAX_STORAGE;
}
export interface ActionCreateTeamLoading {
  type: ActionTeamType.ACTION_CREATE_TEAM_LOADING;
  value: { isOrganizationAssistant: boolean; dataCallback: DataCallback<UpdateTeam> };
}

export interface ActionCreateTeamSuccess {
  type: ActionTeamType.ACTION_CREATE_TEAM_SUCCESS;
  value: TeamDto;
}

export interface ActionCreateTeamError {
  type: ActionTeamType.ACTION_CREATE_TEAM_ERROR;
  value: string;
}

export interface ActionCreateTeamWithStaffLoading {
  type: ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_LOADING;
  value: TeamDto;
}

export interface ActionCreateTeamWithStaffSuccess {
  type: ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_SUCCESS;
  value: TeamDto;
}

export interface ActionCreateTeamWithStaffError {
  type: ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_ERROR;
  value: string;
}

export interface ActionChangeTeamsFormValue {
  type: ActionTeamType.ACTION_CHANGE_TEAM_FORM_VALUE;
  value: { key: string; value: any };
}

export interface ActionSelectTeam {
  type: ActionTeamType.ACTION_SELECT_TEAM;
  value: TeamDto;
}

export interface ActionEditTeamLoading {
  type: ActionTeamType.ACTION_EDIT_TEAM_LOADING;
  value: DataCallback<UpdateTeam>;
}

export interface ActionEditTeamSuccess {
  type: ActionTeamType.ACTION_EDIT_TEAM_SUCCESS;
  value: TeamDto;
}

export interface ActionEditTeamError {
  type: ActionTeamType.ACTION_EDIT_TEAM_ERROR;
  value: string;
}
export interface ActionTeamSumaryLoading {
  type: ActionTeamType.ACTION_TEAM_SUMARY_LOADING;
  value: string;
}

export interface ActionTeamSumarySuccess {
  type: ActionTeamType.ACTION_TEAM_SUMARY_SUCCESS;
  value: TeamSumaryDto;
}

export interface ActionTeamSumaryError {
  type: ActionTeamType.ACTION_TEAM_SUMARY_ERROR;
}

export interface ActionDeleteTeamLoading {
  type: ActionTeamType.ACTION_DELETE_TEAM_LOADING;
  value: TeamDto;
}

export interface ActionDeleteTeamSuccess {
  type: ActionTeamType.ACTION_DELETE_TEAM_SUCCESS;
}

export interface ActionDeleteTeamError {
  type: ActionTeamType.ACTION_DELETE_TEAM_ERROR;
  value: string;
}

export interface ActionCreateStaffLoading {
  type: ActionTeamType.ACTION_CREATE_STAFF_LOADING;
  value: DataCallback<StaffCreateDto>;
}

export interface ActionCreateStaffSuccess {
  type: ActionTeamType.ACTION_CREATE_STAFF_SUCCESS;
}

export interface ActionCreateStaffError {
  type: ActionTeamType.ACTION_CREATE_STAFF_ERROR;
}

export interface ActionEditStaffLoading {
  type: ActionTeamType.ACTION_EDIT_STAFF_LOADING;
  value: {
    teamId: string;
    userId: string;
    dataCallback: DataCallback<EditStaffDto>;
  };
}
export interface ActionEditStaffSuccess {
  type: ActionTeamType.ACTION_EDIT_STAFF_SUCCESS;
}

export interface ActionEditStaffError {
  type: ActionTeamType.ACTION_EDIT_STAFF_ERROR;
}

export interface ActionDeleteStaffLoading {
  type: ActionTeamType.ACTION_DELETE_STAFF_LOADING;
  value: { userId: string; teamId: string };
}

export interface ActionDeleteStaffSuccess {
  type: ActionTeamType.ACTION_DELETE_STAFF_SUCCESS;
}

export interface ActionDeleteStaffError {
  type: ActionTeamType.ACTION_DELETE_STAFF_ERROR;
}
export interface ActionCreateTeamGroupLoading {
  type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_LOADING;
  value: DataCallback<TeamGroupResultDto>;
}
export interface ActionCreateTeamGroupSuccess {
  type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_SUCCESS;
  value: TeamGroupResultDto;
}

export interface ActionCreateTeamGroupError {
  type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_ERROR;
  value: string;
}

export interface ActionEditTeamGroupLoading {
  type: ActionTeamType.ACTION_EDIT_TEAM_GROUP_LOADING;
  value: DataCallback<TeamGroupForm>;
}

export interface ActionEditTeamGroupSuccess {
  type: ActionTeamType.ACTION_EDIT_TEAM_GROUP_SUCCESS;
  value: TeamGroupResultDto;
}

export interface ActionEditTeamGroupError {
  type: ActionTeamType.ACTION_EDIT_TEAM_GROUP_ERROR;
  value: string;
}

export interface ActionDeleteTeamGroupLoading {
  type: ActionTeamType.ACTION_DELETE_TEAM_GROUP_LOADING;
  value: TeamGroupResultDto;
}

export interface ActionDeleteTeamGroupSuccess {
  type: ActionTeamType.ACTION_DELETE_TEAM_GROUP_SUCCESS;
}

export interface ActionDeleteTeamGroupError {
  type: ActionTeamType.ACTION_DELETE_TEAM_GROUP_ERROR;
  value: string;
}

export interface ActionGetTeamGroupsLoading {
  type: ActionTeamType.ACTION_GET_TEAM_GROUPS_LOADING;
  value: string;
}

export interface ActionGetTeamGroupsSuccess {
  type: ActionTeamType.ACTION_GET_TEAM_GROUPS_SUCCESS;
}

export interface ActionGetTeamGroupsError {
  type: ActionTeamType.ACTION_GET_TEAM_GROUPS_ERROR;
}

export interface ActionGetGroupsByOrganizationLoading {
  type: ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_LOADING;
  value: string;
}

export interface ActionGetGroupsByOrganizationSuccess {
  type: ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_SUCCESS;
}

export interface ActionGetGroupsByOrganizationError {
  type: ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_ERROR;
}

export type TypeTeamActions =
  | ActionCreateTeamLoading
  | ActionCreateTeamSuccess
  | ActionCreateTeamError
  | ActionCreateTeamWithStaffLoading
  | ActionCreateTeamWithStaffSuccess
  | ActionCreateTeamWithStaffError
  | ActionChangeTeamsFormValue
  | ActionSelectTeam
  | ActionEditTeamLoading
  | ActionEditTeamSuccess
  | ActionEditTeamError
  | ActionTeamSumaryLoading
  | ActionTeamSumarySuccess
  | ActionTeamSumaryError
  | ActionDeleteTeamLoading
  | ActionDeleteTeamSuccess
  | ActionDeleteTeamError
  | ActionCreateStaffLoading
  | ActionCreateStaffSuccess
  | ActionCreateStaffError
  | ActionEditStaffLoading
  | ActionEditStaffSuccess
  | ActionEditStaffError
  | ActionDeleteStaffLoading
  | ActionDeleteStaffSuccess
  | ActionDeleteStaffError
  | ActionCreateTeamGroupLoading
  | ActionCreateTeamGroupSuccess
  | ActionCreateTeamGroupError
  | ActionEditTeamGroupLoading
  | ActionEditTeamGroupSuccess
  | ActionEditTeamGroupError
  | ActionDeleteTeamGroupLoading
  | ActionDeleteTeamGroupSuccess
  | ActionDeleteTeamGroupError
  | ActionGetTeamGroupsLoading
  | ActionGetTeamGroupsSuccess
  | ActionGetTeamGroupsError
  | ActionGetGroupsByOrganizationLoading
  | ActionGetGroupsByOrganizationSuccess
  | ActionGetGroupsByOrganizationError
  | ActionUpdateDashboardMaxStorage
  | ActionResetDashboardMaxStorage;
