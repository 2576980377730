import React, { useEffect, useState } from "react";
import LmAvatarLabelInfoTwoLines from "../avatar-label-info/LmAvatarLabelInfoTwoLines";
import { SizeScreen } from "../../model/SizeSreen";
import { MainState } from "../../model/states/MainState";
import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../model/states/InitialState";
import { ReactComponent as FilePdf } from "../../assets/icons/file-pdf.svg";
import { ReactComponent as FileDashboard } from "../../assets/icons/layout-alt.svg";
import { ReactComponent as FileVideo } from "../../assets/icons/film.svg";
import { ReactComponent as FileProject } from "../../assets/icons/star.svg";
import { ReactComponent as FileTemplate } from "../../assets/icons/align-left.svg";
import { ICON_DOCUMENT_COLOR, ICON_VIDEO_COLOR } from "../../constants/globalConstanst";
import { ContentManagementType } from "../../model/states/ContentManagementState";
import LmIcon from "../icon/LmIcon";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { formatDateAgo, getSelectedDataById } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";
import DocumentDto from "../../model/dto/DocumentDto";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT, ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL } from "../../routes/routes";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";

interface IHomeDocument {
  document: DocumentDto;
}

const HomeDocument: React.FC<IHomeDocument> = ({ document }) => {
  const { type, teamId, fileName, createdAt, tags } = document;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const [firstTag, setFirstTag] = useState<string | null>(null);
  const [tagNumber, setTagNumber] = useState<number | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const [tagTooltip, setTagTooltip] = useState<string | null>(null);

  useEffect(() => {
    const publicTags = tags.filter(tag => tag.type === 'Public')
    setFirstTag(publicTags[0]?.name ?? null);
    setTagNumber(publicTags.length > 1 ? tags.length - 1 : null);
    setDate(formatDateAgo(createdAt, stateAuth.appLanguage) ?? null);
    if (tags.length > 1) {
      const result = [...publicTags]        
        .splice(1)
        .map((tag) => tag.name)
        .join(", ");
      setTagTooltip(result);
    }
  }, [document]);

  const getDocumentIcon = () => {
    switch (type) {
      case ContentManagementType[ContentManagementType.Document]:
        return (
          <div className="flex justify-center items-center h-full text-decorative bg-document">
            <LmIcon icon={<FilePdf />} size="base" />
          </div>
        );
      case ContentManagementType[ContentManagementType.Video]:
        return (
          <div className="flex justify-center items-center h-full text-decorative bg-video">
            <LmIcon icon={<FileVideo />} size="base" />
          </div>
        );
      case ContentManagementType[ContentManagementType.Dashboard]:
        return (
          <div className="flex justify-center items-center h-full text-decorative bg-dashboard">
            <LmIcon icon={<FileDashboard />} size="base" />
          </div>
        );
      case ContentManagementType[ContentManagementType.Project]:
        return (
          <div className="flex justify-center items-center h-full text-decorative bg-project">
            <LmIcon icon={<FileProject />} size="base" />
          </div>
        );
      case ContentManagementType[ContentManagementType.Template]:
        return (
          <div className="flex justify-center items-center h-full text-decorative bg-template">
            <LmIcon icon={<FileTemplate />} size="base" />
          </div>
        );

      default:
        break;
    }
    if (ContentManagementType[ContentManagementType.Document] === type) {
      return (
        <div className={`bg-[${ICON_DOCUMENT_COLOR}]`}>
          <LmIcon icon={<FilePdf />} size="base" />
        </div>
      );
    } else if (ContentManagementType[ContentManagementType.Video] === type) {
      return (
        <div className={`bg-[${ICON_VIDEO_COLOR}]`}>
          <LmIcon icon={<FileVideo />} size="base" />
        </div>
      );
    }
  };

  const getTeamName = () => {
    return selectedOrganizationData?.teams.find((team) => team.id === teamId)?.name ?? "";
  };

  const goToDocumentDetail = () => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL });
    navigate(`/${ROUTE_PRIVATE_CONTENT_MANAGEMENT}/${document.id}`);
  };

  return (
    <div className="flex items-center gap-4 cursor-pointer" onClick={goToDocumentDetail}>
      <div className="flex-1 truncate">
        <LmAvatarLabelInfoTwoLines size={48} radius="0.75rem" avatarIcon={getDocumentIcon()} label={fileName} secondLine={getTeamName()} />
      </div>

      <div className="flex-1 flex md:grid md:grid-cols-2 justify-end items-center ml-auto">
        {stateMain.sizeScreen !== SizeScreen.MOBILE && (
          <div className="flex justify-end items-center gap-1 text-sm text-white text-center">
            {firstTag && <span className="bg-orgColor rounded-full py-1 px-2">{firstTag}</span>}
            {tagNumber !== null && (
              <Tooltip title={tagTooltip}>
                <span className="bg-orgColor rounded-full py-1 px-2">{`+${tagNumber}`}</span>
              </Tooltip>
            )}
          </div>
        )}

        <p className="shrink-0 text-sm text-neutral-400 text-right">{date}</p>
      </div>
    </div>
  );
};

export default HomeDocument;
