import Popover from '@mui/material/Popover';
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron_down.svg";
import { IdValue } from "../../model/forms/IdValue";
import LmCheckBox from "../check-box/LmCheckBox";
import LmInput from "../input/LmInput";
import "./LmSelect.scss";

interface ISelect {
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string | null;
  tags?: boolean;
  readonly?: boolean;
  required?: boolean;
  options: IdValue[];
  value?: IdValue | undefined | null;
  valueMultiple?: IdValue[];
  prefix?: any;
  multipleCheck?: boolean;
  selectedValue(value: IdValue | IdValue[]): void;
}

const getValueSelected = (value: IdValue | undefined | null, valueMultiple: IdValue[]): string => {
  let response = t("formLabel.selectEmpty") as string;
  if (value) {
    response = value.value;
  } else if (valueMultiple) {
    let num = "";
    if (valueMultiple?.length > 1) {
      num = `(+ ${valueMultiple?.length - 1})`;
    }
    if (valueMultiple?.length > 0) {
      response = `${valueMultiple[0].value} ${num}`;
    }
  }

  return response;
};

const LmSelect: React.FC<ISelect> = ({
  id = "",
  placeholder = "",
  readonly = true,
  disabled = false,
  error = false,
  label = "",
  tags = false,
  required = false,
  options = [],
  value,
  valueMultiple = [],
  prefix = null,
  multipleCheck = false,
  selectedValue = () => {},
}) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [valueSelected, setValueSelected] = useState(getValueSelected(value, valueMultiple));
  const emptyMessage = placeholder !== "" ? placeholder : t("formLabel.selectEmpty");

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (value && value.value !== valueSelected) {
      setValueSelected(value?.value as string);
    } else if (!value && !valueSelected) {
      setValueSelected("");
    }
  }, [value]);

  useEffect(() => {
    if (value === null) {
      setValueSelected("");
    }
  }, [value]);

  useEffect(() => {
    if (multipleCheck && valueMultiple.length === 0) {
      setValueSelected("");
    }
  }, [valueMultiple]);

  const optionSelected = (option: IdValue) => {
    setOpenDropDown(false);

    setValueSelected(option.value);
    selectedValue(option);
  };

  const optionMultipleSelected = (e: any, option: any) => {
    let options = [...valueMultiple];
    if (e.target.checked) {
      options.push(option);
    } else {
      options = valueMultiple?.filter((op) => op.id !== option.id);
    }
    let num = "";
    if (options.length > 1) {
      num = `(+ ${options.length - 1})`;
    }

    setValueSelected(options.length > 0 ? `${options[0].value} ${num}` : "");
    selectedValue(options);
  };

  return (
    <div className="relative">
      <div
        className={`lm-select ${disabled ? "lm-input--disabled" : ""} ${error ? "lm-input--error" : ""}`}
        ref={ref}
        onClick={handleClick}
        data-testid="test-lm-select">
        <LmInput
          id={id}
          label={label}
          tags={tags}
          value={valueSelected}
          disabled={disabled}
          placeholder={emptyMessage}
          readonly={readonly}
          required={required}
          iconSufix={<ChevronDown />}
          prefix={prefix}
          iconSufixAction={() => setOpenDropDown(!openDropDown)}
        />
      </div>

      {!multipleCheck && (
        <Popover
          id={id + "-" + "dropdown"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <div className="lm-drowdown-panel" data-testid={id + "-" + "dropdown"} style={{ width: ref?.current?.offsetWidth }}>
            {options.map((option) => (
              <div
                key={option.id}
                className="lm-option"
                onClick={() => {
                  optionSelected(option);
                  handleClose();
                }}
                data-testid="select-option">
                {option.image && <img src={option.image} className="w-6"></img>}
                {option.icon && (option.icon as SVGSVGElement)}
                <span>{option.value}</span>
              </div>
            ))}
          </div>
        </Popover>
      )}

      {multipleCheck && (
        <Popover
          id={id + "-" + "dropdown"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <div className="lm-drowdown-panel" data-testid={id + "-" + "dropdown"} style={{ width: ref?.current?.offsetWidth }}>
            {options.map((option) => (
              <div key={option.id} className="lm-option" data-testid="select-option">
                <LmCheckBox
                  text={option.value}
                  id={option.id}
                  value={valueMultiple?.find((op) => op.id === option.id) !== undefined}
                  changeValue={(e) => optionMultipleSelected(e, option)}></LmCheckBox>
                {option.icon && (option.icon as SVGSVGElement)}
              </div>
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default LmSelect;
