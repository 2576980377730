import LmIcon from "../icon/LmIcon";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";

import LmAvatar from "../avatar/LmAvatar";

interface Props {
  titleText: JSX.Element;
  subtitleText: string;
  avatarImage?: string;
  actionEdit?(): void;
  actionDelete?(): void;
}

const TeamInfoItem = ({ titleText, subtitleText, avatarImage, actionEdit, actionDelete }: Props) => {
  return (
    <div className="w-full flex justify-between gap-2">
      <div className="flex flex-wrap md:flex-nowrap items-center gap-2">
        {avatarImage && <LmAvatar src={avatarImage} radius="0.5rem" />}
        <div>
          <div className="text-sm font-semibold">{titleText}</div>
          <p className="text-sm text-subtle">{subtitleText}</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-2">
        {actionEdit && (
          <LmIcon className="ml-auto cursor-pointer hover:opacity-80 transition order-1 md:order-none" icon={<Edit />} size="xs" clickAction={actionEdit} />
        )}
        {actionDelete && <LmIcon className="ml-auto cursor-pointer hover:opacity-80 transition " icon={<Trash />} size="xs" clickAction={actionDelete} />}
      </div>
    </div>
  );
};

export default TeamInfoItem;
