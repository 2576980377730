import { useTranslation } from "react-i18next";
import LmButtonViewAll from "../../components/button-view-all/LmButtonViewAll";
import LmLastComments from "../../components/last-comments/LmLastComments";
import LmLastNotifications from "../../components/last-notifications/LmLastNotifications";
import { useEffect, useState } from "react";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ContentManagementState, ContentManagementType } from "../../model/states/ContentManagementState";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { orderDocuments } from "../../utils/utils";
import LmDocument from "../../components/content-management/LmDocument";
import Loading from "../../components/loading/Loading";
import Empty from "../../components/empty/empty";
import { useNavigate } from "react-router-dom";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT, ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL, ROUTE_PRIVATE_NOTIFICATIONS } from "../../routes/routes";
import DocumentDto from "../../model/dto/DocumentDto";
import { ActionAuthType } from "../../model/actions/typeAuthActions";

const PlayerDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const [lastDocuments, setLastDocuments] = useState<DocumentDto[]>([]);

  useEffect(() => {
    if (stateAuth.userInfo.selectedOrganization) {
      dispatch<TypeActions>({
        type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_LOADING,
        value: {
          organizationId: stateAuth.userInfo.selectedOrganization as string,
          types: [
            ContentManagementType[ContentManagementType.Document],
            ContentManagementType[ContentManagementType.Video],
            ContentManagementType[ContentManagementType.Playlist],
          ],
          top: 12,
        },
      });
    }
    
  }, [stateAuth.userInfo.selectedOrganization, stateAuth.appLanguage]);

  useEffect(() => {
    if (stateContent.lastDocuments.data.length > 0) {
      const result = orderDocuments(stateContent.lastDocuments.data, "asc", 12);
      setLastDocuments(result);
    } else {
      setLastDocuments([])
    }
  }, [stateContent.lastDocuments.data, stateAuth.userInfo.selectedOrganization]);

  const onNavigate = (route: string) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: route });
    navigate(`/${route}`);
  };

  const goToDocumentDetail = (documentId: string) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL });
    navigate(`/${ROUTE_PRIVATE_CONTENT_MANAGEMENT}/${documentId}`);
  };

  return (
    <div className="flex flex-col xl:flex-row xl:gap-12">
      <div className="flex-1 mb-5">
        <div className="flex justify-between items-center mb-5">
          <p className="font-extrabold">{t("dashboard.sharedWithYouSection")}</p>
          <LmButtonViewAll onClick={() => onNavigate(ROUTE_PRIVATE_CONTENT_MANAGEMENT)} />
        </div>
        {stateContent.lastDocuments.loading ? (
          <Loading />
        ) : lastDocuments.length ? (
          <div className="grid sm:grid-cols-2 gap-5">
            {lastDocuments.map((doc) => (
              <LmDocument key={doc.id} data={doc} selected={false} isReadMode={true} onSelect={() => goToDocumentDetail(doc.id)} />
            ))}
          </div>
        ) : (
          <Empty text={t("content.emptyCategory")} />
        )}
      </div>
      <div className="flex-1">
        <p className="font-extrabold">{t("dashboard.lastMentionsSection")}</p>
        <LmLastComments isPlayer={true} />
        <div className="flex justify-between items-center mb-5">
          <p className="font-extrabold">{t("dashboard.lastNotificationsSection")}</p>
          <LmButtonViewAll onClick={() => onNavigate(ROUTE_PRIVATE_NOTIFICATIONS)} />
        </div>
        <LmLastNotifications />
      </div>
    </div>
  );
};

export default PlayerDashboard;
