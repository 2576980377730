import React from "react";
import { getBase64Img } from "../../utils/utils";
interface IAnalysisHeaderItemByTeam {
  imgPlayer: string;
  imgTeam: string;
  name: string;
  num: number;
}

const AnalysisHeaderItemByTeam: React.FC<IAnalysisHeaderItemByTeam> = ({ imgPlayer, imgTeam, name, num }) => {
  return (
    <div className="flex items-center gap-4 px-5">
      <div className="h-8 w-12 relative">
        <figure className="h-8 w-8 rounded-full border border-neutral-200 flex justify-center items-center overflow-hidden">
          <img className="aspect-auto max-h-full" src={getBase64Img(imgPlayer)}></img>
        </figure>
        <figure className="h-6 w-6 absolute right-0 top-1 rounded-full border border-neutral-200 flex justify-center items-center overflow-hidden">
          <img className="aspect-auto max-h-full" src={getBase64Img(imgTeam)}></img>
        </figure>
      </div>
      <div>
        <p className="text-xs font-bold">
          {name} ({num})
        </p>
      </div>
    </div>
  );
};

export default AnalysisHeaderItemByTeam;
