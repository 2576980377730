import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Right } from "../../assets/icons/chevron-right.svg";
import LmIcon from "../icon/LmIcon";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import { useSelector } from "react-redux";

interface Props {
  onClick(): void;
}

const LmButtonViewAll = ({ onClick }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const { t } = useTranslation();
  return (
    <Link
      className="flex items-center hover:opacity-70 transition"
      component="button"
      type="button"
      underline="none"
      color="inherit"
      onClick={onClick}
      data-testid="test-add-new-staff">
      <p className="text-sm font-semibold">{t("buttonLabel.viewAll")}</p>

      <LmIcon icon={<Right />} size="base" className={`text-primary lm-icon-color theme-${stateMain.colorAplication}`} />
    </Link>
  );
};

export default LmButtonViewAll;
