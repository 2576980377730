import Popper from '@mui/material/Popper';
import { t } from "i18next";
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useClickAway } from 'react-use';
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import { IdValue } from "../../model/forms/IdValue";
import { normalizeString } from "../../utils/utils";
import LmAvatarLabelInfo from "../avatar-label-info/LmAvatarLabelInfo";
import LmInput from "../input/LmInput";
import "./LmAutocomplete.scss";
interface ISelect {
  id?: string;
  placeholder?: string;
  autocomplete?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string | null;
  required?: boolean;
  options: IdValue[];
  value?: IdValue[];
  valueDisplay?: string;
  prefix?: any;
  multiple: boolean;
  selectedValue(value: IdValue | IdValue[]): void;
  setValueDisplay?(e: ChangeEvent<HTMLInputElement>): void;
  dataFiltered?(values: IdValue[]): void;
}

const LmAutocomplete: React.FC<ISelect> = ({
  id = "",
  autocomplete = "off",
  placeholder = "",
  disabled = false,
  error = false,
  label = "",
  required = false,
  options = [],
  value = [],
  valueDisplay = "",
  prefix = null,
  multiple = false,
  setValueDisplay = () => {},
  selectedValue = () => {},
  dataFiltered = () => {},
}) => {    
  const [optionsFiltered, setOptionsFiltered] = useState(options);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const refDropDown = useRef<HTMLDivElement>(null);
  const [closeOutInput, setCloseOutInput] = useState(false)
  const [closeOutPanel, setCloseOutPanel] = useState(false)

  useClickAway(refDropDown, () => {   
    setCloseOutPanel(true)
  });

  useClickAway(ref, () => {  
    setCloseOutInput(true)
  });

  useEffect(()=>{
    if (closeOutInput && closeOutPanel) {
      handleClose();
    }
  }, [closeOutInput, closeOutPanel])

  const handleClick = () => {
    setAnchorEl(ref.current);
    setCloseOutInput(false)
    setCloseOutPanel(false)
  };

  const handleClose = () => {   
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);  
  
  const isSelected = (value: IdValue[], option: IdValue) => {
    const index = value.findIndex((aux: IdValue) => aux.id === option.id);
    return index !== -1;
  };

  useEffect(() => {
    const items = options.filter(option => optionsFiltered.includes(option) );
    if (items.length !== options.length) {
      setOptionsFiltered(options);
    }
      
  }, [options]);
 
  const optionSelected = (option: IdValue) => {    
    if (!isSelected(value, option)) {
      if (multiple) {
        selectedValue([...value, option]);
        setOptionsFiltered(options);
      } else {
        selectedValue([option]);
        setOptionsFiltered(options);
      }
    }
    handleClose()
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {    
    const inputValue = normalizeString(e.target.value);
    const valuesFiltered = options.filter((option) => normalizeString(option.value).includes(inputValue));
    setOptionsFiltered(valuesFiltered);
    setValueDisplay(e);
    dataFiltered(valuesFiltered);
    if (!multiple) selectedValue([]);
  };

  const deleteSelection = (id: string) => {
    selectedValue(value.filter((option) => option.id !== id));
    setOptionsFiltered(options);
  };  

  const keyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Tab') {
      handleClose()
    }
  } 

  return (
    <div className="" >     
      <div className={`lm-autocomplete ${disabled ? "lm-input--disabled" : ""} ${error ? "lm-input--error" : ""}`} data-testid="test-lm-select" ref={ref}>
        <LmInput
          autoComplete={autocomplete}
          id={id}
          label={label}
          value={valueDisplay}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          iconSufix={<Search />}
          prefix={prefix}
          focus={handleClick}
          keyDown={keyDown}           
          changeValue={(e) => onChange(e)}
        />
      </div>
     
      <Popper    
        id={id + "-" + "dropdown"}
        open={open}
        anchorEl={anchorEl}        
        placement="bottom"
        sx={{zIndex: '1400'}}        
      >
       <div className="lm-drowdown-panel overflow-y max-h-[320px]" data-testid={id + "-" + "dropdown"} style={{width: ref?.current?.offsetWidth, left:0, right:0}} ref={refDropDown}>
          {optionsFiltered.length > 0 ? (
            optionsFiltered.map((option) => (
              <div
                key={option.id}
                className={`lm-option ${isSelected(value, option) ? "lm-option--disabled" : ""}`}
                onClick={() => optionSelected(option)}
                data-testid="select-option">
                {option.icon && (option.icon as SVGSVGElement)} <span>{option.value}</span>
              </div>
            ))
          ) : (
            <div className="p-4 flex justify-center">{t("autocomplete.notFound")}</div>
          )}
        </div>
      </Popper>
      {multiple && (
        <div className="lm-autocomplete-selections my-3">
          {value.map((option) => (
            <LmAvatarLabelInfo
              key={option.id}
              src={option.image}
              size={20}
              radius={"100%"}
              label={option.value}
              icon={<Close />}
              onClickAction={() => deleteSelection(option.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LmAutocomplete;
