import { DashboardDataDto, DashboardTag } from "../../model/dto/DashboardDto";
import { parseColor } from "../../utils/utils";

export interface Props {
  dashboardData: DashboardDataDto[];
}

const LmDashboard = ({ dashboardData }: Props) => {
  const getColumnsClass = (tagsPerRow: number) => {
    return `grid grid-cols-${tagsPerRow}`;
  };

  const getRounded = (index: number) => {
    if (index === 0) {
      return "rounded-tl-lg";
    }

    // third element
    if (index === 2) {
      return "rounded-tr-lg";
    }
    return "";
  };

  const getChildBoder = (index: number, total: number, tagsPerRow: number) => {
    let border = "";
    if (index === 0 && index !== tagsPerRow) {
      border = "border-r";
    }

    // not last one of the row
    if (index + 1 < tagsPerRow && !border) {
      border = "border-r";
    }

    // not first row
    const hasMoreLines = total / tagsPerRow > 1;
    if (hasMoreLines) {
      border += ` border-b`;
    }

    if (hasMoreLines && index >= tagsPerRow && index + 1 !== total) {
      border += ` border-r`;
    }

    if (border) {
      border += ` border-neutral-200`;
    }

    return border;
  };

  return (
    <div className="grid grid-cols-[repeat(auto-fill,_minmax(18rem,_1fr))] auto-rows-max">
      {dashboardData?.map((dashboard: DashboardDataDto, index: number) => {
        return (
          <div
            className={`flex cursor-default m-px overflow-hidden`}
            style={{ backgroundColor: parseColor(dashboard.BackgroundColor), color: parseColor(dashboard.TextColor) }}
            key={dashboard.$id}>
            {!dashboard.EventType && (
              <div className={`${getRounded(index)} flex-1 text-center px-3 py-12 font-semibold text-sm truncate`} title={dashboard.Name}>
                {dashboard.Name}
              </div>
            )}
            {dashboard.EventType && (
              <>
                {!!dashboard.TagsPerRow && (
                  <div className={`${getRounded(index)} flex-1`} style={{ backgroundColor: parseColor(dashboard.EventType.Color) }}>
                    <div
                      title={dashboard.EventType.Name}
                      className={`text-center text-sm font-semibold px-3 py-12 ${
                        dashboard.EventType.Tags.length ? "border-b border-neutral-200" : "flex items-center justify-center"
                      }`}>
                      {dashboard.EventType.Name}
                    </div>
                    <div className={`${getColumnsClass(dashboard.TagsPerRow)}`}>
                      {dashboard.EventType.Tags.map((tag: DashboardTag, index: number) => (
                        <div
                          title={tag.Value}
                          key={tag.Value}
                          className={`text-center text-sm truncate px-3 py-12 border border-neutral-200" ${getChildBoder(
                            index,
                            dashboard.EventType.Tags.length,
                            dashboard.TagsPerRow
                          )}`}>
                          {tag.Value}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {dashboard.EventType.PenaltyCard && (
                  <div
                    title={dashboard.EventType.PenaltyCard.Name}
                    className="flex-1 flex items-center justify-center text-center rounded-full px-3 py-12 text-sm font-semibold sm:aspect-square"
                    style={{ backgroundColor: parseColor(dashboard.EventType.PenaltyCard.Color) }}>
                    {dashboard.EventType.PenaltyCard.Name}
                  </div>
                )}
                {dashboard.EventType.Score && (
                  <div
                    title={dashboard.EventType.Score.Name}
                    className={`${getRounded(index)} flex-1 text-center text-sm px-3 py-12 font-semibold truncate`}
                    style={{ backgroundColor: parseColor(dashboard.EventType.Score.Color) }}>
                    {dashboard.EventType.Score.Name}
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LmDashboard;
