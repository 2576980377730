import { ListItemButton } from "@mui/material";
import { ReactComponent as ExpandMore } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as ExpandLess } from "../../assets/icons/chevron-up.svg";
import { getBase64Img } from "../../utils/utils";
import LmIcon from "../icon/LmIcon";

interface Props {
  id: string;
  shield: string;
  name: string;
  isOpen: boolean;
  onTeamClick: (id: string) => void;
  onTeamExpand: () => void;
}

const MatchStatisticTeamList = ({ id, shield, name, isOpen, onTeamClick, onTeamExpand }: Props) => {
  return (
    <div className="border-b border-neutral-200">
      <ListItemButton onClick={() => onTeamClick(id)}>
        <div className="flex-1 flex items-center gap-2 max-w-full">
          <figure className="h-8 w-8 shrink-0 flex justify-center items-center rounded-full overflow-hidden border border-neutral-200">
            <img className="aspect-auto max-h-full" src={getBase64Img(shield)}></img>
          </figure>
          <div className="shrink overflow-hidden">
            <p className="text-sm font-semibold truncate" title={name}>
              {name}
            </p>
          </div>
          <div className="ml-auto" onClick={() => onTeamExpand()}>
            {isOpen ? <LmIcon icon={<ExpandLess />} size="xs" /> : <LmIcon icon={<ExpandMore />} size="xs" />}
          </div>
        </div>
      </ListItemButton>
    </div>
  );
};

export default MatchStatisticTeamList;
