import { Card, CardActionArea, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoLicense from "../../assets/img/no-license.png";
import LicenseDto from "../../model/dto/LicensesDto";
import LmAvatar from "../avatar/LmAvatar";

interface Props {
  license: LicenseDto;
  onClick?(license: LicenseDto): void;
}

const FreeLicenseCard = ({ license, onClick }: Props) => {
  const { t } = useTranslation();
  const purchasedAt = new Date(license.purchasedAt);
  const formatPurchasedAt = purchasedAt.toLocaleDateString("en-Gb", { year: "numeric", month: "long", day: "numeric" });

  return (
    <>
      <Card className="w-full sm:w-72" variant="outlined" key={license.id} data-testid={"license-" + license.id}>
        <CardActionArea className="h-full" onClick={() => onClick && onClick(license)} data-testid={"button-" + license.id}>
          <CardContent className="flex flex-col h-full items-center rounded">
            <LmAvatar src={NoLicense} className="mb-2" variant="rounded" size={56} radius="8px" border={true}></LmAvatar>
            <p className="max-w-xs text-center text-sm font-semibold">
              {t("licenseSelector.createOrganization1")}
              <span className="italic text-subtle">{` ${license.code} `}</span>
              {t("licenseSelector.createOrganization2")}
              <br></br>
              <span className="italic text-subtle">{formatPurchasedAt}</span>
            </p>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default FreeLicenseCard;
