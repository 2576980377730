import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Folder } from "../../../assets/icons/folder.svg";
import { ReactComponent as Info } from "../../../assets/icons/input-info.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import LmAccordion from "../../../components/accordion/LmAccordion";
import Empty from "../../../components/empty/empty";
import LmIcon from "../../../components/icon/LmIcon";
import Loading from "../../../components/loading/Loading";
import LmModalConfirm from "../../../components/modal-confirm/LmModalConfirm";
import LmModalInfoPlaylist from "../../../components/modal-info-playlist/LmModalInfoPlaylist";
import LmModalShareFile from "../../../components/modal-share-file/LmModalShareFile";
import LmPlaylistItem from "../../../components/playlistItem/LmPlaylistItem";
import { TypeActions } from "../../../model/actions/typeActions";
import { ActionContentManagementType } from "../../../model/actions/typeContentManagementActions";
import DocumentDto from "../../../model/dto/DocumentDto";
import { Clip, Timeline } from "../../../model/dto/ProjectDto";
import UserDto from "../../../model/dto/UserDto";
import { ContentManagementState } from "../../../model/states/ContentManagementState";
import { MainState } from "../../../model/states/MainState";

interface Props {
  users: Partial<UserDto>[];
  loggedUser: UserDto;
  viewTimeLine(timeline: any): void;
}

const Playlists = ({ users, loggedUser, viewTimeLine }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState<DocumentDto>();
  const [openModalShare, setOpenModalShare] = useState(false);
  const [playlistData, setPlaylistData] = useState<DocumentDto[]>([]);
  const [timelineSelected, setTimelineSelected] = useState("");

  useEffect(() => {
    if (selectedPlaylist) {
      const updateSelectedPlaylist = stateContent.playlistByProjectSelected.data.find((playlist) => playlist.id === selectedPlaylist?.id);
      if (updateSelectedPlaylist?.id) {
        setSelectedPlaylist(updateSelectedPlaylist);
      }
    }

    setPlaylistData(stateContent.playlistByProjectSelected.data);
  }, [stateContent.playlistByProjectSelected.data]);

  const onClickDelete = (playlist: DocumentDto) => {
    setSelectedPlaylist(playlist);
    setOpenModalDelete(true);
  };

  const onDelete = () => {
    const params = `?organizationId=${stateContent.playlistByProjectSelected.data[0].organizationId}&id=${selectedPlaylist?.id}`;
    dispatch({
      type: ActionContentManagementType.ACTION_DELETE_FILES_LOADING,
      value: { data: params, callBack: () => dispatch({ type: ActionContentManagementType.ACTION_STATE_DELETE_PLAYLIST_BY_ID, value: selectedPlaylist?.id }) },
    });
    setOpenModalDelete(false);
  };

  const onClickInfoPlaylist = (playlist: any) => {
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_LOADING, value: playlist.id });
    setSelectedPlaylist(playlist);
    dispatch({ type: ActionContentManagementType.ACTION_SELECTED_PLAYLIST, value: playlist.id });
    setOpenModalInfo(true);
  };

  const onCloseModalShare = () => {
    setOpenModalShare(false);
    setOpenModalInfo(true);
  };

  const onOpenModalShare = () => {
    setOpenModalInfo(false);
    setOpenModalShare(true);
  };

  const getClips = (playlist: DocumentDto) => {
    dispatch({
      type: ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_LOADING,
      value: playlist.id,
    });
  };

  const getAccordionHeader = (item: any): JSX.Element => {
    return (
      <div className="flex items-center gap-1 p-4 md:p-6 overflow-hidden">
        <Folder />
        <p title={item.fileName} className="text-xs font-bold truncate">
          {item.fileName}
        </p>
      </div>
    );
  };

  const getActionsButtons = (playlist: DocumentDto): JSX.Element => {
    return (
      <div className="flex items-center gap-4 mr-3">
        <LmIcon className="cursor-pointer ml-auto hover:opacity-70 transition" icon={<Info />} size="sm" clickAction={() => onClickInfoPlaylist(playlist)} />
        <LmIcon className="cursor-pointer ml-auto hover:opacity-70 transition" icon={<Trash />} size="sm" clickAction={() => onClickDelete(playlist)} />
      </div>
    );
  };

  const setViewTimeline = (timeline: Timeline) => {
    viewTimeLine(timeline);
    setTimelineSelected(timeline.$id);
  };

  return (
    <div>
      {stateContent.playlistByProjectSelected.loading ? (
        <Loading />
      ) : (
        <>
          <div className="border border-neutral-200 rounded mt-6">
            {playlistData.length ? (
              playlistData.map((playlist) => (
                <Fragment key={playlist.id}>
                  <div className="border-t border-neutral-200 first:border-0">
                    <LmAccordion header={getAccordionHeader(playlist)} actionsButtons={getActionsButtons(playlist)} onOpen={() => getClips(playlist)}>
                      {playlist.clips.length ? (
                        playlist.clips.map((clip: Clip) => (
                          <LmPlaylistItem
                            key={clip.id}
                            playlist={playlist}
                            clip={clip}
                            active={clip.id === timelineSelected}
                            showRemoveIcon={true}
                            viewTimeLine={setViewTimeline}
                          />
                        ))
                      ) : playlist.clipsLoading ? (
                        <Loading />
                      ) : (
                        <div>
                          <Empty text={t("content.emptyCategory")} />
                        </div>
                      )}
                    </LmAccordion>
                  </div>
                </Fragment>
              ))
            ) : (
              <Empty text={t("content.emptyCategory")} />
            )}
          </div>
          <LmModalInfoPlaylist
            handleOpenShareModal={onOpenModalShare}
            playlist={selectedPlaylist}
            open={openModalInfo}
            handleClose={() => setOpenModalInfo(false)}
            users={users}
            loggedUser={loggedUser}
          />
          <LmModalConfirm
            id="test-modal-confirm-remove-playlist"
            title={t("playlist.messageConfirmDeletePlaylistTitle")}
            description={t("playlist.messageConfirmDeletePlaylistDescription")}
            textConfirm={t("modalConfirm.textConfirm")}
            textCancel={t("modalConfirm.textCancel")}
            open={openModalDelete}
            handleClose={() => setOpenModalDelete(false)}
            confirm={onDelete}></LmModalConfirm>
          <LmModalShareFile open={openModalShare} handleClose={onCloseModalShare} />
        </>
      )}
    </div>
  );
};

export default Playlists;
