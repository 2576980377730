import { AlertColor } from "@mui/material/Alert";
import { SizeScreen } from "../SizeSreen";

export enum ActionMainType {
  ALERT_OPEN = "ALERT_OPEN",
  ALERT_CLOSE = "ALERT_CLOSE",

  ACTION_VIEW_MENU = "ACTION_VIEW_MENU",

  ACTION_SIZE_SCREEN = "ACTION_SIZE_SCREEN",

  ACTION_CHANGE_COLOR_APLICATION = "ACTION_CHANGE_COLOR_APLICATION",
}

export interface ActionOpenAlertNotification {
  type: ActionMainType.ALERT_OPEN;
  value: {
    severity: AlertColor;
    message: string;
  };
}

export interface ActionCloseAlertNotification {
  type: ActionMainType.ALERT_CLOSE;
}

export interface ActionViewMenu {
  type: ActionMainType.ACTION_VIEW_MENU;
  value: {
    showTopMenu: boolean;
    showLateralMenu: boolean;
  };
}

export interface ActionChangeColorAplication {
  type: ActionMainType.ACTION_CHANGE_COLOR_APLICATION;
  value: string;
}

export interface ActionSizeScreen {
  type: ActionMainType.ACTION_SIZE_SCREEN;
  value: SizeScreen;
}

export type TypeMainActions = ActionOpenAlertNotification | ActionCloseAlertNotification | ActionViewMenu | ActionChangeColorAplication | ActionSizeScreen;
