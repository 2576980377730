import { Divider, Link, Tab, Tabs } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import TeamsDto from "../../model/dto/TeamDto";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import ModalNewPlayer from "../../components/modal-new-player/ModalNewPlayer";
import LmModalGroup from "../../components/modal-group/LmModalGroup";
import GroupTab from "./GroupTab";
import { ViewMode } from "../../model/ViewMode";
import LmModalStaff from "../../components/modal-staff/LmModalStaff";
import LmIcon from "../../components/icon/LmIcon";
import LmModalTeam from "../../components/modal-team/LmModalTeam";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import PlayerResultDto from "../../model/dto/PlayerResultDto";
import TeamInfoItem from "../../components/team-info-item/TeamInfoItem";
import LmAvatar from "../../components/avatar/LmAvatar";
import StorageBar from "../../components/storage-bar/StorageBar";
import PlayersTab from "./PlayersTab";
import LmModalConfirm from "../../components/modal-confirm/LmModalConfirm";
import Empty from "../../components/empty/empty";
import StaffDto from "../../model/dto/StaffDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getRoleString, getSelectedDataById, isCoachOrHeadCoach } from "../../utils/utils";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { TeamState } from "../../model/states/TeamState";
import Loading from "../../components/loading/Loading";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import LmAvatarLabelInfo from "../../components/avatar-label-info/LmAvatarLabelInfo";
import { PendingInvitationsUserDto } from "../../model/dto/PendingInvitationsUserDto";

interface ITeamSumary {
  team: TeamsDto;
}

const TeamSumary: React.FC<ITeamSumary> = ({ team }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateTeam: TeamState = useSelector((state: MainState) => state.team);
  const selectedOrganization: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const [openModalPlayer, setOpenModalPlayer] = useState(false);
  const [playerSelected, setPlayerSelected] = useState<PlayerResultDto | null>(null);
  const [openModalGroup, setOpenModalGroup] = useState(false);
  const [openModalStaff, setOpenModalStaff] = useState(false);
  const [openModalTeam, setOpenModalTeam] = useState(false);

  const [openModalDeleteTeam, setOpenModalDeleteTeam] = useState(false);
  const [openModalDeleteStaff, setOpenModalDeleteStaff] = useState(false);
  const [teamSelected, setTeamSelected] = useState<TeamsDto>();
  const [staffSelected, setStaffSelected] = useState<StaffDto | null>(null);

  useEffect(() => {
    if (team && team.id) {
      dispatch<TypeActions>({ type: ActionTeamType.ACTION_TEAM_SUMARY_LOADING, value: team.id as string });
    }

  }, [stateAuth.userInfo.selectedTeam, stateAuth.editImageProfile.ok]);

  useEffect(() => {
    if (team && team.id) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_TEAM, value: team.id as string });
    }
  }, [team]);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_LOADING, value: stateAuth.userInfo.selectedOrganization });
  }, []);

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const modalPlayer = (player: PlayerResultDto | null) => {
    setPlayerSelected(player);
    setOpenModalPlayer(true);
  };
  const onOpenModalTeam = () => {
    dispatch<TypeActions>({ type: ActionTeamType.ACTION_SELECT_TEAM, value: team });
    setOpenModalTeam(true);
  };

  const deleteTeam = (team: any) => {
    setOpenModalDeleteTeam(true);
    setTeamSelected(team);
  };

  const onDeleteTeam = () => {
    setOpenModalDeleteTeam(false);
    dispatch<TypeActions>({ type: ActionTeamType.ACTION_DELETE_TEAM_LOADING, value: teamSelected as TeamsDto });
  };

  const editStaff = (staff: StaffDto | null) => {
    setStaffSelected(staff);
    setOpenModalStaff(true);
  };

  const addStaff = () => {
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING, value: stateAuth.userInfo.selectedOrganization });
    setStaffSelected(null);
    setOpenModalStaff(true);
  };

  const deleteStaff = (staff: any) => {
    setOpenModalDeleteStaff(true);
    setStaffSelected(staff);
  };

  const onDeleteStaff = () => {
    setOpenModalDeleteStaff(false);
    dispatch<TypeActions>({
      type: ActionTeamType.ACTION_DELETE_STAFF_LOADING,
      value: { userId: staffSelected?.user?.id as string, teamId: team.id as string },
    });
  };

  const formatRoles = (roles: string[]) => {
    const result = roles.map((role) => getRoleString(role));
    return result.join(", ");
  };  

  const resendPendingInvitation = (id: string) => {
    dispatch({type: ActionAuthType.RESEND_PENDING_INVITATION_LOADING, value: id})
  }

  const maxCapacity = team?.storage ? team?.storage : selectedOrganization.freeStorage;
  const maxNumberOfUsers = (selectedOrganization.users?.length || 0) >= (selectedOrganization.license?.maxNumberOfUsers as number);
  const pendingInvitationByTeam = useMemo(()=>selectedOrganization?.pendingInvitations?.filter(user => user.teams.find(t => t.teamId === team.id) !== undefined), [team, selectedOrganization])
  return (
    <>
      <div className="flex flex-wrap xl:flex-nowrap px-5 lg:px-8">
        <div className="w-full xl:w-1/3">
          <div className="w-full flex justify-between gap-2 py-5 md:py-8">
            <div className="flex items-center gap-4">
              <LmAvatar src={team?.image} size={70} radius="0.5rem" />
              <div>
                <p className="md:font-decorative font-semibold text-decorative text-sm md:text-base">
                  {team?.name}
                </p>
                <p className="text-sm text-subtle">                  
                  {team?.category}
                </p>
              </div>
            </div>
            {isCoachOrHeadCoach(team, stateAuth) && (
              <div className="flex flex-col md:flex-row gap-2">
                <LmIcon
                  className="ml-auto cursor-pointer hover:opacity-80 transition order-1 md:order-none"
                  icon={<Edit />}
                  size="xs"
                  clickAction={() => onOpenModalTeam()}
                />
                <LmIcon className="ml-auto cursor-pointer hover:opacity-80 transition" icon={<Trash />} size="xs" clickAction={() => deleteTeam(team)} />
              </div>
            )}
          </div>
          <div className="space-y-5 border-b xl:border-0 border-neutral-200 pb-5 md:pb-8 mb-5 xl:mb-0">
            <p className="font-decorative text-decorative text-sm">{t("summary.storage")}</p>
            <StorageBar maxCapacity={maxCapacity as number} currentCapacity={team?.consumedStorage as number} labelUsed={t("storage.consumed")}/>
          </div>
          <div className="border-b border-neutral-200 xl:border-0 pb-5">
            <div className="flex justify-between items-center mb-2">
              <p className="font-decorative text-decorative text-sm">
                {t("summary.staff")} ({team?.staff?.length})
              </p>
              {isCoachOrHeadCoach(team, stateAuth) && (
                <Link
                  className={`flex items-center ${maxNumberOfUsers ? "opacity-60 hover:opacity-60" : ""}`}
                  component="button"
                  underline="none"
                  color="inherit"
                  disabled={maxNumberOfUsers}
                  onClick={addStaff}>
                  <p className="flex items-center gap-2 text-sm font-semibold hover:text-primary transition">
                    <Plus />
                    {t("staff.addStaff")}
                  </p>
                </Link>
              )}
            </div>
            {!stateTeam.teamSumary.loading && (
              <>
                {team?.staff && team?.staff?.length > 0 ? (
                  team?.staff?.map((staff: any) => (
                    <div className="py-3 xl:py-5 xl:border-b xl:border-neutral-200 xl:last:border-0" key={staff.userId}>
                      <TeamInfoItem
                        avatarImage={staff?.avatarImage}
                        titleText={
                          <div>
                            {staff?.user?.name} {staff?.user?.surname} · <span className="italic">{formatRoles(staff?.roles)}</span>
                          </div>
                        }
                        subtitleText={staff?.user?.email}
                        actionEdit={isCoachOrHeadCoach(team, stateAuth) ? () => editStaff(staff) : undefined}
                        actionDelete={isCoachOrHeadCoach(team, stateAuth) ? () => deleteStaff(staff) : undefined}
                      />
                    </div>
                  ))
                )            
                 : (
                  <div className="col-span-full row-span-full">
                    <Empty text={t("staff.emptySection")} />
                  </div>
                )}
              </>
            )}
            {stateTeam.teamSumary.loading && <Loading />}
            <p className="font-decorative text-decorative text-sm py-2">{t("summary.invitations")}</p>
            {pendingInvitationByTeam?.length === 0 && <p>{t("users.emptyPendingInvitations")}</p>}
            {pendingInvitationByTeam?.map((user: PendingInvitationsUserDto) => (<div key={user.id} className="flex justify-between">
              <div className="py-2 flex justify-between w-full">
                <LmAvatarLabelInfo
                  key={user.id}
                  src=""
                  radius="100%"
                  label={`${user?.userName}
                  ${user?.userLastName}`}
                  ></LmAvatarLabelInfo>
                  <p className="cursor-pointer italic text-xs" onClick={()=>resendPendingInvitation(user.id)}>
                    {!stateAuth.resendPendingInvitation.loading ? t('users.resendPendingInvitation') : <Loading />}
                  </p>
              </div>                    
              </div>
            ))}
          </div>
        </div>
        <Divider orientation="vertical" flexItem sx={{ margin: "0 1.5rem" }} />
        <div className="flex flex-col w-full">
          <div className="flex justify-between shadow-border">
            <Tabs textColor="inherit" indicatorColor="primary" value={activeTab} onChange={onChangeTab}>
              <Tab
                sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }}
                label={`${t("summary.playersTab")} (${team?.players?.length})`}
              />
              <Tab
                sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }}
                label={`${t("summary.groupsTab")} (${team?.groups?.length})`}
              />
            </Tabs>
            {activeTab === 0 && isCoachOrHeadCoach(team, stateAuth) ? (
              <Link className="flex items-center" component="button" underline="none" color="inherit" onClick={() => modalPlayer(null)}>
                <p className="flex items-center gap-2 text-sm font-semibold hover:text-primary transition">
                  <Plus />
                  {t("player.addPlayer")}
                </p>
              </Link>
            ) : (
              isCoachOrHeadCoach(team, stateAuth) && (
                <Link className="flex items-center" component="button" underline="none" color="inherit" onClick={() => setOpenModalGroup(true)}>
                  <p className="flex items-center gap-2 text-sm font-semibold hover:text-primary transition">
                    <Plus />
                    {t("group.addGroup")}
                  </p>
                </Link>
              )
            )}
          </div>
          <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 md:gap-5 py-5">
            {activeTab === 0 && <>{!stateTeam.teamSumary.loading ? <PlayersTab players={team?.players} team={team} /> : <Loading />} </>}
            {activeTab === 1 && <>{!stateTeam.teamSumary.loading ? <GroupTab groups={team?.groups} team={team} /> : <Loading />} </>}
          </div>
        </div>
      </div>
      <ModalNewPlayer open={openModalPlayer} handleClose={() => setOpenModalPlayer(false)} player={playerSelected} team={team} />
      <LmModalGroup open={openModalGroup} handleClose={() => setOpenModalGroup(false)} viewMode={ViewMode.CREATE} />
      <LmModalStaff open={openModalStaff} handleClose={() => setOpenModalStaff(false)} staff={staffSelected} team={team} />
      <LmModalTeam open={openModalTeam} handleClose={() => setOpenModalTeam(false)} viewMode={ViewMode.EDIT} />
      <LmModalConfirm
        id="test-modal-confirm-remove-team"
        title={t("teams.deleteTeamTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalDeleteTeam}
        handleClose={() => setOpenModalDeleteTeam(false)}
        confirm={onDeleteTeam}></LmModalConfirm>

      <LmModalConfirm
        id="test-modal-confirm-remove-team"
        title={t("teams.deleteStaffTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalDeleteStaff}
        handleClose={() => setOpenModalDeleteStaff(false)}
        confirm={onDeleteStaff}></LmModalConfirm>
    </>
  );
};

export default TeamSumary;
