import React from "react";
import LmCheckBox from "../check-box/LmCheckBox";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import LmIcon from "../icon/LmIcon";
import { ProjectDto, TeamTemplate, Timeline } from "../../model/dto/ProjectDto";
import { convertMilisecondsToHoursMinutesSeconds, getBase64Img, getProjectDataPlayer, getProjectResultTag, getColorByEvent } from "../../utils/utils";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import { t } from "i18next";

interface IAnalysisItemByTeam {
  selected: boolean;
  timeline: Timeline;
  team: TeamTemplate;
  isStaffByTeam: boolean;
  active: boolean;
  checkTimeline(select: boolean, timeline: Timeline): void;
  selectTimeline(timeline: Timeline): void;
  viewTimeLine(timeline: Timeline): void;
}

const AnalysisItemByTeam: React.FC<IAnalysisItemByTeam> = ({ selected, timeline, active,  team, isStaffByTeam, checkTimeline, selectTimeline, viewTimeLine }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);

  return (
    <div className={`flex items-center gap-2 border-b border-neutral-200 p-4 ${active ? 'bg-[#45e9ad1a]' : ''}`} >
      <div className="flex flex-wrap items-center gap-2">
        {isStaffByTeam && <LmCheckBox id={"timeline-" + timeline.$id} value={selected} changeValue={(e) => checkTimeline(e.currentTarget.checked, timeline)}></LmCheckBox>}
        <div className="flex items-center gap-2">
          <p className="text-xs text-decorative font-semibold">{getProjectResultTag(timeline.Tags)}</p>
          {getProjectResultTag(timeline.Tags) && <div className="h-2 w-2 rounded-sm" style={{ background: getColorByEvent(stateContent.project.data as ProjectDto, timeline) }}></div>}
        </div>
        <div className="flex flex-wrap items-center gap-2" >
          <p className="text-xs text-subtle cursor-pointer" onClick={() => viewTimeLine(timeline)}>
            {convertMilisecondsToHoursMinutesSeconds(timeline.Start)} - {convertMilisecondsToHoursMinutesSeconds(timeline.Stop)}
          </p>
          <div className="flex items-center gap-2">
            {timeline.Players.map((player) => (
              <figure key={player.$ref} className="h-8 w-8 rounded-full border border-neutral-200 overflow-hidden">
                <img className="object-cover min-h-full" src={getBase64Img(getProjectDataPlayer(player.$ref, stateContent.project.data as ProjectDto)?.Photo ?? "")}></img>
              </figure>
            ))}
            <figure className="h-8 w-8 rounded-full border border-neutral-200 flex justify-center items-center overflow-hidden">
              <img className="aspect-auto max-h-full" src={getBase64Img(team?.Shield)}></img>
            </figure>
          </div>
        </div>
      </div>
      <LmIcon icon={<Eye />} size="xs" className="ml-auto cursor-pointer hover:opacity-70 transition" tooltip={t('content.viewDetail') as string} clickAction={() => selectTimeline(timeline)} />
    </div>
  );
};

export default AnalysisItemByTeam;
