import { t } from "i18next";
import { useEffect, useState } from "react";
import { ProjectCategoryDto, ProjectDto, Ref, TeamPlayer, Timeline } from "../../model/dto/ProjectDto";
import { IdValue } from "../../model/forms/IdValue";
import { getBase64Img } from "../../utils/utils";
import LmSelect from "../select/LmSelect";
import MatchStatisticCategoryViewer from "./MatchStatisticCategoryViewer";
import MatchStatisticGraph from "./MatchStatisticGraph";
import MatchStatisticTeamSummary from "./MatchStatisticTeamSummary";

interface Props {
  statistic: ProjectDto;
  eventsCategory: ProjectCategoryDto[];
  categoryNavigation: (categoryName: string) => void;
}

const MatchStatisticTeamMobile = ({ statistic, eventsCategory, categoryNavigation }: Props) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  const [teams, setTeams] = useState<IdValue[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<IdValue>();
  const [selectedPlayer, setSelectedPlayer] = useState<IdValue | null>();
  const [selectedCategory, setSelectedCategory] = useState<IdValue | null>();
  const [players, setPlayers] = useState<IdValue[]>([]);
  const [categories, setCategories] = useState<IdValue[]>([]);
  const [category, setCategory] = useState<ProjectCategoryDto>();

  useEffect(() => {
    if (statistic) {
      const teams = [
        {
          id: statistic.LocalTeamTemplate.$id,
          value: statistic.LocalTeamTemplate.Name,
          image: getBase64Img(statistic.LocalTeamTemplate.Shield),
          type: "local",
        },
        {
          id: statistic.VisitorTeamTemplate.$id,
          value: statistic.VisitorTeamTemplate.Name,
          image: getBase64Img(statistic.VisitorTeamTemplate.Shield),
          type: "visitor",
        },
      ];
      setTeams(teams);
    }
  }, []);

  const onTeamSelect = (team: IdValue) => {
    setSelectedTeam(team);
    setSelectedPlayer(null);
    setSelectedCategory(null);
    const categories = eventsCategory.filter(
      (event) => event.name && event.timelines.some((timeline) => timeline.Teams.length && timeline.Teams.map((t) => t.$ref).includes(team.id))
    );

    const selectCategories = categories.map((category: ProjectCategoryDto) => ({
      id: category.event,
      value: category.name,
      icon: <div style={{ width: "12px", height: "12px", backgroundColor: category.color }} />,
    }));
    setCategories(selectCategories);

    const timelines = categories.map((category: ProjectCategoryDto) => category.timelines).flat();
    const timelinesPlayers = timelines.map((t: Timeline) => t.Players).flat();
    const playerRefs = timelinesPlayers.map((player: Ref) => player.$ref);

    const template: "LocalTeamTemplate" | "VisitorTeamTemplate" = team.type === "local" ? "LocalTeamTemplate" : "VisitorTeamTemplate";
    const players = statistic[template].List.filter((player: TeamPlayer) => playerRefs.includes(player.$id));
    const selectPlayers: IdValue[] = players.map((player: TeamPlayer) => ({
      id: player.$id,
      value: t("player.viewTitle", { name: player.NickName, number: player.Number }) as string,
      image: getBase64Img(player.Photo),
    }));

    setPlayers(selectPlayers);
  };

  const onPlayerSelect = (player: IdValue) => {
    setSelectedPlayer(player);
    setSelectedCategory(null);
    const categories = eventsCategory.filter(
      (event) => event.name && event.timelines.some((timeline) => timeline.Players.length && timeline.Players.map((p) => p.$ref).includes(player.id))
    );

    const selectCategories = categories.map((category: ProjectCategoryDto) => ({
      id: category.event,
      value: category.name,
      icon: <div style={{ width: "12px", height: "12px", backgroundColor: category.color }} />,
    }));
    setCategories(selectCategories);
  };

  const onCategorySelect = (category: IdValue) => {
    setSelectedCategory(category);
    const fullCategory = eventsCategory.find((c) => c.event === category.id);
    setCategory(fullCategory);
  };

  return (
    <div>
      <div className="my-4 flex flex-col md:flex-row gap-4">
        <LmSelect
          label={t("content.team")}
          required
          options={teams}
          selectedValue={(team: IdValue) => onTeamSelect(team)}
          value={selectedTeam}
          prefix={
            selectedTeam?.image ? (
              <figure className="h-6 w-6 flex justify-center items-center rounded-full overflow-hidden border border-neutral-200">
                <img className="aspect-auto max-h-full" src={selectedTeam.image}></img>
              </figure>
            ) : (
              <></>
            )
          }
        />
        <LmSelect
          label={t("content.playersSelector")}
          required
          options={players}
          selectedValue={(player: IdValue) => onPlayerSelect(player)}
          value={selectedPlayer}
          prefix={
            selectedPlayer?.image ? (
              <figure className="h-6 w-6 rounded-full overflow-hidden">
                <img className="object-cover min-h-full" src={selectedPlayer.image}></img>
              </figure>
            ) : (
              <></>
            )
          }
        />
        <LmSelect
          label={t("content.category")}
          required
          options={categories}
          selectedValue={(category: IdValue) => onCategorySelect(category)}
          value={selectedCategory}
          prefix={selectedCategory?.icon}
        />
      </div>

      {category && (
        <div className="col-span-3">
          <MatchStatisticTeamSummary elapsedTime={elapsedTime} points={0} admonition={0}></MatchStatisticTeamSummary>
          {(selectedTeam || selectedPlayer) && (
            <MatchStatisticCategoryViewer
              category={category}
              dashboard={statistic.Dashboard}
              teamId={selectedTeam?.id as string}
              playerId={selectedPlayer?.id as string}
              statistic={statistic}></MatchStatisticCategoryViewer>
          )}
          <MatchStatisticGraph category={category} categoryNavigation={categoryNavigation}></MatchStatisticGraph>
        </div>
      )}
    </div>
  );
};

export default MatchStatisticTeamMobile;
