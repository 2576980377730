import { Dialog } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import LmAvatarLabelInfo from "../avatar-label-info/LmAvatarLabelInfo";
import { useSelector, useDispatch } from "react-redux";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getSelectedDataById } from "../../utils/utils";
import { PendingInvitationsUserDto } from "../../model/dto/PendingInvitationsUserDto";
import Loading from "../loading/Loading";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { InitialState } from "../../model/states/InitialState";

interface Props {
  open: boolean;
  handleClose(): void;
}
const LmModalPendingInvitations = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const dispatch = useDispatch()
  const pendingInvitationLoading: boolean = useSelector((state: MainState) => state.organization.organizationUsers.pendingInvitations.loading);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  const onClose = () => {
    handleClose();
  };

  const getPendingInvitationInfo = (pendingInvitation: PendingInvitationsUserDto): string => {
    const result = pendingInvitation.teams.map((team) => team?.team?.name || "").join(", ");
    return result;
  };

  const resendPendingInvitation = (id: string) => {
    dispatch({type: ActionAuthType.RESEND_PENDING_INVITATION_LOADING, value: id})
  }


  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8">
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl">{t("users.pendingInvitationsTitle")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-pendig-invitations" />
        </div>
        {pendingInvitationLoading ? (
          <Loading />
        ) : (
          <div className="space-y-3">
            {selectedOrganizationData?.pendingInvitations && selectedOrganizationData?.pendingInvitations?.length > 0 ? (
              selectedOrganizationData?.pendingInvitations.map((user: PendingInvitationsUserDto) => (<div key={user.id} className="flex justify-between">
                <LmAvatarLabelInfo                  
                  src={user.avatarImage}
                  radius="100%"
                  label={`${user?.userName}
                  ${user?.userLastName}`}
                  divider="·"
                  info={getPendingInvitationInfo(user)}></LmAvatarLabelInfo>
                  <p className="cursor-pointer italic text-xs" onClick={()=>resendPendingInvitation(user.id)}>
                    {!stateAuth.resendPendingInvitation.loading ? t('users.resendPendingInvitation') : <Loading />}
                  </p>
              </div>))
            ) : (
              <p>{t("users.emptyPendingInvitations")}</p>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default LmModalPendingInvitations;
