import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TypeActions } from "../../model/actions/typeActions";
import { ReactComponent as LoginLogo } from "../../assets/img/logo-longomatch-white.svg";
import { LmRegisterForm } from "../../components/login-forms/LmRegisterForm";
import "./Login.scss";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { ROUTE_LICENSE_SELECTOR, ROUTE_PRIVATE_DASHBOARD, ROUTE_PRIVATE_NEW_ORGANIZATION } from "../../routes/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActionAuthType } from "../../model/actions/typeAuthActions";

function Register() {
  const state: AuthState = useSelector((state: MainState) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: false, showLateralMenu: false } });
  }, []);

  const checkUserInfoData = () => {
    const { freeLicenses, organizations } = state.userInfo?.data || {};

    if (freeLicenses?.length === 1 && organizations?.length === 0) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_LICENSE, value: freeLicenses[0].id });
      return ROUTE_PRIVATE_NEW_ORGANIZATION;
    } else if (freeLicenses?.length === 0 && organizations?.length === 1) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_ORGANIZATION, value: organizations[0] });
      dispatch<TypeActions>({ type: ActionMainType.ACTION_CHANGE_COLOR_APLICATION, value: organizations[0].color });
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHANGE_USER_ORGANIZATION_ROLE, value: organizations[0] });
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_DASHBOARD });
      return ROUTE_PRIVATE_DASHBOARD;
    } else {
      return ROUTE_LICENSE_SELECTOR;
    }
  };

  useEffect(() => {
    // if there is a paramCode in the url the user is changing the password, disregard the storage data.
    const paramCode = searchParams.get("code");
    if (state.auth && state.userInfo?.data && !paramCode) {
      navigate("/" + checkUserInfoData());
    }
  }, [state.auth, state.userInfo?.data]);

  return (
    <div className="login full-height flex flex-col justify-center items-center p-6" data-testid="register">
      <LoginLogo className="md:w-72 w-48 md:mb-24 mb-16" />
      <p className="font-decorative text-white text-center text-base md:text-xl lg:text-2xl mb-12">{t("login.title")}</p>
      <LmRegisterForm />
    </div>
  );
}

export default Register;
