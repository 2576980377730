import React from "react";
import LmAvatar from "../avatar/LmAvatar";
import LmIcon from "../icon/LmIcon";

interface IAvatarLabelInfo {
  src: any;
  size?: number;
  radius?: string;
  alt?: string;
  label: string;
  info?: string;
  infoMargin?: string;
  divider?: string;
  icon?: any;
  id?: string;
  onSelect?(id: string): void;
  onClickAction?(id: string): void;
}

const LmAvatarLabelInfo: React.FC<IAvatarLabelInfo> = ({
  src,
  size = 30,
  radius = "4px",
  label,
  info,
  infoMargin = "",
  divider,
  alt = "",
  icon = null,
  id = "",
  onSelect = () => {},
  onClickAction = () => {},
}) => {
  return (
    <div data-testid={id} className="flex items-center gap-2" onClick={() => onSelect(id)}>
      {src && <LmAvatar radius={radius} size={size} src={src} alt={alt} />}
      <p className="text-sm md:flex md:gap-1">
        <span className="font-semibold text-decorative">{label}</span>

        {info && (
          <>
            {divider && <span className="hidden md:inline text-subtle">{divider}</span>}
            <span className={`block md:inline-flex text-subtle ${infoMargin}`}>{info}</span>
          </>
        )}
      </p>
      {icon && <LmIcon className="ml-auto cursor-pointer hover:opacity-80 transition" icon={icon} size="sm" clickAction={() => onClickAction(id)} />}
    </div>
  );
};

export default LmAvatarLabelInfo;
