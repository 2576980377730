import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import LmButton from "../button/LmButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LmSelect from "../select/LmSelect";
import { IdValue } from "../../model/forms/IdValue";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { OrganizationState } from "../../model/states/OrganizationState";
import { useNavigate } from "react-router-dom";
import { ROUTE_LICENSE_SELECTOR } from "../../routes/routes";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import { anyOwner, isOwner } from "../../utils/utils";
import { TypeActions } from "../../model/actions/typeActions";
import { URL_DO_NOT_RENEW_SUBSCRIPTION } from "../../constants/globalConstanst";
import { InitialState } from "../../model/states/InitialState";

interface IModalEditPassword {
  open: boolean;
  handleClose(): void;
}

const ModalUnsubscribeOrganization: React.FC<IModalEditPassword> = ({ open, handleClose }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const options = (): IdValue[] => {
    const opt: IdValue[] = [];
    if (!isOwner(stateAuth) && stateAuth.userInfo.data.organizations.length > 1) {
      opt.push({ id: "1", value: t("editUser.unsubscribeOption1") });
    } else if (isOwner(stateAuth)) {
      opt.push({ id: "2", value: t("editUser.unsubscribeOption2") });
    }
    if (!anyOwner(stateAuth)) {
      opt.push({ id: "3", value: t("editUser.unsubscribeOption3") });
    }

    return opt;
  };

  const [optionSelected, setOptionSelected] = useState<IdValue>();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setOptionSelected(options()[0]);
  }, [stateAuth.appLanguage]);

  useEffect(() => {
    if (stateOrganization.exit.ok) {
      handleClose();
      navigate("/" + ROUTE_LICENSE_SELECTOR);
      dispatch({ type: ActionOrganizationType.ACTION_RESET_ORGANIZATION_EXIT });
    }
  }, [stateOrganization.exit]);

  useEffect(() => {
    if (stateAuth.desactivateUser?.ok) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_LOGOUT });
    }
  }, [stateAuth.desactivateUser]);

  useEffect(() => {
    setOptionSelected(options()[0]);
  }, [stateAuth.userInfo.selectedOrganization]);

  const unsubscribe = () => {
    setOpenModalConfirm(false);
    switch (optionSelected?.id) {
      case "1": {
        dispatch({ type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_LOADING, value: stateAuth.userInfo.selectedOrganization });
        break;
      }
      case "2": {
        window.open(URL_DO_NOT_RENEW_SUBSCRIPTION, "_blank");
        break;
      }
      case "3": {
        dispatch({ type: ActionAuthType.ACTION_USER_DESACTIVATE_LOADING });
        break;
      }
    }
  };

  const selectedOption = (option: IdValue<string, string>) => {
    setOptionSelected(option);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
        <div className="dialog p-5 md:p-8" data-testid="modal-unsusbcribe-organization">
          <div className="flex justify-between">
            <p className="font-decorative text-decorative text-base md:text-xl mb-5 md:mb-6">{t("editUser.unsubscribeTitle")}</p>
          </div>
          <div>
            <p className="text-sm mb-5 md:mb-6">{t("editUser.unsubscribeSelect")}</p>
            <div className="mb-5 md:mb-6">
              <LmSelect readonly={true} selectedValue={selectedOption} value={optionSelected} options={options()} />
            </div>
            <div className="flex gap-2 justify-end flex-wrap">
              <LmButton text={t("editUser.buttonCancel")} buttonType="button" type="secondary" clickAction={handleClose}></LmButton>
              <LmButton text={t("editUser.buttonSave")} type="organization" buttonType="button" clickAction={() => setOpenModalConfirm(true)}></LmButton>
            </div>
          </div>
        </div>
      </Dialog>
      <LmModalConfirm
        id="test-modal-confirm-logout"
        title={t("editUser.unsubscribeOption" + optionSelected?.id)}
        description={t("modalConfirm.descriptionLogout")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalConfirm}
        handleClose={() => setOpenModalConfirm(false)}
        confirm={unsubscribe}></LmModalConfirm>
    </>
  );
};

export default ModalUnsubscribeOrganization;
