import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Asterisk } from "../../assets/icons/asterisk.svg";
import LmIcon from "../icon/LmIcon";
import "./LmLabel.scss";

interface Button {
  id?: string;
  value?: string | null;
  required?: boolean;
  isTranslateKey?: boolean;
}

const LmLabel: React.FC<Button> = ({ id = "", value = "", required = false, isTranslateKey = false }) => {
  const { t } = useTranslation();
  return (
    <label htmlFor={id} className="lm-label flex items-start">
      {/* {isTranslateKey ? <span>{t(value)}</span> : <span>{value}</span>}  */}
      <span>{value}</span>
      {required && <LmIcon icon={<Asterisk />} size="xs" />}
    </label>
  );
};

export default LmLabel;
