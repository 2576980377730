import React from "react";
import { useSelector } from "react-redux";
import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";

interface IStatsComparisonItem {
  statName: string;
  numberA: number;
  percentA: number;
  numberB: number;
  percentB: number;
}

const StatsComparisonItem: React.FC<IStatsComparisonItem> = ({ statName = "", numberA = 0, percentA = 0, numberB = 0, percentB = 0 }) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  return (
    <div>
      {stateMain.sizeScreen === SizeScreen.MOBILE ? (
        <div className="grid grid-cols-3 items-center gap-4">
          <div>
            <p className="text-sm font-semibold truncate" title={statName}>
              {statName}
            </p>
          </div>
          <div className="w-full h-2 bg-neutral-200 rounded overflow-hidden flex">
            <div className="h-full bg-red-500" style={{ width: `${percentA}%` }}></div>
            <div className="h-full bg-green-500" style={{ width: `${percentB}%` }}></div>
          </div>
          <div className="grid grid-cols-2 items-center gap-4">
            <p className="text-xs text-neutral-400 text-center">{percentA}</p>
            <p className="text-xs text-neutral-400 text-center">{percentB}</p>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-4 justify-center">
          <div className="flex items-center justify-end gap-4 w-2/12 xl:w-1/12">
            <p className="text-xs text-neutral-400">{percentA}%</p>
            <p className="text-sm text-subtle font-semibold">{numberA}</p>
          </div>

          <div className="grid grid-cols-3 items-center gap-4 w-2/3 xl:w-10/12">
            <div className="w-full h-2 bg-red-500 rounded overflow-hidden">
              <div className="h-full bg-neutral-200" style={{ width: `${100 - percentA}%` }}></div>
            </div>
            <div>
              <p className="text-sm font-semibold text-center truncate" title={statName}>
                {statName}
              </p>
            </div>
            <div className="flex justify-end w-full h-2 bg-green-500 rounded overflow-hidden">
              <div className="h-full bg-neutral-200" style={{ width: `${100 - percentB}%` }}></div>
            </div>
          </div>

          <div className="flex items-center gap-4 w-2/12 xl:w-1/12">
            <p className="text-sm text-subtle font-semibold">{numberB}</p>
            <p className="text-xs text-neutral-400">{percentB}%</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsComparisonItem;
