import { t } from "i18next";
import Avatar40 from "../../assets/img/Avatar40px.svg";
import { TemplatePlayerDto } from "../../model/dto/TemplatePlayerDto";
import { getBase64Img } from "../../utils/utils";

interface Props {
  player: TemplatePlayerDto;
  playerClick: (player: TemplatePlayerDto) => void;
}

const PlayerListItem = ({ player, playerClick }: Props) => {
  return (
    <div className="flex flex-col md:flex-row items-center gap-3 cursor-pointer" onClick={() => playerClick(player)}>
      <figure className="h-10 w-10 rounded-lg overflow-hidden">
        <img className="object-cover min-h-full" src={player.Photo ? getBase64Img(player.Photo) : Avatar40}></img>
      </figure>
      <div className="overflow-hidden text-center md:text-left">
        <p className="font-semibold truncate" title={player.NickName}>
          {player.NickName}
        </p>
        <p>{t("content.playerNumber", { value: player.Number })}</p>
      </div>
    </div>
  );
};

export default PlayerListItem;
