import { IdValue } from "../../model/forms/IdValue";
import OrganizationSelector from "./OrganizationSelector";
import UserAvatar from "./UserAvatar";
import "./TopMenu.scss";

import useOrganizationSelector from "../../hooks/useOrganizationSelector";
import { useSelector } from "react-redux";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";

function TopMenu() {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const [organizationOptions, organizationSelected, onChangeOrganization] = useOrganizationSelector();
  const hasOneOrganization = stateAuth.userInfo.data.organizations.length === 1;

  return (
    <div className="lm-top-menu flex justify-end items-center w-full gap-6 px-12 py-4 " data-testid="top-menu-desktop">
      {!hasOneOrganization && (
        <OrganizationSelector
          selected={organizationSelected as IdValue}
          options={organizationOptions as IdValue[]}
          onSelected={onChangeOrganization as (organization: IdValue<string, string>) => void}
        />
      )}
      <div className="flex flex-col self-end cursor-pointer">
        <UserAvatar />
      </div>
    </div>
  );
}

export default TopMenu;
