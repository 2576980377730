import { Dialog } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { IdValue } from "../../model/forms/IdValue";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import LmTags from "../autocomplete/LmTags";
import LmButton from "../button/LmButton";
import LmMessageError from "../message-error/LmMessageError";

/* const ModalAddTag = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    overflow: "visible",
  },
})); */

interface Props {
  open: boolean;
  handleClose(): void;
}
const LmModalAddTag = ({ open, handleClose }: Props) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const dispatch = useDispatch();
  const [tags, setTags] = useState<IdValue[]>([]);
  const { t } = useTranslation();

  const tagsOptions = stateContent.tags.data
    .filter((tag) => tag.type === "Public")
    .map((tag) => {
      return { id: tag.id, value: tag.name, type: tag.type };
    });
  const formik = useFormik({
    initialValues: { tags: "" },
    validationSchema: Yup.object({ tags: Yup.array().required(t("errors.form.required") as string) }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const resetForm = () => {
    formik.resetForm();
    setTags([]);
  };

  useEffect(() => {
    if (stateContent.addTagToFile.ok) {
      resetForm();
      handleClose();
      dispatch({ type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_RESET });
    }
  }, [stateContent.addTagToFile]);

  const onClose = () => {
    resetForm();
    handleClose();
  };

  const selectTag = (option: IdValue) => {
    const options: IdValue[] = [...tags, option];

    setTags(options);
    formik.setFieldValue("tags", options);
  };

  const deleteTag = (option: IdValue) => {
    let newOptions;
    if (option.id) {
      newOptions = tags.filter((tag: IdValue) => tag.id !== option.id);
    } else {
      newOptions = tags.filter((tag: IdValue) => tag.value !== option.value);
    }
    setTags(newOptions);
    formik.setFieldValue("tags", newOptions);
  };

  const onSubmit = async (formValue: any) => {
    dispatch({ type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_LOADING, value: { files: stateContent.filesSelected, tags: formValue.tags } });
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8">
        <div className="flex justify-between items-center mb-8">
          <div>
            <p className="font-decorative text-decorative text-base md:text-2xl"> {t("content.modalChangeAddTagTitle")}</p>
            <p>{t("content.modalChangeAddTagSubTitle")}</p>
          </div>

          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-change-category" />
        </div>
        <p className="text-sm font-semibold mb-1">{t("formLabel.tags")}</p>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-upload-file-form">
          <div>
            <LmTags
              id="tags"
              value={tags}
              privateTags={false}
              options={tagsOptions as IdValue[]}
              selectedValue={(option: IdValue) => selectTag(option)}
              deleteValue={(option: IdValue) => deleteTag(option)}
            />
            {formik.touched.tags && formik.errors.tags && <LmMessageError id="tags" message={formik.errors.tags}></LmMessageError>}
          </div>
          <div className="flex flex-wrap justify-end gap-4 mt-8">
            <LmButton
              styleButton="secondary"
              type="solid"
              clickAction={onClose}
              size="small"
              text={`${t("buttonLabel.cancel")}`}
              iconPosition="right"></LmButton>
            <LmButton
              styleButton="organization"
              buttonType="submit"
              type="solid"
              size="small"
              isDisabled={stateContent.changeFileCategory.loading}
              text={t("buttonLabel.save")}
              iconPosition="right"></LmButton>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default LmModalAddTag;
