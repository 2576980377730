import { t } from "i18next";
import { SizeScreen } from "../../model/SizeSreen";
import { ProjectDto, Timeline } from "../../model/dto/ProjectDto";
import { formatLocaleDate, getBase64Img, getEventProject, getEventScoreProject, getLocaleByUserOrganization, getSelectedDataById, getTypeByEvent, isPlayerLocal, isTeamLocal } from "../../utils/utils";
import LmIcon from "../icon/LmIcon";

import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { AuthState } from "../../model/states/AuthState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { useEffect, useMemo, useState } from "react";
import { ContentManagementState } from "../../model/states/ContentManagementState";

interface Props {
    statistic: ProjectDto;
    sizeScreen: SizeScreen;
    timelines: Timeline[];
    period: string;
    goBackClick: () => void;
}
const MatchStatisticSummary = ({ statistic, sizeScreen, timelines, period, goBackClick }: Props) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const dateLocale = useMemo(()=>getLocaleByUserOrganization(stateAuth.userInfo.data.user.id, selectedOrganizationData?.users) === 'en' ? 'en-US' : 'es-ES', [stateAuth.userInfo.selectedOrganization])
  const [localPoints, setLocalPoints] = useState(0);
  const [visitorPoints, setVisitorPoints] = useState(0);

  useEffect(()=>{
    const timelinesPeriod = timelines.filter(timeline => getTypeByEvent(stateContent.project.data as ProjectDto, timeline).includes('ScoreEventType'));
    const timelinesLocal: Timeline[] = [];
    const timelinesVisitor: Timeline[] = [];

    if (period !== "All") {
      timelinesPeriod.forEach(timeline => {
        let local = false;
        if (timeline.Teams && timeline.Teams.length > 0) {
          local = isTeamLocal(timeline.Teams[0].$ref, stateContent.project.data as ProjectDto)
        } else if (timeline.Players && timeline.Players.length > 0) {
          local = isPlayerLocal(timeline.Players[0].$ref, stateContent.project.data as ProjectDto)
        }
  
        if (local) {
          timelinesLocal.push(timeline);
        } else {
          timelinesVisitor.push(timeline)
        }
      })
      const pointsLocal = timelinesLocal.reduce((accumulator, currentValue) => {
        const points = getEventScoreProject(stateContent.project.data as ProjectDto, currentValue)?.Points ?? 0
        return accumulator + points}
      , 0);

      setLocalPoints(pointsLocal)
  
      const pointsVisitor = timelinesVisitor.reduce((accumulator, currentValue) => {
        const points = getEventScoreProject(stateContent.project.data as ProjectDto, currentValue)?.Points ?? 0
        return accumulator + points}
      , 0)
      setVisitorPoints(pointsVisitor)
    } else {
      setLocalPoints(statistic.Description.LocalGoals)
      setVisitorPoints(statistic.Description.VisitorGoals)
    }
    
    

  },[timelines, period])
    return (
        <>
            <div className="flex items-center gap-2 mb-5">
              <LmIcon className="cursor-pointer" icon={<ChevronLeft />} size="sm" clickAction={() => goBackClick()} />
              <span className="text-decorative font-decorative">{t("content.detailMatchStatisticTitle")}</span>
            </div>

            <div className="flex items-center gap-4 w-full bg-neutral-50 py-3 px-5">
              <div>
                <p className="text-xs sm:text-sm text-subtle">{formatLocaleDate(statistic.Description.MatchDate, dateLocale)}</p>
                <p className="text-xs sm:text-sm font-semibold">{statistic.Description.Competition}-{statistic.Description.Season}</p>
              </div>

              <div className="flex grow shrink-0 justify-center items-center gap-5 md:gap-8">
                <div className="flex gap-4 items-center">
                  <figure className="h-10 w-10 flex justify-center items-center">
                    <img className="aspect-auto max-h-full" src={getBase64Img(statistic.Description.LocalShield)}></img>
                  </figure>
                  {sizeScreen === SizeScreen.DESKTOP && <span className="font-semibold">{statistic.Description.LocalName}</span>}
                </div>
                <div className="text-xl font-extrabold">
                    <span>{localPoints}</span>    
                    <span>-</span>    
                    <span>{visitorPoints}</span>                       
                </div>
                <div className="flex gap-4 items-center">
                  <figure className="h-10 w-10 flex justify-center items-center">
                    <img className="aspect-auto max-h-full" src={getBase64Img(statistic.Description.VisitorShield)}></img>
                  </figure>
                  {sizeScreen === SizeScreen.DESKTOP && <span className="font-semibold">{statistic.Description.VisitorName}</span>}
                </div>
              </div>
            </div>
        </>
    )
}

export default MatchStatisticSummary;
