import  {  useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";
import { MainState } from "../../model/states/MainState";
import MainMenu from "../../components/main-menu/MainMenu";
import TopMenu from "../top-menu/TopMenu";
import { InitialState } from "../../model/states/InitialState";
import { SizeScreen } from "../../model/SizeSreen";
import MainMenuMobile from "../main-menu/MainMenuMobile";
import TopMenuMobile from "../top-menu/TopMenuMobile";
import ModalEditUser from "../modal-edit-user/ModalEditUser";
import ModalEditPassword from "../modal-edit-user/ModalEditPassword";
import ModalUnsubscribeOrganization from "../modal-edit-user/ModalUnsubscribeOrganization";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import { useTranslation } from "react-i18next";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { ROUTE_LOGIN } from "../../routes/routes";
import { LinearProgress } from "@mui/material";
import { ContentManagementState } from "../../model/states/ContentManagementState";

function Layout() {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);

  const [viewMobileMenu, setViewMobileMenu] = useState(false);
  const [openModalEditProfile, setOpenModalEditProfile] = useState(false);
  const [openModalEditPassword, setOpenModalEditPassword] = useState(false);
  const [openUnsubscribeModal, setOpenUnsubscribeModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const unsubscribe = () => {
    setOpenModalEditProfile(false);
    setOpenUnsubscribeModal(true);
  };

  const openEditProfile = () => {
    setViewMobileMenu(!viewMobileMenu);
    setOpenModalEditProfile(true);
  };

  const openChangePassword = () => {
    setViewMobileMenu(!viewMobileMenu);
    setOpenModalEditPassword(true);
  };

  const openConfirm = () => {
    setViewMobileMenu(!viewMobileMenu);
    setOpenModalConfirm(true);
  };

  const logout = () => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_LOGOUT });
    navigate(ROUTE_LOGIN);
  };
  const fileProgress = useMemo(()=>stateContent.uploadDocument.data?.progress ?? 0, [stateContent.uploadDocument.data?.progress]);
  const fileSize = useMemo(()=>stateContent.uploadDocument.data?.size ?? 1, [stateContent.uploadDocument.data?.size])
  return (
    <>
      <div className={`flex theme-${stateMain.colorAplication}`} data-testid="test-layout">
        <div>{stateMain.viewMenu.showLateralMenu && stateMain.sizeScreen === SizeScreen.DESKTOP && <MainMenu />}</div>
        <div>
          {stateMain.sizeScreen !== SizeScreen.DESKTOP && viewMobileMenu && (
            <MainMenuMobile
              toggleMenu={() => setViewMobileMenu(!viewMobileMenu)}
              openEditProfile={openEditProfile}
              openChangePassword={openChangePassword}
              openModalConfirm={openConfirm}
            />
          )}
        </div>
        <div className="w-full">
          {stateMain.viewMenu.showTopMenu && stateMain.sizeScreen === SizeScreen.DESKTOP && <TopMenu />}
          {stateMain.viewMenu.showTopMenu && stateMain.sizeScreen !== SizeScreen.DESKTOP && (
            <TopMenuMobile toggleMenu={() => setViewMobileMenu(!viewMobileMenu)} />
          )}
          <div className="lm-route-container">
            <Outlet />
            {stateContent.uploadDocument.loading && <div className="mx-16 my-8">
              <LinearProgress variant="determinate" value={fileProgress * 100 / fileSize} />
              <p className="my-2 flex gap-2 justify-center">
                <span className="font-bold">{t('content.uploading')}</span>
                <span className="italic">{stateContent.uploadDocument.data?.name}</span>
              </p>
            </div>}
          </div>
        </div>


      </div>
      <ModalEditUser open={openModalEditProfile} handleClose={() => setOpenModalEditProfile(false)} unsubscribe={unsubscribe}></ModalEditUser>
      <ModalEditPassword open={openModalEditPassword} handleClose={() => setOpenModalEditPassword(false)}></ModalEditPassword>
      <ModalUnsubscribeOrganization open={openUnsubscribeModal} handleClose={() => setOpenUnsubscribeModal(false)}></ModalUnsubscribeOrganization>

      <LmModalConfirm
        id="test-modal-confirm-logout"
        title={t("modalConfirm.titleLogout")}
        description={t("modalConfirm.descriptionLogout")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalConfirm}
        handleClose={() => setOpenModalConfirm(false)}
        confirm={logout}></LmModalConfirm>
    </>
  );
}

export default Layout;
