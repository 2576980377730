import { t } from "i18next";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  ActionCategoriesLoading,
  ActionContentManagementType,
  ActionDeleteCategoryLoading,
  ActionEditCategoryLoading,
  TypeDocumentManagementActions,
} from "../../model/actions/typeContentManagementActions";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import { CategoryDto } from "../../model/dto/DocumentDto";
import ResponseApiError from "../../model/dto/ResponseApiError";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getFetchParams } from "./utilsSaga";

const baseUrlApiCategory = process.env.REACT_APP_API_URL + "Categories";

export function* editCategory(action: ActionEditCategoryLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApiCategory + "/" + action.value.id + "?locale=" + state.appLanguage,
      getFetchParams("PUT", { name: action.value.name, organizationId: state.userInfo.selectedOrganization }, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: CategoryDto = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_EDIT_CATEGORY_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_EDIT_CATEGORY_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* categories(action: ActionCategoriesLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApiCategory + "/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: CategoryDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_CATEGORIES_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_CATEGORIES_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteCategory(action: ActionDeleteCategoryLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApiCategory + "/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DELETE_CATEGORY_SUCCESS, value: action.value });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.messageDeleteCategory"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DELETE_CATEGORY_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editCategoryWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_EDIT_CATEGORY_LOADING, editCategory);
}

export function* categoriesWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_CATEGORIES_LOADING, categories);
}

export function* deleteCategoryWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DELETE_CATEGORY_LOADING, deleteCategory);
}

export default function* rootCategoriesSaga() {
  yield all([editCategoryWhatcher(), categoriesWhatcher(), deleteCategoryWhatcher()]);
}
