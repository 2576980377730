import React from "react";
import LmCheckBox from "../check-box/LmCheckBox";
import LmIcon from "../icon/LmIcon";
import { ProjectDto, Timeline } from "../../model/dto/ProjectDto";
import { convertMilisecondsToHoursMinutesSeconds, getBase64Img, getColorByEvent, getProjectDataPlayer, getProjectDataTeam, getProjectResultTag } from "../../utils/utils";
import { ReactComponent as Image } from "../../assets/icons/image-black.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import { t } from "i18next";

interface IAnalysisItemByCategory {
  selected: boolean;
  timeline: Timeline;
  project: ProjectDto;
  active: boolean;
  isStaffByTeam: boolean;
  checkTimeline(select: boolean, timeline: Timeline): void;
  selectTimeline(timeline: Timeline): void;
  viewTimeLine(timeline: Timeline): void;
}

const AnalysisItemByCategory: React.FC<IAnalysisItemByCategory> = ({ selected, timeline, project, active, isStaffByTeam, checkTimeline, selectTimeline, viewTimeLine }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);

  return (
    <div className={`flex items-center gap-2 border-b border-neutral-200 p-4 ${active ? 'bg-[#45e9ad1a]' : ''}`}>
      {isStaffByTeam && <div className="flex items-center">
        <LmCheckBox id={"timeline-" + timeline.$id} value={selected} changeValue={(e) => checkTimeline(e.currentTarget.checked, timeline)}></LmCheckBox>
      </div>}
      <div className="flex flex-wrap items-center gap-2 " >
        {timeline?.Players?.map((player) => (
          <figure key={player.$ref} className="h-8 w-8 rounded-full border border-neutral-200 overflow-hidden">
            <img className="object-cover min-h-full" src={getBase64Img(getProjectDataPlayer(player.$ref, project)?.Photo ?? "")}></img>
          </figure>
        ))}
        {timeline?.Teams?.map((team) => (
          <figure key={team.$ref} className="h-8 w-8 rounded-full border border-neutral-200 flex justify-center items-center overflow-hidden">
            <img className="aspect-auto max-h-full" src={getBase64Img(getProjectDataTeam(team.$ref, project)?.Shield)}></img>
          </figure>
        ))}
        <div>
          <div className="flex items-center gap-2">
            <p className="text-xs text-decorative font-semibold">
              <span>{getProjectResultTag(timeline.Tags)}</span>
            </p>
            {getProjectResultTag(timeline.Tags) && <div className="h-2 w-2 rounded-sm" style={{ background: getColorByEvent(stateContent.project.data as ProjectDto, timeline) }}></div>}
          </div>
          <p className="text-xs text-subtle cursor-pointer" onClick={() => viewTimeLine(timeline)}>
            {convertMilisecondsToHoursMinutesSeconds(timeline.Start)} - {convertMilisecondsToHoursMinutesSeconds(timeline.Stop)}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-4 ml-auto">
        {timeline?.Drawings?.length > 0 && (
          <span className="flex items-center gap-1">
            <LmIcon icon={<Image />} size="xs" className="hover:opacity-70 transition" />
            <p className="text-xs font-semibold">{timeline.Drawings.length}</p>
          </span>
        )}
        <LmIcon icon={<Eye />} size="xs" className="ml-auto cursor-pointer hover:opacity-70 transition" tooltip={t('content.viewDetail') as string} clickAction={() => selectTimeline(timeline)} />
      </div>
    </div>
  );
};

export default AnalysisItemByCategory;
