import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { ReactComponent as FileTemplate } from "../../assets/icons/align-left.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-narrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-narrow-up.svg";
import { ReactComponent as FilePdf } from "../../assets/icons/file-pdf.svg";
import { ReactComponent as FileDashboard } from "../../assets/icons/layout-alt.svg";
import { ReactComponent as FileVideo } from "../../assets/icons/menu-content.svg";
import { ReactComponent as FileProject } from "../../assets/icons/star.svg";
import { ReactComponent as FilePlaylist } from "../../assets/icons/folder.svg";
import LmButton from "../../components/button/LmButton";
import TabElement from "../../components/content-management/TabElement";
import TeamSelector from "../../components/content-management/TeamSelector";
import Loading from "../../components/loading/Loading";
import { TabContentManagement } from "../../model/TabContentManagement";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { ContentManagementState, ContentManagementType } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT } from "../../routes/routes";
import { getSelectedDataById, isStaffByOrganization, typesContentStateFilesSelected } from "../../utils/utils";
import ContentManagementDocument from "./ContentManagementDocument";

function ContentManagement() {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const dispatch = useDispatch();
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const isStaffByOrg = useMemo(() => isStaffByOrganization(selectedOrganizationData, stateAuth), [stateAuth.userInfo.selectedOrganization]);
  const location = useLocation();
  const params = useParams(); 

  useEffect(() => {
    const { id } = params;
    if (!id) {
      dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: [] });
    }    

    dispatch<TypeActions>({
      type: ActionContentManagementType.ACTION_DOCUMENTS_LOADING,
      value: { organizationId: stateAuth.userInfo.selectedOrganization as string, type: ContentManagementType[stateContent.contentSelected] },
    });
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_CATEGORIES_LOADING, value: stateAuth.userInfo.selectedOrganization });
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_TAGS_LOADING, value: {organizationId: stateAuth.userInfo.selectedOrganization} });
  }, []);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: true, showLateralMenu: true } });
  }, []);

  const optionsTabContentManagement = (): TabContentManagement[] => {
    if (isStaffByOrg) {
      return [
        { icon: FilePdf, text: t("content.document"), color: "bg-document" },
        { icon: FileVideo, text: t("content.video"), color: "bg-video" },
        { icon: FilePlaylist, text: t("content.playlist"), color: "bg-playlist" },
        { icon: FileDashboard, text: t("content.dashboard"), color: "bg-dashboard" },
        { icon: FileTemplate, text: t("content.template"), color: "bg-template" },
        { icon: FileProject, text: t("content.project"), color: "bg-project" },
      ];
    } else {
      return [
        { icon: FilePdf, text: t("content.document"), color: "bg-document" },
        { icon: FileVideo, text: t("content.video"), color: "bg-video" },
        { icon: FilePlaylist, text: t("content.playlist"), color: "bg-playlist" },
      ];
    }
  };

  const orderDocuments = () => {
    dispatch({ type: ActionContentManagementType.ACTION_DOCUMENTS_ORDER_BY_DATE });
  };

  const changeTab = (index: number) => {    
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_CONTENT_MANAGEMENT_TAB_SELECTED, value: index });
    dispatch<TypeActions>({
      type: ActionContentManagementType.ACTION_DOCUMENTS_LOADING,
      value: { organizationId: stateAuth.userInfo.selectedOrganization, type: ContentManagementType[index] },
    });
  };

  const typeFileState = useMemo(() => stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)],
    [stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)]]
  );

  const order = typeFileState?.order;

  return (
    <div className="px-5 lg:px-12  mt-20 lg:mt-0" data-testid="page-content-management">
      {location.pathname === "/" + ROUTE_PRIVATE_CONTENT_MANAGEMENT ? (
        <>
          <div className="flex flex-wrap justify-between items-center gap-4 my-5">
            <p className="font-decorative">{isStaffByOrg ? t("content.documentTitle") : t("content.documentTitlePlayer")}</p>

            <div className="flex gap-2">
              <LmButton
                styleButton="secondary"
                buttonType="submit"
                type="solid"
                size="small"
                text={t("content.lastModification")}
                icon={order === "desc" ? <ArrowDown /> : <ArrowUp />}
                iconPosition="center"
                clickAction={orderDocuments}
              />

              {isStaffByOrg && <TeamSelector></TeamSelector>}
            </div>
          </div>
          <div className="flex items-center gap-3 mb-5 overflow-x-scroll scrollbar-hide">
            {optionsTabContentManagement().map((tab, index) => (
              <TabElement key={tab.text} element={tab} active={stateContent.contentSelected === index} onChangeTab={() => changeTab(index)} />
            ))}
          </div>
          {typeFileState?.loading ? <Loading /> : <ContentManagementDocument />}
        </>
      ) : (
        <Outlet />
      )}
    </div>
  );
}
export default ContentManagement;
