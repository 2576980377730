import { ButtonBase, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { ReactComponent as LogoutIcon } from "../../assets/icons/log-out.svg";
import { useNavigate } from "react-router-dom";
import { ROUTE_LOGIN } from "../../routes/routes";
import MainData from "./MainData";
import Teams from "./Teams";
import { MainState } from "../../model/states/MainState";
import { OrganizationState } from "../../model/states/OrganizationState";
import LmModalConfirm from "../../components/modal-confirm/LmModalConfirm";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";

function NewOrganization() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const createOrganization = stateOrganization.organizationForm;
  const dispatch = useDispatch();
  const { activeTab } = createOrganization;
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const isTabDisabled = !createOrganization.mainData.isFormValid || !createOrganization.ok;

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: false, showLateralMenu: false } });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_ACTIVE_TAB, value: newValue });
  };

  const logout = () => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_LOGOUT });
    navigate(ROUTE_LOGIN);
  };

  return (
    <>
      <div className="lm-new-organization full-height max-w-7xl pt-12 md:pt-24 px-5 md:px-6 pb-9 md:pb-16 my-0 mx-auto" data-testid="new-organization-page">
        <div className="flex justify-between items-center mb-6">
          <p className="font-decorative text-decorative">{t("newOrganization.title")}</p>
          <ButtonBase sx={{ padding: "0.5rem", borderRadius: "50%" }} onClick={() => setOpenModalConfirm(true)}>
            <LogoutIcon />
          </ButtonBase>
        </div>
        <div className="text-decorative">
          <Tabs className="shadow-border" textColor="inherit" indicatorColor="primary" value={activeTab} onChange={handleChange}>
            <Tab sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }} label={t("newOrganization.tab1")} />
            <Tab
              disabled={isTabDisabled}
              sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }}
              label={t("newOrganization.tab2")}
              data-testid="test-tab-teams"
            />
          </Tabs>
        </div>
        {activeTab === 0 && <MainData />}
        {activeTab === 1 && <Teams />}
      </div>
      <LmModalConfirm
        title={t("modalConfirm.titleLogout")}
        description={t("modalConfirm.descriptionLogout")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalConfirm}
        handleClose={() => setOpenModalConfirm(false)}
        confirm={logout}></LmModalConfirm>
    </>
  );
}

export default NewOrganization;
