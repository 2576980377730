import React, { useMemo, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Drawer, List, IconButton, Divider, Slide, Badge } from "@mui/material";
import MenuItem from "./MenuItem";
import "./MainMenu.scss";

import { ReactComponent as MenuIconClose } from "../../assets/icons/menu-icon-left.svg";
import { ReactComponent as MenuIconOpen } from "../../assets/icons/menu-icon-right.svg";
import { ReactComponent as LogoIconClose } from "../../assets/icons/logo-close.svg";
import { ReactComponent as LogoIconOpen } from "../../assets/icons/logo-open.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/log-out-white.svg";
import { ReactComponent as MenuHome } from "../../assets/icons/menu-home.svg";
import { ReactComponent as MenuUsers } from "../../assets/icons/menu-users.svg";
import { ReactComponent as MenuTeams } from "../../assets/icons/menu-teams.svg";
import { ReactComponent as MenuContent } from "../../assets/icons/menu-content.svg";
import { ReactComponent as MenuNotifications } from "../../assets/icons/menu-notifications.svg";

import {
  ROUTE_LOGIN,
  ROUTE_PRIVATE_NOTIFICATIONS,
  ROUTE_PRIVATE_DASHBOARD,
  ROUTE_PRIVATE_TEAMS,
  ROUTE_PRIVATE_USERS,
  ROUTE_PRIVATE_CONTENT_MANAGEMENT,
} from "../../routes/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { TypeActions } from "../../model/actions/typeActions";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import NotificationsListItem from "../notifications-list-item/NotificationsListItem";
import { formatDateAgo, getSelectedDataById, isOwner, isStaffByOrganization } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { NotificationsState } from "../../model/states/NotificationsState";
import useNotifications from "../../hooks/useNotifications";
import NotificationDto from "../../model/dto/NotificationDto";
import Loading from "../loading/Loading";

const DRAWER_WIDTH = "16rem";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MuiDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);
  const [showModalConfirmDeleteNotf, setShowModalConfirmDeleteNot] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<NotificationDto>();

  const { markAsRead, markAsUnread, deleteNotification } = useNotifications();

  const [open, setOpen] = React.useState(false);
  const [openSecondMenu, setOpenSecondMenu] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isStaffByOrg = useMemo(() => isStaffByOrganization(selectedOrganizationData, stateAuth), [stateAuth.userInfo.selectedOrganization]);

  const handleDrawerOpen = () => {
    setOpen((prevValue) => !prevValue);
    if (openSecondMenu) {
      setOpenSecondMenu(false);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    if (openSecondMenu) {
      setOpenSecondMenu(false);
    }
  };

  const logout = () => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_LOGOUT });
    navigate(ROUTE_LOGIN);
  };

  const checkPermissions = () => {
    return isOwner(stateAuth, selectedOrganizationData?.id);
  };

  const onClickSeeAll = () => {
    handleDrawerClose();
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_NOTIFICATIONS });
    navigate(ROUTE_PRIVATE_NOTIFICATIONS);
  };

  const onClickMark = (notification: NotificationDto) => {
    if (notification?.readAt !== null) {
      markAsUnread([notification.id]);
    } else {
      markAsRead([notification.id]);
    }
  };

  const onClickDelete = (notification: NotificationDto) => {
    setSelectedNotification(notification);
    setShowModalConfirmDeleteNot(true);
  };

  const onDeleteNotification = () => {
    deleteNotification([selectedNotification?.id ?? ""]);
    setShowModalConfirmDeleteNot(false);
  };

  const notificationsShow = useMemo(
    () => stateNotifications.notificationsList.filter((notification) => !notification.readAt).filter((not, index) => index < 10),
    [stateAuth.userInfo.selectedOrganization, stateNotifications.notificationsList]
  );
  return (
    <>
      <MuiDrawer variant="permanent" open={open} data-testid="main-menu">
        <DrawerHeader className="bg-black" sx={{ justifyContent: "flex-start" }}>
          {open ? (
            <div>
              <IconButton onClick={handleDrawerOpen}>
                <MenuIconOpen className="m-2" />
              </IconButton>
              <LogoIconOpen className="m-2" />
            </div>
          ) : (
            <div>
              <IconButton onClick={handleDrawerOpen}>
                <MenuIconClose className="m-2" />
              </IconButton>
              <LogoIconClose className="m-2" />
            </div>
          )}
        </DrawerHeader>

        <div className="lm-menu-group flex flex-col justify-between h-full bg-black">
          <List>
            <MenuItem
              text={t("menu.home")}
              open={open}
              icon={<MenuHome className="text-white" />}
              route={ROUTE_PRIVATE_DASHBOARD}
              onClick={() => setOpenSecondMenu(false)}></MenuItem>
            {checkPermissions() && (
              <MenuItem
                text={t("menu.users")}
                open={open}
                icon={<MenuUsers className="text-white" />}
                route={ROUTE_PRIVATE_USERS}
                onClick={() => setOpenSecondMenu(false)}></MenuItem>
            )}
            {isStaffByOrg && (
              <MenuItem
                text={t("menu.teams")}
                open={open}
                icon={<MenuTeams className="text-white" />}
                route={ROUTE_PRIVATE_TEAMS}
                onClick={() => setOpenSecondMenu(false)}></MenuItem>
            )}

            <MenuItem
              text={t("menu.content")}
              open={open}
              icon={<MenuContent className="text-white" />}
              route={ROUTE_PRIVATE_CONTENT_MANAGEMENT}
              onClick={() => setOpenSecondMenu(false)}></MenuItem>
          </List>

          <List>
            <Divider sx={{ backgroundColor: "var(--bv-color-neutral-200)", opacity: "0.4", margin: "1rem" }} variant="middle" />

            <MenuItem
              text={t("menu.notifications")}
              open={open}
              icon={
                stateNotifications.showUserNotification ? (
                  <Badge sx={{ ".MuiBadge-badge": { backgroundColor: "#e89633", minWidth: "10px", height: "10px" } }} overlap="circular" badgeContent="">
                    <MenuNotifications />
                  </Badge>
                ) : (
                  <MenuNotifications />
                )
              }
              onClick={() => setOpenSecondMenu(!openSecondMenu)}></MenuItem>

            <MenuItem
              text={t("menu.logOut")}
              open={open}
              icon={<LogoutIcon data-testid="test-logout-desktop" />}
              onClick={() => setOpenModalConfirm(true)}></MenuItem>
          </List>
        </div>
      </MuiDrawer>

      <Slide direction="right" in={openSecondMenu}>
        <div className={`fixed full-height bg-white shadow-shadow ml-16 px-8 py-12 z-20 ${open ? "ml-64" : ""}`}>
          <div className="flex justify-between items-center mb-8 gap-4">
            <p className="font-decorative text-decorative">{t("notifications.unRead")}</p>
            <a className="text-sm text-orgColor font-semibold cursor-pointer transition hover:opacity-70" onClick={onClickSeeAll}>
              {t("notifications.seeAll")}
            </a>
          </div>
          {notificationsShow.map((notification) => (
            <NotificationsListItem
              key={notification.id}
              id={notification.id}
              avatarImage={notification.avatarImage}
              title={notification.type?.title || ""}
              description={`${notification.details ?? ""} · ${formatDateAgo(notification.createdAt, stateAuth.appLanguage)}`}
              read={false}
              date={notification.createdAt}
              externalLink={notification?.externalLink}
              summary={true}
              actionMark={() => onClickMark(notification)}
              actionDelete={() => onClickDelete(notification)}
            />
          ))}
          {notificationsShow.length === 0 && <div>{t("notifications.emptyNotificationsUnread")}</div>}
          {stateNotifications.getNotifications.loading && <Loading />}
        </div>
      </Slide>
      <LmModalConfirm
        id="test-modal-confirm-logout"
        title={t("modalConfirm.titleLogout")}
        description={t("modalConfirm.descriptionLogout")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalConfirm}
        handleClose={() => setOpenModalConfirm(false)}
        confirm={logout}></LmModalConfirm>

      <LmModalConfirm
        id="test-modal-confirm-remove-notification"
        title={t("notifications.deleteNotificationTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={showModalConfirmDeleteNotf}
        handleClose={() => setShowModalConfirmDeleteNot(false)}
        confirm={onDeleteNotification}
      />
    </>
  );
}
