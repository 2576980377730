/* eslint-disable camelcase */
import { DEFAULT_IMAGE } from "../../constants/globalConstanst";
import { OrganizationState } from "../../model/states/OrganizationState";
import { ViewMode } from "../../model/ViewMode";

export const organizationState: OrganizationState = {
  organization: "",
  selectedOrganization: null,
  selectedLicense: null,
  teamTabSelected: 0,
  organizationForm: {
    viewMode: ViewMode.CREATE,
    loading: false,
    ok: false,
    activeTab: 0,
    mainData: {
      image: DEFAULT_IMAGE,
      color: "",
      name: "",
      address: "",
      cifVat: "",
      email: "",
      phone: "",
      isFormValid: false,
      license: "",
      validUntil: "",
      storage: "",
    },
  },
  organizationSumary: {
    loading: false,
    error: false,
    ok: false,
    data: null,
  },
  exit: {
    loading: false,
    error: false,
    ok: false,
  },
  organizationUsers: {
    sort: "upName",
    filter: undefined,
    quickRoles: undefined,
    loading: false,
    error: false,
    ok: false,
    editUser: {
      loading: false,
      error: false,
      ok: false,
    },
    pendingInvitations: {
      loading: false,
      error: false,
      ok: false,
    },
  },
};
