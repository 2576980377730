import { useEffect } from "react";
import HomeDocument from "../home-document/HomeDocument";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ContentManagementState, ContentManagementType } from "../../model/states/ContentManagementState";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import EmptyDashboard from "../empty/emptyDashboard";
import EmptyDocuments from "../../assets/img/empty-document.png";

const LmLastDocuments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  useEffect(() => {
    dispatch<TypeActions>({
      type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_LOADING,
      value: {
        organizationId: stateAuth.userInfo.selectedOrganization as string,
        types: [ContentManagementType[ContentManagementType.Document], ContentManagementType[ContentManagementType.Video]],
        top: 2,
      },
    });
  }, [stateAuth.userInfo.selectedOrganization, stateAuth.appLanguage]);

  return stateContent.lastDocuments.loading ? (
    <Loading />
  ) : (
    <div className="space-y-4">
      {stateContent.lastDocuments.data?.length ? (
        stateContent.lastDocuments.data.map((doc) => <HomeDocument key={doc.id} document={doc} />)
      ) : (
        <EmptyDashboard text={t("content.emptyCategory")} img={EmptyDocuments} />
      )}
    </div>
  );
};

export default LmLastDocuments;
