import React from "react";
import LmIcon from "../icon/LmIcon";
import { ReactComponent as InputError } from "../../assets/icons/input-error.svg";

interface IMessageError {
  message: any;
  id?: string;
}

const LmMessageError: React.FC<IMessageError> = ({ message = "", id = "" }) => {
  return (
    <div className="text-error-500 flex items-center gap-1.5 py-0.5 px-1" data-testid={"error-field-" + id}>
      <LmIcon icon={<InputError />} size="xs" />
      <span className="text-sm">{message}</span>
    </div>
  );
};

export default LmMessageError;
