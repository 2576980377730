import { t } from "i18next";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Avatar200 from "../../assets/img/Avatar200px.svg";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { TemplatePlayerDto } from "../../model/dto/TemplatePlayerDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { formatLocaleDate, getLocaleByUserOrganization, getSelectedDataById } from "../../utils/utils";
import LmAvatar from "../avatar/LmAvatar";
import LmInput from "../input/LmInput";

interface Props {
  player: TemplatePlayerDto;
}

const TemplatePlayerData = ({ player }: Props) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const { Photo, Name, LastName, NickName, Nationality, Position, Number, Height, Weight, Birthday } = player;
  const dateLocale = useMemo(
    () => (getLocaleByUserOrganization(stateAuth.userInfo.data.user.id, selectedOrganizationData?.users) === "en" ? "en-US" : "es-ES"),
    [stateAuth.userInfo.selectedOrganization]
  );

  const getPhoto = () => {
    return `data:image/png;base64, ${Photo}`;
  };

  const getBirthDate = () => {
    return formatLocaleDate(Birthday, dateLocale);
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-8">
        <LmAvatar size={100} src={Photo ? getPhoto() : Avatar200} radius="1rem" />
      </div>
      <div className="grid sm:grid-cols-2 gap-4">
        <div>
          <LmInput readonly={true} label={t("formLabel.name")} disabled={true} value={Name}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.lastName")} disabled={true} value={LastName}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.nickName")} disabled={true} value={NickName}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.nationality")} disabled={true} value={Nationality}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.position")} disabled={true} value={Position}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.number")} disabled={true} value={Number}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.height")} disabled={true} value={Height.toString()}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.weight")} disabled={true} value={Weight}></LmInput>
        </div>
        <div>
          <LmInput readonly={true} label={t("formLabel.birthDate")} disabled={true} value={getBirthDate()}></LmInput>
        </div>
      </div>
    </div>
  );
};

export default TemplatePlayerData;
