import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down-black.svg";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { getSelectedDataById, typesContentStateFilesSelected } from "../../utils/utils";
import LmAvatar from "../avatar/LmAvatar";
import LmButton from "../button/LmButton";

const TeamSelector: React.FC = () => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(()=>{
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_DOCUMENTS_CHANGE_FILTER_TEAM, value: '' });
  }, [stateAuth.userInfo.selectedOrganization])

  const handleClose = (idTeam: string, teamName?: string) => { 
    setSelectedTeam(teamName || "");  
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_DOCUMENTS_CHANGE_FILTER_TEAM, value: idTeam });
    setAnchorEl(null);
  };

  const teams = useMemo(() => {
    const fileType = stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)];
    const teamsFilesId = new Set<string>();
    fileType?.data?.forEach((element) => {
      teamsFilesId.add(element.teamId);
    });
    return [...teamsFilesId].map((teamId) => {
      const team = selectedOrganizationData?.teams.find((team) => team.id === teamId);
      return { id: teamId, name: team?.name, image: team?.image };
    });
  }, [stateContent.contentSelected, stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)]]);


  return (
    <>
      <LmButton
        styleButton="secondary"
        buttonType="submit"
        type="solid"
        size="small"
        text={selectedTeam.length ? selectedTeam : t("content.selectTeam")}
        icon={<ChevronDown />}
        iconPosition="right"
        clickAction={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "0.5rem" }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {teams.map((team) => (
          <MenuItem className="flex items-center gap-2" key={team.id} onClick={() => handleClose(team.id as string, team.name as string)}>
            <LmAvatar radius={"100%"} size={24} src={team.image} />
            {team.name}
          </MenuItem>
        ))}
        <MenuItem onClick={() => handleClose("")}>
          <span className="ml-8">{t("content.allTeams")}</span>
        </MenuItem>
      </Menu>
    </>
  );
};
export default TeamSelector;
