export enum RolesType {
  CloudUser = "CloudUser",
  Owner = "Owner",
  External = "External",
}

export enum RolesUserOrganizationType {
  Admin = "Admin",
  HeadCoach = "HeadCoach",
  Coach = "Coach",
  Analyst = "Analyst",
  Player = "Player",
}
