import { TemplatePlayerDto } from "../../model/dto/TemplatePlayerDto";
import PlayerListItem from "./PlayerListItem";

export interface Props {
  players: TemplatePlayerDto[];
  viewPlayer: (player: TemplatePlayerDto) => void;
}

const LmPlayerList = ({ players, viewPlayer }: Props) => {
  return (
    <div className="grid-cols-[repeat(auto-fill,_minmax(8rem,_1fr))] md:grid-cols-[repeat(auto-fill,_minmax(12rem,_1fr))] grid gap-5">
      {players.map((player: TemplatePlayerDto) => (
        <div className="border border-neutral-200 rounded p-4" key={player.ID}>
          <PlayerListItem player={player} playerClick={viewPlayer}></PlayerListItem>
        </div>
      ))}
    </div>
  );
};

export default LmPlayerList;
