import { t } from "i18next";
import React, { useEffect, useState } from "react";
import LmButton from "../../components/button/LmButton";
import { ReactComponent as Export } from "../../assets/icons/export.svg";
import LmModalUploadFile from "../modal-upload-file/LmModalUploadFile";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { useSelector, useDispatch } from "react-redux";
import { MainState } from "../../model/states/MainState";
import Loading from "../loading/Loading";
import { BlobServiceClient } from "@azure/storage-blob";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { BLOB_SIZE_AZURE_UPLOAD, CONCURRENCY_AZURE_UPLOAD } from "../../constants/globalConstanst";
import { AuthState } from "../../model/states/AuthState";
import { UploadFile as UploadFileModel } from "../../model/dto/FileDto";

const UploadFile: React.FC = () => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false); 
  const dispatch = useDispatch();

  const uploadFile = () => {
    setOpenModalUploadFile(true);
  };

  useEffect(() => {
    if (stateContent.uploadLink.ok && stateContent.uploadLink.data && stateContent.uploadDocument.data && !stateContent.uploadToAzure) {
      fileUpload(stateContent.uploadDocument.data)
    }
  }, [stateContent.uploadLink.ok]);

  useEffect(() => {
    if (stateContent.uploadDocument.ok) {      
      dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_RESET });
    }
    setOpenModalUploadFile(false);
  }, [stateContent.uploadDocument.ok]);

  async function fileUpload(uploadFile: UploadFileModel) {
    try {   
      dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_TO_AZURE_LOADING });        
      const containerName = stateContent.uploadLink.data?.containerName as string;
      const blobService = new BlobServiceClient(stateContent.uploadLink.data?.sasUri as string);
      
      const containerClient = blobService.getContainerClient(containerName);
  
      const blobClient = containerClient.getBlockBlobClient(stateContent.uploadLink.data?.filePath as string);        
      
      const { file } = uploadFile;
      const res = await blobClient.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
        blockSize: BLOB_SIZE_AZURE_UPLOAD,
        concurrency: CONCURRENCY_AZURE_UPLOAD,
        onProgress: ev => {
          dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_PROGRESS, value: ev.loadedBytes });          
        }
      });      
      if (res) {
        dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_LOADING, value: stateContent.uploadDocument.data?.id });
        dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_TO_AZURE_SUCCESS }); 
      }
    } catch (error) {
      console.log(error)    
      dispatch({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } })
      dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_ERROR });
      deleteFile(uploadFile);
    //   dispatch({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_TO_AZURE_SUCCESS });     
    }
  }

  function deleteFile(file: UploadFileModel) {
    const params = `?organizationId=${stateAuth.userInfo.selectedOrganization}&id=${file.id}`;
  
    dispatch({
        type: ActionContentManagementType.ACTION_DELETE_FILES_LOADING,
        value: { data: params, callBack: () => dispatch({ type: ActionContentManagementType.ACTION_DELETE_FILES_SUCCESS, value: [file.id] }) },
      });
  }

  return (
    <>
      <div data-testid="page-content-management" className="min-w-fit">
        {!stateContent.uploadDocument.loading ? <LmButton
          styleButton="organization"
          buttonType="button"
          type="solid"
          size="small"
          fullWidth={false}
          text={t("content.uploadFile")}
          icon={<Export />}
          iconPosition="left"
          clickAction={uploadFile}
        />
        : <Loading />}
      </div>
      {!stateContent.uploadDocument.loading && <LmModalUploadFile open={openModalUploadFile} handleClose={() => setOpenModalUploadFile(false)} />}

      {}
    </>
  );
};
export default UploadFile;
