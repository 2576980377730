import { useDispatch, useSelector } from "react-redux";
import LmButton from "../../components/button/LmButton";
import { MainState } from "../../model/states/MainState";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as UserPlusIcon } from "../../assets/icons/user-plus-white.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/x.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import LmDropDown from "../../components/dropdown/LmDropDown";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { getRoleOptions, getSelectedDataById, normalizeString } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import { IdValue } from "../../model/forms/IdValue";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { Badge, Divider } from "@mui/material";
import LmSelect from "../select/LmSelect";
import LmModalPendingInvitations from "../modal-pending-invitations/LmModalPendingInvitations";
import LmModalInviteMember from "../modal-invite-member/LmModalInviteMember";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";
import { OrganizationState } from "../../model/states/OrganizationState";

const UsersHeader = () => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const roleOptions = getRoleOptions();
  const teamOptions = selectedOrganizationData?.teams.map((team) => {
    return { id: team.id, value: team.name } as IdValue;
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [userNameFilter, setUserNameFilter] = useState<IdValue[]>([]);
  const [userNameFilterValue, setUserNameFilterValue] = useState("");
  const [roles, setRoles] = useState<IdValue[]>([]);
  const [teamsFilter, setTeamsFilter] = useState<IdValue[]>([]);
  const [openPendingInvitationsModal, setOpenPendingInvitationsModal] = useState(false);
  const [openInviteMemberModal, setOpenInviteMemberModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [badgeFilter, setBadgeFilter] = useState(0);

  const optionsStaff = useMemo(() => {
    const users = selectedOrganizationData?.users;
    if (users) {
      return users.map((user) => {
        return { id: user?.userId, value: user?.user?.name + " " + user?.user?.lastName };
      });
    }
    return [];
  }, []);

  useEffect(() => {
    if (stateOrganization.organizationUsers.quickRoles) {
      clearFilter();
    }
  }, [stateOrganization.organizationUsers.quickRoles]);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_USER_NAME_FILTER, value: normalizeString(userNameFilterValue) });
  }, [userNameFilterValue]);

  const selectedValueNameDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setUserNameFilterValue(e.target.value);
  };

  const selectedStaff = (options: IdValue[]) => {
    setUserNameFilter(options as IdValue[]);
    if (options.length) setUserNameFilterValue(options[0].value);
  };

  const onChangeRolesFilter = (options: IdValue[]) => {
    setRoles(options);
  };

  const onChangeTeamsFilter = (options: IdValue[]) => {
    setTeamsFilter(options);
  };

  const clearFilter = (clearQuickFilter?: boolean) => {
    setRoles([]);
    setTeamsFilter([]);
    setBadgeFilter(0);
    setUserNameFilter([]);
    setUserNameFilterValue("");
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_FILTER });

    setOpenFilter(false);
    if (clearQuickFilter) dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_QUICK_FILTER });
  };

  const onSearch = () => {
    if (roles.length || teamsFilter.length) {
      let num = 0;
      if (roles.length) num += 1;
      if (teamsFilter.length) num += 1;

      if (num > 0) setBadgeFilter(num);

      const filterRoles = roles.length > 0 ? roles.map((item) => item.id) : roleOptions.map((rol) => rol.id);
      const filterTeams = teamsFilter.length > 0 ? teamsFilter.map((item) => item.value) : teamOptions.map((team) => team.value);
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_FILTER });
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_QUICK_FILTER });
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_USER_ROLES_FILTER, value: filterRoles });
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_USER_TEAMS_FILTER, value: filterTeams });
    }
    setOpenFilter(false);
  };

  const onOpenPendingInvitations = () => {
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_LOADING, value: stateAuth.userInfo.selectedOrganization });
    setOpenPendingInvitationsModal(!openPendingInvitationsModal);
  };

  const disableInvite = (): boolean => {
    return !selectedOrganizationData?.teams?.length;
  };

  return (
    <>
      <div className="flex flex-wrap items-center justify-between gap-4 py-5 lg:py-8">
        <p className="text-xl lg:text-base font-extrabold mb-10 lg:mb-0">{t("users.usersTitle")}</p>
        <div className="flex flex-wrap gap-5 lg:gap-2 w-full lg:w-auto">
          <div className="w-full lg:w-auto">
            <LmAutocomplete
              placeholder={t("users.searchUser") as string}
              autocomplete="off"
              id="userNameFilter"
              value={userNameFilter}
              valueDisplay={userNameFilterValue}
              multiple={false}
              options={optionsStaff as IdValue[]}
              setValueDisplay={selectedValueNameDisplay}
              selectedValue={(options) => selectedStaff(options as IdValue[])}
            />
          </div>
          <div className="flex items-center justify-between w-full lg:w-auto">
            <div className="flex items-center justify-end gap-2 ml-auto">
              <div className="flex gap-2 ml-auto relative">
                <div className="lg:relative">
                  {stateMain.sizeScreen == SizeScreen.DESKTOP &&
                    (badgeFilter ? (
                      <Badge
                        sx={{ ".MuiBadge-badge": { backgroundColor: "#e89633", minWidth: "20px", height: "20px", padding: "0", top: "0px", right: "3px" } }}
                        overlap="circular"
                        badgeContent={badgeFilter}>
                        <LmButton
                          styleButton="secondary"
                          type="solid"
                          size="base"
                          text={t("buttonLabel.filter")}
                          icon={<FilterIcon />}
                          iconPosition="center"
                          clickAction={() => {
                            setOpenFilter(!openFilter);
                          }}
                        />
                      </Badge>
                    ) : (
                      <LmButton
                        styleButton="secondary"
                        type="solid"
                        size="base"
                        text={t("buttonLabel.filter")}
                        icon={<FilterIcon />}
                        iconPosition="center"
                        clickAction={() => {
                          setOpenFilter(!openFilter);
                        }}
                      />
                    ))}
                  {stateMain.sizeScreen !== SizeScreen.DESKTOP &&
                    (badgeFilter ? (
                      <Badge
                        sx={{ ".MuiBadge-badge": { backgroundColor: "#e89633", minWidth: "20px", height: "20px", padding: "0", top: "0px", right: "3px" } }}
                        overlap="circular"
                        badgeContent={badgeFilter}>
                        <LmButton
                          styleButton="secondary"
                          type="solid"
                          size="base"
                          icon={<FilterIcon />}
                          iconSize="sm"
                          clickAction={() => {
                            setOpenFilter(!openFilter);
                          }}
                        />
                      </Badge>
                    ) : (
                      <LmButton
                        styleButton="secondary"
                        type="solid"
                        size="base"
                        icon={<FilterIcon />}
                        iconSize="sm"
                        clickAction={() => {
                          setOpenFilter(!openFilter);
                        }}
                      />
                    ))}
                  <LmDropDown open={openFilter} close={() => setOpenFilter(false)} id="organization-dropDrown">
                    <div className="m-4 space-y-4 min-w-0 sm:min-w-[20rem]">
                      <div>
                        <LmSelect
                          id="team-select"
                          label={t("formLabel.team")}
                          multipleCheck={true}
                          valueMultiple={teamsFilter}
                          options={teamOptions}
                          selectedValue={onChangeTeamsFilter}
                        />
                      </div>
                      <div>
                        <LmSelect
                          id="roles-select"
                          label={t("formLabel.role")}
                          multipleCheck={true}
                          valueMultiple={roles}
                          options={roleOptions}
                          selectedValue={onChangeRolesFilter}
                        />
                      </div>
                      <div className="flex justify-end mt-8 space-x-2">
                        <div className="add-button-container">
                          <LmButton
                            styleButton="organization"
                            type="solid"
                            size="extra-small"
                            text={t("buttonLabel.search")}
                            iconPosition="left"
                            clickAction={() => onSearch()}
                            icon={<SearchIcon />}></LmButton>
                        </div>
                      </div>
                    </div>
                  </LmDropDown>
                </div>

                {stateMain.sizeScreen === SizeScreen.DESKTOP ? (
                  <LmButton
                    styleButton="secondary"
                    type="solid"
                    size="base"
                    text={t("buttonLabel.clear")}
                    icon={<ClearIcon />}
                    iconPosition="center"
                    clickAction={() => clearFilter(true)}
                  />
                ) : (
                  <div>
                    <LmButton
                      styleButton="secondary"
                      type="solid"
                      text={t("buttonLabel.clear")}
                      icon={<ClearIcon />}
                      iconPosition="center"
                      clickAction={() => clearFilter(true)}
                    />
                  </div>
                )}

                <Divider orientation="vertical" flexItem sx={{ margin: "0 0.5rem" }} />
                {stateMain.sizeScreen == SizeScreen.DESKTOP && (
                  <LmButton
                    styleButton="secondary"
                    type="solid"
                    size="base"
                    text={t("buttonLabel.pendingInvitation")}
                    icon={<SendIcon />}
                    iconPosition="center"
                    clickAction={onOpenPendingInvitations}
                  />
                )}
                {stateMain.sizeScreen !== SizeScreen.DESKTOP && (
                  <LmButton styleButton="secondary" type="solid" size="extra-small" icon={<SendIcon />} iconSize="sm" clickAction={onOpenPendingInvitations} />
                )}
                {stateMain.sizeScreen == SizeScreen.DESKTOP && (
                  <LmButton
                    isDisabled={disableInvite()}
                    styleButton="organization"
                    type="solid"
                    size="base"
                    text={t("buttonLabel.invite")}
                    iconPosition="left"
                    clickAction={() => setOpenInviteMemberModal(!openInviteMemberModal)}
                    icon={<UserPlusIcon />}></LmButton>
                )}
                {stateMain.sizeScreen !== SizeScreen.DESKTOP && (
                  <LmButton
                    isDisabled={disableInvite()}
                    styleButton="organization"
                    type="solid"
                    size="extra-small"
                    iconSize="sm"
                    clickAction={() => setOpenInviteMemberModal(!openInviteMemberModal)}
                    icon={<UserPlusIcon />}></LmButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LmModalPendingInvitations open={openPendingInvitationsModal} handleClose={() => setOpenPendingInvitationsModal(false)} />
      <LmModalInviteMember open={openInviteMemberModal} handleClose={() => setOpenInviteMemberModal(false)} teams={selectedOrganizationData?.teams} />
    </>
  );
};

export default UsersHeader;
