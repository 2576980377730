import { t } from "i18next";
import * as Yup from "yup";

export function teamInitialValues(teamId: string) {
  return {
    description: '',
    teamId: teamId,
    categoryId: '',
    tags: [],
    shareWithUsers: [],
    shareWithGroups: [],
    shareWithPlayers: [],
    file: null,
  };
}

export function teamValidationSchema(tabActive: number) {
  if (tabActive === 0) {
    return Yup.object({     
      teamId: Yup.string().required(t("errors.form.required") as string),
      file: Yup.string().required(t("errors.form.required") as string),  
    });
  } else {
    return Yup.object({
      description: Yup.string().required(t("errors.form.required") as string),
      teamId: Yup.string().required(t("errors.form.required") as string),
      file: Yup.string().required(t("errors.form.required") as string),  
    });
  }
  
}
