/* eslint-disable camelcase */
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import OrganizationUserDto from "../../model/dto/OrganizationUserDto";
import TeamsDto from "../../model/dto/TeamDto";
import TeamUsersDto from "../../model/dto/TeamUsersDto";
import { AuthState } from "../../model/states/AuthState";
import { changeLocale } from "../../utils/utils";
import { authState } from "../states/authState";

export const authReducer = (state: AuthState = authState, action: TypeActions): AuthState => {
  switch (action.type) {
    case (ActionAuthType.ACTION_LOGIN_LOADING, ActionAuthType.ACTION_INVITATIONS_LOADING): {
      return { ...state, auth: false, login: { ...state.login, loading: true, error: false, messageError: "", loginResponse: null } };
    }
    case ActionAuthType.ACTION_LOGIN_SUCCESS: {
      return { ...state, auth: false, login: { ...state.login, loading: false, error: false, messageError: "", loginResponse: action.value } };
    }
    case ActionAuthType.ACTION_LOGIN_ERROR: {
      return { ...state, auth: false, login: { ...state.login, loading: false, error: true, messageError: action.value, loginResponse: null } };
    }

    case ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_LOADING: {
      return { ...state, resetRequestPassword: { loading: true, ok: false } };
    }

    case ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_SUCCESS: {
      return { ...state, resetRequestPassword: { loading: false, ok: true } };
    }

    case ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_ERROR: {
      return { ...state, resetRequestPassword: { loading: false, ok: false } };
    }

    case ActionAuthType.ACTION_PASSWORD_RESET_LOADING: {
      return { ...state, resetPassword: { loading: true, ok: false } };
    }

    case ActionAuthType.ACTION_PASSWORD_RESET_SUCCESS: {
      return { ...state, resetPassword: { loading: false, ok: action.value } };
    }

    case ActionAuthType.ACTION_PASSWORD_RESET_ERROR: {
      return { ...state, resetPassword: { loading: false, ok: false } };
    }

    case ActionAuthType.ACTION_PASSWORD_CHANGE_LOADING: {
      return { ...state, changePassword: { loading: true, ok: false, error: false } };
    }

    case ActionAuthType.ACTION_PASSWORD_CHANGE_SUCCESS: {
      return { ...state, changePassword: { loading: false, ok: true, error: false } };
    }

    case ActionAuthType.ACTION_PASSWORD_CHANGE_ERROR: {
      return { ...state, changePassword: { loading: false, ok: false, error: true } };
    }

    case ActionAuthType.ACTION_CHECK_INVITATIONS_LOADING: {
      return { ...state, checkInvitation: { loading: true, ok: false, error: false, data: null } };
    }

    case ActionAuthType.ACTION_CHECK_INVITATIONS_SUCCESS: {
      return { ...state, checkInvitation: { loading: false, ok: true, error: false, data: action.value } };
    }

    case ActionAuthType.ACTION_CHECK_INVITATIONS_ERROR: {
      return { ...state, checkInvitation: { loading: false, ok: true, error: true, data: null } };
    }

    case ActionAuthType.ACTION_USER_INFO_LOADING: {
      return { ...state, userInfo: { ...state.userInfo, loading: true, error: false, messageError: "" } };
    }

    case ActionAuthType.ACTION_USER_INFO_SUCCESS: {
      return {
        ...state,
        auth: true,
        userInfo: { ...state.userInfo, loading: false, error: false, messageError: "", data: { ...state.userInfo.data, ...action.value } },
      };
    }

    case ActionAuthType.ACTION_USER_INFO_ERROR: {
      return { ...state, auth: false, userInfo: { ...state.userInfo, loading: false, error: true, messageError: action.value } };
    }

    case ActionAuthType.ACTION_USER_PROFILE_LOADING: {
      return { ...state, editProfile: { loading: true, error: false, messageError: "", ok: false } };
    }

    case ActionAuthType.ACTION_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        editProfile: { loading: false, error: false, messageError: "", ok: action.value.ok },
        userInfo: { ...state.userInfo, data: { ...state.userInfo.data, user: action.value.data } },
      };
    }

    case ActionAuthType.ACTION_USER_PROFILE_ERROR: {
      return { ...state, auth: false, editProfile: { loading: false, error: true, messageError: action.value, ok: false } };
    }

    case ActionAuthType.ACTION_USER_PROFILE_IMAGE_LOADING: {
      return { ...state, editImageProfile: { loading: true, error: false, ok: false } };
    }

    case ActionAuthType.ACTION_USER_PROFILE_IMAGE_SUCCESS: {
      return {
        ...state,
        editImageProfile: { loading: false, error: false, ok: action.value.ok },
        avatarUserOrganization: action.value.image,
      };
    }

    case ActionAuthType.ACTION_USER_PROFILE_IMAGE_ERROR: {
      return { ...state, auth: false, editImageProfile: { loading: false, error: true, ok: false } };
    }

    case ActionAuthType.ACTION_USER_DESACTIVATE_LOADING: {
      return { ...state, desactivateUser: { loading: true, error: false, ok: false } };
    }

    case ActionAuthType.ACTION_USER_DESACTIVATE_SUCCESS: {
      return { ...state, desactivateUser: { loading: false, error: false, ok: true } };
    }

    case ActionAuthType.ACTION_USER_DESACTIVATE_ERROR: {
      return { ...state, desactivateUser: { loading: false, error: true, ok: false } };
    }

    case ActionAuthType.ACTION_CHANGE_USER_ORGANIZATION_ROLE: {
      const idUser = state.userInfo.data.user.id;
      if (action.value.users) {
        const userOrganization = action.value.users.find((user) => user.userId === idUser);
        if (userOrganization) {
          return { ...state, avatarUserOrganization: userOrganization.avatarImage };
        }
      }
      return { ...state };
    }
    case ActionAuthType.ACTION_CHECK_STAY_LOGGED: {
      return { ...state, isStayLogged: action.value };
    }
    case ActionAuthType.ACTION_DELETE_STATE_ORGANIZATION: {
      const organizationCopy = structuredClone(state.userInfo.data.organizations);
      const freeLicensesCopy = structuredClone(state.userInfo.data.freeLicenses);
      const index = organizationCopy.findIndex((obj) => obj.id === action.value);
      if (index > -1) {
        organizationCopy.splice(index, 1);
      }

      const today = new Date();
      const licenseData = state.userInfo?.data?.organizations[index]?.license;

      if (licenseData?.validUntil && new Date(licenseData?.validUntil) > today) {
        freeLicensesCopy.push(licenseData);
      }

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          selectedOrganization: "",
          data: { ...state.userInfo.data, organizations: organizationCopy, freeLicenses: freeLicensesCopy },
        },
      };
    }
    case ActionAuthType.ACTION_UNSUBSCRIBE_STATE_ORGANIZATION: {
      const organizationCopy = structuredClone(state.userInfo.data.organizations);
      const index = organizationCopy.findIndex((obj) => obj.id === action.value);
      if (index > -1) {
        organizationCopy.splice(index, 1);
      }

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          selectedOrganization: "",
          data: { ...state.userInfo.data, organizations: organizationCopy },
        },
      };
    }
    case ActionAuthType.ACTION_STATE_SELECT_ORGANIZATION: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          selectedOrganization: action.value.id || "",
        },
      };
    }
    case ActionAuthType.ACTION_STATE_SELECT_LICENSE: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          selectedLicense: action.value,
        },
      };
    }
    case ActionAuthType.ACTION_STATE_SELECT_TEAM: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          selectedTeam: action.value,
        },
      };
    }

    case ActionAuthType.ACTION_OPTION_MENU_SELECTED: {
      return { ...state, actionMenuSelected: action.value };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_ORGANIZATION: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === action.value.id);
      const organizations = structuredClone(state.userInfo.data.organizations);

      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        ...action.value,
      };
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          selectedOrganization: action.value.id as string,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_CREATE_ORGANIZATION: {
      const organizations = structuredClone(state.userInfo.data.organizations);
      const licenseSelected = state.userInfo.data.freeLicenses.find((license) => license.id === state.userInfo.selectedLicense);
      const freeLicensesUpdate = state.userInfo.data.freeLicenses.filter((license) => {
        return license.id !== state.userInfo.selectedLicense;
      });
      organizations.push({ ...action.value, license: licenseSelected });
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          selectedOrganization: action.value.id as string,
          data: {
            ...state.userInfo.data,
            freeLicenses: freeLicensesUpdate,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_CREATE_TEAM: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      organizations[organizationIndex].teams?.push(action.value);
      if (organizations[organizationIndex].freeStorage) {
        organizations[organizationIndex].freeStorage = (organizations[organizationIndex].freeStorage as number) - action.value.storage;
      }

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_TEAM: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams?.findIndex((team) => team.id === action.value.id);
      const teamSelected = organizations[organizationIndex].teams?.find((team) => team.id === action.value.id);

      organizations[organizationIndex].freeStorage =
        (organizations[organizationIndex].freeStorage as number) - action.value.storage + organizations[organizationIndex].teams[teamIndex].storage;
      organizations[organizationIndex].teams[teamIndex] = { ...teamSelected, ...action.value };
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_DELETE_TEAM: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);

      const teamSelected = organizations[organizationIndex].teams?.find((team) => team.id === action.value);

      if (teamSelected) {
        organizations[organizationIndex].freeStorage = (organizations[organizationIndex].freeStorage as number) + teamSelected.storage;
      }

      const teamsUpdate = organizations[organizationIndex].teams.filter((team) => team.id !== action.value);
      organizations[organizationIndex].teams = teamsUpdate;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_CREATE_TEAM_GROUP: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams.findIndex((team) => team.id === state.userInfo.selectedTeam);
      organizations[organizationIndex].teams[teamIndex].groups?.push(action.value);
      organizations[organizationIndex].groups?.push(action.value);

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_DELETE_TEAM_GROUP: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams.findIndex((team) => team.id === state.userInfo.selectedTeam);
      const groupUpdate = organizations[organizationIndex].teams[teamIndex].groups?.filter((group) => group.id !== action.value);
      organizations[organizationIndex].teams[teamIndex].groups = groupUpdate;
      organizations[organizationIndex].groups = organizations[organizationIndex].groups?.filter((group) => group.id !== action.value);

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_TEAM_GROUP: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams.findIndex((team) => team.id === state.userInfo.selectedTeam);
      const groupIndex = organizations[organizationIndex].teams[teamIndex]?.groups?.findIndex((group) => group.id === action.value.id);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      organizations[organizationIndex].teams[teamIndex].groups[groupIndex] = { ...action.value };
      const organizationGroupIndex = organizations[organizationIndex].groups?.findIndex((group) => group.id === action.value.id);
      if (organizationGroupIndex && organizationGroupIndex > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        organizations[organizationIndex].groups[organizationGroupIndex] = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...organizations[organizationIndex].groups[organizationGroupIndex],
          name: action.value.name,
          players: action.value.players,
        };
      }
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_GET_TEAM_GROUPS: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams.findIndex((team) => team.id === action.value.teamId);

      organizations[organizationIndex].teams[teamIndex].groups = action.value.data;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_CREATE_PLAYER: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams?.findIndex((team) => team.id === action.value.data.teamId);
      const teamSelected = organizations[organizationIndex].teams?.find((team) => team.id === action.value.data.teamId);

      action.value.group.forEach((group) => {
        teamSelected?.groups?.filter((gr) => gr.id === group).forEach((gr) => gr.players.push(action.value.data));
      });

      if (teamSelected) {
        teamSelected?.players?.push(action.value.data);
        if (organizations[organizationIndex].teams) {
          organizations[organizationIndex].teams[teamIndex] = { ...teamSelected };
        }
      }

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_PLAYER: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams?.findIndex((team) => team.id === action.value.data.teamId);
      const teamSelected = organizations[organizationIndex].teams?.find((team) => team.id === action.value.data.teamId);
      const playerIndex = teamSelected?.players?.findIndex((player) => player.id === action.value.data.id);
      const playerSelected = teamSelected?.players?.find((player) => player.id === action.value.data.id);

      teamSelected?.groups?.forEach((group) => {
        group.players = group.players.filter((player) => player.id !== action.value.data.id);
      });

      action.value.group.forEach((group) => {
        teamSelected?.groups?.filter((gr) => gr.id === group).forEach((gr) => gr.players.push(action.value.data));
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      organizations[organizationIndex].teams[teamIndex].players[playerIndex] = { ...playerSelected, ...action.value.data };

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_DELETE_PLAYER: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams?.findIndex((team) => team.id === action.value.teamId);
      const teamSelected = organizations[organizationIndex].teams?.find((team) => team.id === action.value.teamId);
      const playersSelected = teamSelected?.players?.filter((player) => player.id !== action.value.playerId);

      teamSelected?.groups?.forEach((group) => {
        group.players = group.players.filter((player) => player.id !== action.value.playerId);
      });
      organizations[organizationIndex].teams[teamIndex].players = playersSelected;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_CREATE_STAFF: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const staff = action.value[0];
      const teamIndex = organizations[organizationIndex].teams?.findIndex((team) => team.id === staff.teamId);
      organizations[organizationIndex].teams[teamIndex].staff?.push(staff);

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_STAFF: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams?.findIndex((team) => team.id === action.value.teamId);
      const teamSelected = organizations[organizationIndex].teams?.find((team) => team.id === action.value.teamId);
      const staffIndex = teamSelected?.staff?.findIndex((staff) => staff.userId === action.value.userId);
      const staffSelected = teamSelected?.staff?.find((staff) => staff.userId === action.value.userId);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      organizations[organizationIndex].teams[teamIndex].staff[staffIndex] = { ...staffSelected, ...action.value };

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_DELETE_STAFF: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const teamIndex = organizations[organizationIndex].teams?.findIndex((team) => team.id === action.value.teamId);
      const teamSelected = organizations[organizationIndex].teams?.find((team) => team.id === action.value.teamId);
      const staffSelected = teamSelected?.staff?.filter((staff) => staff.userId !== action.value.userId);

      organizations[organizationIndex].teams[teamIndex].staff = staffSelected;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_PLAYER_BY_ORGANIZATION: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      // const players = organizations[organizationIndex].players || [];

      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        players: [...action.value],
      };
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_PLAYER_BY_ORGANIZATION_UNREGISTERED: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);

      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        playersUnregistered: [...action.value],
      };
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_GET_ORGANIZATION_USERS: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        users: [...action.value],
      };
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_ORGANIZATION_USER: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const userUpdated: OrganizationUserDto[] | undefined = organizations[organizationIndex].users?.map((user) => {
        let teamUsers = user.teamUsers;
        if (user.userId === action.value.userId) {
          teamUsers = action.value.teamUsers.map((teamUser: TeamUsersDto) => {
            const teamInfo = organizations[organizationIndex].teams.find((team: TeamsDto) => team.id === teamUser.teamId);
            if (teamInfo) {
              return { ...teamUser, team: teamInfo };
            }

            return teamUser;
          });
        }

        return user.userId === action.value.userId ? { ...action.value, teamUsers } : user;
      });

      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        users: userUpdated,
      };

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_DELETE_ORGANIZATION_USER: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        users: organizations[organizationIndex].users?.filter((user) => user.userId !== action.value),
      };

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_GET_PENDING_INVITATIONS: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        pendingInvitations: action.value,
      };

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_PROFILE_IMAGE: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const userId = organizations[organizationIndex].ownerId;
      const updatedUsers = organizations[organizationIndex].users?.map((user) => {
        if (user.userId === userId) {
          return { ...user, avatarImage: action.value };
        }
        return user;
      });

      organizations[organizationIndex].users = updatedUsers;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_UPDATE_PROFILE_DATA: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      const userId = organizations[organizationIndex].ownerId;

      const updatedUsers = organizations[organizationIndex].users?.map((userAux) => {
        if (userAux.userId === userId) {
          return { ...userAux, user: { ...userAux.user, lastName: action.value.lastName, name: action.value.name } };
        }
        return userAux;
      });
      organizations[organizationIndex].users = updatedUsers;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_STATE_CHANGE_LOCALE: {
      if (state.userInfo?.data?.organizations.length) {
        const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
        if (organizationIndex !== -1) {
          const organizations = structuredClone(state.userInfo.data.organizations);
          // const userId = organizations[organizationIndex].ownerId;
          const userId = state.userInfo.data.user.id;
          const updatedUsers = organizations[organizationIndex].users?.map((userAux) => {
            if (userAux.userId === userId) {
              return { ...userAux, language: action.value };
            }
            return userAux;
          });
          organizations[organizationIndex] = { ...organizations[organizationIndex], users: updatedUsers };
          return { ...state, appLanguage: action.value, userInfo: { ...state.userInfo, data: { ...state.userInfo.data, organizations: organizations } } };
        }
      }
      return {
        ...state,
        appLanguage: action.value,
      };
    }

    case ActionAuthType.ACTION_CHANGE_APP_USER_LANGUAGE: {
      const userLanguage = action.value.users?.find((user) => user.userId === state.userInfo.data.user.id)?.language;
      if (userLanguage) {
        changeLocale(userLanguage);
        return {
          ...state,
          appLanguage: userLanguage,
        };
      }
      return {
        ...state,
      };
    }

    case ActionAuthType.ACTION_STATE_GROUPS_BY_ORGANIZATION: {
      const organizationIndex = state.userInfo.data.organizations.findIndex((org) => org.id === state.userInfo.selectedOrganization);
      const organizations = structuredClone(state.userInfo.data.organizations);
      organizations[organizationIndex] = {
        ...organizations[organizationIndex],
        groups: action.value,
      };

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            ...state.userInfo.data,
            organizations: organizations,
          },
        },
      };
    }

    case ActionAuthType.ACTION_GET_LAST_USERS_LOADING: {
      return { ...state, getLastUsers: { ...state.getLastUsers, loading: true, error: false, ok: false } };
    }

    case ActionAuthType.ACTION_GET_LAST_USERS_SUCCESS: {
      return {
        ...state,
        getLastUsers: { ...state.getLastUsers, data: action.value, loading: false, error: false, ok: true },
      };
    }

    case ActionAuthType.ACTION_GET_LAST_USERS_ERROR: {
      return { ...state, getLastUsers: { ...state.getLastUsers, loading: false, error: true, ok: false } };
    }

    case ActionAuthType.RESEND_PENDING_INVITATION_LOADING: {
      return { ...state, resendPendingInvitation: { loading: true, error: false } };
    }

    case ActionAuthType.RESEND_PENDING_INVITATION_SUCCESS: {
      return { ...state, resendPendingInvitation: { loading: false, error: false } };
    }

    case ActionAuthType.RESEND_PENDING_INVITATION_ERROR: {
      return { ...state, resendPendingInvitation: { loading: false, error: true } };
    }

    default: {
      return { ...state };
    }
  }
};
