/* eslint-disable no-useless-escape */
export const MAX_SIZE_IMAGE_USER_PROFILE = 1048576;

export const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$¡!%*¿?&.,;\^\-\+])[A-Za-z\d#@$¡!%*¿?&.,;\^\-\+]{8,}$/;
export const regexInteger = /^\d+$/;
export const regexPhone = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
export const regexNumber = /^\d*([,|.]\d{1})?\d{0,1}$/;
export const regexEmailFormat =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const SIZE_DESKTOP = 1024;
export const SIZE_MOBILE = 768;
export const BLOB_SIZE_AZURE_UPLOAD = 4 * 1024 * 1024;
export const CONCURRENCY_AZURE_UPLOAD = 20;
export const DEFAULT_IMAGE =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiByeD0iOCIgZmlsbD0iIzQ1RTlBRCIvPgo8cGF0aCBkPSJNNDYuOTc3OCAzMi45OTAzTDQ3LjY3MzMgMzYuMzQyNkM0OC44NTkyIDM2LjA2OSA1MC4wOTY0IDM1LjkyMDcgNTEuMzYyIDM1LjkyMDdDNTIuNDg1MiAzNS45MjA3IDUzLjU4NTUgMzYuMDM0OCA1NC42NDU5IDM2LjI1MTRMNTUuMzQxNSAzMi44OTkxQzU0LjA1MyAzMi42MzY4IDUyLjcyNDYgMzIuNSA1MS4zNjIgMzIuNUM0OS44NTY5IDMyLjUgNDguMzkxNyAzMi42NzEgNDYuOTc3OCAzMi45OTAzWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTYyLjc4MjMgMzcuNTQ1N0w2MS40MTk3IDM5LjM3MDFDNjIuNzMxIDQwLjM3MzUgNjMuODgyNiA0MS41NzA4IDY0Ljg0MDQgNDIuOTIyTDY2LjY3MDUgNDEuNTU5NEM2NS41ODE2IDQwLjAzNzIgNjQuMjcwMyAzOC42ODAzIDYyLjc4MjMgMzcuNTQ1N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik01MS4xNTE1IDM3LjI2NjRDNDcuMDUyMyAzNy4yNjY0IDQzLjIxNTQgMzguODg1NiA0MC4zNDc3IDQxLjgyMTdMNDAuMjE2NiA0MS45NTg1TDQzLjMxOCA0NS43NjEzTDQzLjQ4MzQgNDUuNTczMUM0NS40Mzg5IDQzLjM3MjQgNDguMjMyNSA0Mi4xMTI1IDUxLjE1NzIgNDIuMTEyNUM1Ni44MTg1IDQyLjExMjUgNjEuNDI1MSA0Ni43MTkxIDYxLjQyNTEgNTIuMzgwNEM2MS40MjUxIDU4LjA0MTcgNTYuODE4NSA2Mi42NDgzIDUxLjE1NzIgNjIuNjQ4M0M0NS40OTU5IDYyLjY0ODMgNDEuMDA5IDU4LjE2MTQgNDAuODg5MyA1Mi42MDI3SDQzLjg0MjVMMzguNTM0NyA0NC42ODk0TDMzLjMyOTUgNTIuNjA4NEgzNi4wMzc2QzM2LjE1NzMgNjAuODQxIDQyLjg5MDQgNjcuNSA1MS4xNTE1IDY3LjVDNTkuNDEyNiA2Ny41IDY2LjI2NTQgNjAuNzIxMiA2Ni4yNjU0IDUyLjM4NjFDNjYuMjY1NCA0NC4wNTA5IDU5LjQ4NjcgMzcuMjcyMSA1MS4xNTE1IDM3LjI3MjFWMzcuMjY2NFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=";
export const URL_DO_NOT_RENEW_SUBSCRIPTION = "https://longomatch.com/en/profile/delete_profile/";
export const URL_LICENSES_INFO = "https://longomatch.com/es/download/";
export const STATUS_INVITATION_REQUESTED = "Requested";
export const STATUS_INVITATION_COMPLETED = "Completed";
export const FILE_PENDING_DELETE = "PendingDelete";
export const ACCEPT_FILES_CONTENT_DOCUMENTS =
  "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*, audio/* ";
export const ICON_DOCUMENT_COLOR = "bg-document";
export const ICON_VIDEO_COLOR = "bg-video";
export const ICON_DASHBOARD_COLOR = "bg-dashboard";
export const ICON_TEMPLATE_COLOR = "bg-template";
export const ICON_PROJECT_COLOR = "bg-project";
export const ICON_PLAYLIST_COLOR = "bg-playlist";
export const BACKGROUND_COLOR_TIMELINE_ACTIVE = "#45e9ad1a";
export const MAX_LENGTH_TAG = 25;
