import React from "react";
import DocumentDto from "../../model/dto/DocumentDto";
import { Draggable } from "react-beautiful-dnd";
import LmDocument from "./LmDocument";

interface IDocument {
  data: DocumentDto;
  index: number;
  selected: boolean;
  onSelect(documentId: string, select: boolean): void;
  onEdit(data: DocumentDto): void;
}

const LmDocumentDraggable: React.FC<IDocument> = ({ data, index, selected, onSelect, onEdit }) => {
  return (
    <Draggable key={data.id} draggableId={data.id} index={index}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <LmDocument data={data} selected={selected} onSelect={onSelect} onEdit={onEdit} />
          </div>
        );
      }}
    </Draggable>
  );
};

export default LmDocumentDraggable;
