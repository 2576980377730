import { Clip } from "../../model/dto/ProjectDto";
import { convertMilisecondsToHoursMinutesSeconds } from "../../utils/utils";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as FileVideo } from "../../assets/icons/film.svg";
import LmIcon from "../icon/LmIcon";
import { useDispatch } from "react-redux";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentDto from "../../model/dto/DocumentDto";

interface Props {
  clip: Clip;
  playlist?: DocumentDto;
  showRemoveIcon?: boolean;
  active: boolean;
  viewTimeLine(timeline: any): void;
}

const LmPlaylistItem = ({ clip, playlist, showRemoveIcon = false, active, viewTimeLine }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleOnDelete = () => {
    setOpenModalDelete(true);
  };

  const onDeleteClip = () => {
    dispatch({
      type: ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_LOADING,
      value: { clipId: clip.id, playlistId: clip.relatedFileId, lastClip: playlist?.clips.length === 1},
    });    

    setOpenModalDelete(false);
  };

  const onViewTimeLine = () => {
    viewTimeLine({
      $id: clip.id,
      Start: clip.from,
      Stop: clip.to,
      Drawings: [],
    });
  };

  const messageDeleteVideoclip = playlist?.clips.length === 1 ? t("playlist.messageConfirmDeleteClipDescription2") : t("playlist.messageConfirmDeleteClipDescription")

  return (
    <div className={`flex items-center gap-2 px-5 py-3 border-t border-neutral-200 ${active ? 'bg-[#45e9ad1a]' : ''}`}>
      <div className="flex items-center"></div>
      <div className="flex items-center gap-2 cursor-pointer" onClick={onViewTimeLine}>
        <div className="bg-video p-2 rounded">
          <LmIcon icon={<FileVideo />} size="base" />
        </div>
        <div>
          <div className="flex items-center gap-1">
            <p className="text-xs text-decorative font-semibold">
              <span>{clip.eventName}</span>
            </p>
            {clip.eventName && <div className="h-2 w-2 rounded-sm bg-primary" style={{ background: clip.color }}></div>}
          </div>
          <p className="text-xs text-subtle">
            {convertMilisecondsToHoursMinutesSeconds(clip?.from)} - {convertMilisecondsToHoursMinutesSeconds(clip?.to)}
          </p>
        </div>
      </div>
      {showRemoveIcon && <LmIcon icon={<Trash />} size="xs" className="ml-auto cursor-pointer hover:opacity-70 transition" clickAction={handleOnDelete} />}
      <LmModalConfirm
        id="test-modal-confirm-remove-playlist-clip"
        title={t("playlist.messageConfirmDeleteClipTitle")}
        description={messageDeleteVideoclip}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalDelete}
        handleClose={() => setOpenModalDelete(false)}
        confirm={onDeleteClip}></LmModalConfirm>
    </div>
  );
};

export default LmPlaylistItem;
