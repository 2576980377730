import { Badge, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as Sort } from "../../assets/icons/sort.svg";
import LmButton from "../../components/button/LmButton";
import LmCheckBox from "../../components/check-box/LmCheckBox";
import { default as Empty } from "../../components/empty/empty";
import LmNotificationsEdition from "../../components/notifications-edition/NotificationsEdition";
import LmNotificationsList from "../../components/notifications-list/NotificationsList";
import LmNotificationsFilter from "../../components/notificationsFilter/LmNotificationsFilter";
import { default as useNotifications } from "../../hooks/useNotifications";
import { DateRange } from "../../model/DateRange";
import { NotificationsSorterOptions } from "../../model/NotificationsSorterOptions";
import { SizeScreen } from "../../model/SizeSreen";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { ActionNotificationsType } from "../../model/actions/typeNotifications";
import { IdValue } from "../../model/forms/IdValue";
import { AuthState } from "../../model/states/AuthState";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import { NotificationsState } from "../../model/states/NotificationsState";
import { getNotificationsType, getReadType } from "../../utils/utils";

function Notifications() {
  const dispatch = useDispatch();
  const { uncheckAllNotifications, checkAllNotifications } = useNotifications();
  const { t } = useTranslation();
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);
  const [sorterOption, setSorterOption] = useState<NotificationsSorterOptions>(NotificationsSorterOptions.mostRecent);
  const checkCondition: boolean = stateNotifications.selectedNotifications.length > 0;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const notificationsTypeOptions = getNotificationsType();
  const readTypeOptions = getReadType();
  const [badgeFilter, setBadgeFilter] = useState(0);
  const [notificationsType, setNotificationsType] = useState<IdValue[]>([]);
  const [readType, setReadType] = useState<IdValue[]>([]);
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-filter-pop-over" : undefined;

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_LOADING });
    clearFilter();
  }, [stateAuth.appLanguage]);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: true, showLateralMenu: true } });
    clearFilter();
  }, []);

  useEffect(() => {
    clearFilter();
    uncheckAllNotifications();
  }, [stateAuth.userInfo.selectedOrganization]);

  const onClickSorter = () => {
    const { mostRecent, lastRecent } = NotificationsSorterOptions;
    setSorterOption((prevValue) => (prevValue === mostRecent ? lastRecent : mostRecent));
  };

  const onSelectAll = () => {
    if (checkCondition) {
      uncheckAllNotifications();
    } else {
      checkAllNotifications();
    }
  };

  const clearFilter = () => {
    setBadgeFilter(0);
    setReadType([]);
    setNotificationsType([]);
    setDateRange({ start: "", end: "" });

    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_CLEAN_NOTIFICATIONS_FILTER });

    setAnchorEl(null);
  };

  const onSearch = (readType: IdValue[], notificationsType: IdValue[], dateRange: DateRange) => {
    uncheckAllNotifications();
    let num = 0;
    if (readType.length) num += 1;
    if (notificationsType.length) num += 1;
    if (dateRange.start || dateRange.end) num += 1;

    if (num > 0) setBadgeFilter(num);

    setAnchorEl(null);
    const notificationTypeFilter = notificationsType.length > 0 ? notificationsType.map((item) => item.id) : notificationsTypeOptions.map((type) => type.id);
    const readTypeFilter = readType.length > 0 ? readType.map((item) => item.id) : readTypeOptions.map((type) => type.id);

    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_CHANGE_DATE_RANGE_FILTER, value: dateRange });
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_CHANGE_NOTIFICATION_TYPE_FILTER, value: notificationTypeFilter });
    dispatch<TypeActions>({ type: ActionNotificationsType.ACTION_CHANGE_READ_TYPE_FILTER, value: readTypeFilter });
  };

  return (
    <div className="px-5 lg:px-12 py-5 mt-20 lg:mt-0" data-testid="page-notifications">
      <div className="flex flex-wrap justify-between items-center gap-4 mb-5">
        <p className="font-decorative">{t("notifications.notificationsTitle")}</p>

        {stateMain.sizeScreen !== SizeScreen.MOBILE && (
          <div className="flex gap-2">
            {badgeFilter ? (
              <Badge
                sx={{ ".MuiBadge-badge": { backgroundColor: "#e89633", minWidth: "20px", height: "20px", padding: "0", top: "0px", right: "3px" } }}
                overlap="circular"
                badgeContent={badgeFilter}>
                <LmButton
                  isDisabled={!stateNotifications.notificationsList?.length}
                  styleButton="secondary"
                  buttonType="submit"
                  type="solid"
                  size="small"
                  text={t("notifications.filters")}
                  icon={<Filter />}
                  iconPosition="center"
                  clickAction={handleClick}
                />
              </Badge>
            ) : (
              <LmButton
                isDisabled={!stateNotifications.notificationsList?.length}
                styleButton="secondary"
                buttonType="submit"
                type="solid"
                size="small"
                text={t("notifications.filters")}
                icon={<Filter />}
                iconPosition="center"
                clickAction={handleClick}
              />
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}>
              <LmNotificationsFilter
                onSearch={onSearch}
                onClearFilter={clearFilter}
                notificationsType={notificationsType}
                setNotificationsType={setNotificationsType}
                readType={readType}
                setReadType={setReadType}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Popover>
            <LmButton
              isDisabled={!stateNotifications.notificationsList?.length}
              styleButton="secondary"
              buttonType="submit"
              type="solid"
              size="small"
              text={sorterOption === NotificationsSorterOptions.mostRecent ? t("notifications.mostRecent") : t("notifications.lessRecent")}
              icon={<Sort />}
              iconPosition="center"
              clickAction={onClickSorter}
            />
          </div>
        )}
      </div>
      {stateNotifications.notificationsList?.length ? (
        <>
          <div className="flex flex-wrap justify-between items-center gap-4 mb-5">
            {stateNotifications.filteredNotificationsList.length ? (
              <div className="flex items-center gap-0 lg:gap-2 md:ml-3">
                <LmCheckBox value={checkCondition} changeValue={onSelectAll}></LmCheckBox>
              </div>
            ) : (
              <div />
            )}

            {stateMain.sizeScreen == SizeScreen.MOBILE && (
              <div className="flex gap-2">
                {badgeFilter ? (
                  <Badge
                    sx={{ ".MuiBadge-badge": { backgroundColor: "#e89633", minWidth: "20px", height: "20px", padding: "0", top: "0px", right: "3px" } }}
                    overlap="circular"
                    badgeContent={badgeFilter}>
                    <LmButton
                      styleButton="secondary"
                      buttonType="submit"
                      type="solid"
                      size="extra-small"
                      iconSize="sm"
                      icon={<Filter />}
                      iconPosition="center"
                      clickAction={handleClick}
                    />
                  </Badge>
                ) : (
                  <LmButton
                    styleButton="secondary"
                    buttonType="submit"
                    type="solid"
                    size="extra-small"
                    iconSize="sm"
                    icon={<Filter />}
                    iconPosition="center"
                    clickAction={handleClick}
                  />
                )}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}>
                  <LmNotificationsFilter
                    onSearch={onSearch}
                    onClearFilter={clearFilter}
                    notificationsType={notificationsType}
                    setNotificationsType={setNotificationsType}
                    readType={readType}
                    setReadType={setReadType}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </Popover>
                <LmButton
                  styleButton="secondary"
                  buttonType="submit"
                  type="solid"
                  size="extra-small"
                  iconSize="sm"
                  icon={<Sort />}
                  iconPosition="center"
                  clickAction={onClickSorter}
                />
              </div>
            )}
          </div>
          <LmNotificationsList sorterOption={sorterOption} />
        </>
      ) : (
        <div className="col-span-full row-span-full">
          <Empty text={t("notifications.emptyNotifications")} />
        </div>
      )}
      {stateNotifications.selectedNotifications?.length > 1 && <LmNotificationsEdition selectedNum={stateNotifications.selectedNotifications.length} />}
    </div>
  );
}
export default Notifications;
