import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ProjectCategoryDto } from "../../model/dto/ProjectDto";

interface Props {
  category: ProjectCategoryDto;
  categoryNavigation: (categoryName: string) => void;
}

const MatchStatisticGraph = ({ category, categoryNavigation }: Props) => {
  const [totalOptions, setTotalOptions] = useState<ApexOptions>();
  const [totalSeries, setTotalSeries] = useState<ApexOptions["series"]>();

  const [resultsOptions, setResultsOptions] = useState<ApexOptions>();
  const [resultsSeries, setResultsSeries] = useState<ApexOptions["series"]>();

  useEffect(() => {
    if (category) {
      const { tags } = category;
      const title = tags ? tags[0]?.Group : "";
      const values = tags?.map((tag) => tag.Value);
      const timelineTags = category.timelines.map((t) => t.Tags).flat();
      const resultSeries = values?.map((value) => timelineTags.filter((t) => t.Group === title && t.Value === value).length);
      if (resultSeries?.length) {
        setResultsOptions({
          labels: values?.map((val: string, index: number) => `${val}: ${resultSeries[index]}`),
          chart: {
            events: {
              legendClick: (chart: any, seriesIndex?: number) => {
                if (values?.length) {
                  const value = values[seriesIndex ?? -1];
                  categoryNavigation(category.name);
                }
              },
            },
          },
        });
        setResultsSeries(resultSeries);
      }

      const totalValues = category.timelines
        .map((t) => t.Tags)
        .flat()
        .filter((t) => !t.Group)
        .map((t) => t.Value) as string[];
      const distinctValues = [...new Set(totalValues)] as string[];

      const totalSeries = distinctValues?.map((value: string) => totalValues.filter((t: string) => t === value).length);
      if (totalSeries?.length) {
        setTotalOptions({
          labels: distinctValues?.map((val: string, index: number) => `${val}: ${totalSeries[index]}`),
        });
        setTotalSeries(totalSeries);
      }
    }
  }, [category]);

  return (
    <div className="p-4 border-t border-neutral-200">
      <div className="grid 2xl:grid-cols-2">
        <div className="max-w-full">{totalSeries?.length && <Chart options={totalOptions} series={totalSeries} type="pie" />}</div>
        <div className="max-w-full">{resultsSeries?.length && <Chart options={resultsOptions} series={resultsSeries} type="pie" />}</div>
      </div>
    </div>
  );
};

export default MatchStatisticGraph;
