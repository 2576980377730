import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { useTranslation } from "react-i18next";
import { LoginForm } from "../../model/forms/LoginForm";
import LmButton from "../button/LmButton";
import LmIcon from "../icon/LmIcon";
import { loginInitialValues, loginValidationSchema } from "./LmLoginForms.data";
import { ReactComponent as InputError } from "../../assets/icons/input-error.svg";
import { Checkbox, FormControlLabel } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ROUTE_RESTORE_PASSWORD } from "../../routes/routes";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";

export function LmLoginForm() {
  const state: AuthState = useSelector((state: MainState) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: loginInitialValues(),
    validationSchema: loginValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = async (formValue: LoginForm) => {
    dispatch<TypeActions>({
      type: ActionAuthType.ACTION_LOGIN_LOADING,
      value: formValue,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHECK_STAY_LOGGED, value: event.target.checked });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full max-w-sm" data-testid="form-login">
        <div>
          <input
            id="email"
            data-testid="email"
            name="email"
            placeholder={`${t("formLabel.email")}`}
            className={`w-full text-lg leading-7 p-4 rounded bg-white/80 ${formik.errors.email ? "border border-solid border-error-500" : ""}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email && (
            <div className="flex items-center gap-2 my-3" data-testid="error-email">
              <LmIcon icon={<InputError />} size="xs" />
              <span className="text-error-500">{formik.errors.email}</span>
            </div>
          )}
        </div>
        <div>
          <input
            type="password"
            id="password"
            data-testid="password"
            name="password"
            placeholder={`${t("formLabel.password")}`}
            className={`w-full text-lg leading-7 p-4 rounded bg-white/80 ${formik.errors.password ? "border border-solid border-error-500" : ""}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.errors.password && formik.touched.password && (
            <div className="flex items-center gap-2 my-3" data-testid="error-password">
              <LmIcon icon={<InputError />} size="xs" />
              <span className="text-error-500">{formik.errors.password}</span>
            </div>
          )}
        </div>
        <div className="flex flex-wrap justify-between items-center">
          <FormControlLabel
            className="text-white font-brand text-sm"
            control={<Checkbox data-testid="stay-logged" onChange={handleChange} sx={{ color: "#ffffff", "&.Mui-checked": { color: "#45E9AD" } }} />}
            label={t("login.stayLogged")}
            sx={{ ".MuiFormControlLabel-label": { fontFamily: "var(--bv-font-family-brand), sans-serif", fontSize: "0.875rem" } }}
          />
          <NavLink className={"text-white text-sm cursor-pointer"} to={ROUTE_RESTORE_PASSWORD} data-testid="navigate-forgotPassword">
            <span>{t("login.forgotPassword")}</span>
          </NavLink>
        </div>
        <LmButton
          styleButton="primary"
          testid="login-button"
          type="solid"
          buttonType="submit"
          size="medium"
          text={`${t("login.button")}`}
          iconPosition="right"
          isDisabled={
            !formik.isValid || state.login?.loading || state.userInfo?.loading || (formik.values.email === "" && formik.values.password === "")
          }></LmButton>
      </form>
    </>
  );
}
