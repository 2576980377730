import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { NotificationsState } from "../../model/states/NotificationsState";
import { t } from "i18next";
import { formatDateAgo } from "../../utils/utils";
import Loading from "../loading/Loading";
import NotificationsListItem from "../notifications-list-item/NotificationsListItem";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import useNotifications from "../../hooks/useNotifications";
import NotificationDto from "../../model/dto/NotificationDto";
import EmptyNotifications from "../../assets/img/empty-notifications.png";
import EmptyDashboard from "../empty/emptyDashboard";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionNotificationsType } from "../../model/actions/typeNotifications";

const LmLastNotifications = () => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);
  const notificationsShow = useMemo(
    () => stateNotifications.notificationsList.filter((notification) => !notification.readAt).filter((not, index) => index < 3),
    [stateAuth.userInfo.selectedOrganization, stateNotifications.notificationsList]
  );
  const { deleteNotification } = useNotifications();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<NotificationDto>();
  const dispatch = useDispatch();


  useEffect(() => {
    if (stateAuth.userInfo.selectedOrganization) {
      dispatch<TypeActions>({
        type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_LOADING
      });
    }    
  }, [stateAuth.userInfo.selectedOrganization]);

  const onDeleteNotification = () => {
    deleteNotification([selectedNotification?.id ?? ""]);
    setShowModalConfirm(false);
  };

  const onClickDelete = (notification: NotificationDto) => {
    setSelectedNotification(notification);
    setShowModalConfirm(true);
  };

  return (
    <>        
        { stateNotifications.getNotifications.loading ? (
            <Loading />
        ) : notificationsShow.length ? (
            <div className="border border-neutral-200 rounded p-5 mb-5">
                {notificationsShow.map((notification) => (
                    <NotificationsListItem
                        key={notification.id}
                        id={notification.id}
                        avatarImage={notification.avatarImage}
                        title={notification.type?.title || ""}
                        description={`${notification.details ?? ""} · ${formatDateAgo(notification.createdAt, stateAuth.appLanguage)}`}
                        read={false}
                        date={notification.createdAt}
                        summary={true}
                        actionDelete={() => onClickDelete(notification)}
                    />
                ))}
            </div>
        ) : (
            <div>
                <EmptyDashboard text={t("content.emptyCategory")} img={EmptyNotifications} />
            </div>
        )}
        <LmModalConfirm
            id="test-modal-confirm-remove-notification"
            title={t("notifications.deleteNotificationTitle")}
            textConfirm={t("modalConfirm.textConfirm")}
            textCancel={t("modalConfirm.textCancel")}
            open={showModalConfirm}
            handleClose={() => setShowModalConfirm(false)}
            confirm={onDeleteNotification}
        />
    </>
  );
};

export default LmLastNotifications;
