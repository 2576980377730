import LmIcon from "../icon/LmIcon";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Mark } from "../../assets/icons/check-circle.svg";
import LmAvatar from "../avatar/LmAvatar";
import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import LmCheckBox from "../check-box/LmCheckBox";
import { formatDateAgo, getNotificationsType } from "../../utils/utils";
import useNotifications from "../../hooks/useNotifications";
import { useTranslation } from "react-i18next";
import { AuthState } from "../../model/states/AuthState";

interface Props {
  id: string;
  avatarImage?: string;
  title: string;
  description: string;
  tag?: string;
  date: string;
  read: boolean;
  isChecked?: boolean;
  summary?: boolean;
  externalLink?: string | null;
  actionMark?(): void;
  actionDelete?(): void;
}

const NotificationsListItem = ({
  id,
  avatarImage,
  title,
  description,
  tag,
  date,
  read,
  isChecked,
  summary = false,
  externalLink,
  actionMark,
  actionDelete,
}: Props) => {
  const { checkNotification, uncheckNotification } = useNotifications();
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const { t } = useTranslation();
  const onCheckNotification = () => {
    if (isChecked) {
      uncheckNotification(id);
    } else {
      checkNotification(id);
    }
  };

  const getNotificationTag = () => {
    const allTypes = getNotificationsType();
    return allTypes.find((type) => type.id === tag)?.value;
  };

  return (
    <div
      data-testid={`notification-list-item-${id}`}
      className={`flex items-center gap-4 py-2 ${!summary && "md:py-4 md:px-3 md:border-b border-neutral-200"}`}>
      <div className={`flex items-center md:gap-2 ${!summary && "md:w-1/2 lg:w-2/3"}`}>
        {!summary && <LmCheckBox value={isChecked} changeValue={onCheckNotification}></LmCheckBox>}
        <LmAvatar src={avatarImage} badge={read ? undefined : "miniBadge"} radius="0.75rem" />
        <div className="ml-2">
          {externalLink ? (
            <a href={externalLink} className="font-semibold text-decorative text-sm underline">
              {title}
            </a>
          ) : (
            <p className="font-semibold text-decorative text-sm">{title}</p>
          )}

          <p className="text-subtle text-sm">{description}</p>
        </div>
      </div>

      <div className="flex items-center gap-4 xl:gap-8 ml-auto">
        {!summary && (
          <div>
            {stateMain.sizeScreen !== SizeScreen.MOBILE && (
              <div className="flex justify-end w-auto lg:w-36">
                <p className="text-xs text-decorative text-center px-2 py-1 rounded-full bg-orgColor text-white">{getNotificationTag()}</p>
              </div>
            )}
          </div>
        )}
        {!summary && (
          <div>
            {stateMain.sizeScreen !== SizeScreen.MOBILE && (
              <div className="flex justify-end w-auto lg:w-24">
                <p className="block text-sm text-subtle text-right">{formatDateAgo(date, stateAuth.appLanguage)}</p>
              </div>
            )}
          </div>
        )}
        <div className="flex items-center gap-2 md:gap-4">
          {actionMark && (
            <LmIcon
              className={`cursor-pointer hover:opacity-80 transition ${read ? "opacity-100" : "opacity-30"}`}
              icon={<Mark />}
              size="sm"
              tooltip={read ? `${t("notifications.unmark")}` : `${t("notifications.mark")}`}
              clickAction={actionMark}
            />
          )}
          {actionDelete && (
            <LmIcon
              className="cursor-pointer hover:opacity-80 transition"
              icon={<Trash />}
              size="sm"
              clickAction={actionDelete}
              tooltip={`${t("notifications.delete")}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsListItem;
