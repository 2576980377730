import { ContentManagementState, ContentManagementTabs } from "../../model/states/ContentManagementState";

export const contentManagementState: ContentManagementState = {
  typesOfFiles: {
    documents: {
      loading: false,
      error: false,
      ok: false,
      data: [],
      order: "asc",
      teamSelected: "",
      stateCategory: [],
    },
    videos: {
      loading: false,
      error: false,
      ok: false,
      data: [],
      order: "asc",
      teamSelected: "",
      stateCategory: [],
    },
    playlists: {
      loading: false,
      error: false,
      ok: false,
      data: [],
      order: "asc",
      teamSelected: "",
      stateCategory: [],
    },
    dashboards: {
      loading: false,
      error: false,
      ok: false,
      data: [],
      order: "asc",
      teamSelected: "",
      stateCategory: [],
    },
    templates: {
      loading: false,
      error: false,
      ok: false,
      data: [],
      order: "asc",
      teamSelected: "",
      stateCategory: [],
    },
    projects: {
      loading: false,
      error: false,
      ok: false,
      data: [],
      order: "asc",
      teamSelected: "",
      stateCategory: [],
    },
  },
  changeFileCategory: {
    loading: false,
    error: false,
    ok: false,
    data: [],
  },
  addTagToFile: {
    loading: false,
    error: false,
    ok: false,
    data: [],
  },
  editCategory: {
    loading: false,
    error: false,
    ok: false,
  },
  shareFile: {
    loading: false,
    error: false,
    ok: false,
  },
  unShareFile: {
    loading: false,
    error: false,
    ok: false,
  },
  deleteCategory: {
    loading: false,
    error: false,
    ok: false,
  },
  deleteFiles: {
    loading: false,
    error: false,
    ok: false,
  },
  categories: {
    loading: false,
    error: false,
    ok: false,
    data: [],
  },
  tags: {
    loading: false,
    error: false,
    ok: false,
    data: [],
    private: [],
  },
  uploadDocument: {
    loading: false,
    error: false,
    ok: false,
    data: null,
  },
  uploadToAzure: false,
  uploadLink: {
    loading: false,
    error: false,
    ok: false,
    data: null,
  },
  contentSelected: 0,
  filesSelected: [],
  document: {
    loading: false,
    error: false,
    ok: false,
    data: null,
  },
  documentUrl: {
    loading: false,
    error: false,
    ok: false,
    url: "",
  },
  comments: {
    loading: false,
    error: false,
    ok: false,
    data: [],
    lastComments: [],
    parentComment: null,
  },
  matchStatistics: {
    loading: false,
    error: false,
    ok: false,
    data: null,
    eventsCategory: [],
  },
  lastDocuments: {
    loading: false,
    error: false,
    ok: false,
    data: [],
  },
  lastProjects: {
    loading: false,
    error: false,
    ok: false,
    data: [],
  },
  project: {
    data: null,
    eventsCategory: [],
    eventsPlayer: [],
  },
  grantAccess: {
    loading: false,
    error: false,
    ok: false,
  },
  playlistByProjectSelected: {
    loading: false,
    error: false,
    ok: false,
    data: [],
  },
  selectedPlaylist: "",
  contentDetailTabSelected: ContentManagementTabs.Comments,
  playlistComments: {
    loading: false,
    error: false,
    ok: false,
    parentComment: null,
    lastComments: [],
    data: [],
  },
  createPlaylist: {
    loading: false,
    error: false,
    ok: false,
    data: null,
  },
  addClipsToPlaylist: {
    loading: false,
    error: false,
    ok: false,
    data: [],
  },
};
