import { Dialog } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Asterisk } from "../../assets/icons/asterisk.svg";
import { useTranslation } from "react-i18next";
import LmInput from "../input/LmInput";
import LmMessageError from "../message-error/LmMessageError";
import { useFormik } from "formik";
import { inviteMemberInitialValues, inviteMemberValidationSchema } from "./LmInviteMemberForm.data";
import TeamsDto from "../../model/dto/TeamDto";
import LmAvatarLabelInfo from "../avatar-label-info/LmAvatarLabelInfo";
import LmSelect from "../select/LmSelect";
import { useEffect, useState } from "react";
import { IdValue } from "../../model/forms/IdValue";
import { getRole, getRoleOptions } from "../../utils/utils";
import LmButton from "../button/LmButton";
import StaffCreateDto from "../../model/dto/StaffCreateDto";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import LmIcon from "../icon/LmIcon";
import { MainState } from "../../model/states/MainState";
import { InitialState } from "../../model/states/InitialState";

interface Props {
  open: boolean;
  teams: TeamsDto[];
  handleClose(): void;
}

const LmModalInviteMember = ({ open, handleClose, teams }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const { t } = useTranslation();
  const userLoading: boolean = useSelector((state: MainState) => state.organization.organizationUsers.loading);
  const dispatch = useDispatch();
  const roleOptions = getRoleOptions();
  const [teamsWithRoles, setTeamsWithRoles] = useState(
    teams.map((team) => {
      return { id: team.id, name: team.name, image: team.image, roles: [""] };
    })
  );

  useEffect(() => {
    formik.validateForm();
  }, []);

  const formik = useFormik({
    initialValues: inviteMemberInitialValues(),
    validationSchema: inviteMemberValidationSchema(),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = async (formValue: any) => {
    const filterTeams = teamsWithRoles.filter((team) => team.roles.length && team.roles[0] !== "");
    const data: StaffCreateDto = {
      ...formValue,
      teams: filterTeams.map((team) => {
        return { teamId: team.id, roles: team.roles };
      }),
    };

    dispatch<TypeActions>({
      type: ActionTeamType.ACTION_CREATE_STAFF_LOADING,
      value: { data: data, callBack: onClose },
    });
  };

  const onClose = () => {
    setTeamsWithRoles(
      teams.map((team) => {
        return { id: team.id, name: team.name, image: team.image, roles: [] };
      })
    );
    handleClose();
    formik.resetForm();
  };

  const onChangeRole = (options: IdValue[], teamId?: string) => {
    const updateList = structuredClone(teamsWithRoles);
    const index = updateList.findIndex((obj) => obj.id === teamId);
    updateList[index] = { ...updateList[index], roles: options.map((option) => option.id) };
    setTeamsWithRoles(updateList);
  };

  const isFormValid = () => {
    const hasOneRole = teamsWithRoles.some((team) => team.roles.length > 0 && team.roles[0] !== "");
    return formik.isValid && !userLoading && hasOneRole;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8">
        <div className="flex justify-between items-center ">
          <p className="font-decorative text-decorative text-base md:text-2xl">{t("users.inviteMemberTitle")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-new-player" />
        </div>
        <div className="mb-8">
          <p className="text-decorative text-base md:text-md">{t("users.inviteMemberSubtitle")}</p>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-lmInviteMemberForm">
          <div className="grid grid-cols-1 gap-4 w-full">
            <div>
              <LmInput
                required={true}
                id="userName"
                label={t("formLabel.name")}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.userName}></LmInput>
              {formik.touched.userName && formik.errors.userName && <LmMessageError id="userName" message={formik.errors.userName}></LmMessageError>}
            </div>
            <div>
              <LmInput
                required={true}
                id="userLastName"
                label={t("formLabel.lastName")}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.userLastName}></LmInput>
              {formik.touched.userLastName && formik.errors.userLastName && (
                <LmMessageError id="userLastName" message={formik.errors.userLastName}></LmMessageError>
              )}
            </div>
            <div>
              <LmInput
                required={true}
                id="userEmail"
                label={t("formLabel.email")}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.userEmail}></LmInput>
              {formik.touched.userEmail && formik.errors.userEmail && <LmMessageError id="userEmail" message={formik.errors.userEmail}></LmMessageError>}
            </div>
            {teamsWithRoles.length > 0 && (
              <div>
                <label className="lm-label flex items-start">
                  {`${t("users.inviteMemberTeams")} (${teamsWithRoles.length || 0})`}
                  <LmIcon icon={<Asterisk />} size="xs" />
                </label>
                {teamsWithRoles.map((team) => (
                  <div key={team.id} className="my-4 grid md:grid-cols-2 gap-2 items-center">
                    <LmAvatarLabelInfo src={team.image} label={team.name}></LmAvatarLabelInfo>
                    <div>
                      <LmSelect
                        id="roles"
                        placeholder={t("formLabel.role") as string}
                        required={true}
                        multipleCheck={true}
                        valueMultiple={getRole(team.roles)}
                        options={roleOptions}
                        readonly={true}
                        selectedValue={(options) => onChangeRole(options as IdValue[], team.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-end mt-8 space-x-2">
              <div className="add-button-container">
                <LmButton styleButton="secondary" type="solid" size="small" text={t("buttonLabel.cancel")} clickAction={onClose} />
              </div>
              <div className="add-button-container">
                <LmButton
                  isDisabled={!isFormValid()}
                  styleButton="organization"
                  type="solid"
                  size="small"
                  text={t("buttonLabel.invite")}
                  buttonType="submit"></LmButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default LmModalInviteMember;
