import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import PlayButtom from "../../assets/icons/chevron-right.svg";
import { ClipToReactPlayer } from "../../model/dto/ProjectDto";
import { getBase64Img } from "../../utils/utils";
import { ReactComponent as FullScreen } from "../../assets/icons/full-screen.svg";
import { ReactComponent as NormalScreen } from "../../assets/icons/normal-screen.svg";

interface IProjectAnalysisReactPlayer {
    video: string;
    clip: ClipToReactPlayer;
}

const ProjectAnalysisReactPlayer: React.FC<IProjectAnalysisReactPlayer> = ({ video, clip }) => {
    const [play, setPlay] = useState(false);
    
    const [drawing, setDrawing] = useState(false);
    const refVideo = useRef<any>(null);
    const [hasDrawing, setHasDrawing] = useState(false);
    const [countDrawing, setCountDrawing] = useState(0);
    const [miniature, setMiniature] = useState("");
    const [fullScreen, setFullscreen] = useState<boolean>(false);
    
    useEffect(() => {
        if (clip && clip.id) {
            setPlay(true);
            const start = clip.start / 1000;
            refVideo?.current?.seekTo(start);
            setHasDrawing(!!clip.drawings.length);
            setCountDrawing(0);
            setDrawing(false);
        }
    }, [clip]);
    
    const progress = (data: any) => {
        const end = clip?.stop / 1000;
        if (clip.id !== "" && data.playedSeconds > end && data.playedSeconds < end + 1) {
            setPlay(false);
        } 
        
        if (hasDrawing && countDrawing < clip.drawings.length && data.playedSeconds > clip.drawings[countDrawing].Render / 1000) {
            setPlay(false);
            setDrawing(true);
            setMiniature(clip.drawings[countDrawing].DrawingWithoutBackground);
            refVideo?.current?.seekTo(clip.drawings[countDrawing].Render / 1000);
            if (clip.drawings.length < countDrawing + 1) {
                setDrawing(false);
            } else {
                setCountDrawing(countDrawing + 1);
            }
        }
    };
    
    const onPlay = () => {
        setDrawing(false);
        setPlay(true);
    };
    
    const onPause = () => {
        setPlay(false);
    };
    
    const goFullScreen = () => {
        setFullscreen(true);
        const document: any = window.document;
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement ||
        document.webkitFullscreenElement || document.msFullscreenElement;
        
        if (fullscreenElement){
            exitFullscreen();
        } else {
            launchIntoFullscreen(document.getElementById('container'));
        }
    }
    
    // From https://davidwalsh.name/fullscreen
    // Find the right method, call on correct element
    const launchIntoFullscreen = (element: any) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }
    
    // Whack fullscreen
    const exitFullscreen = () => {
        setFullscreen(false);
        const document: any = window.document;
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    return (
        <div id="container">
            <div className="btn-fs" onClick={goFullScreen}>
                { fullScreen ? <NormalScreen />: <FullScreen /> }
            </div>
            <ReactPlayer
                className="react-player video-player"
                url={video}
                ref={refVideo}
                playing={play}
                onProgress={progress}
                onPause={onPause}
                onPlay={onPlay}  
                muted={true}
                controls
                config={{
                    file: {
                        attributes: {
                            controlsList: "nofullscreen",
                        },
                    },
                }}
                width="100%"
                height="100%"
            />
            
            {drawing && (
                <div className="drawing">
                    <img 
                        className="drawing-image" 
                        src={getBase64Img(miniature)} 
                        height={"100%"}
                        width={"100%"}
                    ></img>
                   <img
                        className={`absolute p-2 rounded-full bg-white/50 cursor-pointer hover:bg-white/70 transition ${fullScreen ? "left-8 bottom-12": "left-3 bottom-3"}`}
                        src={PlayButtom}
                        onClick={onPlay}></img>
                </div>
            )}
        </div>    
    );
};

export default ProjectAnalysisReactPlayer;
