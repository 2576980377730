import { useNavigate } from "react-router";
import { ButtonBase, Divider, Link } from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { MainState } from "../../model/states/MainState";
import LmButton from "../button/LmButton";
import LmInput from "../input/LmInput";
import LmMessageError from "../message-error/LmMessageError";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { organizationTeamInitialValues, organizationTeamValidationSchema } from "./NewOrganizationForms.data";
import LoadImage from "../load-image/LoadImage";
import { StaffForm } from "../../model/forms/StaffForm";
import { regexEmailFormat, regexInteger } from "../../constants/globalConstanst";
import { ReactComponent as NoStaffImage } from "../../assets/img/staff.svg";
import { TeamState } from "../../model/states/TeamState";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import TeamsDto, { UpdateTeam } from "../../model/dto/TeamDto";
import { getRole, getRoleOptionsForNewOrganization, getSelectedDataById, teamFormsToDto, teamFormsWithStaffToDto } from "../../utils/utils";
import { ROUTE_PRIVATE_DASHBOARD } from "../../routes/routes";
import { teamState } from "../../store/states/teamState";
import LmSelect from "../select/LmSelect";
import { IdValue } from "../../model/forms/IdValue";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { ActionAuthType } from "../../model/actions/typeAuthActions";

const LmOrganizationTeamForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateTeam: TeamState = useSelector((state: MainState) => state.team);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganization: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const [staff, setStaff] = useState(stateTeam.teamForm.staff ?? []);

  const roleOptions = getRoleOptionsForNewOrganization();
  const formik = useFormik({
    initialValues: organizationTeamInitialValues(stateTeam),
    validationSchema: organizationTeamValidationSchema(selectedOrganization?.license?.storage ?? 0),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => onSubmit(values),
  });

  const staffLimit = selectedOrganization?.license?.maxNumberOfUsers ?? 0;

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    const { loading, ok } = stateTeam;
    if (!loading && ok) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_DASHBOARD });
      navigate("/" + ROUTE_PRIVATE_DASHBOARD);
    }
  }, [stateTeam.loading, stateTeam.ok]);

  useEffect(() => {
    dispatch<TypeActions>({
      type: ActionTeamType.ACTION_CHANGE_TEAM_FORM_VALUE,
      value: { key: "staff", value: staff },
    });
  }, [staff]);

  const onSubmit = async (formValue: any) => {
    if (selectedOrganization) {
      if (staff.length > 0) {
        const newTeam: TeamsDto = teamFormsWithStaffToDto(formValue, staff, selectedOrganization);
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_CREATE_TEAM_WITH_STAFF_LOADING,
          value: newTeam,
        });
      } else {
        const newTeam: UpdateTeam = teamFormsToDto(formValue, undefined, selectedOrganization);
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_CREATE_TEAM_LOADING,
          value: { isOrganizationAssistant: true, dataCallback: { data: newTeam, callBack: () => {} } },
        });
      }
    }
  };

  const onBlurInput = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
    const key = e.target.id;
    const value = e.target.value;
    dispatch<TypeActions>({
      type: ActionTeamType.ACTION_CHANGE_TEAM_FORM_VALUE,
      value: { key, value },
    });
  };

  const goBack = () => {
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_ACTIVE_TAB, value: 0 });
  };

  const addNewStaff = () => {
    setStaff((prevState) => [...prevState, { id: Date.now(), name: "", lastName: "", email: "", roles: [], image: "" }]);
  };

  const changeImage = (image: string, staffId: number) => {
    const aux = structuredClone(staff);
    const index = aux.findIndex((obj) => obj.id === staffId);
    aux[index].image = image;
    setStaff(aux);
  };

  const deleteStaff = (staffId: number) => {
    const aux = structuredClone(staff);
    const index = aux.findIndex((obj) => obj.id === staffId);
    aux.splice(index, 1);
    setStaff(aux);
  };

  const onChangeStaff = (key: string, event: React.ChangeEvent<HTMLInputElement>, staffId: number) => {
    const value = event.target.value;
    const aux = structuredClone(staff);
    const index = aux.findIndex((obj) => obj.id === staffId);
    if (key === "email") {
      const repeatEmail = staff.some((item) => item.email === value);
      if (repeatEmail) {
        aux[index] = { ...aux[index], emailError: true };
      } else {
        aux[index] = { ...aux[index], emailError: false };
      }
    }
    aux[index] = { ...aux[index], [key]: value };
    setStaff(aux);
  };

  const checkStaffItemError = (staff: StaffForm): boolean => {
    return (
      staff.name === "" ||
      staff.lastName === "" ||
      staff.email === "" ||
      !regexEmailFormat.test(staff.email) ||
      staff.roles.length < 1 ||
      staff.emailError === true
    );
  };

  const checkStaffError = () => {
    return staff.some((staff) => checkStaffItemError(staff));
  };

  const onChangeRole = (staffId: number, options: IdValue[]) => {
    const aux = structuredClone(staff);
    const index = aux.findIndex((obj) => obj.id === staffId);
    aux[index] = { ...aux[index], roles: options.map((option) => option.id) };
    setStaff(aux);
  };

  const onChangeStorage = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (regexInteger.test(value)) {
      formik.setFieldValue("storage", parseInt(value));
    } else if (value === "") {
      formik.setFieldValue("storage", value);
    }
  };

  const checkEmailError = (memberStaff: StaffForm) => {
    if (memberStaff.email === "") {
      return <LmMessageError message={t("errors.form.required")} />;
    } else if (!regexEmailFormat.test(memberStaff.email)) {
      return <LmMessageError message={t("errors.form.passwordFormat")} />;
    } else if (memberStaff.emailError) {
      return <LmMessageError message={t("errors.form.repeatEmail")} />;
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col w-full" data-testid="main-data-team-form">
        <div className="flex flex-wrap gap-6">
          <p className="font-decorative text-decorative w-full xl:w-32	">{t("newOrganization.teamSectionTitle")}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full xl:w-auto">
            <div>
              <LmInput
                required={true}
                id="name"
                label={t("formLabel.name")}
                changeValue={formik.handleChange}
                blur={onBlurInput}
                value={formik.values.name}
                autoComplete="off"></LmInput>
              {formik.touched.name && formik.errors.name && <LmMessageError id="name" message={formik.errors.name}></LmMessageError>}
            </div>
            <div>
              <LmInput
                required={true}
                id="category"
                label={t("formLabel.category")}
                changeValue={formik.handleChange}
                blur={onBlurInput}
                value={formik.values.category}
                autoComplete="off"></LmInput>
              {formik.touched.category && formik.errors.category && <LmMessageError id="category" message={formik.errors.category}></LmMessageError>}
            </div>
            <div>
              <LmInput
                id="storage"
                label={t("formLabel.storage")}
                changeValue={onChangeStorage}
                blur={onBlurInput}
                value={`${formik.values.storage}`}
                iconSufix={"GB"}
                autoComplete="off"></LmInput>
              {formik.touched.storage && formik.errors.storage && <LmMessageError id="storage" message={formik.errors.storage}></LmMessageError>}
            </div>
          </div>
        </div>

        <Divider sx={{ margin: "1.5rem 0" }} />

        <div className="flex flex-wrap xl:flex-nowrap gap-6">
          <div className="w-full flex justify-between xl:block xl:w-32 ">
            <div className="flex space-x-2  mb-3">
              <p className="text-subtle">
                <span className="font-decorative text-decorative">{t("newOrganization.staffSectionTitle")}</span>
                {` (${staff.length}/${staffLimit})`}
              </p>
            </div>
            <Link
              disabled={staff.length >= staffLimit}
              className="flex items-center"
              component="button"
              type="button"
              underline="none"
              color="inherit"
              onClick={addNewStaff}
              data-testid="test-add-new-staff">
              <Plus />
              <p className="text-sm font-semibold">{t("newOrganization.addStaff")}</p>
            </Link>
          </div>
          <div className="w-full xl:w-auto">
            {staff && staff.length > 0 ? (
              staff.map((member) => (
                <div
                  key={member.id}
                  className="border-b border-neutral-200 grid grid-cols-1 md:grid-cols-3 xl:flex gap-4 mb-4 pb-4 last:border-none last:mb-0 last:pb-0">
                  <div>
                    <LmInput
                      required={true}
                      id="staff-name"
                      label={t("formLabel.name")}
                      changeValue={(value) => onChangeStaff("name", value, member.id)}
                      value={member.name}
                      autoComplete="off"></LmInput>
                    {member.name === "" && <LmMessageError message={t("errors.form.required")}></LmMessageError>}
                  </div>
                  <div>
                    <LmInput
                      required={true}
                      id="staff-lastName"
                      label={t("formLabel.lastName")}
                      changeValue={(value) => onChangeStaff("lastName", value, member.id)}
                      value={member.lastName}
                      autoComplete="off"></LmInput>
                    {member.lastName === "" && <LmMessageError message={t("errors.form.required")}></LmMessageError>}
                  </div>
                  <div>
                    <LmInput
                      required={true}
                      id="staff-email"
                      label={t("formLabel.email")}
                      changeValue={(value) => onChangeStaff("email", value, member.id)}
                      value={member.email}
                      autoComplete="off"></LmInput>
                    {checkEmailError(member)}
                  </div>
                  <div>
                    <LmSelect
                      id="roles"
                      required={true}
                      label={t("formLabel.role")}
                      multipleCheck={true}
                      valueMultiple={getRole(member.roles)}
                      options={roleOptions}
                      readonly={true}
                      selectedValue={(options) => onChangeRole(member.id, options as IdValue[])}
                    />
                    {member.roles.length < 1 && <LmMessageError message={t("errors.form.required")}></LmMessageError>}
                  </div>
                  <div className={`flex items-center order-first md:order-none ${checkStaffItemError(member) ? "" : "mt-6"} space-x-3`}>
                    <LoadImage size="sm" img={member.image} id={`image${member.id}`} onChangeImage={(image) => changeImage(image, member.id)} />
                    <ButtonBase onClick={() => deleteStaff(member.id)} data-testid="test-delete-staff">
                      <Trash className="w-5" />
                    </ButtonBase>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full xl:w-[720px] p-6 text-center bg-gray-300/30">
                <NoStaffImage className="w-60 mb-4 mx-auto" />
                <p className="font-decorative text-decorative mb-2">{t("newOrganization.noStaffTitle")}</p>
                <p className="text-sm mb-6">{t("newOrganization.noStaffDescription")}</p>
                <div className="flex justify-center">
                  <LmButton styleButton="organization" type="solid" size="small" text={`${t("newOrganization.addStaff")}`} clickAction={addNewStaff}></LmButton>
                </div>
              </div>
            )}
          </div>
        </div>

        <Divider sx={{ margin: "1.5rem 0" }} />
        <div className="flex flex-wrap justify-end gap-4">
          <LmButton
            isDisabled={teamState.loading}
            styleButton="secondary"
            type="solid"
            clickAction={goBack}
            size="small"
            text={`${t("buttonLabel.goBack")}`}
            iconPosition="right"
            testid="test-go-back"></LmButton>
          <LmButton
            testid="test-button-new-team"
            isDisabled={!formik.isValid || checkStaffError() || teamState.loading}
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={`${t("buttonLabel.create")}`}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
};

export default LmOrganizationTeamForm;
