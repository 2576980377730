import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import ResponseApiError from "../../model/dto/ResponseApiError";
import { AuthState } from "../../model/states/AuthState";
import { getFetchParams } from "./utilsSaga";
import { t } from "i18next";
import { MainState } from "../../model/states/MainState";
import {
  ActionCreatePlayerLoading,
  ActionDeletePlayerLoading,
  ActionEditPlayerLoading,
  ActionPlayerByOrganizationLoading,
  ActionPlayerByOrganizationUnregisteredLoading,
  ActionPlayerType,
  TypePlayerActions,
} from "../../model/actions/typePlayerActions";
import { ActionAuthType, TypeAuthActions } from "../../model/actions/typeAuthActions";
import PlayerResultDto from "../../model/dto/PlayerResultDto";
import CountryDto from "../../model/dto/CountryDto";

const baseUrlApi = process.env.REACT_APP_API_URL + "Players";
const baseUrlCountriesApi = process.env.REACT_APP_API_URL + "Countries";

export function* createPlayer(action: ActionCreatePlayerLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "?locale=" + state.appLanguage,
      getFetchParams("POST", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: PlayerResultDto = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_CREATE_PLAYER_SUCCESS, value: true });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_CREATE_PLAYER, value: { data: data, group: action.value.groups || [] } });

      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("player.newPlayerForm.createSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_CREATE_PLAYER_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editPlayer(action: ActionEditPlayerLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value.id + "/team/" + action.value.teamId + "?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: PlayerResultDto = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_EDIT_PLAYER_SUCCESS, value: true });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_PLAYER, value: { data: data, group: action.value.groups || [] } });

      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("player.newPlayerForm.editSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_EDIT_PLAYER_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deletePlayer(action: ActionDeletePlayerLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value.playerId + "/team/" + action.value.teamId + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_DELETE_PLAYER_SUCCESS });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_DELETE_PLAYER, value: action.value });

      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("player.newPlayerForm.deleteSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_DELETE_PLAYER_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* playersByOrganization(action: ActionPlayerByOrganizationLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/byOrganizationId/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: PlayerResultDto[] = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_PLAYER_BY_ORGANIZATION, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* playersUnregisteredByOrganization(action: ActionPlayerByOrganizationUnregisteredLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/byOrganizationId/" + action.value + "/unregistered?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: PlayerResultDto[] = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_PLAYER_BY_ORGANIZATION_UNREGISTERED, value: data });
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_SUCCESS });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* countries() {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlCountriesApi + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: CountryDto[] = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_COUNTRIES_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypePlayerActions>({ type: ActionPlayerType.ACTION_COUNTRIES_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* createPlayerhatcher() {
  yield takeLatest(ActionPlayerType.ACTION_CREATE_PLAYER_LOADING, createPlayer);
}

export function* editPlayerWhatcher() {
  yield takeLatest(ActionPlayerType.ACTION_EDIT_PLAYER_LOADING, editPlayer);
}

export function* deletePlayerWhatcher() {
  yield takeLatest(ActionPlayerType.ACTION_DELETE_PLAYER_LOADING, deletePlayer);
}

export function* playersByOrganizationWhatcher() {
  yield takeLatest(ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_LOADING, playersByOrganization);
}

export function* countriesnWhatcher() {
  yield takeLatest(ActionPlayerType.ACTION_COUNTRIES_LOADING, countries);
}

export function* playersUnregisteredByOrganizationWhatcher() {
  yield takeLatest(ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING, playersUnregisteredByOrganization);
}

export default function* rootPlayerSaga() {
  yield all([
    createPlayerhatcher(),
    editPlayerWhatcher(),
    deletePlayerWhatcher(),
    playersByOrganizationWhatcher(),
    countriesnWhatcher(),
    playersUnregisteredByOrganizationWhatcher(),
  ]);
}
