import React from "react";

import { Navigate } from "react-router-dom";
import { ROUTE_LOGIN } from "./routes";

import { useSelector } from "react-redux";
import { AuthState } from "../model/states/AuthState";
import { MainState } from "../model/states/MainState";

interface Props {
  roleToAccess: string[];
  children: JSX.Element;
}

const PrivateRoute: React.FC<Props> = ({ roleToAccess, children }) => {
  const state: AuthState = useSelector((state: MainState) => state.auth);
  const role = state.login.loginResponse?.systemRole || "";

  return state.auth && roleToAccess.indexOf(role) !== -1 ? children : <Navigate to={ROUTE_LOGIN} />;
};

export default PrivateRoute;
