import { t } from "i18next";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file-pdf.svg";
import { ReactComponent as VideoIcon } from "../../assets/icons/menu-content.svg";
import { ReactComponent as FileProjectIcon } from "../../assets/icons/star.svg";
import { ReactComponent as TemplateIcon } from "../../assets/icons/template.svg";
import { ReactComponent as PlaylistIcon } from "../../assets/icons/folder.svg";
import Dashboard from "../../assets/img/dashboard.svg";
import File from "../../assets/img/file.svg";
import Project from "../../assets/img/project.png";
import Template from "../../assets/img/template.svg";
import Video from "../../assets/img/video-file.png";
import Playlist from "../../assets/img/playlist-img.svg";
import { FILE_PENDING_DELETE } from "../../constants/globalConstanst";
import DocumentDto from "../../model/dto/DocumentDto";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { formatLocaleDate, getLocaleByUserOrganization, getSelectedDataById } from "../../utils/utils";
import LmAvatar from "../avatar/LmAvatar";
import LmIcon from "../icon/LmIcon";

interface IDocument {
  data: DocumentDto;
  selected: boolean;
  isReadMode?: boolean;
  onSelect(documentId: string, select: boolean): void;
  onEdit?(data: DocumentDto): void;
}

const LmDocument: React.FC<IDocument> = ({ data, selected, onSelect, onEdit, isReadMode = false }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const [selectDocument, setSelectDocument] = useState(false);

  const team = selectedOrganizationData?.teams?.find((team) => team.id === data.teamId);

  useEffect(() => {
    setSelectDocument(selected);
  }, [selected]);

  const onSelectdocument = (e: SyntheticEvent) => {
    setSelectDocument(!selectDocument);
    onSelect(data.id, !selectDocument);
    e.stopPropagation();
  };

  const onEditDocument = () => {
    if (data.status !== FILE_PENDING_DELETE && onEdit) {
      onEdit(data);
    }
  };

  const typeFile = useMemo(() => {
    return {
      Document: {
        icon: <FileIcon />,
        name: t("content.document"),
        img: File,
      },
      Video: {
        icon: <VideoIcon />,
        name: t("content.video"),
        img: Video,
      },
      Template: {
        icon: <TemplateIcon />,
        name: t("content.template"),
        img: Template,
      },
      Dashboard: {
        icon: <DashboardIcon />,
        name: t("content.dashboard"),
        img: Dashboard,
      },
      Project: {
        icon: <FileProjectIcon />,
        name: t("content.project"),
        img: Project,
      },
      Playlist: {
        icon: <PlaylistIcon />,
        name: t("content.playlist"),
        img: Playlist,
      },
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const type = typeFile[data.type];

  const dateLocale = useMemo(()=>getLocaleByUserOrganization(stateAuth.userInfo.data.user.id, selectedOrganizationData?.users) === 'en' ? 'en-US' : 'es-ES', [stateAuth.userInfo.selectedOrganization])
  return (
    <div
      className={`border border-natural-200 rounded px-5 pt-2 pb-8 ${data.status === FILE_PENDING_DELETE ? "opacity-50" : ""} ${
        isReadMode && "cursor-pointer"
      }`}
      onClick={(e) => (isReadMode ? onSelectdocument(e) : onEditDocument())} id={data.id}>
      <div className="flex items-center mb-3">
        {data.status !== FILE_PENDING_DELETE && !isReadMode && (
          <div
            className={`w-6 h-6 border border-neutral-200 rounded-full transition ${selected && "border-4 border-primary bg-decorative"}`}
            id="select"
            onClick={onSelectdocument}></div>
        )}
        <div className="flex items-center gap-1 bg-neutral-200 rounded-2xl ml-auto px-2 py-1">
          <LmIcon icon={type.icon} size={"xs"} />
          <p className="text-sm">{type.name}</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="shrink-0">
          <img className="w-20" src={type.img}></img>
        </div>
        <div className="text-sm space-y-2">
          <p className="font-semibold flex justify-between break-all">
            <span className="line-clamp-2" title={data.fileName}>
              {decodeURIComponent(data.fileName)}
            </span>
          </p>
          <div className="flex items-center gap-1">
            <LmAvatar src={team?.image} size={20} radius="0.25rem" />
            <p>{team?.name}</p>
          </div>
          <p className="text-xs text-subtle">{formatLocaleDate(data.createdAt, dateLocale)}</p>
        </div>
      </div>
    </div>
  );
};

export default LmDocument;
