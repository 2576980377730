import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import {
  ActionOrganizationType,
  ActionUnsubscribeLoading,
  ActionCreateOrganizationLoading,
  TypeOrganizationActions,
  ActionEditOrganizationLoading,
  ActionOrganizationSumaryLoading,
  ActionDeleteOrganizationLoading,
  ActionGetOrganizationUsersLoading,
  ActionDeleteOrganizationUserLoading,
  ActionEditOrganizationUserLoading,
  ActionGetOrganizationPendingInvitationsLoading,
} from "../../model/actions/typeOrganizationActions";
import ResponseApiError from "../../model/dto/ResponseApiError";
import { AuthState } from "../../model/states/AuthState";
import { getFetchParams } from "./utilsSaga";
import { t } from "i18next";
import { MainState } from "../../model/states/MainState";
import OrganizationDto from "../../model/dto/OrganizationDto";
import OrganizationSumaryDto from "../../model/dto/OrganizationSumaryDto";
import { ActionAuthType, TypeAuthActions } from "../../model/actions/typeAuthActions";
import OrganizationUserDto from "../../model/dto/OrganizationUserDto";
import { PendingInvitationsUserDto } from "../../model/dto/PendingInvitationsUserDto";

const baseUrlApi = process.env.REACT_APP_API_URL + "Organizations";

export function* createOrganization(action: ActionCreateOrganizationLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "?locale=" + state.appLanguage,
      getFetchParams("POST", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: OrganizationDto = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_CREATE_ORGANIZATION, value: data });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_CREATE_ORGANIZATION_SUCCESS, value: data });
      // yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_INFO_LOADING });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_CREATE_ORGANIZATION_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editOrganization(action: ActionEditOrganizationLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value.organizationId + "?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value.dataCallback.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: OrganizationDto = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_SUCCESS, value: data });
      // yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_INFO_LOADING });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_ORGANIZATION, value: data });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.editOrganizationSuccess"), severity: "success" } });
      yield call(action.value.dataCallback.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_ERROR, value: t("errors.server") });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteOrganization(action: ActionDeleteOrganizationLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value.id + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_DELETE_STATE_ORGANIZATION, value: action.value?.id as string });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("teams.deleteOrganizationSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_ERROR, value: t("errors.server") });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* unsubscribe(action: ActionUnsubscribeLoading) {
  try {
    const state: AuthState = yield select((state) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value + "/exit?locale=" + state.appLanguage,
      getFetchParams("PUT", null, state.login.loginResponse?.token as string)
    );
    if (response.ok) {
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("editUser.messageUnsubscribeSucess"), severity: "success" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_UNSUBSCRIBE_STATE_ORGANIZATION, value: action.value });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_SUCCESS, value: true });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_ERROR });
  }
}

export function* sumaryOrganization(action: ActionOrganizationSumaryLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: OrganizationSumaryDto = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_SUCCESS, value: data });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_ORGANIZATION, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getOrganizationUsers(action: ActionGetOrganizationUsersLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value + "/users?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: OrganizationUserDto[] = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_GET_ORGANIZATION_USERS, value: data });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_SUCCESS });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteOrganizationUser(action: ActionDeleteOrganizationUserLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value.organizationId + "/" + action.value.userId + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_DELETE_ORGANIZATION_USER, value: action.value?.userId });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("users.deleteUserSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_ERROR, value: t("errors.server") });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editOrganizationUser(action: ActionEditOrganizationUserLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value.organizationId + "/user/" + action.value.userId + "?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value.dataCallback.data, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: OrganizationUserDto = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_ORGANIZATION_USER, value: data });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("users.editSuccess"), severity: "success" } });
      yield call(action.value.dataCallback.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_ERROR });
    }
  } catch (e) {
    yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getOrganizationPendingInvitations(action: ActionGetOrganizationPendingInvitationsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value + "/pendingInvitations?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: PendingInvitationsUserDto[] = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_GET_PENDING_INVITATIONS, value: data });
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_SUCCESS });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeOrganizationActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* unsubscribeWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_AUTORIZATION_UNSUBSCRIBE_LOADING, unsubscribe);
}

export function* createOrganizationWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_CREATE_ORGANIZATION_LOADING, createOrganization);
}

export function* editOrganizationWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_EDIT_ORGANIZATION_LOADING, editOrganization);
}

export function* sumaryOrganizationWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_LOADING, sumaryOrganization);
}

export function* deleteOrganizationWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_DELETE_ORGANIZATION_LOADING, deleteOrganization);
}

export function* getOrganizationUsersWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING, getOrganizationUsers);
}

export function* deleteOrganizationUserWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_LOADING, deleteOrganizationUser);
}

export function* editOrganizationUserWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_LOADING, editOrganizationUser);
}

export function* getOrganizationPendingInvitationsWhatcher() {
  yield takeLatest(ActionOrganizationType.ACTION_GET_ORGANIZATION_PENDING_INVITATIONS_LOADING, getOrganizationPendingInvitations);
}

export default function* rootOrganizationsSaga() {
  yield all([
    unsubscribeWhatcher(),
    createOrganizationWhatcher(),
    editOrganizationWhatcher(),
    sumaryOrganizationWhatcher(),
    deleteOrganizationWhatcher(),
    getOrganizationUsersWhatcher(),
    deleteOrganizationUserWhatcher(),
    editOrganizationUserWhatcher(),
    getOrganizationPendingInvitationsWhatcher(),
  ]);
}
