import { useTranslation } from "react-i18next";
import LmAvatarLabelInfo from "../../components/avatar-label-info/LmAvatarLabelInfo";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { formatOrganizationUserDtoToUsersListForm, getAllRoleOptions, getSelectedDataById } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";
import { useDispatch, useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import "./Users.scss";
import { useEffect, useMemo, useState } from "react";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { OrganizationState } from "../../model/states/OrganizationState";

function UsersRoleFilter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const formatList = useMemo(
    () => selectedOrganizationData?.users?.map((user) => formatOrganizationUserDtoToUsersListForm(user)),
    [selectedOrganizationData?.users]
  );
  const rolesType = getAllRoleOptions();
  const [rolesFilter, setRolesFilter] = useState(rolesType.map((rol) => rol.id));
  const [numRolesType, setNumRolesType] = useState<any[]>();

  useEffect(() => {
    const NumRoles = rolesType.map((role) => {
      return {
        ...role,
        num: numRoles(role.id),
      };
    });

    setNumRolesType(NumRoles);
  }, [formatList]);

  const numRoles = (roleType: string): number => {
    let totalNum = 0;
    formatList?.map((item) => {
      [...new Set(item.rolesTotal)].includes(roleType) ? totalNum++ : totalNum;
    });

    return totalNum;
  };

  const isRolActive = (role: string): boolean => {
    return rolesFilter.includes(role);
  };

  const onSelectRol = (role: string) => {
    let auxRolesFilter = [];
    if (rolesFilter.length > 1 || !isRolActive(role)) {
      auxRolesFilter = [role];
      setRolesFilter(auxRolesFilter);
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_FILTER });
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_USER_QUICK_FILTER, value: auxRolesFilter });
    } else {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_QUICK_FILTER });
    }
  };

  useEffect(() => {
    if (!stateOrganization.organizationUsers.quickRoles) {
      setRolesFilter(rolesType.map((rol) => rol.id));
    }
  }, [stateOrganization.organizationUsers.quickRoles]);

  return (
    <div className="bg-neutral-50 rounded px-6 py-4">
      <div className="flex gap-6">
        {numRolesType?.length &&
          numRolesType.map((rolType) => (
            <div key={rolType.id} className={`opacity-50 cursor-pointer transition ${isRolActive(rolType.id) ? "user-role-item--active" : ""}`}>
              <LmAvatarLabelInfo
                key={rolType.id}
                src={rolType.image}
                label={rolType.value}
                info={`${rolType.num} ${t("summary.users")}`}
                onSelect={() => onSelectRol(rolType.id)}></LmAvatarLabelInfo>
            </div>
          ))}
      </div>
    </div>
  );
}
export default UsersRoleFilter;
