export const eng = {
  title: "Longomatch - en",
  login: {
    title: "Welcome to the video analysis software that helps coaches, analysts and athletes",
    stayLogged: "Stay logged in",
    forgotPassword: "Forgot your password?",
    button: "Login",
  },
  restorePassword: {
    title: "Restore password",
    subtitle1: "Enter your email address that you used in registration.",
    subtitle2: "We will send you an email with your username and a link so you can reset your password.",
    sendButton: "Send",
    backButton: "Back to login",
  },
  register: {
    passwordInfoTitle: "Characteristics of strong passwords",
    passwordInfo1: "At least 8 characters",
    passwordInfo2: "A mix of uppercase and lowercase letters",
    passwordInfo3: "A mix of letters and numbers ",
    passwordInfo4: "Contain at least one special character (# . , ? ! @ $ % ^ & * -)",
    button: "Login",
    resetPassword: "Confirm Password",
  },
  licenseSelector: {
    title: "Welcome",
    subtitle: "Select the license with which you want to access the application",
    teams: "Teams",
    createOrganization1: "Create your organization for your",
    createOrganization2: "license purchased on",
  },
  menu: {
    home: "Home",
    users: "User Management",
    teams: "Team Management",
    content: "Content Management",
    notifications: "Notifications",
    logOut: "Log Out",
  },
  topMenu: {
    editProfile: "Edit Profile",
    editPassword: "Edit Password",
    altIconUser: "User Avatar",
  },
  pager: {
    of: "of",
  },
  editUser: {
    buttonCancel: "Cancel",
    buttonSave: "Save",
    unsubscribe: "Unsubscribe from organization",
    title: "Edit Profile",
    mentioned: "When I am mentioned",
    shared: "When content is shared with me",
    added: "When a comment is added to a content on my team",
    titleNotifications: "Receive notifications via email when:",
    messagePasswordChanged: "Password successfully changed",
    messagePasswordCreate: "Password successfully created",
    editPasswordTitle: "Edit Password",
    unsubscribeTitle: "Unsubscribe from Organization",
    unsubscribeSelect: "Please, select the type of withdrawal",
    unsubscribeOption1: "Unsubscribe from this organization",
    unsubscribeOption2: "Do not renew subscription",
    unsubscribeOption3: "Unsubscribe from Longomatch platform",
    messageUnsubscribeSucess: "Subscription successfully canceled",
    messageEditProfile: "Data updated successfully",
    messageEditImageProfile: "Profile picture updated successfully",
    messageDesactivateUser: "The user has been successfully removed from the organization",
  },
  storage: {
    of: "of",
    used: "used",
    consumed: "consumed",
  },
  errors: {
    form: {
      required: "Required field",
      passwordFormat: "Incorrect format",
      passwordRepeat: "Passwords do not match",
      storageLimit: "storage exceeded",
      repeatEmail: "Email is already added",
      birthDate: "Date cannot be greater than the current one",
      zero: "the number has to be between 0 and 100",
      heightError: "Height cannot be greater than 100",
      weightError: "Weight cannot be greater than 500",
    },
    server: "Error performing the operation, please try again later",
    errorImageTitle: "Image selected error",
    errorImageFormat: "Invalid image format, it must be one of the following: .jpg, .jpeg, .png",
    errorImageSize: "The size of the image exceeds the allowed: 1MB",
    errorInvitation: "The invitation has already been used",
    errorMasiveOperationsTitle: "Massive operations",
    errorMasiveOperationsMessage: "You have no item selected",
    errorMasiveOperationNoFilesAllowed: "You cannot do this action on these files",
  },
  newOrganization: {
    title: "New Organization",
    tab1: "Main Data",
    tab2: "Teams",
    continue: "Continue",
    cancel: "Cancel",
    create: "Create",
    loadImageLabel: "Select the image for the organization",
    dataSectionTitle: "Organization Data",
    planSectionTitle: "Plans",
    planSectionSubtitle: "See Features",
    teamSectionTitle: "Team Details",
    staffSectionTitle: "Staff",
    addStaff: "Add staff",
    teamsTitle: "Once the first team is created, depending on your licence, you can create more later.",
    noStaffTitle: "There are no staff registered yet",
    noStaffDescription: "You can create a new staff here",
  },
  teams: {
    editOrganization: "Edit organization",
    editOrganizationSuccess: "The organization has been Updated successfully",
    deleteOrganization: "Delete organization",
    deleteOrganizationSuccess: "Organization has been removed",
    deleteOrganizationSubtitle: "Are you sure you want to delete this organization?",
    deleteTeamTitle: "Are you sure you want to delete this Team?",
    deleteStaffTitle: "Are you sure you want to delete this User?",
    deleteGroupTitle: "Are you sure you want to delete this group?",
    createTeamGroupSuccess: "The group has been successfully created",
    deleteTeamGroupSuccess: "The group has been successfully deleted",
    editTeamGroupSuccess: "The group has been successfully updated",
    teamForm: {
      addTitle: "New team",
      editTitle: "Edit team",
      imageSelector: "Select the image for the team",
      teamDataSection: "Team data",
      storageSection: "Storage",
      createSuccess: "Team created successfully",
      editSuccess: "Team updated successfully",
      deleteSuccess: "Team deleted successfully",
    },
  },
  colorSelector: {
    red: "Red",
    orange: "Orange",
    pink: "Pink",
    purple: "Purple",
    darkBlue: "Dark blue",
    lightBlue: "Light blue",
    darkGreen: "Dark green",
    lightGreen: "Light green",
  },
  buttonLabel: {
    addNewTeam: "Add team",
    cancel: "Cancel",
    continue: "Continue",
    save: "Save",
    delete: "Delete",
    create: "Create",
    goBack: "Go back",
    filter: "Filter",
    pendingInvitation: "Pending invitations",
    invite: "Invite",
    clear: "Clear",
    search: "Search",
    upload: "Upload",
    viewAll: "View all",
    accept: "Accept",
  },
  formLabel: {
    name: "Name",
    lastName: "Last name",
    address: "Direction",
    cifVat: "CIF/VAT",
    email: "Email",
    phone: "Phone",
    password: "Password",
    currentPassword: "Current password",
    repeatPassword: "Repeat password",
    newPassword: "New password",
    confirmPassword: "Confirm password",
    license: "License",
    validUntil: "Date of Expiry",
    storage: "Storage",
    category: "Category",
    role: "Role",
    nickName: "Alias",
    nationality: "Nacionality",
    position: "Position",
    number: "Number",
    height: "Height",
    weight: "Weight",
    birthDate: "BirthDate",
    group: "Group",
    selectEmpty: "nothing selected",
    players: "Players",
    morePlayers: "Add more players",
    team: "Team",
    selectTeam: "Select team",
    description: "Add Description",
    shareWithGroups: "Shared with",
    tags: "Tags",
    comment: "Comment",
    language: "Language",
    notificationType: "Notification type",
    read: "Read",
    dateStart: "Start date",
    dateEnd: "End date",
    filterEvenType: "Event type",
    teamTagged: "Team Tagged",
    playerTagged: "Player Tagged",
    period: "Period",
    notes: "Notes",
    commonTags: "Common Tags",
  },
  modalConfirm: {
    titleLogout: "You are going to log out",
    descriptionLogout: "Are you sure?",
    titleFilesAllowed: "Files allowed",
    descriptionFilesAllowed:
      "There are files for which you do not have permission to perform the selected action. Do you want to perform the action to the rest?",
    textCancel: "No",
    textConfirm: "Yes",
    textOk: "Ok",
  },
  summary: {
    summaryTab: "Summary",
    expireDate: "Expire Date: ",
    playersTab: "Players",
    groupsTab: "Groups",
    plan: "Plan",
    teams: "Teams",
    license: "License",
    storage: "Storage",
    users: "Users",
    members: "Members",
    staff: "Staff",
    invitations: "Pending Invitations",
    noTeams: "no teams available",
    maxStorage: "You are about to reach maximum storage",
  },
  player: {
    emptySection: "No players added to this team",
    addPlayer: "Add player",
    newPlayer: "New player",
    editPlayer: "Edit player",
    goalkeeper: "Goal keeper",
    defender: "Defender",
    midfielder: "Mid fielder",
    winger: "Winger",
    middleForward: "Middle forward",
    newPlayerForm: {
      createSuccess: "Player created successfully",
      editSuccess: "Player updated successfully",
      deleteSuccess: "Player deleted successfully",
    },
    deletePlayerTitle: "Are you sure you want to delete this Player?",
    viewTitle: "{{name}} - N {{number}}",
  },
  staff: {
    emptySection: "No staff added to this team",
    addStaff: "Add Staff",
    editStaff: "Edit Staff",
    createSuccess: "User created successfully",
    editSuccess: "User updated successfully",
    deleteSuccess: "User deleted successfully",
  },
  group: {
    emptySection: "No groups added to this team",
    addGroup: "New group",
    editGroup: "Edit group",
    deleteGroup: "Are you sure you want to delete this Group?",
    players: "Players",
  },
  roles: {
    admin: "Admin",
    headCoach: "Head Coach",
    headcoach: "Head Coach",
    coach: "Coach",
    analyst: "Analyst",
    consumer: "Consumer",
    player: "Player",
  },
  autocomplete: {
    notFound: "No results found",
  },
  users: {
    name: "Name",
    email: "Email",
    role: "Role",
    team: "Team",
    usersTitle: "Users Management",
    searchUser: "Search User name...",
    pendingInvitationsTitle: "Pending invitations",
    emptyPendingInvitations: "No invitations pending",
    editMemberTitle: "Edit User",
    inviteMemberTitle: "Invite member",
    inviteMemberSubtitle: "Enter an email and assign a role per team",
    inviteMemberTeams: "Teams",
    emptyUsers: "No users found",
    deleteUserTitle: "Delete User",
    deleteUserSubtitle: "Are you sure you want to delete this user?",
    deleteUserSuccess: "User has been removed",
    editSuccess: "User updated successfully",
    resendPendingInvitationSuccess: "forwarded invitation",
    resendPendingInvitation: "Resend",
  },
  content: {
    documentTitle: "Content Management",
    documentTitlePlayer: "Shared with you",
    selectTeam: "Select Team",
    lastModification: "Last modification",
    document: "Document",
    documents: "Documents",
    video: "Video",
    videos: "Video",
    dashboard: "Dashboard",
    template: "Teams",
    project: "Project",
    playlist: "Playlist",
    allTeams: "All Teams",
    documentsPlaceholder: "Search {{value}}, name tags...",
    uploadFile: "Upload file",
    download: "Download",
    changeCategory: "Change category",
    emptyCategory: "There are no items in this category",
    share: "Share",
    addTag: "Add tag",
    delete: "Delete",
    selectAll: "Select all",
    items: "Items",
    noCategory: "{{value}} without category",
    uploadFileTitle: "Upload file",
    uploadFileDescription: "Select the document you want to upload",
    uploadVideoDescription: "Select the video you want to upload",
    uploadDocument: "Upload file",
    fileUploaded: "File uploaded",
    groupsPlaceholder: "Group",
    usersPlaceholder: "User",
    modalChangeCategoryTitle: "Change Category",
    modalChangeCategorySubTitle: "Select an existing category or create a new one",
    modalChangeAddTagTitle: "Add tag",
    modalChangeAddTagSubTitle: "Select or create a tag",
    messageChangeFileCategoryOk: "Category changed successfully",
    messageAddTagToFileOk: "Tags added successfully",
    messageDownloadFilesOk: "The requested files will be compressed. You will receive a notification with a download link when the process is ready",
    titleDownloadFilesOk: "Download files",
    messageDeleteFilesOk: "The files will be deleted. You will receive a notification when the process is ready",
    titleDeleteFilesOk: "Delete files",
    messageDeleteCategory: "Category deleted successfully",
    messageConfirmDeleteCategoryTitle: "Delete category",
    messageConfirmDeleteCategoryDescription: "The category will be deleted, the files associated with this category will go to no category",
    messageConfirmDeleteCommentTitle: "Delete comment",
    messageConfirmDeleteCommentDescription: "The comment will be deleted, the replies of this comment will also be deleted",
    messageShareFileOk: "Files shared successfully",
    messageDownloadSingleFileInfo: "Downloading the file, could take a few minutes depending on it's size.",
    messageDownloadSingleFileOk: "File downloaded successfully",
    shareWith: "Share with",
    sharedWithMembers: "Shared with {{value}} members",
    sharedBy: "Shared by",
    stopSharing: "Stop sharing",
    messageUnShareFileOk: "Files unshared successfully",
    detailDocumentMessagesTab: "Messages ({{value}})",
    detailDocumentShareTab: "Share ({{value}})",
    detailDocumentAnalysis: "Analysis",
    detailDocumentZonalTags: "Zonal Tags",
    detailDocumentPlayList: "Playlists ({{value}})",
    detailDocumentClips: "Clips ({{value}})",
    uploadedBy: "Uploaded by",
    answers: "{{ value }} answers",
    answer: "1 answer",
    reply: "Reply",
    comment: "Type here...",
    noComments: "There are no messages yet",
    errorComments: "Comment not found (Maybe it's been removed)",
    publicTag: "Public",
    privateTag: "Private",
    playerNumber: "N. {{ value }}",
    players: "{{value}} players",
    detailMatchStatisticTitle: "Stats",
    detailMatchStatisticGame: {
      game: "Game",
      all: "All",
    },
    detailMatchStatisticPlayer: "Player",
    noFilesToShow: "There are no {{value}} files",
    fileNotFound: "File not found",
    mentionAccessLine1: "The following users don't have access to the content: ",
    mentionAccessLine2: "Do you want to share the content with them?",
    modalGrantAccessTitle: "Attention!",
    viewStats: "View stats",
    noZonalTag: "There is no information about zonal tag",
    results: "Results",
    total: "Total",
    staffPlaceholder: "Player/Staff",
    addToPlaylist: "Add to a Playlist",
    addToPlaylistDescription: "Select an existing playlist or create a new one",
    addToPlaylistDescription2: "Or create a new one",
    playlistExistingPlaceholder: "Existing playlist",
    playlistNewPlaceholder: "New playlist",
    evenDetail: "Event Details",
    playedTime: "Played time: ",
    points: "Points: ",
    admonition: "Admonition: ",
    team: "Team",
    playersSelector: "Players",
    category: "Category",
    wholeField: "Whole Field",
    midfield: "Midfield",
    soccerGoal: "Soccer goal",
    addPlaylistSuccess: "playlist updated successfully ",
    addToPlayListModalInfoTitle: "Add to playlist",
    addToPlayListModalInfoDescription: "There is no event selected",
    play: "Play",
    addPlaylist: "Add to Playlist",
    viewDetail: "Detail",
    sharedFileDescription: "Select the user or group to share the project. If the user doesn’t exist, send an invitation to his email",
    uploading: "Uploading:",
    messageDeleteVideo: "When deleting the video, all associated projects and associated playlists will also be deleted. Are you sure?",
    messageDeleteProjectVideo: "Do you want to delete the video associated with the project as well?",
    messageDeleteProject: "When deleting the project, all associated playlists will also be deleted. Are you sure?",
  },
  project: {
    category: "Category",
  },
  notifications: {
    notificationsTitle: "Notifications",
    filters: "Filters",
    mostRecent: "Most recent",
    lessRecent: "Less recent",
    selected: "Notifications selected:",
    delete: "Delete",
    mark: "Mark as read",
    unmark: "Mark as unread",
    selectAll: "Select all",
    unselectAll: "Unselect all",
    deleteNotificationTitle: "Are you sure you want to delete this notification?",
    emptyNotifications: "No notifications",
    seeAll: "See all",
    unRead: "Last unread notifications",
    emptyNotificationsUnread: "No unread notifications",
  },
  notificationsType: {
    unknown: "Unknown",
    comment: "Comment",
    tagged: "Tagged",
    shared: "Shared",
    storage: "Storage",
    users: "Users",
    deletedFiles: "Deleted files",
    downloadFiles: "Download files",
    deleteUsers: "Delete users",
  },
  time: {
    timeAgo: "",
    minutesAgo: "minutes ago",
    hoursAgo: "hours ago",
  },
  readType: {
    read: "Read",
    unread: "Unread",
  },
  dashboard: {
    teamSection: "Teams",
    storageSection: "Storage",
    lastPostsSection: "Last Posts",
    lastNotificationsSection: "Last Notifications",
    lastDocumentsSection: "Documents",
    lasContentSection: "Content",
    lastProjectsSection: "Projects",
    lastMembersSection: "New Members",
    sharedWithYouSection: "Shared with you",
    lastMentionsSection: "Last Mentions",
    goToComment: "Go to comment",
    members: "Members",
    noMembers: "No members",
    players: "Players",
    noPlayers: "No players",
  },
  playlist: {
    messageConfirmDeletePlaylistTitle: "Delete Playlist",
    messageConfirmDeleteClipTitle: "Delete Videoclip",
    messageConfirmDeletePlaylistDescription: "Are you sure you want to delete this Playlist?",
    messageConfirmDeleteClipDescription: "Are you sure you want to delete this Videoclip?",
    messageConfirmDeleteClipDescription2:
      "Are you sure you want to delete this Video Clip? This is the last Video Clip in the playlist. The playlist will also be deleted.",
    modalInfoPlaylistTitle: "More info Playlist",
    tabShareTitle: "Share",
    tabCommentsTitle: "Comments",
  },
  uploadImage: {
    title: "Upload image",
    subtitle: "Recommendations",
    recommendation1: "The use of square images is recommended",
    recommendation2: "Maximum file size is 1 mb",
  },
  languages: {
    es: "Spanish",
    en: "English",
  },
};
