import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-narrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-narrow-up.svg";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import UsersRoleFilter from "./UsersRoleFIlter";
import { useTranslation } from "react-i18next";
import { SizeScreen } from "../../model/SizeSreen";
import { MainState } from "../../model/states/MainState";
import { InitialState } from "../../model/states/InitialState";
import UsersHeader from "../../components/users-header/UsersHeader";
import { isOwner } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { OrganizationState } from "../../model/states/OrganizationState";
import Loading from "../../components/loading/Loading";
import LmUsersList from "../../components/users-list/LmUsersList";

function Users() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const [sortOption, setSortOption] = useState<"upName" | "downName" | "upEmail" | "downEmail">("upName");

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: true, showLateralMenu: true } });
  }, []);

  useEffect(() => {
    if (isOwner(stateAuth)) {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }
  }, [stateAuth.userInfo.selectedOrganization, stateAuth.appLanguage]);

  useEffect(() => {
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CHANGE_SORT_ORGANIZATION_USER,
      value: sortOption,
    });
  }, [sortOption]);

  return stateOrganization.organizationUsers.ok && !stateOrganization.organizationUsers.loading ? (
    <div className="px-5 lg:px-12 py-4 mt-20 lg:mt-0" data-testid="page-users">
      <div className="">
        <UsersHeader />
      </div>
      {stateMain.sizeScreen == SizeScreen.DESKTOP && <UsersRoleFilter />}
      <div className="lg:mt-5">
        {stateMain.sizeScreen == SizeScreen.DESKTOP && (
          <div className="flex items-center gap-4 px-4 py-2 border-y border-neutral-200">
            <div className="grid grid-cols-4 items-center gap-4 w-full text-xs text-subtle">
              <div
                className="flex cursor-pointer items-center space-x-2"
                onClick={() => setSortOption((prevValue) => (prevValue === "upName" ? "downName" : "upName"))}>
                {sortOption === "upName" && <ArrowUp />}
                {sortOption === "downName" && <ArrowDown />}
                <p>{t("users.name")}</p>
              </div>
              <div
                className="flex cursor-pointer items-center space-x-2"
                onClick={() => setSortOption((prevValue) => (prevValue === "upEmail" ? "downEmail" : "upEmail"))}>
                {sortOption === "upEmail" && <ArrowUp />}
                {sortOption === "downEmail" && <ArrowDown />}
                <p>{t("users.email")}</p>
              </div>

              <p>{t("users.role")}</p>
              <p>{t("users.team")}</p>
            </div>
            <div className="w-14"></div>
          </div>
        )}
        <LmUsersList />
      </div>
    </div>
  ) : (
    <Loading />
  );
}
export default Users;
