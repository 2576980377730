import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SizeScreen } from "../../model/SizeSreen";
import { ProjectCategoryDto, ProjectDto, Timeline } from "../../model/dto/ProjectDto";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import MatchFieldViewer from "./MatchFieldViewer";
import MatchStatisticViewer from "./MatchStatisticViewer";

interface Props {
  statistic: ProjectDto;
  eventsCategory: ProjectCategoryDto[];
  timelinesPeriod(timelines: Timeline[]): void;
  periodSelected(period: string): void;
}

const MatchStatisticGame = ({ statistic, eventsCategory, timelinesPeriod, periodSelected }: Props) => {
  const [selectedPeriod, setSelectedPeriod] = useState("All");
  const [periodTimelines, setPeriodTimelies] = useState<Timeline[]>([]);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  useEffect(() => {
    if (eventsCategory.length) {
      const filteredTimelines = eventsCategory
        .filter((event) => event.name)
        .map((event) => event.timelines)
        .flat();

      if (selectedPeriod === "All") {
        setPeriodTimelies(filteredTimelines);
        return;
      }

      const period = statistic.Periods.find((period) => period.Name === selectedPeriod);
      const start = period?.Nodes[0].Start ?? 0;
      const end = period?.Nodes[0].Stop ?? -1;
      const periodTimelines = filteredTimelines.filter((timeline: Timeline) => timeline.EventTime >= start && timeline.EventTime < end);
      setPeriodTimelies(periodTimelines);
      timelinesPeriod(periodTimelines);
    }
  }, [selectedPeriod, eventsCategory]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedPeriod(value);
    periodSelected(value);
  };

  return (
    <div className="flex flex-col lg:flex-row gap-4 lg:gap-2">
      <div className="lg:w-2/3">
        <div className="grid grid-cols-3 items-center gap-4">
          <div className="col-span-2">
            <RadioGroup row defaultValue="All" className="m-4 mx-0" onChange={handleRadioChange}>
              <FormControlLabel
                value="All"
                control={
                  <Radio
                    sx={{
                      color: "var(--bv-color-neutral-200)",
                      "&.Mui-checked": {
                        color: "var(--bv-color-button-primary-base)",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 16,
                      },
                    }}
                  />
                }
                label="All"
                sx={{ fontSize: "12px" }}
              />
              {statistic.Dashboard.GamePeriods.map((period: string) => (
                <FormControlLabel
                  key={period}
                  value={period}
                  control={
                    <Radio
                      sx={{
                        color: "var(--bv-color-neutral-200)",
                        "&.Mui-checked": {
                          color: "var(--bv-color-button-primary-base)",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                  label={period}
                />
              ))}
            </RadioGroup>
          </div>
          {stateMain.sizeScreen === SizeScreen.MOBILE && (
            <div className="grid grid-cols-2 items-center gap-4">
              <div className="h-4 w-4 rounded-sm bg-error-500 mx-auto"></div>
              <div className="h-4 w-4 rounded-sm bg-green-500 mx-auto"></div>
            </div>
          )}
        </div>

        <MatchStatisticViewer
          selectedPeriod={selectedPeriod}
          eventsCategory={eventsCategory}
          periods={statistic.Periods}
          localId={statistic.LocalTeamTemplate.$id}
          visitorId={statistic.VisitorTeamTemplate.$id}></MatchStatisticViewer>
      </div>
      {stateMain.sizeScreen === SizeScreen.DESKTOP && <Divider orientation="vertical" flexItem sx={{ margin: "0 1.5rem" }} />}
      <div className="lg:w-1/3 lg:mt-12 space-y-4 lg:space-y-6">
        {statistic.Dashboard.FieldBackground && (
          <MatchFieldViewer 
            background={statistic.Dashboard.FieldBackground} 
            timelines={periodTimelines}
            statistic={statistic}
            imageType={"FieldPosition"}
          ></MatchFieldViewer>
        )}
        <div className="flex">
          {statistic.Dashboard.HalfFieldBackground && (
            <div className="block">
              <MatchFieldViewer
                background={statistic.Dashboard.HalfFieldBackground}
                timelines={periodTimelines}
                statistic={statistic}
                imageType={"HalfFieldPosition"}
              ></MatchFieldViewer>
            </div>
          )}
          {statistic.Dashboard.GoalBackground && (
            <div className="block">
              <MatchFieldViewer 
                background={statistic.Dashboard.GoalBackground} 
                timelines={periodTimelines}
                statistic={statistic}
                imageType={"GoalPosition"}
              ></MatchFieldViewer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchStatisticGame;
