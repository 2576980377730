import * as Yup from "yup";

export function teamInitialValues() {
  return {
    playlistExisting: '',
    playlistNew: '',
    staff: [],
    groups: [],
    players: [],
    comment: '' 
  };
}

export function teamValidationSchema() {
  return Yup.object({
    users: Yup.array(),
    groups: Yup.array(),
    comment: Yup.string(),  
  });
}
