import CountryDto from "../dto/CountryDto";
import PlayerResultDto from "../dto/PlayerResultDto";

export enum ActionPlayerType {
  ACTION_CREATE_PLAYER_LOADING = "ACTION_CREATE_PLAYER_LOADING",
  ACTION_CREATE_PLAYER_SUCCESS = "ACTION_CREATE_PLAYER_SUCCESS",
  ACTION_CREATE_PLAYER_ERROR = "ACTION_CREATE_PLAYER_ERROR",

  ACTION_EDIT_PLAYER_LOADING = "ACTION_EDIT_PLAYER_LOADING",
  ACTION_EDIT_PLAYER_SUCCESS = "ACTION_EDIT_PLAYER_SUCCESS",
  ACTION_EDIT_PLAYER_ERROR = "ACTION_EDIT_PLAYER_ERROR",

  ACTION_DELETE_PLAYER_LOADING = "ACTION_DELETE_PLAYER_LOADING",
  ACTION_DELETE_PLAYER_SUCCESS = "ACTION_DELETE_PLAYER_SUCCESS",
  ACTION_DELETE_PLAYER_ERROR = "ACTION_DELETE_PLAYER_ERROR",

  ACTION_PLAYER_BY_ORGANIZATION_LOADING = "ACTION_PLAYER_BY_ORGANIZATION_LOADING",
  ACTION_PLAYER_BY_ORGANIZATION_SUCCESS = "ACTION_PLAYER_BY_ORGANIZATION_SUCCESS",
  ACTION_PLAYER_BY_ORGANIZATION_ERROR = "ACTION_PLAYER_BY_ORGANIZATION_ERROR",

  ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING = "ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING",
  ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_SUCCESS = "ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_SUCCESS",
  ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_ERROR = "ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_ERROR",

  ACTION_COUNTRIES_LOADING = "ACTION_COUNTRIES_LOADING",
  ACTION_COUNTRIES_SUCCESS = "ACTION_COUNTRIES_UCCESS",
  ACTION_COUNTRIES_ERROR = "ACTION_COUNTRIES_ERROR",
}

export interface ActionCreatePlayerLoading {
  type: ActionPlayerType.ACTION_CREATE_PLAYER_LOADING;
  value: PlayerResultDto;
}

export interface ActionCreatePlayerSuccess {
  type: ActionPlayerType.ACTION_CREATE_PLAYER_SUCCESS;
  value: boolean;
}

export interface ActionCreatePlayerError {
  type: ActionPlayerType.ACTION_CREATE_PLAYER_ERROR;
  value: string;
}

export interface ActionEditPlayerLoading {
  type: ActionPlayerType.ACTION_EDIT_PLAYER_LOADING;
  value: PlayerResultDto;
}

export interface ActionEditPlayerSuccess {
  type: ActionPlayerType.ACTION_EDIT_PLAYER_SUCCESS;
  value: boolean;
}

export interface ActionEditPlayerError {
  type: ActionPlayerType.ACTION_EDIT_PLAYER_ERROR;
  value: string;
}

export interface ActionDeletePlayerLoading {
  type: ActionPlayerType.ACTION_DELETE_PLAYER_LOADING;
  value: { playerId: string; teamId: string };
}

export interface ActionDeletePlayerSuccess {
  type: ActionPlayerType.ACTION_DELETE_PLAYER_SUCCESS;
}

export interface ActionDeletePlayerError {
  type: ActionPlayerType.ACTION_DELETE_PLAYER_ERROR;
}

export interface ActionPlayerByOrganizationLoading {
  type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_LOADING;
  value: string;
}

export interface ActionPlayerByOrganizationSuccess {
  type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_SUCCESS;
  value: PlayerResultDto[];
}

export interface ActionPlayerByOrganizationError {
  type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_ERROR;
}

export interface ActionPlayerByOrganizationUnregisteredLoading {
  type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING;
  value: string;
}

export interface ActionPlayerByOrganizationUnregisteredSuccess {
  type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_SUCCESS;  
}

export interface ActionPlayerByOrganizationUnregisteredError {
  type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_ERROR;
}

export interface ActionCountriesLoading {
  type: ActionPlayerType.ACTION_COUNTRIES_LOADING;
}

export interface ActionCountriesSuccess {
  type: ActionPlayerType.ACTION_COUNTRIES_SUCCESS;
  value: CountryDto[];
}

export interface ActionCountriesError {
  type: ActionPlayerType.ACTION_COUNTRIES_ERROR;
}

export type TypePlayerActions =
  | ActionCreatePlayerLoading
  | ActionCreatePlayerSuccess
  | ActionCreatePlayerError
  | ActionEditPlayerLoading
  | ActionEditPlayerSuccess
  | ActionEditPlayerError
  | ActionDeletePlayerLoading
  | ActionDeletePlayerSuccess
  | ActionDeletePlayerError
  | ActionPlayerByOrganizationLoading
  | ActionPlayerByOrganizationSuccess
  | ActionPlayerByOrganizationError
  | ActionCountriesLoading
  | ActionCountriesSuccess
  | ActionCountriesError
  | ActionPlayerByOrganizationUnregisteredLoading
  | ActionPlayerByOrganizationUnregisteredSuccess
  | ActionPlayerByOrganizationUnregisteredError;
