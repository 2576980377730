import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getSelectedDataById, isStaffByOrganization } from "../../utils/utils";
import PlayerDashboard from "./PlayerDashboard";
import StaffDashboard from "./StaffDashboard";

function DashBoard() {
  const dispatch = useDispatch();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = useMemo(
    () => getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations),
    [stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations]
  );
  const isStaffByOrg = useMemo(() => isStaffByOrganization(selectedOrganizationData, stateAuth), [stateAuth.userInfo.selectedOrganization]);
  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: true, showLateralMenu: true } });
  }, []);

  return (
    <div className="lm-dashboard py-5 px-5 lg:px-12 mt-20 lg:mt-0" data-testid="page-dashboard">
      {isStaffByOrg ? <StaffDashboard /> : <PlayerDashboard />}
    </div>
  );
}
export default DashBoard;
