import { TeamState } from "../../model/states/TeamState";

export const teamState: TeamState = {
  selectedTeam: null,
  loading: false,
  ok: false,
  teamForm: {
    image: "",
    name: "",
    category: "",
    storage: "",
    staff: [],
  },
  teamSumary: {
    loading: false,
    error: false,
    ok: false,
    data: null,
  },
  staff: {
    loading: false,
    error: false,
    ok: false,
  },
  groups: {
    loading: false,
    error: false,
    ok: false,
  },
  dashboardTotalStorage: 0,
};
