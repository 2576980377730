import { useFormik } from "formik";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IdValue } from "../../model/forms/IdValue";
import { AuthState } from "../../model/states/AuthState";
import { ContentManagementState, ContentManagementTabs, ContentManagementType } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { getSelectedDataById, typesContentStateFilesSelected } from "../../utils/utils";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import LmButton from "../button/LmButton";
import LmTextArea from "../textarea/LmTextArea";

import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { OrganizationState } from "../../model/states/OrganizationState";
import { PlayerState } from "../../model/states/PlayerState";
import { teamInitialValues, teamValidationSchema } from "./LmShareFileForm.data";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import { RolesUserOrganizationType } from "../../model/RolesType";

interface Props {
  onClose(): void;
}

export function LmShareFileForm({ onClose }: Props) {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const statePlayer: PlayerState = useSelector((state: MainState) => state.player);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const selectedOrganization: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);

  const [group, setGroup] = useState<IdValue[]>([]);
  const [groupValue, setGroupValue] = useState("");

  const [user, setUser] = useState<IdValue[]>([]);
  const [userValue, setUserValue] = useState("");
  const shareWithPlayers =
    stateContent.contentSelected === ContentManagementType.Document ||
    stateContent.contentSelected === ContentManagementType.Video ||
    (stateContent.contentDetailTabSelected &&
      stateContent.contentSelected === ContentManagementType.Project &&
      stateContent.contentDetailTabSelected === ContentManagementTabs.Playlist);

  useEffect(() => {
    if (selectedOrganization.users && selectedOrganization.users?.filter((user) => !user.user).length > 0) {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }
    if (shareWithPlayers && !selectedOrganization.playersUnregistered) {
      dispatch<TypeActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }
    if (shareWithPlayers && (!selectedOrganization.groups || selectedOrganization.groups?.length === 0)) {
      dispatch({ type: ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }
  }, [stateAuth.userInfo.selectedOrganization]);
  const formik = useFormik({
    initialValues: teamInitialValues(stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)].teamSelected),
    validationSchema: teamValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const groupsOptions = useMemo(() => {
    const groups = selectedOrganization?.groups?.map((group) => {
      return {
        id: group.id,
        value: group.name,
      };
    });

    return groups as IdValue[];
  }, [stateAuth.userInfo.selectedOrganization, selectedOrganization.groups]);

  const usersOptions = useMemo(() => {
    let users;

    if (shareWithPlayers) {
      users = selectedOrganization.users
        ?.map((user) => {
          return { id: user.user?.id, value: user.user?.name + ", " + user.user?.lastName, type: "user" };
        })
        .filter((item) => item !== undefined);
    } else {
      users = selectedOrganization.users
        ?.filter((user) => {
          let isStaff: boolean | undefined = false;
          user.teamUsers.forEach((team) => {
            const roles = team.roles;
            isStaff =
              isStaff ||
              roles?.includes(RolesUserOrganizationType.HeadCoach) ||
              roles?.includes(RolesUserOrganizationType.Coach) ||
              roles?.includes(RolesUserOrganizationType.Analyst);
          });
          return isStaff;
        })
        .map((user) => {
          return { id: user.user?.id, value: user.user?.name + ", " + user.user?.lastName, type: "user" };
        })
        .filter((item) => item !== undefined);
    }

    if (shareWithPlayers && selectedOrganization?.playersUnregistered) {
      users = users?.concat(
        selectedOrganization?.playersUnregistered?.map((player) => {
          return { id: player.id, value: player.name + ", " + player.lastName, type: "player" };
        })
      );
    }

    return users;
  }, [stateOrganization.organizationUsers.loading, stateAuth.userInfo.selectedOrganization, statePlayer.dataPlayerUnregistered.loading]);

  useEffect(() => {
    if (stateContent.shareFile.ok) {
      onClose();
      dispatch({ type: ActionContentManagementType.ACTION_SHARE_FILE_RESET });
    }
  }, [stateContent.shareFile.ok]);

  const onSubmit = async (formValue: any) => {
    if (stateContent.contentDetailTabSelected === ContentManagementTabs.Playlist && stateContent.selectedPlaylist) {
      dispatch({ type: ActionContentManagementType.ACTION_SHARE_FILE_LOADING, value: { ...formValue, files: [stateContent.selectedPlaylist] } });
    } else {
      dispatch({ type: ActionContentManagementType.ACTION_SHARE_FILE_LOADING, value: { ...formValue, files: stateContent.filesSelected } });
    }
  };

  const selectedGroup = (options: IdValue[]) => {
    setGroup(options as IdValue[]);
    setGroupValue("");
    formik.setFieldValue(
      "groups",
      options.map((option) => option.id)
    );
  };

  const selectedValueGroupDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setGroupValue(e.target.value);
  };

  const selectedUser = (options: IdValue[]) => {
    setUser(options as IdValue[]);
    setUserValue("");
    formik.setFieldValue(
      "users",
      options.filter((option) => option.type === "user").map((option) => option.id)
    );
    formik.setFieldValue(
      "players",
      options.filter((option) => option.type === "player").map((option) => option.id)
    );
  };

  const selectedValueUserDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setUserValue(e.target.value);
  };

  const onCloseForm = () => {
    onClose();
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-upload-file-form">
        <div className="grid grid-cols-1 w-full mb-8">
          {shareWithPlayers && (
            <div>
              <LmAutocomplete
                label={t("formLabel.shareWithGroups")}
                id="groups"
                placeholder={t("content.groupsPlaceholder") as string}
                value={group}
                valueDisplay={groupValue}
                setValueDisplay={selectedValueGroupDisplay}
                multiple={true}
                options={groupsOptions as IdValue[]}
                selectedValue={(options) => selectedGroup(options as IdValue[])}
              />
            </div>
          )}
          <div>
            <LmAutocomplete
              id="users"
              placeholder={t("content.usersPlaceholder") as string}
              value={user}
              valueDisplay={userValue}
              setValueDisplay={selectedValueUserDisplay}
              multiple={true}
              options={usersOptions as IdValue[]}
              selectedValue={(options) => selectedUser(options as IdValue[])}
            />
          </div>
          <div>
            <LmTextArea
              id="comment"              
              placeholder={t("formLabel.comment") as string}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.comment}></LmTextArea>
          </div>
        </div>

        <div className="flex flex-wrap justify-end gap-4 mt-8">
          <LmButton
            styleButton="secondary"
            type="solid"
            clickAction={onCloseForm}
            size="small"
            text={`${t("buttonLabel.cancel")}`}
            iconPosition="right"></LmButton>
          <LmButton
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={t("buttonLabel.save")}
            isDisabled={stateContent.shareFile.loading}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
}
