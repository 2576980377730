import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LoginLogo } from "../../assets/img/logo-longomatch-white.svg";
import { LmLoginForm } from "../../components/login-forms/LmLoginForm";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import {
  ROUTE_LICENSE_SELECTOR,
  ROUTE_LOGIN,
  ROUTE_PRIVATE_CONTENT_MANAGEMENT,
  ROUTE_PRIVATE_DASHBOARD,
  ROUTE_PRIVATE_NEW_ORGANIZATION,
} from "../../routes/routes";
import "./Login.scss";

function Login() {
  const state: AuthState = useSelector((state: MainState) => state.auth);
  const [idOrganizationLink, setIdOrganizationLink] = useState("");
  const [contentIdLink, setContentIdLink] = useState("");
  const [commentIdLink, setCommentIdLink] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: false, showLateralMenu: false } });
  }, []);

  useEffect(() => {
    const { id, contentId, commentId } = params;
    if (id) {
      setIdOrganizationLink(id);
      setContentIdLink(contentId || "");
      setCommentIdLink(commentId || "");
    }
  }, []);

  useEffect(() => {
    if (state.auth && state.userInfo?.data) {
      const { id, contentId, commentId } = params;

      if (idOrganizationLink || id) {
        const organizations: OrganizationDto[] = state?.userInfo?.data?.organizations || [];
        const organizationSelected = organizations.find((org) => org.id === (idOrganizationLink || id));
        if (organizationSelected) {
          setOrganization(organizationSelected);
          if ((contentIdLink || contentId) && (commentIdLink || commentId)) {
            navigate("/" + ROUTE_PRIVATE_CONTENT_MANAGEMENT + "/" + (contentIdLink || contentId) + "/" + (commentIdLink || commentId));
          } else if (contentIdLink || contentId) {
            navigate("/" + ROUTE_PRIVATE_CONTENT_MANAGEMENT + "/" + (contentIdLink || contentId));
          } else {
            navigate("/" + checkUserInfoData());
          }
        }
      } else {
        navigate("/" + checkUserInfoData());
      }
    } else {
      navigate(ROUTE_LOGIN);
    }
  }, [state.auth, state.userInfo?.data]);

  const setOrganization = (organization: OrganizationDto) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_ORGANIZATION, value: organization });
    dispatch<TypeActions>({ type: ActionMainType.ACTION_CHANGE_COLOR_APLICATION, value: organization.color });
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHANGE_USER_ORGANIZATION_ROLE, value: organization });
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_DASHBOARD });
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHANGE_APP_USER_LANGUAGE, value: organization });
  };

  const checkUserInfoData = () => {
    const { freeLicenses, organizations } = state.userInfo?.data || {};

    if (freeLicenses?.length === 1 && organizations?.length === 0) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_LICENSE, value: freeLicenses[0].id });
      return ROUTE_PRIVATE_NEW_ORGANIZATION;
    } else if (freeLicenses?.length === 0 && organizations?.length === 1) {
      setOrganization(organizations[0]);
      return ROUTE_PRIVATE_DASHBOARD;
    } else {
      return ROUTE_LICENSE_SELECTOR;
    }
  };

  return (
    <div data-testid="login-page" className="login full-height min-h-screen flex flex-col justify-center items-center p-6">
      <LoginLogo className="md:w-72 w-48 md:mb-24 mb-16" />
      <p className="font-decorative text-white text-center text-base md:text-xl lg:text-2xl mb-12">{t("login.title")}</p>
      <LmLoginForm />
    </div>
  );
}

export default Login;
