import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import LmButton from "../button/LmButton";
import LmInput from "../input/LmInput";
import LmMessageError from "../message-error/LmMessageError";
import { ViewMode } from "../../model/ViewMode";
import { groupInitialValues, groupValidationSchema } from "./LmGroupForm.data";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IdValue } from "../../model/forms/IdValue";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { getSelectedDataById } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import TeamsDto from "../../model/dto/TeamDto";
import TeamGroupResultDto from "../../model/dto/TeamGroupResultDto";

interface Props {
  onClose(): void;
  viewMode: ViewMode;
  group?: TeamGroupResultDto | undefined;
}

export function LmGroupForm({ onClose, viewMode, group }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState<IdValue[]>([]);
  const [nameValue, setNameValue] = useState("");
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const selectedTeamData: TeamsDto = getSelectedDataById(stateAuth.userInfo.selectedTeam, selectedOrganizationData?.teams);
  const optionsPlayers = useMemo(
    () =>
      selectedTeamData?.players?.map((player) => {
        return { id: player.id, value: player.name + " " + player.lastName + " - " + player.nickName, image: player.avatarImage };
      }),
    []
  );

  useEffect(() => {
    const format = group?.players.map((player) => {
      return { id: player.id, value: player.name + " " + player.lastName + " - " + player.nickName, image: player.avatarImage };
    });
    if (format) {
      setName(format);
    }
  }, [group]);

  const formik = useFormik({
    initialValues: groupInitialValues(group),
    validationSchema: groupValidationSchema(),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = async (formValue: any) => {
    switch (viewMode) {
      case ViewMode.CREATE:
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_CREATE_TEAM_GROUP_LOADING,
          value: { data: { ...formValue, teamId: stateAuth.userInfo.selectedTeam }, callBack: onClose },
        });
        break;
      case ViewMode.EDIT:
        dispatch<TypeActions>({
          type: ActionTeamType.ACTION_EDIT_TEAM_GROUP_LOADING,
          value: { data: { ...formValue, teamId: stateAuth.userInfo.selectedTeam, id: group?.id }, callBack: onClose },
        });
        break;

      default:
        break;
    }
  };

  const selectedPlayer = (options: IdValue[]) => {
    setName(options as IdValue[]);
    setNameValue("");
    formik.setFieldValue(
      "players",
      options.map((option) => option.id)
    );
  };

  const selectedValueNameDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-lmGroupForm">
        <div className="grid grid-cols-1 gap-4 w-full mb-8">
          <div>
            <LmInput
              required={true}
              id="name"
              label={t("formLabel.name")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.name}></LmInput>
            {formik.touched.name && formik.errors.name && <LmMessageError id="name" message={formik.errors.name}></LmMessageError>}
          </div>
          <div>
            <LmAutocomplete
              label={t("formLabel.players")}
              id="players"
              value={name}
              valueDisplay={nameValue}
              setValueDisplay={selectedValueNameDisplay}
              multiple={true}
              options={optionsPlayers as IdValue[]}
              selectedValue={(options) => selectedPlayer(options as IdValue[])}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-end gap-4">
          <LmButton styleButton="secondary" type="solid" clickAction={onClose} size="small" text={`${t("buttonLabel.cancel")}`} iconPosition="right"></LmButton>
          <LmButton
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={viewMode === ViewMode.CREATE ? t("buttonLabel.create") : t("buttonLabel.save")}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
}
