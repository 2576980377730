export const ROUTE_LOGIN = "/login";
export const ROUTE_RESTORE_PASSWORD = "/restore-password";
export const ROUTE_PASSWORD_RESET = "/password/reset";
export const ROUTE_REGISTER = "/register";
export const ROUTE_ERROR = "/error";
export const ROUTE_LICENSE_SELECTOR = "license-selector";
export const ROUTE_PRIVATE_DASHBOARD = "dashboard";
export const ROUTE_PRIVATE_USERS = "users";
export const ROUTE_PRIVATE_NOTIFICATIONS = "notifications";
export const ROUTE_PRIVATE_CONTENT_MANAGEMENT = "content-management";
export const ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL = "content-management-detail";
export const ROUTE_PRIVATE_NEW_ORGANIZATION = "new-organization";
export const ROUTE_PRIVATE_TEAMS = "teams";
export const ROUTE_PRIVATE_LOGOUT = "logout";
export const ROUTE_PRIVATE_MATCH_STATISTICS = "match-statistics";
