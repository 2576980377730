import { useEffect, useRef, useState } from "react";
import { ProjectDto, Timeline } from "../../model/dto/ProjectDto";
import { getBase64Img, getProjectDataTeamPlayer } from "../../utils/utils";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";

interface EventPosition {
    top: number;
    left: number;
    id: string;
    color: string;
}

export interface Props {
    statistic: ProjectDto;
    background: string;
    timelines: Timeline[];
    sizeMultiplier?: number;
    imageType: "FieldPosition" | "HalfFieldPosition" | "GoalPosition";
    eventTimelineClick?(timelineId: string): void;   
}

const MatchFieldViewer = ({ background, timelines, statistic, sizeMultiplier, imageType, eventTimelineClick }: Props) => {
    const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const radius = 5;
    const radiusActive = 8;
    const [scale, setScale] = useState({ x: 1, y: 1 });
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    const [context, setContext] = useState<CanvasRenderingContext2D>();
    const [timelineActive, setTimelineActive] = useState('')

    useEffect(() => {
        const currentCanvas = canvasRef.current;    
        if (currentCanvas == null) return; 
        const currentContext = currentCanvas.getContext("2d");
        if (currentContext == null) return; 
        setCanvas(canvasRef.current);
        setContext(currentContext);
    }, [timelineActive]);

    useEffect(() => {
        if(canvas && context) {
            draw(canvas, context);
            canvas.addEventListener("resize", resized);
        }
    }, [canvas, context, timelines, scale, timelineActive]);

    const resized = () => {
        const calculateScaleX = (canvas: HTMLCanvasElement | null) => (!canvas ? 0 : canvas.clientWidth / width);
        const calculateScaleY = (canvas: HTMLCanvasElement | null) => (!canvas ? 0 : canvas.clientHeight / height);

        if(canvas) {
            canvas.width = canvas.clientWidth;
            canvas.height = canvas.clientHeight;
            setScale({ x: calculateScaleX(canvas), y: calculateScaleY(canvas) });
            
            canvas.addEventListener("resize", resized);
            return () => canvas.removeEventListener("resize", resized);
        }
    };

    const draw = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
        context.scale(scale.x, scale.y);
        context.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);

        const image = new Image();
        image.src = getBase64Img(background);
        image.onload = () => {
            setWidth(image.width);
            setHeight(image.height);
            
            canvas.width = sizeMultiplier ? canvas.clientWidth * 2: canvas.clientWidth;
            canvas.height = sizeMultiplier ? canvas.clientHeight * 2: canvas.clientHeight;
            
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            setCanvasPoints(context, canvas.width, canvas.height);
        };
    }

    const canvasClicked = (drawnEvents: EventPosition[], event: MouseEvent) => {
        const x = event.pageX - canvas!.offsetLeft, y = event.pageY - canvas!.offsetTop;
        const circleOffset = 5;

        const drawnEvent = drawnEvents?.find((element: EventPosition) => 
            (element.top - circleOffset) < y && 
            (element.top + circleOffset) > y && 
            (element.left - circleOffset) < x && 
            (element.left + circleOffset) > x
        );

        if(drawnEvent && eventTimelineClick) { 
            eventTimelineClick(drawnEvent.id); 
            setTimelineActive(drawnEvent.id);       
        }
        
    }

    const setCanvasPoints = (context: CanvasRenderingContext2D, currentWidth: number, currentHeight: number) => {  
        const drawnEvents: EventPosition[] = [];
        const fieldTimelines = timelines.map(timeline => ({ 
            event: timeline[imageType],           
            teamId: timeline.Players?.map(p => getProjectDataTeamPlayer(p?.$ref, stateContent.project.data as ProjectDto).$id), 
            id: timeline.$id
        }));
        fieldTimelines.filter(timeline => timeline?.event?.Points && timeline.event?.Points.length).forEach((timeline) => {
            let xAxis = 0, yAxis = 0, color = "";
            if(timeline.teamId.includes(statistic.LocalTeamTemplate.$id)) {
                color = "rgb(239 68 68)";
            }
            if(timeline.teamId.includes(statistic.VisitorTeamTemplate.$id)) {
                color = "rgb(34 197 94)";
            }
            const { event } = timeline;
            if(event!.Points.length === 1) {
                const points = event!.Points[0].split(" ");
                xAxis = +points[0] * currentWidth;
                yAxis = +points[1] * currentHeight;
                drawCanvasCircle(context, xAxis, yAxis, timelineActive === timeline.id ? radiusActive : radius, color);
            }

            if(event!.Points.length === 2) {
                const points = event!.Points[0].split(" ");
                xAxis = +points[0] * currentWidth;
                yAxis = +points[1] * currentHeight;
                drawCanvasCircle(context, xAxis, yAxis, timelineActive === timeline.id ? radiusActive : radius, color);
                
                const arrowPoints = event!.Points[1].split(" ");
                const xAxisArrowEnd = +arrowPoints[0] * currentWidth;
                const yAxisArrowEnd = +arrowPoints[1] * currentHeight;
                drawCanvasArrow(context, xAxis, yAxis, xAxisArrowEnd, yAxisArrowEnd, 2, color);
            }
            drawnEvents.push({
                top: yAxis,
                left: xAxis,
                id: timeline.id,
                color: color
            });
        });
        canvas!.addEventListener('click', (event: MouseEvent) => canvasClicked(drawnEvents, event));
    }

    const drawCanvasCircle = (context: CanvasRenderingContext2D, xAxis: number, yAxis: number, radius: number, color: string) => {
        context.fillStyle = color;
        context.beginPath();
        context.arc(xAxis, yAxis, radius, 0, 2 * Math.PI, true);
        context.stroke();
        context.fill();        
    }

    const drawCanvasArrow = (ctx: CanvasRenderingContext2D, fromX: number, fromY: number, toX: number, toY: number, arrowWidth: number, color: string) => {
        // variables to be used when creating the arrow
        const headlen = 10;
        const angle = Math.atan2(toY - fromY, toX - fromX);
     
        ctx.save();
        ctx.strokeStyle = color;
      
        // starting path of the arrow from the start square to the end square
        // and drawing the stroke
        ctx.beginPath();
        ctx.moveTo(fromX, fromY);
        ctx.lineTo(toX, toY);
        ctx.lineWidth = arrowWidth;
        ctx.stroke();

        // starting a new path from the head of the arrow to one of the sides of
        // the point
        ctx.beginPath();

        ctx.moveTo(toX, toY);
        ctx.lineTo(toX - headlen * Math.cos(angle-Math.PI/7),
                   toY - headlen * Math.sin(angle-Math.PI/7));
     
        // path from the side point of the arrow, to the other side point
        ctx.lineTo(toX - headlen * Math.cos(angle+Math.PI/7),
                   toY - headlen * Math.sin(angle+Math.PI/7));
     
        // path from the side point back to the tip of the arrow, and then
        // again to the opposite side point
        ctx.lineTo(toX, toY);
        ctx.lineTo(toX - headlen * Math.cos(angle-Math.PI/7),
                   toY - headlen * Math.sin(angle-Math.PI/7));
     
        // draws the paths created above
        ctx.stroke();
        ctx.restore();
    }   
    return(
        <canvas ref={canvasRef} />
    )

}

export default MatchFieldViewer;