import React, { useMemo } from "react";

import "./MainMenu.scss";

import { ReactComponent as Asterisk } from "../../assets/icons/asterisk-white.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/log-out-white.svg";
import { ReactComponent as MenuContent } from "../../assets/icons/menu-content.svg";
import { ReactComponent as MenuHome } from "../../assets/icons/menu-home.svg";
import { ReactComponent as MenuNotifications } from "../../assets/icons/menu-notifications.svg";
import { ReactComponent as MenuTeams } from "../../assets/icons/menu-teams.svg";
import { ReactComponent as MenuUsers } from "../../assets/icons/menu-users.svg";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_PRIVATE_CONTENT_MANAGEMENT,
  ROUTE_PRIVATE_DASHBOARD,
  ROUTE_PRIVATE_NOTIFICATIONS,
  ROUTE_PRIVATE_TEAMS,
  ROUTE_PRIVATE_USERS,
} from "../../routes/routes";

import { MainState } from "../../model/states/MainState";
import LmAvatar from "../avatar/LmAvatar";

import { ReactComponent as Close } from "../../assets/icons/close-white.svg";
import { ReactComponent as MenuResponsivePrimary } from "../../assets/icons/menu-responsive-primary.svg";
import { DEFAULT_IMAGE } from "../../constants/globalConstanst";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { AuthState } from "../../model/states/AuthState";
import { NotificationsState } from "../../model/states/NotificationsState";
import { getSelectedDataById, isOwner, isStaffByOrganization } from "../../utils/utils";
import LmIcon from "../icon/LmIcon";

interface IMainMenuMobile {
  toggleMenu(): void;
  openEditProfile(): void;
  openChangePassword(): void;
  openModalConfirm(): void;
}

const MainMenuMobile: React.FC<IMainMenuMobile> = ({ toggleMenu, openEditProfile, openChangePassword, openModalConfirm }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);

  const selectedOrganizationData = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const isStaffByOrg = useMemo(() => isStaffByOrganization(selectedOrganizationData, stateAuth), [stateAuth.userInfo.selectedOrganization]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToPage = (page: string) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: page });
    navigate(page);
    toggleMenu();
  };

  const checkPermissions = () => {
    return isOwner(stateAuth, selectedOrganizationData?.id);
  };

  return (
    <>
      <div
        className="lm-menu-group-mobile fixed full-height overflow-y-scroll w-full flex flex-col gap-6 text-white bg-black p-6 z-[9999]"
        data-testid="main-menu-mobile">
        <div className="flex justify-between items-center pt-4">
          <LmIcon icon={<MenuResponsivePrimary />}></LmIcon>
          <LmIcon icon={<Close />} clickAction={toggleMenu}></LmIcon>
        </div>
        <div className="lm-menu-group-mobile__body">
          <ul className="space-y-6">
            <li className={"flex items-center gap-3 cursor-pointer transition hover:opacity-70"} onClick={() => goToPage(ROUTE_PRIVATE_DASHBOARD)}>
              <MenuHome className="w-5" /> <span className="font-decorative text-sm">{t("menu.home")}</span>
            </li>
            {checkPermissions() && (
              <li className={"flex items-center gap-3 cursor-pointer transition hover:opacity-70"} onClick={() => goToPage(ROUTE_PRIVATE_USERS)}>
                <MenuUsers className="w-5" /> <span className="font-decorative text-sm">{t("menu.users")}</span>
              </li>
            )}
            {isStaffByOrg && (
              <li className={"flex items-center gap-3 cursor-pointer transition hover:opacity-70"} onClick={() => goToPage(ROUTE_PRIVATE_TEAMS)}>
                <MenuTeams className="w-5" /> <span className="font-decorative text-sm">{t("menu.teams")}</span>
              </li>
            )}
            <li className={"flex items-center gap-3 cursor-pointer transition hover:opacity-70"} onClick={() => goToPage(ROUTE_PRIVATE_CONTENT_MANAGEMENT)}>
              <MenuContent className="w-5" /> <span className="font-decorative text-sm">{t("menu.content")}</span>
            </li>
            <li className={"flex items-center gap-3 cursor-pointer transition hover:opacity-70"} onClick={() => goToPage(ROUTE_PRIVATE_NOTIFICATIONS)}>
              <MenuNotifications className="w-5" /> <span className="font-decorative text-sm">{t("menu.notifications")}</span>
            </li>
            <li className={"flex items-center gap-3 cursor-pointer transition hover:opacity-70"} onClick={openEditProfile}>
              <MenuUsers className="w-5" /> <span className="font-decorative text-sm">{t("topMenu.editProfile")}</span>
            </li>
            <li className={"flex items-center gap-3 cursor-pointer transition hover:opacity-70"} onClick={openChangePassword}>
              <Asterisk className="w-5" /> <span className="font-decorative text-sm">{t("topMenu.editPassword")}</span>
            </li>
          </ul>
        </div>

        <div className="flex justify-between items-center mt-auto">
          <div className="space-x-3">
            <LmAvatar
              testid="badge-notification"
              src={stateAuth.avatarUserOrganization || selectedOrganizationData?.image || DEFAULT_IMAGE}
              className="user-avatar-test"
              variant="rounded"
              size={56}
              radius="12px"
              border={true}
              badge={stateNotifications.showUserNotification ? "miniBadge" : undefined}
              alt={t("topMenu.altIconUser")}
            />
            <span>
              {stateAuth.userInfo.data.user.name} {stateAuth.userInfo.data.user.lastName}
            </span>
          </div>
          <LmIcon icon={<LogoutIcon data-testid="test-logout-mobile" />} size="base" clickAction={openModalConfirm} />
        </div>
      </div>
    </>
  );
};
export default MainMenuMobile;
