import { AnyAction, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

import { combineReducers } from "redux";
import { mainReducer } from "./mainReducer";
import rootSaga from "../sagas/rootSaga";
import localforage from "localforage";
import { persistReducer, persistStore } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { MainState } from "../../model/states/MainState";
import { authReducer } from "./authReducer";
import { organizationReducer } from "./organizationReducer";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { teamReducer } from "./teamReducer";
import { playerReducer } from "./playerReducer";
import { contentManagementReducer } from "./contentManagementReducer";
import { notificationReducer } from "./notificationReducer";

const persistConfig = {
  key: "root",
  storage: localforage,
  stateReconciler: hardSet,
};

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers<MainState>({
  main: mainReducer,
  auth: authReducer,
  organization: organizationReducer,
  team: teamReducer,
  player: playerReducer,
  content: contentManagementReducer,
  notification: notificationReducer,
});
const reducers = (state: any, action: AnyAction) => {
  if (action.type === ActionAuthType.ACTION_LOGOUT) {
    localforage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer<MainState>(persistConfig, reducers);

export const store = configureStore({ reducer: persistedReducer, middleware: [sagaMiddleware] });
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
