import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import LmButton from "../button/LmButton";
import LmIcon from "../icon/LmIcon";
import { registerInitialValues, registerValidationSchema } from "./LmLoginForms.data";
import { ReactComponent as InputError } from "../../assets/icons/input-error.svg";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LmCharacteristicsPassword } from "./LmCharacteristicsPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_LOGIN } from "../../routes/routes";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { STATUS_INVITATION_COMPLETED } from "../../constants/globalConstanst";
import { ActionMainType } from "../../model/actions/typeMainActions";

interface IPassword {
  password: string;
  repeatPassword: string;
}

export function LmRegisterForm() {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [resetPassword, setResetPassword] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [typePassword, setTypePassword] = useState<IPassword>({
    password: "password",
    repeatPassword: "password",
  });
  /* En esta pantalla tenemos dos casos: 1.- Olvido de contraseña. 2.- Acceso como invitado 
    En el primer caso nos viene el parámetro code en la url y en el segundo el parametro id
    En función de cada caso se muestra o no el check Stay logged y el texto del botón. Ya que en 
    el segundo caso, al introducir la contraseña se loga al usuario.
    Se usa resetPassword para distinguir los dos casos,
    Si accedemos a la url sin ninguno de los dos parametros navegamos al login
  */

  useEffect(() => {
    const paramCode = searchParams.get("code");
    const paramId = searchParams.get("invitationId");
    if (paramCode || paramId) {
      setResetPassword(paramCode !== null);
    } else {
      navigate(ROUTE_LOGIN);
    }
    if (paramId) {
      dispatch({ type: ActionAuthType.ACTION_CHECK_INVITATIONS_LOADING, value: paramId });
    }
  }, []);

  /* En el caso de resetear con exito la contraseña, navegamos al login. Y se vuelve a poner la variable de estado 
  resetPassword: {ok: false} */

  useEffect(() => {
    if (stateAuth.resetPassword?.ok) {
      dispatch({ type: ActionAuthType.ACTION_PASSWORD_RESET_SUCCESS, value: false });
      navigate(ROUTE_LOGIN);
    }
  }, [stateAuth.resetPassword]);

  useEffect(() => {
    /* Si es un registro y ya se ha usado, mostrar error y volver al login */
    if (
      !resetPassword &&
      stateAuth.checkInvitation?.ok &&
      stateAuth.checkInvitation?.data &&
      stateAuth.checkInvitation?.data?.status === STATUS_INVITATION_COMPLETED
    ) {
      navigate(ROUTE_LOGIN);
      dispatch({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.errorInvitation"), severity: "error" } });
    }
  }, [stateAuth.checkInvitation]);

  const formik = useFormik({
    initialValues: registerInitialValues(),
    validationSchema: registerValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = async (formValue: any) => {
    if (resetPassword) {
      dispatch<TypeActions>({
        type: ActionAuthType.ACTION_PASSWORD_RESET_LOADING,
        value: {
          data: {
            code: searchParams.get("code") as string,
            newPassword: formValue.password,
          },
          message: t("editUser.messagePasswordChanged"),
        },
      });
    } else {
      dispatch<TypeActions>({
        type: ActionAuthType.ACTION_INVITATIONS_LOADING,
        value: {
          data: {
            invitationId: searchParams.get("invitationId") as string,
            password: formValue.password,
          },
          message: t("editUser.messagePasswordCreate"),
        },
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHECK_STAY_LOGGED, value: event.target.checked });
  };

  const onClickChangeType = (field: string) => {
    const type: IPassword = { ...typePassword };
    type[field as keyof IPassword] = typePassword[field as keyof IPassword] === "password" ? "text" : "password";
    setTypePassword(type);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full max-w-sm" data-testid="login-register">
        <div className="relative">
          <input
            type={typePassword.password}
            id="password"
            data-testid="password"
            name="password"
            placeholder={`${t("formLabel.password")}`}
            className={`w-full text-lg leading-7 p-4 rounded pr-12 bg-white/80 ${
              formik.touched.password && formik.errors.password ? "border border-solid border-error-500" : ""
            }`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <div className="absolute top-6 right-4 cursor-pointer">
            <LmIcon clickAction={() => onClickChangeType("password")} icon={<Eye />} size="xs" />
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="flex items-center gap-2 my-3" data-testid="error-password">
              <LmIcon icon={<InputError />} size="xs" />
              <span className="text-error-500">{formik.errors.password}</span>
            </div>
          )}
        </div>
        <div className="relative">
          <input
            type={typePassword.repeatPassword}
            id="repeatPassword"
            data-testid="repeatPassword"
            name="repeatPassword"
            placeholder={`${t("formLabel.repeatPassword")}`}
            className={`w-full text-lg leading-7 p-4 rounded pr-12 bg-white/80 ${
              formik.touched.repeatPassword && formik.errors.repeatPassword ? "border border-solid border-error-500" : ""
            }`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.repeatPassword}
          />
          <div className="absolute top-6 right-4 cursor-pointer">
            <LmIcon clickAction={() => onClickChangeType("repeatPassword")} icon={<Eye />} size="xs" />
          </div>
          {formik.touched.repeatPassword && formik.errors.repeatPassword && (
            <div className="flex items-center gap-2 my-3" data-testid="error-repeat-password">
              <LmIcon icon={<InputError />} size="xs" />
              <span className="text-error-500">{formik.errors.repeatPassword}</span>
            </div>
          )}
        </div>
        {!resetPassword && (
          <div>
            <FormControlLabel
              className="text-white"
              control={<Checkbox data-testid="stay-logged" onChange={handleChange} sx={{ color: "#ffffff", "&.Mui-checked": { color: "#45E9AD" } }} />}
              label={<p>{t("login.stayLogged")}</p>}
              sx={{ ".MuiFormControlLabel-label": { fontFamily: "var(--bv-font-family-brand), sans-serif", fontSize: "0.875rem" } }}
            />
          </div>
        )}
        <LmButton
          testid="register-button"
          styleButton="primary"
          type="solid"
          buttonType="submit"
          size="medium"
          text={resetPassword ? t("register.resetPassword") : t("register.button")}
          iconPosition="right"
          isDisabled={
            !formik.isValid ||
            formik.isSubmitting ||
            // stateAuth.resetPassword?.loading ||
            // stateAuth.login?.loading ||
            (formik.values.password === "" && formik.values.repeatPassword === "")
          }></LmButton>

        <div className="bg-white mt-1 p-4 rounded">
          <LmCharacteristicsPassword />
        </div>
      </form>
    </>
  );
}
