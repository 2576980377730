import { put, takeLatest, call, all, select, delay } from "redux-saga/effects";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import ResponseApiError from "../../model/dto/ResponseApiError";
import { AuthState } from "../../model/states/AuthState";
import { getFetchParams } from "./utilsSaga";
import { t } from "i18next";
import { MainState } from "../../model/states/MainState";
import {
  ActionDeleteNotificationsLoading,
  ActionMarkNotificationsReadLoading,
  ActionMarkNotificationsUnreadLoading,
  ActionNotificationsType,
  TypeNotificationsActions,
} from "../../model/actions/typeNotifications";
import NotificationDto from "../../model/dto/NotificationDto";
import { DEFAULT_IMAGE } from "../../constants/globalConstanst";

const baseUrlApi = process.env.REACT_APP_API_URL + "Notification";

function* watchFetchNotifications() {
  while (true) {
    yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_LOADING });
    yield delay(120000);
  }
}

export function* getNotificationsByOrganization() {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    if (state.userInfo.selectedOrganization !== "") {
      const response: Response = yield call(
        fetch,
        baseUrlApi + "/byOrganizationId/" + state.userInfo.selectedOrganization + "?locale=" + state.appLanguage,
        getFetchParams("GET", null, state.login.loginResponse?.token)
      );

      if (response.ok) {
        const orgData = state.userInfo.data.organizations.find((org) => org.id === state.userInfo.selectedOrganization);
        const data: NotificationDto[] = yield call([response, "json"]);
        const formatData = data.map((notification) => {
          if (notification.fromUserId) {
            const avatarImage = orgData?.users?.find((user) => user.userId === notification.fromUserId)?.avatarImage;
            return { ...notification, avatarImage };
          } else {
            return { ...notification, avatarImage: DEFAULT_IMAGE };
          }
        });
        const sorterList = formatData?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_SUCCESS, value: sorterList });
      } else {
        const data: ResponseApiError = yield call([response, "json"]);
        yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
        yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_ERROR });
      }
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_GET_NOTIFICATIONS_ERROR });
  }
}

export function* markNotificationsAsUnread(action: ActionMarkNotificationsUnreadLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = { notificationIds: action.value };
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/notifications/unread?locale=" + state.appLanguage,
      getFetchParams("PUT", params, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: NotificationDto[] = yield call([response, "json"]);
      yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_ERROR });
  }
}

export function* markNotificationsAsRead(action: ActionMarkNotificationsReadLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = { notificationIds: action.value };
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/notifications/read?locale=" + state.appLanguage,
      getFetchParams("PUT", params, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: NotificationDto[] = yield call([response, "json"]);
      yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_ERROR });
  }
}

export function* deleteNotifications(action: ActionDeleteNotificationsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = { notificationIds: action.value };
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/notifications?locale=" + state.appLanguage,
      getFetchParams("DELETE", params, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: NotificationDto[] = yield call([response, "json"]);
      yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_ERROR });
    }
  } catch (e) {
    yield put<TypeNotificationsActions>({ type: ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_ERROR });

    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getNotificationsWhatcher() {
  yield takeLatest(ActionNotificationsType.ACTION_GET_NOTIFICATIONS_LOADING, getNotificationsByOrganization);
}
export function* markNotificationsAsUnreadWhatcher() {
  yield takeLatest(ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_UNREAD_LOADING, markNotificationsAsUnread);
}
export function* markNotificationsAsReadWhatcher() {
  yield takeLatest(ActionNotificationsType.ACTION_MARK_NOTIFICATIONS_READ_LOADING, markNotificationsAsRead);
}
export function* deleteNotificationsWhatcher() {
  yield takeLatest(ActionNotificationsType.ACTION_DELETE_NOTIFICATIONS_LOADING, deleteNotifications);
}

export default function* rootTagsSaga() {
  yield all([
    getNotificationsWhatcher(),
    markNotificationsAsUnreadWhatcher(),
    markNotificationsAsReadWhatcher(),
    deleteNotificationsWhatcher(),
    watchFetchNotifications(),
  ]);
}
