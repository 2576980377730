import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import { IdValue } from "../../model/forms/IdValue";
import { t } from "i18next";
import LmButton from "../button/LmButton";
import { ReactComponent as ClearIcon } from "../../assets/icons/x.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { useSelector } from "react-redux";
import { EventType, IFilterApplied } from "../../model/dto/ProjectDto";
import { InitialState } from "../../model/states/InitialState";

interface IProjectAnalysisFilter {
  filterAplied: IFilterApplied;
  listView: number;
  filter(dataFiltered: IFilterApplied): void;
  handleClose(): void;
}

const ProjectAnalysisFilter: React.FC<IProjectAnalysisFilter> = ({ filterAplied, listView, filter, handleClose }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const [eventType, setEventType] = useState<IdValue[]>([]);
  const [eventTypeValue, setEventTypeValue] = useState("");

  const [tags, setTags] = useState<IdValue[]>([]);
  const [tagsValue, setTagsValue] = useState("");

  const [teams, setTeams] = useState<IdValue[]>([]);
  const [teamsValue, setTeamsValue] = useState("");

  const [player, setPlayer] = useState<IdValue[]>([]);
  const [playerValue, setPlayerValue] = useState("");

  const [period, setPeriod] = useState<IdValue[]>([]);
  const [periodValue, setPeriodValue] = useState("");

  useEffect(() => {
    setEventType(filterAplied.event.length > 0 ? eventTypeOptions.filter((event) => filterAplied.event.indexOf(event.id) !== -1) : []);
    setTags(filterAplied.tags.length > 0 ? tagsOptions.filter((tag) => filterAplied.tags.indexOf(tag.id) !== -1) : []);
    setTeams(filterAplied.teams.length > 0 ? teamsOptions.filter((team) => filterAplied.teams.indexOf(team.id) !== -1) : []);
    setPlayer(filterAplied.player.length > 0 ? playerOptions.filter((player) => filterAplied.player.indexOf(player.id) !== -1) : []);
    setPeriod(filterAplied.period.length > 0 ? periodOptions.filter((period) => filterAplied.period.indexOf(period.id) !== -1) : []);
  }, [filterAplied]);

  const eventTypeOptions: IdValue[] = useMemo(() => {
    return stateContent.project.eventsCategory.map((event) => {
      return { id: event.event, value: event.name };
    });
  }, []);

  const tagsOptions: IdValue[] = useMemo(() => {
    let listTags: IdValue[] = [];

    if (eventType.length > 0) {
      const eventsIdSelected = eventType.map((event) => event.id);
      stateContent.project.data?.Dashboard.List.filter((event) => eventsIdSelected.indexOf((event.EventType as EventType)?.$id) !== -1).forEach((event) =>
        (event.EventType as EventType).Tags?.forEach((tag) => {
          if (!listTags.find((t) => t.value === tag.Value)) {
            listTags = listTags.concat([{ id: tag.Value, value: tag.Value }]);
          }
        })
      );
    } else {
      stateContent.project.data?.Dashboard.List.forEach((event) =>
        (event.EventType as EventType)?.Tags?.forEach((tag) => {
          if (!listTags.find((t) => t.value === tag.Value)) {
            listTags = listTags.concat([{ id: tag.Value, value: tag.Value }]);
          }
        })
      );
    }

    return listTags;
  }, [eventType]);

  const teamsOptions: IdValue[] = useMemo(() => {
    if (listView === 0) {
      return [
        { id: stateContent.project.data?.LocalTeamTemplate.$id ?? "", value: stateContent.project.data?.LocalTeamTemplate.Name ?? "" },
        { id: stateContent.project.data?.VisitorTeamTemplate.$id ?? "", value: stateContent.project.data?.VisitorTeamTemplate.Name ?? "" },
      ];
    } else if (listView === 1) {
      return [{ id: stateContent.project.data?.LocalTeamTemplate.$id ?? "", value: stateContent.project.data?.LocalTeamTemplate.Name ?? "" }];
    } else {
      return [{ id: stateContent.project.data?.VisitorTeamTemplate.$id ?? "", value: stateContent.project.data?.VisitorTeamTemplate.Name ?? "" }];
    }
  }, [listView]);

  const playerOptions: IdValue[] = useMemo(() => {
    const idLocalTeam = stateContent.project.data?.LocalTeamTemplate.$id;
    const idVisitorTeam = stateContent.project.data?.VisitorTeamTemplate.$id;

    const playersLocal: IdValue[] = stateContent.project.data
      ? stateContent.project.data?.LocalTeamTemplate.List.map((player) => {
          const playerName = player.Name ?? "";
          const playerSurname = player.LastName ?? "";
          return { id: player.$id, value: playerName + " " + playerSurname };
        })
      : [];
    const playersVisitor: IdValue[] = stateContent.project.data
      ? stateContent.project.data?.VisitorTeamTemplate.List.map((player) => {
          const playerName = player.Name ?? "";
          const playerSurname = player.LastName ?? "";
          return { id: player.$id, value: playerName + " " + playerSurname };
        })
      : [];

    let players: IdValue[] = [];
    if ((teams.length === 0 && listView === 0) || listView === 1 || teams.map((team) => team.id).indexOf(idLocalTeam ?? "") !== -1) {
      players = players.concat(playersLocal);
    }
    if ((teams.length === 0 && listView === 0) || listView === 2 || teams.map((team) => team.id).indexOf(idVisitorTeam ?? "") !== -1) {
      players = players.concat(playersVisitor);
    }
    return players;
  }, [teams, listView]);

  const periodOptions: IdValue[] = useMemo(() => {
    return stateContent.project.data
      ? stateContent.project.data?.Periods.map((period) => {
          return { id: period.$id, value: period.Name };
        })
      : [];
  }, []);

  const selectedValueEventType = (e: ChangeEvent<HTMLInputElement>) => {
    setEventTypeValue(e.target.value);
  };

  const selectedEventType = (options: IdValue[]) => {
    setEventType(options as IdValue[]);
    setEventTypeValue("");
  };

  const selectedValueTags = (e: ChangeEvent<HTMLInputElement>) => {
    setTagsValue(e.target.value);
  };

  const selectedTags = (options: IdValue[]) => {
    setTags(options as IdValue[]);
    setTagsValue("");
  };

  const selectedValueTeams = (e: ChangeEvent<HTMLInputElement>) => {
    setTeamsValue(e.target.value);
  };

  const selectedTeams = (options: IdValue[]) => {
    setTeams(options as IdValue[]);
    setTeamsValue("");
  };

  const selectedValuePlayer = (e: ChangeEvent<HTMLInputElement>) => {
    setTeamsValue(e.target.value);
  };

  const selectedPlayer = (options: IdValue[]) => {
    setPlayer(options as IdValue[]);
    setPlayerValue("");
  };

  const selectedValuePeriod = (e: ChangeEvent<HTMLInputElement>) => {
    setPeriodValue(e.target.value);
  };

  const selectedPeriod = (options: IdValue[]) => {
    setPeriod(options as IdValue[]);
    setPeriodValue("");
  };

  const onFilter = () => {
    filter({
      event: eventType.map((event) => event.id),
      tags: tags.map((tag) => tag.id),
      teams: teams.map((team) => team.id),
      player: player.map((player) => player.id),
      period: period.map((period) => period.id),
    });
    handleClose();
  };

  const clearFilter = () => {
    setEventType([]);
    setTags([]);
    setTeams([]);
    setPlayer([]);
    setPeriod([]);
  };

  return (
    <div className={`m-4 min-w-0 sm:min-w-[20rem] theme-${stateMain.colorAplication}`}>
      <div>
        <LmAutocomplete
          id="eventType"
          label={t("formLabel.filterEvenType") as string}
          value={eventType}
          valueDisplay={eventTypeValue}
          setValueDisplay={selectedValueEventType}
          multiple={true}
          options={eventTypeOptions as IdValue[]}
          selectedValue={(options) => selectedEventType(options as IdValue[])}
        />
      </div>
      <div>
        <LmAutocomplete
          id="tags"
          label={t("formLabel.tags") as string}
          value={tags}
          valueDisplay={tagsValue}
          setValueDisplay={selectedValueTags}
          multiple={true}
          options={tagsOptions as IdValue[]}
          selectedValue={(options) => selectedTags(options as IdValue[])}
        />
      </div>
      <div>
        <LmAutocomplete
          id="team"
          label={t("formLabel.teamTagged") as string}
          value={teams}
          valueDisplay={teamsValue}
          setValueDisplay={selectedValueTeams}
          multiple={true}
          options={teamsOptions as IdValue[]}
          selectedValue={(options) => selectedTeams(options as IdValue[])}
        />
      </div>
      <div>
        <LmAutocomplete
          id="player"
          label={t("formLabel.playerTagged") as string}
          value={player}
          valueDisplay={playerValue}
          setValueDisplay={selectedValuePlayer}
          multiple={true}
          options={playerOptions as IdValue[]}
          selectedValue={(options) => selectedPlayer(options as IdValue[])}
        />
      </div>
      <div>
        <LmAutocomplete
          id="period"
          label={t("formLabel.period") as string}
          value={period}
          valueDisplay={periodValue}
          setValueDisplay={selectedValuePeriod}
          multiple={true}
          options={periodOptions as IdValue[]}
          selectedValue={(options) => selectedPeriod(options as IdValue[])}
        />
      </div>
      <div className="flex justify-end mt-8 space-x-2">
        <div className="add-button-container">
          <LmButton
            styleButton="secondary"
            buttonType="submit"
            type="solid"
            size="extra-small"
            text={t("buttonLabel.clear")}
            icon={<ClearIcon />}
            iconPosition="center"
            clickAction={clearFilter}
          />
        </div>
        <div className="add-button-container">
          <LmButton
            styleButton="organization"
            type="solid"
            size="extra-small"
            text={t("buttonLabel.search")}
            iconPosition="left"
            clickAction={onFilter}
            icon={<SearchIcon />}></LmButton>
        </div>
      </div>
    </div>
  );
};

export default ProjectAnalysisFilter;
