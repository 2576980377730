import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import LmButton from "../button/LmButton";
import { useTranslation } from "react-i18next";
import LmInput from "../input/LmInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import LmIcon from "../icon/LmIcon";
import LmMessageError from "../message-error/LmMessageError";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import LoadImage from "../load-image/LoadImage";
import LmCheckBox from "../check-box/LmCheckBox";
import { MainState } from "../../model/states/MainState";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../model/states/AuthState";
import UserDto from "../../model/dto/UserDto";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { t } from "i18next";
import { DEFAULT_IMAGE } from "../../constants/globalConstanst";
import LmSelect from "../select/LmSelect";
import { IdValue } from "../../model/forms/IdValue";
import { changeLocale, getAllLocales } from "../../utils/utils";
import { InitialState } from "../../model/states/InitialState";

interface IModalUserEdit {
  open: boolean;
  unsubscribe(): void;
  handleClose(): void;
}

export function userInitialValues(dataInfo: UserDto) {
  return {
    name: dataInfo.name,
    lastName: dataInfo?.lastName,
    email: dataInfo.email,
    notifyCommentsOnMyContent: dataInfo.notifyCommentsOnMyContent,
    notifyWhenContentSharedWithMe: dataInfo.notifyWhenContentSharedWithMe,
    notifyWhenSomeoneTaggedMe: dataInfo.notifyWhenSomeoneTaggedMe,
  };
}

export function userValidationSchema() {
  return Yup.object({
    name: Yup.string().required(t("errors.form.required") as string),
    lastName: Yup.string().required(t("errors.form.required") as string),
  });
}

const ModalEditUser: React.FC<IModalUserEdit> = ({ open, handleClose, unsubscribe }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [image, setImage] = useState(stateAuth.avatarUserOrganization as string);
  const [options, setOptions] = useState<IdValue[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<IdValue>();
  const [isLocaleChanged, setIsLocaleChanged] = useState(false);
  const formik = useFormik({
    initialValues: userInitialValues(stateAuth.userInfo.data.user),
    validationSchema: userValidationSchema(),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    getUserLocale();
    setOptions(getAllLocales());
  }, [open]);

  useEffect(() => {
    setImage(stateAuth.avatarUserOrganization as string);
  }, [stateAuth.avatarUserOrganization]);

  useEffect(() => {
    if (stateAuth.editProfile?.ok) {
      handleClose();
    }
  }, [stateAuth.editProfile]);

  const onSubmit = async (formValue: any) => {
    dispatch({
      type: ActionAuthType.ACTION_USER_PROFILE_LOADING,
      value: formValue,
    });
    if (image !== stateAuth.avatarUserOrganization || isLocaleChanged) {
      dispatch({
        type: ActionAuthType.ACTION_USER_PROFILE_IMAGE_LOADING,
        value: {
          id: stateAuth.userInfo.selectedOrganization,
          image: image || (stateAuth.avatarUserOrganization as string) || DEFAULT_IMAGE,
          defaultLanguage: selectedLanguages?.id,
        },
      });
      dispatch({
        type: ActionAuthType.ACTION_STATE_CHANGE_LOCALE,
        value: selectedLanguages?.id,
      });
      changeLocale(selectedLanguages?.id || "");
    }
  };

  const getUserLocale = () => {
    const userLocale = getAllLocales().find((locale) => locale.id === stateAuth.appLanguage);
    setSelectedLanguages(userLocale);
  };

  const changeImage = (image: string) => {
    setImage(image);
  };

  const onClose = () => {
    handleClose();
    setImage((stateAuth.avatarUserOrganization as string) || DEFAULT_IMAGE);
    formik.resetForm();
  };

  const onchangeLocale = (option: IdValue) => {
    setIsLocaleChanged(true);
    setSelectedLanguages(option);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8" data-testid="modal-edit-user-profile">
        <div className="flex justify-between mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl">{t("editUser.title")}</p>
          <div className="cursor-pointer">
            <LmIcon icon={<Close />} size="md" clickAction={onClose}></LmIcon>
          </div>
        </div>

        <div>
          <div className="flex justify-center mb-5 md:mb-6">
            <LoadImage size="lg" img={image} id="image" onChangeImage={changeImage} />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-5 md:space-y-6 mb-8 md:mb-12">
              <div>
                <LmInput
                  required={true}
                  id="name"
                  label={t("formLabel.name")}
                  changeValue={formik.handleChange}
                  blur={formik.handleBlur}
                  value={formik.values.name}></LmInput>
                {formik.touched.name && formik.errors.name && <LmMessageError id="name" message={formik.errors.name}></LmMessageError>}
              </div>

              <div>
                <LmInput
                  required={true}
                  id="lastName"
                  label={t("formLabel.lastName")}
                  changeValue={formik.handleChange}
                  blur={formik.handleBlur}
                  value={formik.values.lastName}></LmInput>
                {formik.touched.lastName && formik.errors.lastName && <LmMessageError id="lastName" message={formik.errors.lastName}></LmMessageError>}
              </div>

              <LmInput
                required={true}
                id="email"
                label={t("formLabel.email")}
                disabled={true}
                changeValue={formik.handleChange}
                blur={formik.handleBlur}
                value={formik.values.email}></LmInput>

              <LmSelect
                label={t("formLabel.language")}
                required
                id="language-select"
                options={options}
                selectedValue={onchangeLocale}
                value={selectedLanguages}
                prefix={selectedLanguages?.icon}
              />

              <p className="text-sm font-semibold">{t("editUser.titleNotifications")}</p>

              <div className="space-y-4 text-sm font-semibold">
                <LmCheckBox
                  text={t("editUser.mentioned")}
                  id="notifyCommentsOnMyContent"
                  value={formik.values.notifyCommentsOnMyContent}
                  changeValue={formik.handleChange}></LmCheckBox>
                <LmCheckBox
                  text={t("editUser.shared")}
                  id="notifyWhenContentSharedWithMe"
                  value={formik.values.notifyWhenContentSharedWithMe}
                  changeValue={formik.handleChange}></LmCheckBox>
                <LmCheckBox
                  text={t("editUser.added")}
                  id="notifyWhenSomeoneTaggedMe"
                  value={formik.values.notifyWhenSomeoneTaggedMe}
                  changeValue={formik.handleChange}></LmCheckBox>
              </div>
            </div>
            <div className="flex gap-2 justify-end flex-wrap">
              <LmButton
                text={t("editUser.unsubscribe")}
                buttonType="button"
                icon={<Close />}
                type="secondary"
                styleButton="clear"
                clickAction={unsubscribe}></LmButton>
              <LmButton text={t("editUser.buttonCancel")} buttonType="button" type="secondary" clickAction={onClose}></LmButton>
              <LmButton
                text={t("editUser.buttonSave")}
                type="organization"
                buttonType="submit"
                isDisabled={!formik.isValid || stateAuth.editProfile?.loading || stateAuth.editImageProfile?.loading}></LmButton>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalEditUser;
