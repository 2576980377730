import { t } from "i18next";
import { useState } from "react";

import { SizeScreen } from "../../model/SizeSreen";
import { CommentDto } from "../../model/dto/CommentDto";

import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import { ReactComponent as SendIcon } from "../../assets/icons/send-white.svg";
import LmButton from "../button/LmButton";
import CommentListItem from "./CommentListItem";

import UserDto from "../../model/dto/UserDto";
import mentionsInputStyle from "../content-management/mentionsInputStyle";
interface Props {
  comments: CommentDto[];
  parentComment: CommentDto | null;
  screenSize: SizeScreen;
  users: Partial<UserDto>[];
  loggedUser: UserDto;
  isReadMode?: boolean;
  saveComment?: (text: string, parentCommentId: string) => void;
  likeComment?: (commentId: string) => void;
  unlikeComment?: (commentId: string) => void;
  deleteComment?: (commentId: string, isParent: boolean) => void;
  viewAnswers?: (commentId: string) => void;
  goToParentComment?: () => void;
}

const LmCommentList = ({
  comments,
  parentComment,
  screenSize,
  users,
  loggedUser,
  isReadMode = false,
  saveComment,
  likeComment,
  unlikeComment,
  deleteComment,
  viewAnswers,
  goToParentComment,
}: Props) => {
  const [commentText, setCommentText] = useState("");

  const onSaveComment = () => {
    if (saveComment) {
      saveComment(commentText, parentComment?.id || "");
    }
    setCommentText("");
  };

  const reply = (text: string, parentCommentId: string) => {
    if (saveComment) saveComment(text, parentCommentId);
  };

  return (
    <div className="mt-5">
      <div className={`${parentComment && "border border-neutral-200 rounded mb-5"} ${isReadMode && "flex flex-col-reverse"}`}>
        {parentComment && (
          <div className="p-5 border-b border-neutral-200">
            <CommentListItem
              isReadMode={isReadMode}
              comment={parentComment}
              screenSize={screenSize}
              users={users}
              loggedUser={loggedUser}
              isParent={true}
              viewAnswers={viewAnswers}
              reply={reply}
              like={likeComment}
              unlike={unlikeComment}
              deleteComment={deleteComment}
              goToParentComment={goToParentComment}></CommentListItem>
          </div>
        )}
        {comments.map((comment: CommentDto) => (
          <div
            className={parentComment ? "ml-5 last:mb-5 p-5 border-l border-neutral-200 answer relative" : "border border-neutral-200 rounded p-5 mb-5"}
            key={comment.id}>
            <CommentListItem
              isReadMode={isReadMode}
              comment={comment}
              screenSize={screenSize}
              users={users}
              loggedUser={loggedUser}
              viewAnswers={viewAnswers}
              reply={reply}
              like={likeComment}
              unlike={unlikeComment}
              deleteComment={deleteComment}></CommentListItem>
          </div>
        ))}
      </div>

      {!isReadMode && (
        <div>
          <MentionsInput
            value={commentText}
            placeholder={t("content.comment") as string}
            style={mentionsInputStyle}
            onChange={(e) => setCommentText(e.target.value)}>
            <Mention
              trigger="@"
              data={users.map((user: Partial<UserDto>) => ({
                id: user?.id,
                display: `${user?.name} ${user?.lastName}`,
              })) as SuggestionDataItem[]}
              appendSpaceOnAdd={true}
              displayTransform={(id, display) => `@<${display}>`}
              markup="@<__display__>"
              renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                <div className={`user p-2 ${focused ? "focused" : ""}`}>{highlightedDisplay}</div>
              )}
            />
          </MentionsInput>
          <div className="mt-2 flex justify-end lm-button__icon">
            <LmButton
              styleButton="organization"
              buttonType="button"
              type="solid"
              size="small"
              isDisabled={!commentText.length}
              icon={<SendIcon className="lm-button__icon"/>}
              clickAction={onSaveComment}></LmButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default LmCommentList;
