import { Collapse, List } from "@mui/material";
import { useEffect, useState } from "react";

import { EventType, Player, ProjectCategoryDto, ProjectDto, Ref, TeamPlayer, TeamType, Timeline } from "../../model/dto/ProjectDto";

import MatchStatisticCategory from "./MatchStatisticCategory";
import MatchStatisticCategoryViewer from "./MatchStatisticCategoryViewer";
import MatchStatisticGraph from "./MatchStatisticGraph";
import MatchStatisticPlayerList from "./MatchStatisticPlayerList";
import MatchStatisticTeamList from "./MatchStatisticTeamList";
import MatchStatisticTeamSummary from "./MatchStatisticTeamSummary";
import { getEventScoreProject, getProjectDataTeamPlayer } from "../../utils/utils";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
interface Props {
  statistic: ProjectDto;
  eventsCategory: ProjectCategoryDto[];
  categoryNavigation: (categoryName: string) => void;
}

const MatchStatisticTeam = ({ statistic, eventsCategory, categoryNavigation }: Props) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);

  const [localOpen, setLocalOpen] = useState(false);
  const [visitorOpen, setVisitorOpen] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [selectedPlayerId, setSelectedPlayerId] = useState("");
  const [categories, setCategories] = useState<ProjectCategoryDto[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ProjectCategoryDto | null>(null);
  const [playerList, setPlayerList] = useState<TeamPlayer[]>([]);

  const [elapsedTime, setElapsedTime] = useState(0);
  const [points, setPoints] = useState(0);
  const [admonition, setAdmonition] = useState(0);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);

  const onTeamClick = (teamId: string) => {
    setSelectedPlayerId("");
    if (teamId !== selectedTeamId) {
      setSelectedCategory(null);
    }
    setSelectedTeamId(teamId);
  };

  const onPlayerClick = (playerId: string) => {
    setSelectedTeamId("");
    if (playerId !== selectedPlayerId) {
      setSelectedCategory(null);
    }
    setSelectedPlayerId(playerId);
  };

  const onTeamExpand = (type: TeamType) => {
    if (type === TeamType.LOCAL) {
      setLocalOpen(!localOpen);
      setVisitorOpen(false);
    } else {
      setLocalOpen(false);
      setVisitorOpen(!visitorOpen);
    }

    setPlayers(type);
  };

  useEffect(() => {    
    const eventTypeList = statistic?.Dashboard.List.filter(item => !!item.EventType).map(item => {      
      const eventType: EventType = {...item.EventType as EventType};
      if (!(item.EventType as EventType)?.Score){       
        eventType.Score = item.Score;
      }
      if (!(item.EventType as EventType)?.PenaltyCard){       
        eventType.PenaltyCard = item.PenaltyCard;
      }
      return eventType as EventType;
    }).flat();
    setEventTypes(eventTypeList );
  }, []);

  useEffect(() => {
    if(selectedTeamId) {     
      const categories = eventsCategory.filter(event => event?.name && event?.timelines.some(timeline =>        
        timeline?.Teams?.length && timeline?.Teams?.map(t => t.$ref).includes(selectedTeamId) || 
        timeline?.Players?.map((player: Ref) => getProjectDataTeamPlayer(player?.$ref, stateContent.project.data as ProjectDto).$id).includes(selectedTeamId))
      );

      setCategories(categories);

      const eventIds = categories.map(c => c.event);
      const scoreEventId = eventTypes.find((event: EventType) => event.Score)?.$id;
      const admonitionEventIds = eventTypes.filter((event: EventType) => event.PenaltyCard).map((event: EventType) => event.$id);
      
      const teamPoints = eventIds.filter((id: string) => id === (scoreEventId ?? "")).length;
      setPoints(teamPoints);

      const teamAdmonitions = eventIds.filter((id: string) => admonitionEventIds.includes(id)).length;
      setAdmonition(teamAdmonitions);

      const playedTime = statistic.Periods.at(-1)?.Nodes.at(-1)?.Stop ?? "0";
      setElapsedTime(+playedTime);
    }
  }, [selectedTeamId]);

  useEffect(() => {
    if (selectedPlayerId) {
      const categories = eventsCategory.filter(
        (event) => event.name && event.timelines.some((timeline) => timeline.Players.length && timeline.Players.map((p) => p.$ref).includes(selectedPlayerId))
      );
      setCategories(categories);
      const scoreEventId = eventTypes.filter((event: EventType) => event.Score)?.map(event => event.$id);

      const admonitionEventIds = eventTypes.filter((event: EventType) => event.PenaltyCard).map((event: EventType) => event.$id);
      
      const scoreTimelinePlayers = categories.filter((category: ProjectCategoryDto) => scoreEventId.indexOf(category.event) !== -1)
        .map((category: ProjectCategoryDto) => category.timelines).flat()
        .map((timeline: Timeline) => timeline).flat()
        .filter((timeline: Timeline) => timeline?.Players[0].$ref === selectedPlayerId)
        .reduce((accumulator, currentValue) => {
        const points = getEventScoreProject(stateContent.project.data as ProjectDto, currentValue)?.Points ?? 0
        return accumulator + points}
      , 0) 
         
       setPoints(scoreTimelinePlayers);

      if(admonitionEventIds.length) {
        const admonitionTimelinePlayers = categories.filter((category: ProjectCategoryDto) => admonitionEventIds.includes(category.event))
          .map((category: ProjectCategoryDto) => category.timelines).flat()
          .map((timeline: Timeline) => timeline.Players).flat()
          .filter((player: Player) => player.$ref === selectedPlayerId).length;

        setAdmonition(admonitionTimelinePlayers);
      } else {
        setAdmonition(0);
      }

      const substitutionEventId = (statistic.EventTypes.find((event: EventType | Ref) => "$id" in event) as EventType)?.$id;
      const substitutions = statistic.Timeline.filter((timeline: Timeline) => (timeline.EventType as Ref).$ref === substitutionEventId).filter((substitution: Timeline) => !substitution.HomeStartingPlayers);
      const playerSubstitutions = substitutions.filter((substitution: Timeline) => substitution.In?.$ref === selectedPlayerId || substitution.Out?.$ref === selectedPlayerId);
      const endTime = statistic.Periods.at(-1)?.Nodes.at(-1)?.Stop ?? "0";

      if(!playerSubstitutions.length) {
        const playedTime = statistic.Periods.at(-1)?.Nodes.at(-1)?.Stop ?? "0";
        setElapsedTime(+playedTime);
        return;
      }

      if(playerSubstitutions.length === 1) {

        const substitution = playerSubstitutions.at(0)!;
        const playedTime = substitution.Out?.$ref === selectedPlayerId ? substitution.EventTime : (+endTime - substitution.EventTime);
        setElapsedTime(+playedTime);
        return;
      }
      
      let sumPlayedTime = 0;

      // more than one substitution
      playerSubstitutions.forEach((subs, index) => {
        if(index === 0) {
          // first substitution: out = from the begining else nextEvent time - this event time
          sumPlayedTime = subs.Out?.$ref === selectedPlayerId ? subs.EventTime : +(playerSubstitutions.at(index + 1)?.EventTime ?? 0) - subs.EventTime;
        } else {
          // not next out = played for the rest of the game
          if(playerSubstitutions.at(index + 1)?.Out?.$ref === selectedPlayerId) {
            sumPlayedTime = +endTime - subs.EventTime
          } else {
            // anothes substitution next, sum = next event time - this event time
            sumPlayedTime = +(playerSubstitutions.at(index + 1)?.EventTime ?? 0) - subs.EventTime;
          }
        }
      });

      setElapsedTime(sumPlayedTime);

    }
  }, [selectedPlayerId]);

  const setPlayers = (type: TeamType) => {    
    const lineupType: "HomeStartingPlayers" | "AwayStartingPlayers" = type === TeamType.LOCAL ? "HomeStartingPlayers": "AwayStartingPlayers";
    const teamId = type === TeamType.LOCAL ? statistic.LocalTeamTemplate.$id : statistic.VisitorTeamTemplate.$id;

    const substitutionEventId = (statistic.EventTypes.find((event: EventType | Ref) => "$id" in event) as EventType)?.$id;
    const substitutionEvent = statistic.Timeline.filter((timeline: Timeline) => (timeline.EventType as Ref).$ref === substitutionEventId);
    const startingLineup = substitutionEvent.filter((substitution: Timeline) => substitution[lineupType]).map((substitution: Timeline) => substitution[lineupType]?.map(s => s.$ref)).flat();
    
    const substitutions = substitutionEvent.filter((substitution: Timeline) => !substitution[lineupType]);
    const teamSubstitution = substitutions.filter((substitution: Timeline) => substitution.Teams.map(t => t.$ref).includes(teamId) || 
                                                                              substitution?.Players?.map((player: Ref) => getProjectDataTeamPlayer(player?.$ref, stateContent.project.data as ProjectDto).$id).includes(teamId));
    const substitutionPlayerIds = teamSubstitution.map(subs => subs.In?.$ref);

    const playerRefs = [...startingLineup, ...substitutionPlayerIds];
    const template: "LocalTeamTemplate" | "VisitorTeamTemplate" = type === TeamType.LOCAL ? "LocalTeamTemplate": "VisitorTeamTemplate";
    // const players = statistic[template].List.filter((player: TeamPlayer) => playerRefs.includes(player.$id));
    const players = statistic[template].List
    setPlayerList(players);
  }

  return (
    <div className="grid grid-cols-5">
      <List component="nav" disablePadding className="lg:min-h-[calc(100vh-20.313rem)] lg:border-r lg:border-neutral-200">
        <MatchStatisticTeamList
          id={statistic.LocalTeamTemplate.$id}
          name={statistic.LocalTeamTemplate.Name}
          shield={statistic.LocalTeamTemplate.Shield}
          isOpen={localOpen}
          onTeamClick={onTeamClick}
          onTeamExpand={() => onTeamExpand(TeamType.LOCAL)}></MatchStatisticTeamList>

        <Collapse in={localOpen} timeout="auto" unmountOnExit>
          <MatchStatisticPlayerList playerList={playerList} playerActive={selectedPlayerId} onPlayerClicked={onPlayerClick}></MatchStatisticPlayerList>
        </Collapse>

        <div className="border-b border-neutral-200">
          <MatchStatisticTeamList
            id={statistic.VisitorTeamTemplate.$id}
            name={statistic.VisitorTeamTemplate.Name}
            shield={statistic.VisitorTeamTemplate.Shield}
            isOpen={visitorOpen}
            onTeamClick={onTeamClick}
            onTeamExpand={() => onTeamExpand(TeamType.VISITOR)}></MatchStatisticTeamList>
        </div>

        <Collapse in={visitorOpen} timeout="auto" unmountOnExit>
          <MatchStatisticPlayerList playerList={playerList} playerActive={selectedPlayerId} onPlayerClicked={onPlayerClick}></MatchStatisticPlayerList>
        </Collapse>
      </List>

      <div className="lg:min-h-[calc(100vh-20.313rem)] lg:border-r lg:border-neutral-200">
        {categories.map((category: ProjectCategoryDto) => (
          <MatchStatisticCategory key={category.event} category={category} active={category.event === selectedCategory?.event} onCategoryClicked={setSelectedCategory}></MatchStatisticCategory>
        ))}
      </div>
        <div className="col-span-3">
          { (selectedTeamId || selectedPlayerId) && 
            <MatchStatisticTeamSummary 
              elapsedTime={elapsedTime}
              points={points}
              admonition={admonition}
            ></MatchStatisticTeamSummary>
          }
          { selectedCategory && 
            <>
              <MatchStatisticCategoryViewer 
                category={selectedCategory} 
                dashboard={statistic.Dashboard} 
                teamId={selectedTeamId}
                playerId={selectedPlayerId}
                statistic={statistic}
              ></MatchStatisticCategoryViewer>
              <MatchStatisticGraph category={selectedCategory} categoryNavigation={categoryNavigation}></MatchStatisticGraph>
            </>
          }
        </div>
    </div>
  );
};

export default MatchStatisticTeam;
