import { t } from "i18next";
import { put, takeLatest, call, all, select } from "redux-saga/effects";
import {
  ActionAuthType,
  ActionChangePasswordLoading,
  ActionCheckInvitationsLoading,
  ActionGetLastUsersLoading,
  ActionInvitationsLoading,
  ActionLoginLoading,
  ActionPasswordResetLoading,
  ActionResendPendingInvitationLoading,
  ActionResetRequestPasswordLoading,
  ActionUserProfileImageLoading,
  ActionUserProfileLoading,
  TypeAuthActions,
} from "../../model/actions/typeAuthActions";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import CheckInvitationDto from "../../model/dto/CheckInvitationDto";
import LoginResponse from "../../model/dto/LoginResponseDto";
import ResponseApiError from "../../model/dto/ResponseApiError";
import UserDto from "../../model/dto/UserDto";
import UserInfoDto from "../../model/dto/UserInfoDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getFetchParams } from "./utilsSaga";
import OrganizationUserDto from "../../model/dto/OrganizationUserDto";

const baseUrlApi = process.env.REACT_APP_API_URL;

export function* login(action: ActionLoginLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(fetch, baseUrlApi + "Auth/login?locale=" + state.appLanguage, getFetchParams("POST", action.value, null));
    if (response.ok) {
      const data: LoginResponse = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_LOGIN_SUCCESS, value: data });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_INFO_LOADING });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_LOGIN_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_LOGIN_ERROR, value: t("errors.server") });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* resetRequestPassword(action: ActionResetRequestPasswordLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(fetch, baseUrlApi + "Users/password/reset/request?email=" + action.value.email + "&locale=" + state.appLanguage);

    if (response.ok) {
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: action.value.message, severity: "success" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_SUCCESS, value: true });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_ERROR });
    }
  } catch (error) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* resetPassword(action: ActionPasswordResetLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json-patch+json",
      },
      body: JSON.stringify(action.value.data),
    };
    const response: Response = yield call(fetch, baseUrlApi + "Users/password/reset?locale=" + state.appLanguage, params);

    if (response.ok) {
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: action.value.message, severity: "success" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_PASSWORD_RESET_SUCCESS, value: true });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (error) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* invitations(action: ActionInvitationsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json-patch+json",
      },
      body: JSON.stringify(action.value.data),
    };
    const response: Response = yield call(fetch, baseUrlApi + "Invitations?locale=" + state.appLanguage, params);

    if (response.ok) {
      const data: LoginResponse = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_LOGIN_SUCCESS, value: data });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: action.value.message, severity: "success" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_INFO_LOADING });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_LOGIN_ERROR, value: data.Message });
    }
  } catch (error) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_LOGIN_ERROR, value: t("errors.server") });
  }
}

export function* checkInvitation(action: ActionCheckInvitationsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "Invitations/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, null)
    );
    if (response.ok) {
      const data: CheckInvitationDto = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_CHECK_INVITATIONS_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_CHECK_INVITATIONS_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* changePassword(action: ActionChangePasswordLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + state.login.loginResponse?.token,
      },
      body: JSON.stringify(action.value.data),
    };
    const response: Response = yield call(fetch, baseUrlApi + "Users/password/change?locale=" + state.appLanguage, params);

    if (response.ok) {
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: action.value.message, severity: "success" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_PASSWORD_CHANGE_SUCCESS, value: true });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_PASSWORD_CHANGE_ERROR });
    }
  } catch (error) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* userInfo() {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "Users/info?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );

    if (response.ok) {
      const data: UserInfoDto = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_INFO_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_INFO_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (error) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* editProfile(action: ActionUserProfileLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + state.login.loginResponse?.token,
      },
      body: JSON.stringify(action.value),
    };
    const response: Response = yield call(fetch, baseUrlApi + "Users/profile?locale=" + state.appLanguage, params);

    if (response.ok) {
      const data: UserDto = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_PROFILE_SUCCESS, value: { data: data, ok: true } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_PROFILE_DATA, value: data });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("editUser.messageEditProfile"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_PROFILE_ERROR, value: data.Message });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (error) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_PROFILE_ERROR, value: t("errors.server") });
  }
}

export function* editImageProfile(action: ActionUserProfileImageLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + state.login.loginResponse?.token,
      },
      body: JSON.stringify({ avatarImage: action.value.image, defaultLanguage: action.value.defaultLanguage }),
    };
    const response: Response = yield call(
      fetch,
      baseUrlApi + "Users/profile/organization/" + action.value.id + "?locale=" + action.value.defaultLanguage,
      params
    );

    if (response.ok) {
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_PROFILE_IMAGE_SUCCESS, value: { image: action.value.image, ok: true } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_STATE_UPDATE_PROFILE_IMAGE, value: action.value.image });
      // yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_INFO_LOADING });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_PROFILE_IMAGE_ERROR });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (error) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_PROFILE_IMAGE_ERROR });
  }
}

export function* userDesactivates() {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "Users?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );

    if (response.ok) {
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_DESACTIVATE_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("editUser.messageDesactivateUser"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_DESACTIVATE_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (error) {
    yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_USER_DESACTIVATE_SUCCESS });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getLastUsers(action: ActionGetLastUsersLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "Users/byOrganization/" + action.value.organizationId + "/last/" + action.value.top + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: OrganizationUserDto[] = yield call([response, "json"]);
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_GET_LAST_USERS_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_GET_LAST_USERS_ERROR });
    }
  } catch (error) {
    yield put<TypeAuthActions>({ type: ActionAuthType.ACTION_GET_LAST_USERS_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* resendPendingInvitation(action: ActionResendPendingInvitationLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "Invitations/invitations/" + action.value + "/send/?locale=" + state.appLanguage,
      getFetchParams("POST", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      // const data: OrganizationUserDto[] = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("users.resendPendingInvitationSuccess"), severity: "success" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.RESEND_PENDING_INVITATION_SUCCESS });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeAuthActions>({ type: ActionAuthType.RESEND_PENDING_INVITATION_ERROR });
    }
  } catch (error) {
    yield put<TypeAuthActions>({ type: ActionAuthType.RESEND_PENDING_INVITATION_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* loginWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_LOGIN_LOADING, login);
}

export function* resetRequestPasswordWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_RESET_REQUEST_PASSWORD_LOADING, resetRequestPassword);
}

export function* resetPasswordWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_PASSWORD_RESET_LOADING, resetPassword);
}

export function* invitationsWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_INVITATIONS_LOADING, invitations);
}

export function* checkInvitationWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_CHECK_INVITATIONS_LOADING, checkInvitation);
}

export function* changePasswordWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_PASSWORD_CHANGE_LOADING, changePassword);
}

export function* userInfoWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_USER_INFO_LOADING, userInfo);
}

export function* editProfileWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_USER_PROFILE_LOADING, editProfile);
}

export function* editImageProfileWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_USER_PROFILE_IMAGE_LOADING, editImageProfile);
}

export function* userDesactivatesWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_USER_DESACTIVATE_LOADING, userDesactivates);
}

export function* getLastUsersWhatcher() {
  yield takeLatest(ActionAuthType.ACTION_GET_LAST_USERS_LOADING, getLastUsers);
}

export function* resendPendingInvitationWhatcher() {
  yield takeLatest(ActionAuthType.RESEND_PENDING_INVITATION_LOADING, resendPendingInvitation);
}

export default function* rootAuthSaga() {
  yield all([
    loginWhatcher(),
    changePasswordWhatcher(),
    resetRequestPasswordWhatcher(),
    resetPasswordWhatcher(),
    invitationsWhatcher(),
    userInfoWhatcher(),
    editProfileWhatcher(),
    editImageProfileWhatcher(),
    userDesactivatesWhatcher(),
    checkInvitationWhatcher(),
    getLastUsersWhatcher(),
    resendPendingInvitationWhatcher(),
  ]);
}
