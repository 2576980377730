import { AuthState } from "../../model/states/AuthState";

export const authState: AuthState = {
  login: {
    loginResponse: null,
    loading: false,
    error: false,
    messageError: "",
  },
  resetRequestPassword: {
    loading: false,
    ok: false,
  },
  resetPassword: {
    loading: false,
    ok: false,
  },
  changePassword: {
    loading: false,
    error: false,
    ok: false,
  },
  checkInvitation: {
    loading: false,
    ok: false,
    error: false,
    data: null,
  },
  resendPendingInvitation: {
    loading: false,
    error: false,
  },
  userInfo: {
    loading: false,
    error: false,
    selectedOrganization: "",
    selectedLicense: "",
    selectedTeam: "",
    messageError: "",
    data: {
      freeLicenses: [],
      organizations: [],
      user: {
        email: "",
        emailVerified: false,
        id: "",
        isActive: false,
        lastName: "",
        name: "",
        notifyCommentsOnMyContent: false,
        notifyWhenContentSharedWithMe: false,
        notifyWhenSomeoneTaggedMe: false,
        systemRole: "",
      },
    },
  },
  editProfile: {
    loading: false,
    error: false,
    ok: false,
    messageError: "",
  },
  editImageProfile: {
    loading: false,
    error: false,
    ok: false,
  },
  desactivateUser: {
    loading: false,
    error: false,
    ok: false,
  },
  auth: false,
  isStayLogged: false,
  appLanguage: "",
  avatarUserOrganization: null,
  actionMenuSelected: "",
  getLastUsers: {
    data: [],
    loading: false,
    error: false,
    ok: false,
  },
};
