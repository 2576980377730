import { t } from "i18next";
import * as Yup from "yup";
import { regexEmailFormat, regexPassword } from "../../constants/globalConstanst";

export function loginInitialValues() {
  return {
    email: "",
    password: "",
  };
}

export function restorePasswordInitialValues() {
  return {
    email: "",
  };
}

export function registerInitialValues() {
  return {
    password: "",
    repeatPassword: "",
  };
}

export function loginValidationSchema() {
  return Yup.object({
    email: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexEmailFormat, t("errors.form.passwordFormat") as string),
    password: Yup.string().required(t("errors.form.required") as string),
  });
}

export function restorePasswordValidationSchema() {
  return Yup.object({
    email: Yup.string()
      .matches(regexEmailFormat, t("errors.form.passwordFormat") as string)
      .required(t("errors.form.required") as string),
  });
}

export function registerValidationSchema() {
  return Yup.object({
    password: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexPassword, t("errors.form.passwordFormat") as string),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password")], t("errors.form.passwordRepeat") as string)
      .required(t("errors.form.passwordFormat") as string),
  });
}
