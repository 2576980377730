import { Dialog } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Asterisk } from "../../assets/icons/asterisk.svg";
import { useTranslation } from "react-i18next";
import LmInput from "../input/LmInput";
import TeamsDto from "../../model/dto/TeamDto";
import LmAvatarLabelInfo from "../avatar-label-info/LmAvatarLabelInfo";
import LmSelect from "../select/LmSelect";
import { useState } from "react";
import { IdValue } from "../../model/forms/IdValue";
import { getRole, getRoleOptions } from "../../utils/utils";
import LmButton from "../button/LmButton";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import LmIcon from "../icon/LmIcon";
import { UserForm } from "../../model/forms/UserForm";
import LoadImage from "../load-image/LoadImage";
import { UserRolesByTeam } from "../../model/UserRolesByTeam";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { EditOrganizationUserDto } from "../../model/dto/EditOrganizationUserDto";
import { MainState } from "../../model/states/MainState";
import { DEFAULT_IMAGE } from "../../constants/globalConstanst";
import { InitialState } from "../../model/states/InitialState";

interface Props {
  user: UserForm;
  open: boolean;
  teams: TeamsDto[];
  organizationId: string;
  handleClose(): void;
}

const LmModalEditMember = ({ open, handleClose, teams, organizationId, user }: Props) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const { t } = useTranslation();
  const editLoading: boolean = useSelector((state: MainState) => state.organization.organizationUsers.editUser.loading);
  const userLocale: string = useSelector((state: MainState) => state.auth.appLanguage);
  const dispatch = useDispatch();
  const roleOptions = getRoleOptions();
  const [image, setImage] = useState(user?.avatarImage || DEFAULT_IMAGE);
  const [userRolesByTeam, setUserRolesByTeam] = useState<UserRolesByTeam[]>(user.userRolesByTeam);

  const onSubmit = () => {
    const auxTeams = userRolesByTeam
      .map((item) => {
        return { teamId: item.teamId, roles: item.roles };
      })
      .filter((item) => item?.roles?.length);

    const data: EditOrganizationUserDto = {
      avatarImage: image,
      teams: auxTeams,
      defaultLanguage: userLocale,
    };

    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_USER_LOADING,
      value: {
        organizationId: organizationId,
        userId: user.id,
        dataCallback: {
          data: data,
          callBack: onClose,
        },
      },
    });
  };

  const onClose = () => {
    handleClose();
  };

  const onChangeRole = (options: IdValue[], team: TeamsDto) => {
    const updateList = structuredClone(userRolesByTeam);
    const index: number = updateList ? updateList?.findIndex((obj: UserRolesByTeam) => obj.teamId === team.id) : -1;
    if (updateList && index !== -1) {
      updateList[index] = { ...updateList[index], roles: options.map((option) => option.id) };
    } else if (updateList) {
      updateList.push({ teamId: team.id || "", teamName: team.name, teamAvatar: team.image, roles: options.map((option) => option.id) });
    }

    setUserRolesByTeam(updateList);
  };

  const isFormValid = () => {
    const hasOneRole = userRolesByTeam?.some((team: UserRolesByTeam) => team.roles.length > 0 && team.roles[0] !== "");
    return !editLoading && hasOneRole;
  };

  const changeImage = (image: string) => {
    setImage(image);
  };

  const getMultiRoles = (teamId: string) => {
    const index: number = userRolesByTeam ? userRolesByTeam.findIndex((item) => item.teamId === teamId) : -1;
    let result: IdValue[] = [];
    if (userRolesByTeam && index !== -1) {
      result = getRole(userRolesByTeam[index].roles);
    }
    return result;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8">
        <div className="flex justify-between items-center ">
          <p className="font-decorative text-decorative text-base md:text-2xl">{t("users.editMemberTitle")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-new-player" />
        </div>
        <div className="mb-8 mt-8">
          <div className="flex justify-center mb-5 md:mb-6">
            <LoadImage size="lg" img={image} id="image" onChangeImage={changeImage} />
          </div>
        </div>
        <div className="flex flex-col gap-3 w-full" data-testid="test-lmInviteMemberForm">
          <div className="grid grid-cols-1 gap-4 w-full">
            <div>
              <LmInput disabled required={true} id="userName" label={t("formLabel.name")} value={user?.name}></LmInput>
            </div>
            <div>
              <LmInput disabled required={true} id="userLastName" label={t("formLabel.lastName")} value={user?.lastName}></LmInput>
            </div>
            <div>
              <LmInput disabled required={true} id="userEmail" label={t("formLabel.email")} value={user?.email}></LmInput>
            </div>
            {teams?.length > 0 && (
              <div>
                <label className="lm-label flex items-start">
                  {`${t("users.inviteMemberTeams")} (${teams?.length || 0})`}
                  <LmIcon icon={<Asterisk />} size="xs" />
                </label>
                {teams?.map((team) => (
                  <div key={team.id} className="my-4 grid md:grid-cols-2 gap-2 items-center">
                    <LmAvatarLabelInfo src={team.image} label={team.name}></LmAvatarLabelInfo>
                    <div>
                      <LmSelect
                        id="roles"
                        placeholder={t("formLabel.role") as string}
                        required={true}
                        multipleCheck={true}
                        valueMultiple={getMultiRoles(team.id || "")}
                        options={roleOptions}
                        readonly={true}
                        selectedValue={(options) => onChangeRole(options as IdValue[], team)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-end mt-8 space-x-2">
              <div className="add-button-container">
                <LmButton styleButton="secondary" type="solid" size="small" text={t("buttonLabel.cancel")} clickAction={onClose} />
              </div>
              <div className="add-button-container">
                <LmButton
                  isDisabled={!isFormValid()}
                  styleButton="organization"
                  type="solid"
                  size="small"
                  text={t("buttonLabel.save")}
                  buttonType="submit"
                  clickAction={() => onSubmit()}></LmButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LmModalEditMember;
