import React, { useState } from "react";
import { IdValue } from "../../model/forms/IdValue";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down-black.svg";
import LmIcon from "../icon/LmIcon";
import LmDropDown from "../dropdown/LmDropDown";
import "./OrganizationSelector.scss";
import { MainState } from "../../model/states/MainState";
import { useSelector } from "react-redux";
import { InitialState } from "../../model/states/InitialState";
import { SizeScreen } from "../../model/SizeSreen";

interface IOrganizationSelector {
  selected: IdValue;
  options: IdValue[];
  onSelected(option: IdValue): void;
}

const OrganizationSelector: React.FC<IOrganizationSelector> = ({ selected, options = [], onSelected }) => {
  const [openDrowDown, setOpenDrowDown] = useState(false);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const onChangeOrganization = (option: IdValue) => {
    setOpenDrowDown(false);
    onSelected(option);
  };

  return (
    <>
      <div className="relative">
        <div className="organization-selector" data-testid="organization-selector" onClick={() => setOpenDrowDown(true)}>
          <div className="h-9 lg:h-5 w-9 lg:w-5 overflow-hidden rounded-full cursor-pointer">
            <img className="object-cover min-h-full min-w-full" src={selected.image}></img>
          </div>
          {stateMain.sizeScreen === SizeScreen.DESKTOP && (
            <>
              <span className="text-sm font-semibold">{selected.value}</span>
              <LmIcon icon={<ChevronDown />} size="small"></LmIcon>
            </>
          )}
        </div>

        <LmDropDown open={openDrowDown} close={() => setOpenDrowDown(!openDrowDown)}>
          {options.map((option) => (
            <li key={option.id} onClick={() => onChangeOrganization(option)} className="flex items-center gap-2 h-11 cursor-pointer">
              <div className="h-6 w-6 overflow-hidden rounded-full">
                <img className="object-cover min-h-full min-w-full" src={option.image}></img>
              </div>
              <span>{option.value}</span>
            </li>
          ))}
        </LmDropDown>
      </div>
    </>
  );
};

export default OrganizationSelector;
