import { Dialog } from "@mui/material";
import LmMainDataForm from "../new-organization-forms/LmMainDataForm";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";

interface Props {
  open: boolean;
  handleClose(): void;
}
const ModalEditOrganization = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const onClose = () => {
    handleClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className={`p-8 theme-${stateMain.colorAplication}`}>
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative">{t("teams.editOrganization")}</p>
          <Close className="cursor-pointer" data-testid="button-close-edit-organization" onClick={onClose} />
        </div>
        <LmMainDataForm onClose={onClose} modal={true} />
      </div>
    </Dialog>
  );
};

export default ModalEditOrganization;
