import { useEffect } from "react";
import HomeDocument from "../home-document/HomeDocument";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ContentManagementState, ContentManagementType } from "../../model/states/ContentManagementState";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { useTranslation } from "react-i18next";
import Loading from "../loading/Loading";
import EmptyProyect from "../../assets/img/empty-proyect.png";
import EmptyDashboard from "../empty/emptyDashboard";

const LmLastProjects = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  useEffect(() => {
    dispatch<TypeActions>({
      type: ActionContentManagementType.ACTION_GET_LAST_PROJECTS_LOADING,
      value: {
        organizationId: stateAuth.userInfo.selectedOrganization as string,
        types: [
          ContentManagementType[ContentManagementType.Dashboard],
          ContentManagementType[ContentManagementType.Template],
          ContentManagementType[ContentManagementType.Project],
        ],
        top: 2,
      },
    });
  }, [stateAuth.userInfo.selectedOrganization, stateAuth.appLanguage]);

  return stateContent.lastProjects.loading ? (
    <Loading />
  ) : (
    <div className="space-y-4">
      {stateContent.lastProjects.data?.length ? (
        stateContent.lastProjects.data.map((doc) => <HomeDocument key={doc.id} document={doc} />)
      ) : (
        <EmptyDashboard text={t("content.emptyCategory")} img={EmptyProyect} />
      )}
    </div>
  );
};

export default LmLastProjects;
