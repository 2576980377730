import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import LmButton from "../button/LmButton";
import { useTranslation } from "react-i18next";
import LmInput from "../input/LmInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import LmIcon from "../icon/LmIcon";
import LmMessageError from "../message-error/LmMessageError";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { LmCharacteristicsPassword } from "../login-forms/LmCharacteristicsPassword";
import { regexPassword } from "../../constants/globalConstanst";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { InitialState } from "../../model/states/InitialState";
interface IModalEditPassword {
  open: boolean;
  handleClose(): void;
}

interface IPassword {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export function userInitialValues() {
  return {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };
}

export function userValidationSchema() {
  return Yup.object({
    password: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexPassword, t("errors.form.passwordFormat") as string),
    newPassword: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexPassword, t("errors.form.passwordFormat") as string),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], t("errors.form.passwordRepeat") as string)
      .required(t("errors.form.required") as string),
  });
}

const ModalEditPassword: React.FC<IModalEditPassword> = ({ open, handleClose }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [typePassword, setTypePassword] = useState<IPassword>({
    password: "password",
    newPassword: "password",
    confirmPassword: "password",
  });

  const formik = useFormik({
    initialValues: userInitialValues(),
    validationSchema: userValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    if (stateAuth.changePassword?.ok) {
      formik.resetForm();
      handleClose();
      dispatch({ type: ActionAuthType.ACTION_PASSWORD_CHANGE_SUCCESS, value: false });
    }
  }, [stateAuth.changePassword]);

  const onSubmit = async (formValue: any) => {
    dispatch({
      type: ActionAuthType.ACTION_PASSWORD_CHANGE_LOADING,
      value: { data: { oldPassword: formValue.password, newPassword: formValue.newPassword }, message: t("editUser.messagePasswordChanged") },
    });
  };

  const changeTypePassword = (field: string) => {
    const type: IPassword = { ...typePassword };
    type[field as keyof IPassword] = typePassword[field as keyof IPassword] === "password" ? "text" : "password";
    setTypePassword(type);
  };

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8" data-testid="modal-edit-user-password">
        <div className="flex justify-between">
          <p className="font-decorative text-decorative text-base md:text-2xl mb-5 md:mb-6">{t("editUser.editPasswordTitle")}</p>
          <div className="cursor-pointer">
            <LmIcon icon={<Close />} size="md" clickAction={onClose}></LmIcon>
          </div>
        </div>

        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-4 mb-8">
              <div>
                <LmInput
                  required={true}
                  type={typePassword.password}
                  id="password"
                  label={t("formLabel.currentPassword")}
                  changeValue={formik.handleChange}
                  blur={formik.handleBlur}
                  value={formik.values.password}
                  iconSufix={<Eye />}
                  iconSufixAction={() => changeTypePassword("password")}></LmInput>
                {formik.touched.password && formik.errors.password && <LmMessageError id="password" message={formik.errors.password}></LmMessageError>}
              </div>

              <div>
                <LmInput
                  required={true}
                  type={typePassword.newPassword}
                  id="newPassword"
                  label={t("formLabel.newPassword")}
                  changeValue={formik.handleChange}
                  blur={formik.handleBlur}
                  value={formik.values.newPassword}
                  iconSufix={<Eye />}
                  iconSufixAction={() => changeTypePassword("newPassword")}></LmInput>
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <LmMessageError id="newPassword" message={formik.errors.newPassword}></LmMessageError>
                )}
              </div>

              <div>
                <LmInput
                  required={true}
                  type={typePassword.confirmPassword}
                  id="confirmPassword"
                  label={t("formLabel.confirmPassword")}
                  changeValue={formik.handleChange}
                  blur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  iconSufix={<Eye />}
                  iconSufixAction={() => changeTypePassword("confirmPassword")}></LmInput>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <LmMessageError id="confirmPassword" message={formik.errors.confirmPassword}></LmMessageError>
                )}
              </div>

              <div className="mt-2 p-3 rounded bg-emerald-400/10">
                <LmCharacteristicsPassword />
              </div>
            </div>

            <div className="flex gap-1 justify-end flex-wrap">
              <LmButton text={t("editUser.buttonCancel")} buttonType="button" type="secondary" clickAction={onClose}></LmButton>
              <LmButton
                testid="test-button-edit-password"
                text={t("editUser.buttonSave")}
                type="organization"
                buttonType="submit"
                isDisabled={!formik.isValid}></LmButton>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalEditPassword;
