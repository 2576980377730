import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import LmButton from "../button/LmButton";
import LmInput from "../input/LmInput";
import LmMessageError from "../message-error/LmMessageError";
import LoadImage from "../load-image/LoadImage";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { playerInitialValues, playerValidationSchema } from "../modal-new-player/LmPlayerForm.data";
import LmSelect from "../select/LmSelect";
import { dateFormat, getSelectedDataById, optionsCountries, optionsPositionPlayer } from "../../utils/utils";
import { IdValue } from "../../model/forms/IdValue";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
import { TypeActions } from "../../model/actions/typeActions";
import { useDispatch, useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";
import PlayerResultDto from "../../model/dto/PlayerResultDto";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import { AuthState } from "../../model/states/AuthState";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { DEFAULT_IMAGE } from "../../constants/globalConstanst";
import { PlayerState } from "../../model/states/PlayerState";
import TeamsDto from "../../model/dto/TeamDto";
import TeamGroupResultDto from "../../model/dto/TeamGroupResultDto";
import LmCheckBox from "../check-box/LmCheckBox";

interface Props {
  onClose(): void;
  player: PlayerResultDto | null;
  team: TeamsDto;
}

const getGroups = (player: PlayerResultDto | null, team: TeamsDto): IdValue[] => {
  let groupsResult: IdValue[] = [];
  if (player) {
    const groups: TeamGroupResultDto[] = [];
    team.groups?.forEach((group) =>
      group.players.forEach((pl) => {
        if (pl.id === player.id) {
          groups.push(group);
        }
      })
    );
    groupsResult = groups.map((group) => {
      return { id: group.id, value: group.name };
    });
  }
  return groupsResult;
};

export function LmPlayerForm({ onClose, player, team }: Props) {
  const statePlayer: PlayerState = useSelector((state: MainState) => state.player);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  const optionsGroups = useMemo((): IdValue[] => {
    const options = team?.groups?.map((group) => {
      return { id: group.id, value: group.name };
    });
    return options as IdValue[];
  }, []);

  const optionsPositions = useMemo(() => optionsPositionPlayer(), []);
  const optionsSelectCountries = useMemo(() => optionsCountries(statePlayer.countries.data), [statePlayer.countries.data]);

  const { t } = useTranslation();
  const [image, setImage] = useState(player?.avatarImage || DEFAULT_IMAGE);

  const [position, setPosition] = useState<IdValue | undefined | null>(
    player ? optionsPositions.find((option) => option.id === player?.position) : undefined
  );
  const [country, setCountry] = useState<IdValue | undefined | null>(undefined);
  const [name, setName] = useState<IdValue[]>([]);
  const [morePlayers, setMorePlayers] = useState(false);
  const [nameValue, setNameValue] = useState(player ? player.name : "");
  const [groups, setGroups] = useState<any>(getGroups(player, team));

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: playerInitialValues(player),
    validationSchema: playerValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    const format = team?.players?.map((player) => {
      return { id: player.id, value: player.name + "-" + player.lastName + " - " + player.nickName };
    });
    if (format) {
      setName(format);
    }
  }, [team]);

  useEffect(() => {
    if (statePlayer.countries.data.length === 0) {
      dispatch<TypeActions>({
        type: ActionPlayerType.ACTION_COUNTRIES_LOADING,
      });
    }
  }, []);

  useEffect(() => {
    if (statePlayer.countries.data.length > 0) {
      setCountry(optionsSelectCountries.find((option) => option.id === player?.countryId));
    }
  }, [player, statePlayer.countries.data]);

  useEffect(() => {         
      setPosition(optionsPositions.find(pos => pos.id === player?.position))
  }, [player]);

  useEffect(() => {
    if (statePlayer.dataPlayer.ok && !morePlayers) {
      dispatch<TypeActions>({ type: ActionPlayerType.ACTION_CREATE_PLAYER_SUCCESS, value: false });
      onClose();
    } else if (statePlayer.dataPlayer.ok && morePlayers) {
      dispatch<TypeActions>({ type: ActionPlayerType.ACTION_CREATE_PLAYER_SUCCESS, value: false });
      formik.resetForm();
      setNameValue("");
      setImage(DEFAULT_IMAGE);
      setCountry(null);
      setPosition(null);
      setGroups([]);
    }
  }, [statePlayer.dataPlayer]);

  const optionsPlayers = useMemo(
    () =>
      selectedOrganizationData?.players?.map((player) => {
        return { id: player.id, value: player.name + ", " + player.lastName + "-" + player.nickName };
      }),
    []
  );

  const onSubmit = (formValue: any) => {
    const birthDate = formValue.birthDate ? new Date(formValue.birthDate).toISOString() : null;
    let group = [];
    if (groups) {
      group = groups.map((group: IdValue) => group.id);
    }

    const data = {
      ...formValue,
      id: player ? player.id : formValue.id ? formValue.id : null,
      name: player ? formik.values.name : nameValue,      
      teamId: team?.id,
      height: formValue.height.replace(',', '.'),
      weight: formValue.weight.replace(',', '.'),
      birthDate,
      avatarImage: image,
      groups: group,
    };

    if (!player) {
      dispatch<TypeActions>({
        type: ActionPlayerType.ACTION_CREATE_PLAYER_LOADING,
        value: data,
      });
    } else {
      dispatch<TypeActions>({
        type: ActionPlayerType.ACTION_EDIT_PLAYER_LOADING,
        value: data,
      });
    }
  };

  const changeImage = (image: string) => {
    setImage(image);
  };

  const selectedPlayer = (options: IdValue[]) => {
    if (options.length > 0) {
      setName(options as IdValue[]);
      setNameValue(options[0].value.split(",")[0]);
      const player = selectedOrganizationData?.players?.find((pl) => pl.id === options[0].id);
      if (player) {
        formik.setValues({
          ...player,
          weight: player.weight || "",
          height: player.height || "",
          birthDate: player.birthDate ? dateFormat(player.birthDate) : "",
          email: player.email || "",
        });
        setImage(player.avatarImage || DEFAULT_IMAGE);
        setPosition(optionsGroups.find((option) => option.id === player.position));
        setCountry(optionsSelectCountries.find((option) => option.id === player.countryId));
      }
    }    
  };

  const selectedValueNameDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value.split("-")[0]);
    formik.handleChange(e);
    formik.handleBlur(e);
  };

  const onChangePosition = (option: IdValue) => {
    formik.setFieldValue("position", option.id);
    setPosition(option);
  };

  const onChangeCountry = (option: IdValue) => {
    formik.setFieldValue("countryId", option.id);
    setCountry(option);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-lmTeamForm">
        <div className="flex justify-center mb-8">
          <LoadImage size="lg" img={image} id="image" onChangeImage={changeImage} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full mb-8">
          {player ? (
            <div>
              <LmInput
                required={true}
                id="name"
                label={t("formLabel.name")}
                blur={formik.handleBlur}
                changeValue={formik.handleChange}
                value={formik.values.name}></LmInput>
              {formik.touched.name && formik.errors.name && <LmMessageError id="name" message={formik.errors.name}></LmMessageError>}
            </div>
          ) : (
            <div>
              <LmAutocomplete
                label={t("formLabel.name")}
                required={true}
                id="name"
                value={name}
                valueDisplay={nameValue}
                multiple={false}
                options={optionsPlayers as IdValue[]}
                setValueDisplay={selectedValueNameDisplay}
                selectedValue={(options) => selectedPlayer(options as IdValue[])}
              />
              {formik.touched.name && formik.errors.name && <LmMessageError id="name" message={formik.errors.name}></LmMessageError>}
            </div>
          )}

          <div>
            <LmInput
              required={true}
              id="lastName"
              label={t("formLabel.lastName")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.lastName}></LmInput>
            {formik.touched.lastName && formik.errors.lastName && <LmMessageError id="lastName" message={formik.errors.lastName}></LmMessageError>}
          </div>
          <div>
            <LmInput
              required={true}
              id="nickName"
              label={t("formLabel.nickName")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.nickName}></LmInput>
            {formik.touched.nickName && formik.errors.nickName && <LmMessageError id="nickName" message={formik.errors.nickName}></LmMessageError>}
          </div>

          <div>
            <LmSelect
              label={t("formLabel.nationality")}
              required={true}
              id="countryId"
              value={country}
              options={optionsSelectCountries}
              selectedValue={(option: IdValue) => onChangeCountry(option)}></LmSelect>
            {formik.touched.countryId && formik.errors.countryId && <LmMessageError id="countryId" message={t("errors.form.required")}></LmMessageError>}
          </div>

          <div>
            <LmSelect
              label={t("formLabel.position")}
              required={true}
              id="position"
              value={position}
              options={optionsPositions}
              selectedValue={(option: IdValue) => onChangePosition(option)}></LmSelect>
            {formik.touched.position && formik.errors.position && <LmMessageError id="position" message={t("errors.form.required")}></LmMessageError>}
          </div>
          <div>
            <LmInput
              required={true}
              id="number"
              type="number"
              min={1}
              label={t("formLabel.number")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.number}></LmInput>
            {formik.touched.number && formik.errors.number && <LmMessageError id="number" message={formik.errors.number}></LmMessageError>}
          </div>
          <div>
            <LmInput
              required={false}
              id="height"
              label={t("formLabel.height")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.height}></LmInput>
            {formik.touched.height && formik.errors.height && <LmMessageError id="height" message={formik.errors.height}></LmMessageError>}
          </div>
          <div>
            <LmInput
              required={false}
              id="weight"
              label={t("formLabel.weight")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.weight}></LmInput>
            {formik.touched.weight && formik.errors.weight && <LmMessageError id="weight" message={formik.errors.weight}></LmMessageError>}
          </div>
          <div>
            <LmInput
              required={false}
              id="birthDate"
              label={t("formLabel.birthDate")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              type="date"
              value={formik.values.birthDate}></LmInput>
            {formik.touched.birthDate && formik.errors.birthDate && <LmMessageError id="birthDate" message={formik.errors.birthDate}></LmMessageError>}
          </div>
          <div>
            <div>
              <LmSelect
                label={t("formLabel.group")}
                id="group"
                multipleCheck={true}
                valueMultiple={groups}
                options={optionsGroups}
                selectedValue={(options) => setGroups(options)}
              />
            </div>
          </div>
          <div>
            <LmInput
              required={true}
              id="email"
              label={t("formLabel.email")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.email}></LmInput>
            {formik.touched.email && formik.errors.email && <LmMessageError id="email" message={formik.errors.email}></LmMessageError>}
          </div>
        </div>
        {!player && (
          <div>
            <LmCheckBox text={t("formLabel.morePlayers")} id="morePlayers" value={morePlayers} changeValue={() => setMorePlayers(!morePlayers)}></LmCheckBox>
          </div>
        )}

        <div className="flex flex-wrap justify-end gap-4">
          <LmButton styleButton="secondary" type="solid" clickAction={onClose} size="small" text={`${t("buttonLabel.cancel")}`} iconPosition="right"></LmButton>
          <LmButton
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            isDisabled={statePlayer.dataPlayer.loading}
            text={!player ? t("buttonLabel.create") : t("buttonLabel.save")}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
}
