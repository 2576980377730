import React from "react";
import { TabContentManagement } from "../../model/TabContentManagement";
import LmIcon from "../icon/LmIcon";

interface TabElement {
  active: boolean;
  element: TabContentManagement;
  onChangeTab(): void;
}

const TabElement: React.FC<TabElement> = ({ active = false, element, onChangeTab = () => {} }) => {
  return (
    <div className={`${active ? "border-b-2 border-orgColor" : "border-b-2 border-transparent"} pb-2 transition`}>
      <div
        className={`flex items-center gap-2 ${active ? "opacity-100" : "opacity-40"} ${element.color} rounded-2xl cursor-pointer px-5 py-2 transition`}
        onClick={onChangeTab}>
        <LmIcon icon={<element.icon />} size="base" />
        <span>{element.text}</span>
      </div>
    </div>
  );
};

export default TabElement;
