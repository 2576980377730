import React, { ChangeEvent, useEffect, useState } from "react";
import LmIcon from "../icon/LmIcon";
import "./LoadImage.scss";
import { ReactComponent as ImageEdit } from "../../assets/icons/image-edit.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as PasswordInfo } from "../../assets/icons/password-info.svg";
import { DEFAULT_IMAGE, MAX_SIZE_IMAGE_USER_PROFILE } from "../../constants/globalConstanst";
import defaultImage from "../../assets/img/default-image.svg";
import ModalError from "../modal-error/ModalError";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import LmButton from "../button/LmButton";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import { useSelector } from "react-redux";

interface ILoadImage {
  img: string | undefined;
  id?: string;
  size?: string;
  onChangeImage(image: string): void;
}

const LoadImage: React.FC<ILoadImage> = ({ img, id, onChangeImage, size = "md" }) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const { t } = useTranslation();
  const [errorImage, setErrorImage] = useState(false);
  const [errorImageMessage, setErrorImageMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [srcImage, setSrcImage] = useState(img);

  useEffect(() => {
    setSrcImage(img || defaultImage);
  }, [img]);

  const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorImage(false);
    const image = event.currentTarget.files && event.currentTarget.files.length > 0 && event.currentTarget.files[0];
 
    const img = new Image();
    img.onload = () => {
      const elem = document.createElement('canvas');
      const newX = 100;
      const newY = 100;
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      ctx!.drawImage(img, 0, 0, newX, newY);
      const data = ctx!.canvas.toDataURL();
      onChangeImage(data);
      elem.remove();
    }

    const reader = new FileReader();
    reader.onloadend = function () {
      if (image && reader.result) {
        const file = reader.result as string;
        img.src = file;
      }
    };

    if (image && !image.type.match(/image.*/)) {
      setErrorImage(true);
      setErrorImageMessage(t("errors.errorImageFormat") || "");
    } else if (image && image.size >= MAX_SIZE_IMAGE_USER_PROFILE) {
      setErrorImage(true);
      setErrorImageMessage(t("errors.errorImageSize") || "");
    } else if (!image) {
      setErrorImage(true);
    } else {
      reader.readAsDataURL(image as File);
    }
  };

  const closeError = () => {
    setSrcImage(img ? img : DEFAULT_IMAGE);
    setErrorImage(false);
  };

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <div className={`theme-${stateMain.colorAplication}`}>
      <div className={`load-image load-image--${size}`}>
        <div className="load-image__container bg-org" data-testid="image-loaded" onClick={() => setShowDialog(true)}>
          <img className="load-image__file" src={srcImage}></img>
          <LmIcon className="load-image__overlay" size="sm" icon={<ImageEdit />} />
        </div>
      </div>

      <ModalError title={t("errors.errorImageTitle")} message={errorImageMessage} open={errorImage} handleClose={closeError} />

      <Dialog open={showDialog} onClose={onClose} maxWidth="sm" fullWidth className={`theme-${stateMain.colorAplication}`}>
        <div className="p-5 md:p-8">
          <div className="flex justify-between items-center mb-8">
            <p className="font-decorative text-decorative text-base md:text-2xl"> {t("uploadImage.title")}</p>
            <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-change-category" />
          </div>
          <div className="flex items-center">
            <label htmlFor={id}>
              <div className={`load-image load-image--${size}`}>
                <div className="load-image__container" data-testid="image-loaded" onClick={() => setShowDialog(true)}>
                  <img className="load-image__file" src={srcImage}></img>
                  <LmIcon className="load-image__overlay" size="sm" icon={<ImageEdit />} />
                </div>
              </div>
            </label>
            <div className="bg-white mt-1 p-4 rounded">
              <>
                <p className="text-sm font-semibold mb-2.5">{t("uploadImage.subtitle")}</p>
                <div className="flex items-center">
                  <LmIcon size="2xs" icon={<PasswordInfo />} />
                  <p className="text-sm ml-1">{t("uploadImage.recommendation1")}</p>
                </div>
                <div className="flex items-center">
                  <LmIcon size="2xs" icon={<PasswordInfo />} />
                  <p className="text-sm ml-1">{t("uploadImage.recommendation2")}</p>
                </div>
              </>
            </div>
          </div>
          <input type="file" accept="image/*" id={id} data-testid={id} className="hidden" onChange={uploadImage}></input>
          <div className="flex flex-wrap justify-end gap-4 mt-8">
            <LmButton styleButton="organization" type="solid" clickAction={onClose} size="small" text={`${t("buttonLabel.accept")}`} iconPosition="right"></LmButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LoadImage;
