import * as Yup from "yup";
import { t } from "i18next";
import { regexEmailFormat } from "../../constants/globalConstanst";

export function inviteMemberInitialValues() {
  return {
    userName: "",
    userLastName: "",
    userEmail: "",
  };
}

export function inviteMemberValidationSchema() {
  return Yup.object({
    userName: Yup.string().required(t("errors.form.required") as string),
    userLastName: Yup.string().required(t("errors.form.required") as string),
    userEmail: Yup.string()
      .required(t("errors.form.required") as string)
      .matches(regexEmailFormat, t("errors.form.passwordFormat") as string),
  });
}
