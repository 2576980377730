import { useFormik } from "formik";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IdValue } from "../../model/forms/IdValue";
import { AuthState } from "../../model/states/AuthState";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { getColorByEvent, getProjectResultTag, getSelectedDataById } from "../../utils/utils";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import LmButton from "../button/LmButton";
import LmTextArea from "../textarea/LmTextArea";

import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { ProjectDto, Timeline } from "../../model/dto/ProjectDto";
import { OrganizationState } from "../../model/states/OrganizationState";
import { PlayerState } from "../../model/states/PlayerState";
import { TeamState } from "../../model/states/TeamState";
import LmInput from "../input/LmInput";
import { teamInitialValues, teamValidationSchema } from "./LmAddToPlaylistForm.data";

interface Props {
  timelines: Timeline[];
  onClose(): void;
}

export function LmAddToPlaylistForm({ timelines, onClose }: Props) {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateTeam: TeamState = useSelector((state: MainState) => state.team);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const statePlayer: PlayerState = useSelector((state: MainState) => state.player);

  const selectedOrganization: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [group, setGroup] = useState<IdValue[]>([]);
  const [groupValue, setGroupValue] = useState("");

  const [playlist, setPlaylist] = useState<IdValue[]>([]);
  const [playlistValue, setPlaylistValue] = useState("");

  const [user, setUser] = useState<IdValue[]>([]);
  const [userValue, setUserValue] = useState("");

  useEffect(() => {
    if (stateContent.addClipsToPlaylist.ok || stateContent.createPlaylist.ok) {
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_RESET_CREATE_PLAYLIST });
      onClose();
    }
  }, [stateContent.addClipsToPlaylist.ok, stateContent.createPlaylist.ok]);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionTeamType.ACTION_TEAM_SUMARY_LOADING, value: stateContent.document.data?.teamId as string });
    if (selectedOrganization.users && selectedOrganization.users?.filter((user) => !user.user).length > 0) {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }

    if (!selectedOrganization.groups || selectedOrganization.groups?.length === 0) {
      dispatch({ type: ActionTeamType.ACTION_GET_GROUPS_BY_ORGANIZATION_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }

    if (!selectedOrganization.playersUnregistered) {
      dispatch<TypeActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING, value: stateAuth.userInfo.selectedOrganization });
    }
  }, [stateAuth.userInfo.selectedOrganization]);

  const formik = useFormik({
    initialValues: teamInitialValues(),
    validationSchema: teamValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const playlistOptions: IdValue[] = useMemo(
    () =>
      stateContent.playlistByProjectSelected.data.map((playlist) => {
        return {
          id: playlist.id,
          value: playlist.fileName,
        };
      }),
    [stateContent.playlistByProjectSelected]
  );

  const groupsOptions = useMemo(() => {
    const groups = selectedOrganization?.groups?.map((group) => {
      return {
        id: group.id,
        value: group.name,
      };
    });

    return groups as IdValue[];
  }, [stateAuth.userInfo.selectedOrganization, selectedOrganization.groups]);

  const usersOptions = useMemo(() => {
    let users;

    users = selectedOrganization.users
      ?.map((user) => {
        return { id: user.user?.id, value: user.user?.name + ", " + user.user?.lastName, type: "staff" };
      })
      .filter((item) => item !== undefined);

    if (selectedOrganization?.playersUnregistered) {
      users = users?.concat(
        selectedOrganization?.playersUnregistered?.map((player) => {
          return { id: player.id, value: player.name + ", " + player.lastName, type: "player" };
        })
      );
    }

    return users;
  }, [
    stateOrganization.organizationUsers.loading,
    stateAuth.userInfo.selectedOrganization,
    statePlayer.dataPlayerUnregistered,
    stateContent.playlistByProjectSelected,
    stateTeam.teamSumary,
  ]);

  useEffect(() => {
    if (stateContent.shareFile.ok) {
      onClose();
      dispatch({ type: ActionContentManagementType.ACTION_SHARE_FILE_RESET });
    }
  }, [stateContent.shareFile.ok]);

  const onSubmit = async (formValue: any) => {
    const lapses = timelines.map((timeline) => {
      return {
        from: timeline.Start,
        to: timeline.Stop,
        eventName: getProjectResultTag(timeline.Tags),
        color: getColorByEvent(stateContent.project.data as ProjectDto, timeline),
      };
    });

    const videoclip = {
      relatedFileId: formValue.playlistExisting,
      lapses: lapses,
    };
    if (formValue.playlistExisting === "") {
      const playlist = {
        type: "Playlist",
        filename: formValue.playlistNew,
        mimeType: "Playlist",
        relatedProjectId: stateContent.document.data?.id,
        relatedVideoId: "",
        content: null,
        description: "",
        teamId: stateContent.document.data?.teamId,
        categoryId: "",
        newCategoryName: "",
        tagsIds: [],
        shareWithUsers: formValue.staff,
        shareWithGroups: formValue.groups,
        shareWithPlayers: formValue.players,
        comment: formValue.comment,
      };
      dispatch({ type: ActionContentManagementType.ACTION_CREATE_PLAYLIST_LOADING, value: { file: playlist, videoclip: videoclip } });
    } else {
      dispatch({ type: ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_LOADING, value: videoclip });
      dispatch({
        type: ActionContentManagementType.ACTION_SHARE_FILE_LOADING,
        value: {
          users: formValue.staff,
          groups: formValue.groups,
          players: formValue.players,
          comment: formValue.comment,
          files: [formValue.playlistExisting],
        },
      });
    }
  };

  const selectedGroup = (options: IdValue[]) => {
    setGroup(options as IdValue[]);
    setGroupValue("");
    formik.setFieldValue(
      "groups",
      options.map((option) => option.id)
    );
  };

  const selectedPlaylist = (options: IdValue[]) => {
    setPlaylist(options as IdValue[]);
    if (options.length > 0) {
      setPlaylistValue(options[0].value);
      formik.setFieldValue("playlistExisting", options[0].id);
    } else {
      formik.setFieldValue("playlistExisting", "");
    }
  };

  const selectedValueGroupDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setGroupValue(e.target.value);
  };

  const selectedValuePlaylistDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setPlaylistValue(e.target.value);
  };

  const selectedUser = (options: IdValue[]) => {
    setUser(options as IdValue[]);
    setUserValue("");
    formik.setFieldValue(
      "staff",
      options.filter((option) => option.type === "staff").map((option) => option.id)
    );
    formik.setFieldValue(
      "players",
      options.filter((option) => option.type === "player").map((option) => option.id)
    );
  };

  const selectedValueUserDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setUserValue(e.target.value);
  };

  const onCloseForm = () => {
    onClose();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid="test-upload-file-form">
        <div className="grid grid-cols-1 mb-8">
          <div>
            <LmAutocomplete
              id="playlistExisting"
              placeholder={t("content.playlistExistingPlaceholder") as string}
              value={playlist}
              valueDisplay={playlistValue}
              disabled={formik.values.playlistNew !== ""}
              setValueDisplay={selectedValuePlaylistDisplay}
              multiple={false}
              options={playlistOptions as IdValue[]}
              selectedValue={(options) => selectedPlaylist(options as IdValue[])}
            />
          </div>
          <div className="mb-5">
            <Divider sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <p>{t("content.addToPlaylistDescription2")}</p>
            </Divider>
            <LmInput
              required={true}
              id="playlistNew"
              disabled={formik.values.playlistExisting !== ""}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.playlistNew}></LmInput>
          </div>
          <div>
            <LmAutocomplete
              label={t("formLabel.shareWithGroups")}
              id="groups"
              placeholder={t("content.groupsPlaceholder") as string}
              value={group}
              valueDisplay={groupValue}
              setValueDisplay={selectedValueGroupDisplay}
              multiple={true}
              options={groupsOptions as IdValue[]}
              selectedValue={(options) => selectedGroup(options as IdValue[])}
            />
          </div>
          <div>
            <LmAutocomplete
              id="staff"
              placeholder={t("content.staffPlaceholder") as string}
              value={user}
              valueDisplay={userValue}
              setValueDisplay={selectedValueUserDisplay}
              multiple={true}
              options={usersOptions as IdValue[]}
              selectedValue={(options) => selectedUser(options as IdValue[])}
            />
          </div>
          <div>
            <LmTextArea
              id="comment"
              label={t("formLabel.comment")}
              blur={formik.handleBlur}
              changeValue={formik.handleChange}
              value={formik.values.comment}></LmTextArea>
          </div>
        </div>

        <div className="flex flex-wrap justify-end gap-4 mt-8">
          <LmButton
            styleButton="secondary"
            type="solid"
            clickAction={onCloseForm}
            size="small"
            text={`${t("buttonLabel.cancel")}`}
            iconPosition="right"></LmButton>
          <LmButton
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={t("buttonLabel.save")}
            isDisabled={stateContent.shareFile.loading || (playlist.length === 0 && formik.values.playlistNew === "")}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
}
