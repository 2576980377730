import { useTranslation } from "react-i18next";
import LmOrganizationTeamForm from "../../components/new-organization-forms/LmOrganizationTeamForm";

const Teams = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-7" data-testid="tab-new-team">
      <p className="text-sm italic mb-8">{t("newOrganization.teamsTitle")}</p>
      <LmOrganizationTeamForm />
    </div>
  );
};

export default Teams;
