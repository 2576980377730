import React, { ChangeEvent } from "react";
import LmIcon from "../icon/LmIcon";
import LmLabel from "../label/LmLabel";
import "../input/LmInput.scss";

interface ITextArea {
  id?: string;
  rows?: number;
  maxlength?: number;
  readonly?: boolean;
  type?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string | null;
  required?: boolean;
  helpText?: string;
  iconSufix?: any;
  prefix?: any;
  iconSufixAction?(): void;
  changeValue?(value: ChangeEvent<HTMLTextAreaElement>): void;
  blur?(value: ChangeEvent<HTMLTextAreaElement>): void;
  focus?(value: ChangeEvent<HTMLTextAreaElement>): void;
  focusout?(value: ChangeEvent<HTMLTextAreaElement>): void;
}

const LmTextArea: React.FC<ITextArea> = ({
  prefix = null,
  id = "",
  rows = 3,
  maxlength = 250,
  readonly = false,
  value = undefined,
  placeholder = "",
  disabled = false,
  error = false,
  label = "",
  required = false,
  iconSufix = null,
  iconSufixAction = () => {},
  helpText = "",
  changeValue = () => {},
  blur = () => {},
  focus = () => {},
}) => {
  return (
    <div
      className={`lm-input min-w-0 xl:min-w-[230px] ${disabled ? "lm-input--disabled" : ""} ${error ? "lm-input--error" : ""} ${
        readonly ? "lm-input--readonly" : ""
      }`}>
      {label !== "" && <LmLabel value={label} id={id} required={required}></LmLabel>}
      <div className="lm-input__wrapper">
        <div className="lm-input__inner">
          {prefix && <>{prefix}</>}
          <textarea
            className="lm-input__element"
            id={id}
            data-testid={id}
            rows={rows}
            maxLength={maxlength}
            readOnly={readonly}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={changeValue}
            onBlur={blur}
            onFocus={focus}
          />
        </div>
        {iconSufix && (
          <div className="lm-input__actions">
            <LmIcon icon={iconSufix} size="xs" testid="input-icon-sufix" clickAction={iconSufixAction} />
          </div>
        )}
      </div>
      {helpText && <div className="lm-input__help">{helpText}</div>}
    </div>
  );
};

export default LmTextArea;
