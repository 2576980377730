import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import PlayButtom from "../../assets/icons/chevron-right.svg";
import { ClipToReactPlayer } from "../../model/dto/ProjectDto";
import { getBase64Img } from "../../utils/utils";

interface IProjectAnalysisReactPlayerPlayList {
  video: string;
  clip: ClipToReactPlayer;
}

const ProjectAnalysisReactPlayerPlayList: React.FC<IProjectAnalysisReactPlayerPlayList> = ({ video, clip }) => {
  const [play, setPlay] = useState(false);
  const [second, setSecond] = useState(0);
  const [id, setId] = useState("");
  const [resetPlay, setResetPlay] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const [drawing, setDrawing] = useState(false);
  const refVideo = useRef<any>(null);
  const [hasDrawing, setHasDrawing] = useState(false);
  const [countDrawing, setCountDrawing] = useState(0);
  const [miniature, setMiniature] = useState("");

  useEffect(() => {
    if (clip && clip.id) {
      setPlay(true);
      setShowControls(true);
      setSecond(clip.start / 1000);
      setHasDrawing(clip.drawings.length > 0);
      setCountDrawing(0);
      setDrawing(false);
    }
  }, [clip, resetPlay]);

  const progress = (data: any) => {
    if (clip && clip.id !== id) {
      setPlay(false);
      setResetPlay(!resetPlay);
    }

    if (clip.id !== "" && data.playedSeconds > clip?.stop / 1000) {
      setPlay(false);
      setShowControls(false);
    } else {
      setSecond(data.playedSeconds);
    }

    if (hasDrawing && countDrawing < clip.drawings.length && data.playedSeconds > clip.drawings[countDrawing].Render / 1000) {
      setPlay(false);
      setDrawing(true);
      setMiniature(clip.drawings[countDrawing].DrawingWithoutBackground);
      if (clip.drawings.length < countDrawing + 1) {
        setDrawing(false);
      } else {
        setCountDrawing(countDrawing + 1);
      }
    }
  };

  const onPlay = () => {
    setDrawing(false);
    setPlay(true);
  };

  const onPause = () => {
    setPlay(false);
  };

  return (
    <div>
      {drawing ? (
        <>
          <img className="react-player" src={getBase64Img(miniature)} width={"100%"} height={"100%"}></img>
          <img
            className="absolute left-3 bottom-3 p-2 rounded-full bg-white/50 cursor-pointer hover:bg-white/70 transition"
            src={PlayButtom}
            onClick={onPlay}></img>
        </>
      ) : (
        <ReactPlayer
          className="react-player"
          url={video}
          controls={showControls}
          ref={refVideo}
          onProgress={progress}
          playing={play}
          onPlay={() => {
            setId(clip.id);
            refVideo.current.seekTo(second);
            setPlay(true);
          }}
          onPause={onPause}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};
export default ProjectAnalysisReactPlayerPlayList;
