import * as Yup from "yup";
import { t } from "i18next";
import TeamGroupResultDto from "../../model/dto/TeamGroupResultDto";

export function groupInitialValues(groupData: TeamGroupResultDto | undefined) {
  return {
    name: groupData?.name || "",
    players: groupData?.players?.map((pl) => pl.id) || [],
  };
}

export function groupValidationSchema() {
  return Yup.object({
    name: Yup.string().required(t("errors.form.required") as string),
  });
}
