import { List, ListItemButton } from "@mui/material";
import { t } from "i18next";
import { TeamPlayer } from "../../model/dto/ProjectDto";
import { getBase64Img } from "../../utils/utils";
import { BACKGROUND_COLOR_TIMELINE_ACTIVE } from "../../constants/globalConstanst";

interface Props {
  playerList: TeamPlayer[];
  playerActive: string;
  onPlayerClicked: (id: string) => void;
}

const MatchStatisticPlayerList = ({ playerList, playerActive, onPlayerClicked }: Props) => {
  return (
    <List component="div" disablePadding>
      {playerList.map((player: TeamPlayer) => (
        <ListItemButton key={player.ID} sx={{ borderBottom: "1px solid var(--bv-color-neutral-200)", backgroundColor: playerActive === player.$id ? BACKGROUND_COLOR_TIMELINE_ACTIVE : ""}} onClick={() => onPlayerClicked(player.$id)}>
          <div className={`flex-1 flex items-center gap-2 max-w-full`}>
            <figure className="shrink-0 h-8 w-8 rounded-full overflow-hidden">
              <img className="object-cover min-h-full" src={getBase64Img(player.Photo)}></img>
            </figure>
            <div className="shrink overflow-hidden">
              <p className="text-sm truncate" title={`${player.NickName} · ${t("content.playerNumber", { value: player.Number })}`}>
                <span className="font-semibold">{player.NickName}</span>
                <span className="text-subtle"> · {t("content.playerNumber", { value: player.Number })}</span>
              </p>
            </div>
          </div>
        </ListItemButton>
      ))}
    </List>
  );
};

export default MatchStatisticPlayerList;
