import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import LmCommentList from "../comment-list/LmCommentList";
import { AuthState } from "../../model/states/AuthState";
import { useEffect, useMemo, useState } from "react";
import { CommentDto } from "../../model/dto/CommentDto";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { getSelectedDataById } from "../../utils/utils";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import EmptyComments from "../../assets/img/empty-comments.png";
import EmptyDashboard from "../empty/emptyDashboard";

interface Props {
  isPlayer?: boolean;
}

const LmLastComments = ({ isPlayer }: Props) => {
  const { t } = useTranslation();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData: OrganizationDto = useMemo(
    () => getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations),
    [stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations]
  );
  const { comments }: ContentManagementState = useSelector((state: MainState) => state.content);
  const loggedUser = stateAuth.userInfo.data.user;
  const { sizeScreen }: InitialState = useSelector((state: MainState) => state.main);
  const [commentsData, setCommentsData] = useState<CommentDto[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (comments.ok) {
      setCommentsData(comments.lastComments);
    }
  }, [comments]);

  useEffect(() => {
    if (stateAuth.userInfo.selectedOrganization) {
      dispatch<TypeActions>({
        type: isPlayer ? ActionContentManagementType.ACTION_GET_LAST_MENTIONS_LOADING : ActionContentManagementType.ACTION_GET_LAST_COMMENTS_LOADING,
        value: { organizationId: stateAuth.userInfo.selectedOrganization, top: isPlayer ? 3 : 2 },
      });
    }
    
  }, [stateAuth.userInfo.selectedOrganization]);

  return comments.loading ? (
    <Loading />
  ) : commentsData.length ? (
    <div>
      <LmCommentList
        isReadMode={true}
        comments={commentsData}
        parentComment={null}
        screenSize={sizeScreen}
        users={selectedOrganizationData?.users ?? []}
        loggedUser={loggedUser}></LmCommentList>
    </div>
  ) : (
    <div>
      <EmptyDashboard text={t("content.emptyCategory")} img={EmptyComments} />
    </div>
  );
};

export default LmLastComments;
