import React from "react";
import { ReactComponent as EmptyImage } from "../../assets/img/empty.svg";

interface IEmpty {
  text: string;
}

const Empty: React.FC<IEmpty> = ({ text = "" }) => {
  return (
    <div className="h-full flex flex-col justify-center items-center gap-4 p-5 bg-gray-300/30 my-5">
      <EmptyImage />
      <p className="font-decorative text-decorative text-sm text-center">{text}</p>
    </div>
  );
};

export default Empty;
