import { useDispatch, useSelector } from "react-redux";
import { UserForm } from "../../model/forms/UserForm";
import UsersListItem from "../users-list-item/UsersListItem";
import { useEffect, useMemo, useState } from "react";
import { formatOrganizationUserDtoToUsersListForm, normalizeString, getSelectedDataById } from "../../utils/utils";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { OrganizationState } from "../../model/states/OrganizationState";
import { useTranslation } from "react-i18next";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";
import LmModalEditMember from "../modal-edit-member/LmModalEditMember";
import Pager from "../pager/Pager";

const ITEMS_PER_PAGE = 10;

const LmUsersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const selectedOrganizationData: OrganizationDto = useMemo(
    () => getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations),
    [stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations]
  ); 
      
  const [filteredList, setFilteredList] = useState<UserForm[]>();
  const [selectedUser, setSelectedUser] = useState<UserForm>();
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false);
  const [openModalEditUser, setOpenModalEditUser] = useState(false);
  const [formatList, setFormatList] = useState<UserForm[]>([]);
  const [listToShow, setListToShow] = useState<UserForm[]>();
  const [activePage, setActivePage] = useState(0);

  const listFiltered = ()=> {
    const auxFormatList = selectedOrganizationData?.users?.map((user) => formatOrganizationUserDtoToUsersListForm(user));
    if (auxFormatList && auxFormatList?.length > 0 && selectedOrganizationData?.users) {
      setFilteredList(auxFormatList);
      setListToShow(auxFormatList.slice(activePage * ITEMS_PER_PAGE, (activePage + 1) * ITEMS_PER_PAGE));
    }
  }

  useEffect(() => {
    if (selectedOrganizationData?.users?.length) {
      const resutl = selectedOrganizationData?.users?.map((user) => formatOrganizationUserDtoToUsersListForm(user));
      setFormatList(resutl);
    }
  }, []);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_FILTER });
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CLEAN_USER_QUICK_FILTER });
    listFiltered();
  }, [stateAuth.userInfo.selectedOrganization]);

  useEffect(() => {    
    listFiltered();
  }, [selectedOrganizationData?.users]);

  useEffect(() => {
    checkFilters(true);
  }, [stateOrganization.organizationUsers.filter, stateOrganization.organizationUsers.quickRoles]);

  useEffect(() => {
    if (stateOrganization.organizationUsers.quickRoles) {
      checkFilters(true);
    } else {
      checkFilters();
    }
  }, [formatList]);

  useEffect(() => {
    checkFilters(true);
  }, [stateOrganization.organizationUsers.sort]);

  const filterListByName = (list?: UserForm[]): UserForm[] => {
    if (list) {
      return list.filter((user) => normalizeString(user?.name + " " + user?.lastName).includes(stateOrganization.organizationUsers.filter?.name ?? ""));
    }

    return [];
  };

  const filterListByRoles = (list?: UserForm[]): UserForm[] => {
    if (list) {
      return list.filter((item) => {
        if (stateOrganization.organizationUsers.filter?.roles?.some((filter) => (item.rolesTotal ?? []).indexOf(filter) >= 0)) {
          return item;
        }
      });
    }

    return [];
  };

  const filterListByQuickRoles = (list?: UserForm[]): UserForm[] => {
    if (list) {
      return list.filter((item) => {
        if (stateOrganization.organizationUsers.quickRoles?.some((filter) => (item.rolesTotal ?? []).indexOf(filter) >= 0)) {
          return item;
        }
      });
    }

    return [];
  };

  const filterListByTeams = (list?: UserForm[]): UserForm[] => {
    if (list) {
      return list.filter((item) => {
        if (stateOrganization.organizationUsers.filter?.teams?.some((filter) => (item.teamsTotal ?? []).indexOf(filter) >= 0)) {
          return item;
        }
      });
    }

    return [];
  };

  const sortList = (list: UserForm[]): UserForm[] => {
    switch (stateOrganization.organizationUsers.sort) {
      case "upName":
        return list.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      case "downName":
        return list.sort((a, b) => {
          return b.name.localeCompare(a.name);
        });
      case "upEmail":
        return list.sort((a, b) => {
          return a.email.localeCompare(b.email);
        });
      case "downEmail":
        return list.sort((a, b) => {
          return b.email.localeCompare(a.email);
        });
      default:
        return list;
    }
  };

  const checkFilters = (resetPagination?: boolean) => {
    const originalList = formatList;
    let resultList: UserForm[] = [...(originalList ?? [])];
    if (stateOrganization.organizationUsers.filter?.name) {
      resultList = filterListByName(originalList);
    }

    if (stateOrganization.organizationUsers.quickRoles?.length) {
      const aux = [...resultList];
      resultList = filterListByQuickRoles(aux);
    } else {
      if (stateOrganization.organizationUsers.filter?.roles?.length) {
        const aux = [...resultList];
        resultList = filterListByRoles(aux);
      }

      if (stateOrganization.organizationUsers.filter?.teams?.length) {
        const aux = [...resultList];
        resultList = filterListByTeams(aux);
      }
    }

    const sorterList = sortList(resultList);
    setFilteredList(resultList);
    if (resetPagination) {
      setActivePage(0);
      setListToShow(sorterList.slice(0 * ITEMS_PER_PAGE, (0 + 1) * ITEMS_PER_PAGE));
    } else {
      setListToShow(sorterList.slice(activePage * ITEMS_PER_PAGE, (activePage + 1) * ITEMS_PER_PAGE));
    }
  };

  const onDeleteUser = () => {
    setOpenModalDeleteUser(false);
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_DELETE_ORGANIZATION_USER_LOADING,
      value: { organizationId: stateAuth.userInfo.selectedOrganization, userId: selectedUser?.id ?? "" },
    });
  };

  const onClickDelete = (user: UserForm) => {
    setSelectedUser(user);
    setOpenModalDeleteUser(true);
  };

  const onClickEdit = (user: UserForm) => {
    setSelectedUser(user);
    setOpenModalEditUser(true);
  };

  const onClickPrevPage = (items: { start: number; end: number }) => {
    setActivePage((prevValue) => prevValue - 1);
    const itemsByPage = filteredList?.slice(items.start - 1, items.end);
    setListToShow(itemsByPage);
  };

  const onClickNextPage = (items: { start: number; end: number }) => {
    setActivePage((prevValue) => prevValue + 1);
    const itemsByPage = filteredList?.slice(items.start - 1, items.end);
    setListToShow(itemsByPage);
  };

  return (
    <>
      <div>
        {listToShow && listToShow.length > 0 ? (
          listToShow?.map((user: UserForm) => (
            <UsersListItem
              key={user.id}
              id={user.id}
              avatarImage={user.avatarImage ? user.avatarImage : selectedOrganizationData?.image}
              name={`${user.name} ${user.lastName}`}
              email={user.email}
              roles={user.roles}
              teams={user.teams}
              actionEdit={() => onClickEdit(user)}
              actionDelete={() => onClickDelete(user)}
            />
          ))
        ) : (
          <div className="flex justify-center mt-8">{t("users.emptyUsers")}</div>
        )}
      </div>
      <div className="flex justify-end items-center mt-4">
        <Pager
          total={filteredList?.length ?? 0}
          itemsForPage={ITEMS_PER_PAGE}
          activePage={activePage}
          actionPrev={(items: { start: number; end: number }) => onClickPrevPage(items)}
          actionNext={(items: { start: number; end: number }) => onClickNextPage(items)}
        />
      </div>
      <LmModalConfirm
        id="test-modal-confirm-remove-user"
        title={t("users.deleteUserTitle")}
        description={t("users.deleteUserSubtitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalDeleteUser}
        handleClose={() => setOpenModalDeleteUser(false)}
        confirm={onDeleteUser}></LmModalConfirm>
      {selectedUser && openModalEditUser && (
        <LmModalEditMember
          open={openModalEditUser}
          handleClose={() => setOpenModalEditUser(false)}
          teams={selectedOrganizationData?.teams}
          organizationId={stateAuth.userInfo.selectedOrganization}
          user={selectedUser}
        />
      )}
    </>
  );
};

export default LmUsersList;
