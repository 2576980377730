import LmIcon from "../icon/LmIcon";
import { ReactComponent as PasswordInfo } from "../../assets/icons/password-info.svg";
import { useTranslation } from "react-i18next";

export function LmCharacteristicsPassword() {
  const { t } = useTranslation();
  return (
    <>
      <p className="text-sm font-semibold mb-2.5">{t("register.passwordInfoTitle")}</p>
      <div className="flex items-center">
        <LmIcon size="2xs" icon={<PasswordInfo />} />
        <p className="text-sm ml-1">{t("register.passwordInfo1")}</p>
      </div>
      <div className="flex items-center">
        <LmIcon size="2xs" icon={<PasswordInfo />} />
        <p className="text-sm ml-1">{t("register.passwordInfo2")}</p>
      </div>
      <div className="flex items-center">
        <LmIcon size="2xs" icon={<PasswordInfo />} />
        <p className="text-sm ml-1">{t("register.passwordInfo3")}</p>
      </div>
      <div className="flex items-center">
        <LmIcon size="2xs" icon={<PasswordInfo />} />
        <p className="text-sm ml-1">{t("register.passwordInfo4")}</p>
      </div>
    </>
  );
}
