import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LmButton from "../button/LmButton";
import { mainDataInitialValues, mainDataValidationSchema } from "./NewOrganizationForms.data";
import { OrganizationState } from "../../model/states/OrganizationState";
import { MainState } from "../../model/states/MainState";
import LmInput from "../input/LmInput";
import LmMessageError from "../message-error/LmMessageError";
import { Divider, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { ROUTE_LICENSE_SELECTOR } from "../../routes/routes";
import { ViewMode } from "../../model/ViewMode";
import LoadImage from "../load-image/LoadImage";
import LmSelect from "../select/LmSelect";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { IdValue } from "../../model/forms/IdValue";
import CreateOrganizationDto from "../../model/dto/CreateOrganizationDto";
import { getSelectedDataById, organizationFormsToDto } from "../../utils/utils";
import { AuthState } from "../../model/states/AuthState";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { URL_LICENSES_INFO } from "../../constants/globalConstanst";
import StorageBarMultiple from "../storage-bar/StorageBarMultiple";

interface Props {
  onClose?(): void;
  modal: boolean;
}

const LmMainDataForm = ({ onClose = () => {}, modal = false }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);

  const createFormData = stateOrganization.organizationForm.mainData;
  const viewMode = stateOrganization.organizationForm.viewMode;

  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  const licenseData =
    viewMode === ViewMode.CREATE
      ? getSelectedDataById(stateAuth.userInfo.selectedLicense, stateAuth.userInfo.data.freeLicenses)
      : selectedOrganizationData?.license || null;

  const navigate = useNavigate();
  const [image, setImage] = useState(viewMode === ViewMode.CREATE ? createFormData.image : selectedOrganizationData?.image);
  
  const options: IdValue[] = [
    {
      id: "dark-blue",
      value: t("colorSelector.darkBlue"),
      icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#385ab5" }} />,
    },
    {
      id: "light-blue",
      value: t("colorSelector.lightBlue"),
      icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#59adf0" }} />,
    },
    {
      id: "dark-green",
      value: t("colorSelector.darkGreen"),
      icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#147121" }} />,
    },
    {
      id: "light-green",
      value: t("colorSelector.lightGreen"),
      icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#61bb4a" }} />,
    },
    { id: "red", value: t("colorSelector.red"), icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#d32f2f" }} /> },
    { id: "orange", value: t("colorSelector.orange"), icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#df7133" }} /> },
    { id: "pink", value: t("colorSelector.pink"), icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#cd519d" }} /> },
    { id: "purple", value: t("colorSelector.purple"), icon: <div style={{ width: "12px", height: "12px", backgroundColor: "#7c36d7" }} /> },
  ];
  const [orgColor, setOrgColor] = useState(
    options.find((option) => (viewMode === ViewMode.CREATE ? createFormData.color === option.id : selectedOrganizationData?.color === option.id)) || options[0]
  );
  const formik = useFormik({
    initialValues: mainDataInitialValues(viewMode === ViewMode.CREATE ? createFormData : selectedOrganizationData, licenseData),
    validationSchema: mainDataValidationSchema(),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (formData) => onSubmit(formData),
  });

  useEffect(() => {
    formik.validateForm();
    onchangeColor(orgColor);
  }, []);

  useEffect(() => {
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CHANGE_MAIN_DATA_FORM_VALUE,
      value: { key: "isFormValid", value: isFormValid() },
    });
  }, [formik.errors]);

  const onSubmit = async (formData: any) => {
    if (viewMode === ViewMode.CREATE) {
      const newOrganization: CreateOrganizationDto = organizationFormsToDto(createFormData, licenseData);
      dispatch<TypeActions>({
        type: ActionOrganizationType.ACTION_CREATE_ORGANIZATION_LOADING,
        value: newOrganization,
      });
    } else {
      const editOrganization = organizationFormsToDto({ ...formData, image, color: orgColor.id }, licenseData);
      dispatch<TypeActions>({
        type: ActionOrganizationType.ACTION_EDIT_ORGANIZATION_LOADING,
        value: { organizationId: selectedOrganizationData?.id || "", dataCallback: { data: editOrganization, callBack: onClose } },
      });
    }
  };

  const goBack = () => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_CHANGE_COLOR_APLICATION, value: selectedOrganizationData?.color as string });
    if (modal) {
      onClose();
    } else {
      navigate("/" + ROUTE_LICENSE_SELECTOR, { state: { stayOnPage: true } });
    }
  };

  const onBlurInput = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
    const key = e.target.id;
    const value = e.target.value;
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CHANGE_MAIN_DATA_FORM_VALUE,
      value: { key, value },
    });
  };

  const isFormValid = () => {
    const hasError = Object.keys(formik.errors).length !== 0;
    return !hasError;
  };

  const changeImage = (image: string) => {
    setImage(image);
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CHANGE_MAIN_DATA_FORM_VALUE,
      value: { key: "image", value: image },
    });
  };

  const onchangeColor = (option: IdValue) => {
    setOrgColor(option);
    dispatch<TypeActions>({ type: ActionMainType.ACTION_CHANGE_COLOR_APLICATION, value: option.id });
    dispatch<TypeActions>({
      type: ActionOrganizationType.ACTION_CHANGE_MAIN_DATA_FORM_VALUE,
      value: { key: "color", value: option.id },
    });
  };

  return (
    <>
      <div className="flex flex-wrap gap-4 justify-between items-center p-4 md:py-2 mb-7" style={{ backgroundColor: "rgba(241, 243, 246, 0.5)" }}>
        <div className="flex items-center">
          <LoadImage img={image} id="image" onChangeImage={changeImage} />
          <p className="text-sm font-semibold ml-4">{t("newOrganization.loadImageLabel")}</p>
        </div>
        <div className="w-full md:w-auto">
          <LmSelect id="color-select" options={options} selectedValue={onchangeColor} value={orgColor} prefix={orgColor.icon} />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} className="w-full" data-testid="main-data-form">
        <div className="flex flex-wrap gap-6">
          <p className="font-decorative text-decorative w-full xl:w-48">{t("newOrganization.dataSectionTitle")}</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full xl:w-auto">
            <div>
              <LmInput
                required={true}
                id="name"
                label={t("formLabel.name")}
                changeValue={formik.handleChange}
                blur={onBlurInput}
                value={formik.values.name}
                autoComplete="off"></LmInput>
              {formik.touched.name && formik.errors.name && <LmMessageError id="name" message={formik.errors.name}></LmMessageError>}
            </div>
            <div>
              <LmInput
                required={true}
                id="address"
                label={t("formLabel.address")}
                changeValue={formik.handleChange}
                blur={onBlurInput}
                value={formik.values.address}
                autoComplete="off"></LmInput>
              {formik.touched.address && formik.errors.address && <LmMessageError id="address" message={formik.errors.address}></LmMessageError>}
            </div>
            <div>
              <LmInput
                required={true}
                id="cifVat"
                label={t("formLabel.cifVat")}
                changeValue={formik.handleChange}
                blur={onBlurInput}
                value={formik.values.cifVat}
                autoComplete="off"></LmInput>
              {formik.touched.cifVat && formik.errors.cifVat && <LmMessageError id="cifVat" message={formik.errors.cifVat}></LmMessageError>}
            </div>
            <div className="xl:col-span-2">
              <LmInput
                required={true}
                id="email"
                label={t("formLabel.email")}
                changeValue={formik.handleChange}
                blur={onBlurInput}
                value={formik.values.email}
                autoComplete="off"></LmInput>
              {formik.touched.email && formik.errors.email && <LmMessageError id="email" message={formik.errors.email}></LmMessageError>}
            </div>
            <div>
              <LmInput
                required={true}
                id="phone"
                label={t("formLabel.phone")}
                changeValue={formik.handleChange}
                blur={onBlurInput}
                value={formik.values.phone}
                autoComplete="off"></LmInput>
              {formik.touched.phone && formik.errors.phone && <LmMessageError id="phone" message={formik.errors.phone}></LmMessageError>}
            </div>
          </div>
        </div>

        <Divider sx={{ margin: "1.5rem 0" }} />

        <div className="flex flex-wrap gap-6">
          <div className="w-full xl:w-48 flex justify-between xl:block">
            <p className="font-decorative text-decorative mb-2">{t("newOrganization.planSectionTitle")}</p>
            <Link className="m-new-organization__features-link" href={URL_LICENSES_INFO} target="_blank" rel="noreferrer" underline="none" color="inherit">
              {t("newOrganization.planSectionSubtitle")}
            </Link>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full xl:w-auto">
            <LmInput disabled id="license" label={t("formLabel.license")} changeValue={formik.handleChange} value={formik.values.license}></LmInput>
            <LmInput disabled id="validUntil" label={t("formLabel.validUntil")} changeValue={formik.handleChange} value={formik.values.validUntil}></LmInput>
            <LmInput
              disabled
              id="storage"
              label={t("formLabel.storage")}
              changeValue={formik.handleChange}
              value={formik.values.storage}
              iconSufix={"GB"}></LmInput>
            {stateAuth.userInfo.selectedOrganization !== '' && <div className="md:col-span-3 mt-2 xl:mt-9">
              <StorageBarMultiple       
                maxCapacity={selectedOrganizationData?.license?.storage as number}
                data = {selectedOrganizationData?.teams.map(team => {return {name: team.name, value: team?.consumedStorage as number}})}
              />
            </div>}
          </div>
        </div>

        <Divider sx={{ margin: "1.5rem 0" }} />

        <div className="flex flex-wrap justify-end gap-4">
          <LmButton
            isDisabled={stateOrganization.organizationForm.loading}
            styleButton="secondary"
            type="solid"
            clickAction={goBack}
            size="small"
            text={`${t("buttonLabel.cancel")}`}
            iconPosition="right"></LmButton>
          <LmButton
            isDisabled={!formik.isValid || stateOrganization.organizationForm.loading}
            styleButton="organization"
            buttonType="submit"
            type="solid"
            size="small"
            text={viewMode === ViewMode.CREATE ? `${t("buttonLabel.continue")}` : `${t("buttonLabel.save")}`}
            iconPosition="right"></LmButton>
        </div>
      </form>
    </>
  );
};

export default LmMainDataForm;
