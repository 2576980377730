import { Dialog } from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { IdValue } from "../../model/forms/IdValue";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import LmAutocomplete from "../autocomplete/LmAutocomplete";
import LmButton from "../button/LmButton";
import LmMessageError from "../message-error/LmMessageError";
import { InitialState } from "../../model/states/InitialState";
interface Props {
  initialCategory?: IdValue;
  open: boolean;
  handleClose(): void;
}
const LmModalChangeFileCategory = ({ initialCategory, open, handleClose }: Props) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const dispatch = useDispatch();
  const [category, setCategory] = useState<IdValue[]>(initialCategory ? [initialCategory] : []);
  const [categoryValue, setCategoryValue] = useState(initialCategory ? initialCategory.value : "");
  const { t } = useTranslation();

  const categoryOptions = useMemo(
    () =>
      stateContent.categories.data.map((category) => {
        return { id: category.id, value: category.name };         
      }),
    [stateContent.editCategory.loading, stateContent.categories, stateContent.uploadDocument.ok]
  );

  const formik = useFormik({
    initialValues: { categoryId: "" },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
  });

  const resetForm = () => {
    formik.resetForm();
    setCategoryValue("");
    setCategory([]);
  };

  useEffect(() => {
    if (stateContent.changeFileCategory.ok) {
      resetForm();
      handleClose();
    }
  }, [stateContent.changeFileCategory.ok]);

  const onClose = () => {
    resetForm();
    handleClose();
  };

  const selectedCategory = (options: IdValue[]) => {
    setCategory(options as IdValue[]);
    if (options.length > 0) {
      setCategoryValue(options[0].value);
      formik.setFieldValue("categoryId", options[0].id);
    }
  };

  const selectedValueCategoryDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setCategoryValue(e.target.value);
    formik.setFieldValue("categoryId", e.target.value);
  };

  const onSubmit = async (formValue: any) => {  
    const newCategory = formValue.categoryId === categoryValue;
    dispatch({
      type: ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_LOADING,
      value: { files: stateContent.filesSelected, newCategoryName: newCategory ? categoryValue : "", categoryId: newCategory ? "" : formValue.categoryId },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8">
        <div className="flex justify-between items-center mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl"> {t("content.modalChangeCategoryTitle")}</p>
          <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-change-category" />
        </div>
        <p className="mb-3">{t("content.modalChangeCategorySubTitle")}</p>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-full" data-testid="test-upload-file-form">
          <div>
            <LmAutocomplete
              placeholder={t("formLabel.category") as string}
              autocomplete="off"
              id="categoryId"
              value={category}
              valueDisplay={categoryValue}
              multiple={false}
              options={categoryOptions as IdValue[]}
              setValueDisplay={selectedValueCategoryDisplay}
              selectedValue={(options) => selectedCategory(options as IdValue[])}
            />
            {formik.touched.categoryId && formik.errors.categoryId && <LmMessageError id="categoryId" message={formik.errors.categoryId}></LmMessageError>}
          </div>
          <div className="flex flex-wrap justify-end gap-4 mt-8">
            <LmButton
              styleButton="secondary"
              type="solid"
              clickAction={onClose}
              size="small"
              text={`${t("buttonLabel.cancel")}`}
              iconPosition="right"></LmButton>
            <LmButton
              styleButton="organization"
              buttonType="submit"
              type="solid"
              size="small"
              isDisabled={stateContent.changeFileCategory.loading}
              text={t("buttonLabel.save")}
              iconPosition="right"></LmButton>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default LmModalChangeFileCategory;
