import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";

interface Props {
  icon: any;
  text: string;
  open: boolean;
  route?: string;
  onClick?(): void;
}

export default function MenuItem({ icon, text, open, route, onClick }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  const navigateTo = () => {
    if (onClick) {
      onClick();
    }

    if (route) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: route });
      navigate(route);
    }
  };

  useEffect(() => {
    const formatLocation = location.pathname.split("/")[1];
    setIsActive(formatLocation === route);
  }, [location]);

  return (
    <ListItem
      className={`lm-menu-item text-white relative opacity-70 transition ${isActive && "lm-menu-item--active"}`}
      key={text}
      disablePadding
      sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
        onClick={navigateTo}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}
