import React, { useMemo } from "react";

import { Navigate } from "react-router-dom";
import { ROUTE_PRIVATE_DASHBOARD } from "./routes";

import { useSelector } from "react-redux";
import { AuthState } from "../model/states/AuthState";
import { MainState } from "../model/states/MainState";
import { getSelectedDataById, isOwner, isStaffByOrganization } from "../utils/utils";
interface Props {
  access: string[];
  children: JSX.Element;
}

const PermissionsRoute: React.FC<Props> = ({ access, children }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const selectedOrganizationData = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const isStaffByOrg = useMemo(() => isStaffByOrganization(selectedOrganizationData, stateAuth), [stateAuth.userInfo.selectedOrganization]);

  const checkPermissions = () => {
    let check = false;
    access.forEach((element) => {
      if (element === "ownerClub") {
        check = check || isOwner(stateAuth, selectedOrganizationData?.id);
      } else if (element === "owner") {
        check = check || isOwner(stateAuth, selectedOrganizationData?.id);
      } else if (element === "staff") {
        check = check || isStaffByOrg;
      }
    });
    return check;
  };

  return checkPermissions() ? children : <Navigate to={"/" + ROUTE_PRIVATE_DASHBOARD} />;
};

export default PermissionsRoute;
