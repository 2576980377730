import React from "react";
import LmIcon from "../icon/LmIcon";

interface ILmTag {
  label: string;
  icon?: any;
  id?: string;
  type: string;
  onSelect?(id: string): void;
  onClickAction?(id: string): void;
}

const LmTag: React.FC<ILmTag> = ({ label, icon = null, id = "", type = "", onSelect = () => {}, onClickAction = () => {} }) => {
  return (
    <div
      data-testid={id}
      className={`flex items-center gap-2 px-2 py-1 rounded-full ${type === "Public" ? "bg-[#E4F5E6]" : "bg-neutral-50"}`}
      onClick={() => onSelect(id)}>
      <p className="text-sm flex gap-1">{label}</p>
      {icon && <LmIcon className="ml-auto cursor-pointer hover:opacity-80 transition" icon={icon} size="sm" clickAction={() => onClickAction(id)} />}
    </div>
  );
};

export default LmTag;
