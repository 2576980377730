import { t } from "i18next";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LmAutocomplete from "../../components/autocomplete/LmAutocomplete";
import { IdValue } from "../../model/forms/IdValue";
import { MainState } from "../../model/states/MainState";

import LmAccordion from "../../components/content-management/LmAccordion";
import LmDocument from "../../components/content-management/LmDocument";
import MasiveOptions from "../../components/content-management/MasiveOptions";
import UploadFile from "../../components/content-management/UploadFile";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ContentManagementState, ContentManagementType, FileTypeState } from "../../model/states/ContentManagementState";

import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import LmDocumentDraggable from "../../components/content-management/LmDocumentDraggable";
import DocumentDto from "../../model/dto/DocumentDto";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { getSelectedDataById, isStaff, isStaffByOrganization, typesContentStateFilesSelected } from "../../utils/utils";
import Empty from "../../components/empty/empty";

const getFiles = (stateContent: ContentManagementState): FileTypeState =>
  stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)];

const ContentManagementDocument: React.FC = () => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const dispatch = useDispatch();
  const [documentFilter, setDocumentFilter] = useState<IdValue[]>([]);
  const [documentFilterValue, setDocumentFilterValue] = useState("");
  const [documentsShown, setDocumentsShown] = useState(getFiles(stateContent)?.data || []);
  const [selectAllDocuments, setSelectAllDocuments] = useState(false);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const navigate = useNavigate();
  const stateCategories = getFiles(stateContent)?.stateCategory;
  const isStaffByOrg = useMemo(() => isStaffByOrganization(selectedOrganizationData, stateAuth), [stateAuth.userInfo.selectedOrganization]);
  const typeDocumentPlaceholder: string[] = useMemo(() => ["documents", "videos", "playlist", "dashboard", "template", "project"], []);

  const isStaffByTeam = (idTeam: string) => {
    if (selectedOrganizationData && selectedOrganizationData?.teams && Array.isArray(selectedOrganizationData?.teams)) {
      const team = selectedOrganizationData?.teams.find((team) => team.id === idTeam);
      if (team) {
        return isStaff(team, stateAuth);
      }
    }    
    return false;
  };

  const filterDocuments = () => {
    let docs: DocumentDto[] = [];
    if (getFiles(stateContent)) {
      docs = [...getFiles(stateContent).data];
    }
    
    if (getFiles(stateContent)?.teamSelected !== "") {
      docs = getFiles(stateContent)?.data.filter((document) => document.teamId === getFiles(stateContent)?.teamSelected);
    }
    return docs;
  };

  useEffect(() => {
    // Scroll to last item viewed
    const hash = window.location.hash.slice(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [documentsShown]);

  useEffect(() => {
    dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: [] });
  }, []);

  useEffect(() => {
    setDocumentsShown(filterDocuments());
  }, [
    getFiles(stateContent)?.teamSelected,
    stateContent.uploadDocument.ok,
    selectedOrganizationData,
    stateContent.changeFileCategory.ok,
    stateContent.deleteCategory,
    stateContent.typesOfFiles[typesContentStateFilesSelected(stateContent.contentSelected)]?.order,
  ]);

  useEffect(() => {
    const newDocsFiltered = stateContent.filesSelected.filter((doc) => documentsShown.find((docShow) => docShow.id === doc));
    dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: newDocsFiltered });
  }, [documentsShown]);

  const optionsDocumentsNameFilter = useMemo(() => {
    return filterDocuments()?.map((doc) => {
      if (doc.tags.length > 0) {
        return { id: doc.id, value: doc.fileName + ":" + doc.tags.map((tag) => tag.name).join(",") };
      }
      return { id: doc.id, value: doc.fileName };
    });
  }, [getFiles(stateContent)?.teamSelected, stateContent.uploadDocument.ok]);

  const selectedValueDocumentDisplay = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentFilterValue(e.target.value.split("/")[0]);
  };

  const selectedDocument = (options: IdValue[]) => {
    setDocumentFilter(options as IdValue[]);
    if (options.length > 0) {
      setDocumentFilterValue(options[0].value.split("/")[0]);
      filteredDocuments(options);
    }
  };

  const filteredDocuments = (documentsFiltered: IdValue[]) => {
    const docsToShow: DocumentDto[] = [];
    documentsFiltered.forEach((doc) => {
      const findDoc = filterDocuments().find((docShow) => docShow.id === doc.id);
      if (findDoc) {
        docsToShow.push(findDoc);
      }
    });
    setDocumentsShown(docsToShow);
  };

  const onDragEnd = (result: DropResult) => {
    if (result.destination && result.destination.droppableId !== result.source.droppableId) {
      const document = getFiles(stateContent)?.data[result.source.index];
      dispatch({
        type: ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_LOADING,
        value: { files: [document.id], categoryId: result.destination?.droppableId !== "0" ? result.destination?.droppableId : null, newCategoryName: null },
      });
    }
  };

  const onSelectDocument = (documentId: string, select: boolean) => {
    setSelectAllDocuments(false);
    let files = [];
    if (select) {
      files = [...stateContent.filesSelected, documentId];
    } else {
      files = stateContent.filesSelected.filter((document) => document !== documentId);
    }
    dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: files });
  };

  const onSelectAlldocuments = () => {
    let files: string[] = [];
    if (!selectAllDocuments) {
      files = getFiles(stateContent)?.data.map((doc) => doc.id);
    }
    dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: files });
    setSelectAllDocuments(!selectAllDocuments);
  };

  const editDocument = (document: DocumentDto) => {
    dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: [document?.id] });
    if (stateContent.contentSelected === ContentManagementType.Project) {
      dispatch({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_LOADING, value: document.id });
    }
    navigate(document.id);
  };

  const hasCategory = useMemo(
    () => getFiles(stateContent)?.data.filter((doc) => doc.categoryId !== null).length,
    [stateContent.uploadDocument.ok, selectedOrganizationData, stateContent.changeFileCategory.ok]
  );

  return (
    <div data-testid="page-content-management">
      <div>
        <LmAutocomplete
          placeholder={t("content.documentsPlaceholder", { value: t("content." + typeDocumentPlaceholder[stateContent.contentSelected]) }) as string}
          autocomplete="off"
          id="documentFilter"
          value={documentFilter}
          valueDisplay={documentFilterValue}
          multiple={false}
          options={optionsDocumentsNameFilter as IdValue[]}
          setValueDisplay={selectedValueDocumentDisplay}
          selectedValue={selectedDocument}
          dataFiltered={filteredDocuments}
        />
      </div>

      <div className="flex flex-wrap items-center gap-4 py-5">
        <div className="flex items-center gap-2">
          <div
            className={`w-4 h-4 border border-neutral-200 rounded-full transition ${selectAllDocuments && "border-4 border-orgColor bg-decorative"}`}
            id="select"
            onClick={onSelectAlldocuments}></div>
          <p>
            {t("content.selectAll")} ({stateContent.filesSelected.length} Selected)
          </p>
        </div>
        <div className="flex items-center gap-2  ml-auto">
          {(stateContent.contentSelected !==2 || (stateContent.contentSelected ===2 && isStaffByOrg)) && <MasiveOptions isDetail={false}></MasiveOptions>}
          {stateContent.contentSelected <= 1 && isStaffByOrg && <UploadFile />}
        </div>
      </div>
      {hasCategory > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="space-y-1">            
            {stateCategories.map((cat) => (
              <LmAccordion key={cat.idCategory} data={{id: cat.idCategory, name: cat.name}} isStaff={isStaffByOrg} open={cat.state} typeOfFile={typesContentStateFilesSelected(stateContent.contentSelected)}>
                {documentsShown
                  .filter((doc) => doc.category?.id === cat.idCategory)
                  .map((doc) =>
                    isStaffByTeam(doc.teamId) ? (
                      <LmDocumentDraggable
                        key={doc.id}
                        data={doc}
                        index={doc.index}
                        selected={stateContent.filesSelected.indexOf(doc.id) !== -1}
                        onSelect={onSelectDocument}
                        onEdit={editDocument}
                      />
                    ) : (
                      <LmDocument
                        key={doc.id}
                        data={doc}
                        selected={stateContent.filesSelected.indexOf(doc.id) !== -1}
                        onSelect={onSelectDocument}
                        onEdit={editDocument}
                      />
                    )
                  )}
              </LmAccordion>
            ))}

            <LmAccordion
              data={{ id: "0", name: t("content.noCategory", { value: t("content." + typeDocumentPlaceholder[stateContent.contentSelected]) }) }}
              isStaff={isStaffByOrg}
              open={stateCategories.find(cat => cat.idCategory === '0')?.state as boolean}
              typeOfFile={typesContentStateFilesSelected(stateContent.contentSelected)}>
              {documentsShown
                .filter((doc) => doc.category === null)
                .map((doc) =>
                  isStaffByTeam(doc.teamId) ? (
                    <LmDocumentDraggable
                      key={doc.id}
                      data={doc}
                      index={doc.index}
                      selected={stateContent.filesSelected.indexOf(doc.id) !== -1}
                      onSelect={onSelectDocument}
                      onEdit={editDocument}
                    />
                  ) : (
                    <LmDocument
                      key={doc.id}
                      data={doc}
                      selected={stateContent.filesSelected.indexOf(doc.id) !== -1}
                      onSelect={onSelectDocument}
                      onEdit={editDocument}
                    />
                  )
                )}
            </LmAccordion>
          </div>
        </DragDropContext>
      )}
      {hasCategory === 0 && (
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 "py-5 cursor-pointer`}>
          {documentsShown.map((doc) => (
            <LmDocument
              key={doc.id}
              data={doc}
              selected={stateContent.filesSelected.indexOf(doc.id) !== -1}
              onSelect={onSelectDocument}
              onEdit={editDocument}
            />
          ))}
        </div>
      )}
      {documentsShown.length === 0 && (
        <Empty text={t("content.noFilesToShow", { value: t("content." + typeDocumentPlaceholder[stateContent.contentSelected]) })} />
      )}
    </div>
  );
};
export default ContentManagementDocument;
