import { Divider, Tab, Tabs } from "@mui/material";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MasiveOptions from "../../components/content-management/MasiveOptions";
import { ContentManagementState, ContentManagementTabs, ContentManagementType } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";

import ReactPlayer from "react-player";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import File from "../../assets/img/file.svg";
import LmIcon from "../../components/icon/LmIcon";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT, ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL } from "../../routes/routes";

import LmTag from "../../components/autocomplete/LmTag";
import LmTags from "../../components/autocomplete/LmTags";
import LmCommentList from "../../components/comment-list/LmCommentList";
import DetailDocumentShare from "../../components/content-management/DetailDocumentShare";
import LmDashboard from "../../components/dashboard/LmDashboard";
import DocumentMetadata from "../../components/document-metadata/DocumentMetadata";
import Empty from "../../components/empty/empty";
import Loading from "../../components/loading/Loading";
import LmModalConfirm from "../../components/modal-confirm/LmModalConfirm";
import LmModalChangeFileCategory from "../../components/modal-content-change-category/LmModalChangeFileCategory";
import LmModalGrantAccess from "../../components/modal-grant-access/LmModalGrantAccess";
import LmPlayerList from "../../components/player-list/LmPlayerList";
import ModalTemplatePlayer from "../../components/player-list/PlayerModal";
import LmPlaylistItem from "../../components/playlistItem/LmPlaylistItem";
import ProjectAnalysis from "../../components/projectAnalysis/ProjectAnalysis";
import ProjectAnalysisReactPlayer from "../../components/projectAnalysis/ProjectAnalysisReactPlayer";
import ProjectAnalysisReactPlayerPlayList from "../../components/projectAnalysis/ProjectAnalysisReactPlayerPlayList";
import ProjectZonalTags from "../../components/projectAnalysis/ProjectZonalTags";
import { SizeScreen } from "../../model/SizeSreen";
import { ActionAuthType } from "../../model/actions/typeAuthActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import { ActionPlayerType } from "../../model/actions/typePlayerActions";
import { CommentDto, UserAccessDto } from "../../model/dto/CommentDto";
import { DashboardDataDto, DashboardDto } from "../../model/dto/DashboardDto";
import DocumentDto, { TagsDto } from "../../model/dto/DocumentDto";
import OrganizationDto from "../../model/dto/OrganizationDto";
import OrganizationUserDto from "../../model/dto/OrganizationUserDto";
import { ClipToReactPlayer, Timeline } from "../../model/dto/ProjectDto";
import TeamsDto from "../../model/dto/TeamDto";
import { TemplateDto } from "../../model/dto/TemplateDto";
import { TemplatePlayerDto } from "../../model/dto/TemplatePlayerDto";
import UserDto from "../../model/dto/UserDto";
import { IdValue } from "../../model/forms/IdValue";
import { AuthState } from "../../model/states/AuthState";
import { InitialState } from "../../model/states/InitialState";
import { getSelectedDataById, getTypeOfDocument, isStaff, isStaffRoleIncluded } from "../../utils/utils";
import "./ContentManagementDetail.scss";
import Playlists from "./tab-playlists/Playlists";

export default function ContentManagementDetail() {
  const { sizeScreen }: InitialState = useSelector((state: MainState) => state.main);
  const { tags, contentSelected, document, comments, addTagToFile, deleteFiles, documentUrl, grantAccess }: ContentManagementState = useSelector(
    (state: MainState) => state.content
  );
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const loggedUser = stateAuth.userInfo.data.user;

  const tagsOptions = useMemo(
    () =>
      tags.data
        .filter((tag) => tag.type === "Public")
        .map((tag) => {
          return { id: tag.id, value: tag.name, type: tag.type };
        })
        .concat(
          tags.private
            .filter((tag) => tag.teamId === document.data?.teamId)
            .map((tag) => {
              return { id: tag.id, value: tag.name, type: tag.type };
            })
        ),
    [tags.loading]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [selectedTab, setSelecteTab] = useState(
    stateContent.contentSelected === ContentManagementType.Project ? ContentManagementTabs.Analysis : ContentManagementTabs.Comments
  );
  const [selectedTabValue, setSelecteTabValue] = useState(0);

  const [documentData, setDocumentData] = useState<DocumentDto | null>(null);
  const [commentsData, setCommentsData] = useState<CommentDto[]>([]);
  const [parentComment, setParentComment] = useState<CommentDto | null>(null);
  const [modalDeleteComent, setModalDeleteComent] = useState(false);
  const [modalChangeCategory, setModalChangeCategory] = useState(false);
  const [modalGrantAccess, setModalGrantAccess] = useState(false);
  const [modalViewPlayer, setModalViewPlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<TemplatePlayerDto | null>(null);
  const [commentToDelete, setCommentToDelete] = useState("");
  const [commentToDeleteIsParent, setCommentToDeleteIsParent] = useState(false);
  const [isStaffByTeam, setIsStaffByTeam] = useState(false);
  const [templateData, setTemplateData] = useState<TemplateDto | null>(null);
  const [dashboardData, setDashboardData] = useState<DashboardDto | null>(null);
  const [loading, setLoading] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [urlLoading, setUrlLoading] = useState(false);
  const [saveCommentData, setSaveCommentData] = useState<Partial<CommentDto> | null>(null);
  const [usersGrantAccess, setUsersGrantAccess] = useState<UserAccessDto[]>([]);
  const [clipSelected, setClipSelected] = useState<ClipToReactPlayer>({ start: 0, stop: 0, id: "", drawings: [] });
  const tabsProjects = useMemo(
    () => [
      ContentManagementTabs.Analysis,
      ContentManagementTabs.ZonalTags,
      ContentManagementTabs.Comments,
      ContentManagementTabs.Share,
      ContentManagementTabs.Playlist,
    ],
    [stateContent.contentSelected]
  );

  const tabs = useMemo(() => [ContentManagementTabs.Comments, ContentManagementTabs.Share], [stateContent.contentSelected]);
  const tabsPlaylist = useMemo(() => [ContentManagementTabs.Clips, ContentManagementTabs.Comments], [stateContent.contentSelected]);
  const [users, setUsers] = useState<Partial<UserDto>[]>([]);

  const tabSelectedByDocumentType = (value: number) => {
    if (stateContent.contentSelected === ContentManagementType.Project) {
      setSelecteTab(tabsProjects[value]);
      dispatch({ type: ActionContentManagementType.ACTION_CONTENT_DETAIL_TAB_SELECTED, value: tabsProjects[value] });
    } else if (stateContent.contentSelected === ContentManagementType.Playlist) {
      setSelecteTab(tabsPlaylist[value]);
      dispatch({ type: ActionContentManagementType.ACTION_CONTENT_DETAIL_TAB_SELECTED, value: tabsPlaylist[value] });
    } else {
      setSelecteTab(tabs[value]);
      dispatch({ type: ActionContentManagementType.ACTION_CONTENT_DETAIL_TAB_SELECTED, value: tabsPlaylist[value] });
    }
  };

  useEffect(() => {
    tabSelectedByDocumentType(0);
  }, [stateContent.contentSelected]);

  useEffect(() => {
    const { id, commentId } = params;
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL });
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_LOADING, value: id as string });
    dispatch<TypeActions>({ type: ActionPlayerType.ACTION_PLAYER_BY_ORGANIZATION_UNREGISTERED_LOADING, value: stateAuth.userInfo.selectedOrganization });
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: [id as string] });
    dispatch<TypeActions>({
      type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_LOADING,
      value: id as string,
    });

    if (commentId) {
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING, value: commentId });
    }
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_GET_ORGANIZATION_USERS_LOADING, value: stateAuth.userInfo.selectedOrganization });
  }, []);

  const isRolePlayer = (user: OrganizationUserDto) => {
    const teamId = document.data?.teamId;
    const isRolePlayerInTeam =
      user.teamUsers.find((team) => team.teamId === teamId && team.roles.length === 1 && team.roles.indexOf("Player") !== -1) !== undefined;
    const noRoleInTeam = user.teamUsers.find((team) => team.teamId === teamId) === undefined;
    const isStaffInOtherTeam =
      user.teamUsers.find((team) => team.teamId !== teamId && (team.roles.length > 1 || (team.roles.length === 1 && team.roles.indexOf("Player") === -1))) !==
      undefined;
    return (isRolePlayerInTeam && !isStaffInOtherTeam) || (noRoleInTeam && !isStaffInOtherTeam);
  };

  const isStaffInThisTeam = (userId: string) => {
    const teamId = document.data?.teamId;
    const user = selectedOrganizationData?.users?.find((user) => user.userId === userId);
    let isStaffInTeam = false;
    if (user) {
      isStaffInTeam = user.teamUsers.find((team) => team.teamId === teamId && isStaffRoleIncluded(team.roles)) ? true : false;
    }
    if (selectedOrganizationData?.license?.owner === user?.userId) {
      isStaffInTeam = true;
    }
    return isStaffInTeam;
  };

  useEffect(() => {
    if (selectedOrganizationData) {
      const { users, playersUnregistered } = selectedOrganizationData;
      let orgUsers: Partial<UserDto>[] = [];

      if (users?.length) {
        const mappedUsers = selectedOrganizationData?.users!.map((user) => ({
          id: user.user?.id,
          name: user.user?.name,
          lastName: user.user?.lastName,
          avatarImage: user.avatarImage ? user.avatarImage : user.user?.avatarImage,
          isPlayer: false,
          isRolePlayer: isRolePlayer(user),
        }));
        orgUsers = [...orgUsers, ...mappedUsers];
      }

      if (playersUnregistered?.length) {
        const mappedPlayers = selectedOrganizationData?.playersUnregistered!.map((player) => ({
          id: player.id,
          name: player.name,
          lastName: player.lastName,
          avatarImage: player.avatarImage,
          isPlayer: true,
          isRolePlayer: false,
        }));
        orgUsers = [...orgUsers, ...mappedPlayers];
      }

      setUsers(orgUsers);
    }
  }, [selectedOrganizationData, document]);

  useEffect(() => {
    if (document.ok && document.data) {
      dispatch<TypeActions>({
        type: ActionContentManagementType.ACTION_TAGS_PRIVATE_LOADING,
        value: { organizationId: stateAuth.userInfo.selectedOrganization, teamId: document.data.teamId },
      });
      setDocumentData(document.data);
      dispatch<TypeActions>({
        type: ActionContentManagementType.ACTION_CONTENT_MANAGEMENT_TAB_SELECTED,
        value: getTypeOfDocument(document.data?.type as string),
      });

      if (document.data?.type === "Video") {
        dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_LOADING, value: document.data.id as string });
      }

      if (document.data?.type === "Template" && document.data) {
        parseTemplateTeam(document.data.content);
      }

      if (document.data?.type === "Dashboard" && document.data) {
        parseDashboard(document.data.content);
      }

      if (document && (document.data?.type === "Project" || document.data?.type === "Playlist")) {
        dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_LOADING, value: document.data?.relatedVideoId as string });
      }

      const teamSelected = selectedOrganizationData?.teams.find((team) => team.id === document.data?.teamId);
      setIsStaffByTeam(isStaff(teamSelected as TeamsDto, stateAuth));
    }
    setLoading(document.loading);
  }, [document]);

  useEffect(() => {
    if (document.ok) {
      const { commentId } = params;
      if (commentId) {
        setSelecteTab(ContentManagementTabs.Comments);
        if (document.data?.type === "Project") {
          setSelecteTabValue(2);
          dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_CONTENT_MANAGEMENT_TAB_SELECTED, value: 5 });
        } else if (document.data?.type === "Playlist") {
          dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_CONTENT_MANAGEMENT_TAB_SELECTED, value: 2 });
          setSelecteTabValue(1);
        }
      }
    }
  }, [document, parentComment, stateContent.contentSelected]);

  useEffect(() => {
    if (addTagToFile.ok) {
      const doc = {
        ...(documentData as DocumentDto),
        tags: addTagToFile.data[0].tags,
      };
      setDocumentData(doc);
    }
  }, [addTagToFile.loading]);

  useEffect(() => {
    setCommentsLoading(comments.loading);
    if (comments.ok) {
      setCommentsData(comments.data);
      setParentComment(comments.parentComment);
    }
  }, [comments, parentComment]);

  useEffect(() => {
    if (deleteFiles.ok) {
      dispatch({ type: ActionContentManagementType.ACTION_DELETE_FILES_RESET });
      goBack();
    }
    setLoading(deleteFiles.loading);
  }, [deleteFiles]);

  useEffect(() => {
    setUrlLoading(documentUrl.loading);
  }, [documentUrl]);

  useEffect(() => {
    if (grantAccess.ok && selectedTab !== ContentManagementTabs.Playlist) {
      saveComment();
      setUsersGrantAccess([]);
      dispatch({ type: ActionContentManagementType.ACTION_GRANT_ACCESS_RESET });
    }
  }, [grantAccess]);

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    tabSelectedByDocumentType(newValue);
    setSelecteTabValue(newValue);
  };

  const goBack = () => {
    const { id } = params;
    dispatch({ type: ActionContentManagementType.ACTION_DOCUMENT_RESET });
    dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: [] });
    dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT });
    navigate("/" + ROUTE_PRIVATE_CONTENT_MANAGEMENT + "#" + id);
  };

  const selectedTag = (option: IdValue) => {
    const allTags = documentData!.tags.map((tag: TagsDto) => ({
      id: tag.id,
      value: tag.name,
      type: tag.type,
    }));
    const payload = { files: [documentData!.id], tags: [...allTags, option], teamId: documentData?.teamId as string };
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_LOADING, value: payload });
  };

  const removeTag = (tagId: string) => {
    const payload = { tagId: tagId, fileId: documentData!.id };
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_LOADING, value: payload });
  };

  const checkSaveComment = (text: string, parentCommentId = "") => {
    const payload = {
      text: text,
      relatedFileId: documentData!.id,
      parentCommentId: parentCommentId,
    };
    setSaveCommentData(payload);

    const getMentions = (comment: string) => {
      const mentions = [];
      for (let i = 0; i < comment.length; i++) {
        if (comment.charAt(i) === "@" && comment.charAt(i + 1) === "<") {
          const stopIndex = comment.indexOf(">", i);
          if (stopIndex !== -1) {
            mentions.push(comment.substring(i + 2, stopIndex));
          }
        }
      }
      return mentions;
    };
    const mentions = getMentions(text);
    if (!mentions.length) {
      saveComment(payload);
      return;
    }

    const mentionUsers: UserAccessDto[] = [];

    // if dashboard or template only grant acces to users, not players
    const filteredUsers = [ContentManagementType.Dashboard, ContentManagementType.Template, ContentManagementType.Project].includes(contentSelected)
      ? users.filter((user) => !user.isPlayer && !user.isRolePlayer)
      : users;
    filteredUsers?.forEach((user) => {
      if (mentions.includes(`${user.name} ${user.lastName}`)) {
        mentionUsers.push({
          mention: `${user.name} ${user.lastName}`,
          id: user?.id as string,
          name: user?.name as string,
          lastName: user?.lastName as string,
          isPlayer: user.isPlayer,
        });
      }
    });

    const notFoundUsers: UserAccessDto[] = [];
    mentionUsers.forEach((user: any) => {
      if (!documentData?.shareWithUsers?.includes(user.id) && !documentData?.shareWithPlayers?.includes(user.id) && !isStaffInThisTeam(user.id)) {
        notFoundUsers.push(user);
      }
    });
    if (notFoundUsers.length) {
      setUsersGrantAccess(notFoundUsers);
      setModalGrantAccess(true);
    } else {
      saveComment(payload);
    }
  };

  const dispatchGrantAccess = () => {
    setModalGrantAccess(false);
    const payload = {
      users: usersGrantAccess.filter((u) => !u.isPlayer).map((u) => u.id),
      players: usersGrantAccess.filter((u) => u.isPlayer).map((u) => u.id),
      files: [documentData!.id],
    };

    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GRANT_ACCESS_LOADING, value: payload });
  };

  const closeModalGrantAccess = () => {
    setModalGrantAccess(false);
    saveComment();
  };

  const saveComment = (data?: Partial<CommentDto>) => {
    if (!data && saveCommentData) {
      data = saveCommentData as Partial<CommentDto>;
    }
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_LOADING, value: data as Partial<CommentDto> });
    setSaveCommentData(null);
  };

  const likeComment = (commentId: string) => {
    const payload = { commentId: commentId, userId: loggedUser.id };
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_LOADING, value: payload });
  };

  const unlikeComment = (commentId: string) => {
    const payload = { commentId: commentId, userId: loggedUser.id };
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_LOADING, value: payload });
  };

  const askDeleteComment = (commentId: string, isParent: boolean) => {
    setCommentToDelete(commentId);
    setCommentToDeleteIsParent(isParent);
    setModalDeleteComent(true);
  };

  const deleteComments = () => {
    dispatch<TypeActions>({
      type: ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_LOADING,
      value: { commentId: commentToDelete, isParent: commentToDeleteIsParent },
    });

    if (commentToDeleteIsParent) {
      goToParentComment();
    }

    setModalDeleteComent(false);
    setCommentToDelete("");
    setCommentToDeleteIsParent(false);
  };

  const viewAnswers = (commentId: string) => {
    dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING, value: commentId });
  };

  const goToParentComment = () => {
    if (!parentComment?.parentCommentId) {
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_LOADING, value: documentData!.id });
    } else {
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING, value: parentComment.parentCommentId });
    }
  };

  const hasMasiveOptions = () => {
    return contentSelected !== ContentManagementType.Playlist;
  };

  const parseTemplateTeam = (content: string) => {
    try {
      setTemplateData(JSON.parse(content));
    } catch (e) {
      setTemplateData(null);
    }
  };

  const parseDashboard = (content: string) => {
    try {
      setDashboardData(JSON.parse(content));
    } catch (e) {
      setDashboardData(null);
    }
  };

  const viewPlayer = (player: TemplatePlayerDto) => {
    setSelectedPlayer(player);
    setModalViewPlayer(true);
  };

  const closeModalViewPlayer = () => {
    setModalViewPlayer(false);
    setSelectedPlayer(null);
  };

  const getCategory = () => {
    if (documentData && documentData.category) {
      return { id: documentData.category.id, value: documentData.category.name };
    }
  };

  const viewTimeLine = (timeline: Timeline) => {
    setClipSelected({
      id: timeline.$id,
      start: timeline.Start,
      stop: timeline.Stop,
      drawings: timeline.Drawings,
    });
  };

  const numUsersShared = useMemo(
    () =>
      documentData?.shareWithUsers.filter((userId: string) => selectedOrganizationData?.users?.find((userOrganization) => userOrganization.userId === userId))
        ?.length,
    [documentData, selectedOrganizationData]
  );

  return (
    <>
      {!stateContent.document.error && (
        <div className="flex flex-col xl:flex-row justify-between">
          {!loading && !urlLoading && documentData ? (
            <div className="xl:flex-1 py-8">
              <div className="flex flex-wrap justify-between items-center mb-5">
                <div className="flex items-center">
                  <LmIcon className="cursor-pointer" icon={<ChevronLeft />} size="sm" clickAction={() => goBack()} />
                  <span className="text-decorative font-decorative font-bold">{documentData?.fileName}</span>
                  {contentSelected === ContentManagementType.Template && templateData && (
                    <span className="text-decorative font-decorative ml-2">{t("content.players", { value: templateData?.List.length })}</span>
                  )}
                </div>

                {hasMasiveOptions() && <MasiveOptions isDetail={true}></MasiveOptions>}
              </div>
              <div className="xl:border xl:border-nuetral-200 xl:rounded">
                <div>
                  {contentSelected === ContentManagementType.Document && (
                    <div className="flex flex-col items-center gap-5 p-5 xl:p-8 bg-neutral-50">
                      <img className="flex-1" src={File}></img>
                      <span className="text-sm font-semibold text-decorative text-center">{documentData?.fileName}</span>
                    </div>
                  )}
                  {contentSelected === ContentManagementType.Video && (
                    <div id="container">
                      <div className="player-wrapper">
                        <ReactPlayer className="react-player" url={documentUrl.url} controls={true} width="100%" height="100%" />
                      </div>
                    </div>
                  )}
                  {contentSelected === ContentManagementType.Template && templateData && (
                    <div className="">
                      <LmPlayerList players={templateData?.List as TemplatePlayerDto[]} viewPlayer={viewPlayer}></LmPlayerList>
                    </div>
                  )}

                  {contentSelected === ContentManagementType.Dashboard && dashboardData && (
                    <LmDashboard dashboardData={dashboardData?.List as DashboardDataDto[]}></LmDashboard>
                  )}

                  {contentSelected === ContentManagementType.Project && (
                    <div className="player-wrapper">
                      <ProjectAnalysisReactPlayer video={documentUrl.url} clip={clipSelected} />
                    </div>
                  )}
                  {contentSelected === ContentManagementType.Playlist && (
                    <div id="container">
                      <div className="player-wrapper">
                        <ProjectAnalysisReactPlayerPlayList video={documentUrl.url} clip={clipSelected} />
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <div className="py-5 xl:px-6 border-y border-neutral-200">
                    <DocumentMetadata document={documentData} teams={selectedOrganizationData?.teams} users={selectedOrganizationData?.users ?? []} />
                    <div className="mt-4">{isStaffByTeam && documentData?.description && <div className="">{documentData?.description}</div>}</div>
                  </div>
                  <div className="py-5 xl:px-6">
                    {isStaffByTeam && (
                      <LmTags
                        id="tags"
                        value={documentData?.tags.map((tag: TagsDto) => ({
                          id: tag.id,
                          value: tag.name,
                          type: tag.type,
                        }))}
                        options={tagsOptions as IdValue[]}
                        selectedValue={(option: IdValue) => selectedTag(option)}
                        deleteValue={(option: IdValue) => removeTag(option.id)}
                      />
                    )}
                    {!isStaffByTeam && (
                      <div className="lm-autocomplete-selections mt-4">
                        {documentData?.tags
                          .filter((tag) => tag.type === "Public")
                          .map((tag: TagsDto) => (
                            <LmTag key={tag.id} label={tag.name} type={tag.type ?? ""}></LmTag>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loading />
          )}
          {sizeScreen == SizeScreen.DESKTOP && <Divider className="hidden xl:block" orientation="vertical" sx={{ margin: "0 2rem", height: "auto" }} />}
          <div className="xl:w-5/12 pb-5">
            <Tabs
              className="shadow-border"
              textColor="inherit"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              value={selectedTabValue}
              onChange={onChangeTab}>
              {stateContent.contentSelected === ContentManagementType.Playlist && (
                <Tab
                  sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }}
                  label={t("content.detailDocumentClips", { value: documentData?.clips?.length ?? 0 })}
                />
              )}
              {stateContent.contentSelected === ContentManagementType.Project && (
                <Tab sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }} label={t("content.detailDocumentAnalysis")} />
              )}
              {stateContent.contentSelected === ContentManagementType.Project && (
                <Tab sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }} label={t("content.detailDocumentZonalTags")} />
              )}
              <Tab
                sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }}
                label={t("content.detailDocumentMessagesTab", { value: comments?.data?.length })}
              />
              {isStaffByTeam && stateContent.contentSelected !== ContentManagementType.Playlist && (
                <Tab
                  sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }}
                  label={t("content.detailDocumentShareTab", { value: numUsersShared })}
                />
              )}
              {stateContent.contentSelected === ContentManagementType.Project && isStaffByTeam && (
                <Tab
                  sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }}
                  label={t("content.detailDocumentPlayList", { value: stateContent.playlistByProjectSelected?.data?.length ?? 0 })}
                />
              )}
            </Tabs>
            <div>
              {selectedTab === ContentManagementTabs.Clips && document && (
                <div>
                  {documentData?.clips.length ? (
                    documentData?.clips.map((clip) => (
                      <LmPlaylistItem key={clip.id} clip={clip} active={clipSelected.id === clip.id} viewTimeLine={viewTimeLine} />
                    ))
                  ) : (
                    <div>
                      <Empty text={t("content.emptyCategory")} />
                    </div>
                  )}
                </div>
              )}
              {selectedTab === ContentManagementTabs.Comments && document && (
                <div>
                  {!commentsData.length && !commentsLoading && !parentComment && !comments.error && <p className="mt-5">{t("content.noComments")}</p>}
                  {comments.error && <p className="mt-5">{t("content.errorComments")}</p>}
                  {!commentsLoading ? (
                    <LmCommentList
                      comments={commentsData}
                      parentComment={parentComment}
                      screenSize={sizeScreen}
                      users={users}
                      loggedUser={loggedUser}
                      saveComment={checkSaveComment}
                      likeComment={likeComment}
                      unlikeComment={unlikeComment}
                      deleteComment={askDeleteComment}
                      viewAnswers={viewAnswers}
                      goToParentComment={goToParentComment}></LmCommentList>
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
              {isStaffByTeam && selectedTab === ContentManagementTabs.Share && document && <DetailDocumentShare document={documentData as DocumentDto} />}
              {selectedTab === ContentManagementTabs.Analysis && document && <ProjectAnalysis isStaffByTeam={isStaffByTeam} viewTimeLine={viewTimeLine} />}
              {selectedTab === ContentManagementTabs.ZonalTags && document && <ProjectZonalTags viewTimeLine={viewTimeLine} />}
              {selectedTab === ContentManagementTabs.Playlist && <Playlists users={users} loggedUser={loggedUser} viewTimeLine={viewTimeLine} />}
            </div>
          </div>
        </div>
      )}
      {stateContent.document.error && (
        <div className="p-1">
          <Empty text={t("content.fileNotFound")} />
        </div>
      )}
      <LmModalConfirm
        title={t("content.messageConfirmDeleteCommentTitle")}
        description={t("content.messageConfirmDeleteCommentDescription")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={modalDeleteComent}
        confirm={deleteComments}
        handleClose={() => setModalDeleteComent(false)}></LmModalConfirm>

      <LmModalChangeFileCategory initialCategory={getCategory()} open={modalChangeCategory} handleClose={() => setModalChangeCategory(false)} />
      <LmModalGrantAccess open={modalGrantAccess} users={usersGrantAccess} handleClose={() => closeModalGrantAccess()} confirm={() => dispatchGrantAccess()} />
      <ModalTemplatePlayer open={modalViewPlayer} player={selectedPlayer as TemplatePlayerDto} handleClose={() => closeModalViewPlayer()} />
    </>
  );
}
