import { t } from "i18next";
import { useEffect, useState } from "react";
import { Dashboard, ProjectCategoryDto, ProjectDto, Ref, Timeline } from "../../model/dto/ProjectDto";
import Empty from "../empty/empty";
import MatchFieldViewer from "./MatchFieldViewer";

interface Props {
  statistic: ProjectDto;
  category: ProjectCategoryDto;
  dashboard: Dashboard;
  teamId: string;
  playerId: string;
}

const MatchStatisticCategoryViewer = ({ category, statistic, dashboard, teamId, playerId }: Props) => {
  const [timelines, setTimelines] = useState<Timeline[]>([]);

  useEffect(() => {
    if (teamId) {
      const cat = category.timelines.filter((t: Timeline) => t.Teams.length && t.Teams.map((team: Ref) => team.$ref).includes(teamId));
      setTimelines(cat);
    }

    if (playerId) {
      const cat = category.timelines.filter((t: Timeline) => t.Players.length && t.Players.map((player: Ref) => player.$ref).includes(playerId));
      setTimelines(cat);
    }
  }, [category]);

  const hasImageType = (position: "FieldPosition" | "HalfFieldPosition" | "GoalPosition") => {
    return timelines.some((t: Timeline) => t[position]);
  };

  return (
    <div className="p-4">
      <div className="grid md:grid-cols-3 items-start gap-4">
        {hasImageType("FieldPosition") && (
          <div className="md:col-span-2">
            <MatchFieldViewer background={dashboard.FieldBackground} timelines={timelines} statistic={statistic} imageType={"FieldPosition"}></MatchFieldViewer>
          </div>
        )}
        <div className="space-y-4">
          {hasImageType("HalfFieldPosition") && (
            <div>
              <MatchFieldViewer
                background={dashboard.HalfFieldBackground}
                timelines={timelines}
                statistic={statistic}
                imageType={"HalfFieldPosition"}></MatchFieldViewer>
            </div>
          )}
          {hasImageType("GoalPosition") && (
            <div>
              <MatchFieldViewer background={dashboard.GoalBackground} timelines={timelines} statistic={statistic} imageType={"GoalPosition"}></MatchFieldViewer>
            </div>
          )}
        </div>{" "}
      </div>
      {!hasImageType("FieldPosition") && !hasImageType("HalfFieldPosition") && !hasImageType("GoalPosition") && (
        <div className="w-full">
          <Empty text={t("content.noZonalTag")}></Empty>
        </div>
      )}
    </div>
  );
};

export default MatchStatisticCategoryViewer;
