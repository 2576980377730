import React from "react";
import Dialog from "@mui/material/Dialog";
import LmIcon from "../icon/LmIcon";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import ImgModalError from "../../assets/img/error-dialog.svg";
import { InitialState } from "../../model/states/InitialState";
import { MainState } from "../../model/states/MainState";
import { useSelector } from "react-redux";

interface IModalError {
  title: string;
  message: string;
  open: boolean;
  handleClose(): void;
}
const ModalError: React.FC<IModalError> = ({ title = "", message = "", open, handleClose }) => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth className={`theme-${stateMain.colorAplication}`}>
      <div className="p-5 md:p-8">
        <div className="flex justify-between items-center gap-2 mb-8">
          <p className="font-decorative text-decorative text-base md:text-2xl text-center w-full">{title}</p>
          <LmIcon className="cursor-pointer" icon={<Close />} size="md" clickAction={handleClose}></LmIcon>
        </div>

        <div className="flex justify-center mb-10">
          <img src={ImgModalError} />
        </div>
        <p className="text-sm text-center">{message}</p>
      </div>
    </Dialog>
  );
};
export default ModalError;
