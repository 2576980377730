import { Divider } from "@mui/material";
import { t } from "i18next";
import React, { ChangeEvent, useEffect, useState, useRef, KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Tag } from "../../assets/icons/tag.svg";
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import { IdValue } from "../../model/forms/IdValue";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { normalizeString } from "../../utils/utils";
import LmButton from "../button/LmButton";
import LmInput from "../input/LmInput";
import LmSelect from "../select/LmSelect";
import "./LmAutocomplete.scss";
import LmTag from "./LmTag";
import Popper from '@mui/material/Popper';
import { useClickAway } from 'react-use';

import "./LmTags.scss";
import Loading from "../loading/Loading";
import { MAX_LENGTH_TAG } from "../../constants/globalConstanst";
interface ISelect {
  id?: string;
  placeholder?: string;
  label?: string;
  autocomplete?: string;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  options: IdValue[];
  value?: IdValue[];
  privateTags?: boolean;
  prefix?: any;
  selectedValue(value: IdValue): void;
  deleteValue(value: IdValue): void;
  dataFiltered?(values: IdValue[]): void;
}

const LmTags: React.FC<ISelect> = ({
  id = "",
  autocomplete = "off",
  placeholder = "",
  label = "",
  disabled = false,
  error = false,
  required = false,
  options = [],
  value = [],
  prefix = null,
  privateTags = true,
  selectedValue = () => {},
  deleteValue = () => {},
  dataFiltered = () => {},
}) => {  
  const [optionsFiltered, setOptionsFiltered] = useState(options);
  const [valueDisplay, setValueDisplay] = useState("");
  const [typeTag, setTypeTag] = useState<IdValue | undefined | null>({ id: "Public", value: t("content.publicTag") });
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const refDropDown = useRef<HTMLDivElement>(null);
  const [closeOutInput, setCloseOutInput] = useState(false)
  const [closeOutPanel, setCloseOutPanel] = useState(false)

  useClickAway(refDropDown, () => {   
    setCloseOutPanel(true)
  });

  useClickAway(ref, () => {  
    setCloseOutInput(true)
  });

  useEffect(()=>{
    if (closeOutInput && closeOutPanel) {
      handleClose();
    }
  }, [closeOutInput, closeOutPanel])
  
  const handleClose = () => {   
    setAnchorEl(null);
  };

  const handleClick = () => {
    setAnchorEl(ref.current);
    setCloseOutInput(false)
    setCloseOutPanel(false)
  };

  const open = Boolean(anchorEl);  

  const optionsTypesTags = privateTags ? [
    { id: "Public", value: t("content.publicTag") },
    { id: "Staff", value: t("content.privateTag") },
  ] : [{ id: "Public", value: t("content.publicTag") }];

  const isSelected = (value: IdValue[], option: IdValue) => {
    if (!option.id) {
      return value.find((tags: IdValue) => tags.value === option.value);
    }
    return value.find((tags: IdValue) => tags.id === option.id);
  };

  useEffect(() => {
    setOptionsFiltered(options);
  }, [options]);

  const optionSelected = (option: IdValue) => {
   handleClose();
    if (!isSelected(value, option)) {
      selectedValue(option);
      setOptionsFiltered(options);
      setValueDisplay("");
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = normalizeString(e.target.value);
    if (inputValue.length <= MAX_LENGTH_TAG) {
      const valuesFiltered = options.filter((option) => normalizeString(option.value).includes(inputValue));
      setOptionsFiltered(valuesFiltered);
      setValueDisplay(e.target.value);
      dataFiltered(valuesFiltered);
    }    
  };

  const deleteSelection = (option: IdValue) => {
    deleteValue(option);
    setOptionsFiltered(options);
  };

  const addTag = () => {
    if (valueDisplay && valueDisplay.trim() !== '') {
      optionSelected({ id: "", value: valueDisplay, type: typeTag?.id });
    }
    
  };

  const keyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Tab') {
      handleClose()
    }
  }

  return (
    <div className="relative">
      <p className="text-sm font-semibold mb-1">{label}</p>     
      <div className="flex justify-end flex-wrap sm:flex-nowrap gap-2 sm:gap-6">
        <div className="tags flex w-full">
          <div
            className={`lm-autocomplete w-full ${disabled ? "lm-input--disabled" : ""} ${error ? "lm-input--error" : ""}`}            
            data-testid="test-lm-select" ref={ref}>
            <LmInput
              autoComplete={autocomplete}
              maxlength={MAX_LENGTH_TAG}              
              max={MAX_LENGTH_TAG}
              id={id}
              tags={true}
              value={valueDisplay}
              disabled={disabled}
              placeholder={placeholder}
              required={required}
              iconSufix={<Search />}
              prefix={prefix}
              focus={handleClick}
              keyDown={keyDown}               
              changeValue={(e) => onChange(e)}
            />
          </div>
          <Divider orientation="vertical" />
          <div className="w-36">
            <LmSelect
              id="selectTagcomponent"
              value={typeTag}
              tags={true}
              options={optionsTypesTags}
              selectedValue={(option: IdValue) => setTypeTag(option)}></LmSelect>
          </div>
        </div>
        <div className="min-w-fit">
          <LmButton
            styleButton="organization"
            type="solid"
            size="medium"
            text={`${t("content.addTag")}`}
            icon={<Tag />}
            iconPosition="center"
            isDisabled={stateContent.tags.loading}
            clickAction={addTag}
          />
        </div>
      </div>
      <Popper    
        id={id + "-" + "dropdown"}
        open={open}
        anchorEl={anchorEl}        
        placement="bottom"
        sx={{zIndex: '1400'}}        
      >      
        <div className="lm-drowdown-panel overflow-y max-h-[320px]" data-testid={id + "-" + "dropdown"} ref={refDropDown} style={{width: ref?.current?.offsetWidth, left:0, right:0}}>
          {optionsFiltered.length > 0 ? (
            optionsFiltered.map((option) => (
              <div
                key={option.id}
                className={`lm-option ${isSelected(value, option) ? "lm-option--disabled" : ""}`}
                onClick={() => optionSelected(option)}
                data-testid="select-option">
                {option.icon && (option.icon as SVGSVGElement)} <span>{option.value}</span>
              </div>
            ))
          ) : (
            <div className="p-4 flex justify-center">{t("autocomplete.notFound")}</div>
          )}
        </div>      
      </Popper>
      <div className="lm-autocomplete-selections mt-4">
        {!stateContent.addTagToFile.loading && value.map((option) => (
          <LmTag key={option.id} label={option.value} type={option.type || ""} icon={<Close />} onClickAction={() => deleteSelection(option)} />
        ))}
        {stateContent.addTagToFile.loading && <Loading />}
      </div>
      
    </div>
  );
};

export default LmTags;
