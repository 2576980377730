import { t } from "i18next";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  ActionAddClipToPlaylistLoading,
  ActionAddTagToDocumentLoading,
  ActionChangeCategoryLoading,
  ActionContentManagementType,
  ActionCreatePlaylistLoading,
  ActionDeleteClipsByIdLoading,
  ActionDeleteFileCommentsLoading,
  ActionDeleteFilesLoading,
  ActionDeletePlaylistLoading,
  ActionDocumentsLoading,
  ActionDownloadFilesLoading,
  ActionDownloadSingleFileLoading,
  ActionGetClipsByPlaylistIdLoading,
  ActionGetCommentRepliesLoading,
  ActionGetFileCommentsLoading,
  ActionGetLastCommentsLoading,
  ActionGetLastDocumentsLoading,
  ActionGetLastMentionsLoading,
  ActionGetLastProyectsLoading,
  ActionGetPlaylistByProjectLoading,
  ActionGetPlaylistDetailsLoading,
  ActionGrantAccessLoading,
  ActionLikeFileCommentsLoading,
  ActionLoadDocumentLoading,
  ActionLoadDocumentUrlLoading,
  ActionRemoveTagFromDocumentLoading,
  ActionSaveFileCommentsLoading,
  ActionShareFileLoading,
  ActionUnShareFileLoading,
  ActionUnlikeFileCommentsLoading,
  ActionUploadConfirmLoading,
  ActionUploadFileDataLoading,
  ActionUploadLinkLoading,
  TypeDocumentManagementActions,
} from "../../model/actions/typeContentManagementActions";
import { ActionMainType, TypeMainActions } from "../../model/actions/typeMainActions";
import { CommentDto } from "../../model/dto/CommentDto";
import DocumentDto, { TagsDto } from "../../model/dto/DocumentDto";
import ResponseApiError from "../../model/dto/ResponseApiError";
import { IdValue } from "../../model/forms/IdValue";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { getFetchParams } from "./utilsSaga";
import { Clip } from "../../model/dto/ProjectDto";
import { ContentManagementTabs } from "../../model/states/ContentManagementState";
import { UploadLinkDto } from "../../model/dto/FileDto";
const baseUrlApi = process.env.REACT_APP_API_URL + "Files";
const baseUrlApiTags = process.env.REACT_APP_API_URL + "Tags";
const baseUrlApiCommnents = process.env.REACT_APP_API_URL + "Comment";
const baseUrlApiLikes = process.env.REACT_APP_API_URL + "Likes";
const baseUrlApiVideoclips = process.env.REACT_APP_API_URL + "Videoclips";
const baseUrlApiClips = process.env.REACT_APP_API_URL + "Videoclips";

async function downloadLinks(links: string[]): Promise<boolean> {
    return new Promise((resolve, _) => {
        links.forEach((url) => {
            const name = url.substring(url.lastIndexOf("/") + 1);
            fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                const nameCoded = name.split("?")[0];
                a.download = decodeURIComponent(decodeURIComponent(nameCoded));
                document.body.appendChild(a);
                a.click();
                a.remove();
                resolve(true);
            });
        });
    });
}

export function* documents(action: ActionDocumentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/search?locale=" + state.appLanguage,
      getFetchParams("POST", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: DocumentDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DOCUMENTS_SUCCESS, value: { data: data, type: action.value.type } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DOCUMENTS_ERROR, value: { type: action.value.type } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* changeFileCategory(action: ActionChangeCategoryLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/changeCategory?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: DocumentDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_SUCCESS,
        value: { data: data, categoryId: action.value.categoryId },
      });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.messageChangeFileCategoryOk"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* uploadFileData(action: ActionUploadFileDataLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const notSavedTags = action.value.data.tags.filter((tag) => !tag.id);
    const savedTagIds = action.value.data.tags.filter((tag) => !!tag.id).map((tag) => tag.id);
    const newTags: TagsDto[] = yield call(saveTags, notSavedTags, action.value.data.teamId);
    const newTagIds: string[] = newTags.map((tag: TagsDto) => tag.id);
    yield put<TypeDocumentManagementActions>({
      type: ActionContentManagementType.ADD_TAGS_ORGANIZATION,
      value: newTags,
    });
    const fileValue = {
      ...action.value.data,
      tagsIds: [...newTagIds, ...savedTagIds],
    };

    const response: Response = yield call(
      fetch,
      baseUrlApi + "?locale=" + state.appLanguage,
      getFetchParams("POST", fileValue, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: DocumentDto = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_SUCCESS,
        value: { data: data, newCategoryName: action.value.data.newCategoryName },
      });
      if (action.value.data.type === "Document" || action.value.data.type === "Video") {
        yield put<TypeDocumentManagementActions>({
          type: ActionContentManagementType.ACTION_UPLOAD_LINK_LOADING,
          value: data.id,
        });
      }
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* uploadlink(action: ActionUploadLinkLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);

    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value + "/uploadlink",
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );

    if (response.ok) {
      const data: UploadLinkDto = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UPLOAD_LINK_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UPLOAD_LINK_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* uploadConfirm(action: ActionUploadConfirmLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);

    const response: Response = yield call(fetch, baseUrlApi + "/" + action.value + "/confirm", getFetchParams("PUT", null, state.login.loginResponse?.token));

    if (response.ok) {
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_SUCCESS });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.fileUploaded"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* addTagsToFile(action: ActionAddTagToDocumentLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const notSavedTags = action.value.tags.filter((tag) => !tag.id);
    const savedTagIds = action.value.tags.filter((tag) => !!tag.id).map((tag) => tag.id);
    const newTags: TagsDto[] = notSavedTags.length ? yield call(saveTags, notSavedTags, action.value.teamId) : [];
    const newTagIds: string[] = newTags.map((tag: TagsDto) => tag.id);
    yield put<TypeDocumentManagementActions>({
      type: ActionContentManagementType.ADD_TAGS_ORGANIZATION,
      value: newTags,
    });
    const fileValue = {
      ...action.value,
      tags: [...newTagIds, ...savedTagIds],
    };
    const response: Response = yield call(fetch, baseUrlApi + "/addTags", getFetchParams("PUT", fileValue, state.login.loginResponse?.token));
    if (response.ok) {
      const data: DocumentDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_SUCCESS,
        value: data,
      });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.messageAddTagToFileOk"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* downloadSingleFile(action: ActionDownloadSingleFileLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/" + action.value + "/download?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield call([response, "blob"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.messageDownloadSingleFileOk"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* downloadFiles(action: ActionDownloadFilesLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/request/download?id=" + action.value.data + "&locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield call(action.value.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* downloadLinksFiles(action: ActionDownloadFilesLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(fetch, baseUrlApi + "/links?id=" + action.value.data, getFetchParams("GET", null, state.login.loginResponse?.token));
    if (response.ok) {
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.messageDownloadSingleFileInfo"), severity: "info" } });
      const data: string[] = yield call([response, "json"]);
      const request: boolean = yield call(downloadLinks, data);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: request ? { message: t("content.messageDownloadSingleFileOk"), severity: "success" } : { message: t("errors.server"), severity: "error" }});
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteFiles(action: ActionDeleteFilesLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + action.value.data + "&locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield call(action.value.callBack);
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deletePlaylist(action: ActionDeletePlaylistLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + action.value.url + "&locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DELETE_PLAY_LIST_SUCCESS, value: action.value.idPlaylist });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* shareFiles(action: ActionShareFileLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/shareFile?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: DocumentDto[] = yield call([response, "json"]);
      if (data[0].type === ContentManagementTabs.Playlist) {
        yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_SUCCESS, value: data[0] });
      }
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_SHARE_FILE_SUCCESS, value: data });

      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.messageShareFileOk"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_SHARE_FILE_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_SHARE_FILE_ERROR });
  }
}

export function* unShareFiles(action: ActionUnShareFileLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/unShareFile?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: DocumentDto[] = yield call([response, "json"]);
      if (data[0].type === ContentManagementTabs.Playlist) {
        yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_SUCCESS, value: data[0] });
      }
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UNSHARE_FILE_SUCCESS, value: data });

      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.messageUnShareFileOk"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UNSHARE_FILE_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UNSHARE_FILE_ERROR });
  }
}

export function* getDocumentDetail(action: ActionLoadDocumentLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApi}/${action.value}`;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, state.login.loginResponse?.token));
    if (response.ok) {
      const data: DocumentDto = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getPlaylistDetails(action: ActionGetPlaylistDetailsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApi}/${action.value}`;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, state.login.loginResponse?.token));
    if (response.ok) {
      const data: DocumentDto = yield call([response, "json"]);

      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_ERROR });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getVideoUrl(action: ActionLoadDocumentUrlLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApi}/${action.value}/link`;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, state.login.loginResponse?.token));
    if (response.ok) {
      const data: string = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* removeTag(action: ActionRemoveTagFromDocumentLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApi}/removeTags`;
    const payload = { files: [action.value.fileId], tags: [action.value.tagId] };
    const response: Response = yield call(fetch, url, getFetchParams("PUT", payload, state.login.loginResponse?.token));
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_SUCCESS, value: action.value });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getDocumentComments(action: ActionGetFileCommentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApiCommnents}/byFile/${action.value}`;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, state.login.loginResponse?.token));
    if (response.ok) {
      const comments: CommentDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_SUCCESS,
        value: comments,
      });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getCommentReplies(action: ActionGetCommentRepliesLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApiCommnents}/byComment/${action.value}`;
    const { token } = state.login.loginResponse!;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, token));
    if (response.ok) {
      const replies: CommentDto[] = yield call([response, "json"]);
      const commentData: CommentDto = yield call(getCommentData, action.value, token as string);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_SUCCESS,
        value: {
          replies: replies,
          parentComment: commentData,
        },
      });
    } else {
      // const data: ResponseApiError = yield call([response, "json"]);
      // yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

function* getCommentData(commentId: string, token: string) {
  const url = `${baseUrlApiCommnents}/${commentId}`;
  const response: Response = yield call(fetch, url, getFetchParams("GET", null, token));
  if (response.ok) {
    const comment: CommentDto = yield call([response, "json"]);
    return comment;
  }
  return null;
}

export function* saveDocumentComments(action: ActionSaveFileCommentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const payload = { ...action.value, organizationId: state.userInfo.selectedOrganization };
    const response: Response = yield call(fetch, baseUrlApiCommnents, getFetchParams("POST", payload, state.login.loginResponse?.token));
    if (response.ok) {
      const comment: CommentDto = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_SUCCESS,
        value: { savedComment: comment, parentId: action.value.parentCommentId },
      });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteDocumentComments(action: ActionDeleteFileCommentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApiCommnents}/${action.value.commentId}`;
    const response: Response = yield call(fetch, url, getFetchParams("DELETE", null, state.login.loginResponse?.token));
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_SUCCESS,
        value: { commentId: action.value.commentId, isParent: action.value.isParent },
      });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* likeDocumentComments(action: ActionLikeFileCommentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApiLikes,
      getFetchParams("POST", { commentId: action.value.commentId }, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_SUCCESS, value: action.value });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* unlikeDocumentComments(action: ActionUnlikeFileCommentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApiLikes}/byComment/${action.value.commentId}`;
    const response: Response = yield call(fetch, url, getFetchParams("DELETE", null, state.login.loginResponse?.token));
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_SUCCESS, value: action.value });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getLastMentions(action: ActionGetLastMentionsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApiCommnents}/byOrganization/${action.value.organizationId}/lastByMentions/${action.value.top}?locale=${state.appLanguage}`;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, state.login.loginResponse?.token));

    if (response.ok) {
      const comments: CommentDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_MENTIONS_SUCCESS, value: comments });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_MENTIONS_ERROR });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_MENTIONS_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getLastComments(action: ActionGetLastCommentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const url = `${baseUrlApiCommnents}/byOrganization/${action.value.organizationId}/last/${action.value.top}?locale=${state.appLanguage}`;
    const response: Response = yield call(fetch, url, getFetchParams("GET", null, state.login.loginResponse?.token));

    if (response.ok) {
      const comments: CommentDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_COMMENTS_SUCCESS, value: comments });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_COMMENTS_ERROR });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_COMMENTS_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getLastDocuments(action: ActionGetLastDocumentsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const documentTypes = action.value.types;
    let errorMessage: any = null;
    const responses: Response[] = yield all(
      documentTypes.map((type) => {
        return call(
          fetch,
          baseUrlApi + "/search?locale=" + state.appLanguage,
          getFetchParams("POST", { organizationId: action.value.organizationId, top: action.value.top, type }, state.login.loginResponse?.token)
        );
      })
    );

    const allDocuments: DocumentDto[] = yield Promise.all(
      responses.map((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          errorMessage = res.json();
        }
      })
    );

    if (errorMessage) {
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: errorMessage.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_ERROR });
    } else {
      let flattenedArray: DocumentDto[] = [];
      flattenedArray = flattenedArray.concat(...allDocuments);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_SUCCESS, value: flattenedArray });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getLastProjects(action: ActionGetLastProyectsLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const documentTypes = action.value.types;
    let errorMessage: any = null;
    const responses: Response[] = yield all(
      documentTypes.map((type) => {
        return call(
          fetch,
          baseUrlApi + "/search?locale=" + state.appLanguage,
          getFetchParams("POST", { organizationId: action.value.organizationId, top: action.value.top, type }, state.login.loginResponse?.token)
        );
      })
    );

    const allDocuments: DocumentDto[] = yield Promise.all(
      responses.map((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          errorMessage = res.json();
        }
      })
    );

    if (errorMessage) {
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: errorMessage.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_PROJECTS_ERROR });
    } else {
      let flattenedArray: DocumentDto[] = [];
      flattenedArray = flattenedArray.concat(...allDocuments);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_PROJECTS_SUCCESS, value: flattenedArray });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_LAST_PROJECTS_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

function* saveTags(tags: IdValue[], teamId?: string) {
  const state: AuthState = yield select((state: MainState) => state.auth);
  const responses: Response[] = yield all(
    tags.map((tag) => {
      const tagPayload = {
        organizationId: state.userInfo.selectedOrganization,
        name: tag.value,
        type: tag.type,
        teamId: teamId ? teamId : null,
      };
      return call(fetch, baseUrlApiTags, getFetchParams("POST", tagPayload, state.login.loginResponse?.token));
    })
  );
  const savedTags: TagsDto[] = yield Promise.all(responses.map((res: Response) => res.json()));
  return savedTags;
}

export function* grantUserAccess(action: ActionGrantAccessLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/shareFile?locale=" + state.appLanguage,
      getFetchParams("PUT", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GRANT_ACCESS_SUCCESS, value: action.value.users });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GRANT_ACCESS_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getPlaylistByProject(action: ActionGetPlaylistByProjectLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "/playlists/byprojectid/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: DocumentDto[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_SUCCESS, value: data });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_ERROR });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* getClipsByPlaylistId(action: ActionGetClipsByPlaylistIdLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApiClips + "/byplaylist/" + action.value + "?locale=" + state.appLanguage,
      getFetchParams("GET", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: Clip[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_SUCCESS,
        value: { playlistId: action.value, clips: data },
      });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_ERROR, value: action.value });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_ERROR, value: action.value });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* deleteClipById(action: ActionDeleteClipsByIdLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApiClips + "/" + action.value.clipId + "?locale=" + state.appLanguage,
      getFetchParams("DELETE", null, state.login.loginResponse?.token)
    );
    if (response.ok) {
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_SUCCESS,
        value: { clipId: action.value.clipId, playlistId: action.value.playlistId },
      });
      if (action.value.lastClip) {
        const params = `?organizationId=${state.userInfo.selectedOrganization}&id=${action.value.playlistId}`;
        yield put<TypeDocumentManagementActions>({
          type: ActionContentManagementType.ACTION_DELETE_PLAY_LIST_LOADING,
          value: { url: params, idPlaylist: action.value.playlistId },
        });
      }
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_ERROR });
    }
  } catch (e) {
    yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_ERROR });
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* createPlayList(action: ActionCreatePlaylistLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApi + "?locale=" + state.appLanguage,
      getFetchParams("POST", action.value.file, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: DocumentDto = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_CREATE_PLAYLIST_SUCCESS,
        value: data,
      });
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_LOADING,
        value: { ...action.value.videoclip, relatedFileId: data.id },
      });
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_SHARE_FILE_LOADING,
        value: {
          users: action.value.file.shareWithUsers,
          groups: action.value.file.shareWithGroups,
          players: action.value.file.shareWithPlayers,
          comment: action.value.file.comment,
          files: [data.id],
        },
      });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_CREATE_PLAYLIST_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* addClipsToPlayList(action: ActionAddClipToPlaylistLoading) {
  try {
    const state: AuthState = yield select((state: MainState) => state.auth);
    const response: Response = yield call(
      fetch,
      baseUrlApiVideoclips + "?locale=" + state.appLanguage,
      getFetchParams("POST", action.value, state.login.loginResponse?.token)
    );
    if (response.ok) {
      const data: any[] = yield call([response, "json"]);
      yield put<TypeDocumentManagementActions>({
        type: ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_SUCCESS,
        value: data,
      });
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("content.addPlaylistSuccess"), severity: "success" } });
    } else {
      const data: ResponseApiError = yield call([response, "json"]);
      yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: data.Message, severity: "error" } });
      yield put<TypeDocumentManagementActions>({ type: ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_ERROR });
    }
  } catch (e) {
    yield put<TypeMainActions>({ type: ActionMainType.ALERT_OPEN, value: { message: t("errors.server"), severity: "error" } });
  }
}

export function* documentsWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DOCUMENTS_LOADING, documents);
}

export function* changeFileCategoryWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_LOADING, changeFileCategory);
}

export function* uploadFileDataWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_LOADING, uploadFileData);
}

export function* addTagsToFileWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_LOADING, addTagsToFile);
}

export function* downloadSingleFileWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DOWNLOAD_SINGLE_FILE_LOADING, downloadSingleFile);
}

export function* downloadFilesWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DOWNLOAD_FILES_LOADING, downloadLinksFiles);
}

export function* deleteFilesWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DELETE_FILES_LOADING, deleteFiles);
}

export function* shareFilesWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_SHARE_FILE_LOADING, shareFiles);
}

export function* unShareFilesWhatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_UNSHARE_FILE_LOADING, unShareFiles);
}

export function* documentDataWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_LOAD_DOCUMENT_LOADING, getDocumentDetail);
}

export function* getPlaylistDetailsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_LOADING, getPlaylistDetails);
}

export function* documentUrlDataWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_LOADING, getVideoUrl);
}

export function* removeTagWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_LOADING, removeTag);
}

export function* getCommentsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_FILE_COMMENTS_LOADING, getDocumentComments);
}

export function* getLastCommentsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_LAST_COMMENTS_LOADING, getLastComments);
}

export function* getLastMentionsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_LAST_MENTIONS_LOADING, getLastMentions);
}

export function* saveCommentsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_LOADING, saveDocumentComments);
}

export function* deleteCommentsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_LOADING, deleteDocumentComments);
}

export function* likeCommentsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_LOADING, likeDocumentComments);
}

export function* unlikeCommentsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_LOADING, unlikeDocumentComments);
}

export function* getLastDocumentsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_LOADING, getLastDocuments);
}

export function* getLastProjectsWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_LAST_PROJECTS_LOADING, getLastProjects);
}
export function* getCommentRepliesWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING, getCommentReplies);
}

export function* grantUserAccessWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GRANT_ACCESS_LOADING, grantUserAccess);
}

export function* getPlaylistByProjectWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_LOADING, getPlaylistByProject);
}

export function* createPlaylistWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_CREATE_PLAYLIST_LOADING, createPlayList);
}

export function* addClipToPlaylistWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_LOADING, addClipsToPlayList);
}

export function* getClipsByPlaylistIdWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_LOADING, getClipsByPlaylistId);
}

export function* deleteClipByIdWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_LOADING, deleteClipById);
}

export function* uploadlinkWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_UPLOAD_LINK_LOADING, uploadlink);
}

export function* uploadConfirmWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_LOADING, uploadConfirm);
}

export function* deletePlaylistWatcher() {
  yield takeLatest(ActionContentManagementType.ACTION_DELETE_PLAY_LIST_LOADING, deletePlaylist);
}

export default function* rootContentManagementSaga() {
  yield all([
    documentsWhatcher(),
    changeFileCategoryWhatcher(),
    uploadFileDataWhatcher(),
    addTagsToFileWhatcher(),
    downloadSingleFileWhatcher(),
    downloadFilesWhatcher(),
    deleteFilesWhatcher(),
    shareFilesWhatcher(),
    unShareFilesWhatcher(),
    documentDataWatcher(),
    documentUrlDataWatcher(),
    removeTagWatcher(),
    getCommentsWatcher(),
    saveCommentsWatcher(),
    deleteCommentsWatcher(),
    likeCommentsWatcher(),
    unlikeCommentsWatcher(),
    getLastCommentsWatcher(),
    getLastDocumentsWatcher(),
    getLastProjectsWatcher(),
    getLastMentionsWatcher(),
    getCommentRepliesWatcher(),
    grantUserAccessWatcher(),
    getPlaylistByProjectWatcher(),
    createPlaylistWatcher(),
    addClipToPlaylistWatcher(),
    getClipsByPlaylistIdWatcher(),
    deleteClipByIdWatcher(),
    getPlaylistDetailsWatcher(),
    uploadlinkWatcher(),
    uploadConfirmWatcher(),
    deletePlaylistWatcher(),
  ]);
}
