import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LmAvatarLabelInfo from "../../components/avatar-label-info/LmAvatarLabelInfo";
import { TypeActions } from "../../model/actions/typeActions";
import { MainState } from "../../model/states/MainState";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import StorageBar from "../../components/storage-bar/StorageBar";
import { InitialState } from "../../model/states/InitialState";
import { SizeScreen } from "../../model/SizeSreen";
import { useTranslation } from "react-i18next";
import { AuthState } from "../../model/states/AuthState";
import { formatLocaleDate, getAllRoleOptions, getLocaleByUserOrganization, getRoleString, getSelectedDataById, isOwner } from "../../utils/utils";
import OrganizationDto from "../../model/dto/OrganizationDto";
import LmModalConfirm from "../../components/modal-confirm/LmModalConfirm";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import TeamsDto from "../../model/dto/TeamDto";
import { OrganizationState } from "../../model/states/OrganizationState";
import Loading from "../../components/loading/Loading";
import { PendingInvitationsUserDto } from "../../model/dto/PendingInvitationsUserDto";
import StorageBarMultiple from "../../components/storage-bar/StorageBarMultiple";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import LmAvatar from "../../components/avatar/LmAvatar";
import { ReactComponent as InputError } from "../../assets/icons/input-error.svg";
import LmIcon from "../../components/icon/LmIcon";
import { ActionAuthType } from "../../model/actions/typeAuthActions";

const OrganizationSumary = () => {
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const { t } = useTranslation();
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const dispatch = useDispatch();
  const [openModalDeleteTeam, setOpenModalDeleteTeam] = useState(false);
  const [teamSelected, setTeamSelected] = useState<TeamsDto>();
  const rolesType = getAllRoleOptions();

  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  useEffect(() => {
    if (isOwner(stateAuth)) {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_LOADING, value: selectedOrganizationData?.id as string });
    }
  }, []);

  const teamsLength = useMemo(() => selectedOrganizationData?.teams?.length, [selectedOrganizationData]);

  const getRolesUser = (userId: string): string => {
    const rolesTeams = selectedOrganizationData?.teams?.map((team) => team.staff?.find((staff) => staff.userId === userId)).filter(Boolean);
    let roles: string[] = [];
    if (rolesTeams && rolesTeams.length > 0) {
      const allRolesByTeam = rolesTeams.map((rolTeam) => rolTeam?.roles || []).flat();
      roles = allRolesByTeam.filter((item, index) => allRolesByTeam.indexOf(item) === index);
    }

    const result = roles.map((role) => getRoleString(role));
    return result.join(", ");
  };

  const numRoles = (roleType: string): number => {
    let count = 0;
    const rolesByUserId = selectedOrganizationData?.teams.reduce((acc, curr) => {
      curr.staff &&
        curr.staff.forEach((staffMember) => {
          const { userId, roles } = staffMember;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!acc[userId]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            acc[userId] = [];
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc[userId] = [...new Set([...acc[userId], ...roles])];
        });
      return acc;
    }, {});

    Object.entries(rolesByUserId)
      .map((item) => item[1])
      .flat()
      .map((item) => {
        item === roleType ? count++ : count;
      });

    return count;
  };

  const numRolesType = rolesType.map((role) => {
    return {
      ...role,
      num: numRoles(role.id),
    };
  });

  const deleteTeam = (team: any) => {
    setOpenModalDeleteTeam(true);
    setTeamSelected(team);
  };

  const onDeleteTeam = () => {
    setOpenModalDeleteTeam(false);
    dispatch<TypeActions>({ type: ActionTeamType.ACTION_DELETE_TEAM_LOADING, value: teamSelected as TeamsDto });
  };

  const getPendingInvitationInfo = (pendingInvitation: PendingInvitationsUserDto): string => {
    const result = pendingInvitation.teams.map((team) => team?.team?.name).join(", ");
    return result;
  };

  const storageComsumed = useMemo(()=>selectedOrganizationData?.teams.reduce((accumulator, currentValue) => accumulator + (currentValue?.consumedStorage || 0), 0), [selectedOrganizationData])
  const comsumedPercent = useMemo(()=> storageComsumed * 100 / (selectedOrganizationData?.license?.storage || 1), [selectedOrganizationData])
  const dateLocale = useMemo(()=>getLocaleByUserOrganization(stateAuth.userInfo.data.user.id, selectedOrganizationData?.users) === 'en' ? 'en-US' : 'es-ES', [stateAuth.userInfo.selectedOrganization])

  const resendPendingInvitation = (id: string) => {
    dispatch({type: ActionAuthType.RESEND_PENDING_INVITATION_LOADING, value: id})
  }

  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap justify-between px-5 lg:px-8">
        <div className="w-full lg:max-w-xl py-5 lg:py-8 lg:pr-8">
          {stateMain.sizeScreen === SizeScreen.DESKTOP && (
            <div className="flex items-center gap-4 pb-4">
              <LmAvatar src={selectedOrganizationData?.image} size={64} radius="0.5rem" />
              <p className="font-decorative text-decorative">{selectedOrganizationData?.name}</p>
            </div>
          )}
          <div className="py-4 border-b md:border-0 border-neutral-200">
            <p className="font-decorative text-decorative text-sm mb-2 md:mb-0">
              {t("summary.teams")} ({teamsLength})
            </p>
            {!stateOrganization.organizationSumary.loading &&
              selectedOrganizationData?.teams?.map((team) => (
                <div className="py-2 md:py-4 md:border-b border-neutral-200" key={team.id}>
                  <LmAvatarLabelInfo
                    src={team.image}
                    label={team.name}
                    info={`${team.players?.length || 0} ${t("summary.playersTab")} · ${team.staff?.length || 0} ${t("summary.staff")}`}
                    infoMargin="md:ml-4"
                    icon={<Trash />}
                    onClickAction={() => deleteTeam(team)}></LmAvatarLabelInfo>
                </div>
              ))}
            {stateOrganization.organizationSumary.loading && <Loading />}
          </div>
          <div className="space-y-5 py-4 border-b lg:border-0 border-neutral-200">
            <p className="font-decorative text-decorative text-sm">{t("summary.plan")}</p>
            <p className="text-subtle flex gap-2">
              <span className="text-decorative font-semibold">
                {t("summary.license")} {selectedOrganizationData?.license?.type?.code}
              </span>
              <span>
                {t("summary.expireDate")} {formatLocaleDate(selectedOrganizationData?.license?.validUntil as string, dateLocale)}
              </span>
            </p>
          </div>
          <div className="space-y-5 py-4 border-b lg:border-0 border-neutral-200">
            <p className="font-decorative text-decorative text-sm flex gap-1">
              {t("summary.storage")}
              {comsumedPercent > 95 && <LmIcon icon={<InputError />} tooltip={t('summary.maxStorage') as string} size="xs" />}
            </p>            
            <StorageBarMultiple              
              maxCapacity={selectedOrganizationData?.license?.storage as number}
              data = {selectedOrganizationData?.teams.map(team => {return {name: team.name, value: team?.consumedStorage as number}})}
            />
          </div>
          <div className="space-y-5 py-4 border-b lg:border-0 border-neutral-200">
            <p className="font-decorative text-decorative text-sm">{t("summary.users")}</p>
            <StorageBar
              measure={t("summary.users")}
              maxCapacity={selectedOrganizationData?.license?.maxNumberOfUsers || 0}
              currentCapacity={selectedOrganizationData?.users?.length || 0}
              labelUsed={t("storage.used")}
            />
            <div className="space-y-4">
              {!stateOrganization.organizationSumary.loading &&
                numRolesType.map((rolType) => (
                  <LmAvatarLabelInfo
                    key={rolType.id}
                    src={rolType.image}
                    label={rolType.value}
                    info={`${rolType.num} ${t("summary.users")}`}></LmAvatarLabelInfo>
                ))}
              {stateOrganization.organizationSumary.loading && <Loading />}
            </div>
          </div>
        </div>
        <div className="w-full lg:max-w-sm pb-5 lg:py-8 lg:pl-8 space-y-5 border-0 lg:border-l border-neutral-200">
          <p className="font-decorative text-decorative text-sm">{t("summary.members")}</p>
          {!stateOrganization.organizationSumary.loading &&
            selectedOrganizationData?.users?.map((user) => (
              <LmAvatarLabelInfo
                key={user.userId}
                src={user.avatarImage}
                radius="100%"
                label={`${user?.user?.name}
          ${user?.user?.lastName}`}
                divider="·"
                info={getRolesUser(user.userId)}></LmAvatarLabelInfo>
            ))}
          {stateOrganization.organizationSumary.loading && <Loading />}
          <p className="font-decorative text-decorative text-sm">{t("summary.invitations")}</p>
          {!stateOrganization.organizationSumary.loading &&
            selectedOrganizationData?.pendingInvitations?.map((user: PendingInvitationsUserDto) => (<div key={user.id} className="flex justify-between">
              <LmAvatarLabelInfo
                key={user.id}
                src=""
                radius="100%"
                label={`${user?.userName}
                ${user?.userLastName}`}
                divider="·"
                info={getPendingInvitationInfo(user)}></LmAvatarLabelInfo>
                <p className="cursor-pointer italic text-xs" onClick={()=>resendPendingInvitation(user.id)}>
                  {!stateAuth.resendPendingInvitation.loading ? t('users.resendPendingInvitation') : <Loading />}
                </p>
              </div>
            ))}
          {stateOrganization.organizationSumary.loading && <Loading />}
        </div>
      </div>
      <LmModalConfirm
        id="test-modal-confirm-remove-team"
        title={t("teams.deleteTeamTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalDeleteTeam}
        handleClose={() => setOpenModalDeleteTeam(false)}
        confirm={onDeleteTeam}></LmModalConfirm>
    </>
  );
};

export default OrganizationSumary;
