import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../components/empty/empty";
import LmModalConfirm from "../../components/modal-confirm/LmModalConfirm";
import LmModalGroup from "../../components/modal-group/LmModalGroup";
import TeamInfoItem from "../../components/team-info-item/TeamInfoItem";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionTeamType } from "../../model/actions/typeTeamActions";
import TeamsDto from "../../model/dto/TeamDto";
import TeamGroupResultDto from "../../model/dto/TeamGroupResultDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { ViewMode } from "../../model/ViewMode";
import { isCoachOrHeadCoach } from "../../utils/utils";
interface Props {
  groups: TeamGroupResultDto[] | undefined;
  team: TeamsDto;
}

const GroupTab = ({ groups, team }: Props) => {
  const { t } = useTranslation();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalGroup, setOpenModalGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<TeamGroupResultDto>();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);

  const dispatch = useDispatch();

  const handleDeleteGroup = (group: TeamGroupResultDto) => {
    setSelectedGroup(group);
    setOpenModalConfirm(true);
  };

  const onDeleteGroup = () => {
    if (selectedGroup) {
      dispatch<TypeActions>({ type: ActionTeamType.ACTION_DELETE_TEAM_GROUP_LOADING, value: selectedGroup });
      setOpenModalConfirm(false);
    }
  };

  const handleEditGroup = (group: TeamGroupResultDto) => {
    setSelectedGroup(group);
    setOpenModalGroup(true);
  };

  return (
    <>
      {groups && groups.length > 0 ? (
        groups.map((group: TeamGroupResultDto) => (
          <div className="border border-neutral-200 rounded p-4" key={group.id}>
            <TeamInfoItem
              titleText={<div>{group.name}</div>}
              subtitleText={`${group.players.length} ${t("group.players")}`}
              actionEdit={isCoachOrHeadCoach(team, stateAuth) ? () => handleEditGroup(group) : undefined}
              actionDelete={isCoachOrHeadCoach(team, stateAuth) ? () => handleDeleteGroup(group) : undefined}
            />
          </div>
        ))
      ) : (
        <div className="col-span-full row-span-full">
          <Empty text={t("group.emptySection")} />
        </div>
      )}
      <LmModalConfirm
        id="test-modal-confirm-remove-group"
        title={t("teams.deleteGroupTitle")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={openModalConfirm}
        handleClose={() => setOpenModalConfirm(false)}
        confirm={onDeleteGroup}></LmModalConfirm>
      <LmModalGroup open={openModalGroup} handleClose={() => setOpenModalGroup(false)} viewMode={ViewMode.EDIT} group={selectedGroup} />
    </>
  );
};

export default GroupTab;
