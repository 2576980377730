import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { UserAccessDto } from "../../model/dto/CommentDto";
import LmButton from "../button/LmButton";
import { InitialState } from "../../model/states/InitialState";
import { useSelector } from "react-redux";
import { MainState } from "../../model/states/MainState";

interface Props {
    users: UserAccessDto[];
    open: boolean;
    confirm(): void;
    handleClose(): void;
}
  
const LmModalGrantAccess = ({ users, open, confirm, handleClose }: Props) => {
    const stateMain: InitialState = useSelector((state: MainState) => state.main);

    const { t } = useTranslation();

    const onClose = () => {
        handleClose();
    };

    return(
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className={`theme-${stateMain.colorAplication}`}>
            <div className="p-5 md:p-8">
                <div className="flex justify-between items-center mb-8">
                    <p className="font-decorative text-decorative text-base md:text-2xl"> {t("content.modalGrantAccessTitle")}</p>
                    <Close className="cursor-pointer" onClick={onClose} data-testid="button-close-change-category" />
                </div>
                <div className="mb-3">
                    <p>{t("content.mentionAccessLine1")}<b>{ users.map(u => u.mention).join(", ") }</b></p>
                    <p>{t("content.mentionAccessLine2")}</p>
                </div>
            </div>
            <div className="flex gap-2 justify-end flex-wrap mb-4 mr-4 ml-4">
                <LmButton
                    styleButton="secondary"
                    type="solid"
                    clickAction={onClose}
                    size="small"
                    text={`${t("buttonLabel.cancel")}`}
                    iconPosition="right"></LmButton>
                <LmButton
                    styleButton="organization"
                    clickAction={confirm}
                    type="solid"
                    size="small"
                    text={t("buttonLabel.accept")}
                    iconPosition="right"></LmButton>
          </div>
        </Dialog>
    )
}

export default LmModalGrantAccess;