import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { TypeActions } from "../model/actions/typeActions";
import { ActionAuthType } from "../model/actions/typeAuthActions";
import { ActionContentManagementType } from "../model/actions/typeContentManagementActions";
import { ActionMainType } from "../model/actions/typeMainActions";
import { ActionOrganizationType } from "../model/actions/typeOrganizationActions";
import { IdValue } from "../model/forms/IdValue";
import { AuthState } from "../model/states/AuthState";
import { ContentManagementState, ContentManagementType } from "../model/states/ContentManagementState";
import { MainState } from "../model/states/MainState";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT, ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL, ROUTE_PRIVATE_TEAMS } from "../routes/routes";
import { getSelectedDataById, isOwner } from "../utils/utils";

const useOrganizationSelector = () => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedOrganizationData = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  useEffect(() => {
    onReloadData(selectedOrganizationData?.id, false);
  }, [stateAuth.appLanguage]);

  const onChangeOrganization = (organization: IdValue): void => {
    const organizationSelected = stateAuth.userInfo.data.organizations.find((org) => org.id === organization.id);
    if (organizationSelected) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_STATE_SELECT_ORGANIZATION, value: organizationSelected });
      dispatch<TypeActions>({ type: ActionMainType.ACTION_CHANGE_COLOR_APLICATION, value: organizationSelected.color });
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHANGE_USER_ORGANIZATION_ROLE, value: organizationSelected });
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_TEAM_TAB_SELECTED, value: 0 });
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_CHANGE_APP_USER_LANGUAGE, value: organizationSelected });
      onReloadData(organizationSelected?.id ?? "", true);
    }
  };

  const onReloadData = (organizationId: string, changeOrganization: boolean) => {
    if (stateAuth.actionMenuSelected === ROUTE_PRIVATE_TEAMS && isOwner(stateAuth, organizationId)) {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_LOADING, value: organizationId as string });
    } else if (stateAuth.actionMenuSelected === ROUTE_PRIVATE_CONTENT_MANAGEMENT) {
      dispatch<TypeActions>({
        type: ActionContentManagementType.ACTION_DOCUMENTS_LOADING,
        value: { organizationId: organizationId as string, type: ContentManagementType[stateContent.contentSelected] },
      });
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_CATEGORIES_LOADING, value: organizationId as string });
      dispatch<TypeActions>({ type: ActionContentManagementType.ACTION_TAGS_LOADING, value: { organizationId: organizationId as string } });
    } else if (stateAuth.actionMenuSelected === ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL && changeOrganization) {
      dispatch<TypeActions>({
        type: ActionContentManagementType.ACTION_DOCUMENTS_LOADING,
        value: { organizationId: organizationId as string, type: ContentManagementType[stateContent.contentSelected] },
      });
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT });
      navigate("/" + ROUTE_PRIVATE_CONTENT_MANAGEMENT);
    }
  };

  const generateOrganizacioOptions = () =>
    stateAuth.userInfo.data.organizations.map((organization) => {
      return {
        id: organization.id,
        value: organization.name,
        image: organization.image,
      };
    });

  const generateOrganizationSelected = (): IdValue => ({
    id: selectedOrganizationData?.id || "",
    value: selectedOrganizationData?.name || "",
    image: selectedOrganizationData?.image,
  });

  const organizationOptions = useMemo(() => generateOrganizacioOptions(), [stateAuth.userInfo.data.organizations]);
  // const organizationSelected: IdValue = useMemo(() => generateOrganizationSelected(), [selectedOrganizationData]);

  const organizationSelected: IdValue = generateOrganizationSelected();

  return [organizationOptions, organizationSelected, onChangeOrganization];
};

export default useOrganizationSelector;
