import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import ModalEditUser from "../../components/modal-edit-user/ModalEditUser";
import "./TopMenu.scss";
import LmIcon from "../icon/LmIcon";
import { ReactComponent as AsteriskBlack } from "../../assets/icons/asterisk-black.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import LmAvatar from "../avatar/LmAvatar";
import ModalEditPassword from "../modal-edit-user/ModalEditPassword";
import ModalUnsubscribeOrganization from "../modal-edit-user/ModalUnsubscribeOrganization";
import LmDropDown from "../dropdown/LmDropDown";
import { DEFAULT_IMAGE } from "../../constants/globalConstanst";
import { NotificationsState } from "../../model/states/NotificationsState";
import { getSelectedDataById } from "../../utils/utils";

function UserAvatar() {
  const { t } = useTranslation();
  const state: AuthState = useSelector((state: MainState) => state.auth);
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);
  const selectedOrganizationData = getSelectedDataById(state.userInfo.selectedOrganization, state.userInfo.data.organizations);

  const [openModalEditProfile, setOpenModalEditProfile] = useState(false);
  const [openModalEditPassword, setOpenModalEditPassword] = useState(false);
  const [openUnsubscribeModal, setOpenUnsubscribeModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const openEditProfile = () => {
    setOpenMenu(false);
    setOpenModalEditProfile(true);
  };

  const openEditPassword = () => {
    setOpenMenu(false);
    setOpenModalEditPassword(true);
  };

  const unsubscribe = () => {
    setOpenModalEditProfile(false);
    setOpenUnsubscribeModal(true);
  };

  return (
    <>
      <div className="lm-top-menu--avatar relative">
        <div>
          <LmAvatar
            testid="badge-notification"
            src={state.avatarUserOrganization || selectedOrganizationData?.image || DEFAULT_IMAGE}
            className="user-avatar-test"
            badge={stateNotifications.showUserNotification ? "miniBadge" : undefined}
            alt={t("topMenu.altIconUser")}
            size={56}
            radius="12px"
            border={true}
            onClick={() => setOpenMenu(!openMenu)}
          />
        </div>

        <LmDropDown open={openMenu} close={() => setOpenMenu(false)} id="user-menu">
          <li data-testid="modal-edit-profile" onClick={openEditProfile} className="flex items-center gap-2 h-11">
            <LmIcon icon={<User />} size="xs"></LmIcon>
            <span className="text-sm">{t("topMenu.editProfile")}</span>
          </li>
          <li data-testid="modal-edit-password" onClick={openEditPassword} className="flex items-center gap-2 h-11">
            <LmIcon icon={<AsteriskBlack />} size="xs"></LmIcon>
            <span className="text-sm">{t("topMenu.editPassword")}</span>
          </li>
        </LmDropDown>
      </div>

      <ModalEditUser open={openModalEditProfile} handleClose={() => setOpenModalEditProfile(false)} unsubscribe={unsubscribe}></ModalEditUser>
      <ModalEditPassword open={openModalEditPassword} handleClose={() => setOpenModalEditPassword(false)}></ModalEditPassword>
      <ModalUnsubscribeOrganization open={openUnsubscribeModal} handleClose={() => setOpenUnsubscribeModal(false)}></ModalUnsubscribeOrganization>
    </>
  );
}

export default UserAvatar;
