import { DataCallback } from "../DataCallback";
import { CommentDto } from "../dto/CommentDto";
import DocumentDto, { CategoryDto, TagsDto } from "../dto/DocumentDto";
import FileDto, { UploadLinkDto } from "../dto/FileDto";
import { AddVideoClip, Clip } from "../dto/ProjectDto";
import ShareFileDto from "../dto/ShareFileDto";
import { IdValue } from "../forms/IdValue";
import { ContentManagementTabs, ITypesOfFiles } from "../states/ContentManagementState";

export enum ActionContentManagementType {
  ACTION_DOCUMENTS_LOADING = "ACTION_DOCUMENTS_LOADING",
  ACTION_DOCUMENTS_SUCCESS = "ACTION_DOCUMENTS_SUCCESS",
  ACTION_DOCUMENTS_ERROR = "ACTION_DOCUMENTS_ERROR",

  ACTION_DOCUMENTS_ORDER_BY_DATE = "ACTION_DOCUMENTS_ORDER_BY_DATE",

  ACTION_DOCUMENTS_CHANGE_FILTER_TEAM = "ACTION_DOCUMENTS_CHANGE_FILTER_TEAM",

  ACTION_CHANGE_DOCUMENT_CATEGORY_LOADING = "ACTION_CHANGE_DOCUMENT_CATEGORY_LOADING",
  ACTION_CHANGE_DOCUMENT_CATEGORY_SUCCESS = "ACTION_CHANGE_DOCUMENT_CATEGORY_SUCCESS",
  ACTION_CHANGE_DOCUMENT_CATEGORY_ERROR = "ACTION_CHANGE_DOCUMENT_CATEGORY_ERROR",

  ACTION_ADD_TAG_TO_DOCUMENT_LOADING = "ACTION_ADD_TAG_TO_DOCUMENT_LOADING",
  ACTION_ADD_TAG_TO_DOCUMENT_SUCCESS = "ACTION_ADD_TAG_TO_DOCUMENT_SUCCESS",
  ACTION_ADD_TAG_TO_DOCUMENT_ERROR = "ACTION_ADD_TAG_TO_DOCUMENT_ERROR",
  ACTION_ADD_TAG_TO_DOCUMENT_RESET = "ACTION_ADD_TAG_TO_DOCUMENT_RESET",

  ACTION_EDIT_CATEGORY_LOADING = "ACTION_EDIT_CATEGORY_LOADING",
  ACTION_EDIT_CATEGORY_SUCCESS = "ACTION_EDIT_CATEGORY_SUCCESS",
  ACTION_EDIT_CATEGORY_ERROR = "ACTION_EDIT_CATEGORY_ERROR",

  ACTION_DELETE_CATEGORY_LOADING = "ACTION_DELETE_CATEGORY_LOADING",
  ACTION_DELETE_CATEGORY_SUCCESS = "ACTION_DELETE_CATEGORY_SUCCESS",
  ACTION_DELETE_CATEGORY_ERROR = "ACTION_DELETE_CATEGORY_ERROR",

  ACTION_TAGS_LOADING = "ACTION_TAGS_LOADING",
  ACTION_TAGS_SUCCESS = "ACTION_TAGS_SUCCESS",
  ACTION_TAGS_ERROR = "ACTION_TAGS_ERROR",

  ACTION_TAGS_PRIVATE_LOADING = "ACTION_TAGS_PRIVATE_LOADING",
  ACTION_TAGS_PRIVATE_SUCCESS = "ACTION_TAGS_PRIVATE_SUCCESS",
  ACTION_TAGS_PRIVATE_ERROR = "ACTION_TAGS_PRIVATE_ERROR",

  ACTION_CATEGORIES_LOADING = "ACTION_CATEGORIES_LOADING",
  ACTION_CATEGORIES_SUCCESS = "ACTION_CATEGORIES_SUCCESS",
  ACTION_CATEGORIES_ERROR = "ACTION_CATEGORIES_ERROR",

  ACTION_OPEN_CLOSE_CATEGORY = "ACTION_OPEN_CLOSE_CATEGORY",

  ACTION_UPLOAD_FILE_DATA_LOADING = "ACTION_UPLOAD_FILE_DATA_LOADING",
  ACTION_UPLOAD_FILE_DATA_SUCCESS = "ACTION_UPLOAD_FILE_DATA_SUCCESS",
  ACTION_UPLOAD_FILE_DATA_ERROR = "ACTION_UPLOAD_FILE_DATA_ERROR",
 
  ACTION_UPLOAD_FILE_PROGRESS = "ACTION_UPLOAD_FILE_PROGRESS",

  ACTION_UPLOAD_LINK_LOADING = 'ACTION_UPLOAD_LINK_LOADING',
  ACTION_UPLOAD_LINK_SUCCESS = 'ACTION_UPLOAD_LINK_SUCCESS',
  ACTION_UPLOAD_LINK_ERROR = 'ACTION_UPLOAD_LINK_ERROR',

  ACTION_UPLOAD_FILE_CONFIRM_LOADING = 'ACTION_UPLOAD_FILE_CONFIRM_LOADING',
  ACTION_UPLOAD_FILE_CONFIRM_SUCCESS = 'ACTION_UPLOAD_FILE_CONFIRM_SUCCESS',
  ACTION_UPLOAD_FILE_CONFIRM_ERROR = 'ACTION_UPLOAD_FILE_CONFIRM_ERROR',

  ACTION_UPLOAD_FILE_TO_AZURE_LOADING = 'ACTION_UPLOAD_FILE_TO_AZURE_LOADING',
  ACTION_UPLOAD_FILE_TO_AZURE_SUCCESS = 'ACTION_UPLOAD_FILE_TO_AZURE_SUCCESS',

  ACTION_DOWNLOAD_SINGLE_FILE_LOADING = "ACTION_DOWNLOAD_SINGLE_FILE_LOADING",
  ACTION_DOWNLOAD_FILES_LOADING = "ACTION_DOWNLOAD_FILES_LOADING",
  ACTION_DOWNLOAD_FILES_SUCCESS = "ACTION_DOWNLOAD_FILES_SUCCESS",
  ACTION_DOWNLOAD_FILES_ERROR = "ACTION_DOWNLOAD_FILES_ERROR",

  ACTION_DELETE_FILES_LOADING = "ACTION_DELETE_FILES_LOADING",
  ACTION_DELETE_FILES_SUCCESS = "ACTION_DELETE_FILES_SUCCESS",
  ACTION_DELETE_FILES_ERROR = "ACTION_DELETE_FILES_ERROR",
  ACTION_DELETE_FILES_RESET = "ACTION_DELETE_FILES_RESET",

  ACTION_SHARE_FILE_LOADING = "ACTION_SHARE_FILE_LOADING",
  ACTION_SHARE_FILE_SUCCESS = "ACTION_SHARE_FILE_SUCCESS",
  ACTION_SHARE_FILE_ERROR = "ACTION_SHARE_FILE_ERROR",

  ACTION_UNSHARE_FILE_LOADING = "ACTION_UNSHARE_FILE_LOADING",
  ACTION_UNSHARE_FILE_SUCCESS = "ACTION_UNSHARE_FILE_SUCCESS",
  ACTION_UNSHARE_FILE_ERROR = "ACTION_UNSHARE_FILE_ERROR",

  ACTION_SHARE_FILE_RESET = "ACTION_SHARE_FILE_RESET",
  ACTION_UNSHARE_FILE_RESET = "ACTION_UNSHARE_FILE_RESET",

  ACTION_UPLOAD_RESET = "ACTION_UPLOAD_RESET",

  ACTION_CONTENT_MANAGEMENT_TAB_SELECTED = "ACTION_CONTENT_MANAGEMENT_TAB_SELECTED",

  ACTION_CHANGE_SELECTED_FILES = "ACTION_CHANGE_SELECTED_FILES",
  ACTION_DOCUMENT_RESET = "ACTION_DOCUMENT_RESET",

  ACTION_LOAD_DOCUMENT_LOADING = "ACTION_LOAD_DOCUMENT_LOADING",
  ACTION_LOAD_DOCUMENT_SUCCESS = "ACTION_LOAD_DOCUMENT_SUCCESS",
  ACTION_LOAD_DOCUMENT_ERROR = "ACTION_LOAD_DOCUMENT_ERROR",

  ACTION_LOAD_DOCUMENT_URL_LOADING = "ACTION_LOAD_DOCUMENT_URL_LOADING",
  ACTION_LOAD_DOCUMENT_URL_SUCCESS = "ACTION_LOAD_DOCUMENT_URL_SUCCESS",
  ACTION_LOAD_DOCUMENT_URL_ERROR = "ACTION_LOAD_DOCUMENT_URL_ERROR",

  ACTION_REMOVE_TAG_FROM_DOCUMENT_LOADING = "ACTION_REMOVE_TAG_FROM_DOCUMENT_LOADING",
  ACTION_REMOVE_TAG_FROM_DOCUMENT_SUCCESS = "ACTION_REMOVE_TAG_FROM_DOCUMENT_SUCCESS",
  ACTION_REMOVE_TAG_FROM_DOCUMENT_ERROR = "ACTION_REMOVE_TAG_FROM_DOCUMENT_ERROR",

  ACTION_GET_FILE_COMMENTS_LOADING = "ACTION_GET_FILE_COMMENTS_LOADING",
  ACTION_GET_FILE_COMMENTS_SUCCESS = "ACTION_GET_FILE_COMMENTS_SUCCESS",
  ACTION_GET_FILE_COMMENTS_ERROR = "ACTION_GET_FILE_COMMENTS_ERROR",

  ACTION_SAVE_FILE_COMMENTS_LOADING = "ACTION_SAVE_FILE_COMMENTS_LOADING",
  ACTION_SAVE_FILE_COMMENTS_SUCCESS = "ACTION_SAVE_FILE_COMMENTS_SUCCESS",
  ACTION_SAVE_FILE_COMMENTS_ERROR = "ACTION_SAVE_FILE_COMMENTS_ERROR",

  ACTION_DELETE_FILE_COMMENTS_LOADING = "ACTION_DELETE_FILE_COMMENTS_LOADING",
  ACTION_DELETE_FILE_COMMENTS_SUCCESS = "ACTION_DELETE_FILE_COMMENTS_SUCCESS",
  ACTION_DELETE_FILE_COMMENTS_ERROR = "ACTION_DELETE_FILE_COMMENTS_ERROR",

  ACTION_LIKE_FILE_COMMENTS_LOADING = "ACTION_LIKE_FILE_COMMENTS_LOADING",
  ACTION_LIKE_FILE_COMMENTS_SUCCESS = "ACTION_LIKE_FILE_COMMENTS_SUCCESS",
  ACTION_LIKE_FILE_COMMENTS_ERROR = "ACTION_LIKE_FILE_COMMENTS_ERROR",

  ACTION_UNLIKE_FILE_COMMENTS_LOADING = "ACTION_UNLIKE_FILE_COMMENTS_LOADING",
  ACTION_UNLIKE_FILE_COMMENTS_SUCCESS = "ACTION_UNLIKE_FILE_COMMENTS_SUCCESS",
  ACTION_UNLIKE_FILE_COMMENTS_ERROR = "ACTION_UNLIKE_FILE_COMMENTS_ERROR",

  ACTION_GET_LAST_COMMENTS_LOADING = "ACTION_GET_LAST_COMMENTS_LOADING",
  ACTION_GET_LAST_COMMENTS_SUCCESS = "ACTION_GET_LAST_COMMENTS_SUCCESS",
  ACTION_GET_LAST_COMMENTS_ERROR = "ACTION_GET_LAST_COMMENTS_ERROR",

  ACTION_GET_LAST_MENTIONS_LOADING = "ACTION_GET_LAST_MENTIONS_LOADING",
  ACTION_GET_LAST_MENTIONS_SUCCESS = "ACTION_GET_LAST_MENTIONS_SUCCESS",
  ACTION_GET_LAST_MENTIONS_ERROR = "ACTION_GET_LAST_MENTIONS_ERROR",

  ACTION_GET_LAST_DOCUMENTS_LOADING = "ACTION_GET_LAST_DOCUMENTS_LOADING",
  ACTION_GET_LAST_DOCUMENTS_SUCCESS = "ACTION_GET_LAST_DOCUMENTS_SUCCESS",
  ACTION_GET_LAST_DOCUMENTS_ERROR = "ACTION_GET_LAST_DOCUMENTS_ERROR",

  ACTION_GET_LAST_PROJECTS_LOADING = "ACTION_GET_LAST_PROJECTS_LOADING",
  ACTION_GET_LAST_PROJECTS_SUCCESS = "ACTION_GET_LAST_PROJECTS_SUCCESS",
  ACTION_GET_LAST_PROJECTS_ERROR = "ACTION_GET_LAST_PROJECTS_ERROR",

  ACTION_GET_FILE_REPLIES = "ACTION_GET_FILE_REPLIES",

  ACTION_GET_COMMENT_REPLIES_LOADING = "ACTION_GET_COMMENT_REPLIES_LOADING",
  ACTION_GET_COMMENT_REPLIES_SUCCESS = "ACTION_GET_COMMENT_REPLIES_SUCCESS",
  ACTION_GET_COMMENT_REPLIES_ERROR = "ACTION_GET_COMMENT_REPLIES_ERROR",

  ACTION_GRANT_ACCESS_LOADING = "ACTION_GRANT_ACCESS_LOADING",
  ACTION_GRANT_ACCESS_SUCCESS = "ACTION_GRANT_ACCESS_SUCCESS",
  ACTION_GRANT_ACCESS_ERROR = "ACTION_GRANT_ACCESS_ERROR",
  ACTION_GRANT_ACCESS_RESET = "ACTION_GRANT_ACCESS_RESET",

  ADD_TAGS_ORGANIZATION = "ADD_TAGS_ORGANIZATION",
  ACTION_CONTENT_DETAIL_TAB_SELECTED = "ACTION_CONTENT_DETAIL_TAB_SELECTED",

  ACTION_GET_PLAYLIST_BY_PROJECT_LOADING = "ACTION_GET_PLAYLIST_BY_PROJECT_LOADING",
  ACTION_GET_PLAYLIST_BY_PROJECT_SUCCESS = "ACTION_GET_PLAYLIST_BY_PROJECT_SUCCESS",
  ACTION_GET_PLAYLIST_BY_PROJECT_ERROR = "ACTION_GET_PLAYLIST_BY_PROJECT_ERROR",

  ACTION_CREATE_PLAYLIST_LOADING = "ACTION_CREATE_PLAYLIST_LOADING",
  ACTION_CREATE_PLAYLIST_SUCCESS = "ACTION_CREATE_PLAYLIST_SUCCESS",
  ACTION_CREATE_PLAYLIST_ERROR = "ACTION_CREATE_PLAYLIST_ERROR",

  ACTION_ADD_CLIP_TO_PLAYLIST_LOADING = "ACTION_ADD_CLIP_TO_PLAYLIST_LOADING",
  ACTION_ADD_CLIP_TO_PLAYLIST_SUCCESS = "ACTION_ADD_CLIP_TO_PLAYLIST_SUCCESS",
  ACTION_ADD_CLIP_TO_PLAYLIST_ERROR = "ACTION_ADD_CLIP_TO_PLAYLIST_ERROR",

  ACTION_RESET_CREATE_PLAYLIST = 'ACTION_RESET_CREATE_PLAYLIST',
  ACTION_STATE_DELETE_PLAYLIST_BY_ID = "ACTION_STATE_DELETE_PLAYLIST_BY_ID",

  ACTION_GET_CLIPS_BY_PLAYLIST_ID_LOADING = "ACTION_GET_CLIPS_BY_PLAYLIST_ID_LOADING",
  ACTION_GET_CLIPS_BY_PLAYLIST_ID_SUCCESS = "ACTION_GET_CLIPS_BY_PLAYLIST_ID_SUCCESS",
  ACTION_GET_CLIPS_BY_PLAYLIST_ID_ERROR = "ACTION_GET_CLIPS_BY_PLAYLIST_ID_ERROR",

  ACTION_DELETE_CLIPS_BY_ID_LOADING = "ACTION_DELETE_CLIPS_BY_ID_LOADING",
  ACTION_DELETE_CLIPS_BY_ID_SUCCESS = "ACTION_DELETE_CLIPS_BY_ID_SUCCESS",
  ACTION_DELETE_CLIPS_BY_ID_ERROR = "ACTION_DELETE_CLIPS_BY_ID_ERROR",

  ACTION_DELETE_PLAY_LIST_LOADING = "ACTION_DELETE_PLAY_LIST_LOADING",
  ACTION_DELETE_PLAY_LIST_SUCCESS = "ACTION_DELETE_PLAY_LIST_SUCCESS",

  ACTION_SELECTED_PLAYLIST = "ACTION_SELECTED_PLAYLIST",

  ACTION_GET_PLAYLIST_DETAILS_LOADING = "ACTION_GET_PLAYLIST_DETAILS_LOADING",
  ACTION_GET_PLAYLIST_DETAILS_SUCCESS = "ACTION_GET_PLAYLIST_DETAILS_SUCCESS",
  ACTION_GET_PLAYLIST_DETAILS_ERROR = "ACTION_GET_PLAYLIST_DETAILS_ERROR",
}

export interface ActionDocumentsLoading {
  type: ActionContentManagementType.ACTION_DOCUMENTS_LOADING;
  value: {
    organizationId: string;
    type: string;
    top?: number;
  };
}

export interface ActionDocumentsSuccess {
  type: ActionContentManagementType.ACTION_DOCUMENTS_SUCCESS;
  value: { data: DocumentDto[]; type: string };
}

export interface ActionDocumentsError {
  type: ActionContentManagementType.ACTION_DOCUMENTS_ERROR;
  value: { type: string };
}

export interface ActionGetLastDocumentsLoading {
  type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_LOADING;
  value: {
    organizationId: string;
    types: string[];
    top?: number;
  };
}

export interface ActionGetLastDocumentsSuccess {
  type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_SUCCESS;
  value: DocumentDto[];
}

export interface ActionGetLastDocumentsError {
  type: ActionContentManagementType.ACTION_GET_LAST_DOCUMENTS_ERROR;
}

export interface ActionGetLastProyectsLoading {
  type: ActionContentManagementType.ACTION_GET_LAST_PROJECTS_LOADING;
  value: {
    organizationId: string;
    types: string[];
    top?: number;
  };
}

export interface ActionGetLastProyectsSuccess {
  type: ActionContentManagementType.ACTION_GET_LAST_PROJECTS_SUCCESS;
  value: DocumentDto[];
}

export interface ActionGetLastProyectsError {
  type: ActionContentManagementType.ACTION_GET_LAST_PROJECTS_ERROR;
}

export interface ActionOrderDocumentByDate {
  type: ActionContentManagementType.ACTION_DOCUMENTS_ORDER_BY_DATE;
}

export interface ActionChangeFilterTeamDocuments {
  type: ActionContentManagementType.ACTION_DOCUMENTS_CHANGE_FILTER_TEAM;
  value: string;
}

export interface ActionChangeCategoryLoading {
  type: ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_LOADING;
  value: { categoryId: string; newCategoryName: string; files: string[] };
}

export interface ActionChangeCategorySuccess {
  type: ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_SUCCESS;
  value: { data: DocumentDto[]; categoryId: string };
}

export interface ActionChangeCategoryError {
  type: ActionContentManagementType.ACTION_CHANGE_DOCUMENT_CATEGORY_ERROR;
}

export interface ActionAddTagToDocumentLoading {
  type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_LOADING;
  value: { files: string[]; tags: IdValue[]; teamId?: string };
}

export interface ActionAddTagToDocumentSuccess {
  type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_SUCCESS;
  value: DocumentDto[];
}

export interface ActionAddTagToDocumentError {
  type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_ERROR;
}

export interface ActionAddTagToDocumentReset {
  type: ActionContentManagementType.ACTION_ADD_TAG_TO_DOCUMENT_RESET;
}

export interface ActionEditCategoryLoading {
  type: ActionContentManagementType.ACTION_EDIT_CATEGORY_LOADING;
  value: { id: string; name: string };
}

export interface ActionEditCategorySuccess {
  type: ActionContentManagementType.ACTION_EDIT_CATEGORY_SUCCESS;
  value: CategoryDto;
}

export interface ActionEditCategoryError {
  type: ActionContentManagementType.ACTION_EDIT_CATEGORY_ERROR;
}

export interface ActionDeleteCategoryLoading {
  type: ActionContentManagementType.ACTION_DELETE_CATEGORY_LOADING;
  value: string;
}

export interface ActionDeleteCategorySuccess {
  type: ActionContentManagementType.ACTION_DELETE_CATEGORY_SUCCESS;
  value: string;
}

export interface ActionDeleteCategoryError {
  type: ActionContentManagementType.ACTION_DELETE_CATEGORY_ERROR;
}

export interface ActionUploadFileDataLoading {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_LOADING;
  value: { data: FileDto; file: File };
}

export interface ActionUploadFileDataSuccess {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_SUCCESS;
  value: { data: DocumentDto; newCategoryName: string };
}

export interface ActionUploadFileDataError {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_DATA_ERROR;
}

export interface ActionUploadFileProgress {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_PROGRESS;
  value: number;
}

export interface ActionTagsLoading {
  type: ActionContentManagementType.ACTION_TAGS_LOADING;
  value: {organizationId: string;}
}

export interface ActionTagsSuccess {
  type: ActionContentManagementType.ACTION_TAGS_SUCCESS;
  value: TagsDto[];
}

export interface ActionTagsError {
  type: ActionContentManagementType.ACTION_TAGS_ERROR;
}

export interface ActionTagsPrivateLoading {
  type: ActionContentManagementType.ACTION_TAGS_PRIVATE_LOADING;
  value: {organizationId: string; teamId: string}
}

export interface ActionTagsPrivateSuccess {
  type: ActionContentManagementType.ACTION_TAGS_PRIVATE_SUCCESS;
  value: TagsDto[];
}

export interface ActionTagsPrivateError {
  type: ActionContentManagementType.ACTION_TAGS_PRIVATE_ERROR;
}

export interface ActionCategoriesLoading {
  type: ActionContentManagementType.ACTION_CATEGORIES_LOADING;
  value: string;
}

export interface ActionCategoriesSuccess {
  type: ActionContentManagementType.ACTION_CATEGORIES_SUCCESS;
  value: CategoryDto[];
}

export interface ActionCategoriesError {
  type: ActionContentManagementType.ACTION_CATEGORIES_ERROR;
}

export interface ActionUploadReset {
  type: ActionContentManagementType.ACTION_UPLOAD_RESET;
}

export interface ActionContentManagementTabSelected {
  type: ActionContentManagementType.ACTION_CONTENT_MANAGEMENT_TAB_SELECTED;
  value: number;
}

export interface ActionChangeSelectedFiles {
  type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES;
  value: string[];
}

export interface ActionDownloadSingleFileLoading {
  type: ActionContentManagementType.ACTION_DOWNLOAD_SINGLE_FILE_LOADING;
  value: string;
}

export interface ActionDownloadFilesLoading {
  type: ActionContentManagementType.ACTION_DOWNLOAD_FILES_LOADING;
  value: DataCallback<string>;
}

export interface ActionDeleteFilesLoading {
  type: ActionContentManagementType.ACTION_DELETE_FILES_LOADING;
  value: DataCallback<string>;
}

export interface ActionDeleteFilesSuccess {
  type: ActionContentManagementType.ACTION_DELETE_FILES_SUCCESS;
  value: string[];
}

export interface ActionDeleteFilesError {
  type: ActionContentManagementType.ACTION_DELETE_FILES_ERROR;
}

export interface ActionDeleteFilesReset {
  type: ActionContentManagementType.ACTION_DELETE_FILES_RESET;
}

export interface ActionShareFileLoading {
  type: ActionContentManagementType.ACTION_SHARE_FILE_LOADING;
  value: ShareFileDto;
}

export interface ActionShareFileSuccess {
  type: ActionContentManagementType.ACTION_SHARE_FILE_SUCCESS;
  value: DocumentDto[];
}

export interface ActionShareFileError {
  type: ActionContentManagementType.ACTION_SHARE_FILE_ERROR;
}

export interface ActionLoadDocumentLoading {
  type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_LOADING;
  value: string;
}

export interface ActionLoadDocumentSuccess {
  type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_SUCCESS;
  value: DocumentDto;
}

export interface ActionLoadDocumentError {
  type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_ERROR;
}

export interface ActionDocumentReset {
  type: ActionContentManagementType.ACTION_DOCUMENT_RESET;
}

export interface ActionLoadDocumentUrlLoading {
  type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_LOADING;
  value: string;
}

export interface ActionLoadDocumentUrlSuccess {
  type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_SUCCESS;
  value: string;
}

export interface ActionLoadDocumentUrlError {
  type: ActionContentManagementType.ACTION_LOAD_DOCUMENT_URL_ERROR;
}

export interface ActionRemoveTagFromDocumentLoading {
  type: ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_LOADING;
  value: {
    fileId: string;
    tagId: string;
  };
}

export interface ActionRemoveTagFromDocumentSuccess {
  type: ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_SUCCESS;
  value: {
    fileId: string;
    tagId: string;
  };
}

export interface ActionRemoveTagFromDocumentError {
  type: ActionContentManagementType.ACTION_REMOVE_TAG_FROM_DOCUMENT_ERROR;
}

export interface ActionShareFileReset {
  type: ActionContentManagementType.ACTION_SHARE_FILE_RESET;
}

export interface ActionGetFileCommentsLoading {
  type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_LOADING;
  value: string;
}

export interface ActionGetFileCommentsSuccess {
  type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_SUCCESS;
  value: CommentDto[];
}

export interface ActionGetFileCommentsError {
  type: ActionContentManagementType.ACTION_GET_FILE_COMMENTS_ERROR;
}
export interface ActionSaveFileCommentsLoading {
  type: ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_LOADING;
  value: Partial<CommentDto>;
}

export interface ActionSaveFileCommentsSuccess {
  type: ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_SUCCESS;
  value: {
    parentId?: string;
    savedComment: CommentDto;
  };
}

export interface ActionSaveFileCommentsError {
  type: ActionContentManagementType.ACTION_SAVE_FILE_COMMENTS_ERROR;
}

export interface ActionDeleteFileCommentsLoading {
  type: ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_LOADING;
  value: {
    commentId: string;
    isParent: boolean;
  };
}

export interface ActionDeleteFileCommentsSuccess {
  type: ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_SUCCESS;
  value: {
    commentId: string;
    isParent: boolean;
  };
}

export interface ActionDeleteFileCommentsError {
  type: ActionContentManagementType.ACTION_DELETE_FILE_COMMENTS_ERROR;
}

export interface ActionLikeFileCommentsLoading {
  type: ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_LOADING;
  value: {
    commentId: string;
    userId: string;
  };
}

export interface ActionLikeFileCommentsSuccess {
  type: ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_SUCCESS;
  value: {
    commentId: string;
    userId: string;
  };
}

export interface ActionLikeFileCommentsError {
  type: ActionContentManagementType.ACTION_LIKE_FILE_COMMENTS_ERROR;
}

export interface ActionUnlikeFileCommentsLoading {
  type: ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_LOADING;
  value: {
    commentId: string;
    userId: string;
  };
}

export interface ActionUnlikeFileCommentsSuccess {
  type: ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_SUCCESS;
  value: {
    commentId: string;
    userId: string;
  };
}

export interface ActionUnlikeFileCommentsError {
  type: ActionContentManagementType.ACTION_UNLIKE_FILE_COMMENTS_ERROR;
}

export interface ActionGetLastCommentsLoading {
  type: ActionContentManagementType.ACTION_GET_LAST_COMMENTS_LOADING;
  value: {
    organizationId: string;
    top: number;
  };
}

export interface ActionGetLastCommentsSuccess {
  type: ActionContentManagementType.ACTION_GET_LAST_COMMENTS_SUCCESS;
  value: CommentDto[];
}

export interface ActionGetLastCommentsError {
  type: ActionContentManagementType.ACTION_GET_LAST_COMMENTS_ERROR;
}

export interface ActionGetLastMentionsLoading {
  type: ActionContentManagementType.ACTION_GET_LAST_MENTIONS_LOADING;
  value: {
    organizationId: string;
    top: number;
  };
}

export interface ActionGetLastMentionsSuccess {
  type: ActionContentManagementType.ACTION_GET_LAST_MENTIONS_SUCCESS;
  value: CommentDto[];
}

export interface ActionGetLastMentionsError {
  type: ActionContentManagementType.ACTION_GET_LAST_MENTIONS_ERROR;
}

export interface ActionGetCommentRepliesLoading {
  type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_LOADING;
  value: string;
}

export interface ActionGetCommentRepliesSuccess {
  type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_SUCCESS;
  value: {
    replies: CommentDto[];
    parentComment: CommentDto;
  };
}

export interface ActionGetCommentRepliesError {
  type: ActionContentManagementType.ACTION_GET_COMMENT_REPLIES_ERROR;
}

export interface ActionUnShareFileLoading {
  type: ActionContentManagementType.ACTION_UNSHARE_FILE_LOADING;
  value: ShareFileDto;
}

export interface ActionUnShareFileSuccess {
  type: ActionContentManagementType.ACTION_UNSHARE_FILE_SUCCESS;
  value: DocumentDto[];
}

export interface ActionUnShareFileError {
  type: ActionContentManagementType.ACTION_UNSHARE_FILE_ERROR;
}

export interface ActionUnShareFileReset {
  type: ActionContentManagementType.ACTION_UNSHARE_FILE_RESET;
}

export interface ActionGrantAccessLoading {
  type: ActionContentManagementType.ACTION_GRANT_ACCESS_LOADING;
  value: { files: string[]; users: string[] };
}

export interface ActionGrantAccessSuccess {
  type: ActionContentManagementType.ACTION_GRANT_ACCESS_SUCCESS;
  value: string[];
}

export interface ActionGrantAccessError {
  type: ActionContentManagementType.ACTION_GRANT_ACCESS_ERROR;
}

export interface ActionGrantAccessReset {
  type: ActionContentManagementType.ACTION_GRANT_ACCESS_RESET;
}

export interface ActionAddTagsOrganization {
  type: ActionContentManagementType.ADD_TAGS_ORGANIZATION;
  value: TagsDto[];
}

export interface ActionContentDetailTabSelected {
  type: ActionContentManagementType.ACTION_CONTENT_DETAIL_TAB_SELECTED;
  value: ContentManagementTabs;
}

export interface ActionGetPlaylistByProjectLoading {
  type: ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_LOADING;
  value: string;
}

export interface ActionGetPlaylistByProjectSuccess {
  type: ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_SUCCESS;
  value: DocumentDto[];
}

export interface ActionGetPlaylistByProjectError {
  type: ActionContentManagementType.ACTION_GET_PLAYLIST_BY_PROJECT_ERROR;
}

export interface ActionCreatePlaylistLoading {
  type: ActionContentManagementType.ACTION_CREATE_PLAYLIST_LOADING;
  value: {file: FileDto, videoclip: AddVideoClip}
}
export interface ActionCreatePlaylistSuccess {
  type: ActionContentManagementType.ACTION_CREATE_PLAYLIST_SUCCESS;
  value: DocumentDto;
}

export interface ActionCreatePlaylistError {
  type: ActionContentManagementType.ACTION_CREATE_PLAYLIST_ERROR;
}

export interface ActionAddClipToPlaylistLoading {
  type: ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_LOADING;
  value: AddVideoClip;
}

export interface ActionAddClipToPlaylistSuccess {
  type: ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_SUCCESS;
  value: any[];
}

export interface ActionAddClipToPlaylistError {
  type: ActionContentManagementType.ACTION_ADD_CLIP_TO_PLAYLIST_ERROR;
}

export interface ActionResetCreatePlayList {
  type: ActionContentManagementType.ACTION_RESET_CREATE_PLAYLIST;
}
export interface ActionStateDeletePlaylistById {
  type: ActionContentManagementType.ACTION_STATE_DELETE_PLAYLIST_BY_ID;
  value: string;
}

export interface ActionGetClipsByPlaylistIdLoading {
  type: ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_LOADING;
  value: string;
}

export interface ActionGetClipsByPlaylistIdSuccess {
  type: ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_SUCCESS;
  value: { clips: Clip[]; playlistId: string };
}

export interface ActionGetClipsByPlaylistIdError {
  type: ActionContentManagementType.ACTION_GET_CLIPS_BY_PLAYLIST_ID_ERROR;
  value: string;
}

export interface ActionDeleteClipsByIdLoading {
  type: ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_LOADING;
  value: { clipId: string; playlistId: string, lastClip: boolean };
}

export interface ActionDeleteClipsByIdSuccess {
  type: ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_SUCCESS;
  value: { clipId: string; playlistId: string };
}

export interface ActionDeleteClipsByIdError {
  type: ActionContentManagementType.ACTION_DELETE_CLIPS_BY_ID_ERROR;
}

export interface ActionSelectedPlaylist {
  type: ActionContentManagementType.ACTION_SELECTED_PLAYLIST;
  value: string;
}

export interface ActionGetPlaylistDetailsLoading {
  type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_LOADING;
  value: string;
}

export interface ActionGetPlaylistDetailsSuccess {
  type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_SUCCESS;
  value: DocumentDto;
}

export interface ActionGetPlaylistDetailsError {
  type: ActionContentManagementType.ACTION_GET_PLAYLIST_DETAILS_ERROR;
}

export interface ActionUploadLinkLoading {
  type: ActionContentManagementType.ACTION_UPLOAD_LINK_LOADING;
  value: string;
}

export interface ActionUploadLinkSuccess {
  type: ActionContentManagementType.ACTION_UPLOAD_LINK_SUCCESS;
  value: UploadLinkDto;
}

export interface ActionUploadLinkError {
  type: ActionContentManagementType.ACTION_UPLOAD_LINK_ERROR;
}

export interface ActionUploadConfirmLoading {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_LOADING;
  value: string;
}

export interface ActionUploadConfirmSuccess {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_SUCCESS;  
}

export interface ActionUploadConfirmError {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_CONFIRM_ERROR;
}

export interface ActionUploadFileToAzureLoading {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_TO_AZURE_LOADING;
}

export interface ActionUploadFileToAzureSuccess {
  type: ActionContentManagementType.ACTION_UPLOAD_FILE_TO_AZURE_SUCCESS;
}

export interface ActionDeletePlaylistLoading {
  type: ActionContentManagementType.ACTION_DELETE_PLAY_LIST_LOADING;
  value: {url: string, idPlaylist: string};
}
export interface ActionDeletePlaylistSuccess {
  type: ActionContentManagementType.ACTION_DELETE_PLAY_LIST_SUCCESS;
  value: string;
}

export interface ActionOpenCloseCategory {
  type: ActionContentManagementType.ACTION_OPEN_CLOSE_CATEGORY;
  value: {
    idCategory: string;
    status: boolean;
    typeOfFile: keyof ITypesOfFiles;
  };
}

export type TypeDocumentManagementActions =
  | ActionDocumentsLoading
  | ActionDocumentsSuccess
  | ActionDocumentsError
  | ActionChangeCategoryLoading
  | ActionChangeCategorySuccess
  | ActionChangeCategoryError
  | ActionEditCategoryLoading
  | ActionEditCategorySuccess
  | ActionEditCategoryError
  | ActionUploadFileDataLoading
  | ActionUploadFileDataSuccess
  | ActionUploadFileDataError  
  | ActionOrderDocumentByDate
  | ActionChangeFilterTeamDocuments
  | ActionTagsLoading
  | ActionTagsSuccess
  | ActionTagsError
  | ActionCategoriesLoading
  | ActionCategoriesSuccess
  | ActionCategoriesError
  | ActionUploadReset
  | ActionContentManagementTabSelected
  | ActionChangeSelectedFiles
  | ActionAddTagToDocumentLoading
  | ActionAddTagToDocumentSuccess
  | ActionAddTagToDocumentError
  | ActionDownloadSingleFileLoading
  | ActionDownloadFilesLoading
  | ActionDeleteFilesLoading
  | ActionDeleteFilesSuccess
  | ActionDeleteFilesError
  | ActionDeleteFilesReset
  | ActionDeleteCategoryLoading
  | ActionDeleteCategorySuccess
  | ActionDeleteCategoryError
  | ActionShareFileLoading
  | ActionShareFileSuccess
  | ActionShareFileError
  | ActionLoadDocumentLoading
  | ActionLoadDocumentSuccess
  | ActionLoadDocumentError
  | ActionDocumentReset
  | ActionLoadDocumentUrlLoading
  | ActionLoadDocumentUrlSuccess
  | ActionLoadDocumentUrlError
  | ActionRemoveTagFromDocumentLoading
  | ActionRemoveTagFromDocumentSuccess
  | ActionRemoveTagFromDocumentError
  | ActionShareFileReset
  | ActionGetFileCommentsLoading
  | ActionGetFileCommentsSuccess
  | ActionGetFileCommentsError
  | ActionSaveFileCommentsLoading
  | ActionSaveFileCommentsSuccess
  | ActionSaveFileCommentsError
  | ActionDeleteFileCommentsLoading
  | ActionDeleteFileCommentsSuccess
  | ActionDeleteFileCommentsError
  | ActionLikeFileCommentsLoading
  | ActionLikeFileCommentsSuccess
  | ActionLikeFileCommentsError
  | ActionGetCommentRepliesLoading
  | ActionGetCommentRepliesSuccess
  | ActionGetCommentRepliesError
  | ActionUnShareFileLoading
  | ActionUnShareFileSuccess
  | ActionUnShareFileError
  | ActionUnShareFileReset
  | ActionUnlikeFileCommentsLoading
  | ActionUnlikeFileCommentsSuccess
  | ActionUnlikeFileCommentsError
  | ActionAddTagToDocumentReset
  | ActionGetLastCommentsLoading
  | ActionGetLastCommentsSuccess
  | ActionGetLastCommentsError
  | ActionGetLastDocumentsLoading
  | ActionGetLastDocumentsSuccess
  | ActionGetLastDocumentsError
  | ActionGetLastProyectsLoading
  | ActionGetLastProyectsSuccess
  | ActionGetLastProyectsError
  | ActionGetLastMentionsLoading
  | ActionGetLastMentionsSuccess
  | ActionGetLastMentionsError
  | ActionGrantAccessLoading
  | ActionGrantAccessSuccess
  | ActionGrantAccessError
  | ActionGrantAccessReset
  | ActionAddTagsOrganization
  | ActionContentDetailTabSelected
  | ActionGetPlaylistByProjectLoading
  | ActionGetPlaylistByProjectSuccess
  | ActionGetPlaylistByProjectError
  | ActionCreatePlaylistLoading
  | ActionCreatePlaylistSuccess
  | ActionCreatePlaylistError
  | ActionAddClipToPlaylistLoading
  | ActionAddClipToPlaylistSuccess
  | ActionAddClipToPlaylistError
  | ActionResetCreatePlayList
  | ActionStateDeletePlaylistById
  | ActionGetClipsByPlaylistIdLoading
  | ActionGetClipsByPlaylistIdSuccess
  | ActionGetClipsByPlaylistIdError
  | ActionDeleteClipsByIdLoading
  | ActionDeleteClipsByIdSuccess
  | ActionDeleteClipsByIdError
  | ActionSelectedPlaylist
  | ActionGetPlaylistDetailsLoading
  | ActionGetPlaylistDetailsSuccess
  | ActionGetPlaylistDetailsError
  | ActionUploadFileProgress
  | ActionUploadLinkLoading
  | ActionUploadLinkSuccess
  | ActionUploadLinkError
  | ActionUploadConfirmLoading
  | ActionUploadConfirmSuccess
  | ActionUploadConfirmError
  | ActionUploadFileToAzureLoading
  | ActionUploadFileToAzureSuccess
  | ActionDeletePlaylistLoading
  | ActionDeletePlaylistSuccess
  | ActionTagsPrivateLoading
  | ActionTagsPrivateSuccess
  | ActionTagsPrivateError
  | ActionOpenCloseCategory;
