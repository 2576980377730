import { AvatarGroup, Card, CardActionArea, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import OrganizationDto from "../../model/dto/OrganizationDto";
import LmAvatar from "../avatar/LmAvatar";

interface Props {
  organization: OrganizationDto;
  onClick?(organization: OrganizationDto): void;
}

const OrganizationCard = ({ organization, onClick }: Props) => {
  const SIZE_AVATAR_GROUP = 5;
  const { t } = useTranslation();

  return (
    <Card className="w-full sm:w-72" variant="outlined" data-testid={"organization-card-" + organization?.id}>
      <CardActionArea onClick={() => onClick && onClick(organization)} data-testid={"button-card-" + organization?.id}>
        <CardContent className="flex flex-col items-center rounded">
          <LmAvatar size={60} src={organization.image} variant="rounded" radius="8px" border={true} />
          <div className="flex items-center">
            <p className="text-sm font-semibold mr-2">{organization.name}</p>
            <div className={`w-2.5	h-2.5 rounded-sm theme-${organization.color} bg-orgColor`} />
          </div>
          <p className="text-sm mb-3 text-subtle">{`${organization.teams?.length} ${t("licenseSelector.teams")}`}</p>
          <AvatarGroup total={organization.users?.length} max={SIZE_AVATAR_GROUP}>
            {organization.users?.map((member) => (
              <LmAvatar radius="100%" key={member.userId} src={member?.avatarImage} variant="circular" />
            ))}
          </AvatarGroup>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OrganizationCard;
