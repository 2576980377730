import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TypeActions } from "../../model/actions/typeActions";
import { ReactComponent as LoginLogo } from "../../assets/img/logo-longomatch-white.svg";
import { LmRestorePasswordForm } from "../../components/login-forms/LmRestorePasswordForm";
import "./Login.scss";
import { ActionMainType } from "../../model/actions/typeMainActions";

function RestorePassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: false, showLateralMenu: false } });
  }, []);

  return (
    <div className="login full-height flex flex-col justify-center items-center p-6" data-testid="restore-password">
      <LoginLogo className="md:w-72 w-48 md:mb-24 mb-16" />
      <p className="font-decorative text-white text-center md:text-xl lg:text-2xl mb-4">{t("restorePassword.title")}</p>
      <p className="text-white text-center font-semibold md:text-xl max-w-3xl">{t("restorePassword.subtitle1")}</p>
      <p className="text-white text-center font-semibold md:text-xl max-w-3xl mb-12">{t("restorePassword.subtitle2")}</p>
      <LmRestorePasswordForm />
    </div>
  );
}

export default RestorePassword;
