import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import DocumentDto from "../../model/dto/DocumentDto";
import OrganizationDto from "../../model/dto/OrganizationDto";
import { AuthState } from "../../model/states/AuthState";
import { ContentManagementState } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import { getSelectedDataById } from "../../utils/utils";
import LmAvatarLabelInfoTwoLines from "../avatar-label-info/LmAvatarLabelInfoTwoLines";
import LmButton from "../button/LmButton";
import Loading from "../loading/Loading";
import LmModalShareFile from "../modal-share-file/LmModalShareFile";
import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";

interface Props {
  document: DocumentDto;
}

const DetailDocumentShare = ({ document }: Props) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const users = useMemo(
    () =>
      document?.shareWithUsers.map((userId: string) => {
        const dataUsers = selectedOrganizationData?.users?.find((userOrganization) => userOrganization.userId === userId);
        let roles: string[] = [];
        dataUsers?.teamUsers
          .filter((team) => team.userId === userId)
          .forEach((team) => {
            roles = roles.concat(team.roles);
          });
        const setRoles = new Set<string>(roles);
        return {
          id: dataUsers?.userId,
          name: dataUsers?.user?.name,
          lastName: dataUsers?.user?.lastName,
          avatar: dataUsers?.avatarImage,
          roles: roles && roles.length ? [...setRoles].join(",") : "",
        };
      }),
    [document]
  );

  useEffect(() => {
    if (
      stateContent.filesSelected.length === 0 ||
      stateContent.filesSelected.length > 1 ||
      (stateContent.filesSelected.length === 1 && stateContent.filesSelected[0] !== document?.id)
    ) {
      dispatch({ type: ActionContentManagementType.ACTION_CHANGE_SELECTED_FILES, value: [document?.id] });
    }
  }, [document]);

  const unShareFile = (user: any) => {
    dispatch({ type: ActionContentManagementType.ACTION_UNSHARE_FILE_LOADING, value: { files: [document?.id], users: [user.id], groups: [], comment: "" } });
  };

  return (
    <div className="mt-8">
      <div className="flex flex-wrap gap-3 items-center mb-8">
        <span className="font-semibold">{t("content.sharedWithMembers", { value:  users?.filter((user) => user.name).length })}</span>
        <div className="shrink-0 ml-auto">
          <LmButton
            styleButton="organization"
            buttonType="button"
            type="solid"
            size="small"
            text={`${t("content.shareWith")}`}
            icon={<Share />}
            iconPosition="left"
            clickAction={() => setOpenModal(true)}
          />
        </div>
      </div>
      <div className="space-y-3">
        {!stateContent.unShareFile.loading &&
          users
            ?.filter((user) => user.name)
            .map((user) => (
              <div key={user?.id}>
                <LmAvatarLabelInfoTwoLines
                  src={user.avatar}
                  label={user?.name + " " + user?.lastName}
                  info={user.roles}
                  divider="·"
                  radius="100%"
                  secondLine={`${t("content.sharedBy")} ${document.shared ? document.shared?.name + " " + document.shared?.lastName : ""}`}
                  icon={<Close />}
                  textAction={stateMain.sizeScreen !== SizeScreen.MOBILE ? t("content.stopSharing") as string : ''}
                  onClickAction={() => unShareFile(user)}></LmAvatarLabelInfoTwoLines>
              </div>
            ))}
        {stateContent.unShareFile.loading && <Loading />}
      </div>
      <LmModalShareFile open={openModal} handleClose={() => setOpenModal(false)} />
    </div>
  );
};

export default DetailDocumentShare;
