import { SizeScreen } from "../../model/SizeSreen";
import { InitialState } from "../../model/states/InitialState";

export const initialState: InitialState = {
  alertNotification: {
    open: false,
    severity: "success",
    message: "",
  },
  viewMenu: {
    showTopMenu: true,
    showLateralMenu: true,
  },
  colorAplication: "default",
  sizeScreen: SizeScreen.DESKTOP,
};
