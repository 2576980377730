import React from "react";
import { IdValue } from "../../model/forms/IdValue";
import OrganizationSelector from "./OrganizationSelector";
import "./TopMenu.scss";
import { MainState } from "../../model/states/MainState";
import { useSelector } from "react-redux";
import { Badge } from "@mui/material";
import { ReactComponent as MenuNotifications } from "../../assets/icons/bell.svg";
import { AuthState } from "../../model/states/AuthState";
import { ReactComponent as MenuResponsive } from "../../assets/icons/menu-responsive.svg";
import useOrganizationSelector from "../../hooks/useOrganizationSelector";
import { NotificationsState } from "../../model/states/NotificationsState";
import { useNavigate } from "react-router";
import { ROUTE_PRIVATE_NOTIFICATIONS } from "../../routes/routes";

interface ITopMenuMobile {
  toggleMenu(): void;
}

const TopMenuMobile: React.FC<ITopMenuMobile> = ({ toggleMenu }) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const [organizationOptions, organizationSelected, onChangeOrganization] = useOrganizationSelector();
  const hasOneOrganization = stateAuth.userInfo.data.organizations.length === 1;
  const stateNotifications: NotificationsState = useSelector((state: MainState) => state.notification);
  const navigate = useNavigate();

  return (
    <div className="fixed w-full flex justify-between items-center p-5 pb-2 bg-white/95 z-50" data-testid="top-menu-mobile">
      <div className="lm-top-menu-mobile--left">
        <MenuResponsive onClick={toggleMenu} data-testid="icon-menu-mobile" />
      </div>
      <div className="flex items-center gap-3">
        {stateNotifications.showUserNotification ? (
          <Badge sx={{ ".MuiBadge-badge": { backgroundColor: "#e89633", minWidth: "6px", height: "6px", padding: "0" } }} overlap="circular" badgeContent="">
            <MenuNotifications className="w-5" onClick={()=>navigate(ROUTE_PRIVATE_NOTIFICATIONS)}/>
          </Badge>
        ) : (
          <MenuNotifications />
        )}
        {!hasOneOrganization && (
          <OrganizationSelector
            selected={organizationSelected as IdValue}
            options={organizationOptions as IdValue[]}
            onSelected={onChangeOrganization as (organization: IdValue<string, string>) => void}
          />
        )}
      </div>
    </div>
  );
};

export default TopMenuMobile;
