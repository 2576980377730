import { CommentDto } from "../dto/CommentDto";
import DocumentDto, { CategoryDto, TagsDto } from "../dto/DocumentDto";
import { UploadFile, UploadLinkDto } from "../dto/FileDto";
import { ProjectCategoryDto, ProjectDto, ProjectPlayeryDto } from "../dto/ProjectDto";

export interface StateCategory {
  idCategory: string;
  name: string;
  state: boolean;
}
export interface FileTypeState {
  loading: boolean;
  error: boolean;
  ok: boolean;
  data: DocumentDto[];
  order: "asc" | "desc";
  teamSelected: string;
  stateCategory: StateCategory[];
}
export interface ITypesOfFiles {
  documents: FileTypeState;
  videos: FileTypeState;
  playlists: FileTypeState;
  dashboards: FileTypeState;
  templates: FileTypeState;
  projects: FileTypeState;
}

export enum ContentManagementType {
  Document = 0,
  Video = 1,
  Playlist = 2,
  Dashboard = 3,
  Template = 4,
  Project = 5,
}

export enum ContentManagementTabs {
  Comments = "Comments",
  Share = "Share",
  Analysis = "Analysis",
  ZonalTags = "ZonalTags",
  Playlist = "Playlist",
  Clips = "Clips",
}

export type TypeOfFile = keyof ITypesOfFiles;
export interface ContentManagementState {
  typesOfFiles: ITypesOfFiles;
  changeFileCategory: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: DocumentDto[];
  };
  addTagToFile: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: DocumentDto[];
  };
  editCategory: {
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  deleteCategory: {
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  deleteFiles: {
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  shareFile: {
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  unShareFile: {
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  categories: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: CategoryDto[];
  };
  tags: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: TagsDto[];
    private: TagsDto[];
  };
  uploadDocument: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: UploadFile | null;
  };
  uploadToAzure: boolean;
  uploadLink: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: UploadLinkDto | null;
  };
  contentSelected: ContentManagementType;
  filesSelected: string[];
  document: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: DocumentDto | null;
  };
  documentUrl: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    url: string;
  };
  comments: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    parentComment: CommentDto | null;
    data: CommentDto[];
    lastComments: CommentDto[];
  };
  matchStatistics: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: ProjectDto | null;
    eventsCategory: ProjectCategoryDto[];
  };
  lastDocuments: {
    data: DocumentDto[];
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  lastProjects: {
    data: DocumentDto[];
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  project: {
    data: ProjectDto | null;
    eventsCategory: ProjectCategoryDto[];
    eventsPlayer: ProjectPlayeryDto[];
  };
  grantAccess: {
    loading: boolean;
    error: boolean;
    ok: boolean;
  };
  playlistByProjectSelected: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: DocumentDto[];
  };
  selectedPlaylist: string;
  contentDetailTabSelected: ContentManagementTabs | null;
  playlistComments: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    parentComment: CommentDto | null;
    lastComments: CommentDto[];
    data: CommentDto[];
  };
  createPlaylist: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: DocumentDto | null;
  };
  addClipsToPlaylist: {
    loading: boolean;
    error: boolean;
    ok: boolean;
    data: any[];
  };
}
