import Menu from "@mui/material/Menu";
import { useEffect, useState } from "react";
import { SizeScreen } from "../../model/SizeSreen";
import { CommentDto } from "../../model/dto/CommentDto";
import LmAvatar from "../avatar/LmAvatar";
import LmButton from "../button/LmButton";
import LmIcon from "../icon/LmIcon";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import { ReactComponent as Back } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as Answer } from "../../assets/icons/corner-up-right.svg";
import { ReactComponent as Like } from "../../assets/icons/like.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import UserDto from "../../model/dto/UserDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { formatDateAgo, isOwner } from "../../utils/utils";
import LmAvatarLabelInfo from "../avatar-label-info/LmAvatarLabelInfo";
import mentionsInputStyle from "../content-management/mentionsInputStyle";
import "./CommentListItem.scss";
import { useNavigate } from "react-router-dom";
import { ROUTE_PRIVATE_CONTENT_MANAGEMENT, ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL } from "../../routes/routes";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionAuthType } from "../../model/actions/typeAuthActions";

interface Props {
  comment: CommentDto;
  screenSize: SizeScreen;
  users: Partial<UserDto>[];
  isParent?: boolean;
  loggedUser: UserDto;
  isReadMode?: boolean;
  viewAnswers?: (commentId: string) => void;
  reply: (text: string, parentCommentId: string) => void;
  like?: (commentId: string) => void;
  unlike?: (commentId: string) => void;
  deleteComment?: (commentId: string, isParent: boolean) => void;
  goToParentComment?: () => void;
}

const CommentListItem = ({
  comment,
  screenSize,
  users,
  isParent = false,
  loggedUser,
  isReadMode = false,
  reply,
  like,
  unlike,
  deleteComment,
  viewAnswers,
  goToParentComment,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const [openReplyPannel, setOpenReplyPannel] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const user = users?.find((user) => user?.id === comment.from || user?.userId === comment.from);

  useEffect(() => {
    if (!openReplyPannel) {
      setCommentText("");
    }
  }, [openReplyPannel]);

  const onSaveComment = () => {
    reply(commentText, comment.id);
    setCommentText("");
    setOpenReplyPannel(false);
  };

  const getCommentData = () => {
    let newText = comment.text.replace(/</g, "").replace(/>/g, "");
    users.forEach((user: Partial<UserDto>) => {
      const userName = `@${user?.name} ${user?.lastName}`;
      newText = newText.replace(userName, "<b>" + userName + "</b>");
    });

    return {
      __html: newText,
    };
  };

  const commentLikeClicked = () => {
    if (unlike && like) {
      if (isLikedByMe()) {
        unlike(comment.id);
      } else {
        like(comment.id);
      }
    }
  };

  const isLikedByMe = () => {
    return comment.likedBy.includes(loggedUser.id);
  };

  const showLikesPanel = (event: any) => {
    if (anchorEl !== event.currentTarget && comment.likedBy.length) {
      setAnchorEl(event.currentTarget);
    }
  };

  const hideLikesPanel = () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const canDeleteComment = () => {
    return comment.from === loggedUser.id || isOwner(stateAuth);
  };

  const getParsedCommentDate = () => {
    if (comment.createdAt) {
      return formatDateAgo(comment.createdAt, stateAuth.appLanguage);
    }
    return "";
  };

  document.addEventListener("mousedown", hideLikesPanel);

  const onNavigate = () => {
    if (isReadMode) {
      dispatch<TypeActions>({ type: ActionAuthType.ACTION_OPTION_MENU_SELECTED, value: ROUTE_PRIVATE_CONTENT_MANAGEMENT_DETAIL });
      navigate(`/${ROUTE_PRIVATE_CONTENT_MANAGEMENT}/${comment.relatedFileId}/${comment.id}`);
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="flex items-center gap-2 relative">
          {isParent && <LmIcon className="cursor-pointer" size="sm" icon={<Back />} clickAction={goToParentComment}></LmIcon>}

          <LmAvatar size={30} src={user?.avatarImage} radius="1rem" />

          <div className={`${screenSize !== SizeScreen.DESKTOP ? "flex items-center gap-2" : "flex gap-4"}`}>
            <span>
              {comment.fromUser?.name} {comment.fromUser?.lastName}
            </span>
            <span className="text-neutral-400">{getParsedCommentDate()}</span>
          </div>
        </div>
        {canDeleteComment() && deleteComment ? (
          <LmIcon
            className="cursor-pointer ml-auto hover:opacity-70 transition"
            icon={<Trash />}
            size="sm"
            clickAction={() => deleteComment(comment.id, isParent)}
          />
        ) : (
          ""
        )}
      </div>
      <div className="mt-4 break-all" dangerouslySetInnerHTML={getCommentData()}></div>
      {!isReadMode ? (
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center gap-2 text-neutral-400 font-semibold">
            {viewAnswers ? (
              <div
                className={`${comment.numberOfReplies && !isParent ? "cursor-pointer hover:opacity-70" : ""} transition`}
                onClick={() => (comment.numberOfReplies && !isParent ? viewAnswers(comment.id) : () => false)}>
                {comment.numberOfReplies !== 1 ? t("content.answers", { value: comment.numberOfReplies }) : t("content.answer")}
              </div>
            ) : (
              ""
            )}
            <div className="cursor-pointer flex items-center gap-1 hover:opacity-70 transition" onClick={() => setOpenReplyPannel(!openReplyPannel)}>
              <LmIcon size="sm" icon={<Answer />}></LmIcon>
              {t("content.reply")}
            </div>
          </div>
          <div className="cursor-pointer flex items-center gap-1">
            <LmIcon
              size="sm"
              icon={<Like />}
              className={`${isLikedByMe() ? "text-primary" : "text-white"} transition hover:opacity-70`}
              clickAction={() => commentLikeClicked()}></LmIcon>
            <div onMouseOver={showLikesPanel} onClick={showLikesPanel}>
              {comment.likedBy.length}
            </div>
          </div>
          <Menu
            className="p-4"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={hideLikesPanel}
            MenuListProps={{ onMouseLeave: hideLikesPanel }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}>
            {comment.likedBy.map((userId: string) => {
              const user = users.find((user: Partial<UserDto>) => user?.id === userId) as Partial<UserDto>;
              return (
                user && (
                  <div className="p-4" key={user.id}>
                    <LmAvatarLabelInfo size={30} src={user?.avatarImage} radius="1rem" label={`${user?.name} ${user?.lastName}`} />
                  </div>
                )
              );
            })}
          </Menu>
        </div>
      ) : (
        <div className="cursor-pointer underline flex flex-row-reverse mt-2" onClick={onNavigate}>
          {t("dashboard.goToComment")}
        </div>
      )}

      {openReplyPannel && (
        <div className="mt-8 mb-8 border-t border-neutral-200">
          <div className="mt-4">
            <MentionsInput
              value={commentText}
              placeholder={t("content.comment") as string}
              style={mentionsInputStyle}
              onChange={(e) => setCommentText(e.target.value)}>
              <Mention
                trigger="@"
                data={
                  users.map((user: Partial<UserDto>) => ({
                    id: user?.id,
                    display: `${user?.name} ${user?.lastName}`,
                  })) as SuggestionDataItem[]
                }
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => `@<${display}>`}
                markup="@<__display__>"
                renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                  <div className={`user p-2 ${focused ? "focused" : ""}`}>{highlightedDisplay}</div>
                )}
              />
            </MentionsInput>
          </div>
          <div className="mt-2 mb-12 float-right">
            <LmButton
              styleButton="organization"
              buttonType="button"
              type="solid"
              size="small"
              isDisabled={!commentText.length}
              icon={<SendIcon />}
              clickAction={onSaveComment}></LmButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentListItem;
