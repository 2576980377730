import React from "react";
import "./LmIcon.scss";
import { Tooltip } from "@mui/material";

interface IIcon {
  testid?: string;
  size?: string;
  icon: any;
  className?: string;
  style?: React.CSSProperties;
  tooltip?: string;
  clickAction?(): void;
}

const LmIcon: React.FC<IIcon> = ({ tooltip = "", testid = "", size = "lg", icon, className = "", style = {}, clickAction = () => {} }) => {
  return (
    <Tooltip title={tooltip}>
      <div data-testid={testid} onClick={clickAction} style={style} className={`lm-icon lm-icon--${size} ${className}`}>
        {icon}
      </div>
    </Tooltip>
  );
};

export default LmIcon;
