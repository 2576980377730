import { useSelector } from "react-redux";
import OrganizationUserDto from "../../model/dto/OrganizationUserDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { formatDateAgo, getRoleString } from "../../utils/utils";
import TeamInfoItem from "../team-info-item/TeamInfoItem";
import TeamsDto from "../../model/dto/TeamDto";

interface Props {
  user: OrganizationUserDto;
  teamsInfo: TeamsDto[];
}
const LmHomeUsers = ({ user, teamsInfo }: Props) => {
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const date = formatDateAgo(user.createdAt ?? "", stateAuth.appLanguage);

  const formatRoles = () => {
    const rolesSet = new Set(user.teamUsers.flatMap((obj) => obj.roles));
    const rolesArray = Array.from(rolesSet);
    const result = rolesArray.map((rol) => getRoleString(rol));
    return result.join(", ");
  };

  const getTeamsName = () => {
    const teamIds = user.teamUsers.map((team) => team.teamId);
    const result = teamsInfo?.filter((team) => teamIds.includes(team.id ?? "")).map((team) => team.name);
    return result.join(", ");
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex-1 truncate">
        <TeamInfoItem
          avatarImage={user.avatarImage}
          titleText={
            <div>
              {user?.user?.name} {user?.user?.lastName} · <span className="italic">{formatRoles()}</span>
            </div>
          }
          subtitleText={getTeamsName()}
        />
      </div>
      <div className="flex md:grid md:grid-cols-2 items-center ml-auto">
        <p className="shrink-0 text-sm text-neutral-400 text-right">{date}</p>
      </div>
    </div>
  );
};

export default LmHomeUsers;
