export enum NotificationsType {
  Unknown = "Unknown",
  Comment = "Comment",
  Tagged = "Tagged",
  Shared = "Shared",
  Storage = "Storage",
  Users = "Users",
  DeletedFiles = "DeletedFiles",
  DownloadFiles = "DownloadFiles",
  DeleteUsers = "DeleteUsers",
}
