import { Divider, Tab, Tabs } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TeamsHeader from "../../components/teams-header/TeamsHeader";
import { TypeActions } from "../../model/actions/typeActions";
import { ActionMainType } from "../../model/actions/typeMainActions";
import { ActionOrganizationType } from "../../model/actions/typeOrganizationActions";
import OrganizationDto from "../../model/dto/OrganizationDto";
import TeamsDto from "../../model/dto/TeamDto";
import { AuthState } from "../../model/states/AuthState";
import { MainState } from "../../model/states/MainState";
import { OrganizationState } from "../../model/states/OrganizationState";
import { getSelectedDataById, isOwner } from "../../utils/utils";
import OrganizationSumary from "./OrganizationSumary";
import TeamSumary from "./TeamSumary";
import { InitialState } from "../../model/states/InitialState";
import { SizeScreen } from "../../model/SizeSreen";
import { Navigate } from "react-router";
import { ROUTE_LICENSE_SELECTOR } from "../../routes/routes";

const Teams = () => {
  const stateOrganization: OrganizationState = useSelector((state: MainState) => state.organization);
  const stateAuth: AuthState = useSelector((state: MainState) => state.auth);
  const stateMain: InitialState = useSelector((state: MainState) => state.main);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedOrganizationData: OrganizationDto = getSelectedDataById(stateAuth.userInfo.selectedOrganization, stateAuth.userInfo.data.organizations);

  useEffect(() => {
    dispatch<TypeActions>({ type: ActionMainType.ACTION_VIEW_MENU, value: { showTopMenu: true, showLateralMenu: true } });
  }, [stateAuth.userInfo.selectedTeam]);

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_CHANGE_TEAM_TAB_SELECTED, value: newValue });
    if (isOwner(stateAuth) && stateAuth.userInfo.selectedOrganization !== "" && newValue === 0) {
      dispatch<TypeActions>({ type: ActionOrganizationType.ACTION_ORGANIZATION_SUMARY_LOADING, value: selectedOrganizationData?.id as string });
    }
  };

  const teamsAvailable = (): TeamsDto[] => {
    if (isOwner(stateAuth)) {
      return selectedOrganizationData?.teams;
    } else {
      const userId = stateAuth.userInfo.data.user.id;
      const teams: TeamsDto[] = [];
      selectedOrganizationData?.teams?.forEach((team) =>
        team?.staff?.forEach((staff) => {
          if (staff.userId === userId) {
            teams.push(team);
          }
        })
      );
      return teams;
    }
  };
  const userNoTeams = () => {
    const teams = teamsAvailable();
    if (!isOwner(stateAuth) && teams.length === 0) {
      return true;
    }
    return false;
  };
  return (
    <>
      {userNoTeams() ? (
        <Navigate to={"/" + ROUTE_LICENSE_SELECTOR} />
      ) : (
        <div className="mt-20 lg:mt-0" data-testid="page-teams">
          {stateMain.sizeScreen === SizeScreen.MOBILE && <p className="font-decorative p-5">{t("menu.teams")}</p>}
          <TeamsHeader />
          <Divider />
          <div className="text-decorative px-5 lg:px-8 lg:w-[96%]">
            <Tabs 
              allowScrollButtonsMobile
              className="shadow-border" 
              textColor="inherit" 
              indicatorColor="primary" 
              value={stateOrganization.teamTabSelected} 
              variant="scrollable"
              scrollButtons="auto"
              onChange={onChangeTab}>
              {isOwner(stateAuth) && <Tab sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }} label={t("summary.summaryTab")} />}
              {stateAuth.userInfo.selectedOrganization !== "" &&
                teamsAvailable().map((team) => (
                  <Tab sx={{ fontFamily: "var(--bv-font-family-brand)", textTransform: "capitalize" }} key={team.id} label={team.name} />
                ))}
              {!stateOrganization.organizationSumary.ok && ""}
            </Tabs>
          </div>

          {isOwner(stateAuth) && stateAuth.userInfo.selectedOrganization !== "" && stateOrganization.teamTabSelected === 0 && <OrganizationSumary />}
          {stateOrganization.teamTabSelected !== 0 &&
            stateAuth.userInfo.selectedOrganization !== "" &&
            selectedOrganizationData?.teams &&
            isOwner(stateAuth) && <TeamSumary team={selectedOrganizationData?.teams[stateOrganization.teamTabSelected - 1]} />}

          {stateAuth.userInfo.selectedOrganization !== "" && !isOwner(stateAuth) && <TeamSumary team={teamsAvailable()[stateOrganization.teamTabSelected]} />}
        </div>
      )}
    </>
  );
};

export default Teams;
