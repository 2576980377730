import React from "react";

interface IEmptyDashboard {
  text: string;
  img: string;
}

const EmptyDashboard: React.FC<IEmptyDashboard> = ({ text = "", img = "" }) => {
  return (
    <div className="flex items-center gap-3 md:gap-5">
      <img className="shrink-0" src={img}></img>
      <p className="font-semibold">{text}</p>
    </div>
  );
};

export default EmptyDashboard;
