import { t } from "i18next";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down-black.svg";
import { ReactComponent as Disquete } from "../../assets/icons/diskette.svg";
import { ReactComponent as ImageEdit } from "../../assets/icons/image-edit.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ActionContentManagementType } from "../../model/actions/typeContentManagementActions";
import { CategoryDto } from "../../model/dto/DocumentDto";
import { ContentManagementState, ITypesOfFiles } from "../../model/states/ContentManagementState";
import { MainState } from "../../model/states/MainState";
import Empty from "../empty/empty";
import LmIcon from "../icon/LmIcon";
import LmModalConfirm from "../modal-confirm/LmModalConfirm";

interface ILmAccordion {
  data: CategoryDto;
  isStaff: boolean;
  open: boolean;
  typeOfFile: keyof ITypesOfFiles;
  children: any;
}

const LmAccordion: React.FC<ILmAccordion> = ({ data, isStaff, open, typeOfFile, children }) => {
  const stateContent: ContentManagementState = useSelector((state: MainState) => state.content);
  const [edit, setEdit] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [modalDeleteCategory, setModalDeleteCategory] = useState(false);
  const [name, setName] = useState(data.name);
  const dispatch = useDispatch();
  const refEditCategory = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  useEffect(() => {
    if (stateContent.deleteCategory.error || stateContent.deleteCategory.ok) {
      setModalDeleteCategory(false);
    }    
  }, [stateContent.deleteCategory.error || stateContent.deleteCategory.ok ]);

  useEffect(() => {
    if (edit && refEditCategory.current) {
      refEditCategory.current?.focus();
    }    
  }, [edit]);

  if (!enabled) {
    return null;
  }

  const editCatgory = () => {
    setEdit(true);    
  };

  const saveCategory = () => {
    setEdit(false);
    dispatch({ type: ActionContentManagementType.ACTION_EDIT_CATEGORY_LOADING, value: { id: data.id, name: name } });
  };

  const changeCategory = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const deleteCategory = () => {
    dispatch({ type: ActionContentManagementType.ACTION_DELETE_CATEGORY_LOADING, value: data.id });
  }

  const openClose = ()=>{
    dispatch({type: ActionContentManagementType.ACTION_OPEN_CLOSE_CATEGORY, value: {idCategory: data.id, status: !open, typeOfFile: typeOfFile} })
  }

  const numElements = children.length;
  
  return (<>
      {(numElements > 0) && <div className="flex justify-between items-center gap-4 px-3 py-2 rounded bg-neutral-50">
        <div className="flex items-center gap-2">
          {!edit ? (
            <span className="text-sm font-semibold text-decorative">
              {data.name} ({numElements} {t("content.items")})
            </span>
          ) : (
            <input type="text" ref={refEditCategory} value={name} className="italic bg-neutral-50" onChange={changeCategory}></input>
          )}
          {(isStaff && data.id !== '0') && <>
            {!edit ? (
            <LmIcon className="text-decorative cursor-pointer" icon={<ImageEdit />} size="xs" clickAction={editCatgory} />
            ) : (
              <LmIcon className="cursor-pointer" icon={<Disquete />} size="xs" clickAction={saveCategory} />
            )}
          </>}
         
        </div>
        <div className="flex gap-1">
          {(isStaff && data.id !== '0') && <LmIcon className="cursor-pointer" icon={<Trash />} size="sm" clickAction={()=>setModalDeleteCategory(true)} />}               
          <LmIcon className="cursor-pointer" icon={<ChevronDown />} size="sm" clickAction={openClose} />
        </div>              
      </div>}
     {(numElements > 0) ? <Droppable droppableId={data.id} key={data.id}>
      {(provided) => {
        return (
          <div {...provided.droppableProps} ref={provided.innerRef} >            
            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 ${open && "py-5"}`}>
              {open && numElements > 0 && children}
              {open && numElements === 0 && (
                <div className="col-span-full">
                  <Empty text={t("content.emptyCategory")} />
                </div>
              )}             
            </div>            
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable> 
    : <></>}
      <LmModalConfirm
        id="test-modal-confirm-remove-player"
        title={t("content.messageConfirmDeleteCategoryTitle")}
        description={t("content.messageConfirmDeleteCategoryDescription")}
        textConfirm={t("modalConfirm.textConfirm")}
        textCancel={t("modalConfirm.textCancel")}
        open={modalDeleteCategory}
        handleClose={() => setModalDeleteCategory(false)}
        confirm={deleteCategory}></LmModalConfirm>
    </>);
};

export default LmAccordion;
