import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LmIcon from "../icon/LmIcon";
import { ReactComponent as Left } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as Right } from "../../assets/icons/chevron-right.svg";

interface IPager {
  itemsForPage: number;
  total: number;
  activePage: number;
  actionNext(items: { start: number; end: number }): void;
  actionPrev(items: { start: number; end: number }): void;
}

const Pager: React.FC<IPager> = ({ total, activePage, itemsForPage, actionNext, actionPrev }) => {
  const { t } = useTranslation();
  const [startEndItems, setStartEndItems] = useState<{ start: number; end: number }[]>();
  const [totalPages, setTotalPages] = useState(0);

  const onClickActionPrev = () => {
    if (startEndItems && activePage > 0) {
      actionPrev(startEndItems[activePage - 1]);
    }
  };

  const onClickActionNext = () => {
    if (startEndItems && startEndItems[activePage + 1]) {
      actionNext(startEndItems[activePage + 1]);
    }
  };

  function getPageStart(pageSize: number, pageNr: number) {
    return pageSize * pageNr;
  }

  function getPageLabel(total: number, pageSize: number, pageNr: number) {
    const start = Math.max(getPageStart(pageSize, pageNr) + 1, 0);

    const end = Math.min(getPageStart(pageSize, pageNr + 1), total);

    return { start, end };
  }

  useEffect(() => {
    const itemsToShow = Array.from({ length: total }, (_, i) => `Item ${i + 1}`);
    const size = itemsForPage;
    const pages = Array.from({ length: Math.ceil(itemsToShow.length / size) }, (_, i) => getPageLabel(itemsToShow.length, size, i));
    setTotalPages(pages.length);
    setStartEndItems(pages);
  }, [total, itemsForPage]);

  return startEndItems?.length ? (
    <div className="flex items-center gap-3">
      <p className="text-xs text-subtle">{`${startEndItems[activePage]?.start} - ${startEndItems[activePage]?.end} ${t("pager.of")} ${total}`}</p>
      <div className="flex items-center gap-2">
        <LmIcon
          className={activePage === 0 ? "opacity-40" : "cursor-pointer hover:text-primary transition"}
          icon={<Left />}
          size="base"
          clickAction={onClickActionPrev}
        />
        <LmIcon
          className={activePage === totalPages - 1 ? "opacity-40" : "cursor-pointer hover:text-primary transition"}
          icon={<Right />}
          size="base"
          clickAction={onClickActionNext}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default Pager;
